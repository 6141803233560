import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Icon, Avatar, Typography } from 'antd';
import { useFeature } from '@sendpayments/react-shared/hooks/useFeature';
import { useLogout, useProfilePhoto } from '@send-data-hooks';
import history from '@send-legacy-app/history';
import { Cache } from 'aws-amplify';
import { PD_2160_VRN_CREATION } from '@sendpayments/shared-constants/features';
import { getHubUrl } from '@send-services/environment';

const SUserMenu = ({ registration }) => {
  const profilePhotoUrl = useProfilePhoto(registration.profilePhoto);
  const [, doLogout] = useLogout();

  const { firstName, lastName } = registration || {};

  const acceptedStatuses = ['Registered', 'Dealing'];

  const registeredUser = registration && acceptedStatuses.some((status) => status === registration?.status);
  const isActiveUser = registration && registration.status && registration.status !== 'Inactive';

  const [
    {
      data: { isEnabled: vrnCreationEnabled },
    },
  ] = useFeature({
    feature: PD_2160_VRN_CREATION,
    functionName: 'VRNCreationFunction',
    activeValue: undefined,
    defaultValue: undefined,
  });

  const renderUserInfoItem = () => {
    return isActiveUser ? (
      <Menu.Item className="non-clickable tertiary no-margin-bottom user-send user-info-item no-hover">
        <Avatar size={28} className="user-end-avatar" src={profilePhotoUrl} icon="user" />
        <div className="user-send-name">
          <Typography.Text strong>{[firstName, lastName].join(' ')}</Typography.Text>
          {registration.sendId && (
            <Typography.Text type="secondary" className="send-id-label">
              Send ID {registration.sendId}
            </Typography.Text>
          )}
        </div>
      </Menu.Item>
    ) : (
      registration && registration.sendId && (
        <Menu.Item className="non-clickable user-send">
          Send ID
          <b className="send-id">{registration.sendId}</b>
        </Menu.Item>
      )
    );
  };

  const renderDocumentUploadsItem = () => {
    return (
      isActiveUser && (
        <Menu.Item className="tertiary no-margin-bottom" onClick={() => history.push('/document')}>
          <Icon type="cloud-upload" />
          Documents
        </Menu.Item>
      )
    );
  };

  return (
    <Menu selectedKeys={[]} className="user-menu">
      {renderUserInfoItem()}

      {isActiveUser && (
        <Menu.Item className="tertiary no-margin-bottom" onClick={() => history.push('/setting/profile')}>
          <Icon type="user" />
          My account
        </Menu.Item>
      )}

      {/*VRN redirect*/}
      {vrnCreationEnabled && registeredUser && (
        <Menu.Item
          key="banking"
          className="tertiary banking"
          onClick={() => window.location.assign(getHubUrl() + '/banking-information')}
        >
          <Icon type="bank" className="bank-outlined" />
          Banking Information
        </Menu.Item>
      )}

      {renderDocumentUploadsItem()}

      <Menu.Item
        key="logout"
        className="tertiary logout"
        onClick={async () => {
          await clearSessionData();
          await doLogout();

          history.push('/auth');
        }}
      >
        <Icon type="poweroff" />
        Logout
        <Icon type="right" className="icon-right" />
      </Menu.Item>
    </Menu>
  );
};

SUserMenu.propTypes = {
  registration: PropTypes.object,
};

SUserMenu.defaultProps = {
  registration: undefined,
};

export default SUserMenu;

async function clearSessionData() {
  // clear amplify cache
  Cache.clear();

  // clear local storage
  localStorage.clear();

  // clear subdomain scoped cookies
  var cookies = document.cookie.split('; ');
  for (var c = 0; c < cookies.length; c++) {
    var d = window.location.hostname.split('.');
    while (d.length > 0) {
      var cookieBase =
        encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
        '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
        d.join('.') +
        ' ;path=';
      var p = location.pathname.split('/');
      document.cookie = cookieBase + '/';
      while (p.length > 0) {
        document.cookie = cookieBase + p.join('/');
        p.pop();
      }
      d.shift();
    }
  }
}
