import React from 'react';
import { Text, Button, Box } from '@chakra-ui/react';
import { GoogleIcon, FacebookIcon, MailIcon } from '@sendpayments/react-shared/components/Icons';
import { AuthPiece, IAuthPieceState } from 'aws-amplify-react/lib-esm/Auth/AuthPiece';
import { ISignUpProps } from 'aws-amplify-react/lib-esm/Auth/SignUp';

export interface WelcomeBackProps {
  changeState: AuthPiece<ISignUpProps, IAuthPieceState>['changeState'];
  isWelcome: IsWelcomeType;
  onSocialLogin: (argu: 'Google' | 'Facebook') => void;
  setIsWelcome: (argu) => void;
  page: 'register' | 'signin';
}

interface IsWelcomeType {
  source?: 'facebook' | 'google';
  email?: string;
}

export const WelcomeBackForm: React.FC<WelcomeBackProps> = ({ changeState, isWelcome, onSocialLogin, setIsWelcome, page }) => {
  const hiddenEmail = (email) => {
    const emailArr = email.split('@');
    const firstPartEmail = emailArr[0];
    const newFirstPart = firstPartEmail.split('').map((char, index) => {
      if (index === 0 || index === firstPartEmail.length - 1) {
        return char;
      } else {
        return '*';
      }
    });

    return `${newFirstPart.join('')}@${emailArr[1]}`;
  };

  return (
    <>
      <Text align="center" fontSize="md">
        <Text as="span" display="flex" justifyContent="center" alignItems="center" mt="6" mb="6">
          <MailIcon />
          <Box ml="8px">{hiddenEmail(isWelcome.email)}</Box>
        </Text>
        {isWelcome.source === 'google' ? (
          <Button variant="social" onClick={() => onSocialLogin('Google')} width="100%" maxWidth="340px" height="48px">
            <Box w="5%" ml="8px" mr={{ base: '0px', md: '10px' }}>
              <GoogleIcon width="18" height="18" />
            </Box>
            <Box
              position="relative"
              right="5%"
              w={{ base: '100%', md: '95%' }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              Continue with Google
            </Box>
          </Button>
        ) : (
          <Button variant="social" onClick={() => onSocialLogin('Facebook')} width="100%" maxWidth="340px" height="48px">
            <Box w="5%" ml="8px" mr={{ base: '0px', md: '10px' }}>
              <FacebookIcon width="18" height="18" />
            </Box>
            <Box
              position="relative"
              right="5%"
              w={{ base: '100%', md: '95%' }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              Continue with Facebook
            </Box>
          </Button>
        )}

        <Text fontSize="md" lineHeight="20px" mt="6" mb="4">
          Not you?
          <Text
            as="a"
            fontWeight="semibold"
            color="primary.base"
            onClick={() => {
              changeState('signIn');
              if (page === 'signin') {
                setIsWelcome({});
              }
            }}
          >
            {` Use another account`}
          </Text>
        </Text>
      </Text>
    </>
  );
};
