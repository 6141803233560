import * as queries from '@sendpayments/graphql/queries';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
import { query } from '@send-base/utilities/endpoint';
import { buildUniqueKey } from '@sendpayments/js-utils/dist/services/build-unique-key';
import { useRemoteData } from '@sendpayments/react-shared/hooks/useRemoteData';

const fetchMarketingSettings = async ({ utmSource }) => {
  if (!utmSource || utmSource === '') {
    return {};
  }

  const setting = await query(queries.getCoconut, {
    params: { id: utmSource, type: CoconutTypes.MARKETING_SETTINGS },
  });

  return setting;
};

const useMarketingSettings = ({ utmSource }) =>
  useRemoteData(
    () => ({
      key: buildUniqueKey('queries.getCoconut', { id: utmSource, type: CoconutTypes.MARKETING_SETTINGS }),
      onInitialFetch: () => fetchMarketingSettings({ utmSource }),
      initialState: {},
    }),
    [utmSource],
  );

export default useMarketingSettings;
