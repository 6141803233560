import { Box, BoxProps, Text } from '@chakra-ui/react';
import * as React from 'react';

export type CurrencyWidgetBankComparisonProps = {
  text: string;
};

export const CurrencyExchangeBankComparison = (props: CurrencyWidgetBankComparisonProps & BoxProps) => {
  const { text, ...delegatedProps } = props;
  return (
    <Box {...delegatedProps}>
      <Text fontSize={'sm'} fontWeight={'semibold'} mb={2}>
        Save up to
      </Text>
      <Text color="primary.base" fontSize={'3xl'} fontWeight={'semibold'}>
        {text}*
      </Text>
    </Box>
  );
};
