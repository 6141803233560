import React, { ReactNode } from 'react';
//@ts-ignore
import { CurrencyFlagCircular } from '@sendpayments/react-shared/components/CurrencyFlag';
import { Flex, Box, Text } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

interface CurrencyListItem {
  value: string;
  label: string;
  currencyCode: string;
}

export interface ListItemsWithFlagsProps {
  open: boolean;
  items: CurrencyListItem[];
  selectedItem: string;
  onSelect: (selectedItem: string) => void;
  props?: ReactNode;
}

export const ListItemsWithFlags: React.FC<ListItemsWithFlagsProps> = ({ open, items, selectedItem, onSelect, ...props }) => {
  return (
    <Flex
      flexDirection="column"
      border="1px solid"
      borderColor="border.base"
      borderRadius="4px"
      display={open ? 'block' : 'none'}
      boxShadow="md"
      zIndex={1}
      mt={3}
      backgroundColor="white"
      gridRowStart={2}
      position="absolute"
      h={'300px'}
      overflowY={'scroll'}
      {...props}
    >
      {items.map((item, index: number) => {
        return (
          <Flex
            _hover={{ cursor: 'pointer', backgroundColor: 'border.light' }}
            h="40px"
            key={`${index}-item.value`}
            flexDirection="row"
            onClick={() => onSelect(item.value)}
            pt={6}
            pb={6}
          >
            <Box p={2} alignSelf="center" ml={1}>
              <CurrencyFlagCircular currencyName={item.currencyCode} />
            </Box>
            <Text alignSelf="center" ml={1} fontWeight="bold" fontSize="md">
              {item.value}
            </Text>
            <Box alignSelf="center" ml={2} mr={{ base: 1, sm: 10 }} fontSize="md">
              {item.label}
            </Box>
            {selectedItem === item.value ? (
              <CheckIcon h={10} display="flex" fontSize="lg" m={3} alignSelf="center" color="secondary.base" w="15px" />
            ) : (
              <Box w="15px" m={3} h={10}></Box>
            )}
          </Flex>
        );
      })}
    </Flex>
  );
};
