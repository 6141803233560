/* eslint-disable no-unused-vars */
import React from 'react';
import { Flex, View } from '@adobe/react-spectrum';
import { withTheme } from '@sendpayments/react-shared/components/ThemeProvider';
import styles from './InfoPanel.module.less';

const InfoPanel = ({ items = [], children, ...props }) => {
  return (
    <View {...props}>
      <div className={styles.container}>
        <Flex direction="column" gap="size-125">
          {children}
        </Flex>
      </div>
    </View>
  );
};

InfoPanel.displayName = 'InfoPanel';

export default withTheme(InfoPanel);
