import React from 'react';
import styles from './SectionGroup.module.less';

const SectionGroup = (props) => {
  const { title, children } = props;

  return (
    <>
      {title && <div className={styles.title}>{title}</div>}
      {children}
    </>
  );
};

export default SectionGroup;
