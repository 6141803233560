import React from 'react';
import { Notifications } from '@send-legacy-components/Notifications';
import { useNotifications, useCurrentEmail } from '@send-data-hooks';
import { logger } from '@sendpayments/js-utils/dist/services/logger';
import { saveEntity } from '@sendpayments/js-utils/dist/services/entities';
import history from '@send-legacy-app/history';
import { CoconutTypes } from '@sendpayments/shared-constants/types';

const NotificationsContainer = () => {
  const [{ data: email }] = useCurrentEmail();

  const [{ data: notifications, loading }, updateNotifications] = useNotifications({ email });

  const onItemClickHandler = async ({ id }) => {
    const notification = notifications.find(({ id: idNotif }) => idNotif === id);
    if (notification && notification.link) {
      // only save when is needed
      if (notification.unread) {
        await saveEntity({ entity: { ...notification, unread: false }, type: CoconutTypes.CUSTOMER_NOTIFICATION });
        updateNotifications();
      }
      history.push('/' + notification.link);
    } else {
      logger.error(`we couldn't find the notification ${id}`);
    }
  };

  return <Notifications notifications={notifications} loading={loading} onItemClick={onItemClickHandler} />;
};

export default NotificationsContainer;
