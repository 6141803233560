import React from 'react';
import { InputField } from '@sendpayments/react-shared/components/molecules';
import { Box, FormLabel } from '@chakra-ui/react';
import { useField } from 'formik';

type BsbInput = {
  field: any;
  form: any;
  bankName: string;
  isBsbInvalid: boolean;
  isBsbLoading: boolean;
  mutate: (s: string) => void;
};

export const BsbInputField: React.FC<BsbInput> = ({ field, form, bankName, isBsbInvalid, isBsbLoading, mutate }) => {
  const [nouse, _, helpers] = useField(field.name);
  const formatBsb = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value: rawBsb },
    } = event;
    const updatedBsb = rawBsb.replace(/^(\d{3})(\d{3})$/, '$1-$2');
    helpers.setValue(updatedBsb);
  };
  return (
    <Box position="relative">
      <FormLabel fontWeight="normal" position="absolute" right="0">
        {bankName}
      </FormLabel>
      <InputField
        mb={3}
        id={field.name}
        label="BSB"
        placeholder="A 6-digit number located at the top of a bank statement"
        isInvalid={(!!form.errors[field.name] && form.touched[field.name]) || isBsbInvalid}
        isValid={!form.errors[field.name] && !!field.value && !!bankName}
        errorText={isBsbInvalid ? 'Please enter a valid BSB number' : form.errors[field.name]}
        inputProps={{
          ...field,
          type: 'text',
          size: 'md',
        }}
        onBlur={() => mutate(field.value)}
        onChange={formatBsb}
        isLoading={isBsbLoading}
      />
    </Box>
  );
};
