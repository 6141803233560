import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { AutoComplete, message, Col } from 'antd';
import { getAppConfig } from '@send-legacy-utils/appConfig';
import Script from 'react-load-script';

const appConfig = getAppConfig();

const getAddressFromGoogleApi = (address) => {
  if (address) {
    const data = {};
    for (let i = 0, iLen = address.address_components.length; i < iLen; i++) {
      if (address.address_components[i].types.includes('route')) {
        data.streetAddressName = address.address_components[i].long_name;
        appConfig.streetTypes.forEach((element) => {
          if (address.address_components[i].long_name.includes(element[0])) {
            // eslint-disable-next-line prefer-destructuring
            data.streetAddressType = element[0];
            data.streetAddressName = address.address_components[i].long_name.replace(element[0], '').trim();
          }
        });
      }
      if (address.address_components[i].types.includes('street_number')) {
        data.streetAddressNumber = address.address_components[i].short_name;
      }
      if (address.address_components[i].types.includes('locality')) {
        data.city = address.address_components[i].short_name;
      }
      if (address.address_components[i].types.includes('administrative_area_level_1')) {
        data.state = address.address_components[i].short_name;
      }
      if (address.address_components[i].types.includes('postal_code')) {
        data.postcode = address.address_components[i].short_name;
      }
      if (address.address_components[i].types.includes('country')) {
        data.country = address.address_components[i].long_name;
      }
    }
    return data;
  }
  return {};
};

// REVIEW THIS FILE, we are too tight to the google api.
// THIS FILE MUST BE REFACTORED!!!!
function AddressAutoComplete(props) {
  const sm = props.sm ? props.sm : props.span;
  const md = props.md ? props.md : sm;
  const { Option } = AutoComplete;
  const [results, setResults] = useState([]);
  const [resultsFeedback, setResultsFeedback] = useState('No results found');
  const { onAddressChanged } = props;

  let timer = null;

  const initService = (textInput) => {
    // safety check
    if (!textInput || (textInput && textInput.trim() === '')) {
      setResults([]);
      return;
    }

    const returnSuggestions = (predictions, status) => {
      // eslint-disable-next-line no-undef
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        // eslint-disable-next-line no-undef
        if (status !== google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
          message.error(status);
        }
        return;
      }

      setResults(predictions);
      if (predictions.length === 0) {
        setResultsFeedback('No results found');
      }
    };

    // eslint-disable-next-line no-undef
    const service = new google.maps.places.AutocompleteService();
    service.getQueryPredictions({ input: textInput, types: ['address'] }, returnSuggestions);
  };

  const changeFilter = (value) => {
    clearTimeout(timer);
    setResultsFeedback('Searching...');
    timer = setTimeout(() => initService(value.trim()), 1000);
  };

  const getDetails = (value) => {
    // safety check
    if (!value || (value && value.trim() === '')) {
      return;
    }

    const request = {
      placeId: value,
      fields: ['address_component'],
    };

    function callback(place, status) {
      // eslint-disable-next-line no-undef
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        props.setter(place);
        const address = getAddressFromGoogleApi(place);
        onAddressChanged(address);
      }
    }
    // eslint-disable-next-line no-undef
    const service = new google.maps.places.PlacesService(document.createElement('div'));
    service.getDetails(request, callback);
  };

  const options = results
    .filter((item) => !!item.place_id && !!item.description)
    .map((item) => (
      <Option key={item.place_id} label={item.description}>
        {item.description}
      </Option>
    ));

  return (
    <Col span={props.span} md={md} sm={sm}>
      <Script url="https://maps.googleapis.com/maps/api/js?key=AIzaSyAr7XoNnw5b1Kk4VaFCi3hFvoS3IA_WWMw&libraries=places" />
      <AutoComplete
        data-testid="addressSearch"
        onChange={changeFilter}
        dataSource={options}
        resultsFeedback={resultsFeedback}
        onSelect={getDetails}
        placeholder={props.label}
        style={{ width: '100%' }}
      />
    </Col>
  );
}

AddressAutoComplete.propTypes = {
  label: PropTypes.string,
  span: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  setter: PropTypes.func,
  onAddressChanged: PropTypes.func,
};

AddressAutoComplete.defaultProps = {
  span: 24,
  sm: 0,
  md: 0,
  label: 'Search for an address',
  setter: () => {},
  onAddressChanged: () => {},
};

export default AddressAutoComplete;
