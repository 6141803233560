import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isProd } from '@send-services/environment';
import { ContextDevTool } from 'react-context-devtool';
import { AppContext } from './Context';

function AppProvider({ children }) {
  const [, setLoadings] = useState([false]);
  const [loading, setOriginalLoading] = useState(false);
  const [showBubbles, setShowBubbles] = useState(true);
  const [allowedFields, setAllowedFields] = useState({});
  const [registration, setRegistration] = useState({});
  const [user, setUser] = useState(null);
  const [permissions, setPermissions] = useState({});
  const [groups, setGroups] = useState([]);

  // TODO review this what is this for?
  const setLoading = (value) => {
    if (value) {
      setLoadings((prev) => {
        const newLoadings = [...prev, value];
        setOriginalLoading(newLoadings.filter((item) => item).length > 0);
        return newLoadings;
      });
    } else {
      setLoadings((prev) => {
        if (prev.length) {
          const newLoadings = [...prev.slice(0, prev.length - 1)];
          setOriginalLoading(newLoadings.filter((item) => item).length > 0);
          return newLoadings;
        }
        return prev;
      });
    }
  };

  return (
    <AppContext.Provider
      value={{
        loading,
        setLoading,
        user,
        setUser,
        permissions,
        setPermissions,
        groups,
        setGroups,
        allowedFields,
        setAllowedFields,
        showBubbles,
        setShowBubbles,
        registration,
        setRegistration,
      }}
    >
      {isProd && <ContextDevTool context={AppContext} id="AppContext" displayName="AppContext" />}
      {children}
    </AppContext.Provider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
