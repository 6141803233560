import React, { useState } from 'react';
import { SMenu } from '@send-legacy-components/UI/SMenu';
import { Row, Layout, Divider } from 'antd';

import CustomLayout from '@send-legacy-components/Common/CustomLayout';
import CSSReset from './CSSReset';

const { Content } = Layout;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'chakra-scope': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Registration = any;

export interface NavProps {
  registration: Registration;
  activeItem: string;
  children?: React.ReactNode;
}

export const LegacyNav = ({ activeItem, registration, children }: NavProps) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <>
      {/* @ts-ignore onclick not defined */}
      <CustomLayout onClick={() => setCollapsed(true)} showSupportButton>
        <Content>
          <div className="container">
            <Row>
              <SMenu activeItem={activeItem} registration={registration} collapsed={collapsed} setCollapsed={setCollapsed} />
              <Divider className="top-menu-divider" />
              <CSSReset />
              <chakra-scope>{children}</chakra-scope>
            </Row>
          </div>
        </Content>
      </CustomLayout>
    </>
  );
};
