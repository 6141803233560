import React from 'react';
import { Icon } from '@send-legacy-components/Common/Icon';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import styles from './GroupedListView.module.less';
import { ListView } from '../ListView';

const GroupedListView = ({ dataSource, className, onRenderExpandedTemplate, onItemClick }) => (
  <div className={className}>
    {dataSource.map(({ groupName, groupTitle, groupDescription, groupIconType, list, config }) => {
      const onRenderExpandedTemplateHandler = (item) =>
        onRenderExpandedTemplate({ groupName, groupTitle, groupIconType, config, ...item });

      return (
        <Row key={groupName} className={`${groupName}-group`}>
          <Col>
            {(groupTitle || groupIconType) && (
              <div className={styles.group}>
                {groupIconType && <Icon type={groupIconType} />}
                {groupTitle && <span>{groupTitle}</span>}
              </div>
            )}
            {groupDescription && <div className={styles.groupDescription}>{groupDescription}</div>}
            <ListView
              dataSource={list.map((item) => ({ ...item, expandable: config.expandable, expandedIds: config.expandedIds }))}
              {...(config || {})}
              onItemClick={(item) => onItemClick(groupName, item)}
            >
              {config.expandable && onRenderExpandedTemplate && onRenderExpandedTemplateHandler}
            </ListView>
          </Col>
        </Row>
      );
    })}
  </div>
);

GroupedListView.propTypes = {
  className: PropTypes.string,
  onRenderExpandedTemplate: PropTypes.func,
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      groupName: PropTypes.string.isRequired,
      groupTitle: PropTypes.string,
      groupDescription: PropTypes.string,
      groupIconType: PropTypes.string,
      list: PropTypes.array.isRequired,
    }),
  ).isRequired,
  onItemClick: PropTypes.func,
};

GroupedListView.defaultProps = {
  className: undefined,
  onRenderExpandedTemplate: undefined,
  onItemClick: () => {},
};

export default GroupedListView;
