import * as React from 'react';
import { Button, Stack, Box } from '@chakra-ui/react';
import { PasswordField, InputField } from '@sendpayments/react-shared/components/molecules';
import { useToast } from '@sendpayments/react-shared/components/molecules/Toast';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { SendForm } from '../types';
import { logger } from '@sendpayments/js-utils/dist/services/logger';
import { analytics } from '@sendpayments/js-utils/dist';

const submitSchema = yup.object({
  code: yup.string().required('Verification code is required'),
  newPassword: yup
    .string()
    .min(8, 'Please create a password with at least 8 characters')
    .required('Please create your new password'),
});

type ResetPasswordData = yup.InferType<typeof submitSchema>;

export type ResetPasswordFormProps = SendForm<ResetPasswordData>;

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ onSubmit, isLoading }) => {
  const toast = useToast();
  return (
    <Box>
      <Formik
        initialValues={{ code: '', newPassword: '' }}
        validationSchema={submitSchema}
        onSubmit={async (values, actions) => {
          try {
            await onSubmit(values);
            analytics.push({ action: { type: 'form_submit', data: { name: 'ChangePasswordForm' } } });
          } catch (error) {
            logger.error('ChangePasswordForm', 'ERROR: ', error);
            actions.setSubmitting(false);
            toast({
              variant: 'negative',
              title: 'Something went wrong on our end. Please try again!',
            });
          }
        }}
      >
        {(props) => (
          // noValidate to disable normal html validator.
          <Form noValidate>
            <Stack spacing="3">
              {/* Email */}
              <Field name="code">
                {({ field, form }) => (
                  <InputField
                    id="code"
                    label="Verification code"
                    isInvalid={form.errors.code && form.touched.code}
                    errorText={form.errors.code}
                    inputProps={{
                      autoComplete: 'one-time-code',
                      size: 'md',
                      placeholder: 'Enter the verification code',
                      type: 'text',
                      ...field,
                    }}
                  />
                )}
              </Field>
              {/* Password */}
              <Field name="newPassword">
                {({ field, form }) => (
                  <PasswordField
                    id="newPassword"
                    label="New Password"
                    isInvalid={form.errors.newPassword && form.touched.newPassword}
                    errorText={form.errors.newPassword}
                    inputProps={{ autoComplete: 'new-password', size: 'md', placeholder: 'Enter your password', ...field }}
                  />
                )}
              </Field>
              <Button isLoading={props.isSubmitting && isLoading} type="submit" maxW="40" alignSelf="center" fontSize="md">
                Change Password
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
