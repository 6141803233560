import React from 'react';
import { Button as ButtonAntd, Icon, Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import { withAnalytics } from '@sendpayments/react-shared/HOC/withAnalytics';
import history from '@send-legacy-app/history';

const { Button } = withAnalytics({ Button: ButtonAntd });

const { Text } = Typography;

function RegSelectButton(props) {
  return (
    <div className="reg-button-div">
      <Col>
        <Row className="reg-button-row">
          <Button name={props.text} className="reg-button" onClick={() => history.push(props.destination)}>
            <Icon className="reg-button-icon" type={props.icon} style={{ lineHeight: 'unset', fontSize: 60 }} />
          </Button>
        </Row>
        <br />
        <Row className="reg-button-row">
          <Text className="reg-button-text">{props.text}</Text>
        </Row>
      </Col>
    </div>
  );
}

RegSelectButton.propTypes = {
  destination: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default RegSelectButton;
