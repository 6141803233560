import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const WarningIcon = (props: IconProps) => (
  <Icon height="20px" width="20px" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M10 3.3335L1.66669 18.1668H18.8334L10 3.3335Z" fill="#FBE074" />
    <g clipPath="url(#clip0_2548_525)">
      <path
        d="M18.9167 19H0.916709C0.738709 19 0.574709 18.906 0.484709 18.752C0.394709 18.598 0.393709 18.409 0.480709 18.254L9.48071 2.25402C9.56971 2.09702 9.73571 1.99902 9.91671 1.99902C10.0977 1.99902 10.2637 2.09602 10.3527 2.25402L19.3527 18.254C19.4397 18.409 19.4377 18.598 19.3487 18.752C19.2597 18.906 19.0947 19 18.9167 19ZM1.77171 18H18.0617L9.91671 3.52002L1.77171 18Z"
        fill="black"
      />
      <path
        d="M9.91669 14C9.64069 14 9.41669 13.776 9.41669 13.5V8.5C9.41669 8.224 9.64069 8 9.91669 8C10.1927 8 10.4167 8.224 10.4167 8.5V13.5C10.4167 13.776 10.1927 14 9.91669 14Z"
        fill="black"
      />
      <path
        d="M9.91669 17C9.64069 17 9.41669 16.776 9.41669 16.5V15.5C9.41669 15.224 9.64069 15 9.91669 15C10.1927 15 10.4167 15.224 10.4167 15.5V16.5C10.4167 16.776 10.1927 17 9.91669 17Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_2548_525">
        <rect width={20} height={20} fill="white" transform="translate(0.416687)" />
      </clipPath>
    </defs>
  </Icon>
);
