/* eslint-disable no-console */
let isLoggingEnabled = true;
let fullView = false;

const log = (method) => (functionName, text, object) => {
  if (isLoggingEnabled) {
    if (fullView) {
      return console[method](`${functionName} - ${text} - ${object ? JSON.stringify(object) : ''}`);
    }
    // eslint-disable-next-line prefer-template
    return console[method](`${functionName} - ${text} - ${object ? JSON.stringify(object).substring(0, 100) + '...' : ''}`);
  }
  return undefined;
};

const execute = (method) => log(method);

export const activateLogging = (value) => {
  isLoggingEnabled = value;
};

export const activateFullView = (value) => {
  fullView = value;
};

export const logger = {
  log: execute('log'),
  error: execute('error'),
  info: execute('info'),
};

window.logger = {
  activateLogging,
  activateFullView,
};

export default logger;
