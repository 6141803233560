import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Flex, View } from '@adobe/react-spectrum';
import { CollapseSection } from '@send-components/CollapseSection';
import { StatusBadge } from '@send-components/StatusBadge';
import { breakpoints } from '@sendpayments/react-shared/design-system';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { post } from '@send-base/utilities/endpoint';
import { InfoPanel, Item } from '@send-components/InfoPanel';
import { appConfig } from '@send-base/app.config';
import { PrimaryLink } from '@send-components/PrimaryLink';
import { VerifyCodeModal } from '@send-base/components/VerifyCodeModal';
import { triggerToast } from '@send-components/Toast';
import { logger } from '@sendpayments/js-utils/dist/services/logger';
import * as styles from './PhoneSetting.module.less';
import Info from '@spectrum-icons/workflow/Info';
import Alert from '@spectrum-icons/workflow/Alert';
import { UserAdmin } from '@send-components/Icons';
import { maskPhoneNumber } from '../utils';

const { internationalPhoneNumber, sendDealerHelpDeskUrl } = appConfig;

const getAccessToken = async () => {
  return (await Auth.currentSession()).getAccessToken().getJwtToken();
};

const formatPhoneNumber = ({ phoneCountryCode, phone }) => {
  return `${phoneCountryCode} ${phone && phone[0] === '0' ? phone.substr(1) : phone}`;
};

const PhoneSetting = ({ account, activeSection, sectionName, changeSectionActive }) => {
  const [isOpenVerifyModal, setIsOpenVerifyModal] = useState(false);
  const [isLoadingVerifyPhone, setIsLoadingVerifyPhone] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(account.phoneNumberVerified || false);
  const [codeDeliveryDetail, setCodeDeliveryDetail] = useState(null);
  const [modalError, setModalError] = useState('');
  const { isMedia } = useBreakpoint(breakpoints);
  const isMobile = isMedia({ to: breakpoints.xs });

  const verifyPhoneNumber = async () => {
    try {
      setIsLoadingVerifyPhone(true);
      const payload = {
        accessToken: await getAccessToken(),
        regType: account.type,
      };
      const verifyPhoneRes = await post('/profile/verifyPhone', {
        data: payload,
      });
      setCodeDeliveryDetail(verifyPhoneRes.data?.CodeDeliveryDetails);
      setIsOpenVerifyModal(true);
      setIsLoadingVerifyPhone(false);
    } catch (error) {
      logger.error('phoneSetting', 'verifyPhoneNumber', error);
      triggerToast({
        type: 'error',
        message: 'Error sending verification code. You may have sent too many recently. Please wait some minutes, and try again.',
        rightIcon: <Alert size="M" />,
      });
    }
  };

  const onCodeComplete = async (code) => {
    try {
      await post('/profile/verifyPhoneSubmit', {
        data: {
          accessToken: await getAccessToken(),
          regType: account.type,
          code,
        },
      });
      setIsOpenVerifyModal(false);
      setIsPhoneVerified(true);
      triggerToast({
        type: 'success',
        message: 'Your phone number has been verified',
        rightIcon: <Info size="M" />,
      });
    } catch (error) {
      logger.error('phoneSetting', 'verifyPhoneNumberSubmit', error);
      setModalError('The code entered doesn’t match the code sent or the code is expired. Please try again or resent the code.');
    }
  };

  const onVerifyCodeModalDismiss = () => {
    setIsOpenVerifyModal(false);
    setIsLoadingVerifyPhone(false);
    setModalError('');
  };

  const statusBadgeProps = () => {
    if (isPhoneVerified) {
      return { type: 'success', text: 'Verified' };
    }
    return { type: 'danger', text: 'Not verified' };
  };

  useEffect(() => {
    setIsPhoneVerified(account.phoneNumberVerified);
  }, [account]);

  return (
    <>
      <VerifyCodeModal
        isOpen={isOpenVerifyModal}
        title="We’ve just sent you an SMS"
        description={`To verify your new phone number, <strong>please enter the 6-digit security code we sent to ${codeDeliveryDetail?.Destination}.</strong>`}
        onCodeComplete={onCodeComplete}
        onResendCode={verifyPhoneNumber}
        onDismiss={onVerifyCodeModalDismiss}
        error={modalError}
      />
      <CollapseSection activeSection={activeSection} sectionName={sectionName} changeSectionActive={changeSectionActive}>
        <Flex direction={isMobile ? 'column' : 'row'} gap={isMobile ? 'size-40' : 'size-100'} alignItems="baseline">
          <span className={styles.itemTitle}>Phone</span>
          <StatusBadge {...statusBadgeProps()} />
        </Flex>
        <View>
          Your primary phone number is <strong>{maskPhoneNumber(formatPhoneNumber(account))}</strong>
          <Flex direction="column" marginTop={20} gap="size-100">
            {!isPhoneVerified && (
              <InfoPanel>
                <Item icon={Alert} isLoading={isLoadingVerifyPhone}>
                  <PrimaryLink onPress={verifyPhoneNumber} isDisabled={isLoadingVerifyPhone}>
                    Verify your new phone number
                  </PrimaryLink>{' '}
                  by entering the code we sent you via SMS.
                </Item>
              </InfoPanel>
            )}

            <InfoPanel>
              <Item icon={UserAdmin}>
                Please contact our support team on {internationalPhoneNumber} or{' '}
                <PrimaryLink>
                  <a href={sendDealerHelpDeskUrl} target="_blank" rel="noreferrer">
                    click here
                  </a>
                </PrimaryLink>
                , to change your primary phone number.
              </Item>
            </InfoPanel>
          </Flex>
        </View>
      </CollapseSection>
    </>
  );
};

export default PhoneSetting;
