import { Layout, Spin } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const { Content } = Layout;

export const BaseLayout = (props) => (
  <Spin spinning={props.loading}>
    <Layout>
      <Content>
        <div className="container">{props.children}</div>
      </Content>
    </Layout>
  </Spin>
);

BaseLayout.propTypes = {
  children: PropTypes.element.isRequired,
  loading: PropTypes.bool.isRequired,
};
