import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const PaperPlaneIcon = (props: IconProps) => (
  <Icon width="20px" height="20px" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M6.41663 11L1.91663 9.5L19.4166 2.5L14.4166 18L10.4166 14L7.41663 17L6.41663 11Z" fill="white" />
    <g clipPath="url(#clip0_2548_778)">
      <path
        d="M20.2496 1.12681C20.1056 0.997806 19.9006 0.963806 19.7226 1.03881L0.722631 9.0388C0.530631 9.1198 0.408631 9.3108 0.416631 9.5188C0.424631 9.7268 0.560631 9.9078 0.758631 9.9738L6.41663 11.8598V17.4998C6.41663 17.7118 6.54963 17.8998 6.74963 17.9708C6.80463 17.9898 6.86063 17.9998 6.91663 17.9998C7.06463 17.9998 7.20763 17.9338 7.30463 17.8148L10.0676 14.4138L14.5646 18.8548C14.6596 18.9488 14.7856 18.9988 14.9156 18.9988C14.9576 18.9988 14.9996 18.9938 15.0406 18.9828C15.2106 18.9388 15.3456 18.8088 15.3956 18.6398L20.3956 1.63981C20.4506 1.45481 20.3926 1.25481 20.2486 1.12581L20.2496 1.12681ZM16.5466 3.46081L6.82263 10.9408L2.33463 9.4448L16.5466 3.46081ZM7.41663 11.7458L16.8316 4.50381L9.63763 13.3578C9.63763 13.3578 9.63763 13.3588 9.63663 13.3588L7.41663 16.0918V11.7458ZM14.6726 17.5568L10.7006 13.6348L18.7336 3.74781L14.6726 17.5558V17.5568Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_2548_778">
        <rect width={20} height={20} fill="white" transform="translate(0.416626)" />
      </clipPath>
    </defs>
  </Icon>
);
