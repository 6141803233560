import * as React from 'react';
import {
  Modal,
  Heading,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Link,
  Center,
  FormControl,
  FormLabel,
  PinInput,
  PinInputField,
  Text,
  Stack,
  HStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { LockIcon } from '@sendpayments/react-shared/components/Icons';

export interface VerifyCodeModalProps {
  isOpen: boolean;
  onClose?: () => void;
  onSubmit: (code: string) => void;
  onResend: () => void;
  destination?: string;
  resendDelay?: number;
}

export const VerifyCodeModal: React.FC<VerifyCodeModalProps> = ({
  isOpen,
  onClose = () => null,
  onSubmit,
  onResend,
  destination,
  resendDelay = 180,
}: VerifyCodeModalProps) => {
  const [resendSeconds, setResendSeconds] = React.useState(resendDelay);
  const [isEnableResend, setIsEnableResend] = React.useState(false);
  const pinSize = useBreakpointValue({ base: 'md', md: 'lg' });

  // From legacy might need to be changed to use time so its more accurate
  React.useEffect(() => {
    if (!isEnableResend && isOpen) {
      const timer = setInterval(() => {
        setResendSeconds((val) => {
          const newVal = val - 1;
          if (newVal === 0) {
            clearInterval(timer);
            setIsEnableResend(true);
          }
          return newVal;
        });
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [isEnableResend, isOpen]);

  const handleResend = () => {
    setResendSeconds(resendDelay);
    setIsEnableResend(false);
    onResend();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent p={4}>
        <Center>
          <Stack textAlign="center" p={4}>
            <LockIcon color="text.base" mx="auto" boxSize={{ base: '57px', lg: '83px' }} />
            <Heading as="h3" variant="heading3" mt={4}>
              {"We've just sent you a SMS"}
            </Heading>
            <Text>{`To log in, please enter the 6-digit security code we sent to `}</Text>
            <Text fontWeight="semibold">{`${destination}.`}</Text>
            <ModalCloseButton />
            <ModalBody w="auto" maxW="100%" mt={8}>
              <FormControl>
                <FormLabel>Code</FormLabel>
                <HStack>
                  <PinInput placeholder="" autoFocus size={pinSize} onComplete={(code) => onSubmit(code)} otp>
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
              </FormControl>
            </ModalBody>

            <Stack>
              {isEnableResend && (
                <Link color="primary.base" fontSize="md" onClick={handleResend}>
                  Please resend the code
                </Link>
              )}
              <Text>
                <Text as="span">This code is valid for the next:</Text>
                <Text as="span" fontWeight="semibold">{` ${resendSeconds} seconds`}</Text>
              </Text>
            </Stack>
          </Stack>
        </Center>
      </ModalContent>
    </Modal>
  );
};
