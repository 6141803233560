import { getCurrentResource, getCurrentImagesPath } from '@send-legacy-themes/provider';

const labelResources = new Map();

const imagesResources = new Map();

const iconsResources = new Map();

const backgroundsResources = new Map();

const configs = new Map();

let resourceLoaded = false;

let themeImagesPath;

export const initializeResource = ({ images, labels, config, icons, backgrounds } = {}, { imagesPath } = {}) => {
  if (images) {
    Object.entries(images).forEach(([key, value]) => {
      imagesResources.set(key, value);
    });
  }

  if (labels) {
    Object.entries(labels).forEach(([key, value]) => {
      labelResources.set(key, value);
    });
  }

  if (icons) {
    Object.entries(icons).forEach(([key, value]) => {
      iconsResources.set(key, value);
    });
  }

  if (backgrounds) {
    Object.entries(backgrounds).forEach(([key, value]) => {
      backgroundsResources.set(key, value);
    });
  }

  if (config) {
    Object.entries(config).forEach(([key, value]) => {
      configs.set(key, value);
    });
  }

  if (imagesPath) {
    themeImagesPath = imagesPath;
  }
  resourceLoaded = true;
};

export const getLabelByKey = ({ key, default: defaultValue }) => {
  if (!resourceLoaded) {
    initializeResource(getCurrentResource(), { imagesPath: getCurrentImagesPath() });
  }
  return labelResources.get(key) || defaultValue;
};

export const getBackgroundByKey = ({ key, default: defaultValue }) => {
  if (!resourceLoaded) {
    initializeResource(getCurrentResource(), { imagesPath: getCurrentImagesPath() });
  }
  return backgroundsResources.get(key) || defaultValue;
};

export const getConfigByKey = ({ key }) => configs.get(key) || {};

export const getIconTypePropsByKey = ({ key }) => iconsResources.get(key) || {};

// TODO: review this to always have a type
const buildImageKey = (type, name) => (type && name ? `${type}-${name}` : `${name}`);

export const getImageSrc = ({ type, name }) => {
  const nameNoExtension = name.split('.')[0];

  const imageResource = imagesResources.get(buildImageKey(type, nameNoExtension));

  const typePath = type ? `${type}/` : '';

  if (imageResource) {
    return `/legacy/themes/${themeImagesPath}/${imageResource}`;
  }

  return `/static/${typePath}${name}`;
};
