import { useReducer } from 'react';
import { types } from './types';
import { reducer } from './reducer';

const useStepsContext = () => {
  const [steps, dispatch] = useReducer(reducer, {});

  const setStepIndex = ({ id, index }) => {
    if (!id) {
      throw Error('setStepIndex needs an id');
    }

    if (typeof index !== 'number') {
      throw Error('setStepIndex index needs to be a number');
    }

    dispatch({
      type: types.SET_STEP_INDEX,
      payload: {
        id,
        index,
      },
    });
  };

  const setStepData = ({ id, data }) => {
    if (!id) {
      throw Error('setStepIndex needs an id');
    }

    if (typeof data !== 'object' && !steps.data.current && !steps.data.next) {
      throw Error('setStepData data needs to be an object');
    }

    dispatch({
      type: types.SET_STEP_DATA,
      payload: {
        id,
        data,
      },
    });
  };

  const getStepIndex = ({ id }) => {
    if (!id) {
      throw Error('setStepIndex needs an id');
    }

    return steps[id] && steps[id].currentIndex;
  };

  const getStepData = ({ id }) => {
    if (!id) {
      throw Error('setStepIndex needs an id');
    }

    return steps[id] && steps[id].data;
  };

  const clearSteps = ({ id }) => {
    dispatch({
      type: types.CLEAR_STEPS,
      payload: {
        id,
      },
    });
  };

  const setStepResult = ({ id, index, result }) => {
    if (!id) {
      throw Error('setStepIndex needs an id');
    }

    if (typeof result !== 'object') {
      throw Error('setStepData data needs to be an object');
    }

    dispatch({
      type: types.SET_STEP_RESULT,
      payload: {
        id,
        index,
        result,
      },
    });
  };

  return [steps, { getStepIndex, setStepIndex, clearSteps, setStepData, getStepData, setStepResult }];
};

export default useStepsContext;
