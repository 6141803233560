import React from 'react';
import { Box, BoxProps, Icon, Text } from '@chakra-ui/react';

export const RecipientAlert: React.FC<BoxProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <Box {...rest} p={4} borderRadius="4px" bgColor="secondary.light" display="flex">
      <Icon mr="4" width="4" height="4" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346625 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0ZM6.7 2.388C6.69361 2.32148 6.70607 2.2545 6.73595 2.19473C6.76584 2.13495 6.81195 2.0848 6.869 2.05C7.22257 1.90637 7.59946 1.82875 7.981 1.821C8.35995 1.81631 8.73706 1.87443 9.097 1.993C9.15814 2.02686 9.209 2.07661 9.2442 2.13698C9.27941 2.19735 9.29765 2.26611 9.297 2.336V3.562C9.297 5.005 9.009 9.007 8.955 9.535C8.95448 9.60484 8.92629 9.67163 8.87662 9.72074C8.82695 9.76984 8.75985 9.79727 8.69 9.797H8.662H7.3C7.23146 9.79919 7.16443 9.7765 7.11131 9.73312C7.05819 9.68974 7.02256 9.6286 7.011 9.561C6.961 8.879 6.7 5.011 6.7 3.616V2.388ZM8 13.425C7.81927 13.441 7.6372 13.4186 7.46573 13.3593C7.29426 13.2999 7.13728 13.205 7.00508 13.0808C6.87287 12.9565 6.76844 12.8057 6.69863 12.6382C6.62882 12.4707 6.59521 12.2904 6.6 12.109C6.59709 11.9343 6.62861 11.7608 6.69278 11.5983C6.75694 11.4358 6.85248 11.2876 6.97394 11.1621C7.09541 11.0365 7.24041 10.9361 7.40068 10.8666C7.56095 10.7971 7.73334 10.7599 7.908 10.757C7.93867 10.757 7.96934 10.757 8 10.757C8.17266 10.7456 8.34587 10.7684 8.50973 10.824C8.67358 10.8796 8.82485 10.967 8.95489 11.0812C9.08492 11.1953 9.19117 11.334 9.26755 11.4893C9.34393 11.6445 9.38894 11.8133 9.4 11.986C9.4 12.0267 9.4 12.067 9.4 12.107C9.40509 12.2885 9.37169 12.4691 9.302 12.6368C9.23232 12.8045 9.12793 12.9556 8.9957 13.0801C8.86346 13.2046 8.70638 13.2997 8.53477 13.3591C8.36316 13.4186 8.18091 13.441 8 13.425Z"
          fill="#0097CE"
        />
      </Icon>
      {children}
    </Box>
  );
};
