import { appConfig } from '@send-base/app.config';
import { get } from '@sendpayments/js-utils/dist';
import { useEffect, useState } from 'react';

type Currency = {
  code: string;
  decimalPlaces: number;
  currencyName: string;
};

const getActiveCurrencies = async () => {
  const response = await get('/dealing/currencies');
  const ccCurrencies =
    response?.map((currency) => ({
      code: currency.code,
      decimalPlaces: currency.decimal_places,
      currencyName: `${currency.code} - ${currency.name}`,
    })) || [];

  return ccCurrencies;
};

interface Settings {
  currencies: Array<string>;
  blacklistedCurrencies: Array<string>;
}

/**
 * Useful hook to get all of the valid currencies that we can accept.
 *
 * @example
 * const [{ data: settings }] = useSettings();
 * const [{ data: currencies, loading: loadingCurrencies }] = useActiveCurrencies(settings);
 */
const useActiveCurrencies = (settings: Settings): [{ data: Array<Currency>; loading: boolean }] => {
  const [currenciesList, setCurrenciesList] = useState([]);

  const { currencies = [] } = settings || {};

  // This is an expensive call and so should be memoized in case some
  // cabron puts 30 hooks in one component (see Recipient/Form)
  useEffect(() => {
    if (currencies?.length) {
      getActiveCurrencies().then((ccCurrencies) => {
        const c = ccCurrencies.filter(
          ({ code }) =>
            !!currencies.find((ccCurrency) => ccCurrency.indexOf(code) === 0) && !appConfig.disabledCurrencies.includes(code),
        );
        setCurrenciesList(c);
      });
    }
  }, [currencies]);

  return [{ data: currenciesList, loading: !currenciesList.length }];
};

export default useActiveCurrencies;
