import React, { useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { post } from '@send-base/utilities/endpoint';
import { ReviewAmountView } from '@send-legacy-components/DealerAssist';
import { getFromAmount, getToAmount } from '@send-legacy-containers/ExchangeCurrency';
import { findAllowedDecimalPlaces } from '@sendpayments/js-utils/dist/utils/currency';
import { withDecimals } from '@sendpayments/js-utils/dist/utils/numbers';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
import { Confirmation, useConfirmation } from '@send-legacy-components/Common/Confirmation';
import { HyperlinkNewTab } from '@send-legacy-components/Common/HyperLink';
import { appConfig } from '@send-base/app.config';
import { useRate, useActiveCurrencies, useConversion, useRegistration, useSettings } from '@send-data-hooks';
import { logger } from '@sendpayments/js-utils/dist/services/logger';

const ReviewAmount = forwardRef(
  (
    { parentId, parentType, onEditClick, fixedAmount, fromAmount, fromCurrency, toAmount, toCurrency, rate, invertedRate },
    ref,
  ) => {
    const [
      {
        data: {
          rate: updatedRate,
          invertedRate: updatedInvertedRate,
          quoteId: updatedQuoteId,
          conversionDate: updatedConversionDate,
        },
      },
      { releaseRate },
    ] = useRate({
      fromCurrency,
      toCurrency,
    });

    const [
      isAmountConfirmationVisible,
      {
        asAwaitConfirmation: { awaitConfirmationResponse, onConfirmCallback, onCancelCallback },
      },
    ] = useConfirmation();

    const [{ data: settings }] = useSettings();
    const [{ data: currenciesConfig }] = useActiveCurrencies(settings);
    const [{ data: reg }] = useRegistration();
    const fromDecimalPlaces = findAllowedDecimalPlaces(fromCurrency, { currenciesConfig });
    const toDecimalPlaces = findAllowedDecimalPlaces(toCurrency, { currenciesConfig });
    const [, { partialCreateOrUpdateEntity }] = useConversion({});

    const calculatedRate = rate && updatedRate && rate !== updatedRate ? updatedRate : rate;
    const calculatedInvertedRate =
      invertedRate && updatedInvertedRate && invertedRate !== updatedInvertedRate ? updatedInvertedRate : invertedRate;
    const calculatedToAmount = getToAmount(fixedAmount, { fromAmount, toAmount, rate: calculatedRate, toDecimalPlaces });
    const calculatedFromAmount = getFromAmount(fixedAmount, {
      fromAmount,
      toAmount,
      invertedRate: calculatedInvertedRate,
      fromDecimalPlaces,
    });

    useImperativeHandle(ref, () => ({
      onSave: async (_, stepsDataAsMap = {}) => {
        try {
          const { amount } = stepsDataAsMap;

          await awaitConfirmationResponse();

          const changesToSend = {
            ...(amount || {}),
            fixedSide: fixedAmount === 'toAmount' ? 'buy' : 'sell',
            toAmount: withDecimals(toAmount, toDecimalPlaces),
            fromAmount: withDecimals(fromAmount, fromDecimalPlaces),
          };

          // if the rate changed, update the references
          if (updatedRate) {
            changesToSend.rate = updatedRate;
            changesToSend.quoteId = updatedQuoteId;
            changesToSend.conversionDate = updatedConversionDate;
            changesToSend.toAmount = withDecimals(calculatedToAmount, toDecimalPlaces);
            changesToSend.fromAmount = withDecimals(calculatedFromAmount, fromDecimalPlaces);
          }

          const conversion = {
            quoteId: changesToSend.quoteId,
            bookedBuyAmount: changesToSend.toAmount,
            bookedSellAmount: changesToSend.fromAmount,
            buyCurrency: changesToSend.toCurrency,
            sellCurrency: changesToSend.fromCurrency,
            conversionDate: changesToSend.conversionDate,
            fixedSide: changesToSend.fixedSide,
            parentId,
            parentType,
          };

          const { id, status } = await post('/dealing/customer/create-conversion', {
            data: conversion,
          });

          /* eslint-disable no-console */
          console.log('conversion', conversion);

          const resultConversion = {
            id,
            status,
            type: CoconutTypes.CONVERSION,
            ...conversion,
          };

          partialCreateOrUpdateEntity(resultConversion);

          // release any reference to this rate since we have a conversion now.
          releaseRate();

          return resultConversion;
        } catch (ex) {
          if (ex.isConfirmed === false) {
            return undefined;
          }

          logger.error('@ReviewAmount', 'Unable to confirm transfer.', ex);
          throw new Error('Unable to confirm transfer.');
        }
      },
    }));

    if (!rate) {
      return null;
    }

    return (
      <>
        {isAmountConfirmationVisible && (
          <Confirmation
            title="Confirm Your Transfer"
            bodyText="Do you want to proceed with this transfer?"
            bodySubText={
              <p>
                By clicking &apos;Confirm&apos;, you&apos;re entering a contract and agreeing to our{' '}
                <HyperlinkNewTab url={appConfig.sendTermsAndConditionsUrl}>Terms & Conditions</HyperlinkNewTab>. You&apos;ll
                select a recipient for your transfer in the next step.
              </p>
            }
            okButtonText="Confirm"
            onOkClick={onConfirmCallback}
            onCancelClick={onCancelCallback}
            cancelButtonText="Back"
          />
        )}
        <ReviewAmountView
          onEditClick={onEditClick}
          fromAmount={calculatedFromAmount}
          fromCurrency={fromCurrency}
          toAmount={calculatedToAmount}
          toCurrency={toCurrency}
          rate={updatedRate || rate}
          isRisingRate={!!(updatedRate && rate && updatedRate > rate)}
          isEqualRate={!updatedRate || !rate || updatedRate === rate}
        />
      </>
    );
  },
);

ReviewAmount.propTypes = {
  fromAmount: PropTypes.number,
  fromCurrency: PropTypes.string,
  toAmount: PropTypes.number,
  toCurrency: PropTypes.string,
  rate: PropTypes.number,
  invertedRate: PropTypes.number,
  onEditClick: PropTypes.func.isRequired,
  parentId: PropTypes.string,
  parentType: PropTypes.string,
  fixedAmount: PropTypes.string,
};

ReviewAmount.defaultProps = {
  fromAmount: undefined,
  fromCurrency: undefined,
  toAmount: undefined,
  toCurrency: undefined,
  rate: undefined,
  invertedRate: undefined,
  parentId: undefined,
  parentType: undefined,
  fixedAmount: 'fromAmount',
};

export default ReviewAmount;
