import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Layout, Row, Button, Input, Form, Col, Alert } from 'antd';
import { ConfirmSignUp } from 'aws-amplify-react';
import Auth from '@aws-amplify/auth';
import { ThemedImage } from '@send-legacy-components/Common';
import { getLabelByKey } from '@send-services/resources';
import SideImage from './SideImage';
import { SFooter } from '../UI';
import { VerifyEmailPage } from '../../../atomic-components/pages';
import { analytics } from '@sendpayments/js-utils/dist';

const { Content } = Layout;

// TODO create a new resource section to configuration using label for now
const showSideImage = getLabelByKey({ key: 'show_side_image', default: 'true' }) === 'true';

/**
 * @deprecated Please remove when legacy login not needed
 * @param {import('@sendpayments/react-shared/components/molecules/AlertBox/AlertBox').AlertBoxProps["variant"]} type
 */
function convertAlertBoxTypeToAntType(type) {
  if (type === 'neutral') {
    // For this use case, only success box is themed properly.
    return 'success';
  }
  if (type === 'positive') {
    return 'success';
  }
  if (type === 'negative') {
    return 'error';
  }
  return type;
}

class ConfirmSignUpComponent extends ConfirmSignUp {
  constructor(props) {
    super(props);
    // TODO: use enum to get alert text, do not store in state
    this.state = {
      alert: {
        title: "We've sent a verification code to ${this.username}.",
        description: 'Please enter it below to verify your email.',
        type: 'neutral',
      },
      verified: false,
      btnLabel: 'Verify',
    };
    this.confirmSignUp = this.confirmSignUp.bind(this);
  }

  /**
   * Workaround to populate the username as we do not have access in the constructor
   * @deprecated fix this hack up when decommissioning the old code
   */
  fillTemplate(templateString, templateVars) {
    if (!templateString) {
      return '';
    }
    return new Function('return `' + templateString + '`;').call(templateVars);
  }

  confirmSignUp(username, code) {
    this.setState({ loading: true });
    const { password, forgotPassword } = this.props.authData;
    const changeStateToSignedUp = () => {
      this.setState({
        alert: {
          title: 'Thank you, your email has been verified. You will now be automatically logged in.',
          type: 'neutral',
        },
        verified: true,
        btnLabel: 'Logging in',
        loading: true,
      });
      Auth.signIn(username, password).then((user) => {
        this.changeState('signedIn', user);
      });
    };

    if (forgotPassword) {
      Auth.forgotPasswordSubmit(username, code, password)
        .then(() => changeStateToSignedUp())
        .catch(() => {
          this.setState({
            alert: {
              description: 'Verification code is incorrect or expired',
              type: 'negative',
            },
          });
          this.setState({ loading: false });
        });
    } else {
      Auth.confirmSignUp(username, code)
        .then(() => changeStateToSignedUp())
        .catch(() => {
          this.setState({
            alert: {
              description: 'Verification code is incorrect or expired',
              type: 'negative',
            },
          });
          this.setState({ loading: false });
        });
    }
  }

  confirm() {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return;
      }
      let { code } = values;
      code = code.trim();
      const username = values.username.toLowerCase().trim();
      this.confirmSignUp(username, code);
      analytics.push({ action: { type: 'form_submit', data: { name: 'RegisterForm' } } });
    });
  }

  async resend() {
    const username = this.usernameFromAuthData().toLowerCase().trim();
    await Auth.resendSignUp(username)
      .then(() =>
        this.setState({
          alert: {
            description: 'Verification code resent',
            type: 'neutral',
          },
        }),
      )
      .catch((error) => this.error(error));
  }

  showComponent() {
    const username = this.usernameFromAuthData();

    return (
      <VerifyEmailPage
        username={username}
        resendVerificationCode={this.resend}
        confirmSignUp={this.confirmSignUp}
        alertBox={{
          title: this.fillTemplate(this.state.alert?.title, { username }),
          description: this.state.alert?.description,
          variant: this.state.alert?.type,
        }}
      />
    );
  }
}

ConfirmSignUpComponent.propTypes = {
  form: PropTypes.object.isRequired,
  isNewProfile: PropTypes.bool,
};

const ConfirmSignUpForm = Form.create({ name: 'beneficiary_in_modal' })(ConfirmSignUpComponent);
export default ConfirmSignUpForm;
