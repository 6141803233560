import React from 'react';
import { Text, Box, keyframes, usePrefersReducedMotion, Tooltip, BoxProps } from '@chakra-ui/react';
import { RefreshIcon } from '@sendpayments/react-shared/components/Icons/RefreshIcon';

export interface TransferRateProps extends BoxProps {
  isLoading: boolean;
  isAnimationRunning: boolean;
  animationSpeed?: number;
  rate: number;
}

const label =
  'This amount is based on the market rate. As live rates vary minute to minute, the amount indicated here may differ to your final transfer amount.';

export const TransferRate: React.VFC<TransferRateProps> = (props) => {
  const { rate, isLoading, isAnimationRunning, animationSpeed = 5, ...delegatedProps } = props;
  const prefersReducedMotion = usePrefersReducedMotion();
  const animation = prefersReducedMotion ? undefined : `${spin} infinite ${animationSpeed}s linear`;

  return (
    <Wrapper {...delegatedProps}>
      <Tooltip hasArrow placement="top" label={label}>
        <Box>
          <RefreshIcon
            color="primary.base"
            w={8}
            h={8}
            mr="8px"
            animation={animation}
            css={{ animationPlayState: isAnimationRunning ? 'running' : 'paused' }}
          />
          {isLoading && <Text as="span">Rate loading</Text>}
          {!isLoading && (
            <>
              <Text as="span" mr="4px">
                Rate
              </Text>
              <Text as="span" fontWeight="bold">
                {rate}
              </Text>
            </>
          )}
        </Box>
      </Tooltip>
    </Wrapper>
  );
};

const Wrapper = (props: BoxProps) => <Box display={'flex'} alignItems="center" role="group" cursor={'pointer'} {...props} />;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
