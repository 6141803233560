import React from 'react';
import { PrimaryButton as SpectrumPrimaryButton } from '@sendpayments/react-shared/components/buttons/PrimaryButton';

const PrimaryButton = (props) => (
  <SpectrumPrimaryButton
    UNSAFE_style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 600, fontSize: '12px', height: '36px' }}
    {...props}
  />
);

export default PrimaryButton;
