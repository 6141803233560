import { ListView } from '@send-legacy-components/Common/ListView';
import React from 'react';
import PropTypes from 'prop-types';
import { listViewReferenceTypes as listTypes } from '@send-legacy-components/Common';
import { paymentActivityStatus } from '@send-const/statuses';
import classNames from 'classnames';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import styles from './Activities.module.less';

const getReferenceType = (status) => {
  if (status === paymentActivityStatus.CANCELLED || status === paymentActivityStatus.FAILED) {
    return listTypes.ERROR;
  }
  if (status === paymentActivityStatus.COMPLETED) {
    return listTypes.SUCCESS;
  }
  return listTypes.WARNING;
};

const getDescription = ({ accountNumber, iban }) =>
  accountNumber || iban
    ? `${accountNumber ? 'Account' : 'IBAN'} ending in ${(accountNumber || iban).substring(
        (accountNumber || iban).length - 4,
        (accountNumber || iban).length,
      )}`
    : '';

const Payment = ({ pay }) => {
  const item = {
    id: pay.id,
    iconType: 'send-arrow',
    header: `${pay.amount.toLocaleString()} ${pay.currency} to ${pay.recipientName}`,
    description: getDescription(pay),
    reference: {
      type: getReferenceType(pay.status),
      text: pay.status === 'failed' ? 'Payment Unsuccessful' : pay.status,
    },
    clickable: false,
    expandable: pay.status === paymentActivityStatus.INFO_NEEDED,
  };

  const onRenderExpandedTemplateHandler = () => (
    <Link to="/document">
      <Button className={styles.uploadNowBtn} type="secondary">
        Upload Now
      </Button>
    </Link>
  );

  return (
    <ListView
      className={
        pay.status === paymentActivityStatus.INFO_NEEDED ? classNames(styles.payment, styles.activityHighlight) : styles.payment
      }
      dataSource={[item]}
      expandedIds={[item.id]}
    >
      {onRenderExpandedTemplateHandler}
    </ListView>
  );
};

Payment.propTypes = {
  pay: PropTypes.object.isRequired,
};

export default Payment;
