import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row } from 'antd';
import { Icon, sendIconTypes } from '@send-legacy-components/Common/Icon';
import { getCurrencyString } from '@sendpayments/js-utils/dist/utils/currency';
import { CurrencyFlag } from '@sendpayments/react-shared/components/CurrencyFlag';
import styles from './View.module.less';

const ReviewAmountView = ({ onEditClick, fromAmount, fromCurrency, toAmount, toCurrency, rate, isRisingRate, isEqualRate }) => (
  <Row className={styles.container}>
    <div className={styles.title}>
      <span>
        <Icon type={sendIconTypes.SWAP} />
        <span className={styles.mainTitle}>Transfer Details </span>
      </span>
      <span className={styles.link} onClick={onEditClick}>
        Edit transfer
      </span>
    </div>
    <div className={classNames(styles.reviewAmount, styles.reviewAmountBorder)}>
      <div className={styles.containerRounded}>
        <Row className={styles.firstBlock}>
          <div className={styles.fieldTitle}>You send</div>
          <div className={classNames(styles.fieldValue, styles.flag)}>
            <CurrencyFlag currencyName={fromCurrency} />
            <span className={styles.amount}>{getCurrencyString({ amount: fromAmount, currency: fromCurrency })}</span>
          </div>
        </Row>
        <Row>
          <div className={styles.fieldTitle}>Recipient gets</div>
          <div className={classNames(styles.fieldValue, styles.flag)}>
            <CurrencyFlag currencyName={toCurrency} />
            <span className={styles.amount}>{getCurrencyString({ amount: toAmount, currency: toCurrency })}</span>
          </div>
        </Row>
      </div>
    </div>
    <div className={styles.reviewRate}>
      <div className={styles.containerRounded}>
        <Row className={styles.firstBlock}>
          <div className={styles.fieldTitle}>Rate</div>
          <div className={classNames(styles.fieldValue, styles.showRateIcon)}>
            {rate}{' '}
            {!isEqualRate && (
              <div className={classNames(isRisingRate ? styles.rise : styles.fall)}>
                <Icon type={isRisingRate ? sendIconTypes.RISE : sendIconTypes.FALL} />
              </div>
            )}
          </div>
        </Row>
        <Row>
          <div className={classNames(styles.fieldTitle, styles.secondTitle)}>Transfer Fee</div>
          <div className={styles.fieldValue}>None</div>
        </Row>
      </div>
    </div>
  </Row>
);

ReviewAmountView.propTypes = {
  fromAmount: PropTypes.number.isRequired,
  fromCurrency: PropTypes.string.isRequired,
  toAmount: PropTypes.number.isRequired,
  toCurrency: PropTypes.string.isRequired,
  rate: PropTypes.number.isRequired,
  onEditClick: PropTypes.func.isRequired,
  isRisingRate: PropTypes.bool,
  isEqualRate: PropTypes.bool,
};

ReviewAmountView.defaultProps = {
  isRisingRate: undefined,
  isEqualRate: true,
};

export default ReviewAmountView;
