import { Authenticator } from 'aws-amplify-react';
import { Redirect } from 'react-router-dom';
import { Auth, API } from 'aws-amplify';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
// import useInterval from 'use-interval';
import dayjs from 'dayjs';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
import * as queries from '@sendpayments/graphql/queries';
import { graphqlOperationEx } from '@sendpayments/graphql/fields';
import { useRegistration } from '@send-data-hooks';
import { SupportCard } from '@send-legacy-containers/SupportCard';
import { AppContext } from '@send-legacy-app/Context';
import CustomSignIn from '@send-legacy-components/Auth/SignIn';
import CustomSignUp from '@send-legacy-components/Auth/SignUp';
import CustomForgotPassword from '@send-legacy-components/Auth/ForgotPassword';
import CustomConfirmSignUp from '@send-legacy-components/Auth/ConfirmSignUp';
import CustomRequireNewPassword from '@send-legacy-components/Auth/RequireNewPassword';
import CustomVerifyContact from '@send-legacy-components/Auth/VerifyContact';
import { logger } from '@sendpayments/js-utils/dist';
import { getHubUrl } from '@send-services/environment';
import { useFeature } from '@sendpayments/react-shared/hooks/useFeature';
import { PD_2197_PORTAL_TO_HUB_AUTH_REDIRECT } from '@sendpayments/shared-constants/features';
import { useTrackingId } from '../../atomic-components/pages/RegisterPage/RegisterPage';

function AuthPage(props) {
  const { user, setUser } = useContext(AppContext);
  const [{ data: reg }] = useRegistration();
  const [isCheckingAuth, setIsCheckingAuth] = React.useState(true);
  const trackingId = useTrackingId();

  const [
    {
      data: { isEnabled: isHubAuthRedirectEnabled },
    },
  ] = useFeature({
    feature: PD_2197_PORTAL_TO_HUB_AUTH_REDIRECT,
    functionName: `PortalToHubAuthRedirect`,
    defaultValue: 'isLoading',
  });

  // If the default value is returned, we can assume the feature flags haven't been fetched yet
  const isLoadingFeatureFlags = isHubAuthRedirectEnabled === 'isLoading';

  /**
   * Little dirty temporary hack that we need to implement to ensure that the
   * TMT portal works even when we disable legacy login/registering and turn on
   * the hub login/registering
   */
  const isTravelMoneyTransfer =
    process.env.PROJECT === 'travel-money-transfers' || process.env.PROJECT_NAME === 'travel-money-transfers';

  useEffect(() => {
    if (isLoadingFeatureFlags) {
      return;
    }

    const checkAuth = async () => {
      try {
        await Auth.currentAuthenticatedUser();
      } catch {
        if (isHubAuthRedirectEnabled && !isTravelMoneyTransfer && !trackingId) {
          const isSignup = props.location.pathname === '/signup';
          const path = isSignup ? '/register' : '/auth';
          window.location.href = `${getHubUrl()}${path}${props.location.search}`;
          return; // prevent setIsCheckingAuth(false) from running to ensure we don't render the page before redirecting
        }
      }

      setIsCheckingAuth(false);
    };

    checkAuth();
  }, [isLoadingFeatureFlags, isHubAuthRedirectEnabled]);

  if (isCheckingAuth || isLoadingFeatureFlags) {
    return null;
  }

  // Affiliate ID to be saved at this point from
  // query param to cookie. Needs to be added to retail data
  // later to give (street) credit to referrer.
  if (props.affiliateId) {
    logger.log('Auth', 'setting affiliate', props.affiliateId);
    localStorage.setItem('affiliateId', props.affiliateId);
  }

  const redirect = props.location.search ? props.location.search.replace('?redirect=', '') : '/';

  // const email = user && user.attributes && user.attributes.email;

  // TODO Review this logic, this is quite old. move this to useRemoteData
  const authenticateUser = () => {
    props.setLoading(true);
    return Auth.currentAuthenticatedUser()
      .then((loggedInUser) => {
        props.setIsLoggedIn(true);
        if (
          !user ||
          user.attributes.email !== loggedInUser.attributes.email ||
          user.signInUserSession.accessToken.payload.exp - dayjs().unix() < 900
        ) {
          setUser(loggedInUser);

          API.graphql(
            graphqlOperationEx(queries.getCoconut, {
              type: CoconutTypes.PERMISSION,
              id: 'Customer',
            }),
          ).then((perms) => {
            let permissions = {};
            let allowed = {};
            const data = perms.data.getCoconut;
            if (data && data.permissions) {
              data.permissions.forEach((perm) => {
                const [component, action, scope] = perm.split('-');
                // Batch permissions
                if (!permissions[component]) {
                  permissions[component] = { [action]: scope };
                } else if (permissions[component][action] !== 'all') {
                  permissions[component][action] = scope;
                }
                // Batch allowed fields
                const allowedFields = data.allowedFields ? JSON.parse(data.allowedFields) : {};
                const fields = allowedFields[perm] || 'all';
                if (!allowed[component]) {
                  allowed[component] = { [action]: fields };
                } else if (scope === 'all' || !allowed[component][action]) {
                  allowed[component][action] = fields;
                }
              });

              props.setPermissions(permissions);
              props.setAllowedFields(allowed);
            }
            props.setPermissions(permissions);
            props.setAllowedFields(allowed);
          });
        }
      })
      .catch(() => props.setIsLoggedIn(false))
      .finally(() => props.setLoading(false));
  };

  const handleStateChange = (state) => {
    if (state === 'signedIn') {
      authenticateUser();
    }
  };

  const signUpConfig = {
    hideAllDefaults: true,
    signUpFields: [
      {
        label: 'Email',
        key: 'username',
        required: true,
        placeholder: 'Email',
        type: 'email',
        displayOrder: 1,
      },
      {
        label: 'Password',
        key: 'password',
        required: true,
        placeholder: 'Password',
        type: 'password',
        displayOrder: 2,
      },
      {
        label: 'Source',
        key: 'custom:source',
        required: true,
      },
      {
        label: 'PortalSource',
        key: 'custom:portalSource',
        required: true,
      },
      {
        label: 'TrackingId',
        key: 'custom:trackingId',
        required: false,
      },
    ],
  };

  return props.isLoggedIn && user ? (
    <Redirect to={redirect} />
  ) : (
    <>
      <Authenticator authState={props.state} hideDefault={true} onStateChange={handleStateChange}>
        <CustomSignIn />
        <CustomSignUp signUpConfig={signUpConfig} />
        <CustomForgotPassword />
        <CustomConfirmSignUp />
        <CustomRequireNewPassword />
        <CustomVerifyContact />
      </Authenticator>
      <SupportCard supportUsername={reg.accountManager} />
    </>
  );
}

AuthPage.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  affiliateId: PropTypes.string,
  setIsLoggedIn: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setGroups: PropTypes.func.isRequired,
  setPermissions: PropTypes.func.isRequired,
  setAllowedFields: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  state: PropTypes.string,
};

AuthPage.defaultProps = {
  state: 'signIn',
};

export default AuthPage;
