import React from 'react';
import PropTypes from 'prop-types';
import { NewRecipientForm } from '@send-legacy-components/DealerAssist';

const NewRecipient = ({ type, fixedCurrencyTo, onSave, onCancel }) => (
  <NewRecipientForm type={type} fixedCurrencyTo={fixedCurrencyTo} onSave={onSave} onCancel={onCancel} />
);

NewRecipient.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  type: PropTypes.string,
  fixedCurrencyTo: PropTypes.string,
};

NewRecipient.defaultProps = {
  type: undefined,
  fixedCurrencyTo: undefined,
};

export default NewRecipient;
