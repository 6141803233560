import * as React from 'react';
import { Box, Stack, Button } from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
// @ts-ignore should add d.ts file for this.
import { InputField } from '@sendpayments/react-shared/components/molecules';
import { useToast } from '@sendpayments/react-shared/components/molecules/Toast';
import * as yup from 'yup';
import { SendForm } from '../types';
import { analytics } from '@sendpayments/js-utils/dist';
import logger from '@sendpayments/js-utils/dist/services/logger';

const verifyEmailFormSchema = yup.object({
  verificationCode: yup.string().required('Please enter the verification code we just sent you via email'),
});

type VerifyEmailFormData = yup.InferType<typeof verifyEmailFormSchema>;

export type VerifyEmailFormProps = SendForm<VerifyEmailFormData>;

export const VerifyEmailForm: React.FC<VerifyEmailFormProps> = ({ onSubmit, isLoading }) => {
  const toast = useToast();
  return (
    <Box>
      <Formik
        initialValues={{ verificationCode: '' }}
        validationSchema={verifyEmailFormSchema}
        onSubmit={async (values, actions) => {
          try {
            await onSubmit(values);
            analytics.push({ action: { type: 'form_submit', data: { name: 'SendEmailVerificationForm' } } });
          } catch (error) {
            logger.error('SendEmailVerificationForm', 'ERROR: ', error);
            actions.setSubmitting(false);
            toast({
              variant: 'negative',
              title: 'Something went wrong on our end. Please try again!',
            });
          }
        }}
      >
        {(props) => (
          <Form noValidate>
            <Stack spacing="3">
              <Field name="verificationCode">
                {({ field, form }) => (
                  <InputField
                    id="verificationCode"
                    label="Verification code"
                    isInvalid={form.errors.verificationCode && form.touched.verificationCode}
                    errorText={form.errors.verificationCode}
                    inputProps={{ type: 'string', size: 'md', placeholder: 'Enter the verification code', ...field }}
                  />
                )}
              </Field>
              <Button isLoading={props.isSubmitting || isLoading} type="submit" maxW="40" alignSelf="center" fontSize="md">
                Verify now
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
