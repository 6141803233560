import React from 'react';
import IconContainer from './IconContainer';

export const InfoOutline = () => {
  return (
    <IconContainer>
      <svg viewBox="0 0 36 36" focusable="false" aria-hidden="true" role="img">
        <path
          fillRule="evenodd"
          d="M20.15,12A2.15,2.15,0,1,1,18,9.85,2.15,2.15,0,0,1,20.15,12Zm.1835,12H20V16.3999A.4001.4001,0,0,0,19.60007,16H15.66648S14.5,16.03223,14.5,17c0,.96729,1.16651,1,1.16651,1H16v6h-.33349S14.5,24.03223,14.5,25c0,.96729,1.16651,1,1.16651,1h4.667S21.5,25.96729,21.5,25C21.5,24.03223,20.33347,24,20.33347,24ZM18,1A17,17,0,1,0,35.00008,18,17.00014,17.00014,0,0,0,18,1Zm0,30.34961A13.34961,13.34961,0,1,1,31.34967,18,13.34962,13.34962,0,0,1,18,31.34961Z"
        ></path>
      </svg>
    </IconContainer>
  );
};
