const config = {
  hotjar: {
    prod: {
      sendpayments: {
        id: '1770428',
        version: '6',
      },
      'travel-money-transfers': {
        id: '2440051',
        version: '6',
      },
    },
    staging: {
      sendpayments: {
        id: '1756640',
        version: '6',
      },
      'travel-money-transfers': {
        id: '1756640',
        version: '6',
      },
    },
  },
  analytics: {
    prod: {
      uid: 'UA-130858412-1',
    },
    staging: {
      uid: 'UA-130858412-2',
    },
  },
  tag: {
    prod: {
      sendpayments: {
        uid: 'GTM-NQ5NBQB',
      },
      'travel-money-transfers': {
        uid: 'GTM-KFSBG2N',
      },
    },
    staging: {
      sendpayments: {
        uid: 'GTM-N8QJ64V',
      },
      'travel-money-transfers': {
        uid: 'GTM-KFSBG2N',
      },
    },
  },
  taboola: {
    id: 1312318,
  },
  launchdarkly: {
    ara: {
      sendpayments: {
        clientSideID: '6078c6ec4f67d50c1f00c90e',
      },
    },
    staging: {
      sendpayments: {
        clientSideID: '6029afa77f53740ae9376ecd',
      },
      'travel-money-transfers': {
        clientSideID: '60c6ec009ffa400b847c5d52',
      },
    },
    prod: {
      sendpayments: {
        clientSideID: '6029afa77f53740ae9376ece',
      },
      'travel-money-transfers': {
        clientSideID: '60c6ec08cbdbfb0bf356c16c',
      },
    },
  },
  recaptcha: {
    siteKey: '6LfxZUcfAAAAAJzIRm8tc2qjxxxOaScvecG4qG6W',
  },
};

export default config;
