import * as React from 'react';
import { AlertBox, useToast } from '@sendpayments/react-shared/components/molecules';
import { LoginForm, VerifyCodeModal, WelcomeBackForm } from '@send-base/atomic-components/organisms';
import { SocialLogin } from '@send-base/atomic-components/molecules';
import { Link, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { AuthenticationTemplate } from '@send-base/atomic-components/templates';

import { ISignInProps } from 'aws-amplify-react/lib-esm/Auth/SignIn';
import { AuthPiece, IAuthPieceState } from 'aws-amplify-react/lib-esm/Auth/AuthPiece';
import Auth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import history from '@send-legacy-app/history';
import axios from 'axios';
import { env } from '@send-services/environment';

export interface SignInPageProps {
  changeState: AuthPiece<ISignInProps, IAuthPieceState>['changeState'];
  checkContact: (user: any) => void;
}

interface AlertBoxData {
  message?: string | JSX.Element;
  type?: string;
}

interface IsWelcomeData {
  source?: 'facebook' | 'google';
  email?: string;
}

export const SignInPage: React.FC<SignInPageProps> = ({ changeState, checkContact }) => {
  const [alertBox, setAlertBox] = React.useState<AlertBoxData>({ message: undefined, type: undefined });
  const [isLoading, setLoading] = React.useState(false);
  const [userCredentials, setUserCredentials] = React.useState({ username: '', password: '' });
  const [user, setUser] = React.useState<any>(null);
  const [isWelcome, setIsWelcome] = React.useState<IsWelcomeData>({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  React.useEffect(() => {
    const { search } = history.location;
    const queryList = search?.split?.('?') || [];

    const error = queryList
      .map((q) => {
        const params = new URLSearchParams(q);
        return params.get('error_description');
      })
      .find(Boolean);

    if (error?.includes?.('PreSignUp')) {
      setAlertBox({ message: 'Looks like you already have an account. Please log in with your email instead.', type: 'error' });
    }
  }, []);

  // We will need to do this on login and other pages that need MFA
  const handleMFA = async (code: string) => {
    try {
      const loggedUser = await Auth.confirmSignIn(user, code, user.challengeName);
      changeState('signedIn', loggedUser);
      onClose();
    } catch (error: any) {
      if (error.code === 'CodeMismatchException') {
        toast({
          variant: 'negative',
          title: 'The security code entered is not correct. Please try again.',
        });
      } else {
        toast({
          variant: 'negative',
          title: "Something is wrong, can't verify your code",
        });
      }
    }
  };

  const onSocialLogin = (provider: 'Google' | 'Facebook') => {
    const providers = {
      Google: CognitoHostedUIIdentityProvider.Google,
      Facebook: CognitoHostedUIIdentityProvider.Facebook,
    };
    Auth.federatedSignIn({ provider: providers[provider] });
  };

  const onSignIn = async (values) => {
    setLoading(true);
    try {
      const user = await Auth.signIn(values.email?.trim().toLowerCase(), values.password);

      if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        setUser(user);
        setUserCredentials(values); // not sure if this is secure
        onOpen();
      } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        changeState('requireNewPassword', user);
      } else if (user.challengeName === 'MFA_SETUP') {
        changeState('TOTPSetup', user);
        // } else if (user.challengeName === 'CUSTOM_CHALLENGE' && user.challengeParam && user.challengeParam.trigger === 'true') {
        //   changeState('customConfirmSignIn', user);
        // }
      } else {
        checkContact(user);
      }
    } catch (error: any) {
      if (error.code === 'UserNotConfirmedException') {
        changeState('confirmSignUp', { username: values.email });
        Auth.resendSignUp(values.email);
      } else if (error.code === 'PasswordResetRequiredException') {
        changeState('forgotPassword', { username: values.email });
      } else if (error.code === 'NotAuthorizedException' && error.message.includes('disabled')) {
        setAlertBox({ message: 'Sorry, this account is deactivated.', type: 'error' });
      } else {
        let finalEnv = env;
        let finalEndpoint = '';
        if (env === 'reskinuat') {
          finalEnv = 'uat';
          finalEndpoint = `https://internal-api-${finalEnv}.env.sendpayments.com/v1/auth/check-social-user`;
        } else if (env === 'reskinprod') {
          finalEndpoint = `https://internal-api.sendpayments.com/v1/auth/check-social-user`;
        } else {
          finalEndpoint = `https://internal-api-${finalEnv}.env.sendpayments.com/v1/auth/check-social-user`;
        }

        try {
          const { data } = await axios.get(finalEndpoint, {
            params: { email: values.email },
          });

          const userOrigin = data.data.origin;
          if (['facebook', 'google'].includes(userOrigin)) {
            setIsWelcome({
              source: userOrigin,
              email: values.email,
            });
          } else {
            setAlertBox({
              message: (
                <>
                  <Text as="span" fontSize="sm" fontWeight="bold">
                    Incorrect username or password
                  </Text>
                  <Text as="span" fontSize="sm">
                    {". If you can't remember your password, click here to "}
                    <Link fontSize="sm" color="primary.base" textDecor="underline" onClick={() => changeState('forgotPassword')}>
                      reset
                    </Link>
                  </Text>
                </>
              ),
              type: 'error',
            });
          }
        } catch (error: any) {
          setAlertBox({
            message: (
              <>
                <Text as="span" fontSize="sm" fontWeight="bold">
                  Incorrect username or password
                </Text>
                <Text as="span" fontSize="sm">
                  {". If you can't remember your password, click here to "}
                  <Link fontSize="sm" color="primary.base" textDecor="underline" onClick={() => changeState('forgotPassword')}>
                    reset
                  </Link>
                </Text>
              </>
            ),
            type: 'error',
          });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <VerifyCodeModal
        destination={user?.challengeParam.CODE_DELIVERY_DESTINATION}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleMFA}
        onResend={() => onSignIn(userCredentials)}
      />
      <AuthenticationTemplate heading={isWelcome?.source ? 'Welcome back' : 'Login'}>
        <Stack spacing={8}>
          {isWelcome?.source ? (
            <WelcomeBackForm
              isWelcome={isWelcome}
              setIsWelcome={setIsWelcome}
              onSocialLogin={onSocialLogin}
              changeState={changeState}
              page="signin"
            />
          ) : (
            <>
              <Text align="center" fontSize="md">
                <Text as="span">{'Not registered yet? '}</Text>
                <Text as="a" fontWeight="semibold" fontSize="md" color="primary.base" onClick={() => changeState('signUp')}>
                  Register
                </Text>
              </Text>
              {/* Body with Login form component */}
              {!!alertBox.message && (
                <AlertBox
                  maxW="100%"
                  my="4"
                  title=""
                  alignSelf="center"
                  variant={alertBox.type === 'success' ? 'neutral' : 'negative'}
                  description={alertBox.message as string}
                  showIcon
                />
              )}
              <LoginForm
                onSubmit={onSignIn}
                isLoading={isLoading}
                onForgot={() => changeState('forgotPassword')}
                initialValues={{ email: '', password: '' }}
              />
              <SocialLogin content="Or" onSocialLogin={onSocialLogin} />
            </>
          )}
        </Stack>
      </AuthenticationTemplate>
    </>
  );
};
