import * as React from 'react';
import { AuthenticationTemplate } from '../../templates';
import { logger } from '@sendpayments/js-utils/dist/services/logger';
import { Stack, Text } from '@chakra-ui/layout';
import { VerifyEmailForm } from '../../organisms';
import { AlertBox } from '@sendpayments/react-shared/components/molecules';
import { AlertBoxProps } from '@sendpayments/react-shared/components/molecules/AlertBox/AlertBox';

interface VerifyEmailPageProps {
  username: string;
  alertBox: AlertBoxProps;
  resendVerificationCode(): Promise<void>;
  confirmSignUp(username: string, verificationCode: string): Promise<void>;
}

const VerifyEmailPage: React.FC<VerifyEmailPageProps> = ({ username, alertBox, resendVerificationCode, confirmSignUp }) => {
  const [disableResend, setDisableResend] = React.useState(false);

  const onSubmit = (values: { verificationCode: string }) => {
    const verificationCode = values?.verificationCode.trim();
    if (!verificationCode) {
      logger.log('Verification code blank. Unable to confirm.', '', '');
      return Promise.resolve();
    }
    return confirmSignUp(username, verificationCode);
  };

  return (
    <AuthenticationTemplate heading={'Verify your email'}>
      <Stack spacing={8}>
        <Text align="center" fontSize="md">
          <Text as="span">{'Still not received the code? '}</Text>
          <Text
            as="a"
            fontWeight="semibold"
            fontSize="md"
            color={disableResend ? 'text.light' : 'primary.base'}
            onClick={async () => {
              if (!disableResend) {
                setDisableResend(true);
                await resendVerificationCode();
                setDisableResend(false);
              }
            }}
          >
            Resend verification code
          </Text>
        </Text>
        {!!alertBox && <AlertBox maxW="100%" title="" my="4" alignSelf="center" showIcon {...alertBox} />}
        <VerifyEmailForm onSubmit={onSubmit} isLoading={false} initialValues={{ verificationCode: '' }} />
      </Stack>
    </AuthenticationTemplate>
  );
};

export { VerifyEmailPage, VerifyEmailPageProps };
