import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { getCurrencyString } from '@sendpayments/js-utils/dist/utils/currency';

export const BankDetailsTables = ({ bankDetails, loading, small, amount, currency }) => (
  <Spin spinning={loading}>
    <table className={`bank-details ${small ? 'small' : ''}`}>
      <tbody>
        {amount && currency && (
          <tr>
            <td>Amount Outstanding</td>
            <td className="info">{getCurrencyString({ amount, currency })}</td>
          </tr>
        )}
        <tr>
          <td>Account Name</td>
          <td className="info">{bankDetails.name}</td>
        </tr>
        <tr>
          <td>Bank</td>
          <td className="info">{bankDetails.bank}</td>
        </tr>
        <tr>
          <td>Currency</td>
          <td className="info">{bankDetails.currency}</td>
        </tr>
        {['AUD', 'CNY'].includes(bankDetails.currency) && (
          <>
            {bankDetails.sortCode && (
              <tr>
                <td>{bankDetails.currency === 'AUD' ? 'BSB' : 'Sort Code'}</td>
                <td className="info">{bankDetails.sortCode}</td>
              </tr>
            )}
            {bankDetails.bsbCode && (
              <tr>
                <td>BSB</td>
                <td className="info">{bankDetails.bsbCode}</td>
              </tr>
            )}
            {bankDetails.accountNumber && (
              <tr>
                <td>Account Number</td>
                <td className="info">{bankDetails.accountNumber}</td>
              </tr>
            )}
            {bankDetails.iban && (
              <tr>
                <td>IBAN</td>
                <td className="info">{bankDetails.iban}</td>
              </tr>
            )}
            {bankDetails.swift && (
              <tr>
                <td>Swift</td>
                <td className="info">{bankDetails.swift}</td>
              </tr>
            )}
            {bankDetails.payId && (
              <tr>
                <td>
                  <img src="/static/images/payid.png" alt="payID" />
                </td>
                <td className="info">{bankDetails.payId}</td>
              </tr>
            )}
          </>
        )}
        <tr>
          <td>Payment Reference</td>
          <td className="info">{bankDetails.reference}</td>
        </tr>
        <tr>
          <td>Bank Address</td>
          <td className="info">{bankDetails.bankAddress}</td>
        </tr>
      </tbody>
    </table>

    {!['AUD', 'CNY'].includes(bankDetails.currency) && (
      <>
        {(bankDetails.iban || bankDetails.swift) && (
          <p className="small-title" style={{ marginTop: '1em' }}>
            Choosing one of the following:
          </p>
        )}
        <table className={`bank-details-border bank-details ${small ? 'small' : ''}`}>
          <tbody>
            <tr>
              <td>Sort Code</td>
              <td className="info">{bankDetails.sortCode}</td>
            </tr>
            <tr>
              <td>Account Number</td>
              <td className="info">{bankDetails.accountNumber}</td>
            </tr>
          </tbody>
        </table>
        {(bankDetails.iban || bankDetails.swift) && (
          <>
            <p style={{ marginTop: '1em' }}>or</p>
            <table className={`bank-details ${small ? 'small' : ''}`}>
              <tbody>
                <tr>
                  <td>IBAN</td>
                  <td className="info">{bankDetails.iban}</td>
                </tr>
                <tr>
                  <td>Swift</td>
                  <td className="info">{bankDetails.swift}</td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </>
    )}
  </Spin>
);

BankDetailsTables.propTypes = {
  bankDetails: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  small: PropTypes.bool,
  amount: PropTypes.number,
  currency: PropTypes.string,
};

BankDetailsTables.defaultProps = {
  loading: false,
  small: false,
  amount: undefined,
  currency: undefined,
};
