import { get } from '@send-base/utilities/endpoint';
import { Beneficiary } from '@sendpayments/graphql/types';
import { getCountryCode } from '@sendpayments/js-utils/dist/utils/common';

type BeneRequirements = {
  payment_type: 'regular' | 'priority';
  beneficiary_entity_type: 'individual' | 'company';
  beneficiary_first_name: string;
  beneficiary_last_name: string;
  beneficiary_city: string;
  beneficiary_address: string;
  beneficiary_country: string;
  beneficiary_postcode: string;
  acct_number?: string;
  bsb_code?: string;
  bic_swift?: string;
  sort_code?: string;
  iban?: string;
  clabe?: string;
  cnaps?: string;
  aba?: string;
  bank_name?: string;
  bank_code?: string;
  ifsc?: string;
};
export const getBankDetailsValidation = async ({
  beneficiary,
}: {
  beneficiary: Beneficiary;
}): Promise<Record<string, string | undefined>> => {
  const result = {};

  const beneRequirements: BeneRequirements[] = await get('/dealing/beneficiary-required-details', {
    data: { currency: beneficiary.currencyTo, bank_account_country: getCountryCode(beneficiary.beneficiaryBankAccountCountry) },
  });

  const beneBankFields = getBeneBankDetails(beneficiary);

  beneBankFields.map((field) => (result[field] = getRegExpForField({ field, beneRequirements })));
  return result;
};

const getRegExpForField = ({ field, beneRequirements }): string | undefined => {
  const ccFieldMapper = {
    bicSwiftCode: 'bic_swift',
    sortCodeNumber: 'sort_code',
    iban: 'iban',
    clabeNumber: 'clabe',
    cnapsNumber: 'cnaps',
    abaCode: 'aba',
    accountNumber: 'acct_number',
    bankName: 'bank_name',
    bankCode: 'bank_code',
    ifscNumber: 'ifsc',
  };
  for (const req of beneRequirements) {
    if (req[ccFieldMapper[field]]) {
      return req[ccFieldMapper[field]];
    }
  }
  return undefined;
};
export const accountDetailFields = {
  accountNumber: 'Account Number',
  iban: 'IBAN',
  clabeNumber: 'CLABE',
};
export const institutionDetailFields = {
  bsbCode: 'BSB Code',
  bicSwiftCode: 'BIC/SWIFT',
  sortCodeNumber: 'Sort Code',
  cnapsNumber: 'CNAPS',
  bankName: 'Bank Name',
  bankCode: 'Bank Code',
  abaCode: 'ABA',
  ifscNumber: 'IFSC',
};
export const getBeneBankDetails = (beneficiary: Beneficiary): string[] => {
  const allBeneKeys = Object.keys(beneficiary);
  const bankDetailsKeys = [...Object.keys(accountDetailFields), ...Object.keys(institutionDetailFields)];
  return allBeneKeys.filter((key) => !!beneficiary[key] && bankDetailsKeys.includes(key));
};
