import React, { useEffect, useState } from 'react';
import { triggerToast } from '@send-components/Toast';
import { Conversion, Beneficiary } from '@sendpayments/graphql/types';
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { OpenConversions } from '@send-base/atomic-components/organisms/BeneModal/OpenConversions';
interface BeneDeleteModal {
  isOpen: boolean;
  onClose: () => void;
  deleteBeneficiary: (id: string) => Promise<void>;
  triggerToast: typeof triggerToast;
  openConversions: Conversion[];
  beneData: Beneficiary;
  allowBeneEdit: boolean;
}

export const BeneDeleteModal: React.FC<BeneDeleteModal> = ({
  isOpen,
  onClose,
  deleteBeneficiary,
  triggerToast,
  openConversions,
  beneData,
  allowBeneEdit,
}: BeneDeleteModal) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [allowDelete, setAllowDelete] = useState(true);

  useEffect(() => {
    if (openConversions.length === 0 && allowBeneEdit) {
      setAllowDelete(true);
    } else {
      setAllowDelete(false);
    }
  }, [openConversions, allowBeneEdit]);

  const onClick = async () => {
    setSubmitLoading(true);
    try {
      await deleteBeneficiary(beneData.id);
      triggerToast({
        type: 'success',
        message: 'Successfully deleted recipient, please refresh to see the update.',
      });
    } catch (err) {
      triggerToast({
        type: 'error',
        message: 'Something went wrong while deleting recipient.',
      });
    }
    onClose();
    setSubmitLoading(false);
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="1000px">
        <Grid>
          <Box w="auto" display="none">
            <ModalCloseButton background="border.light" borderRadius="100%" />
          </Box>
          <ModalBody w="auto" textAlign="center" mt={8}>
            {allowDelete ? (
              <Box>
                <Text> Are you sure you want to delete this recipient? </Text>
                <ButtonGroup flexDir="row" spacing={{ base: 0, sm: 2 }}>
                  <Button _hover={{ cursor: 'pointer' }} onClick={onClick} border="none">
                    {submitLoading ? <Spinner /> : 'Confirm'}
                  </Button>
                  <Button _hover={{ cursor: 'pointer' }} onClick={onClose} mb={{ base: 2, sm: 0 }} variant={'outline'}>
                    Cancel
                  </Button>
                </ButtonGroup>
              </Box>
            ) : (
              <OpenConversions type="delete" onClose={onClose} openConversions={openConversions} allowBeneEdit={allowBeneEdit} />
            )}
          </ModalBody>

          <ModalFooter
            w="auto"
            justifyContent="center"
            pt={0}
            mt={{ base: 2, sm: 4 }}
            mb={4}
            flexDir={{ base: 'column', sm: 'row' }}
          />
        </Grid>
      </ModalContent>
    </Modal>
  );
};
