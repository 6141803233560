import { get } from '@send-base/utilities/endpoint';
import { useMutation } from 'react-query';

export type BsbInfo = {
  type: 'success';
  data: {
    id: string;
    branch_name: string;
    address: string;
    suburb: string;
    state: string;
    postcode: number;
    bank: {
      id: string;
      country_id: string;
      code: string;
      name: string;
    };
  };
};

export const useBsbInfo = <D = BsbInfo, E = unknown, T = string>(options?: Parameters<typeof useMutation>[2]) => {
  return useMutation<D, E, T>(getBsbInfo, options);
};

const getBsbInfo = <D, V>(bsb: D): Promise<V> => get(`/v1/info/bsb/${bsb}`, undefined, 'InternalApi');
