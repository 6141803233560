import { appConfig } from '@send-base/app.config';

/**
 * A function that converts values or default values to options for the
 * transfer form
 */
export function useTransferOptions(
  { transferAmounts }: Record<'transferAmounts', Array<string> | undefined>,
  {
    defaultTransferAmounts,
    defaultCurrencies,
  }: Record<'defaultTransferAmounts', Array<string>> & {
    defaultCurrencies: string[][];
  },
) {
  const transferAmountOptions = (transferAmounts || defaultTransferAmounts)
    ?.filter((option) => !['<$25k', '$25k to $100k', '$100k to $500k', '$500k to $1m', '>$1m'].includes(option))
    ?.map((value) => ({
      value: value,
      label: value,
    }));

  const currencyOptions = defaultCurrencies
    ?.filter((value) => !appConfig.disabledCurrencies.includes(value[1]))
    .map((value) => ({
      value: value[1],
      label: value[0],
      currencyCode: value[1],
    }));

  return { transferAmountOptions, currencyOptions };
}
