import * as queries from '@sendpayments/graphql/queries';
import { query } from '@send-base/utilities/endpoint';
import { useQuery } from 'react-query';
import { ConvType } from '@send-const/types';

export const useConversion = <T>({ id }: { id: string }) => {
  return useQuery('CoconutConversion', () => getConversion<T & { status: string; fullFundsReceived: boolean }>(id), {
    select: (conversion: T & { status: string; fullFundsReceived?: boolean }) => {
      const { status } = conversion;
      let newStatus = status;
      if (status === ConvType.CompliancePending) {
        newStatus = ConvType.AwaitingFunds;
      }
      if (status === ConvType.AwaitingFunds && conversion.fullFundsReceived) {
        newStatus = ConvType.FundsReceived;
      }

      return { ...conversion, status: newStatus };
    },
  });
};

const getConversion = <T>(id: string): Promise<T> => {
  return query(queries.getCoconut, {
    params: { id, type: 'Conversion' },
    projection: {},
  });
};
