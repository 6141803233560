import React from 'react';
import PropTypes from 'prop-types';
import { Row, Button, Card, Col, Icon } from 'antd';
import { ThemedImage } from '@send-legacy-components/Common';
import { getLabelByKey } from '@send-services/resources';
import styles from './SupportInfo.module.less';

const localPhone = getLabelByKey({ key: 'local_phone', default: '1800982418' });
const localPhoneDisplay = getLabelByKey({ key: 'local_phone_display', default: '1800 982 418' });

const internationalPhone = getLabelByKey({ key: 'international_phone', default: '+61756195700' });
const internationalPhoneDisplay = getLabelByKey({ key: 'international_phone_phone_display', default: '+617 5619 5700' });

const nzPhone = getLabelByKey({ key: 'nz_phone', default: undefined });
const nzPhoneDisplay = getLabelByKey({ key: 'nz_phone_display', default: undefined });

const SupportInfo = ({ className, style, avatar, email, name, title, bio, hobby }) => {
  const filesPath = avatar && avatar.split('/');
  const fileIcon = filesPath[filesPath.length - 1];
  const filePosition = name === 'Support' ? 'images' : 'images/account-managers';

  return (
    <Card className={`sidebar ${className} ${styles.support}`} style={style} bordered={false}>
      <ThemedImage
        width={100}
        className={`avatar ${styles.avatar}`}
        alt="support image rollover"
        path={filePosition}
        file={fileIcon}
      />
      <a className="mail" href={`mailto:${email}`}>
        <Button style={{ lineHeight: '34px' }} className={`transparent icon-only ${styles.iconOnly}`}>
          <Icon type="mail" />
        </Button>
      </a>
      <div className={styles.name} style={{ marginBottom: 0 }}>
        {name}
      </div>
      <h4 style={{ marginBottom: 25 }}>{title}</h4>
      {bio && (
        <Row className="block">
          <p className="bold small">
            <Icon type="clock-circle" />
            {bio}
          </p>
        </Row>
      )}
      {hobby && (
        <Row className="block">
          <p className="bold small">
            <Icon type="smile" />
            {hobby}
          </p>
        </Row>
      )}
      <Row className="phone">
        <Col>
          <div className="tertiary">
            <a className="hyperlink" href={`tel:${localPhone}`}>
              {localPhoneDisplay}
            </a>{' '}
            within Australia
          </div>
          {nzPhone && nzPhoneDisplay && (
            <div className="tertiary">
              <a className="hyperlink" href={`tel:${nzPhone}`}>
                {nzPhoneDisplay}
              </a>{' '}
              within New Zealand
            </div>
          )}
          <div className="tertiary">
            <a className="hyperlink" href={`tel:${internationalPhone}`}>
              {internationalPhoneDisplay}
            </a>{' '}
            when overseas
          </div>
        </Col>
      </Row>
    </Card>
  );
};

SupportInfo.propTypes = {
  avatar: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  bio: PropTypes.string.isRequired,
  hobby: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

SupportInfo.defaultProps = {
  className: '',
  style: {},
};

export default SupportInfo;
