import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  BoxProps,
  Button,
  Divider,
  Flex,
  Grid,
  HStack,
  Tag,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { AvatarIcon, LockIcon } from '@sendpayments/react-shared/components/Icons';
import gsap from 'gsap';
import { CurrencyFlagCircular } from '@sendpayments/react-shared/components/CurrencyFlag';
import { RecipientAlert } from '../RecipientAlert';
import { useFeature } from '@sendpayments/react-shared/hooks/useFeature';
import { PD_1864_BENE_EDIT } from '@sendpayments/shared-constants/features';
import { triggerToast } from '@send-components/Toast';
import { API, Auth } from 'aws-amplify';
import { graphqlOperationEx } from '@sendpayments/graphql/fields';
import * as mutations from '@sendpayments/graphql/mutations';
import { analytics, AnalyticsDataLayer } from '@sendpayments/js-utils/dist';
import { BeneModal } from '../../BeneModal';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
import { Conversion, Beneficiary, Payment } from '@sendpayments/graphql/types';
import { get } from '@send-base/utilities/endpoint';
import {
  getBeneBankDetails,
  accountDetailFields,
  institutionDetailFields,
} from '@send-services/beneficiary/getBankDetailsValidation';
import { BeneDeleteModal } from '@send-base/atomic-components/organisms/BeneModal/BeneDeleteModal';
import { VerifyPhoneModal } from '../../VerifyPhoneModal';
import { SpinnerOverlay } from '@send-base/atomic-components/atoms';

export interface CustomAvatarComponentProps {
  currency?: string;
}

export const CustomAvatarComponent: React.VFC<BoxProps & CustomAvatarComponentProps> = (props) => {
  return (
    <Box position="relative">
      <AvatarIcon w="38px" h="38px"></AvatarIcon>
      {/* Inner flag */}
      <Box
        transform="scale(0.7)"
        position="absolute"
        bottom="-2px"
        right="-9px"
        border="medium"
        borderRadius="100%"
        borderColor="border.light"
      >
        <CurrencyFlagCircular currencyName={props.currency} />
      </Box>
    </Box>
  );
};

export interface RecipientListItemProps {
  userEmail: string;
  recipient: Beneficiary;
  name: string;
  key?: string;
  description?: string;
  src?: string;
  type?: 'Verified' | 'New' | 'Deleted';
  isOpen?: boolean;
  accountNumber?: string;
  iban?: string;
  email?: string;
  currency?: string;
  isDisabled?: boolean;
  // Omit or set to falsy to disable
  onSendMoney?: React.MouseEventHandler<HTMLButtonElement>;
  //get open conversion when it opens (if applicable)
  refetchBenes: () => Promise<void>;
}

export const RecipientListItem: React.VFC<RecipientListItemProps & BoxProps> = (props) => {
  const {
    name,
    description,
    src,
    type,
    isOpen,
    accountNumber,
    iban,
    email,
    userEmail,
    currency,
    isDisabled,
    onSendMoney,
    recipient,
    refetchBenes,
    ...rest
  } = props;
  const sendText = useBreakpointValue({ base: 'Send', md: 'Send money' });
  const beneBankFields = useMemo(() => getBeneBankDetails(recipient), [recipient]);

  const [beneEditMode, setBeneEditMode] = useState<'edit' | 'delete'>('edit');
  const [isFetchingConversions, setIsFetchingConversions] = useState(false);

  const verifyPhoneModal = useDisclosure();
  const editModal = useDisclosure();
  const deleteModal = useDisclosure();

  const openBeneModal = {
    edit: editModal.onOpen,
    delete: deleteModal.onOpen,
  }[beneEditMode];

  const [
    {
      data: { isEnabled: isBeneEditEnabled },
    },
  ] = useFeature({
    feature: PD_1864_BENE_EDIT,
    functionName: `BeneEdit`,
  });

  const [openConversions, setOpenConversions] = useState<Conversion[]>([]);

  const ref = useRef<HTMLDivElement>(null);
  const animationRef = useRef<gsap.core.Tween | null>(null);
  const [allowBeneEdit, setAllowBeneEdit] = useState(true);

  const sendMoneyFunctionDisabled = !onSendMoney || isDisabled;

  useEffect(() => {
    // Animate accordion open and closing
    if (isOpen) {
      animationRef.current = gsap.to(ref.current, { height: 'auto', ease: 'power1.ease', duration: 0.2 });
    } else {
      animationRef.current = gsap.to(ref.current, { height: '0', ease: 'power1.ease', duration: 0.2 });
    }

    return () => {
      animationRef?.current?.kill();
    };
  }, [isOpen]);

  /** Leaving this here incase payment status requirements change
    Allowed statuses:
    SendArchived: 'send_archived',
    New: 'new',
    Completed: 'completed',
    ReadyToSend: 'ready_to_send',
    AwaitingAuthorisation: 'awaiting_authorisation',
    Authorised: 'authorised',
    Deleted: 'deleted',
    Failed: 'failed',
    SendApproved: 'send_approved',
    AwaitingFunds: 'awaiting_funds',
    SendCompliancePending: 'send_compliance_pending',
    Suspended: 'suspended',
    */
  const notAllowedPaymentStatuses = {
    Released: 'released',
    Submitted: 'submitted',
  };

  const allowBeneficiaryEdits = async () => {
    //Check if bene has an OPEN CONVERSION on it
    const { data: conversions } = await get(`/v1/beneficiary/conversions/${recipient.id}`, undefined, 'InternalApi');

    const { data: payments } = await get(`/v1/beneficiary/payments/${recipient.id}`, undefined, 'InternalApi');

    if (!payments || payments?.length === 0) {
      setOpenConversions(conversions ?? []);
      return;
    }
    //Assume the payments are allowed to be edited
    //And determine with the forEach if otherwise
    let allowEdit = true;

    const paymentStatuses = payments && payments.map((payment: Payment) => payment.status.toLowerCase());

    paymentStatuses.forEach((status) => {
      //If any payments are 'released' or 'submitted', we are not allowed to make changes to the bene
      if (allowEdit === false) {
        return;
      }
      if (Object.values(notAllowedPaymentStatuses).includes(status)) {
        allowEdit = false;
      }
    });

    setAllowBeneEdit(allowEdit);
    setOpenConversions(conversions);
  };

  const deleteBeneficiary = async (id) => {
    try {
      //We don't have js-utils function for delete so have to use amplify API directly
      const session = await Auth.currentSession();
      const params = {
        headers: {
          Token: session.getIdToken().getJwtToken(),
          'Content-Type': 'application/json',
        },
        body: {},
      };
      await API.del('InternalApi', `/v1/beneficiary/${id}`, params);
      analytics.push({ action: { type: 'deleted_recipient' } } as AnalyticsDataLayer);
    } catch (error) {
      throw new Error('Error deleting recipient');
    }
  };
  const updateBeneAccountDetails = async (value) => {
    try {
      const beneInput = {
        id: recipient.id,
        type: CoconutTypes.BENEFICIARY,
        updatedBy: userEmail,
      };
      const beneBankDetails = getBeneBankDetails(value);
      for (const field of beneBankDetails) {
        beneInput[field] = value[field];
      }
      await API.graphql(
        graphqlOperationEx(mutations.updateCoconut, {
          input: beneInput,
        }),
      );

      await refetchBenes();

      analytics.push({ action: { type: 'updated_recipient' } } as AnalyticsDataLayer);
    } catch (error) {
      throw new Error('Error updating recipient');
    }
  };

  return (
    <Box
      {...rest}
      backgroundColor={isOpen && type !== 'New' ? 'secondary.light' : 'inherit'}
      borderRadius="5px"
      pl="4"
      pr="4"
      pt="6"
      pb="6"
      transition="background .1s"
      cursor={type === 'Verified' ? 'pointer' : 'default'}
    >
      {type === 'New' && (
        // Only shows on mobile
        <Tag variant="informative" mb="4" display={{ base: 'flex', md: 'none' }} width="min-content">
          Pending
        </Tag>
      )}
      {type === 'Deleted' && (
        // Only shows on mobile
        <Tag variant="informative" mb="4" display={{ base: 'flex', md: 'none' }} width="min-content">
          Deactivated
        </Tag>
      )}
      <Flex alignItems="center" justifyContent={'space-between'}>
        <Flex alignItems="center">
          <Box marginRight="3">
            <CustomAvatarComponent currency={currency} />
          </Box>
          <Flex direction={'column'} mr={2} maxW={{ base: 150, md: 'none' }}>
            <Text fontWeight={'bold'} textOverflow={'ellipsis'} overflow={'hidden'} whiteSpace={'nowrap'}>
              {name}
            </Text>
            <Text>{description}</Text>
          </Flex>
        </Flex>
        {type === 'New' ? (
          <Tag variant="informative" display={{ base: 'none', md: 'inherit' }}>
            Pending
          </Tag>
        ) : type === 'Deleted' ? (
          <Tag variant="informative" display={{ base: 'none', md: 'inherit' }}>
            Deactivated
          </Tag>
        ) : (
          <Button
            rightIcon={sendMoneyFunctionDisabled ? <LockIcon /> : <></>}
            disabled={sendMoneyFunctionDisabled}
            onClick={(event) => {
              event.stopPropagation();
              onSendMoney?.(event);
            }}
            flexShrink={0}
          >
            {sendText}
          </Button>
        )}
      </Flex>
      {type === 'New' && (
        <RecipientAlert mt="14px">
          <Text fontSize="sm">
            We’re currently reviewing this recipient.{' '}
            <Text as="span" fontSize="sm" fontWeight="semibold">
              This is usually done instantly but might take up to 48 hours.
            </Text>
          </Text>
        </RecipientAlert>
      )}
      {type === 'Deleted' && (
        <RecipientAlert mt="14px">
          <Text fontSize="sm">
            This recipient has been previously deactivated.{' '}
            <Text as="span" fontSize="sm" fontWeight="semibold">
              If you wish to reactivate please contact us on 1800 982 418.
            </Text>
          </Text>
        </RecipientAlert>
      )}
      {/* Details box. Animated above */}
      {type === 'Verified' && (email || accountNumber || iban) && (
        <Box ref={ref} height="0" overflow="hidden">
          <Divider mt="4" mb="4" opacity={1} />
          <Flex flexDir={{ base: 'column', md: 'row' }} align="end" justify="space-between">
            <Grid pl={{ base: '12' }} gridTemplateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} rowGap="5" w="100%">
              {Object.entries(institutionDetailFields).map(
                ([key, title]) =>
                  recipient[key] && (
                    <Box key={key}>
                      <Text fontWeight="bold">{title}</Text>
                      <Text>{recipient[key]}</Text>
                    </Box>
                  ),
              )}
              {Object.entries(accountDetailFields).map(
                ([key, title]) =>
                  recipient[key] && (
                    <Box key={key}>
                      <Text fontWeight="bold">{title}</Text>
                      <Text>{recipient[key]}</Text>
                    </Box>
                  ),
              )}
            </Grid>
            {isBeneEditEnabled && (
              <HStack pr={3}>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={async (e) => {
                    e.stopPropagation();
                    setBeneEditMode('delete');
                    verifyPhoneModal.onOpen();
                  }}
                >
                  Delete
                </Button>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={async (e) => {
                    e.stopPropagation();
                    setBeneEditMode('edit');
                    verifyPhoneModal.onOpen();
                  }}
                >
                  Edit
                </Button>
              </HStack>
            )}
          </Flex>
        </Box>
      )}
      <BeneModal
        isOpen={editModal.isOpen}
        onClose={editModal.onClose}
        updateBeneAccountDetails={updateBeneAccountDetails}
        openConversions={openConversions}
        key="updateBeneModal"
        beneData={recipient}
        triggerToast={triggerToast}
        allowBeneEdit={allowBeneEdit}
      />
      <BeneDeleteModal
        isOpen={deleteModal.isOpen}
        onClose={deleteModal.onClose}
        deleteBeneficiary={deleteBeneficiary}
        openConversions={openConversions}
        key="deleteBeneModal"
        triggerToast={triggerToast}
        beneData={recipient}
        allowBeneEdit={allowBeneEdit}
      />
      <VerifyPhoneModal
        {...verifyPhoneModal}
        description="To edit your recipient’s details, we need to make sure that it’s really you."
        onVerifySuccess={async () => {
          verifyPhoneModal.onClose();

          setIsFetchingConversions(true);
          await allowBeneficiaryEdits().catch(() => setOpenConversions([]));
          setIsFetchingConversions(false);

          openBeneModal();
        }}
      />
      {isFetchingConversions && <SpinnerOverlay text={`Unlocking recipient's details`} />}
    </Box>
  );
};
