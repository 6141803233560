import React from 'react';
import { Avatar, Box, Heading, Stack, HStack, Text } from '@chakra-ui/react';

export interface SupportCardProps {
  avatarPath: string;
  name: string;
  title: string;
  email: string;
  availability: string;
  localPhone: string;
  internationalPhone: string;
}

export const SupportCard: React.FC<SupportCardProps> = ({
  avatarPath,
  name,
  title,
  email,
  availability,
  localPhone,
  internationalPhone,
}) => {
  return (
    <Box maxW={{ base: '100%', md: '276px' }} h="232px" as="section" rounded="xl" p="17px" boxShadow="xl" borderWidth="1px">
      <Stack spacing="16px">
        <HStack>
          <Avatar padding="2px" bgColor="border.base" src={avatarPath} w="56px" h="56px" />
          <Stack spacing="sm">
            <Heading as="h2" fontSize="22px">
              {name}
            </Heading>
            <Text fontSize="sm">{title ? title : 'FX Experts on standby'}</Text>
          </Stack>
        </HStack>
        <Text as="a" fontSize="sm" color="primary.base" href={`mailto:${email}?subject=Support`}>
          {email}
        </Text>
        <Stack spacing="3px">
          <Text fontSize="sm">
            <Text as="a" color="primary.base" href={`tel:${localPhone.replace(/\s/g, '')}`}>
              {localPhone}
            </Text>
            {' within Australia'}
          </Text>
          <Text fontSize="sm">
            <Text as="a" color="primary.base" href={`tel:${internationalPhone.replace(/\s/g, '')}`}>
              {internationalPhone}
            </Text>
            {' when Overseas'}
          </Text>
          <Text fontSize="xs">{availability}</Text>
        </Stack>
        <Text
          as="a"
          fontSize="sm"
          color="primary.base"
          href="https://help.sendpayments.com/en/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {'Support & FAQs'}
        </Text>
      </Stack>
    </Box>
  );
};
