import { getActivityStatus } from '@send-services/activities';
import { isPartiallyUpdated } from '@sendpayments/js-utils/dist/services/partial-updates';
import { conversionActivityStatus, paymentActivityStatus } from '@send-const/statuses';

const ARCHIVED_STATUS = 'send_archived';

export const isLegacyConversion = (conversion) => {
  const FROM_TRADES_LEGACY_DATE = new Date('01-03-2020');
  const { createdAt } = conversion;

  if (isPartiallyUpdated(conversion)) {
    return false;
  }

  if (!createdAt) {
    throw new Error('createdAt need to be present');
  }

  return new Date(createdAt) <= FROM_TRADES_LEGACY_DATE;
};

export const getStatusMapping = (activity, { payments = [], documents = [] }) => {
  const isPaymentNeeded = payments.length === 0;
  const isDocumentNeeded = documents.length > 0;
  return getActivityStatus(activity, { isPaymentNeeded, isDocumentNeeded });
};

export const isValidConversion = (conversion) =>
  conversion.bookedBuyAmount &&
  conversion.bookedSellAmount &&
  !conversion.hideFromCustomerActivity &&
  conversion.status !== ARCHIVED_STATUS;

export const enrichConversion = (conversion, conversionPayments = [], conversionDocs = []) => {
  const status = getStatusMapping(conversion, { payments: conversionPayments, documents: conversionDocs });

  const isRecipientNeeded =
    !isLegacyConversion(conversion) && conversionPayments.length === 0 && status !== conversionActivityStatus.CANCELLED;

  const isActionNeeded =
    (isRecipientNeeded && status !== conversionActivityStatus.CANCELLED) ||
    ![conversionActivityStatus.CANCELLED, conversionActivityStatus.PAYMENT_RECEIVED].includes(status) ||
    conversionPayments.find((item) => item.status === paymentActivityStatus.INFO_NEEDED);

  return {
    ...conversion,
    status,
    dbStatus: conversion.status,
    isRecipientNeeded: isRecipientNeeded,
    isActionNeeded,
    outstandingAmount: conversion.bookedSellAmount - (conversion.fundsReceived || 0),
  };
};
