import React, { useEffect, useRef } from 'react';
import { Box, Heading } from '@chakra-ui/react';

export const TrustPilotWidget = () => {
  const widgetWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (globalThis.Trustpilot) {
      globalThis.Trustpilot.loadFromElement(widgetWrapperRef.current, true);
    }
  }, []);

  return (
    <Box
      bg={{ base: 'rgba(255, 255, 255, 0.4)', sm: 'transparent' }}
      backdropFilter={{ base: 'blur(9.35px)', sm: 'none' }}
      padding={{ base: '0px', sm: '35px 75px 35px 75px' }}
      maxWidth="600px"
      w="full"
    >
      <Box
        paddingTop="23px"
        paddingLeft="19px"
        paddingRight="19px"
        bg={{ base: '#F9F9FA', sm: '#FFFFFF' }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Heading fontSize="20px" fontWeight="600" color="#1B2221">
          Our Customer Reviews
        </Heading>

        <Box borderRadius="4px" bg="#ED3D86" width="73px" height="3px" marginTop="10px" marginBottom="5px" />

        <Box
          className="trustpilot-widget"
          data-locale="en-US"
          data-template-id="539ad0ffdec7e10e686debd7"
          data-businessunit-id="6064e8e2e143b400018567c5"
          data-style-height="340px"
          data-style-width="100%"
          data-theme="light"
          data-stars="1,2,3,4,5"
          data-review-languages="en"
          w="full"
          ref={widgetWrapperRef}
        >
          <a href="https://www.trustpilot.com/review/sendpayments.com" rel="noreferrer" target="_blank">
            Trustpilot
          </a>
        </Box>
      </Box>
    </Box>
  );
};
