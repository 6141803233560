/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';

const StepContainer = forwardRef(
  (
    {
      index,
      title,
      subtitle,
      alert,
      name,
      showProgress,
      icon,
      nextButtonText,
      submitButtonText,
      showNext,
      showBack,
      footerText,
      callOnSaveImperativeHandler,
      children,
      containerSize,
      onButtonNextClick,
      onButtonBackClick,
    },
    ref,
  ) => {
    const childRef = useRef();
    const childElement = React.Children.only(children);

    useImperativeHandle(ref, () => ({
      onSave: (data) => {
        if (childRef.current.onSave) {
          return childRef.current.onSave(data);
        }
        // we may only display something in the step.
        return undefined;
      },
    }));

    return React.cloneElement(childElement, { ref: childRef });
  },
);

StepContainer.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  alert: PropTypes.element,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.node.isRequired,
  nextButtonText: PropTypes.string,
  submitButtonText: PropTypes.string,
  showProgress: PropTypes.bool,
  footerText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showNext: PropTypes.bool,
  showBack: PropTypes.bool,
  callOnSaveImperativeHandler: PropTypes.bool, // this is so we know if we have to call on save on step change
  containerSize: PropTypes.shape({
    xs: PropTypes.number,
    lg: PropTypes.number,
  }),
  onButtonNextClick: PropTypes.func, // will create a custom function on next click. wont execute default functionality
  onButtonBackClick: PropTypes.func, // will create a custom function on next click. wont execute default functionality
};

StepContainer.defaultProps = {
  nextButtonText: 'NEXT',
  submitButtonText: 'SUBMIT',
  footerText: undefined,
  showNext: true,
  showBack: false,
  title: '',
  subtitle: '',
  alert: undefined,
  showProgress: true,
  icon: undefined,
  callOnSaveImperativeHandler: false,
  containerSize: undefined,
  onButtonNextClick: undefined,
  onButtonBackClick: undefined,
};

export default StepContainer;
