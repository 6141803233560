import React, { useContext } from 'react';
import { AddStep as AddRecipientStep } from '@send-legacy-components/Recipient';
import { AppContext } from '@send-legacy-app/Context';

const AddRecipient = () => {
  const { loading } = useContext(AppContext);
  return <AddRecipientStep loading={loading} />;
};

export default AddRecipient;
