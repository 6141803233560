import React, { FC, ReactElement } from 'react';
import { Flex, useRadioGroup, FormErrorMessage, FormControl, FlexProps } from '@chakra-ui/react';
import { PropGetter } from '@chakra-ui/react-utils';

export interface RadioOption {
  value: string;
  title: string;
  description?: string | ReactElement;
  icon?: ReactElement;
}

type EventOrValue = React.ChangeEvent<HTMLInputElement> | string | number;
export interface RadioListItemComponentProps<T extends HTMLElement> {
  radioProps: {
    onChange?: ((e: EventOrValue) => void) | undefined;
    value?: string | number | undefined;
  } & PropGetter<T>;
  title?: string;
  description?: string | ReactElement;
  showError: boolean;
}

export type RadioListProps<RadioListType> = {
  radioListName: RadioListType;
  defaultValue?: RadioListType;
  onRadioListChange: (selectedValue: RadioListType) => void;
  valid: boolean;
  errorMessage?: string;
  radioOptions: RadioOption[];
  ListItemComponent: FC<RadioListItemComponentProps<HTMLInputElement>>;
} & FlexProps;

// Type must be string to satisfy useRadioGroup
type Base = string;

export const RadioList = <T extends Base>({
  radioListName,
  defaultValue,
  onRadioListChange,
  valid,
  errorMessage,
  radioOptions,
  ListItemComponent,
  ...props
}: RadioListProps<T>) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: radioListName,
    defaultValue: defaultValue,
    onChange: (selectedValue) => onRadioListChange(selectedValue as any),
  });

  const group = getRootProps();
  return (
    <FormControl id={radioListName} isInvalid={!valid}>
      {/* // TODO: make these Flex props fit a generic list */}
      <Flex as="ul" {...group} justifyContent="space-evenly" flexDirection={{ base: 'column', md: 'row' }} {...props}>
        {radioOptions.map((option: RadioOption) => {
          const radioProps = getRadioProps({ value: option.value });
          return (
            <ListItemComponent
              key={option.value}
              title={option.title}
              description={option?.description ? option.description : ''}
              icon={option?.icon}
              // @ts-ignore
              radioProps={radioProps}
              showError={!valid}
            />
          );
        })}
      </Flex>
      <FormErrorMessage marginLeft="15px">{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
