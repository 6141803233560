/* eslint-disable react/prop-types */
import { ChakraProvider } from '@chakra-ui/react';
import theme from '@send-chakra-theme';
import { NotFoundPage } from '@send-legacy-screens/404';
import ErrorPage from '@send-legacy-screens/500';
import { Activities } from '@send-legacy-screens/Activities';
import AddRecipientPage from '@send-legacy-screens/AddRecipient';
import { Steps as AddTransferRecipient } from '@send-legacy-screens/AddTransferRecipient';
import AuthPage from '@send-legacy-screens/Auth';
import DashboardPage from '@send-legacy-screens/Dashboard';
import { Steps as SelDealingSteps } from '@send-legacy-screens/DealerAssist';
import { Document } from '@send-legacy-screens/DocumentHistory';
import PaymentDetailPage from '@send-legacy-screens/Payment';
import { Conversion } from '@send-legacy-screens/TradeDetail';
import { analytics } from '@sendpayments/js-utils/dist';
import { trackPageView } from '@sendpayments/js-utils/dist/services/ads-manager';
import { usePageViews } from '@sendpayments/react-shared/hooks';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import uuid from 'uuid/v4';
import {
  AccountTypePage,
  ComparisonRegisterStep2,
  CorporatePage as NewCorporatePage,
  RecipientPage as NewRecipientPage,
  RecipientTransferPage,
  RetailPage as NewRetailPage,
} from '../../atomic-components/pages';
import { DirectDebitPage } from '@send-base/atomic-components/pages/DirectDebitPage/DirectDebitPage';

/**
 * A custom hook that builds on useLocation to parse
 * the query string for you.
 *
 * @see https://reactrouter.com/web/example/query-parameters
 */
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const ProtectedRoute = ({ hideFromAffiliates, render: C, props: childProps, ...rest }) => {
  // eslint-disable-next-line prettier/prettier
  const redirectToDashboard = hideFromAffiliates?.includes(childProps.user?.attributes['custom:portalSource']);

  return (
    <Route
      {...rest}
      render={(rProps) => {
        if (childProps.isLoggedIn || childProps.loading) {
          if (redirectToDashboard) {
            return <Redirect to={'/dashboard'} />;
          }
          return <C {...rProps} {...childProps} />;
        }
        return <Redirect to={`/auth?redirect=${rProps.location.pathname}${rProps.location.search}`} />;
      }}
    />
  );
};

const ProppedRoute = ({ render: C, props: childProps, ...rest }) => (
  <Route {...rest} render={(rProps) => <C {...rProps} {...childProps} />} />
);

export const Routes = ({ childProps }) => {
  const query = useQuery();

  usePageViews(() => {
    analytics.push({
      page: {
        title: document.querySelector('h1')?.innerHTML,
        path: window.location.pathname,
      },
    });
    trackPageView();
  });

  const queryClient = new QueryClient();

  const withChakra = (Page, resetCSS = true) => {
    return (props) => (
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme} resetCSS={resetCSS}>
          {resetCSS ? (
            <chakra-scope>
              <Page {...props} />
            </chakra-scope>
          ) : (
            <Page {...props} />
          )}
        </ChakraProvider>
      </QueryClientProvider>
    );
  };

  const withQueryProvider = (Page) => {
    return (props) => (
      <QueryClientProvider client={queryClient}>
        <Page {...props} />
      </QueryClientProvider>
    );
  };

  const isUserFromComparisonSite = () => {
    return !!window.localStorage.getItem('trackingId');
  };

  const affiliatesToHideFrom = ['pexa'];

  return (
    <Switch>
      <ProtectedRoute
        exact
        path="/index.html"
        render={() => (isUserFromComparisonSite() ? <Redirect to="/getting-started" /> : <Redirect to="/dashboard" />)}
        props={childProps}
      />
      <ProtectedRoute
        exact
        path="/"
        render={() => (isUserFromComparisonSite() ? <Redirect to="/getting-started" /> : <Redirect to="/dashboard" />)}
        props={childProps}
      />
      <ProtectedRoute exact path="/dashboard" render={withQueryProvider(DashboardPage)} props={childProps} />
      <ProtectedRoute exact path="/getting-started" render={withChakra(ComparisonRegisterStep2)} props={childProps} />
      <ProtectedRoute exact path="/regselect" render={withChakra(AccountTypePage)} props={childProps} />
      <ProtectedRoute exact path="/retail" render={withChakra(NewRetailPage)} props={childProps} />
      <ProtectedRoute exact path="/corporate" render={withChakra(NewCorporatePage)} props={childProps} />
      <ProtectedRoute
        exact
        path="/recipient"
        render={withChakra(NewRecipientPage, false)}
        props={childProps}
        hideFromAffiliates={affiliatesToHideFrom}
      />

      <ProtectedRoute exact path={'/recipient/transfer/:id'} render={withChakra(RecipientTransferPage)} props={childProps} />
      <ProtectedRoute
        exact
        path="/activity"
        render={withChakra(Activities, false)}
        props={childProps}
        hideFromAffiliates={affiliatesToHideFrom}
      />
      <ProtectedRoute exact path="/conversion/:id" render={withChakra(Conversion, false)} props={childProps} />
      <ProtectedRoute
        exact
        path="/conversion/:id/funds/direct-debit"
        render={withChakra(DirectDebitPage, true)}
        props={childProps}
      />
      <ProtectedRoute exact path="/payment/:id" render={PaymentDetailPage} props={childProps} />
      <ProtectedRoute exact path="/document" render={Document} props={childProps} />
      <ProtectedRoute exact path="/add-recipient" render={AddRecipientPage} props={childProps} />
      <ProtectedRoute
        exact
        path={['/transfer', '/recipient/transfer']}
        render={withChakra(SelDealingSteps, false)}
        props={childProps}
      />
      <ProtectedRoute exact path="/transfer/:id/add-recipient" render={AddTransferRecipient} props={childProps} />
      <ProppedRoute exact path="/auth" render={withChakra(AuthPage)} props={{ ...childProps, loading: true }} />
      <ProppedRoute
        exact
        path="/login"
        render={withChakra(AuthPage)}
        props={{ ...childProps, loading: true, state: 'signIn', key: uuid() }}
      />
      <ProppedRoute
        exact
        path="/signup"
        render={withChakra(AuthPage)}
        props={{ ...childProps, loading: true, state: 'signUp', affiliateId: query.get('affiliateId') }}
      />
      <ProppedRoute exact path="/error" render={ErrorPage} props={childProps} />
      {/* while we have 2 routers (one for legacy an d one for non legacy we need to keep the new routes in here as
        empty routes */}
      <ProppedRoute exact path="/setting/profile" render={() => null} props={childProps} />
      <ProppedRoute exact path="/setting/security" render={() => null} props={childProps} />
      <ProppedRoute exact path="/setting/notification" render={() => null} props={childProps} />
      <ProppedRoute path="/" render={NotFoundPage} props={childProps} />
      <ProppedRoute path="/404" render={NotFoundPage} props={childProps} />
      <ProppedRoute path="*" render={NotFoundPage} props={childProps} />
    </Switch>
  );
};

export default Routes;
