import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
import { saveRegistrationDocument } from '@send-services/registration-document';
import { withPortalSource } from '@send-services/white-label';
// js-utils
import { useRegistrationByType, useCurrentEmail, useDocumentsByOwner, useMarketingSettings, useSettings } from '@send-data-hooks';
import { getUtmSource } from '@sendpayments/js-utils/dist/services/analytics/legacy';
import { objBlankFieldsToNull } from '@sendpayments/js-utils/dist/utils/common';
import { logger } from '@sendpayments/js-utils/dist/services/logger';
import { saveEntity } from '@sendpayments/js-utils/dist/services/entities';
// Layout
import { Box, Flex, Spinner } from '@chakra-ui/react';
import { StepTemplate } from '../../templates/StepTemplate';
import {
  CorporateDetailsForm,
  CorporateInformationForm,
  IdentityForm,
  FirstTransferForm,
  CorporateDetailsData,
  CorporateInformationData,
  IdentityData,
  FirstTransferData,
  StepData,
} from '../../organisms';
import { CongratulationsPage } from '../../pages/CongratulationsPage';
import { useToast } from '@sendpayments/react-shared/components/molecules/Toast';

import { SupportCard } from '@send-legacy-containers/SupportCard';

import { AppContext } from '@send-legacy-app/Context';
import * as queries from '@sendpayments/graphql/queries';
import { query } from '@send-base/utilities/endpoint';
import { Auth } from 'aws-amplify';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

import { useFeature } from '@sendpayments/react-shared/hooks/useFeature';
import { PD_1565_CARD_NUMBER } from '@sendpayments/shared-constants/features';

import { getDateFormats } from '@send-base/utilities';

const steps: StepData[] = [
  {
    heading: 'Business information',
    step: 'Business',
  },
  {
    heading: 'Representative information',
    step: 'Representative',
  },
  {
    heading: 'Identity details',
    step: 'Identity',
  },
  {
    heading: 'Expected first transfer',
    step: 'Example',
    subHeading: 'Give Send an example of how you’d expect to make your first transfer',
  },
];

interface FormMetaData {
  id: string;
  type: 'Retail' | 'Corporate';
  loginSource?: string;
  email: string;
  updatedBy: string;
  createdBy: string;
  owner: string;
  status: string;
  affiliateId?: string;
  smsActivityNotificationsEnabled?: boolean;
  smsTransferStatusNotificationsEnabled?: boolean;
  emailMarketingNotificationsEnabled?: boolean;
  emailTransferStatusNotificationsEnabled?: boolean;
  mfaReminder?: boolean;
}

export const CorporatePage = () => {
  const { user }: { user: any } = useContext(AppContext);
  // Check if user is logged in via Social Login
  const identities = user?.attributes?.identities ? JSON.parse(user.attributes.identities)[0] : {};
  const [stepForm, goToStepForm] = useState(0);
  const [loadingNext, setLoadingNext] = useState(false);
  const history = useHistory();
  // Data retrived from endpoints
  const toast = useToast();
  const [{ data: email }] = useCurrentEmail();
  // @ts-ignore need to ignore type
  const [{ data, loading: loadingRegistration }, setData] = useRegistrationByType(CoconutTypes.CORPORATE);
  const [{ data: documents }] = useDocumentsByOwner({ owner: email });

  const [{ data: settings }] = useSettings();
  const { countries, transferAmounts, transferPurposes } = settings;

  // To pull current registration stuff.
  // Marketing feature
  const currentUtmSource = getUtmSource();
  const [{ data: marketingMappings }] = useMarketingSettings({ utmSource: currentUtmSource });

  const getFormMetaData = () => {
    return {
      id: data.id,
      type: data.type,
      loginSource: identities?.providerType?.toLowerCase(),
      email,
      updatedBy: email,
      createdBy: email,
      owner: email,
      status: stepForm < 3 ? 'Incomplete' : 'New',
    };
  };

  // Can be removed in the future
  const [
    {
      data: { isEnabled: isCardNumberEnabled },
    },
  ] = useFeature({
    feature: PD_1565_CARD_NUMBER,
    functionName: 'CardNumber',
  });

  const handleCreate = async (values: CorporateDetailsData | CorporateInformationData | IdentityData | FirstTransferData) => {
    try {
      setLoadingNext(true);
      // Preparing form data
      // Meta data on the form for customer support to help live registration
      const meta = getFormMetaData();

      // Check if user registered from affiliate
      const affiliateId = localStorage.getItem('affiliateId');
      localStorage.removeItem('affiliateId');
      logger.log('CorporatePage', '(optional) affiliateId cookie 🍪', affiliateId);
      // @ts-ignore need to ignore type
      let corporate: FormMetaData & Partial<CorporateDetailsData & CorporateInformationData & IdentityData & FirstTransferData> =
        {
          ...(affiliateId && { affiliateId }),
          ...meta,
          ...values,
          ...(stepForm === 3
            ? {
                smsActivityNotificationsEnabled: false,
                smsTransferStatusNotificationsEnabled: false,
                emailMarketingNotificationsEnabled: true,
                emailTransferStatusNotificationsEnabled: true,
                mfaReminderEnabled: true,
              }
            : {}),
        };
      // @ts-ignore
      corporate = objBlankFieldsToNull(corporate);

      if (stepForm === 1 && corporate.birthDate) {
        const { string: birthDateString, unix: birthDateUnix } = getDateFormats(corporate.birthDate);
        corporate.birthDate = birthDateUnix;
        corporate.birthDateString = birthDateString;
      }

      if (stepForm === 2 && corporate.docExpiryDateString) {
        const { string: docExpiryString, unix: docExpiryUnix } = getDateFormats(corporate.docExpiryDateString);
        corporate.docExpiryDate = docExpiryUnix;
        corporate.docExpiryDateString = docExpiryString;
      }

      const registration = {
        ...data,
        ...corporate,
      };

      // Saving identity document details
      if (stepForm === 2) {
        await saveRegistrationDocument({
          documents,
          registration,
          isCardNumberEnabled,
        });
      }

      // // Marketing feature flag need to see if this is still needed or if we can not use the flag
      if (!registration.affiliateId && !registration.sendId) {
        logger.log('Corporate', 'handleCreate currentUtmSource', currentUtmSource);

        if (marketingMappings) {
          const mappedAffiliateId = marketingMappings.mappedAffiliateId;
          logger.log('Corporate', 'handleCreate mappedAffiliateId', mappedAffiliateId);

          if (mappedAffiliateId) {
            corporate.affiliateId = mappedAffiliateId;
            logger.log('Corporate', 'handleCreate setting affiliateId to ', mappedAffiliateId);
          }
        }
      }

      const cognitoUser = await Auth.currentAuthenticatedUser();

      // In case the user failed useRegistrationByType but an entity is already created in DynamoDB
      // then we replace all the data from the form above but replace the id from null to the existing ID in DynamoDB
      const { items: possibleExistingReg } = await query(queries.listCoconutsByOwner, {
        params: {
          owner: cognitoUser?.attributes?.email,
          type: { eq: 'Corporate' },
        },
        projection: {},
      });

      if (possibleExistingReg && possibleExistingReg.length > 0) {
        corporate.id = possibleExistingReg[0].id;
      }

      // Saving the state
      await saveEntity({
        entity: withPortalSource(corporate),
        type: CoconutTypes.CORPORATE,
        owner: corporate.owner,
      });

      // @ts-ignore form progress
      setData({ registration, type: CoconutTypes.CORPORATE });

      // Move to next step (might be able to save this in the state)
      if (stepForm < 4) {
        goToStepForm((prev) => prev + 1);
      }
    } catch (error) {
      logger.error('handleCreate', 'error', error);
      toast({
        variant: 'negative',
        title: 'Something went wrong on our end. Please try again!',
      });
    } finally {
      setLoadingNext(false);
    }
  };

  const handleBack = () => {
    goToStepForm((prev) => prev - 1);
  };

  const onCancel = () => {
    // Maybe save here
    history.push('/dashboard');
  };

  return (data?.status && data?.status !== 'Incomplete') || stepForm > 3 ? (
    <CongratulationsPage name={data?.firstName} />
  ) : (
    <>
      {loadingRegistration && <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="gray.900" size="xl" />}
      <StepTemplate progress={{ current: stepForm, steps: steps }} onNavigateBack={handleBack} onCancel={onCancel}>
        <Flex align="center" justify="center" display="flex" flexDirection="column">
          <Box w={{ base: '100%', md: '416px' }}>
            {stepForm === 0 && (
              <CorporateDetailsForm
                onCancel={onCancel}
                onSubmit={async (values) => {
                  await handleCreate(values);
                }}
                isLoading={loadingNext}
                initialValues={{
                  businessName: data.businessName ?? '',
                  registrationNumber: data.registrationNumber ?? '',
                  businessType: data.businessType ?? '',
                  industry: data.industry ?? '',
                  corporateStreetAddress: data.corporateStreetAddress ?? '',
                  corporateCity: data.corporateCity ?? '',
                  corporateState: data.corporateState ?? '',
                  corporatePostcode: data.corporatePostcode ?? '',
                  corporateCountry: data.corporateCountry ?? '',
                }}
              />
            )}
            {stepForm === 1 && (
              <CorporateInformationForm
                onCancel={onCancel}
                onSubmit={async (values) => {
                  await handleCreate(values);
                }}
                isLoading={loadingNext}
                showDetailedAddressSection={!!data.streetAddressName}
                initialValues={{
                  businessRole: data.businessRole ?? '',
                  firstName: data.firstName ? data.firstName : user?.attributes?.given_name,
                  middleName: data.middleName ?? '',
                  lastName: data.lastName ? data.lastName : user?.attributes?.family_name,
                  birthDate: data.birthDate ?? '',
                  countryOfBirth: data.countryOfBirth ?? '',
                  phoneCountryCode: data.phoneCountryCode ? data.phoneCountryCode : user?.attributes?.countryCode,
                  phone: data.phone ? data.phone : user?.attributes?.number,
                  streetAddressUnitNumber: data.streetAddressUnitNumber ?? '',
                  streetAddressName: data.streetAddressName ?? '',
                  streetAddressNumber: data.streetAddressNumber ?? '',
                  streetAddressType: data.streetAddressType ?? '',
                  city: data.city ?? '',
                  state: data.state ?? '',
                  postcode: data.postcode ?? '',
                  country: data.country ?? '',
                }}
              />
            )}
            {stepForm === 2 && (
              <IdentityForm
                isRepresentative
                onSubmit={async (values) => {
                  await handleCreate(values);
                }}
                isLoading={loadingNext}
                initialValues={{
                  docType: data.docType ?? '',
                  docId: data.docId ?? '',
                  docIssuingCountry: data.docIssuingCountry ?? '',
                  ...(data?.docRegion && { docRegion: data?.docRegion }),
                  ...(data?.docLicenceVersion && { docLicenceVersion: data?.docLicenceVersion }),
                  docExpiryDateString: data.docExpiryDateString ?? '',
                  termsAccepted: data.termsAccepted ?? false,
                  businessRepConfirmed: data.businessRepConfirmed ?? false,
                  ...(isCardNumberEnabled && { docCardNumber: data.docCardNumber ?? '' }),
                }}
              />
            )}
            {stepForm === 3 && (
              <FirstTransferForm
                onSubmit={async (values) => {
                  await handleCreate(values);
                }}
                isLoading={loadingNext}
                initialValues={{
                  otherPurpose: data.otherPurpose ?? '',
                  estimatedValue: data.estimatedValue ?? '',
                  currencyFrom: data.currencyFrom ?? '',
                  currencyTo: data.currencyTo ?? '',
                  countryFrom: data.countryFrom ?? '',
                  countryTo: data.countryTo ?? '',
                  purpose: data.purpose ?? '',
                  frequency: data.frequency ?? '',
                  fundsFromCustomer: data.fundsFromCustomer ?? '',
                  fundsGoingtoDiffPerson: data.fundsGoingtoDiffPerson ?? '',
                }}
                transferPurposes={transferPurposes}
                countries={countries}
                transferAmounts={transferAmounts?.filter(
                  (option) => !['<$25k', '$25k to $100k', '$100k to $500k', '$500k to $1m', '>$1m'].includes(option),
                )}
              />
            )}
          </Box>
        </Flex>
      </StepTemplate>
      <SupportCard />
    </>
  );
};
