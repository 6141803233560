import { CoconutTypes } from '@sendpayments/shared-constants/types';
import { saveEntity } from '@sendpayments/js-utils/dist/services/entities';
import { useMutation } from 'react-query';

export const useCreateOpportunity = () => useMutation(createOpportunity);

type Opportunity = {
  id?: string;
  status: 'New' | string;
  sendId?: string;
  parentId: string;
  parentType: string;
  currencyTo: string;
  currencyFrom: string;
  clientBuyAmount: number;
  clientSellAmount: number;
  clientRate: number;
  fixedSide: 'buy' | 'sell';
  owner: string;
};

const createOpportunity = (opportunity: Opportunity): Promise<Opportunity> =>
  saveEntity({
    entity: opportunity,
    type: CoconutTypes.OPPORTUNITY,
    owner: opportunity.owner,
  });
