import React from 'react';
import PropTypes from 'prop-types';
import { ListView } from '@send-legacy-components/Common/ListView';
import {
  BankDetailsPayInAccount,
  BankDetailsPayInBPay,
  BankDetailsPayInPayId,
  BankDetailsTables,
} from '@send-legacy-components/UI';
import PoliPay from './PoliPay';
import styles from './View.module.less';
import { useFeature } from '@sendpayments/react-shared/hooks/useFeature';
import { PD_1135_MONOOVA_BPAY, PD_1325_DIRECT_DEBIT, PD_2160_VRN_CREATION } from '@sendpayments/shared-constants/features';
import { getBackgroundByKey } from '@send-services/resources';
import { Link } from '@chakra-ui/react';

const dataSourcePayID = [
  {
    id: 'payID',
    iconType: 'bank',
    header: 'PayID',
    expandable: true,
  },
];

const dataSourceBPay = [
  {
    id: 'bPay',
    headImage: getBackgroundByKey({ key: 'bpay_image', default: 'static/images/bpay.svg' }),
    clickable: true,
    expandable: true,
  },
];

const dataSourceBankTransfer = [
  {
    id: 'bankTransfer',
    iconType: 'bank',
    header: 'Bank Transfer',
    expandable: true,
  },
];

const dataSourceDirectDebit = [
  {
    id: 'directDebit',
    iconType: 'credit-card',
    header: 'Direct Debit',
    clickable: true,
  },
];

const PaymentOptions = ({ currency, amount, settlementAccounts, loading, convId, complianceScore }) => {
  const [
    {
      data: { isEnabled: bPayEnabled },
    },
  ] = useFeature({
    feature: PD_1135_MONOOVA_BPAY,
    functionName: 'MonoovaBPayFunction',
    activeValue: undefined,
    defaultValue: undefined,
  });

  const [
    {
      data: { isEnabled: vrnCreationEnabled },
    },
  ] = useFeature({
    feature: PD_2160_VRN_CREATION,
    functionName: 'VRNCreationFunction',
    activeValue: undefined,
    defaultValue: undefined,
  });

  const [
    {
      data: { isEnabled: directDebitEnabled },
    },
  ] = useFeature({
    feature: PD_1325_DIRECT_DEBIT,
    functionName: 'DirectDebitFunction',
    activeValue: undefined,
    defaultValue: undefined,
  });

  //Only render BPAY option if customer is low-risk or if the transfer amount is high
  const bPayAvailable = amount > 100000 || complianceScore < 15;

  return (
    <>
      {currency === 'AUD' ? (
        <div className={styles.highlight}>
          {!vrnCreationEnabled && (
            <>
              <div style={{ marginTop: 15 }} />
              <BankDetailsPayInPayId dataSourcePayID={dataSourcePayID} amount={amount} currency={currency} convId={convId} />
            </>
          )}
          <div style={{ marginTop: 15 }} />
          <ListView dataSource={dataSourceBankTransfer}>
            <div>
              <div className="small-title">Please transfer the funds to your Send account, using the below details:</div>
              <BankDetailsPayInAccount small reference={convId} amount={amount} currency={currency} />
            </div>
          </ListView>
          {!vrnCreationEnabled && directDebitEnabled && (
            <div style={{ marginTop: 15 }}>
              <Link href={`/conversion/${convId}/funds/direct-debit`} style={{ textDecoration: 'none' }}>
                <ListView onItemClick={() => {}} dataSource={dataSourceDirectDebit} />
              </Link>
            </div>
          )}
          {!vrnCreationEnabled && (
            <>
              <div style={{ marginTop: 15 }} />
              <PoliPay convId={convId} />
            </>
          )}
          {!vrnCreationEnabled && bPayEnabled && bPayAvailable && (
            <>
              <div style={{ marginTop: 15 }} />
              <BankDetailsPayInBPay dataSourceBPay={dataSourceBPay} amount={amount} currency={currency} convId={convId} />
              <div style={{ marginTop: 15 }} />
            </>
          )}
        </div>
      ) : (
        <>
          <div className="small-title" style={{ marginTop: 10 }}>
            Please pay the settlement payment to Send by using the below details:
          </div>
          <BankDetailsTables
            small
            loading={loading}
            bankDetails={{ ...settlementAccounts[currency], payId: undefined }}
            amount={amount}
            currency={currency}
          />
        </>
      )}
    </>
  );
};

PaymentOptions.propTypes = {
  currency: PropTypes.string,
  amount: PropTypes.number,
  settlementAccounts: PropTypes.object,
  loading: PropTypes.bool,
  sendId: PropTypes.string,
  convId: PropTypes.string,
  complianceScore: PropTypes.number,
};

PaymentOptions.defaultProps = {
  currency: '',
  amount: 0,
  loading: false,
  settlementAccounts: {},
  sendId: undefined,
  convId: undefined,
  complianceScore: undefined,
};

export default PaymentOptions;
