import { CoconutTypes } from '@sendpayments/shared-constants/types';
import { query } from '@send-base/utilities/endpoint';
import * as queries from '@sendpayments/graphql/queries';
import { useQuery, UseQueryOptions } from 'react-query';

export const queryKeysSetting = {
  setting: ['setting'],
};

export const useSetting = ({
  options,
}: {
  options?: UseQueryOptions<Setting>;
} = {}) => useQuery<Setting>(queryKeysSetting.setting, () => getSetting(), { ...options });

const getSetting = () =>
  query(queries.getCoconut, {
    params: { id: 'Setting', type: CoconutTypes.SETTING },
    projection: {},
  });

export type Setting = {
  id: string;
  type: string;
  owner: string;
  createdBy?: string;
  updatedBy: string;
  createdAt?: string;
  updatedAt: string;
  features: string[];
  affiliates: string[];
  unqualifiedReasons: string[];
  transferPurposes: string[];
  transferAmounts: string[];
  countries: string[];
  currencies: string[];
  pricingPlanBankRateComparison: number;
  pricingPlanBankSendingFee: number;
  customerDailyTradeLimit: number;
  highRiskCustomerDailyTradeLimit: number;
  customerMinTradeValue: number;
  blacklistedCurrencies: string[];
  poaDocInstructions?: string;
  poiPhotoDocInstructions?: string;
  poiDocInstructions?: string;
};
