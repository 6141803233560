import React, { FC } from 'react';
import {
  Box,
  Link,
  Button,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Heading,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { InputField, Select, ValueContainer } from '@sendpayments/react-shared/components/molecules';
import { useToast } from '@sendpayments/react-shared/components/molecules/Toast';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { businessTypes, industries } from '@sendpayments/js-utils/dist/const/defaults';
import merge from 'lodash.merge';
import { SendForm } from '../types';
import { logger } from '@sendpayments/js-utils/dist/services/logger';
import { analytics } from '@sendpayments/js-utils/dist';
import { CountrySelect, SimpleModal } from '@send-base/atomic-components/organisms';
import { EarthIcon, ChevronIcon } from '@sendpayments/react-shared/components/Icons';
import { appConfig } from '@send-base/app.config';

const businessTypeOptions = businessTypes.map((value) => ({
  value: value,
  label: value,
}));

const industryOptions = industries.map((value) => ({
  value: value,
  label: value,
}));

const address = {
  corporateStreetAddress: '',
  corporateCity: '',
  corporateState: '',
  corporatePostcode: '',
  corporateCountry: '',
};

const fields = {
  businessName: '',
  registrationNumber: '',
  businessType: '',
  industry: '',
  ...address,
};

const corporateDetailsSchema = yup.object({
  businessName: yup.string().trim().required('Please enter your business name'),
  registrationNumber: yup.string().trim().required('Please enter your registration number'),
  businessType: yup.string().required('Please select your business type'),
  industry: yup.string().required('Please select your industry'),
  corporateStreetAddress: yup.string().trim().required('Please enter your street address'),
  corporateCity: yup.string().trim().required('Please enter your city'),
  corporateState: yup.string().trim().required('Please select your state'),
  corporatePostcode: yup.string().trim().required('Please enter your post code/zip code'),
  corporateCountry: yup.string().trim().required('Please select your country'),
});

export type CorporateDetailsData = yup.InferType<typeof corporateDetailsSchema> & { birthDateString?: string };
export interface CorporateDetailsFormProps extends SendForm<CorporateDetailsData> {
  onCancel: () => void;
  showDetailedAddressSection?: boolean;
}

export const CorporateDetailsForm: FC<CorporateDetailsFormProps> = (props) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box>
      <Formik
        initialValues={merge(fields, props.initialValues)}
        validateOnMount={false}
        validationSchema={corporateDetailsSchema}
        onSubmit={async (values, actions) => {
          try {
            await props?.onSubmit(values);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            analytics.push({ action: { type: 'form_submit', data: { name: 'CorporateDetailsForm' } } });
          } catch (error) {
            logger.error('CorporateDetailsForm', 'ERROR: ', error);
            actions.setSubmitting(false);
            toast({
              variant: 'negative',
              title: 'Something went wrong on our end. Please try again!',
            });
          }
        }}
      >
        {({ isSubmitting, setFieldValue }) => (
          <>
            <SimpleModal
              open={isOpen}
              primaryButtonText="Proceed"
              secondaryButtonText="Cancel onboarding"
              onClose={onClose}
              onSecondaryAction={() => props.onCancel()}
              onPrimaryAction={() => {
                Object.keys(address).map((key) => setFieldValue(`${key}`, ''));
                onClose();
              }}
              key={'ValidCountryModal'}
            >
              <EarthIcon color="text.base" boxSize={{ base: '57px', lg: '83px' }} />
              <Heading as="h3" variant="heading3" mt={4}>
                We are sorry, we don&apos;t serve your country of residence yet.
              </Heading>
              <Text as="span">If you have a primary address that is on our </Text>
              <Link as="a" fontSize="md" color="text.base" pr={1} href={appConfig.sendSupportedCountriesUrl} isExternal>
                list of countries we serve,
              </Link>
              <Text as="span">please choose &apos;proceed&apos; and input your primary address details.</Text>
            </SimpleModal>
            <Form noValidate>
              <Stack spacing={4} textAlign="left">
                {/* Business name */}
                <Field name="businessName">
                  {({ field, form }) => (
                    <InputField
                      id={field.name}
                      label="Business name"
                      placeholder="Enter your business name"
                      isInvalid={form.errors[field.name] && form.touched[field.name]}
                      isValid={!form.errors[field.name] && !!field.value}
                      errorText={form.errors[field.name]}
                      inputProps={{ type: 'text', size: 'md', ...field }}
                    />
                  )}
                </Field>

                {/* Registration number */}
                <Field name="registrationNumber">
                  {({ field, form }) => (
                    <InputField
                      id={field.name}
                      label="Business registration number"
                      placeholder="Enter your business registration number"
                      isInvalid={form.errors[field.name] && form.touched[field.name]}
                      isValid={!form.errors[field.name] && !!field.value}
                      errorText={form.errors[field.name]}
                      inputProps={{
                        type: 'text',
                        size: 'md',
                        ...field,
                      }}
                    />
                  )}
                </Field>

                {/* Business type */}
                <Field name="businessType">
                  {({ field, form }) => (
                    <FormControl id={field.name} isInvalid={form.errors[field.name] && form.touched[field.name]}>
                      <FormLabel mb={1}>Business type</FormLabel>
                      <Select
                        placeholder={'Select your business type'}
                        value={businessTypeOptions.find((item: { value: string }) => item.value === field.value)}
                        options={businessTypeOptions}
                        onChange={(option) => field.onChange(field.name)(option?.value)}
                        width="100%"
                        isInvalid={form.errors[field.name] && form.touched[field.name]}
                        components={{ ValueContainer }}
                      />
                      <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                {/* Industry */}
                <Field name="industry">
                  {({ field, form }) => (
                    <FormControl id={field.name} isInvalid={form.errors[field.name] && form.touched[field.name]}>
                      <FormLabel mb={1}>Industry</FormLabel>
                      <Select
                        placeholder={'Select your industry'}
                        value={industryOptions.find((item: { value: string }) => item.value === field.value)}
                        options={industryOptions}
                        onChange={(option) => field.onChange(field.name)(option?.value)}
                        width="100%"
                        isInvalid={form.errors[field.name] && form.touched[field.name]}
                        components={{ ValueContainer }}
                      />
                      <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                {/* Website */}
                <Field name="website">
                  {({ field, form }) => (
                    <InputField
                      id={field.name}
                      label="Website"
                      placeholder="Enter your website"
                      isInvalid={form.errors[field.name] && form.touched[field.name]}
                      isValid={!form.errors[field.name] && !!field.value}
                      errorText={form.errors[field.name]}
                      inputProps={{
                        type: 'text',
                        size: 'md',
                        ...field,
                      }}
                    />
                  )}
                </Field>

                {/* Corporate Street Address */}
                <Field name="corporateStreetAddress">
                  {({ field, form }) => (
                    <InputField
                      id={field.name}
                      label="Street address"
                      placeholder="Enter your street address"
                      isInvalid={form.errors[field.name] && form.touched[field.name]}
                      isValid={!form.errors[field.name] && !!field.value}
                      errorText={form.errors[field.name]}
                      inputProps={{
                        type: 'text',
                        size: 'md',
                        ...field,
                      }}
                    />
                  )}
                </Field>

                {/* Corporate City */}
                <Field name="corporateCity">
                  {({ field, form }) => (
                    <InputField
                      id={field.name}
                      label="City"
                      placeholder="Enter your city"
                      isInvalid={form.errors[field.name] && form.touched[field.name]}
                      isValid={!form.errors[field.name] && !!field.value}
                      errorText={form.errors[field.name]}
                      inputProps={{
                        type: 'text',
                        size: 'md',
                        ...field,
                      }}
                    />
                  )}
                </Field>

                {/* Corporate State */}
                <Field name="corporateState">
                  {({ field, form }) => (
                    <InputField
                      id={field.name}
                      label="State / Province"
                      placeholder="Enter your state or province"
                      isInvalid={form.errors[field.name] && form.touched[field.name]}
                      isValid={!form.errors[field.name] && !!field.value}
                      errorText={form.errors[field.name]}
                      inputProps={{
                        type: 'text',
                        size: 'md',
                        ...field,
                      }}
                    />
                  )}
                </Field>

                {/* Corporate Post Code / Zip Code */}
                <Field name="corporatePostcode">
                  {({ field, form }) => (
                    <InputField
                      id={field.name}
                      label="Post code / Zip code"
                      placeholder="Enter your post code or zip code"
                      isInvalid={form.errors[field.name] && form.touched[field.name]}
                      isValid={!form.errors[field.name] && !!field.value}
                      errorText={form.errors[field.name]}
                      inputProps={{
                        type: 'text',
                        size: 'md',
                        ...field,
                      }}
                    />
                  )}
                </Field>

                {/* Corporate Country */}
                <Field name="corporateCountry">
                  {({ field, form }) => (
                    <CountrySelect
                      id={field.name}
                      label="Country"
                      value={field.value}
                      onCountryChange={(option) => {
                        if (option?.value && !appConfig.supportedCountries.includes(option?.value)) {
                          onOpen();
                        } else {
                          field.onChange(field.name)(option?.value);
                        }
                      }}
                      placeholder={'Select your country...'}
                      isInvalid={form.errors[field.name] && form.touched[field.name]}
                      errorText={form.errors[field.name]}
                    />
                  )}
                </Field>
                <Button
                  isLoading={isSubmitting || props.isLoading}
                  type="submit"
                  alignSelf="center"
                  fontSize="md"
                  rightIcon={<ChevronIcon h="8px" w="6px" direction="forward" />}
                >
                  Next
                </Button>
              </Stack>
            </Form>
          </>
        )}
      </Formik>
    </Box>
  );
};
