import React from 'react';
import { SignIn } from 'aws-amplify-react';
import { CloseAccountModal } from '@send-components/CloseAccountModal';
import history from '@send-legacy-app/history';
import { SignInPage } from '../../../atomic-components/pages';
class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this.onDismissCloseAccountModal = this.onDismissCloseAccountModal.bind(this);
  }

  onDismissCloseAccountModal = () => {
    history.replace('/auth', null);
  };

  showComponent() {
    return (
      <>
        {history.location.state?.closeAccountModalisOpen && (
          <CloseAccountModal onDismissCloseAccountModal={this.onDismissCloseAccountModal} />
        )}
        <SignInPage changeState={this.changeState} checkContact={this.checkContact} />
      </>
    );
  }
}

export default CustomSignIn;
