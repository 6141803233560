import React, { useState } from 'react';
import { AuthenticationTemplate } from '../../templates';
import { Stack, Text } from '@chakra-ui/react';
import { RegisterForm, WelcomeBackForm } from '@send-base/atomic-components/organisms';
import { SocialLogin } from '@send-base/atomic-components/molecules';
import { AlertBox } from '@sendpayments/react-shared/components/molecules';
import { logger } from '@sendpayments/js-utils/dist';

import { getPortalSource } from '@send-services/white-label';

import { ISignUpProps } from 'aws-amplify-react/lib-esm/Auth/SignUp';
import { AuthPiece, IAuthPieceState } from 'aws-amplify-react/lib-esm/Auth/AuthPiece';
import Auth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import history from '@send-legacy-app/history';
import { RegistrationFormStack } from '@send-base/atomic-components/organisms/RegistrationFormStack';

interface RegisterPageProps {
  changeState: AuthPiece<ISignUpProps, IAuthPieceState>['changeState'];
}

interface RegisterFormValues {
  email: string;
  password: string;
}

export interface AlertBoxData {
  message?: string;
  type?: string;
}

interface IsWelcomeData {
  source?: 'facebook' | 'google';
  email?: string;
}

export const useTrackingId = () => {
  const [trackingId, setTrackingId] = useState<null | string>(null);
  const acceptedParams = ['source', 'mozo_id', 'cid', '_ctmtransaction_id'];

  React.useEffect(() => {
    const { search } = history.location;
    const queryList = search?.split?.('?') || [];

    queryList.forEach((query) => {
      const params = new URLSearchParams(query);

      params.forEach((value, key) => {
        if (acceptedParams.includes(key)) {
          setTrackingId(value);
        }
      });
    });
  }, []);

  return trackingId;
};

const RegisterPage: React.FC<RegisterPageProps> = ({ changeState }) => {
  const [alertBox, setAlertBox] = useState<AlertBoxData>({ message: undefined, type: undefined });
  const [isLoading, setLoading] = useState(false);
  const trackingId = useTrackingId();

  const [isWelcome, setIsWelcome] = useState<IsWelcomeData>({});

  const onSocialLogin = (provider: 'Google' | 'Facebook') => {
    const providers = {
      Google: CognitoHostedUIIdentityProvider.Google,
      Facebook: CognitoHostedUIIdentityProvider.Facebook,
    };
    if (trackingId) {
      window.localStorage.setItem('trackingId', trackingId);
    }
    Auth.federatedSignIn({ provider: providers[provider] });
  };

  const handleSubmit = async (values: RegisterFormValues) => {
    setLoading(true);

    try {
      const signupInfo = {
        username: values.email?.trim().toLowerCase(),
        password: values.password,
        attributes: { 'custom:source': 'Portal', 'custom:portalSource': getPortalSource() },
      };

      if (trackingId) {
        signupInfo.attributes['custom:trackingId'] = trackingId;
      }

      if (!signupInfo.password || !signupInfo.username) throw new Error('Cannot authenticate');
      await Auth.signUp(signupInfo);
      // changeState('confirmSignUp', { username: signupInfo.username, password: signupInfo.password });

      if (trackingId) {
        window.localStorage.setItem('trackingId', trackingId);
      }

      const loggedUser = await Auth.signIn(signupInfo.username, signupInfo.password);
      changeState('signedIn', loggedUser);
    } catch (error) {
      logger.error('Register OnSubmit', 'error signing up', error);
      // @ts-ignore email already registered.
      if (error.name === 'UsernameExistsException' || String(error).includes('PreSignUp')) {
        // eslint-disable-next-line no-console
        console.log('error', error);
        if (String(error).includes('Google')) {
          setAlertBox({ message: 'This email is already registered with Google.\nPlease log in.', type: 'error' });
          setIsWelcome({ source: 'google', email: values.email });
        } else if (String(error).includes('Facebook')) {
          setAlertBox({ message: 'This email is already registered with Facebook.\nPlease log in.', type: 'error' });
          setIsWelcome({ source: 'facebook', email: values.email });
        } else {
          setAlertBox({ message: 'This email is already registered.\nPlease log in.', type: 'error' });
        }
      }
    }
    setLoading(false);
  };

  return (
    <>
      {trackingId !== null ? (
        <RegistrationFormStack
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          onSocialLogin={onSocialLogin}
          changeState={() => changeState('signIn')}
          alertBox={alertBox}
        />
      ) : (
        <AuthenticationTemplate heading={isWelcome?.source ? 'Welcome back' : 'Get started'}>
          <Stack spacing={8}>
            {isWelcome?.source ? (
              <WelcomeBackForm
                isWelcome={isWelcome}
                setIsWelcome={setIsWelcome}
                onSocialLogin={onSocialLogin}
                changeState={changeState}
                page="register"
              />
            ) : (
              <>
                <Text align="center" fontSize="md">
                  <Text as="span">{'Already registered? '}</Text>
                  <Text as="a" fontWeight="semibold" fontSize="md" color="primary.base" onClick={() => changeState('signIn')}>
                    Log in
                  </Text>
                </Text>
                {!!alertBox.message && (
                  <AlertBox
                    maxW="100%"
                    my="4"
                    title=""
                    alignSelf="center"
                    variant={alertBox.type === 'success' ? 'neutral' : 'negative'}
                    description={alertBox.message}
                    showIcon
                  />
                )}
                <RegisterForm onSubmit={handleSubmit} isLoading={isLoading} initialValues={{ email: '', password: '' }} />
                <SocialLogin content="Or" onSocialLogin={onSocialLogin} />
              </>
            )}
          </Stack>
        </AuthenticationTemplate>
      )}
    </>
  );
};

export { RegisterPage, RegisterPageProps, RegisterFormValues };
