import { useRegistration, useVRNs } from '@send-base/data-hooks';
import {
  VRN_CURRENCIES as ALLOWED_VRN_CURRENCIES,
  VRN_GENERAL_ACKNOWLEDGEMENT_LIMIT_DATES,
} from '@sendpayments/shared-constants/vrn';
import React, { useEffect } from 'react';
import { useFeature } from '@sendpayments/react-shared/hooks/useFeature';
import { PD_2160_VRN_CREATION } from '@sendpayments/shared-constants/features';
import dayjs from 'dayjs';
import { API } from 'aws-amplify';
import { graphqlOperationEx } from '@sendpayments/graphql/fields';
import * as mutations from '@sendpayments/graphql/mutations';
import styles from './VRNAcknowlegementBanner.module.less';
import { Icon, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { appConfig } from '@send-base/app.config';
const { Text } = Typography;

const VRNAcknowledgementBanner = () => {
  const [visibleCurrencyBanners, setVisibleCurrencyBanners] = React.useState([]);
  const [{ data: account }] = useRegistration();
  const [{ data: vrns }] = useVRNs();
  const [
    {
      data: { isEnabled: vrnEnabled },
    },
  ] = useFeature({
    feature: PD_2160_VRN_CREATION,
    functionName: 'VRNCreationFunction',
  });

  useEffect(() => {
    if (vrnEnabled && vrns?.length) {
      const acknowledgementForCurrencies = account?.vrnLegalAcknowledgement ? JSON.parse(account.vrnLegalAcknowledgement) : {};
      const allowedCurrencies = vrns
        .filter((vrn) => ALLOWED_VRN_CURRENCIES.includes(vrn.currency)) // this filter will make sure only system wide enabled currencies are supported
        .filter((vrn) => !acknowledgementForCurrencies[vrn.currency]) // this filter will make sure dismissed acknowlegement will not show
        .filter((vrn) => dayjs().isBefore(dayjs(VRN_GENERAL_ACKNOWLEDGEMENT_LIMIT_DATES[vrn.currency]))) // this filter will make sure banner for a given currency will not show after the date limit
        .map((vrn) => vrn.currency);
      setVisibleCurrencyBanners(allowedCurrencies);
    }
  }, [vrns, vrnEnabled, account]);

  const handleDismiss = React.useCallback(
    (currency) => () => {
      const accountUpdates = {
        id: account.id,
        type: account.type,
        vrnLegalAcknowledgement: JSON.stringify({ [currency]: new Date().toISOString() }),
        updatedBy: account.email,
      };
      API.graphql(
        graphqlOperationEx(mutations.updateCoconut, {
          input: accountUpdates,
        }),
      );
      const bannersCopy = [...visibleCurrencyBanners];
      setVisibleCurrencyBanners(bannersCopy.filter((currency) => currency !== currency));
    },
    [account],
  );

  if (visibleCurrencyBanners?.length) {
    return (
      <>
        {visibleCurrencyBanners.map((currency) => (
          <div key={currency} className={styles.banner}>
            <div className={styles.messageWrapper}>
              <Icon type="info-circle" className={styles.icon} />
              <Text strong className={styles.message}>
                {`We've updated our ${currency} pay in bank details. Please pay attention when transferring money to Send via bank transfer. For more information, please review your emails or click`}
                &nbsp;
                <Link to={{ pathname: appConfig.sendPaymentMethods }} target={'_blank'}>
                  <strong style={{ fontSize: '0.9rem', textDecoration: 'underline' }}>here</strong>
                </Link>
              </Text>
            </div>
            <button className={styles.closeBtn} onClick={handleDismiss(currency)}>
              <Icon type="close" theme="outlined" size="small" />
            </button>
          </div>
        ))}
      </>
    );
  } else {
    return <></>;
  }
};

export default VRNAcknowledgementBanner;
