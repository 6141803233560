/* eslint-disable no-console */
import React from 'react';
import PropType from 'prop-types';
import { message } from 'antd';
import { logger as loggerService } from '@sendpayments/js-utils/dist/services/logger';
import { uploadS3All } from '@sendpayments/js-utils/dist/services/s3-manager';
import { saveEntity } from '@sendpayments/js-utils/dist/services/entities/save-entity';
import { ListItem } from '@send-legacy-components/RequestDocument';
import dayjs from 'dayjs';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);

const logger = loggerService.loggerIn('@RequestDocument');

const onValidateFileHandler = (file) => {
  return new Promise((resolve) => {
    const magicNumbers = {
      pdf: [37, 80, 68, 70],
      png: [137, 80, 78, 71, 13, 10, 26, 10],
      jpg: [255, 216, 255],
      gif: [71, 73, 70, 56],
      bmp: [66, 77],
      doc: [208, 207, 17, 224],
      docx: [80, 75, 3, 4],
    };

    const mimeTypes = {
      pdf: 'application/pdf',
      png: 'image/png',
      jpg: 'image/jpeg',
      gif: 'image/gif',
      bmp: 'image/bmp',
      doc: 'application/msword',
      docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    };

    const reader = new FileReader();
    reader.onloadend = (event) => {
      if (file.size > 10000000) {
        resolve('The supplied file size exceeds the maximum limit of 10 MB.');
        return;
      }

      const arr = new Uint8Array(event.target.result);
      const isMagicNumberValid = Object.entries(magicNumbers).some(([type, magicNumber]) =>
        magicNumber.every((val, index) => val === arr[index]),
      );
      const isMimeTypeValid = Object.values(mimeTypes).includes(file.type);

      resolve(
        isMagicNumberValid && isMimeTypeValid
          ? undefined
          : 'The supplied file must be either PDF, JPEG, JPG, PNG, BMP, GIF, DOC or DOCX.',
      );
    };

    reader.readAsArrayBuffer(file.slice(0, 8));
  });
};

const RequestDocument = ({ owner, document, onIsListLoaded }) => {
  const onFilesSubmittedHandler = async (fileList, { onStartUploading, onProgress, onFinishUploading, onErrorUploading }) => {
    const groupLogger = logger.groupIn('onFilesSubmittedHandlerHandler');
    onStartUploading();
    const newFileList = fileList.map((file) => {
      const ext = file.name.split('.')[file.name.split('.').length - 1];
      const name = file.name.replace(`.${ext}`, '');
      const uid = Date.now();
      return {
        ...file,
        name: `${name}-${uid}.${ext}`,
      };
    });
    const result = await uploadS3All(newFileList, {
      // this is so we do not get to the 100% and we leave some margin for the save entity
      onProgress: ({ percentage }) => (percentage > 90 ? onProgress({ percentage: 90 }) : onProgress({ percentage })),
    });

    // remove any possible error from S3
    const paths = result.filter(({ withError }) => !withError).map(({ path }) => path);

    groupLogger.log('paths to upload to document', paths);

    if (result.some(({ withError }) => withError)) {
      groupLogger.error(
        'some errors coming from s3',
        result.map(({ withError }) => withError).filter((error) => !error),
      );
    }

    if (result.some(({ withError }) => withError) || paths.length === 0) {
      message.error("The files can't be uploaded. Please try again later.");

      onProgress({ percentage: 100 });
      onErrorUploading();
      onIsListLoaded(false);

      return;
    }

    // show some progress before finishing off the action
    onProgress({ percentage: 95 });

    /// save all the files references to client into dynamoDb
    await saveEntity({
      entity: {
        ...document,
        docKeys: [...paths],
        docUploadedBy: owner,
        docUploadedDate: dayjs().format('X'),
        docStatus: 'Uploaded',
      },
      type: CoconutTypes.DOCUMENT,
    });

    onProgress({ percentage: 100 });
    onFinishUploading();
    onIsListLoaded(false);
  };

  return (
    <ListItem
      document={document}
      onFilesSubmitted={onFilesSubmittedHandler}
      onValidateFile={onValidateFileHandler}
      onIsListLoaded={onIsListLoaded}
    />
  );
};

RequestDocument.propTypes = {
  owner: PropType.string,
  document: PropType.object,
  onIsListLoaded: PropType.func.isRequired,
};

RequestDocument.defaultProps = {
  owner: undefined,
  document: undefined,
};

export default RequestDocument;
