import React from 'react';

export const BankDetails = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5 17H5V8H5.5C5.776 8 6 7.776 6 7.5C6 7.224 5.776 7 5.5 7H1.5C1.224 7 1 7.224 1 7.5C1 7.776 1.224 8 1.5 8H2V17H1.5C1.224 17 1 17.224 1 17.5C1 17.776 1.224 18 1.5 18H5.5C5.776 18 6 17.776 6 17.5C6 17.224 5.776 17 5.5 17ZM4 17H3V8H4V17Z"
        fill="black"
      />
      <path
        d="M11.5 17H11V8H11.5C11.776 8 12 7.776 12 7.5C12 7.224 11.776 7 11.5 7H7.5C7.224 7 7 7.224 7 7.5C7 7.776 7.224 8 7.5 8H8V17H7.5C7.224 17 7 17.224 7 17.5C7 17.776 7.224 18 7.5 18H11.5C11.776 18 12 17.776 12 17.5C12 17.224 11.776 17 11.5 17ZM10 17H9V8H10V17Z"
        fill="black"
      />
      <path
        d="M17.5 17H17V8H17.5C17.776 8 18 7.776 18 7.5C18 7.224 17.776 7 17.5 7H13.5C13.224 7 13 7.224 13 7.5C13 7.776 13.224 8 13.5 8H14V17H13.5C13.224 17 13 17.224 13 17.5C13 17.776 13.224 18 13.5 18H17.5C17.776 18 18 17.776 18 17.5C18 17.224 17.776 17 17.5 17ZM16 17H15V8H16V17Z"
        fill="black"
      />
      <path
        d="M18.5 20H0.5C0.224 20 0 19.776 0 19.5C0 19.224 0.224 19 0.5 19H18.5C18.776 19 19 19.224 19 19.5C19 19.776 18.776 20 18.5 20Z"
        fill="black"
      />
      <path
        d="M17.5001 5.99999H1.50006C1.27706 5.99999 1.08106 5.85199 1.01906 5.63799C0.957056 5.42399 1.04506 5.19399 1.23506 5.07599L9.23506 0.0759941C9.39706 -0.0250059 9.60306 -0.0250059 9.76506 0.0759941L17.7651 5.07599C17.9541 5.19399 18.0421 5.42299 17.9811 5.63799C17.9201 5.85299 17.7231 5.99999 17.5001 5.99999ZM3.24306 4.99999H15.7561L9.49906 1.08999L3.24206 4.99999H3.24306Z"
        fill="black"
      />
    </svg>
  );
};
