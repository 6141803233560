import React, { ReactElement } from 'react';
import { Box, Text, Flex, Input, useRadio } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

export interface RadioCardProps {
  radioProps: any;
  title?: string;
  description?: string | ReactElement;
  icon?: ReactElement;
  showError: boolean;
}

export const RadioCard: React.FC<RadioCardProps> = ({ radioProps, title, description, showError, icon }) => {
  const { getInputProps, getCheckboxProps } = useRadio(radioProps);
  const inputProps = getInputProps();
  const checkboxProps = getCheckboxProps();
  const borderColor = showError ? 'error' : 'border.base';

  return (
    <Box
      as="label"
      {...checkboxProps}
      display="flex"
      flexDirection="column"
      h={{ base: '154px', sm: '166px' }}
      cursor="pointer"
      border={1}
      borderStyle="solid"
      borderColor={borderColor}
      borderRadius={5}
      p={5}
      mt="5px"
      mx="5px"
      position="relative"
      maxW={{ base: '343px', md: '238px' }}
      _hover={{
        borderColor: 'focus',
      }}
      _checked={{
        borderColor: 'focus',
        background: 'secondary.light',
      }}
    >
      <Flex>
        {icon}
        <Input {...inputProps} display="none" />
        <CheckIcon
          {...checkboxProps}
          _checked={{
            background: 'primary.base',
            color: 'white',
          }}
          w={7}
          h={7}
          border={2}
          borderStyle="solid"
          borderColor="primary.base"
          borderRadius="50%"
          background="white"
          color="white"
          right={6}
          top={8}
          position="absolute"
        />
      </Flex>
      <Flex flexDirection="column" textAlign="left" mt={'auto'}>
        <Text as="p" fontWeight="bold" fontSize="md">
          {title}
        </Text>
        <Text as="p" fontSize="md">
          {description}
        </Text>
      </Flex>
    </Box>
  );
};
