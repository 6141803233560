import React, { useEffect } from 'react';
import { LegacyLayout } from '@send-components/LegacyLayout';
import { SubMenu, Item } from '@send-components/SubMenu';
import { Checkbox } from '@send-components/Checkbox';
import { View } from '@adobe/react-spectrum';
import { useRegistration } from '@send-base/data-hooks';
import { logger } from '@sendpayments/js-utils/dist/services/logger';
import { saveEntity } from '@sendpayments/js-utils/dist/services/entities';
import { triggerToast } from '@send-components/Toast';
import Info from '@spectrum-icons/workflow/Info';
import { breakpoints } from '@sendpayments/react-shared/design-system';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { Auth, API } from 'aws-amplify';
import PhoneReminders from '@send-components/Account/PhoneReminders/PhoneReminders';
import history from '@send-legacy-app/history';
import TransferStatusSection from './TransferStatusSection';
import accountStyles from '../Account.module.less';
import SettingItem from './SettingItem';

const getIdToken = async () => {
  return (await Auth.currentSession()).idToken.jwtToken;
};

const getAccessToken = async () => {
  return (await Auth.currentSession()).accessToken.jwtToken;
};

const NotificationSetting = () => {
  const { isMedia } = useBreakpoint(breakpoints);
  const isMobile = isMedia({ to: breakpoints.xs });
  const [{ data: account }] = useRegistration();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSmsActivityNotificationSelected, setIsSmsActivityNotificationSelected] = React.useState(
    account.smsActivityNotificationsEnabled,
  );
  const [isEmailMarketingNotificationsSelected, setIsEmailMarketingNotificationsSelected] = React.useState(
    account.emailMarketingNotificationsEnabled,
  );

  useEffect(() => {
    setIsSmsActivityNotificationSelected(account.smsActivityNotificationsEnabled);
  }, [account.smsActivityNotificationsEnabled]);

  useEffect(() => {
    setIsEmailMarketingNotificationsSelected(account.emailMarketingNotificationsEnabled);
  }, [account.emailMarketingNotificationsEnabled]);

  /**
   * @param {boolean} isSelected
   */
  async function updateSmsActivityNotification(isSelected) {
    try {
      if (account.id) {
        await saveEntity({ entity: { id: account.id, smsActivityNotificationsEnabled: isSelected }, type: account.type });
      }
      triggerToast({
        type: 'success',
        message: 'Successfully updated SMS notification settings',
        rightIcon: <Info size="M" />,
      });
    } catch (ex) {
      logger.error('updateSmsActivityNotification', ex);
      triggerToast({
        type: 'error',
        message: 'Could not update SMS notification settings',
        rightIcon: <Info size="M" />,
      });
    }
  }

  return (
    <>
      <LegacyLayout activeMenu="setting">
        <SubMenu>
          <Item routerTo="/setting/profile">Profile</Item>
          <Item routerTo="/setting/security">Password and security</Item>
          <Item routerTo="/setting/notification">Notification</Item>
        </SubMenu>
        <div className={accountStyles.container}>
          <div className={accountStyles.header}>
            <div className={accountStyles.title}>Alerts and notifications</div>
            <View>Let us know how you’d like to hear from us</View>
          </div>

          <View marginBottom="size-300" width="100%">
            <PhoneReminders
              account={account}
              onPhoneAdd={() => {
                history.push({
                  pathname: '/setting/profile',
                  state: {
                    openPhoneToast: true,
                  },
                });
              }}
              onCompleteVerify={async () => {
                logger.log('Completed verify');
                // Reload
                window.location.reload();
              }}
            />
          </View>

          <TransferStatusSection triggerToast={triggerToast} />

          <SettingItem
            isMobile={isMobile}
            title="Account activity"
            desc="Important notifications about your account as they happen."
          >
            <Checkbox
              isSelected={isSmsActivityNotificationSelected}
              isDisabled={!account.phoneNumberVerified && !isSmsActivityNotificationSelected}
              onChange={(isSelected) => {
                if (!account.phoneNumberVerified) {
                  triggerToast({
                    type: 'error',
                    message: 'Mobile number not verified',
                    rightIcon: <Info size="M" />,
                  });
                  logger.error('updateSmsActivityNotification', 'Mobile number not verified');
                  setIsSmsActivityNotificationSelected(false);
                  return;
                }
                setIsSmsActivityNotificationSelected(isSelected);
                updateSmsActivityNotification(isSelected);
              }}
            >
              SMS
            </Checkbox>
            <Checkbox isDisabled isSelected>
              Email
            </Checkbox>
          </SettingItem>
          <SettingItem
            isMobile={isMobile}
            title="News and updates"
            desc="The latest announcements, product updates, and special offers from Send."
          >
            <Checkbox isDisabled>SMS</Checkbox>
            <Checkbox
              isDisabled={isLoading}
              isSelected={isEmailMarketingNotificationsSelected}
              onChange={async (isSelected) => {
                if (!account.email) {
                  triggerToast({
                    type: 'error',
                    message: 'no email on account',
                    rightIcon: <Info size="M" />,
                  });
                  return;
                }
                setIsLoading(true);
                const idToken = await getIdToken();
                const accessToken = await getAccessToken();

                try {
                  const response = await API.post('SendApi', `/mailChimp/changeSubscription`, {
                    headers: {
                      Token: idToken,
                    },
                    body: {
                      userEmail: account.email,
                      isSubscribed: isSelected ?? false,
                      accessToken,
                    },
                  });
                  logger.log('useMarketingEmails', 'Marketing status succeeded', response);
                  triggerToast({
                    type: 'success',
                    message: 'Marketing setting updated',
                  });

                  setIsEmailMarketingNotificationsSelected(isSelected);
                } catch (ex) {
                  logger.error('useMarketingEmails', 'Error updating marketing settings', ex);
                  triggerToast({
                    type: 'error',
                    message: 'Error updating marketing settings',
                    rightIcon: <Info size="M" />,
                  });
                }

                setIsLoading(false);
              }}
            >
              Email
            </Checkbox>
          </SettingItem>
        </div>
      </LegacyLayout>
    </>
  );
};

export default NotificationSetting;
