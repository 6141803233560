import { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import * as subscriptions from '@sendpayments/graphql/subscriptions';
import { graphqlOperationEx } from '@sendpayments/graphql/fields';
import { logger } from '@sendpayments/js-utils/dist';
import * as queries from '@sendpayments/graphql/queries';

const usePayInPayId = ({ userAccount: { id, type, email } }) => {
  const [payInPayIdDetail, setPayInPayIdDetail] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (payInPayIdDetail.payInPayIdStatus !== 'Active') {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [payInPayIdDetail]);

  useEffect(() => {
    if (id && type) {
      API.graphql(graphqlOperationEx(queries.getCoconut, { id, type }))
        .then(({ data: { getCoconut: account } }) => {
          const { payInPayIdStatus, payInPayIdAlias } = account;
          if (payInPayIdStatus !== 'Active') {
            setLoading(true);
          }
          setPayInPayIdDetail({
            payInPayIdStatus,
            payInPayIdAlias,
          });
        })
        .catch((error) => logger.error(error));
    }
  }, [id, type]);

  useEffect(() => {
    // create subscribe
    const subscription = API.graphql(
      graphqlOperationEx(
        subscriptions.onCreateCoconutByOwnerType,
        {
          owner: email,
          type,
        },
        ['payInPayIdStatus'],
      ),
    ).subscribe({
      next: (coconut) => {
        const { payInPayIdStatus } = coconut.value.data.onCreateCoconutByOwnerType;

        setPayInPayIdDetail({
          payInPayIdStatus,
        });
      },
      error: (err) => logger.error('create', 'payInPayIdStatus', err),
    });
    // update subscribe
    const updateSubscription = API.graphql(
      graphqlOperationEx(
        subscriptions.onUpdateCoconutByOwnerType,
        {
          owner: email,
          type,
        },
        ['payInPayIdStatus'],
      ),
    ).subscribe({
      next: (coconut) => {
        const { payInPayIdStatus } = coconut.value.data.onUpdateCoconutByOwnerType;
        setPayInPayIdDetail({
          payInPayIdStatus,
        });
      },
      error: (err) => logger.error('update', 'payInPayIdStatus', err),
    });
    return function cleanup() {
      subscription?.unsubscribe();
      updateSubscription?.unsubscribe();
    };
  }, []);

  return [payInPayIdDetail, loading];
};

export default usePayInPayId;
