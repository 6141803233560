import React from 'react';
import { Link, ProgressCircle } from '@adobe/react-spectrum';
import cn from 'classnames';
import { withTheme } from '@sendpayments/react-shared/components/ThemeProvider';
import styles from './PrimaryLink.module.less';

const PrimaryLink = ({ children, isDisabled = false, isLoading = false, onPress, ...props }) => {
  const isDisablePress = isDisabled || isLoading;
  return (
    <>
      <Link
        onPress={!isDisablePress && onPress}
        UNSAFE_className={cn(styles.container, isDisabled && styles.disabled)}
        {...props}
      >
        {children}
      </Link>
      {isLoading && <ProgressCircle aria-label="Loading…" isIndeterminate size="S" marginStart={5} />}
    </>
  );
};
PrimaryLink.displayName = 'PrimaryLink';

const InlineWraper = (props) => {
  const Comp = withTheme(PrimaryLink);

  return (
    <span style={{ display: 'inline-block' }}>
      <Comp {...props} />
    </span>
  );
};

export default InlineWraper;
