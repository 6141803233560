import { useRemoteDataSelector } from '@sendpayments/react-shared/hooks/useRemoteData';
import { docStatus } from '@send-const/statuses';
import { useDocumentsByOwner } from '../useDocumentsByOwner';

const filterDocuments = (documents) =>
  documents.data.filter((item) => item.docStatus === docStatus.REQUESTED && item.showToClient === true);

const usePendingDocuments = ({ owner }) => {
  const [documents, , createOrUpdateDocuments] = useDocumentsByOwner({ owner });

  const [pendingDocuments] = useRemoteDataSelector(() => filterDocuments(documents), [documents]);

  return [pendingDocuments, createOrUpdateDocuments];
};

export default usePendingDocuments;
