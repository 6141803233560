import { useState } from 'react';
import { useRegistration, useDirectDebit, FormState } from '@send-base/infrastructure/query';
import { ConversionResponse } from '@send-base/atomic-components/pages/DirectDebitPage';

const getAccountName = (registration: ReturnType<typeof useRegistration>['data']): string => {
  let accountName = '';
  if (registration) {
    switch (registration.type) {
      case 'Retail': {
        accountName = `${registration?.firstName} ${registration?.lastName}`;
        return accountName;
      }
      case 'Corporate': {
        accountName = registration?.businessName || '';
        return accountName;
      }
      default: {
        return accountName;
      }
    }
  }
  return accountName;
};

export const useDirectDebitForm = ({
  registration,
  conversion,
}: {
  registration: ReturnType<typeof useRegistration>['data'];
  conversion: ConversionResponse | undefined;
}) => {
  const { mutate, isError: isPostError, isLoading, isSuccess: isPostSuccess, reset } = useDirectDebit();

  const accountName = getAccountName(registration);

  const formInitialData = {
    bankDetails: {
      accountName,
      accountNumber: '',
      bsbNumber: '',
    },
    termsAndConditions: true,
  };

  const [formData, setFormData] = useState<FormState>(formInitialData);

  const amount = (conversion?.bookedSellAmount || 0) as number;

  const handleSubmit = (formData: FormState) => {
    const finalPostData = {
      registrationId: registration?.id || '',
      registrationType: registration?.type || '',
      conversionId: conversion?.id || '',
      amount,
      ...formData,
    };
    mutate(finalPostData);
  };

  return {
    amount,
    accountName,
    isLoading,
    isPostSuccess,
    isPostError,
    formData,
    setFormData,
    handleSubmit,
    reset,
  };
};
