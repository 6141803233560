import { Box, Text } from '@chakra-ui/react';
import { StaticNotification } from '@send-base/atomic-components/atoms';
import CSSReset from '@send-base/atomic-components/pages/utils/CSSReset';
import { useRegistration } from '@send-data-hooks';
import history from '@send-legacy-app/history';
import { Confirmation, useConfirmation } from '@send-legacy-components/Common/Confirmation';
import { Confirmation as VRNConfirmation } from '@send-legacy-components/Common/Confirmation/VRN/Confirmation';
import { Icon, sendIconTypes } from '@send-legacy-components/Common/Icon';
import { StepContainer, Steps, StepsProvider, footerButtonsPositions, useSteps } from '@send-legacy-components/Common/Steps';
import {
  ConfirmRecipient,
  ConfirmTrade,
  FundsInDetails,
  NewRecipient,
  ReviewAmount,
  SelectRecipient,
} from '@send-legacy-containers/DealerAssist';
import { useFeature } from '@sendpayments/react-shared/hooks/useFeature';
import { PD_2160_VRN_CREATION } from '@sendpayments/shared-constants/features';
import { VRN_CURRENCIES } from '@sendpayments/shared-constants/vrn';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { StepsLayout } from './StepLayout';
import styles from './Steps.module.less';
import { saveEntity } from '@sendpayments/js-utils/dist/services/entities';
import dayjs from 'dayjs';
import { VRN_CURRENCIES as ALLOWED_VRN_CURRENCIES } from '@sendpayments/shared-constants/vrn';

const id = 'transfer';

const steps = {
  AMOUNT: 1,
  REVIEW_AMOUNT: 2,
  TRANSFER: 3,
  NEW_RECIPIENT: 4,
  RECIPIENT: 5,
  PAYMENT: 6,
};

const PaymentAlert = () => {
  return (
    <>
      <CSSReset />
      <chakra-scope>
        <Box mb={4}>
          <StaticNotification>
            <Text as="span">{`We've updated our bank details. `}</Text>
            <Text as="span" fontWeight="bold">
              Please pay attention when transferring money to Send via bank transfer.
            </Text>
          </StaticNotification>
        </Box>
      </chakra-scope>
    </>
  );
};

const DealerAssistSteps = ({ tradeDetails }) => {
  const [
    isVisibleTradeConfirmation,
    { setConfirmationVisible: setTradeConfirmationVisible, setConfirmationHidden: setTradeConfirmationHidden },
  ] = useConfirmation();

  const [
    isVisibleRecipientConfirmation,
    { setConfirmationVisible: setRecipientConfirmationVisible, setConfirmationHidden: setRecipientConfirmationHidden },
  ] = useConfirmation();

  const [
    {
      data: { isEnabled: isCreateVRNEnabled },
    },
  ] = useFeature({
    feature: PD_2160_VRN_CREATION,
    functionName: 'VRNDetailsCreate',
  });

  const [
    isVisibleUpdatedBankDetailsConfirmation,
    {
      setConfirmationVisible: setUpdatedBankDetailsConfirmationVisible,
      setConfirmationHidden: setUpdatedBankDetailsConfirmationHidden,
    },
  ] = useConfirmation();

  const initialState =
    (tradeDetails && {
      index: steps.REVIEW_AMOUNT,
    }) ||
    undefined;

  const [{ currentIndex }, { setStepIndex, getStepResult, setStepResult }] = useSteps({ id, initialState });
  const [selectedRecipient, setSelectedRecipient] = useState();
  const [
    {
      data: {
        id: idReg,
        status: registrationStatus,
        type,
        currencyCloudReference,
        sendId,
        complianceScore,
        unknown,
        vrnLegalAcknowledgement,
      },
    },
  ] = useRegistration();

  const onStepsCloseButtonClick = () => {
    if (currentIndex < steps.TRANSFER) {
      return setTradeConfirmationVisible();
    }
    if (currentIndex >= steps.TRANSFER) {
      return setRecipientConfirmationVisible();
    }
    return undefined;
  };

  const onStepsFinishedHandler = () => {
    const stepConversion = getStepResult(steps.REVIEW_AMOUNT);
    history.push({ pathname: `/conversion/${stepConversion.id}` });
  };

  const onCancelTrade = () => {
    history.push({ pathname: `/dashboard` });
    setTradeConfirmationHidden();
  };

  const onViewActivities = () => {
    history.push({ pathname: `/activity` });
    setRecipientConfirmationHidden();
  };

  const onBookClickHandler = (data) => {
    setStepIndex(steps.REVIEW_AMOUNT);
    setStepResult(steps.AMOUNT, data);
  };

  const onRecipientClick = (recipientId) => {
    setSelectedRecipient(recipientId);
  };

  const onReviewRecipient = (recipient) => {
    setStepResult(steps.RECIPIENT, {
      name: recipient.businessName || `${recipient.firstName} ${recipient.lastName}`,
      ...recipient,
    });
    setStepIndex(steps.RECIPIENT);
  };

  const onCancelNewRecipient = () => {
    setSelectedRecipient(undefined);
    setStepIndex(steps.TRANSFER);
  };

  const onEditClick = () => {
    setStepIndex(steps.AMOUNT);
  };

  const onEditRecipientClick = () => {
    setStepIndex(steps.TRANSFER);
  };

  const onContinueRecipient = () => {
    setRecipientConfirmationHidden();
  };

  const onContinuePayment = async () => {
    await saveEntity({
      entity: {
        id: idReg,
        vrnLegalAcknowledgement: JSON.stringify({
          AUD: dayjs().format(),
        }),
      },
      type: type,
    });

    setUpdatedBankDetailsConfirmationHidden();
  };

  const onAddRecipientLater = () => {
    setStepIndex(steps.PAYMENT);
  };

  useEffect(() => {
    if (selectedRecipient) {
      setStepIndex(steps.NEW_RECIPIENT);
    }
  }, [selectedRecipient]);

  useEffect(() => {
    if (tradeDetails) {
      setStepResult(steps.AMOUNT, tradeDetails);
    }
  }, [tradeDetails]);

  useEffect(() => {
    if (unknown === true) {
      history.push({ pathname: `/dashboard` });
    }
  }, [unknown]);

  useEffect(() => {
    if (currentIndex >= steps.PAYMENT) {
      const vrnAcknowledgement = JSON.parse(vrnLegalAcknowledgement)?.[fromCurrency] ? true : false;
      if (ALLOWED_VRN_CURRENCIES.includes(fromCurrency) && isCreateVRNEnabled && !vrnAcknowledgement)
        return setUpdatedBankDetailsConfirmationVisible();
    }
  }, [currentIndex]);

  const stepAmount = getStepResult(steps.AMOUNT);
  const { fromCurrency, toCurrency, fromAmount, toAmount, rate, invertedRate, fixedAmount } = stepAmount || {};

  const stepConversion = getStepResult(steps.REVIEW_AMOUNT);
  const { id: conversionId, conversionDate } = stepConversion || {};

  const stepRecipient = getStepResult(steps.RECIPIENT);

  const {
    id: paymentId,
    name,
    accountNumber,
    bsbCode,
    email,
    sortCodeNumber,
    reason,
    reference,
    beneficiaryBankAccountCountry,
    iban,
    beneficiaryType,
  } = stepRecipient || {};

  const accountActive = ['Registered', 'Dealing', 'Recipient Awaiting Docs'].includes(registrationStatus);

  let showAlert = fromCurrency === 'AUD';
  if (isCreateVRNEnabled) {
    showAlert = VRN_CURRENCIES.includes(fromCurrency);
  }

  return (
    <>
      {isVisibleTradeConfirmation && (
        <Confirmation
          title="Attention"
          bodyText="Are you sure you'd like to cancel this transfer?"
          bodySubtext="You will lose your rate and need to start again."
          onOkClick={onCancelTrade}
          okButtonText="Cancel trade"
          onCancelClick={setTradeConfirmationHidden}
          cancelButtonText="Continue"
        />
      )}
      {isVisibleRecipientConfirmation && (
        <Confirmation
          title="Attention"
          bodyText="Are you sure you’d like to add a recipient later?"
          bodySubtext="You can add a recipient on the dashboard."
          onOkClick={onViewActivities}
          okButtonText="View activities"
          onCancelClick={onContinueRecipient}
          cancelButtonText="Continue"
        />
      )}
      {isVisibleUpdatedBankDetailsConfirmation && <VRNConfirmation okButtonText="Confirm" onOkClick={onContinuePayment} />}
      <Steps
        id={id}
        footerButtonsPosition={footerButtonsPositions.CENTER}
        onStepsFinished={onStepsFinishedHandler}
        onStepsCloseButtonClick={onStepsCloseButtonClick}
        footerClassName={styles.footer}
        LayoutComponent={StepsLayout}
      >
        <StepContainer
          title="How much would you like to transfer?"
          name="amount"
          position="Amount"
          icon={<Icon type={sendIconTypes.PROGRESS_AMOUNT} />}
          index={steps.AMOUNT}
          showNext={false}
        >
          <ConfirmTrade
            showBookButton={accountActive}
            onBookClick={onBookClickHandler}
            initialFromCurrency={fromCurrency}
            initialToCurrency={toCurrency}
            initialFromAmount={fromAmount}
            initialFixedAmount={fixedAmount}
          />
        </StepContainer>
        <StepContainer
          title="Review details of your transfer"
          name="transfer"
          position="Transfer"
          icon={<Icon type={sendIconTypes.PROGRESS_TRANSFER} />}
          index={steps.REVIEW_AMOUNT}
          showBack={false}
          nextButtonText="Confirm transfer"
          footerText={`Please note that while Send does not charge you any transfer fees,
            we are not able to control fees passed to you by third-party banks which you may
            be charged for. Unfortunately, Send does not receive any notice that these fees are
            charged. This may impact the final amount you receive in your account. Your transfer
            is a legally binding contract and is subject to our Terms and Conditions. If you breach
            our Terms and Conditions, Send reserves the right to terminate your transfer and recover
            any losses from you.`}
          callOnSaveImperativeHandler
        >
          <ReviewAmount
            onEditClick={onEditClick}
            fromAmount={fromAmount}
            fromCurrency={fromCurrency}
            toAmount={toAmount}
            toCurrency={toCurrency}
            rate={rate}
            parentId={idReg}
            parentType={type}
            fixedAmount={fixedAmount}
            invertedRate={invertedRate}
          />
        </StepContainer>
        <StepContainer
          title="Who are you transferring money to?"
          name="recipient"
          position="Recipient"
          icon={<Icon type={sendIconTypes.PROGRESS_RECIPIENT} />}
          showBack={false}
          nextButtonText="Add a recipient later"
          index={steps.TRANSFER}
          onButtonNextClick={onAddRecipientLater}
        >
          <SelectRecipient
            parentId={idReg}
            parentType={type}
            onRecipientClick={onRecipientClick}
            onReviewRecipient={onReviewRecipient}
            toCurrency={toCurrency}
          />
        </StepContainer>
        <StepContainer
          name="addRecipient"
          containerSize={{ lg: 24 }}
          title="Add a new recipient"
          showBack={false}
          showNext={false}
          hiddenStep
          index={steps.NEW_RECIPIENT}
        >
          <NewRecipient
            type={selectedRecipient}
            fixedCurrencyTo={toCurrency}
            onSave={onReviewRecipient}
            onCancel={onCancelNewRecipient}
          />
        </StepContainer>
        <StepContainer
          name="confirmRecipient"
          title="Is this the correct recipient?"
          showBack={false}
          nextButtonText="Confirm recipient"
          index={steps.RECIPIENT}
          callOnSaveImperativeHandler
        >
          <ConfirmRecipient
            onEditClick={onEditRecipientClick}
            name={name}
            accountNumber={accountNumber}
            bsbCode={bsbCode}
            email={email}
            sortCodeNumber={sortCodeNumber}
            toCurrency={toCurrency}
            bankCountry={beneficiaryBankAccountCountry}
            beneficiaryType={beneficiaryType}
            reason={reason}
            reference={reference}
            parentId={idReg}
            parentType={type}
            conversionId={conversionId}
            paymentDate={conversionDate}
            toAmount={toAmount}
            iban={iban}
          />
        </StepContainer>
        <StepContainer
          title={isCreateVRNEnabled ? 'You’re all set to make your payment' : 'How would you like to pay?'}
          name="payment"
          position="Pay"
          icon={<Icon type={sendIconTypes.PROGRESS_PAYMENT} />}
          submitButtonText="Pay later"
          showNext={false}
          backButtonText="Add recipient now +"
          onButtonBackClick={() => setStepIndex(steps.TRANSFER)}
          showBack={!paymentId}
          index={steps.PAYMENT}
          alert={showAlert && <PaymentAlert />}
          footerText={
            <>
              <p>
                Unless previously agreed, we only accept payments from your bank account. We do not accept payments via cash or
                cheques under any circumstances.
              </p>
              <p>
                Please note that while Send does not charge you any transfer fees, we are not able to control fees passed to you
                by third-party banks which you may be charged for. Unfortunately, Send does not receive any notice that these fees
                are charged. This may impact the final amount you receive in your account.
              </p>
              <p>Your transfer is a legally binding contract and is subject to our Terms and Conditions.</p>
              <p>
                If you breach our Terms and Conditions, Send reserves the right to terminate your transfer and recover any losses
                from you.
              </p>
            </>
          }
        >
          <FundsInDetails
            currency={fromCurrency}
            amount={fromAmount}
            sellAmount={fromAmount}
            userId={idReg}
            userType={type}
            currencyCloudReference={currencyCloudReference}
            sendId={sendId}
            convId={stepConversion && stepConversion.id}
            complianceScore={complianceScore}
            isCreateVRNEnabled={isCreateVRNEnabled}
          />
        </StepContainer>
      </Steps>
    </>
  );
};

DealerAssistSteps.propTypes = {
  tradeDetails: PropTypes.shape({
    fromCurrency: PropTypes.string,
    toCurrency: PropTypes.string,
    fromAmount: PropTypes.number,
    toAmount: PropTypes.number,
    rate: PropTypes.number,
    quoteId: PropTypes.string,
    conversionDate: PropTypes.string,
  }),
};

DealerAssistSteps.defaultProps = {
  tradeDetails: undefined,
};

const StepsScreen = () => {
  const tradeDetails = history.location.state;
  return (
    <StepsProvider>
      <DealerAssistSteps tradeDetails={tradeDetails} />
    </StepsProvider>
  );
};

export default StepsScreen;
