/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { Row, Button, Icon } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { HyperLink } from '@send-legacy-components/Common/HyperLink';

function Block(props) {
  const Parent = !props.clickable ? Row : props.href ? HyperLink : Link;
  return (
    <Parent
      to={props.clickable ? props.link : ''}
      href={props.href}
      className={`block ${props.clickable ? 'clickable' : ''} ${props.disabled ? 'disabled' : ''}`}
      style={{ textAlign: props.notification ? 'center' : 'inherit' }}
    >
      {!props.notification && (
        <div className="left">
          <Button className="transparent icon-only small">
            <Icon type={props.disabled ? 'lock' : props.clickable ? props.icon : 'clock-circle'} />
          </Button>
        </div>
      )}
      <div className="center">
        <h5 className="bold" style={{ fontSize: props.notification ? 20 : 'inherit' }}>
          {props.header}
        </h5>
        <p>{props.description}</p>
      </div>
      {props.clickable && (
        <div className="right">
          <Icon type="right" />
        </div>
      )}
    </Parent>
  );
}

Block.propTypes = {
  icon: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  clickable: PropTypes.bool,
  disabled: PropTypes.bool,
  link: PropTypes.string,
  notification: PropTypes.bool,
  href: PropTypes.string,
};

Block.defaultProps = {
  clickable: false,
  disabled: false,
  notification: false,
  link: '',
  href: null,
};

export default Block;
