import React from 'react';
import { Radio } from '@adobe/react-spectrum';
import { withTheme } from '@sendpayments/react-shared/components/ThemeProvider';

const PrimaryRadioButton = ({ value }) => {
  return (
    <Radio aria-label="radio" value={value}>
      {value}
    </Radio>
  );
};

PrimaryRadioButton.displayName = 'PrimaryRadioButton';

export default withTheme(PrimaryRadioButton);
