import { CoconutTypes } from '@sendpayments/shared-constants/types';
import { query } from '@send-base/utilities/endpoint';
import * as queries from '@sendpayments/graphql/queries';
import { buildUniqueKey } from '@sendpayments/js-utils/dist/services/build-unique-key';
import { useRemoteData } from '@sendpayments/react-shared/hooks/useRemoteData';
import { useCurrentEmail } from '../useCurrentEmail';

const fetchSettings = async () => {
  const response = await query(queries.getCoconut, {
    params: { id: 'Setting', type: CoconutTypes.SETTING },
  });

  return response;
};
/**
 * @return {[
 *  {
 *    data: {
 *      currencies?: Array<string>,
 *      blacklistedCurrencies?: Array<string>,
 *      countries?: Array<string>,
 *      transferAmounts?: Array<string>,
 *      transferPurposes?: Array<string> & ['Other']
 *    }
 *  }
 *]}
 */
const useSettings = () => {
  const [{ data: email }] = useCurrentEmail();

  return useRemoteData(
    () => ({
      key: buildUniqueKey('queries.getCoconut', { type: CoconutTypes.SETTING }),
      onInitialFetch: fetchSettings,
      initialState: {},
    }),
    [email],
  );
};

export default useSettings;
