import React from 'react';
import PropTypes from 'prop-types';
import { appConfig } from '@send-base/app.config';

export const SFooter = (props) => (
  <div className="footer" style={props.style ? props.style : {}}>
    <p className="small">{new Date().getFullYear()} @ SendFX Limited ACN 617 647 220 (AFSL: 509635)</p>
    <p>
      <a href={appConfig.sendTermsAndConditionsUrl} rel="noopener noreferrer" target="_blank">
        Terms and Conditions
      </a>
      <span style={{ display: 'inline-block', width: 10 }} />
      <a href={appConfig.sendPrivacyAndPolicyUrl} rel="noopener noreferrer" target="_blank">
        Privacy Policy
      </a>
      <span style={{ display: 'inline-block', width: 10 }} />
      <a href={appConfig.sendFinancialServicesGuideUrl} rel="noopener noreferrer" target="_blank">
        Financial Services Guide
      </a>
      <span style={{ display: 'inline-block', width: 10 }} />
      <a href={appConfig.sendProductDisclosureStatementUrl} rel="noopener noreferrer" target="_blank">
        Product Disclosure Statements
      </a>
    </p>
    <br />
  </div>
);

SFooter.propTypes = {
  style: PropTypes.object,
};

SFooter.defaultProps = {
  style: null,
};
