import React, { ReactNode } from 'react';
import { Flex, Heading, Box, Center, Link } from '@chakra-ui/react';
import { ThemedImage } from '@send-legacy-components/Common';
import { Footer } from '../../atoms';
interface AuthenticationTemplateProps {
  heading?: string;
  children: ReactNode;
}

const AuthenticationTemplate: React.FC<AuthenticationTemplateProps> = ({ heading = '', children }) => (
  <Flex
    minH="100vh"
    backgroundImage={{ base: 'none', lg: "url('/static/images/background.jpg')" }}
    backgroundPosition="center"
    backgroundRepeat="no-repeat"
    backgroundSize="contain"
    backgroundBlendMode="multiply"
    backgroundColor={{ base: 'none', lg: 'rgba(0, 0, 0, 0.5)' }}
    p={{ base: 4, lg: 8 }}
    direction="column"
  >
    <Link to="/login" className="logo">
      <ThemedImage alt="logo" file="logo.svg" path={null} />
    </Link>
    <Center pt={{ base: 20, lg: '80px' }}>
      <Box bg="white" borderRadius={8} p={{ base: 0, md: '49' }} w="100%" maxW="514px">
        <Heading as="h2" variant="heading2" mb={1} fontSize={36} textAlign="center" lineHeight="44px" color="#1B2221">
          {heading}
        </Heading>
        {children}
        <Footer mt={4} />
      </Box>
    </Center>
  </Flex>
);

export { AuthenticationTemplate, AuthenticationTemplateProps };
