import React from 'react';
import { Box, Text } from '@chakra-ui/react';

export interface AlternativeOptionsProps {
  content: string;
}

const AlternativeOptions: React.FC<AlternativeOptionsProps> = (props: AlternativeOptionsProps) => {
  return (
    <Box width="100%" display="flex" justifyContent="center" alignItems="center" flexDirection="column" position="relative">
      <Box bgColor="#D3D3D3" height="1px" width="100%" position="absolute"></Box>
      <Text bgColor="white" zIndex="1" pl="21px" pr="21px">
        {props.content}
      </Text>
    </Box>
  );
};

export { AlternativeOptions };
