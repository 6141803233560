import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Icon } from 'antd';
import classNames from 'classnames';
import styles from './StepsLayout.module.less';

const StepsLayout = ({ title, index, children, steps, containerSize: { xs, lg } }) => {
  if (steps[index - 1].name !== 'recipientConfirmation') {
    return (
      <Row>
        <Col md={24} lg={{ span: 16, offset: 4 }}>
          <Row>
            {steps
              .filter(({ position }) => position)
              .map((step) => (
                <div
                  key={step.index}
                  className={classNames(
                    step.position && styles[step.position.toLowerCase()],
                    styles.toolbar,
                    step.index > 1 && styles.spaceIcons,
                    step.index > index && styles.ahead,
                  )}
                >
                  {step.index > index - 1 ? step.icon : <Icon type="check-circle" />}
                  <span
                    className={classNames(styles.text, step.index < index && styles.passed, step.index > index && styles.ahead)}
                  >
                    {step.position}
                  </span>
                </div>
              ))}
          </Row>
          <Row className="flex">
            <h1 className="bold">{title}</h1>
          </Row>
          <Row className={styles.container} type="flex" justify="center">
            <Col xs={xs || 24} lg={lg || 14}>
              {children}
            </Col>
          </Row>
          <br />
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col md={24} lg={{ span: 16, offset: 4 }}>
        <Row className={styles.container} type="flex" justify="center">
          <Col xs={xs || 24} lg={lg || 14}>
            {children}
          </Col>
        </Row>
        <br />
      </Col>
    </Row>
  );
};

StepsLayout.propTypes = {
  steps: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  containerSize: PropTypes.object,
};

StepsLayout.defaultProps = {
  containerSize: { xs: 24, lg: 14 },
};

export default StepsLayout;
