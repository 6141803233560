import { appConfig } from '@send-base/app.config';

/**
 * We are keeping it in sync with the API, as api cache it for 30 seconds
 * Also the quote is valid for 1 day so 30 seconds is more than sufficient.
 */
const rateStaleTime = 1000 * 30;

export const exchangeWidgetConfig = {
  defaultFirstCurrency: appConfig.firstDefaultCurrency,
  defaultSecondCurrency: appConfig.secondDefaultCurrency,

  initialFromAmount: 500,
  initialFromCurrency: appConfig.defaultWidgetCurrency,
  initialFromCurrencyDecimal: 2,
  initialToAmount: 0,
  initialToCurrency: appConfig.secondDefaultCurrency,
  initialCurrencyToDecimal: 2,
  popularCurrencies: appConfig.popularCurrencies,
  rateQuery: {
    staleTime: rateStaleTime,
  },
  messages: {
    minAmountError: 'This amount is below the minimum transfer value.',
    maxAmountError: 'This exceeds the maximum online transfer amount, please call your account manager to book.',
    rateFetchError:
      'Unable to retrieve rate data. Please refresh the page or contact your account manager for further assistance.',
    currencyPairNotSupportedError:
      'This currency pair is not supported right now. Contact your account manager if you require further assistance.',
  },
};
