import { Storage } from 'aws-amplify';
import { useEffect, useState } from 'react';

const useProfilePhoto = (key) => {
  const [profilePhoto, setProfilePhoto] = useState('');

  useEffect(() => {
    if (key) {
      Storage.get(key, { level: 'private' }).then((imgUrl) => {
        setProfilePhoto(imgUrl);
      });
    }
  }, [key]);

  return profilePhoto;
};

export default useProfilePhoto;
