import { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { logger } from '@sendpayments/js-utils/dist';
import { graphqlOperationEx, VirtualAccountFields } from '@sendpayments/graphql/fields';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
import * as queries from '@sendpayments/graphql/queries';
import * as subscriptions from '@sendpayments/graphql/subscriptions';
import { useFeature } from '@sendpayments/react-shared/hooks/useFeature';
import { PD_2160_VRN_CREATION } from '@sendpayments/shared-constants/features';
import { VRN_CURRENCIES, VRN_BANK_DETAILS } from '@sendpayments/shared-constants/vrn';
import { VRN_ROUTING_TYPES } from '@sendpayments/shared-constants/types';

const usePayInAccount = ({ userAccount: { id, type, email }, currency }) => {
  const [payInDetail, setPayInDetail] = useState({});
  const [loading, setLoading] = useState(true);

  const [
    {
      data: { isEnabled: isCreateVRNEnabled },
    },
  ] = useFeature({
    feature: PD_2160_VRN_CREATION,
    functionName: 'VRNDetailsCreate',
  });

  useEffect(() => {
    if (payInDetail.payInABAStatus !== 'Active') {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [payInDetail]);

  const setMonoovaPayInDetails = (account) => {
    const { payInABAStatus, payInAccNo, payInAccountName, payInBSB } = account;

    if (payInABAStatus !== 'Active') {
      setLoading(true);
    }

    setPayInDetail({
      payInABAStatus,
      payInAccNo,
      payInAccountName,
      payInBSB,
    });
  };

  useEffect(() => {
    if (id && type) {
      API.graphql(graphqlOperationEx(queries.getCoconut, { id, type }))
        .then(({ data: { getCoconut: account } }) => {
          // Check if we have to get data from Dynamo or VRN
          if (isCreateVRNEnabled && email && VRN_CURRENCIES.includes(currency)) {
            const params = {
              filter: { type: { eq: CoconutTypes.VIRTUAL_ACCOUNT }, owner: { eq: email }, currency: { eq: currency } },
            };
            // Get data from VRN
            API.graphql(graphqlOperationEx(queries.searchCoconutsEx, params, VirtualAccountFields))
              .then(({ data: { searchCoconutsEx: response } }) => {
                const accountItems = response.items ?? [];
                if (Array.isArray(accountItems) && accountItems.length > 0) {
                  let bsbRoutingDetails = undefined;
                  const virtualAccount = accountItems.find((account) => {
                    const routingDetails = JSON.parse(account.routingDetails) ?? [];
                    bsbRoutingDetails = routingDetails.find(
                      (routingDetail) => routingDetail.routingNumberType === VRN_ROUTING_TYPES.AU_BSB,
                    );
                    return bsbRoutingDetails ? true : false;
                  });

                  if (virtualAccount && bsbRoutingDetails) {
                    const accountDetails = JSON.parse(virtualAccount.accountDetails)[0] ?? undefined;
                    if (accountDetails) {
                      setPayInDetail({
                        payInABAStatus: 'Active',
                        payInAccNo: accountDetails.accountNumber,
                        payInAccountName: VRN_BANK_DETAILS['AUD'].accountName,
                        payInSwiftCode: VRN_BANK_DETAILS['AUD'].swiftCode,
                        payInBSB: bsbRoutingDetails.routingNumber,
                      });

                      return;
                    }
                  }
                }

                setMonoovaPayInDetails(account);
              })
              .catch((error) => {
                logger.error(error);
                setMonoovaPayInDetails(account);
              });
          } else {
            setMonoovaPayInDetails(account);
          }
        })
        .catch((error) => logger.error(error));
    }
  }, [id, type, email]);

  useEffect(() => {
    if (email) {
      // create subscribe
      const subscription = API.graphql(
        graphqlOperationEx(
          subscriptions.onCreateCoconutByOwnerType,
          {
            owner: email,
            type,
          },
          ['payInABAStatus'],
        ),
      ).subscribe({
        next: (coconut) => {
          const { payInABAStatus } = coconut.value.data.onCreateCoconutByOwnerType;
          setPayInDetail({
            payInABAStatus,
          });
        },
      });
      // update subscribe
      const updateSubscription = API.graphql(
        graphqlOperationEx(
          subscriptions.onUpdateCoconutByOwnerType,
          {
            owner: email,
            type,
          },
          ['payInABAStatus'],
        ),
      ).subscribe({
        next: (coconut) => {
          const { payInABAStatus } = coconut.value.data.onUpdateCoconutByOwnerType;
          setPayInDetail({
            payInABAStatus,
          });
        },
      });
      return function cleanup() {
        subscription.unsubscribe();
        updateSubscription.unsubscribe();
      };
    }

    return undefined;
  }, [email]);

  return [payInDetail, loading];
};

export default usePayInAccount;
