import React, { useState } from 'react';
import { ListView, Icon, CloseButton } from '@send-legacy-components/Common';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Menu as MenuAntd, Avatar, Dropdown, Badge, Layout } from 'antd';
import { MediaBreakpoint, appBreakpoints } from '@sendpayments/react-shared/components/MediaBreakpoint';
import styles from './Menu.module.less';

const { Sider } = Layout;

const mobileMenuPositions = {
  RIGHT: 'right',
  LEFT: 'left',
};

const Menu = ({
  dataSource,
  loading,
  icon,
  badgeNumber,
  className,
  overlayClassName,
  onItemClick,
  emptyMessage,
  mobileMenuPosition,
  enableItemMobileView,
}) => {
  const [collapsed, setCollapsed] = useState(true);

  const renderMenu = () => (
    <MenuAntd className={classNames(styles.menuOverlay, overlayClassName)}>
      {dataSource.length > 0 && (
        <ListView dataSource={dataSource} onItemClick={onItemClick} enableMobileView={enableItemMobileView} />
      )}
      {dataSource.length === 0 && <div className={styles.empty}>{emptyMessage}</div>}
    </MenuAntd>
  );

  const renderIcon = () => (
    <div className={classNames(badgeNumber > 9 && styles.badgeNumberLarge)}>
      {badgeNumber && <Badge count={Math.min(badgeNumber, 99)} />}
      {!badgeNumber && loading && <Badge count={<Icon type="sync" spin />} />}
      <MediaBreakpoint to={appBreakpoints.md}>
        <Avatar className={styles.iconAvatar} size="large" icon={icon} onClick={() => setCollapsed(false)} />
      </MediaBreakpoint>
      <MediaBreakpoint from={appBreakpoints.md}>
        <Avatar className={styles.iconAvatar} size="large" icon={icon} />
      </MediaBreakpoint>
    </div>
  );

  return (
    <>
      <MediaBreakpoint to={appBreakpoints.md}>
        <div className={classNames(styles.menu, className)}>{renderIcon()}</div>
        {!collapsed && <div className={styles.mobileMenuOverlay} onClick={() => setCollapsed(!collapsed)} />}
        <Sider
          collapsedWidth="0"
          collapsible
          defaultCollapsed
          collapsed={collapsed}
          className={classNames(styles.mobileMenu, styles[mobileMenuPosition])}
          width={307}
        >
          <div style={{ height: 'inherit' }}>{renderMenu()}</div>
          {!collapsed && <CloseButton onClick={() => setCollapsed(!collapsed)} />}
        </Sider>
      </MediaBreakpoint>
      <MediaBreakpoint from={appBreakpoints.md}>
        <div className={classNames(styles.menu, className)}>
          <Dropdown overlay={renderMenu()}>{renderIcon()}</Dropdown>
        </div>
      </MediaBreakpoint>
    </>
  );
};

Menu.propTypes = {
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  dataSource: PropTypes.array.isRequired,
  icon: PropTypes.string.isRequired,
  badgeNumber: PropTypes.number,
  onItemClick: PropTypes.func,
  loading: PropTypes.bool,
  emptyMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  mobileMenuPosition: PropTypes.oneOf(Object.values(mobileMenuPositions)),
  enableItemMobileView: PropTypes.bool,
};

Menu.defaultProps = {
  className: undefined,
  overlayClassName: undefined,
  badgeNumber: undefined,
  onItemClick: () => {},
  loading: false,
  emptyMessage: 'You have an empty list',
  mobileMenuPosition: mobileMenuPositions.RIGHT,
  enableItemMobileView: true,
};

export default Menu;
