import React from 'react';
import { Text, Flex, Input, useRadio, ListItem, ListItemProps } from '@chakra-ui/react';
import { RadioOption } from '@send-base/atomic-components/organisms/RadioList/RadioList';

export type HorizontalRadioItemProps = {
  value: RadioOption['value'];
  children: RadioOption['title'];
  radioProps: any;
  error?: boolean;
} & ListItemProps;

export const HorizontalRadioItem: React.FC<HorizontalRadioItemProps> = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props.radioProps);

  const inputProps = getInputProps();
  const checkboxProps = getCheckboxProps();

  return (
    <ListItem
      as="label"
      cursor="pointer"
      {...checkboxProps}
      display="flex"
      justifyContent="center"
      listStyleType="none"
      _hover={{
        bg: 'tertiary.light',
        transition: 'background .15s',
      }}
      _checked={{
        background: 'tertiary.light',
      }}
      transition="background .15s"
      minWidth="83.2px"
      padding="7px 18px"
      minHeight={12}
      border="light"
      borderColor={props.error ? 'red.400' : 'gray.400'}
      lineHeight="1.4"
      {...props}
    >
      <Flex flexDirection="column" textAlign="center" justifyContent="center">
        <Input {...inputProps} display="none" />
        <Text as="p" fontSize="md">
          {props.children}
        </Text>
      </Flex>
    </ListItem>
  );
};
