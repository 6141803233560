import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { GroupedListView } from '@send-legacy-components/Common/GroupedListView';
import { documentSources } from '@sendpayments/js-utils/dist/const/defaults';
import { RequestDocument } from '@send-legacy-containers/RequestDocument';
import { sortBy } from 'lodash';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import styles from './List.module.less';

dayjs.extend(localizedFormat);

const groupNames = {
  PENDING: 'pending',
  UPLOADED: 'uploaded',
};

const getDescription = ({ docType, docInstructions }) => {
  if (docType) {
    const { value: documentLabel } = documentSources.find(({ key }) => key === docType) || {};

    if (!documentLabel) {
      return docInstructions || '';
    }

    if (!docInstructions) {
      return documentLabel;
    }

    return `${documentLabel} \n${docInstructions}`;
  }

  return docInstructions || '';
};

const appendDocumentDate = (str, unixTimestamp) => (
  <>
    {str}
    {unixTimestamp && <div className={styles.time}>{dayjs(new Date(unixTimestamp * 1000)).format('ll')}</div>}
  </>
);

const Documents = ({ loading, pending, uploaded, owner, onDirtyStateChange }) => {
  const [idDirtyStates, setDirtyState] = useState({});

  const updateIdDirtyState = (id, value) =>
    setDirtyState({
      ...idDirtyStates,
      [id]: value,
    });

  const dataSource = [
    pending.length > 0 && {
      groupName: groupNames.PENDING,
      groupTitle: 'Pending',
      groupIconType: 'clock-circle',
      list: pending.map(({ id }) => ({ id, clickable: false })),
      config: {
        button: false,
        expandable: true,
        expandedIds: pending.map(({ id }) => id),
      },
    },
    uploaded.length > 0 && {
      groupName: groupNames.UPLOADED,
      groupTitle: 'Uploaded',
      groupIconType: 'check-circle',
      list: sortBy(uploaded, ['docUploadedDate']).map((document) => ({
        id: document.id,
        header: document.docRequestType,
        description: appendDocumentDate(getDescription(document), document.docUploadedDate),
        reference: { text: 'Uploaded', type: 'success' },
        clickable: false,
      })),
      config: {
        button: false,
        expandable: false,
        autoAdjustHeight: true,
      },
    },
  ].filter(Boolean);

  const pendingAsMap = useMemo(
    () =>
      pending.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {}),
    [pending],
  );

  useEffect(() => {
    const isDirty = Object.values(idDirtyStates).reduce((acc, value) => acc || value, false);
    onDirtyStateChange(isDirty);
  }, [idDirtyStates]);

  const onRenderExpandedPendingTemplateHandler = ({ id }) => (
    <RequestDocument onIsListLoaded={(hasData) => updateIdDirtyState(id, hasData)} document={pendingAsMap[id]} owner={owner} />
  );

  return (
    <Spin spinning={loading}>
      <div className={styles.documentsContainer}>
        <GroupedListView dataSource={dataSource} onRenderExpandedTemplate={onRenderExpandedPendingTemplateHandler} />
      </div>
    </Spin>
  );
};

Documents.propTypes = {
  owner: PropTypes.string,
  loading: PropTypes.bool,
  pending: PropTypes.array,
  uploaded: PropTypes.array,
  onDirtyStateChange: PropTypes.func.isRequired,
};

Documents.defaultProps = {
  owner: undefined,
  loading: false,
  pending: [],
  uploaded: [],
};

export default Documents;
