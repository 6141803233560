import { BaseLayout } from './BaseLayout';
import { SCard } from './SCard';
import { SFooter } from './SFooter';
import { SNotification } from './SNotification';
import { SSubAreaTitle } from './SSubAreaTitle';
import { BankDetailsTables } from './BankDetailsTable';
import { BankDetailsPayInAccount } from './BankDetailsPayInAccount';
import { BankDetailsPayInPayId } from './BankDetailsPayInPayId';
import { BankDetailsPayInBPay } from '@send-legacy-components/UI/BankDetailsPayInBPay';

export {
  BaseLayout,
  SCard,
  SFooter,
  SNotification,
  SSubAreaTitle,
  BankDetailsTables,
  BankDetailsPayInAccount,
  BankDetailsPayInPayId,
  BankDetailsPayInBPay,
};
