import React from 'react';
import { ExchangeCurrency } from '@send-legacy-containers/ExchangeCurrency';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import styles from './View.module.less';

const View = ({ onBookClick, showBookButton, initialToCurrency, initialFromCurrency, initialFromAmount, initialFixedAmount }) => (
  <Row type="flex" justify="center">
    <Col sm={24} lg={19} className={styles.container}>
      <ExchangeCurrency
        showBookButton={showBookButton}
        onBookClick={onBookClick}
        dropDownClassName={styles.dropDown}
        initialFromCurrency={initialToCurrency}
        initialToCurrency={initialFromCurrency}
        initialFromAmount={initialFromAmount}
        initialFixedAmount={initialFixedAmount}
      />
    </Col>
  </Row>
);

View.propTypes = {
  showBookButton: PropTypes.bool,
  onBookClick: PropTypes.func.isRequired,
  initialToCurrency: PropTypes.string,
  initialFromCurrency: PropTypes.string,
  initialFromAmount: PropTypes.number,
  initialFixedAmount: PropTypes.string,
};

View.defaultProps = {
  showBookButton: false,
  initialToCurrency: undefined,
  initialFromCurrency: undefined,
  initialFromAmount: undefined,
  initialFixedAmount: undefined,
};
export default View;
