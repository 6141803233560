import React, { useState, useEffect } from 'react';
import {
  Content,
  Dialog,
  Footer,
  Heading,
  DialogContainer,
  View,
  useDialogContainer,
  Text,
  Form,
  TextField,
  Flex,
} from '@adobe/react-spectrum';
import { CloseIcon } from '@sendpayments/react-shared/components/CloseIcon';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { breakpoints } from '@sendpayments/react-shared/design-system';
import { PrimaryActionButton } from '@sendpayments/react-shared/components/buttons/PrimaryActionButton';
import { PrimaryButton } from '@send-components/PrimaryButton';
import { PrimaryLink } from '@send-components/PrimaryLink';
import { withTheme } from '@sendpayments/react-shared/components/ThemeProvider';
import styles from './EmailVerifyModal.module.less';

const DialogContent = ({ onDismissVerifyEmailModal, onVerifyEmailCode, onResendCode, resendDelaySeconds = 30, error }) => {
  const dialog = useDialogContainer();
  const { isMediaMobile } = useBreakpoint(breakpoints);
  const [resendSeconds, setResendSeconds] = useState(resendDelaySeconds);
  const [userInput, setUserInput] = useState('');
  const [isEnableResend, setIsEnableResend] = useState(false);

  useEffect(() => {
    if (!isEnableResend) {
      const timer = setInterval(() => {
        setResendSeconds((val) => {
          const newVal = val - 1;
          if (newVal === 0) {
            clearInterval(timer);
            setIsEnableResend(true);
          }
          return newVal;
        });
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }

    return () => {};
  }, [isEnableResend]);

  const onResendCodeHandler = () => {
    setResendSeconds(resendDelaySeconds);
    setIsEnableResend(false);
    onResendCode();
  };

  const onVerifyEmailCodeHandler = async () => {
    await onVerifyEmailCode(userInput);
  };

  const onDismissDialogHandler = () => {
    onDismissVerifyEmailModal();
    return dialog.dismiss;
  };

  return (
    <Dialog size="M">
      <CloseIcon className={styles.closeIcon} onClick={onDismissDialogHandler} />
      <Heading>
        <div className={`${styles.title} ${isMediaMobile && styles.titleMobile}`}>Please verify your email</div>
      </Heading>
      <Content marginTop={8}>
        <Text>
          We’ve sent a verification code to your email.{' '}
          <span className={styles.bold}>Please enter it below to change your email. You will need to login again.</span>
        </Text>
        <View marginTop={20} />
        <Form>
          <TextField
            placeholder="Enter Verification Code"
            label="Verification Code"
            maxWidth={320}
            value={userInput}
            onChange={setUserInput}
          />
          {error && <div className={styles.errorMessage}>{error}</div>}
          <View marginTop={10} />
          <Flex direction="row">
            <PrimaryActionButton onPress={onVerifyEmailCodeHandler} height="inherit">
              Verify
            </PrimaryActionButton>
            <PrimaryButton onPress={onDismissDialogHandler} marginX={12}>
              Cancel email change
            </PrimaryButton>
          </Flex>
        </Form>
      </Content>
      <Footer UNSAFE_className={styles.footer}>
        <PrimaryLink isDisabled={!isEnableResend} onPress={onResendCodeHandler}>
          {`Please resend the code ${resendSeconds ? `(${resendSeconds}s)` : ''}`}
        </PrimaryLink>
      </Footer>
    </Dialog>
  );
};

const EmailVerifyModal = (props) => {
  const [isOpen, setIsOpen] = useState(true);

  return <DialogContainer onDismiss={() => setIsOpen(false)}>{isOpen && <DialogContent {...props} />}</DialogContainer>;
};

export default withTheme(EmailVerifyModal);
