import React from 'react';
import { Form, Select as SelectAntd, Input as InputAntd, Col } from 'antd';
import PropTypes from 'prop-types';
import { withAnalytics } from '@sendpayments/react-shared/HOC/withAnalytics';

const { Input, Select } = withAnalytics({ Input: InputAntd, Select: SelectAntd }, { info: 'Phone' });

function CommonPhoneInput(props) {
  const { getFieldDecorator } = props.onGetFieldDecorator ? { getFieldDecorator: props.onGetFieldDecorator } : props.form;
  const { Option } = SelectAntd;

  const prefixSelector = getFieldDecorator(props.prefixName, {
    initialValue: props.dataExt || '+61',
  })(
    <Select showSearch style={{ width: 90 }}>
      {props.dataList.map((value) => (
        <Option key={value} value={value}>
          {value}
        </Option>
      ))}
    </Select>,
  );
  const sm = props.sm ? props.sm : props.span;
  const md = props.md ? props.md : sm;
  return (
    <Col span={props.span} sm={sm} md={md}>
      <Form.Item hasFeedback colon={false} required={false} label={props.label}>
        {getFieldDecorator(props.name, {
          initialValue: props.dataPhone,
          rules: [
            { required: props.required, message: `${props.label} is required` },
            { min: 5, message: 'Phone number is too short' },
            { max: 13, message: 'Phone number is too long' },
          ],
        })(<Input name={props.name} addonBefore={prefixSelector} placeholder={`Enter your ${props.label}`} />)}
      </Form.Item>
    </Col>
  );
}

CommonPhoneInput.propTypes = {
  form: PropTypes.object,
  onGetFieldDecorator: PropTypes.func,
  name: PropTypes.string.isRequired,
  prefixName: PropTypes.string.isRequired,
  label: PropTypes.string,
  span: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  required: PropTypes.bool,
  dataList: PropTypes.array.isRequired,
  dataPhone: PropTypes.string,
  dataExt: PropTypes.string,
};

CommonPhoneInput.defaultProps = {
  form: {},
  onGetFieldDecorator: undefined,
  span: 24,
  sm: 0,
  md: 0,
  label: '',
  required: true,
  dataPhone: null,
  dataExt: '+61',
};

export default CommonPhoneInput;
