import * as React from 'react';

import { Box, BoxProps, Divider, Flex } from '@chakra-ui/react';

import { CurrencyAmountField, CurrencySelect } from '@sendpayments/react-shared/components/molecules';
import { TransferRate } from '@send-base/atomic-components/molecules';

import {
  CurrencyExchangeSupportAction,
  CurrencyExchangeSupportActionProps,
} from '@send-base/atomic-components/organisms/CurrencyExchangeWidget/CurrencyExchangeSupportAction';

import {
  CurrencyExchangeBankComparison,
  CurrencyWidgetBankComparisonProps,
} from '@send-base/atomic-components/organisms/CurrencyExchangeWidget/CurrencyExchangeBankComparison';

export type CurrencyOption = {
  groupName: string;
  options: {
    currencyCode: string;
    currencyName: string;
  }[];
};

export type CurrencyPair = {
  fromAmount: number;
  fromAmountDecimal: number;
  fromCurrencyCode: string;

  toAmount: number;
  toAmountDecimal: number;
  toCurrencyCode: string;

  rate: number;
  invertedRate: number;
};

export type CurrencyWidgetProps = {
  isLoading: boolean;

  sender?: { isLocked?: boolean; label?: string };
  recipient?: { isLocked?: boolean; label?: string };

  currencies: { from: CurrencyOption[]; to: CurrencyOption[] };
  currencyPairValue: CurrencyPair;

  onFromAmountChange: (value: number) => void;
  onToAmountChange: (value: number) => void;
  onFromSelectedCurrencyChange: (currencyCode: string) => void;
  onToSelectedCurrencyChange: (currencyCode: string) => void;

  error?: { sender?: string; recipient?: string; general?: string };

  comparison?: CurrencyWidgetBankComparisonProps;
  support?: CurrencyExchangeSupportActionProps;
};

export const CurrencyExchangeWidget = (props: CurrencyWidgetProps) => {
  const { comparison, support, error } = props;
  return (
    <WrapperPanel>
      <Widget {...props} />
      {support && error && <CurrencyExchangeSupportAction mt={6} width={'auto'} alignSelf={'center'} {...support} />}
      {comparison && !error && <CurrencyExchangeBankComparison mt={8} ml={4} {...comparison} />}
    </WrapperPanel>
  );
};

const Widget = (props: CurrencyWidgetProps) => {
  const { isLoading, currencies, currencyPairValue, error, sender, recipient } = props;
  const { onFromAmountChange, onToAmountChange, onFromSelectedCurrencyChange, onToSelectedCurrencyChange } = props;

  const { fromCurrencyOptions, fromCurrencySelectedOption, toCurrencyOptions, toCurrencySelectedOption } =
    toCurrencySelectOptions({
      fromCurrencies: currencies.from,
      fromCurrencyCode: currencyPairValue.fromCurrencyCode,
      fromCurrencyCodeExclusionCode: currencyPairValue.toCurrencyCode,
      toCurrencies: currencies.to,
      toCurrencyCode: currencyPairValue.toCurrencyCode,
      toCurrencyCodeExclusionCode: currencyPairValue.fromCurrencyCode,
    });

  const isSenderFieldError = Boolean(error?.sender);
  const isRecipientFieldError = Boolean(error?.recipient);
  const isGeneralError = Boolean(error?.general);

  const handleOnFromSelectedCurrencyChange = (value: string) => {
    onFromSelectedCurrencyChange(value);
  };

  const handleOnToSelectedCurrencyChange = (value: string) => {
    onToSelectedCurrencyChange(value);
  };

  return (
    <Flex flexDirection={'column'} alignItems={'flex-start'}>
      <FieldPanel isInvalid={isSenderFieldError || isGeneralError}>
        <CurrencyAmountField
          id={'from-amount'}
          label={sender?.label ?? 'You send'}
          maxWidth={{ base: '120px', md: '150px' }}
          decimal={currencyPairValue.fromAmountDecimal}
          value={currencyPairValue.fromAmount}
          onChange={onFromAmountChange}
          ariaDescribeBy={'from-amount-error'}
          isDisabled={isLoading}
          isInvalid={isSenderFieldError}
        />
        <CurrencySelect
          width={'auto'}
          currencies={fromCurrencyOptions}
          value={fromCurrencySelectedOption}
          isDisabled={isLoading || sender?.isLocked}
          isLocked={sender?.isLocked}
          isInvalid={isSenderFieldError}
          onSelect={(selected) => handleOnFromSelectedCurrencyChange(selected.value)}
        />
      </FieldPanel>
      <BackgroundOverlayDivider>
        {isSenderFieldError && <ErrorMessage id={'from-amount-error'}>{error?.sender}</ErrorMessage>}
        <TransferRate ml={4} my={4} isLoading={isLoading} isAnimationRunning={isLoading} rate={currencyPairValue.rate} />
      </BackgroundOverlayDivider>
      <FieldPanel isInvalid={isRecipientFieldError || isGeneralError}>
        <CurrencyAmountField
          id={'to-amount'}
          label={recipient?.label ?? 'Recipient gets'}
          maxWidth={{ base: '120px', md: '150px' }}
          decimal={currencyPairValue.toAmountDecimal}
          value={currencyPairValue.toAmount}
          onChange={onToAmountChange}
          ariaDescribeBy={'to-amount-error'}
          isDisabled={isLoading}
          isInvalid={isRecipientFieldError}
        />
        <CurrencySelect
          width={'auto'}
          currencies={toCurrencyOptions}
          value={toCurrencySelectedOption}
          isDisabled={isLoading || recipient?.isLocked}
          isLocked={recipient?.isLocked}
          isInvalid={isRecipientFieldError}
          onSelect={(selected) => handleOnToSelectedCurrencyChange(selected.value)}
        />
      </FieldPanel>
      {isRecipientFieldError && <ErrorMessage id={'to-amount-error'}>{error?.recipient}</ErrorMessage>}
      {isGeneralError && <ErrorMessage id={'currency-error'}>{error?.general}</ErrorMessage>}
    </Flex>
  );
};

const WrapperPanel = (props: BoxProps) => (
  <Box
    width={props.width}
    maxWidth={props.maxWidth}
    py={{ base: '16px', md: '30px' }}
    px={{ base: '12px', md: '24px' }}
    display={'flex'}
    flexDirection={'column'}
    border={'1.5px solid'}
    borderColor={'border.base'}
    borderRadius={'8px'}
    textAlign={'initial'}
    {...props}
  />
);

const FieldPanel = (props: { isInvalid?: boolean; children: React.ReactNode }) => (
  <Box
    width={'100%'}
    display={'flex'}
    alignItems={'center'}
    justifyContent={'center'}
    bg={'gray.50'}
    py={{ base: '20px', md: '24px' }}
    px={{ base: '12px', md: '16px' }}
    border={'1px solid'}
    borderColor={props.isInvalid ? 'error' : 'gray.50'}
    borderRadius={'4px'}
  >
    {props.children}
  </Box>
);

const BackgroundOverlayDivider = (props: { children: React.ReactNode }) => (
  <Box position={'relative'} isolation={'isolate'}>
    <Divider orientation={'vertical'} position={'absolute'} top={0} left={8} />
    <Box position={'relative'} zIndex={1}>
      {props.children}
    </Box>
  </Box>
);

/**
 * Background color is white, as it needs to overlay over Divider
 */
const ErrorMessage = (props: { id?: string; children: React.ReactNode }) => (
  <Box id={props.id} mt={2} fontSize={'sm'} backgroundColor={'white'} color={'error'}>
    {props.children}
  </Box>
);

const toCurrencySelectOptions = ({
  fromCurrencies,
  fromCurrencyCode,
  fromCurrencyCodeExclusionCode,
  toCurrencies,
  toCurrencyCode,
  toCurrencyCodeExclusionCode,
}: {
  fromCurrencies: CurrencyOption[];
  fromCurrencyCode: string;
  fromCurrencyCodeExclusionCode: string;
  toCurrencies: CurrencyOption[];
  toCurrencyCode: string;
  toCurrencyCodeExclusionCode: string;
}) => {
  const toOptions = ({
    currencies,
    currencyCode,
    excludeCurrencyCode,
  }: {
    currencies: CurrencyOption[];
    currencyCode: string;
    excludeCurrencyCode: string;
  }) => {
    const currencyOptions = currencies.map((it) => ({
      label: it.groupName,
      options: it.options
        .map((it) => ({
          value: it.currencyCode,
          label: it.currencyName,
        }))
        .filter((it) => it.value !== excludeCurrencyCode),
    }));
    const defaultCurrencyOption = currencies
      .flatMap((it) => it.options)
      .filter((it) => it.currencyCode === currencyCode)
      .map((it) => ({
        value: it.currencyCode,
        label: it.currencyName,
      }))[0] ?? { value: '', label: '' };
    return {
      currencyOptions,
      defaultCurrencyOption,
    };
  };

  const { currencyOptions: fromCurrencyOptions, defaultCurrencyOption: fromCurrencySelectedOption } = toOptions({
    currencies: fromCurrencies,
    currencyCode: fromCurrencyCode,
    excludeCurrencyCode: fromCurrencyCodeExclusionCode,
  });

  const { currencyOptions: toCurrencyOptions, defaultCurrencyOption: toCurrencySelectedOption } = toOptions({
    currencies: toCurrencies,
    currencyCode: toCurrencyCode,
    excludeCurrencyCode: toCurrencyCodeExclusionCode,
  });

  return {
    fromCurrencyOptions,
    fromCurrencySelectedOption,
    toCurrencyOptions,
    toCurrencySelectedOption,
  };
};
