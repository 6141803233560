export const env = process.env.ENV;

export const isProd = env && env.includes('prod');

export const credentialKey = isProd ? 'prod' : 'staging';

export const projectName = process.env.PROJECT_NAME || 'sendpayments';

const urls = {
  default: `https://hub-${env}.env.sendfx.net`,
  demo: 'https://hub.demo.sendpayments.com',
  localhost: 'http://localhost:3000',
  prod: 'https://hub.sendpayments.com',
  reskinuat: 'https://hub-uat.env.sendpayments.com',
};

export const getHubUrl = () => {
  if (isProd) {
    return urls.prod;
  }

  switch (env) {
    case 'reskinuat':
      return urls.reskinuat;
    case 'demo':
      return urls.demo;
    default:
      if (window && window.location.hostname === 'localhost') {
        return 'http://localhost:3000';
      }
      return urls.default;
  }
};
