import React, { useState } from 'react';
import { Stack, Text, Button } from '@chakra-ui/react';
import { AlertBox, useToast } from '@sendpayments/react-shared/components/molecules';
import { SendEmailVerificationForm, ResetPasswordForm, VerifyCodeModal } from '../../organisms';
import { AuthenticationTemplate } from '@send-base/atomic-components/templates';
import { AuthPiece, IAuthPieceProps, IAuthPieceState } from 'aws-amplify-react/lib-esm/Auth/AuthPiece';
import Auth from '@aws-amplify/auth';

interface AlertBoxData {
  message?: string;
  type?: string;
}

export interface ForgotPasswordPageProps {
  changeState: AuthPiece<IAuthPieceProps, IAuthPieceState>['changeState'];
}

export const ForgotPasswordPage: React.FC<ForgotPasswordPageProps> = ({ changeState }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [alertBox, setAlertBox] = useState<AlertBoxData>({ message: undefined, type: undefined });
  const [username, setUsername] = useState('');
  const [confirmed, setConfirmed] = useState(false);
  const toast = useToast();

  const onSend = async ({ email }) => {
    setIsLoading(true);
    const username = email?.trim().toLowerCase();
    try {
      const data = await Auth.forgotPassword(username);
      setAlertBox({
        message: `We've sent a verification code to ${data.CodeDeliveryDetails.Destination}. Please enter it below to reset your password.`,
        type: 'success',
      });
      setUsername(username);
    } catch (error) {
      setAlertBox({
        message: `We were unable to send a verification code to the account linked to ${email}.`,
        type: 'fail',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async ({ code, newPassword: password }: { code: string; newPassword: string }) => {
    setIsLoading(true);
    try {
      await Auth.forgotPasswordSubmit(username, code, password);
      setAlertBox({
        message: 'Thank you, your password has been changed.',
        type: 'success',
      });
      setConfirmed(true);
    } catch (error) {
      toast({
        variant: 'negative',
        title: 'Verification code is incorrect or expired',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthenticationTemplate heading={'Reset Password'}>
      <Stack>
        {!confirmed && (
          <Text align="center" fontSize="md">
            <Text as="span">{'We’ll send you a code to reset. '}</Text>
            <br />
            <Text as="span">{'Go '}</Text>
            <Text as="a" fontWeight="semibold" fontSize="md" color="primary.base" onClick={() => changeState('signIn')}>
              back to login here
            </Text>
          </Text>
        )}
        {alertBox.message && (
          <AlertBox
            maxW="100%"
            my="4"
            title=""
            alignSelf="center"
            variant={alertBox.type === 'success' ? 'neutral' : 'negative'}
            description={alertBox.message}
            showIcon
          />
        )}
        {username ? (
          confirmed ? (
            <Button size="md" type="submit" maxW="40" alignSelf="center" onClick={() => changeState('signIn')}>
              Back to login
            </Button>
          ) : (
            <ResetPasswordForm onSubmit={onSubmit} isLoading={isLoading} initialValues={{ code: '', newPassword: '' }} />
          )
        ) : (
          <SendEmailVerificationForm onSubmit={onSend} isLoading={isLoading} initialValues={{ email: '' }} />
        )}
      </Stack>
    </AuthenticationTemplate>
  );
};
