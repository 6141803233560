import { HStack, StackProps, Text } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface MessageBoxProps extends StackProps {
  content: string | ReactNode;
  icon?: ReactNode;
  variant?: 'success' | 'error' | 'warning' | 'info';
}

export const MessageBox = ({ content, icon, variant = 'info', ...props }: MessageBoxProps) => {
  const colors = {
    success: 'tertiary.tint',
    info: 'notification',
    warning: 'yellow.500',
    error: 'error',
  };

  return (
    <HStack
      p={4}
      bg="border.light"
      border="1px solid"
      borderColor={colors[variant]}
      borderRadius="4px"
      textAlign="left"
      alignItems="flex-start"
      {...props}
    >
      {icon}
      {typeof content === 'string' ? <Text>{content}</Text> : content}
    </HStack>
  );
};
