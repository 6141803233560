import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ChevronLeftIcon = (props: IconProps) => (
  <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.98 0.0198765C14.376 0.0187721 14.7634 0.135256 15.0932 0.354567C15.4229 0.573877 15.6801 0.886143 15.8322 1.25179C15.9843 1.61744 16.0244 2.02 15.9475 2.40847C15.8705 2.79693 15.68 3.15381 15.4 3.43388L8.828 9.99988L15.4 16.5659C15.5861 16.752 15.7337 16.9729 15.8344 17.216C15.9351 17.4591 15.9869 17.7197 15.9869 17.9829C15.9869 18.246 15.9351 18.5066 15.8344 18.7498C15.7337 18.9929 15.5861 19.2138 15.4 19.3999C15.2139 19.586 14.993 19.7336 14.7499 19.8343C14.5067 19.935 14.2462 19.9868 13.983 19.9868C13.7198 19.9868 13.4593 19.935 13.2161 19.8343C12.973 19.7336 12.7521 19.586 12.566 19.3999L4.6 11.4139C4.22506 11.0388 4.01443 10.5302 4.01443 9.99988C4.01443 9.46955 4.22506 8.96093 4.6 8.58588L12.566 0.599876C12.942 0.226976 13.4505 0.0183964 13.98 0.0198765Z"
      fill="#ED3D86"
    />
  </Icon>
);
