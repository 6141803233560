import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { getCurrencyString } from '@sendpayments/js-utils/dist/utils/currency';
import { useRegistration, usePayInAccount } from '@send-data-hooks';

export const BankDetailsPayInAccount = ({ small, amount, currency, reference }) => {
  const [{ data: userAccount }] = useRegistration();

  const [payInDetail, loading] = usePayInAccount({ userAccount, currency });

  return (
    <Spin spinning={loading}>
      <table className={`bank-details ${small ? 'small' : ''}`}>
        <tbody>
          {amount && currency && (
            <tr>
              <td>Amount Outstanding</td>
              <td className="info">{getCurrencyString({ amount, currency })}</td>
            </tr>
          )}
          <tr>
            <td>Bank Account Name</td>
            <td className="info">{payInDetail.payInAccountName}</td>
          </tr>
          {payInDetail.payInSwiftCode && (
            <tr>
              <td>SWIFT Code</td>
              <td className="info">{payInDetail.payInSwiftCode}</td>
            </tr>
          )}
          <tr>
            <td>BSB</td>
            <td className="info">{payInDetail.payInBSB}</td>
          </tr>
          <tr>
            <td>Account</td>
            <td className="info">{payInDetail.payInAccNo}</td>
          </tr>
          <tr>
            <td>Payment Reference</td>
            <td className="info">{reference.substring(0, 5).toUpperCase()}</td>
          </tr>
        </tbody>
      </table>
    </Spin>
  );
};

BankDetailsPayInAccount.propTypes = {
  reference: PropTypes.string,
  loading: PropTypes.bool,
  small: PropTypes.bool,
  amount: PropTypes.number,
  currency: PropTypes.string,
};

BankDetailsPayInAccount.defaultProps = {
  loading: false,
  small: false,
  amount: undefined,
  currency: undefined,
};
