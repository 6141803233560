import React, { useEffect } from 'react';
import { Box, Button, Checkbox, Flex, FormControl, FormErrorMessage, Link, Stack, Text, Spacer } from '@chakra-ui/react';
import { useToast } from '@sendpayments/react-shared/components/molecules/Toast';
import { InputField } from '@sendpayments/react-shared/components/molecules';
import { Field, Form, Formik } from 'formik';
import { analytics } from '@sendpayments/js-utils/dist';
import { logger } from '@sendpayments/js-utils/dist/services/logger';
import * as yup from 'yup';
import { SendForm } from '@send-base/atomic-components/organisms/forms/types';
import { WarningIcon } from '@chakra-ui/icons';
import { ErrorModal } from './modals/ErrorModal';
import { ReviewModal } from './modals/ReviewModal';
import { FormState } from '@send-base/infrastructure/query';
import { BsbInputField } from './BsbInputField';
import { useBsbInfo, BsbInfo } from '@send-base/infrastructure/query';

const directDebitSchema = yup.object({
  bsbNumber: yup
    .string()
    .length(7, 'Please enter a valid BSB number')
    .matches(/^\d{3}-\d{3}$/, { excludeEmptyString: true, message: 'Please enter a valid BSB number' })
    .required('Please enter your BSB number'),
  accountName: yup.string().min(2).max(32).required(),
  accountNumber: yup
    .string()
    .min(3, 'Please enter a valid account number')
    .max(9, 'Please enter a valid account number')
    .matches(/^\d+$/, { excludeEmptyString: true, message: 'Please enter a valid account number' })
    .required('Please enter your account number'),
  termsAndConditions: yup
    .boolean()
    .required('Please accept the terms and conditions')
    .oneOf([true], 'Please accept the terms and conditions'),
});

type DirectDebitData = yup.InferType<typeof directDebitSchema>;

export interface DirectDebitFormProps extends Omit<SendForm<DirectDebitData>, 'onSubmit'> {
  accountName: string;
  amount: number;
  handleFormSubmit: () => void;
  setFormData: (data: FormState) => void;
  reset: () => void;
  formData: FormState | undefined;
  onClose: () => void;
  onOpen: () => void;
  isReviewOpen: boolean;
  isErrorOpen: boolean;
}

const defaultFormValues = {
  accountNumber: '',
  accountName: '',
  bsbNumber: '',
  termsAndConditions: false,
};

export const DirectDebitForm: React.FC<DirectDebitFormProps> = ({
  initialValues,
  amount,
  accountName,
  handleFormSubmit,
  setFormData,
  formData,
  onClose,
  onOpen,
  isReviewOpen,
  isErrorOpen,
  isLoading,
  reset,
}) => {
  const toast = useToast();

  const { mutate, isLoading: isBsbLoading, data, isError: isBsbInvalid, reset: clearSpinner } = useBsbInfo<BsbInfo>();

  useEffect(() => {
    if (isBsbInvalid) {
      const timer = setTimeout(() => {
        clearSpinner();
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isBsbInvalid]);

  const bankName = data?.data.bank.name || '';

  return (
    <Box>
      <Formik
        initialValues={{ ...defaultFormValues, ...initialValues, accountName }}
        validateOnMount={false}
        validationSchema={directDebitSchema}
        enableReinitialize
        onSubmit={async (values, actions) => {
          try {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            analytics.push({ action: { type: 'form_submit', data: { name: 'DirectDebitForm' } } });
            onOpen();
            const { termsAndConditions, ...rest } = values;
            setFormData({ bankDetails: { ...rest }, termsAndConditions });
          } catch (error) {
            logger.error('DirectDebitForm', 'ERROR: ', error);
            actions.setSubmitting(false);
            toast({
              variant: 'negative',
              title: 'Something went wrong on our end. Please try again!',
            });
          }
        }}
      >
        {(props) => (
          <>
            <ReviewModal
              // adding isloading here make sure during the request user cannot click anything else (avoid multiple request)
              isOpen={(isReviewOpen && !isErrorOpen) || isLoading}
              onClose={onClose}
              formData={formData}
              onPrimaryAction={handleFormSubmit}
              isLoading={isLoading}
              bankName={bankName}
            />
            <ErrorModal isOpen={isErrorOpen && !isReviewOpen} onClose={onClose} reset={reset} />
            <Form noValidate>
              <Stack spacing={4} textAlign="left">
                <Box
                  border={{ md: 'light', lg: 'light' }}
                  borderColor={{ md: 'border.base', lg: 'border.base' }}
                  borderRadius={{ md: 8, lg: 8 }}
                  p={{ md: 4, lg: 4 }}
                  w="100%"
                  h="100%"
                >
                  <Text textAlign="center" lineHeight={8} fontWeight="semibold" fontSize="22px" mb={4}>
                    Input your bank details below and we will direct debit{' '}
                    <Text as={'span'} color="primary.base" fontSize={'22px'}>
                      {amount.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{' '}
                      AUD{' '}
                    </Text>{' '}
                    from your account.{' '}
                  </Text>
                  <Flex backgroundColor="complimentary.base" alignItems="center" borderRadius="4px" padding="8px" mb={4}>
                    <Box alignItems="start">
                      <WarningIcon w="19px" h="19px" />
                    </Box>
                    <Box flexDirection="column" ml="16px">
                      <Text m="auto" fontSize="sm">
                        You can only use a bank account for direct debit that is under your legal name {accountName}.
                      </Text>
                    </Box>
                  </Flex>
                  <Text color="secondary.base" mb={3}>
                    Your bank details
                  </Text>
                  {/* BSB */}
                  <Field name="bsbNumber">
                    {({ field, form }) => (
                      <BsbInputField
                        field={field}
                        form={form}
                        isBsbLoading={isBsbLoading}
                        mutate={mutate}
                        isBsbInvalid={isBsbInvalid}
                        bankName={bankName}
                      />
                    )}
                  </Field>

                  {/* Account number */}
                  <Field name="accountNumber">
                    {({ field, form }) => (
                      <InputField
                        mb={3}
                        id={field.name}
                        label="Account Number"
                        placeholder="A 1-9 digit number located at the top of a bank statement"
                        isInvalid={!!form.errors[field.name] && form.touched[field.name]}
                        isValid={!form.errors[field.name] && !!field.value}
                        errorText={form.errors[field.name]}
                        inputProps={{
                          ...field,
                          type: 'text',
                          size: 'md',
                        }}
                      />
                    )}
                  </Field>

                  {/* Account Name */}
                  <Field name="accountName">
                    {({ field, form }) => (
                      <InputField
                        mb={3}
                        id={field.name}
                        label="Account Name"
                        placeholder={field.value}
                        isInvalid={!!form.errors[field.name] && form.touched[field.name]}
                        isValid={!form.errors[field.name] && !!field.value}
                        errorText={form.errors[field.name]}
                        inputProps={{
                          ...field,
                          type: 'text',
                          size: 'md',
                          _disabled: {
                            bgColor: 'disabled.base',
                            color: 'gray.700',
                          },
                          isDisabled: true,
                        }}
                      />
                    )}
                  </Field>
                </Box>

                {/* Terms checkbox */}
                <Field name="termsAndConditions">
                  {({ field, form }) => (
                    <FormControl id={field.name} isInvalid={form.errors[field.name] && form.touched[field.name]}>
                      <Box
                        border="light"
                        borderColor={form.errors[field.name] && form.touched[field.name] ? 'error' : 'border.base'}
                        borderRadius={8}
                        p={4}
                        w="100%"
                        h="100%"
                      >
                        <Checkbox
                          alignItems="top"
                          size="md"
                          isInvalid={form.errors[field.name] && form.touched[field.name]}
                          isChecked={field.value}
                          onChange={(e) => form.setFieldValue(field.name, e.target.checked)}
                        >
                          <Text fontSize="sm" lineHeight={1.5}>
                            I understand that this payment option is powered by a third-party service provider, Moneytech Limited
                            ACN 106 249 852 / AFSL 421414 trading as Monoova (‘the Service Provider’) and its use is subject to
                            the Service Provider&apos;s terms and conditions. Unless otherwise advised by me, I authorise the
                            Service Provider to debit my nominated account through the Bulk Electronic Clearing System (BECS) or
                            my nominated credit or debit card for monies due to settle the trade booked with SendFX Limited ACN
                            617 647 220 (‘Send’), in accordance with the terms and conditions of the Service Provider&apos;s{' '}
                            <Link href="https://www.monoova.com/ddrsa" target="_blank">
                              direct debit service agreement
                            </Link>
                            . I understand and agree that the debit payment amount will be based on the trade confirmation details
                            provided by Send to the Service Provider. Send accepts no liability for any loss, damage or costs
                            incurred by you in utilising the Service Provider’s services.
                          </Text>
                        </Checkbox>
                      </Box>
                      <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Button
                  w="100%"
                  isLoading={props.isSubmitting}
                  variant="solid"
                  type="submit"
                  alignSelf="center"
                  fontSize="md"
                  isDisabled={!props.isValid || isBsbInvalid || isBsbLoading}
                >
                  Agree
                </Button>
              </Stack>
            </Form>
          </>
        )}
      </Formik>
    </Box>
  );
};
