import React from 'react';
import PropTypes from 'prop-types';
import { getImageSrc } from '@send-services/resources';

const ThemedImage = ({ path, file, alt, ...props }) => <img alt={alt} src={getImageSrc({ type: path, name: file })} {...props} />;

ThemedImage.propTypes = {
  file: PropTypes.string.isRequired,
  path: PropTypes.string,
  alt: PropTypes.string.isRequired,
};

ThemedImage.defaultProps = {
  path: undefined,
};

export default ThemedImage;
