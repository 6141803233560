import React from 'react';
import PropTypes from 'prop-types';
import { BaseLayout } from '@send-legacy-components/BaseLayout';
import { useRegistration, useAppContext } from '@send-data-hooks';

const BaseLayoutContainer = ({ loading, name, title, subtitle, children, actionableButtonsComponent }) => {
  const [{ data: registration, loading: loadingReg }] = useRegistration();
  const [{ loading: pageLoading }] = useAppContext();

  return (
    <BaseLayout
      name={name}
      loading={loading || loadingReg || pageLoading}
      title={title}
      subtitle={subtitle}
      registration={registration}
      actionableButtonsComponent={actionableButtonsComponent}
    >
      {children}
    </BaseLayout>
  );
};

BaseLayoutContainer.propTypes = {
  loading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  actionableButtonsComponent: PropTypes.node,
};

BaseLayoutContainer.defaultProps = {
  loading: false,
  actionableButtonsComponent: undefined,
};

export default BaseLayoutContainer;
