import * as React from 'react';
import { Flex, View, Form } from '@adobe/react-spectrum';
import { LegacyLayout } from '@send-components/LegacyLayout';
import { SectionGroup } from '@send-components/SectionGroup';
import { CollapseSection } from '@send-components/CollapseSection';
import { logger } from '@sendpayments/js-utils/dist/services/logger';
import { SubMenu, Item } from '@send-components/SubMenu';
import { useRegistration, useAppContext } from '@send-data-hooks';
import { MFA } from '@send-components/Account/Security/MFA';
import { PasswordField } from '@sendpayments/react-shared/components/PasswordField';
import { PrimaryActionButton } from '@sendpayments/react-shared/components/buttons/PrimaryActionButton';
import { Auth, API } from 'aws-amplify';
import Info from '@spectrum-icons/workflow/Info';
import { triggerToast } from '@send-components/Toast';
import { useForm } from '../../data-hooks/useForm';
import styles from './Account.module.less';
import { analytics } from '@sendpayments/js-utils/dist';
import { LoginSourceTypes } from '@sendpayments/shared-constants/types';

const passwordMessage = 'Password must be at least 8 characters (max 128)';

const notify = () => {
  triggerToast({
    type: 'success',
    message: 'Your password has been updated!',
    rightIcon: <Info size="M" />,
  });
};

/**
 * Tests a password for validation.
 * Must be 8 chars or more (under 128)
 *
 * @link https://stackoverflow.com/questions/58767980/aws-cognito-password-regex-specific-to-aws-cognito
 * @returns {boolean}
 */
function validatePassword(password) {
  if (password?.length >= 8 && password?.length <= 128) {
    return true;
  }
  return false;
}

const getIdToken = async () => {
  return (await Auth.currentSession()).idToken.jwtToken;
};

const getAccessToken = async () => {
  return (await Auth.currentSession()).accessToken.jwtToken;
};

const PasswordSection = (props) => {
  return (
    <>
      <PasswordField
        {...props}
        onChange={(value) => {
          props.onChange(value);
        }}
      />
    </>
  );
};

/**
 * Get a sensible error from an AWS response
 */
function getSensibleError(fields, error) {
  let err = '';
  let field = fields.newPassword;
  if (error.response) {
    // Request made and server responded
    err = error.response.data;
  } else {
    // Something happened in setting up the request that triggered an Error
    err = error.message;
  }

  if (err === 'Incorrect username or password.') {
    logger.error('getSensibleError', err);
    // Username must be correct (logged in).
    err = 'Incorrect password';
    field = fields.currentPassword;
  }
  return [err, field];
}

const Security = () => {
  const [{ user }] = useAppContext();
  const [{ data: account }] = useRegistration();
  const [formLoading, setFormLoading] = React.useState(false);
  const [sectionActive, setSectionActive] = React.useState('');

  const fields = {
    currentPassword: 'currentPassword',
    newPassword: 'newPassword',
    newPasswordAgain: 'newPasswordAgain',
  };

  const [formState, updateFormState, { clearForm }] = useForm(Object.keys(fields));

  /**
   * Method that sets and validates new passwords
   */
  const setNewPasswords = (newPassword, newPasswordAgain) => {
    let newPasswordError = '';
    let newPasswordAgainError = '';

    if (!validatePassword(newPassword)) {
      newPasswordError = passwordMessage;
    }

    if (newPasswordAgain && newPasswordAgain !== newPassword) {
      newPasswordAgainError = "Passwords don't match";
    }

    updateFormState({ field: 'newPassword', value: newPassword, error: newPasswordError });
    updateFormState({ field: 'newPasswordAgain', value: newPasswordAgain, error: newPasswordAgainError });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const idToken = await getIdToken();
    const accessToken = await getAccessToken();

    // Ensure all fields are completed
    let atLeastOneFieldIsIncomplete = false;
    Object.entries(fields).forEach(([, val]) => {
      if (!formState[val].value) {
        updateFormState({ field: val, error: 'This field must be completed' });
        atLeastOneFieldIsIncomplete = true;
      }
    });
    if (atLeastOneFieldIsIncomplete) {
      return;
    }

    // If passwords don't match, do not submit
    if (formState[fields.newPassword].value !== formState[fields.newPasswordAgain].value) {
      updateFormState({ field: fields.newPasswordAgain, error: "Passwords don't match" });
      return;
    }

    let response = null;
    try {
      setFormLoading(true);

      response = await API.post('SendApi', `/customer/updatePassword`, {
        headers: {
          Token: idToken,
        },
        body: {
          accessToken,
          previousPassword: formState[fields.currentPassword]?.value,
          proposedPassword: formState[fields.newPassword]?.value,
          id: account?.id,
        },
      });
    } catch (error) {
      logger.error('onSubmit', `Error changing password: '${error}'`);
      const [err, field] = getSensibleError(fields, error);
      updateFormState({ field, error: err });
      setFormLoading(false);
      return;
    }

    setFormLoading(false);

    // Response succeeded
    logger.log('onSubmit', 'Password update succeeded', response);
    analytics.push({ action: { type: 'form_submit', data: { name: 'ChangePasswordForm' } } });
    clearForm();
    notify();
  };

  const changeSectionActive = (choosenSectionName) => {
    setSectionActive(choosenSectionName);
  };

  const isPexaUser = account && account.portalSource === 'pexa';

  return (
    <>
      <LegacyLayout activeMenu="setting">
        <SubMenu>
          <Item routerTo="/setting/profile">Profile</Item>
          <Item routerTo="/setting/security">Password and security</Item>
          {!isPexaUser && <Item routerTo="/setting/notification">Notification</Item>}
        </SubMenu>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.title}>Password and security</div>
            <View>Manage your password and 2-step verification</View>
          </div>

          {![LoginSourceTypes.GOOGLE, LoginSourceTypes.FACEBOOK].includes(account.loginSource) && (
            <SectionGroup title="Password">
              <CollapseSection
                buttonText="Change"
                activeSection={sectionActive}
                sectionName="ChangePassword"
                changeSectionActive={changeSectionActive}
              >
                <Flex direction="row" gap="size-100" alignItems="baseline">
                  <span className={styles.itemTitle}>Change your password</span>
                </Flex>
                <View>
                  You will change the password for <strong>{account.email}</strong>
                </View>
                <View>
                  <Form onSubmit={onSubmit}>
                    <Flex gap="size-200" direction="column" marginTop="size-300" marginBottom="size-200">
                      <PasswordSection
                        label="Current password"
                        value={formState[fields.currentPassword]?.value}
                        validationState={formState[fields.currentPassword]?.error ? 'invalid' : 'valid'}
                        validationText={formState[fields.currentPassword]?.error}
                        placeholder="Enter current password"
                        onChange={(value) => {
                          updateFormState({ field: 'currentPassword', value, error: '' });
                        }}
                      />
                      <PasswordSection
                        label="New password"
                        value={formState[fields.newPassword]?.value}
                        validationState={formState[fields.newPassword]?.error ? 'invalid' : 'valid'}
                        validationText={formState[fields.newPassword]?.error}
                        placeholder="Enter new password"
                        onChange={(value) => {
                          setNewPasswords(value, formState[fields.newPasswordAgain]?.value);
                        }}
                      />
                      <PasswordSection
                        placeholder="Re-enter new password"
                        value={formState[fields.newPasswordAgain]?.value}
                        validationState={formState[fields.newPasswordAgain]?.error ? 'invalid' : 'valid'}
                        validationText={formState[fields.newPasswordAgain]?.error}
                        onChange={(value) => {
                          setNewPasswords(formState[fields.newPassword]?.value, value);
                        }}
                      />
                    </Flex>

                    <PrimaryActionButton
                      UNSAFE_className={styles.primaryButton}
                      height="36px"
                      displayName="Change Password"
                      type="submit"
                      loading={formLoading}
                    >
                      Change Password
                    </PrimaryActionButton>
                  </Form>
                </View>
              </CollapseSection>
            </SectionGroup>
          )}

          {!isPexaUser && (
            <SectionGroup title="Security">
              <MFA
                user={user}
                phoneNumber={`${account.phoneCountryCode}${account.phone}`}
                account={account}
                activeSection={sectionActive}
                sectionName="MFA"
                changeSectionActive={changeSectionActive}
              />
            </SectionGroup>
          )}
        </div>
      </LegacyLayout>
    </>
  );
};

export default Security;
