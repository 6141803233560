import { Col, Icon, Row } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './StepsLayout.module.less';

const findStepProgressIdx = (steps, currentIdx) => {
  const stepIdx = currentIdx - 1;
  if (stepIdx > 0 && steps[stepIdx].position === undefined) {
    return findStepProgressIdx(steps, currentIdx - 1);
  }
  return currentIdx;
};

const StepsLayout = ({ title, alert, index, children, steps, containerSize: { xs, lg } }) => {
  if (steps[index - 1].name !== 'tradeConfirmation') {
    return (
      <Row>
        <Col md={24} lg={{ span: 16, offset: 4 }}>
          <Row>
            {steps
              .filter(({ position }) => position)
              .map((step) => {
                const currentStepIdx = findStepProgressIdx(steps, index);
                return (
                  <div
                    key={step.index}
                    className={classNames(
                      styles.toolbar,
                      step.index > 1 && styles.spaceIcons,
                      step.index > currentStepIdx && styles.ahead,
                    )}
                  >
                    {step.index > currentStepIdx - 1 ? step.icon : <Icon type="check-circle" />}
                    <span
                      className={classNames(
                        styles.text,
                        step.index < currentStepIdx && styles.passed,
                        step.index > currentStepIdx && styles.ahead,
                      )}
                    >
                      {step.position}
                    </span>
                  </div>
                );
              })}
          </Row>
          <Row className="flex">
            <h1 className="bold">{title}</h1>
          </Row>
          <Row className={styles.container} type="flex" justify="center">
            {alert}
            <Col xs={xs || 24} lg={lg || 14}>
              {children}
            </Col>
          </Row>
          <br />
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col md={24} lg={{ span: 16, offset: 4 }}>
        <Row className={styles.container} type="flex" justify="center">
          <Col xs={xs || 24} lg={lg || 14}>
            {children}
          </Col>
        </Row>
        <br />
      </Col>
    </Row>
  );
};

StepsLayout.propTypes = {
  alert: PropTypes.element,
  steps: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  containerSize: PropTypes.object,
};

StepsLayout.defaultProps = {
  containerSize: { xs: 24, lg: 24 },
};

export default StepsLayout;
