import React from 'react';
import PropTypes from 'prop-types';
import { Row, Button, Col } from 'antd';
import { ThemedImage } from '@send-legacy-components/Common';
import styles from './View.module.less';

const View = ({ onAddRecipient }) => (
  <Row className={styles.container} type="flex" justify="center">
    <Row className={styles.title}>Hooray</Row>
    <Row className={styles.title}>your rate has been confirmed</Row>
    <Row className={styles.subtitle}>
      <p>{`We're reviewing your transfer details and will contact you shortly.`}</p>
      <p>In the meantime, you can view your transfer status on the activity page and process your payment upfront.</p>
    </Row>
    <Row className={styles.box}>
      <Col xs={24} sm={12}>
        <div className={styles.image}>
          <ThemedImage path="images" alt="confirmation" file="donut.png" />
        </div>
      </Col>
      <Col className={styles.buttonContainer} xs={24} sm={12}>
        <div className={styles.underway}>underway</div>
        <div className={styles.boxText}>{`It's nearly time to treat yourself`}</div>
        <div className={styles.button}>
          <Row type="flex" justify="center">
            <Button type="primary" onClick={onAddRecipient}>
              Add recipient now +
            </Button>
          </Row>
        </div>
      </Col>
    </Row>
  </Row>
);

View.propTypes = {
  onAddRecipient: PropTypes.func.isRequired,
};

export default View;
