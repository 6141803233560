import React from 'react';
import { Form, Col } from 'antd';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import DateSelect from './DateSelect';

function CommonFormDateSelect(props) {
  const { getFieldDecorator } = props.onGetFieldDecorator ? { getFieldDecorator: props.onGetFieldDecorator } : props.form;
  const sm = props.sm ? props.sm : props.span;
  const md = props.md ? props.md : sm;
  const checkDate = (rule, value, callback) => {
    if (!value) {
      return callback();
    }
    const date = typeof value === 'number' ? dayjs(value * 1000) : value;
    if (!date.isValid()) {
      return callback('Invalid date');
    }
    if (props.forwardOnly && date < dayjs()) {
      return callback('Cannot select a date in the past');
    }
    if (props.pastOnly && date > dayjs()) {
      return callback('Cannot select a date in the future');
    }
    return callback();
  };
  return (
    <Col span={props.span} md={md} sm={sm}>
      <Form.Item required={false} colon={false} label={props.label}>
        {getFieldDecorator(props.name, {
          initialValue: props.data ? dayjs(props.data * 1000) : props.defaultDate,
          rules: [{ required: props.required, message: `${props.label} is required` }, { validator: checkDate }],
        })(
          <DateSelect disabled={props.disabled} forwardOnly={props.forwardOnly} pastOnly={props.pastOnly} isDob={props.isDob} />,
        )}
      </Form.Item>
    </Col>
  );
}

CommonFormDateSelect.propTypes = {
  form: PropTypes.object,
  onGetFieldDecorator: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  span: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  required: PropTypes.bool,
  defaultDate: PropTypes.object,
  forwardOnly: PropTypes.bool,
  pastOnly: PropTypes.bool,
  data: PropTypes.number,
  disabled: PropTypes.bool,
  isDob: PropTypes.bool,
};

CommonFormDateSelect.defaultProps = {
  form: {},
  onGetFieldDecorator: undefined,
  span: 24,
  sm: 0,
  md: 0,
  label: '',
  required: true,
  forwardOnly: false,
  pastOnly: false,
  isDob: false,
  defaultDate: undefined,
  data: null,
  disabled: false,
};

export default CommonFormDateSelect;
