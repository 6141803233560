import React from 'react';
import PropTypes from 'prop-types';
import { getLabelByKey } from '@send-services/resources';

const ResourceLabel = ({ label, children }) => <>{getLabelByKey({ key: label, default: children })}</>;

ResourceLabel.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ResourceLabel;
