import { isDateOutOfHours } from '@sendpayments/js-utils/dist/utils/dates';
import { appConfig } from '@send-base/app.config';

const { australiaWorkingDays, australiaStartBusinessHoursTime, australiaEndBusinessHoursTime } = appConfig;

export const isOutOfHours = (australiaPublicHolidays: string[]) => {
  return isDateOutOfHours(new Date(), {
    australiaPublicHolidays,
    australiaWorkingDays,
    australiaStartBusinessHoursTime,
    australiaEndBusinessHoursTime,
  });
};
