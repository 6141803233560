import * as React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const InformationIcon = (props: IconProps): JSX.Element => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM6.7 2.39a.36.36 0 0 1 .17-.34 3.12 3.12 0 0 1 2.23-.06.39.39 0 0 1 .2.35v1.22C9.3 5 9 9.01 8.95 9.54a.26.26 0 0 1-.26.26H7.3a.28.28 0 0 1-.29-.24c-.05-.68-.31-4.55-.31-5.94V2.39ZM8 13.43a1.29 1.29 0 0 1-1.4-1.32 1.33 1.33 0 0 1 1.3-1.35H8a1.32 1.32 0 0 1 1.4 1.23v.12A1.29 1.29 0 0 1 8 13.43Z"
      />
    </Icon>
  );
};
