/* eslint-disable react/jsx-one-expression-per-line */
import PropTypes from 'prop-types';
import React from 'react';
import { Row, Divider, Col, Spin } from 'antd';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Icon, sendIconTypes } from '@send-legacy-components/Common';
import { DownloadButton as DownloadPdfButton } from '@sendpayments/react-shared/components/buttons/DownloadButton';
import { PaymentDetails } from '@send-legacy-components/Payment';
import classNames from 'classnames';
import { ConversionList } from '@send-legacy-screens/Activities';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
import { paymentActivityStatus } from '@send-const/statuses';
import styles from './Details.module.less';
dayjs.extend(localizedFormat);

const Detail = ({
  loading,
  sellCurrency,
  bookedSellAmount,
  bookedBuyAmount,
  buyCurrency,
  status,
  legacyContractId,
  id,
  forwardContract,
  createdAt,
  conversionDate,
  bookedClientRate,
  conversion,
  payments,
  // this is from parent
  businessName,
  firstName,
  lastName,
  // this is from reg
  sendId,
  // callbacks
  onDownloadClick,
}) => (
  <Spin spinning={loading}>
    <div className={styles.container}>
      <Row>
        <Row type="flex" justify="space-between">
          <div>
            <h1 className="bold">
              {`${bookedSellAmount && bookedSellAmount.toLocaleString()} ${sellCurrency} to ${
                bookedBuyAmount && bookedBuyAmount.toLocaleString()
              } ${buyCurrency}`}
            </h1>
            <h3>Here you will find all your transfer and recipient details</h3>
          </div>
        </Row>
        <br />
        <Divider className="title-underline secondary" />
        <br />
      </Row>
      <ConversionList conversions={[conversion]} paymentsByConvId={{ [id]: payments }} noLink />
      <Row>
        <div className={styles.title}>
          <span>
            <Icon type={sendIconTypes.SWAP} />
            <span className={styles.mainTitle}>Overview </span>
          </span>
          <DownloadPdfButton
            className="download-btn"
            onClick={() => onDownloadClick(id, CoconutTypes.CONVERSION)}
            fileName={`Transfer-${legacyContractId || (id && id.slice(0, 8).toUpperCase())}`}
            text="Download Contract"
          />
        </div>
      </Row>
      <Row className={classNames(styles.details, 'details')}>
        <Col span={24} md={12} className={classNames(styles.firstColumn, 'item')}>
          <label>Contract ID</label>
          <p>{legacyContractId || (id && id.slice(0, 8).toUpperCase())}</p>
        </Col>
        {bookedSellAmount && (
          <Col span={24} md={12} className={classNames(styles.secondColumn, 'item')}>
            <label>Amount Sending</label>
            <p>
              {bookedSellAmount.toLocaleString()} {sellCurrency}
            </p>
          </Col>
        )}
        <Col span={24} md={12} className={classNames(styles.firstColumn, 'item')}>
          <label>Send account holder</label>
          <p>{businessName || `${firstName} ${lastName}`}</p>
        </Col>
        {bookedBuyAmount && (
          <Col span={24} md={12} className={classNames(styles.secondColumn, 'item')}>
            <label>Amount Receiving</label>
            <p>
              {bookedBuyAmount.toLocaleString()} {buyCurrency}
            </p>
          </Col>
        )}
        <Col span={24} md={12} className={classNames(styles.firstColumn, 'item')}>
          <label>Client ID</label>
          <p>{sendId}</p>
        </Col>
        {bookedClientRate && (
          <Col span={24} md={12} className={classNames(styles.secondColumn, 'item')}>
            <label>Exchange Rate</label>
            <p>{bookedClientRate}</p>
          </Col>
        )}
        <Col span={24} md={12} className={classNames(styles.firstColumn, 'item')}>
          <label>Type of Contract</label>
          <p>{forwardContract ? 'Forward' : 'Spot'}</p>
        </Col>
        <Col span={24} md={12} className={classNames(styles.secondColumn, 'item')}>
          <label>Send Transfer Fee</label>
          <p>None</p>
        </Col>
        <Col span={24} md={12} className={classNames(styles.firstColumn, 'item')}>
          <label>Contract Date</label>
          <p>{dayjs(createdAt).format('LL')}</p>
        </Col>
        <Col span={24} md={12} className={classNames(styles.secondColumn, 'item')}>
          <label>Date of Transfer</label>
          <p>{dayjs(conversionDate).format('LL')}</p>
        </Col>
        <Col span={24} md={12} className={classNames(styles.firstColumn, 'item')}>
          <label>Currency Pairing</label>
          <p>
            {sellCurrency} / {buyCurrency}
          </p>
        </Col>
      </Row>
      {payments.map((payment) => (
        <Row className={styles.recipient} key={payment.id}>
          <div className={styles.title}>
            <span>
              <Icon type="user-add" />
              <span className={styles.mainTitle}>Recipient</span>
            </span>
            {payment.status === paymentActivityStatus.COMPLETED && (
              <DownloadPdfButton
                onClick={() => onDownloadClick(payment.id, CoconutTypes.PAYMENT)}
                fileName={`Payment-${payment.id && payment.id.slice(0, 8).toUpperCase()}`}
                text="Payment Receipt"
              />
            )}
          </div>
          <PaymentDetails payment={payment} />
        </Row>
      ))}
      {status === 'awaiting_funds' && (
        <Row className={styles.tc}>
          <p>
            Unless previously agreed, we only accept payments from your bank account. We do not accept payments via cash or
            cheques under any circumstances. Please note that while Send does not charge you any transfer fees, we are not able to
            control fees passed to you by third-party banks which you may be charged for. Unfortunately, Send does not receive any
            notice that these fees are charged. This may impact the final amount you receive in your account. Your transfer is a
            legally binding contract and is subject to our Terms and Conditions. If you breach our Terms and Conditions, Send
            reserves the right to terminate your transfer and recover any losses from you.
          </p>
        </Row>
      )}
    </div>
  </Spin>
);

Detail.propTypes = {
  loading: PropTypes.bool,
  sellCurrency: PropTypes.string,
  bookedSellAmount: PropTypes.number,
  bookedBuyAmount: PropTypes.number,
  bookedClientRate: PropTypes.number,
  buyCurrency: PropTypes.string,
  status: PropTypes.string,
  legacyContractId: PropTypes.string,
  id: PropTypes.string,
  forwardContract: PropTypes.string,
  createdAt: PropTypes.string,
  conversionDate: PropTypes.string,
  conversion: PropTypes.object,
  payments: PropTypes.array,
  // this is from parent
  businessName: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  // from reg
  sendId: PropTypes.string,
  // callbacks
  onDownloadClick: PropTypes.func.isRequired,
};

Detail.defaultProps = {
  loading: false,
  // conversion
  sellCurrency: undefined,
  bookedSellAmount: undefined,
  bookedBuyAmount: undefined,
  bookedClientRate: undefined,
  buyCurrency: undefined,
  status: undefined,
  legacyContractId: undefined,
  id: undefined,
  forwardContract: undefined,
  createdAt: undefined,
  conversionDate: undefined,
  conversion: undefined,
  payments: [],
  // this is from parent
  businessName: undefined,
  firstName: undefined,
  lastName: undefined,
  // from reg
  sendId: undefined,
};

export default Detail;
