import React from 'react';
import { Flex } from '@adobe/react-spectrum';
import { sizes } from '@sendpayments/react-shared/design-system';
import styles from './SubMenu.module.less';

const SubMenu = (props) => {
  const { children } = props;
  return (
    <div className={styles.container}>
      <Flex gap={sizes.size40}>{children}</Flex>
    </div>
  );
};

export default SubMenu;
