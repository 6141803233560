import { triggerToast } from '@send-components/Toast';
import { VerifyCodeModal } from '@send-components/VerifyCodeModal';
import { logger } from '@sendpayments/js-utils/dist/services/logger';

import React, { useEffect, useState } from 'react';
import { post } from '@send-base/utilities/endpoint';
import Alert from '@spectrum-icons/workflow/Alert';
import Info from '@spectrum-icons/workflow/Info';

function VerifyPhoneWrapper({ account, setOpen, onComplete, accessToken }) {
  const [codeDeliveryDetail, setCodeDeliveryDetail] = useState(null);

  useEffect(() => {
    if (accessToken) {
      verifyPhoneNumber();
    }
  }, []);

  const verifyPhoneNumber = async () => {
    try {
      const payload = {
        accessToken,
        regType: account.type,
      };
      const verifyPhoneRes = await post('/profile/verifyPhone', {
        data: payload,
      });
      setCodeDeliveryDetail(verifyPhoneRes.data?.CodeDeliveryDetails);
    } catch (error) {
      logger.error('phoneSetting', 'verifyPhoneNumber', error);
      triggerToast({
        type: 'error',
        message: 'Error sending verification code. You may have sent too many recently. Please wait some minutes, and try again.',
        rightIcon: <Alert size="M" />,
      });
      setOpen(false);
    }
  };

  const onCodeComplete = async (code) => {
    try {
      await post('/profile/verifyPhoneSubmit', {
        data: {
          accessToken,
          regType: account.type,
          code,
        },
      });
      if (setOpen) {
        setOpen(false);
      }
      triggerToast({
        type: 'success',
        message: 'Your phone number has been verified',
        rightIcon: <Info size="M" />,
      });
      // eslint-disable-next-line no-unused-expressions
      onComplete?.();
    } catch (error) {
      logger.error('phoneSetting', 'verifyPhoneNumberSubmit', error);
      triggerToast({
        type: 'error',
        message: 'Error submitting verification code.',
        rightIcon: <Alert size="M" />,
      });
    }
  };

  const dismiss = () => {
    if (setOpen) {
      setOpen(false);
    }
  };

  return (
    <VerifyCodeModal
      isOpen={codeDeliveryDetail}
      title="We’ve just sent you an SMS"
      description={`To verify your new phone number, <strong>please enter the 6-digit security code we sent to ${codeDeliveryDetail?.Destination}.</strong>`}
      onCodeComplete={onCodeComplete}
      onResendCode={verifyPhoneNumber}
      onDismiss={dismiss}
    />
  );
}

export default React.memo(VerifyPhoneWrapper);
