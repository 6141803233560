import React, { useState, useEffect } from 'react';
import { Flex, View, Divider } from '@adobe/react-spectrum';
import { LegacyLayout } from '@send-components/LegacyLayout';
import { SectionGroup } from '@send-components/SectionGroup';
import { CollapseSection } from '@send-components/CollapseSection';
import { SubMenu, Item } from '@send-components/SubMenu';
import { useRegistration, useAppContext } from '@send-data-hooks';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
import { breakpoints } from '@sendpayments/react-shared/design-system';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { CloseAccount } from '@send-components/Account/CloseAccount';
import { ChangeEmail } from '@send-components//Account/ChangeEmail';
import { GridRow, GridColumn as GridCol } from '@sendpayments/react-shared/design-system/grid-system';
import { PhoneSetting } from '@send-components/Account/PhoneSetting';
import { ProfilePhoto } from '@send-components/Account/ProfilePhoto';
import dayjs from 'dayjs';
import { triggerToast } from '@send-components/Toast';
import history from '@send-legacy-app/history';
import MFAHelper from '@send-components/Account/Security/MFA/MFAHelper';
import logger from '@sendpayments/js-utils/dist/services/logger';
import styles from './Account.module.less';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const formatBirthDate = (dateString) => {
  return dateString ? dayjs(dateString).format('DD MMM YYYY') : '';
};

const getFullAddress = (account) => {
  let result;
  if (account.type === CoconutTypes.RETAIL) {
    result = [
      account.streetAddressUnitNumber,
      account.streetAddressNumber,
      account.streetAddressName,
      account.streetAddressType,
      account.city,
      account.state,
      account.postcode,
      account.country,
    ];
  } else {
    result = [
      account.corporateStreetAddress,
      account.corporateCity,
      account.corporateState,
      account.corporatePostcode,
      account.corporateCountry,
    ];
  }

  return result.join(' ');
};

const FieldItem = ({ label, value }) => {
  return (
    <div className={styles.fieldItem}>
      <Flex direction="column" gap="size-40">
        <div className={styles.label}>{label}</div>
        <div className={styles.value}>{value}</div>
        <Divider size="S" marginTop="5px" />
      </Flex>
    </div>
  );
};

const AccountPersonalInformation = ({ account }) => {
  if (account.type === CoconutTypes.RETAIL) {
    return (
      <View>
        <GridRow>
          <GridCol md={6}>
            <FieldItem label="Address" value={getFullAddress(account)} />
          </GridCol>
        </GridRow>
      </View>
    );
  }
  return (
    <View>
      <GridCol md={6}>
        <GridCol md={12}>
          <FieldItem label="Business name" value={account.businessName} />
        </GridCol>
        <GridCol md={12}>
          <FieldItem label="Address" value={getFullAddress(account)} />
        </GridCol>
        <GridCol md={12}>
          <FieldItem label="Business registration number" value={account.registrationNumber} />
        </GridCol>
      </GridCol>
    </View>
  );
};

const ProfileSetting = () => {
  const [{ data: account }] = useRegistration();
  const { isMedia } = useBreakpoint(breakpoints);
  const isTablet = isMedia({ to: breakpoints.sm });
  const [{ user }] = useAppContext();
  const [sectionActive, setSectionActive] = useState('');
  const email = user && user.attributes && user.attributes.email;

  useEffect(() => {
    if (history.location.state?.openPhoneToast) {
      triggerToast({
        type: 'info',
        message: 'Please add a phone number to your account.',
      });
    }
  }, []);

  const isPexaUser = account && account.portalSource === 'pexa';

  useEffect(() => {
    if (!isPexaUser) {
      logger.log('ProfileSetting', 'Triggering 2fa reminder');
      // 2fa reminder
      MFAHelper.userHasMfaEnabled(user).then((result) => {
        if (user && !result && window.sessionStorage?.getItem('mfaReminderProfileDismissed') !== 'true') {
          triggerToast({
            type: 'info',
            message: 'Add more security to your account with 2-step verification',
            link: '/setting/security',
          });

          // eslint-disable-next-line no-unused-expressions
          window.sessionStorage?.setItem('mfaReminderProfileDismissed', 'true');
        }
      });
    }
  }, [user]);

  const changeSectionActive = (choosenSectionName) => {
    setSectionActive(choosenSectionName);
  };

  return (
    <>
      <LegacyLayout activeMenu="setting">
        <SubMenu>
          <Item routerTo="/setting/profile">Profile</Item>
          <Item routerTo="/setting/security">Password and security</Item>
          {!isPexaUser && <Item routerTo="/setting/notification">Notification</Item>}
        </SubMenu>
        <div className={styles.container}>
          <SectionGroup>
            <CollapseSection
              noDivider
              isVerticalTrigger={isTablet}
              buttonText="Personal information"
              activeSection={sectionActive}
              sectionName="Personal information"
              changeSectionActive={changeSectionActive}
            >
              <View>
                <div className={styles.title}>{[account.firstName, account.middleName, account.lastName].join(' ')}</div>
                <View>Send ID {account.sendId}</View>
              </View>
              <View />
              <View paddingTop={15}>
                <AccountPersonalInformation account={account} />
                <View>
                  <small>
                    <strong>If you like to change these personal settings contact your dedicated agent.</strong>
                  </small>
                </View>
              </View>
            </CollapseSection>
          </SectionGroup>
          <ProfilePhoto account={account} />
          <SectionGroup title="Details">
            <ChangeEmail
              account={account}
              user={user}
              activeSection={sectionActive}
              sectionName="Email"
              changeSectionActive={changeSectionActive}
            />
            <PhoneSetting
              account={account}
              activeSection={sectionActive}
              sectionName="PhoneVerify"
              changeSectionActive={changeSectionActive}
            />
            <CloseAccount
              email={email}
              account={account}
              activeSection={sectionActive}
              sectionName="CloseAccount"
              changeSectionActive={changeSectionActive}
            />
          </SectionGroup>
        </div>
      </LegacyLayout>
    </>
  );
};

export default ProfileSetting;
