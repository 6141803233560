import { useRemoteData } from '@sendpayments/react-shared/hooks/useRemoteData';
import { query } from '@send-base/utilities/endpoint';
import * as queries from '@sendpayments/graphql/queries';
import { buildUniqueKey } from '@sendpayments/js-utils/dist/services/build-unique-key';
import { CoconutTypes } from '@sendpayments/shared-constants/types';

const fetchPayment = async ({ id }) => {
  const payment = await query(queries.getCoconut, {
    params: { id, type: CoconutTypes.PAYMENT },
  });

  return payment;
};

const usePayment = ({ id }) =>
  useRemoteData(
    () => ({
      key: buildUniqueKey('queries.getCoconut', { id, type: CoconutTypes.PAYMENT }),
      onInitialFetch: () => fetchPayment({ id }),
      initialState: {},
    }),
    [id],
  );

export default usePayment;
