import React, { useEffect, useState } from 'react';
import RcUpload from 'rc-upload';
import uuid from 'uuid/v4';
import { uploadS3 } from '@sendpayments/js-utils/dist/services/s3-manager';
import { Flex, View, Divider } from '@adobe/react-spectrum';
import { SectionGroup } from '@send-components/SectionGroup';
import { Storage } from 'aws-amplify';
import { saveEntity } from '@sendpayments/js-utils/dist/services/entities/save-entity';
import Alert from '@spectrum-icons/workflow/Alert';
import { triggerToast } from '@send-components/Toast';
import { logger } from '@sendpayments/js-utils/dist/services/logger';
import Info from '@spectrum-icons/workflow/Info';
import { PrimaryButton } from '@send-components/PrimaryButton';
import { breakpoints } from '@sendpayments/react-shared/design-system';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import * as styles from './ProfilePhoto.module.less';
import { AvatarUpload } from './AvatarUpload.tsx';

const ProfilePhoto = ({ account }) => {
  const sectionTitle = account.profilePhoto ? 'Photo' : 'Customise';
  const [profilePhotoURL, setProfilePhotoURL] = useState('');
  const [isDisabledForm, setIsDisabledForm] = useState(false);
  const { isMedia } = useBreakpoint(breakpoints);
  const isTablet = isMedia({ to: breakpoints.sm });

  useEffect(() => {
    (async () => {
      if (account.profilePhoto) {
        const imgUrl = await Storage.get(account.profilePhoto, { level: 'private' });
        setProfilePhotoURL(imgUrl);
      }
    })();
  }, [account.profilePhoto]);

  const uploadProps = {
    multiple: false,
    accept: 'image/jpeg, image/png',
    beforeUpload: (file) => {
      return new Promise((resolve) => {
        const magicNumbers = {
          jpg: [255, 216, 255],
          png: [137, 80, 78, 71, 13, 10, 26, 10],
        };

        const mimeTypes = {
          jpg: 'image/jpeg',
          png: 'image/png',
        };

        const reader = new FileReader();
        reader.onloadend = (event) => {
          if (file.size > 5242880) {
            triggerToast({
              type: 'error',
              message: '[Error] The selected file is over 5MB.',
              rightIcon: <Alert size="M" />,
            });
            resolve(false);
            return;
          }

          const arr = new Uint8Array(event.target.result);
          const isMagicNumberValid = Object.entries(magicNumbers).some(([type, magicNumber]) =>
            magicNumber.every((val, index) => val === arr[index]),
          );
          const isMimeTypeValid = Object.values(mimeTypes).includes(file.type);

          if (!isMagicNumberValid || !isMimeTypeValid) {
            triggerToast({
              type: 'error',
              message: '[Error] The supplied file must be either PNG or JPEG.',
              rightIcon: <Alert size="M" />,
            });
            resolve(false);
          } else {
            resolve(true);
          }
        };

        reader.readAsArrayBuffer(file.slice(0, 8));
      });
    },

    async onSuccess(res) {
      await saveEntity({
        entity: {
          id: account.id,
          profilePhoto: res.key,
        },
        type: account.type,
      });
      setProfilePhotoURL(res.preSignedUrl);
      triggerToast({
        type: 'success',
        message: 'Your profile picture has been updated',
        rightIcon: <Info size="M" />,
      });
      setIsDisabledForm(false);
    },
    onError(err) {
      logger.error('profilePhoto', 'upload', err);
      triggerToast({
        type: 'error',
        message: '[Error] An error occurred when saving the photo. Please try again later.',
        rightIcon: <Alert size="M" />,
      });
      setIsDisabledForm(false);
    },
    async customRequest({ file, onError, onSuccess }) {
      setIsDisabledForm(true);
      try {
        const result = await uploadS3(
          {
            name: `profile_${uuid()}`,
            content: file,
            type: file.type,
          },
          {
            level: 'private',
          },
        );
        if (result.withError) {
          onError(result.withError);
        } else {
          onSuccess(result);
        }
      } catch (err) {
        onError(err);
      }
    },
  };

  let profilePhoto;
  if (!account.profilePhoto) {
    profilePhoto = <AvatarUpload style={{ color: 'var(--primary-color)' }} />;
  } else if (profilePhotoURL) {
    profilePhoto = <img src={profilePhotoURL} alt="profile-image" />;
  }

  return (
    <SectionGroup title={sectionTitle}>
      <Divider size="S" marginBottom="20px" />
      <Flex
        direction={isTablet ? 'column' : 'row'}
        gap={isTablet ? 'size-225' : 'size-100'}
        alignItems="start"
        marginBottom="40px"
      >
        <View flexGrow="1">
          <Flex direction="row" gap="size-200" alignItems="center">
            <div className={styles.profilePhoto}>
              <RcUpload {...uploadProps}>{profilePhoto}</RcUpload>
            </div>
            <View>
              <div className={styles.itemTitle}>Profile photo</div>
              <View>Max file 5mb, PNG or JPEG</View>
            </View>
          </Flex>
        </View>
        {account.profilePhoto && (
          <RcUpload {...uploadProps}>
            <div style={{ cursor: 'pointer' }}>
              <PrimaryButton isDisabled={isDisabledForm} UNSAFE_style={{ zIndex: -1 }}>
                Upload a new photo
              </PrimaryButton>
            </div>
          </RcUpload>
        )}
      </Flex>
    </SectionGroup>
  );
};

export default ProfilePhoto;
