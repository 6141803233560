import React, { useState, useEffect } from 'react';
import { InfoPanel, Item as InfoPanelItem } from '@send-components/InfoPanel';
import { PrimaryLink } from '@send-components/PrimaryLink';
import VerifyPhoneWrapper from '@send-components/Account/VerifyPhoneWrapper';
import VerifyPhoneReminderModal from '@send-components/Account/VerifyPhoneReminderModal';
import { PrimaryActionButton } from '@sendpayments/react-shared/components/buttons/PrimaryActionButton';
import { PrimaryButton } from '@send-components/PrimaryButton';
import InfoPanelInner from '@send-components/Account/PhoneReminders/InfoPanelInner';
import { Flex } from '@adobe/react-spectrum';
import useAuth from '@send-base/data-hooks/useAuth';
import { SMSKey } from '@send-components/Icons';

/**
 * @param {{ onPhoneAdd: Function; onCompleteVerify: Function; account: any }} props
 */
function PhoneReminders({ onPhoneAdd, onCompleteVerify, account }) {
  const { accessToken } = useAuth();

  const [verifyState, setVerifyState] = useState('not-verified');

  useEffect(() => {
    // Make sure state's initial value is always up to date
    // https://stackoverflow.com/questions/54865764/react-usestate-does-not-reload-state-from-props
    setVerifyState(account.phoneNumberVerified ? 'verified' : 'not-verified');
  }, [account]);

  if (!account?.phone) {
    return (
      <InfoPanel style={{ width: '100%' }}>
        <InfoPanelItem icon={SMSKey}>
          <Flex>
            <div>
              You currently don’t have a phone number attached to this account. Would you like to{' '}
              <PrimaryLink onClick={onPhoneAdd}>add a phone number</PrimaryLink> to use SMS?
            </div>
          </Flex>
        </InfoPanelItem>
      </InfoPanel>
    );
  }

  if (verifyState === 'not-verified') {
    return <InfoPanelInner onClick={() => setVerifyState('pre-verify')} loading={false} />;
  }

  if (verifyState === 'pre-verify') {
    return (
      <>
        <InfoPanelInner key={1} />
        <VerifyPhoneReminderModal
          title="Verify your phone number"
          onClose={() => setVerifyState('not-verified')}
          buttons={
            <>
              <PrimaryActionButton height="inherit" onClick={() => setVerifyState('verify')}>
                Verify
              </PrimaryActionButton>
              <PrimaryButton height="inherit" onClick={() => setVerifyState('not-verified')}>
                Verify later
              </PrimaryButton>
            </>
          }
        >
          <Flex gap="size-200" direction="column">
            You will need to verify your phone number before you can use SMS for Notifications and alerts.
            <strong>
              Would you like to verify +{'*'.repeat(8)}
              {account.phone?.slice(-4)}?
            </strong>
          </Flex>
        </VerifyPhoneReminderModal>
      </>
    );
  }

  if (verifyState === 'verify') {
    return (
      <>
        <InfoPanelInner key={2} loading />
        <VerifyPhoneWrapper
          accessToken={accessToken}
          account={account}
          setOpen={(res) => {
            if (!res) {
              setVerifyState('not-verified');
            }
          }}
          onComplete={() => {
            setVerifyState('verified');
            // eslint-disable-next-line no-unused-expressions
            onCompleteVerify?.();
          }}
        />
      </>
    );
  }
  return null;
}

export default PhoneReminders;
