import React, { useImperativeHandle, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import { post } from '@send-base/utilities/endpoint';
import { useReasonCodes, usePayment } from '@send-data-hooks';
import { getCountryCode } from '@sendpayments/js-utils/dist/utils/common';
import { ConfirmRecipientForm } from '@send-legacy-components/DealerAssist';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
import { paymentActivityStatus } from '@send-const/statuses';
import { logger } from '@sendpayments/js-utils/dist/services/logger';

const getReasonList = ({ transferReasons, paymentReasons }) => {
  if (paymentReasons.length > 0) {
    return paymentReasons;
  }

  return transferReasons;
};

const ConfirmRecipient = forwardRef(
  (
    {
      paymentDate,
      conversionId,
      parentId,
      parentType,
      toAmount,
      onEditClick,
      toCurrency,
      bankCountry,
      name,
      accountNumber,
      bsbCode,
      email,
      sortCodeNumber,
      reason,
      reference,
      iban,
      clabeNumber,
      beneficiaryType,
    },
    ref,
  ) => {
    const formRef = useRef(null);
    const [loading, setLoading] = React.useState(false);
    const [
      {
        data: { transferReasons, paymentReasons },
      },
    ] = useReasonCodes({ toCurrency, bankCountryCode: getCountryCode(bankCountry), entityType: beneficiaryType });
    const [, { partialCreateOrUpdateEntity }] = usePayment({});

    const reasonCodesList = getReasonList({ transferReasons, paymentReasons });

    useImperativeHandle(ref, () => ({
      onSave: async (result) => {
        try {
          setLoading(true);

          const {
            reason: formReasonValue,
            reference: formReferenceValue,
            otherReason: formOtherReasonValue,
          } = await formRef.current.getFields();

          const { code, description } = reasonCodesList.find(({ code: codeReason }) => codeReason === formReasonValue);

          const newReason = formOtherReasonValue ? `${description} - ${formOtherReasonValue}` : description;

          const data = {
            parentId,
            parentType,
            amount: parseFloat(toAmount.toFixed(2)),
            currency: toCurrency,
            reference: formReferenceValue,
            beneficiaryId: result.id,
            reason: newReason,
            // this is only going to be used by currency cloud for certain payments
            purposeCode: paymentReasons.length > 0 ? code : undefined,
            paymentDate,
            conversionId,
          };

          // there is an issue with appSync not able to refresh token with multiple concurrent calls. we are splitting this so
          // there si enough time for the token to be refresh. We will have to take a look to understand a little bit more
          // why this is happening
          const payment = await post('/dealing/customer/create-payment', {
            data,
          });

          logger.log('ConfirmRecipient', 'onSave', payment);

          partialCreateOrUpdateEntity({
            id: payment.id,
            type: CoconutTypes.PAYMENT,
            status: payment.status || paymentActivityStatus.UNDERWAY,
            ...data,
          });

          setLoading(false);

          return {
            ...payment,
            paymentDate,
            conversionId,
            parentId,
            parentType,
            toAmount,
            onEditClick,
            toCurrency,
            bankCountry,
            name,
            accountNumber,
            bsbCode,
            email,
            sortCodeNumber,
            reason,
            reference,
            iban,
            clabeNumber,
          };
        } catch (ex) {
          setLoading(false);
          if (ex?.formFieldErrors) {
            throw new Error('Please complete the required fields.');
          }
          logger.error('ConfirmRecipient', 'onSave', ex);
          throw new Error('Unable to confirm recipient.');
        }
      },
    }));

    return (
      <ConfirmRecipientForm
        ref={formRef}
        onEditClick={onEditClick}
        name={name}
        accountNumber={accountNumber}
        bsbCode={bsbCode}
        email={email}
        sortCodeNumber={sortCodeNumber}
        reference={reference}
        reason={reason}
        iban={iban}
        clabeNumber={clabeNumber}
        reasonCodesList={reasonCodesList}
        loading={loading}
      />
    );
  },
);

ConfirmRecipient.propTypes = {
  onEditClick: PropTypes.func.isRequired,
  name: PropTypes.string,
  accountNumber: PropTypes.string,
  bsbCode: PropTypes.string,
  toCurrency: PropTypes.string,
  bankCountry: PropTypes.string,
  email: PropTypes.string,
  sortCodeNumber: PropTypes.string,
  reason: PropTypes.string,
  reference: PropTypes.string,
  parentId: PropTypes.string,
  parentType: PropTypes.string,
  toAmount: PropTypes.number,
  paymentDate: PropTypes.string,
  beneficiaryType: PropTypes.string,
  conversionId: PropTypes.string,
  iban: PropTypes.string,
  clabeNumber: PropTypes.string,
};

ConfirmRecipient.defaultProps = {
  name: undefined,
  accountNumber: undefined,
  bsbCode: undefined,
  email: undefined,
  toCurrency: undefined,
  bankCountry: undefined,
  sortCodeNumber: undefined,
  beneficiaryType: undefined,
  reason: undefined,
  reference: undefined,
  parentId: undefined,
  parentType: undefined,
  toAmount: undefined,
  paymentDate: undefined,
  conversionId: undefined,
  iban: undefined,
  clabeNumber: undefined,
};
export default ConfirmRecipient;
