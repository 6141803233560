import React, { useState, useEffect, useReducer } from 'react';
import { withTheme } from '@sendpayments/react-shared';
import { useRegistration } from '@send-base/data-hooks';
import { saveEntity } from '@sendpayments/js-utils/dist/services/entities';
import { Checkbox } from '@send-components/Checkbox';
import { Flex } from '@adobe/react-spectrum';
import { breakpoints } from '@sendpayments/react-shared/design-system';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { PrimaryActionButton } from '@sendpayments/react-shared/components/buttons/PrimaryActionButton';
import { PrimaryButton } from '@sendpayments/react-shared/components/buttons/PrimaryButton';
import { SimpleModal } from '@sendpayments/react-shared/components/SimpleModal';
import SettingItem from '../SettingItem';
import styles from './TransferStatusSection.module.less';

function TransferStatusSection({ triggerToast }) {
  const { isMedia } = useBreakpoint(breakpoints);
  const isMobile = isMedia({ to: breakpoints.xs });
  const [showNotificationWarning, setShowNotificationWarning] = useState(false);

  const [{ data: account }] = useRegistration();

  /**
   * @typedef {{ sms: boolean; email: boolean; }} TransferStatusState
   */
  /**
   * @type {[TransferStatusState, React.Dispatch<{ type: keyof TransferStatusState; selected: boolean }>]}
   */
  const [transferStatusState, dispatchTransferStatus] = useReducer(
    (state, { type, selected }) => {
      switch (type) {
        case 'sms':
          return { ...state, sms: !!selected };
        case 'email':
          return { ...state, email: !!selected };
        default:
          throw new Error('Reducer problem');
      }
    },
    {
      email: account.emailTransferStatusNotificationsEnabled,
      sms: account.phoneNumberVerified && account.smsTransferStatusNotificationsEnabled,
    },
  );

  useEffect(() => {
    dispatchTransferStatus({ type: 'email', selected: account.emailTransferStatusNotificationsEnabled });
  }, [account.emailTransferStatusNotificationsEnabled]);

  useEffect(() => {
    dispatchTransferStatus({
      type: 'sms',
      selected: account.phoneNumberVerified && account.smsTransferStatusNotificationsEnabled,
    });
  }, [account.smsTransferStatusNotificationsEnabled]);

  /**
   * @param {'email' | 'sms'} type
   * @param {boolean} type
   */
  async function updateTransferStatusNotifications(type, selected, triggerOnce) {
    // If trying to disable both fields, warn the user
    if (!selected) {
      if ((type === 'sms' && !transferStatusState.email) || (type === 'email' && !transferStatusState.sms)) {
        if (!showNotificationWarning) {
          setShowNotificationWarning(true);
          return;
        }
      }
    }

    try {
      if (type === 'sms') {
        if (account.id) {
          await saveEntity({ entity: { id: account.id, smsTransferStatusNotificationsEnabled: selected }, type: account.type });
        }
        dispatchTransferStatus({ type, selected });
      } else if (type === 'email') {
        if (account.id) {
          await saveEntity({ entity: { id: account.id, emailTransferStatusNotificationsEnabled: selected }, type: account.type });
        }
        dispatchTransferStatus({ type, selected });
      }

      if (triggerOnce) {
        triggerToast({ type: 'success', message: 'Your notification option has been updated' });
      }
    } catch (err) {
      if (triggerOnce) {
        triggerToast({
          type: 'error',
          message: 'Error updating transfer notification settings',
        });
      }

      throw err;
    }
  }

  return (
    <SettingItem isMobile={isMobile} title="Transfer status" desc="Receive updates about your transfers and recipients.">
      {showNotificationWarning && (
        <SimpleModal
          title="Enable Notification"
          buttons={
            <div className={styles.buttonWrapper}>
              <Flex direction="row" gap={isMobile ? 'size-100' : 'size-150'}>
                <PrimaryActionButton
                  onClick={async () => {
                    await updateTransferStatusNotifications('email', true, true);
                    await updateTransferStatusNotifications('sms', false, false);
                    setShowNotificationWarning(false);
                  }}
                >
                  Email Option
                </PrimaryActionButton>
                <PrimaryButton
                  onClick={async () => {
                    await updateTransferStatusNotifications('sms', true, true);
                    await updateTransferStatusNotifications('email', false, false);
                    setShowNotificationWarning(false);
                  }}
                >
                  SMS Option
                </PrimaryButton>
              </Flex>
            </div>
          }
          onDismiss={() => {
            setShowNotificationWarning(false);
          }}
        >
          You need to have at least one option selected to receive notifications regarding your transfer status.
          <br />
          <strong className={styles.strong}>Please select an option to be notified!</strong>
        </SimpleModal>
      )}
      <Checkbox
        isSelected={transferStatusState.sms}
        isDisabled={!account.phoneNumberVerified}
        onChange={(value) => {
          updateTransferStatusNotifications('sms', value, true);
        }}
      >
        SMS
      </Checkbox>
      <Checkbox
        isSelected={transferStatusState.email}
        onChange={(value) => updateTransferStatusNotifications('email', value, true)}
      >
        Email
      </Checkbox>
    </SettingItem>
  );
}

export default withTheme(TransferStatusSection);
