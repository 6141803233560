import { useRemoteData } from '@sendpayments/react-shared/hooks/useRemoteData';
import { query } from '@send-base/utilities/endpoint';
import * as queries from '@sendpayments/graphql/queries';
import { buildUniqueKey } from '@sendpayments/js-utils/dist/services/build-unique-key';
import { ConvType } from '@send-const/types';
import { CoconutTypes } from '@sendpayments/shared-constants/types';

const fetchConversion = async ({ id }) => {
  const conversion = await query(queries.getCoconut, {
    params: { id, type: CoconutTypes.CONVERSION },
  });

  const { status } = conversion;
  let newStatus = status;
  if (status === ConvType.CompliancePending) {
    newStatus = ConvType.AwaitingFunds;
  }
  if (status === ConvType.AwaitingFunds && conversion.fullFundsReceived) {
    newStatus = ConvType.FundsReceived;
  }

  return { ...conversion, status: newStatus };
};

const useConversion = ({ id }) =>
  useRemoteData(
    () => ({
      key: buildUniqueKey('queries.getCoconut', { id, type: CoconutTypes.CONVERSION }),
      onInitialFetch: () => fetchConversion({ id }),
      initialState: {},
    }),
    [id],
  );

export default useConversion;
