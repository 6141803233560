import React, { useEffect } from 'react';
import { FeaturesToggleProviderBuilder } from '@sendpayments/react-shared/components/Features';
import { RemoteDataProvider } from '@sendpayments/react-shared/remote-data';
import { initializeFeatureFlags } from '@sendpayments/js-utils/dist/services/feature';
import { WhiteLabelRedirect } from '@send-common-components/WhiteLabelRedirect';
import { initializeDependencies } from '@send-services/initialize-dependencies';
import { env, credentialKey } from '@send-services/environment';
import credentials from '@send-base/services.config';
import { useAppContext } from '@send-data-hooks';
import StateProvider from './context/Provider';
import './App.module.less';
import { getPortalSource } from '../../services/white-label';

const isProdEnv = (environment) => {
  return environment && environment.includes('prod');
};

const portalSource = getPortalSource();

const getFeaturesFlagCredential = () => {
  return credentials.launchdarkly[credentialKey][portalSource];
};

const isProd = isProdEnv(env);
const featuresFlagCredentials = getFeaturesFlagCredential(credentials.launchdarkly, env);

const FeaturesToggleProvider = FeaturesToggleProviderBuilder({
  credentials: featuresFlagCredentials,
});

initializeDependencies();

const AppContainer = ({ children }) => {
  const [{ user }] = useAppContext();

  const userEmail = user?.attributes?.email;

  useEffect(() => {
    if (user) {
      const email = user.attributes?.email;
      if (email) {
        initializeFeatureFlags({ credentials: featuresFlagCredentials, email });
      }
    }
  }, [user]);

  return (
    <RemoteDataProvider email={userEmail} isProd={isProd}>
      <FeaturesToggleProvider allowDevTools={!isProd} userEmail={userEmail}>
        <WhiteLabelRedirect>{children}</WhiteLabelRedirect>
      </FeaturesToggleProvider>
    </RemoteDataProvider>
  );
};

const AppContainerWithProvider = ({ children }) => (
  <StateProvider>
    <AppContainer>{children}</AppContainer>
  </StateProvider>
);

export default AppContainerWithProvider;
