import React, { useEffect, useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, Grid, Box } from '@chakra-ui/react';
import { triggerToast } from '@send-components/Toast';
import { OpenConversions } from './OpenConversions';
import { Conversion, Beneficiary } from '@sendpayments/graphql/types';
import { BeneficiaryEditForm } from '../forms/BeneficiaryEditForm/BeneficiaryEditForm';

export interface BeneModalProps {
  isOpen: boolean;
  onClose: () => void;
  updateBeneAccountDetails: (value: Beneficiary) => Promise<void>;
  beneData: Beneficiary;
  triggerToast: typeof triggerToast;
  openConversions: Conversion[];
  allowBeneEdit: boolean;
}

export interface OpenConversionProps {
  id: string;
  createdAt: string;
  sellCurrency: string;
  clientSellAmount?: number;
  buyCurrency: string;
  clientBuyAmount?: number;
}

export const BeneModal = ({
  isOpen,
  onClose,
  updateBeneAccountDetails,
  beneData,
  triggerToast,
  openConversions,
  allowBeneEdit,
}: BeneModalProps) => {
  const [accepted, setAccepted] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    if (openConversions.length === 0 && allowBeneEdit) {
      setAccepted(true);
    } else {
      setAccepted(false);
    }
  }, [openConversions, allowBeneEdit]);

  const onSetAccepted = () => {
    setAccepted(true);
  };

  const onSubmit = async (value) => {
    setSubmitLoading(true);
    try {
      await updateBeneAccountDetails(value);
      triggerToast({
        type: 'success',
        message: 'Successfully updated recipient.',
      });
    } catch (err) {
      triggerToast({
        type: 'error',
        message: 'Something went wrong while updating recipient.',
      });
    }
    onClose();
    setSubmitLoading(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="1000px">
        <Grid>
          <Box w="auto" display="none">
            <ModalCloseButton background="border.light" borderRadius="100%" />
          </Box>

          <ModalBody w="auto" textAlign="center" mt={8}>
            {!accepted ? (
              <OpenConversions
                type="edit"
                onSetAccepted={onSetAccepted}
                onClose={onClose}
                openConversions={openConversions}
                allowBeneEdit={allowBeneEdit}
              />
            ) : (
              <BeneficiaryEditForm beneData={beneData} onSubmit={onSubmit} onClose={onClose} submitLoading={submitLoading} />
            )}
          </ModalBody>

          <ModalFooter
            w="auto"
            justifyContent="center"
            pt={0}
            mt={{ base: 2, sm: 4 }}
            mb={4}
            flexDir={{ base: 'column', sm: 'row' }}
          />
        </Grid>
      </ModalContent>
    </Modal>
  );
};
