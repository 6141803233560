import React, { useState } from 'react';
import { message } from 'antd';
import PropTypes from 'prop-types';
import { ListView } from '@send-legacy-components/Common/ListView';
import { post } from '@send-base/utilities/endpoint';
import { logger } from '@sendpayments/js-utils/dist/services/logger';
import { projectName } from '@send-legacy-themes/provider';
import { getBackgroundByKey } from '@send-services/resources';

const dataSourcePoli = [
  {
    id: 'poli',
    headImage: getBackgroundByKey({ key: 'poli_image', default: '/static/images/poli.png' }),
    clickable: true,
    expandable: true,
  },
];

const PoliPay = ({ convId }) => {
  const [loading, setLoading] = useState(false);

  const onPoliClick = async () => {
    try {
      setLoading(true);
      const response = await post('/poli/initiate', {
        data: { convId, portalProject: projectName },
      });
      setLoading(false);
      window.location.href = response.NavigateURL;
    } catch (error) {
      setLoading(false);
      logger.error('onPoliClick', 'error', error);
      message.error('Unable to enable Poli pay. Please try other payment method.');
    }
  };
  return (
    <>
      <ListView loading={loading} onItemClick={onPoliClick} dataSource={dataSourcePoli} />
    </>
  );
};

PoliPay.propTypes = {
  convId: PropTypes.string,
};

PoliPay.defaultProps = {
  convId: undefined,
};

export default PoliPay;
