import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React, { memo } from 'react';
import { Row, Col } from 'antd';

dayjs.extend(localizedFormat);

const PaymentDetails = memo(({ payment }) => {
  return (
    <Row className="details">
      {payment.amount && (
        <Col span={24} md={12} className="item">
          <label>Amount</label>
          <p>{`${payment.amount.toLocaleString()} ${payment.currency}`}</p>
        </Col>
      )}
      {payment.shortReference && (
        <Col span={24} md={12} className="item">
          <label>Reference</label>
          <p>{payment.shortReference}</p>
        </Col>
      )}
      {payment.bankName && (
        <Col span={24} md={12} className="item">
          <label>Bank Name</label>
          <p>{payment.bankName}</p>
        </Col>
      )}
      {payment.bankCode && (
        <Col span={24} md={12} className="item">
          <label>Bank Code</label>
          <p>{payment.bankCode}</p>
        </Col>
      )}
      {payment.recipientName && (
        <Col span={24} md={12} className="item">
          <label>Recipient Name</label>
          <p>{payment.recipientName}</p>
        </Col>
      )}
      {payment.bicSwiftCode && (
        <Col span={24} md={12} className="item">
          <label>SWIFT / BIC Code</label>
          <p>{payment.bicSwiftCode}</p>
        </Col>
      )}
      {payment.iban && (
        <Col span={24} md={12} className="item">
          <label>IBAN</label>
          <p>{payment.iban}</p>
        </Col>
      )}
      {payment.accountNumber && (
        <Col span={24} md={12} className="item">
          <label>Account Number</label>
          <p>{payment.accountNumber}</p>
        </Col>
      )}
      {payment.bsbCode && (
        <Col span={24} md={12} className="item">
          <label>BSB Code</label>
          <p>{payment.bsbCode}</p>
        </Col>
      )}
      {payment.sortCodeNumber && (
        <Col span={24} md={12} className="item">
          <label>BSB / Sort Code</label>
          <p>{payment.sortCodeNumber}</p>
        </Col>
      )}
      {payment.reference && (
        <Col span={24} md={12} className="item">
          <label>Payment Reference</label>
          <p>{payment.reference}</p>
        </Col>
      )}
      {payment.paymentDate && (
        <Col span={24} md={12} className="item">
          <label>Payment Date</label>
          <p>{dayjs(payment.paymentDate).format('LL')}</p>
        </Col>
      )}
      {payment.transferredAt && (
        <Col span={24} md={12} className="item">
          <label>Transferred At</label>
          <p>{dayjs(payment.transferredAt).format('LL')}</p>
        </Col>
      )}
    </Row>
  );
});

PaymentDetails.propTypes = {
  payment: PropTypes.object,
};

PaymentDetails.defaultProps = {
  payment: {},
};

export default PaymentDetails;
