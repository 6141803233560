import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Layout, Row, Button, Input, Form, Col, Alert } from 'antd';
import { RequireNewPassword } from 'aws-amplify-react';
import Auth from '@aws-amplify/auth';
import { ThemedImage } from '@send-legacy-components/Common';
import { getLabelByKey } from '@send-services/resources';
import SideImage from './SideImage';
import { SFooter } from '../UI';
import { analytics } from '@sendpayments/js-utils/dist';

const { Content } = Layout;

// TODO create a new resource section to configuration using label for now
const showSideImage = getLabelByKey({ key: 'show_side_image', default: 'true' }) === 'true';

class RequireNewPasswordComponent extends RequireNewPassword {
  constructor(props) {
    super(props);
    this.state = {
      btnLabel: 'Submit',
    };
  }

  change() {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return;
      }
      this.setState({
        loading: true,
      });
      const username = this.props.authData;
      const { password } = values;
      Auth.completeNewPassword(username, password)
        .then((user) => {
          this.setState({
            alert: {
              message: 'Thank you, your password has been changed. You will now be automatically logged in.',
              type: 'success',
            },
            btnLabel: 'Logging in',
            loading: true,
          });
          analytics.push({ action: { type: 'form_submit', data: { name: 'RequirePasswordForm' } } });
          this.changeState('signedIn', user);
        })
        .catch((error) => {
          this.error(error);
          this.setState({
            loading: false,
            alert: {
              message: 'Error. Please try again later.',
              type: 'error',
            },
          });
        });
    });
  }

  showComponent() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        {showSideImage && <SideImage />}
        <Layout className="sign-in">
          <Link to="/login" className="logo">
            <ThemedImage alt="logo" file="logo.svg" />
          </Link>
          <Content>
            <Row>
              <Col
                sm={{ span: 24, offset: 0 }}
                md={{ span: showSideImage ? 12 : 24, offset: showSideImage ? 1 : 0 }}
                lg={{ span: showSideImage ? 8 : 24, offset: showSideImage ? 3 : 0 }}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Row type="flex" align="middle" style={{ flexDirection: 'column', maxWidth: 528, width: '100%', padding: 28 }}>
                  <h1 className="bold uppercase text-center">Change password</h1>
                  {this.state && this.state.alert && (
                    <Alert
                      style={{ width: '100%', marginBottom: 12 }}
                      description={this.state.alert.message}
                      showIcon
                      type={this.state.alert.type}
                    />
                  )}
                  <Row type="flex" style={{ width: '100%', justifyContent: 'flex-end', marginBottom: 6 }}>
                    <Col>
                      <a
                        className="link-password"
                        onClick={() => {
                          this.changeState('signIn');
                          this.setState({ username: '', alert: false });
                        }}
                      >
                        Back to Login
                      </a>
                    </Col>
                  </Row>
                  <Form onSubmit={this.handleSubmit} className="login-form">
                    <Form.Item>
                      {getFieldDecorator('password', {
                        rules: [
                          { required: true, message: 'Password is required' },
                          { min: 8, message: 'Password must have length greater than or equal to 8' },
                        ],
                      })(
                        <Input
                          prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                          placeholder="Enter your new password"
                          type="password"
                        />,
                      )}
                    </Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      loading={this.state && this.state.loading}
                      onClick={this.change}
                    >
                      {this.state.btnLabel}
                    </Button>
                  </Form>
                </Row>
              </Col>
            </Row>
          </Content>
        </Layout>
        <Row className="footer-wrap">
          <Col
            sm={{ span: 24, offset: 0 }}
            md={{ span: showSideImage ? 12 : 24, offset: showSideImage ? 1 : 0 }}
            lg={{ span: showSideImage ? 8 : 24, offset: showSideImage ? 3 : 0 }}
          >
            <SFooter />
          </Col>
        </Row>
      </div>
    );
  }
}

RequireNewPasswordComponent.propTypes = {
  form: PropTypes.object.isRequired,
};

const RequireNewPasswordForm = Form.create({ name: 'beneficiary_in_modal' })(RequireNewPasswordComponent);
export default RequireNewPasswordForm;
