import Amplify from 'aws-amplify';

import { appConfig as defaultAppConfig } from '@send-base/app.config';
import awsconfig from '@send-base/aws-exports';
import credentials from '@send-base/services.config';

import awsenvconfig from '@sendpayments/graphql/aws-env-config';
import { initializeAnalytics } from '@sendpayments/js-utils/dist/services/analytics/legacy';
import { initializeUserInteractionTracker } from '@sendpayments/js-utils/dist/services/user-interaction-tracker';
import { activateLogging, setForwardErrorsToServer, logger } from '@sendpayments/js-utils/dist/services/logger';
import { initializeAdsManager, initializeAdsManagerDisabled } from '@sendpayments/js-utils/dist/services/ads-manager';
import { initialize as initializeEndpointService } from '@send-base/utilities/endpoint';

import { getPortalSource } from '../white-label';
import { env, credentialKey } from '../environment';

export const initializeDependencies = () => {
  try {
    const isProd = env && env.includes('prod');

    activateLogging(!isProd);
    setForwardErrorsToServer(isProd);

    const portalSource = getPortalSource();

    logger.log('initializeDependencies', 'portal source', portalSource);
    logger.log('initializeDependencies', 'env', credentialKey);
    logger.log('initializeDependencies', 'isProd', isProd);

    initializeAnalytics({ credentials, credentialKey, project: portalSource });

    if (isProd) {
      logger.log('initializeDependencies', 'initializing prod services');
      initializeUserInteractionTracker({
        credentials:
          (credentials.hotjar && credentials.hotjar[credentialKey] && credentials.hotjar[credentialKey][portalSource]) || {},
      });
      initializeAdsManager({ credentials: credentials.taboola });
    } else {
      logger.log('initializeDependencies', 'initializing not prod services');
      initializeAdsManagerDisabled();
    }

    if (credentialKey === 'staging') {
      initializeUserInteractionTracker({
        credentials:
          (credentials.hotjar && credentials.hotjar[credentialKey] && credentials.hotjar[credentialKey][portalSource]) || {},
        excludeErrorsIncluding: ['There is no connection right now', 'amqjs0004e', 'failed to connect', 'UnauthorizedException'],
      });
    }

    logger.log('initializeDependencies', 'newFeatureGroups', defaultAppConfig.newFeatureGroups);

    initializeEndpointService({ env: env, useLogging: !isProd });

    const awsEnvConfig = awsenvconfig[env];

    awsEnvConfig.oauth.redirectSignIn = `${window.location.origin}/`;
    awsEnvConfig.oauth.redirectSignOut = `${window.location.origin}/`;

    const base_url =
      window && window.location
        ? window.location.hostname == ('localhost' || '127.0.0.1')
          ? window.location.hostname
          : window.location.hostname.split('.').slice(-2).join('.')
        : // Default to sendpayments.com if any unexpected failure occurs
          'sendpayments.com';

    awsEnvConfig.cookieStorage = {
      domain: base_url,
    };

    const awsResultConfig = { ...awsEnvConfig, ...awsconfig, aws_cloud_logic_custom: awsEnvConfig.aws_cloud_logic_custom };

    logger.log('initializeDependencies', 'aws exports', awsconfig);
    logger.log('initializeDependencies', 'awsEnvConfig', awsEnvConfig);
    logger.log('initializeDependencies', 'awsResultConfig', awsResultConfig);

    Amplify.configure(awsResultConfig);

    if (!isProd) {
      window.onunhandledrejection = (event) => {
        logger.error(`initializeDependencies`, `onunhandledrejection UNHANDLED PROMISE REJECTION: ${event.reason}`, event);
      };
    }
  } catch (e) {
    logger.error('initializeDependencies', 'error starting the app portal may not be initialized correctly', e);
  }
};
