import * as React from 'react';

/**
 * @typedef {{ value: string; error: string; }} StateItem
 * @typedef {Record<string, StateItem>} State
 */

/**
 * A custom hook which helps create forms.
 * @param {Array<string>} fields
 * @return {[Record<string, StateItem>, React.Dispatch<{field: string;} & Partial<StateItem>>, { clearForm: () => void; }]}
 */
function useForm(fields) {
  /**
   * @param {State} state
   * @param {{ field: string; } & StateItem} action
   * @returns {State}
   */
  function formReducer(state, action) {
    return {
      ...state,
      [action.field]: {
        value: action.value ?? state?.[action.field]?.value,
        error: action.error ?? state?.[action.field]?.error,
      },
    };
  }

  const reducer = React.useReducer(
    formReducer,
    // Initializes all fields
    fields.reduce((acc, key) => {
      acc[key] = {
        error: '',
        value: '',
      };
      return acc;
    }, {}),
  );

  const [state, dispatch] = reducer;

  return [
    state,
    dispatch,
    {
      clearForm: () => {
        fields.forEach((field) => {
          dispatch({ field, error: '', value: '' });
        });
      },
    },
  ];
}

export default useForm;
