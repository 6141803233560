import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Layout } from 'antd';
import { SSubAreaTitle } from '@send-legacy-components/UI';

const { Content } = Layout;

const DefaultLayout = ({ size, title, icon, subtitle, index, children }) => (
  <Row>
    <Col md={24} lg={{ span: 16, offset: 4 }}>
      <Content className="step-content">
        <Form layout="horizontal">
          <Row>
            <div className="small-label">
              Step {index}/{size}
            </div>
          </Row>
          <Row className="flex">
            <h1 className="bold">{title}</h1>
            <SSubAreaTitle icon={icon} text={subtitle} />
          </Row>
          <Row>
            <Col>{children}</Col>
          </Row>
        </Form>
        <br />
      </Content>
    </Col>
  </Row>
);

DefaultLayout.propTypes = {
  size: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default DefaultLayout;
