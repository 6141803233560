import React from 'react';
import { Flex, Button, Box, Heading, Text, useDisclosure } from '@chakra-ui/react';
import { ProgressBar, Progress, SimpleModal } from '@send-base/atomic-components/organisms';
import { CloseIcon, ChevronIcon, ExitIcon } from '@sendpayments/react-shared/components/Icons';

interface StepTemplateProps {
  progress?: Progress;
  modal?: {
    title: string;
    subtitle: string;
    confirm: string;
    cancel: string;
    onClose?: () => void;
  };
  onNavigateBack?: () => void;
  onCancel?: () => void;
  children?: React.ReactNode;
}

const StepTemplate = ({
  progress = { current: 0, steps: [{ heading: 'Example Heading', step: 'Example' }] },
  children,
  modal = {
    title: 'Are you sure you want to leave this process?',
    subtitle: 'Changes you made may not be saved.',
    confirm: 'Yes, leave',
    cancel: 'Cancel',
    onClose: () => null,
  },
  onNavigateBack = () => null,
  onCancel = () => null,
}: StepTemplateProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const closeModal = () => {
    onClose();
    modal.onClose && modal.onClose();
  };

  return (
    <>
      <Flex alignItems="center" p={4}>
        <Button
          w={100}
          variant={'outline'}
          zIndex={3}
          leftIcon={<ChevronIcon direction="back" />}
          onClick={progress.current === 0 ? onOpen : onNavigateBack}
        >
          Back
        </Button>
        <ProgressBar progress={progress} w="50%" />
        <Flex ml="auto" w={100}>
          <CloseIcon
            onClick={onOpen}
            color="text.base"
            minW={10}
            ml="auto"
            w={10}
            h={10}
            p={3}
            zIndex={3}
            border="medium"
            bgColor="white"
            borderColor="border.base"
            _hover={{
              cursor: 'pointer',
              bgColor: 'white',
              borderColor: 'primary.base',
              color: 'primary.base',
            }}
            _active={{
              bgColor: 'white',
              borderColor: 'primary.dark',
              color: 'primary.dark',
            }}
            borderRadius="100%"
          />
        </Flex>
        <SimpleModal
          open={isOpen}
          primaryButtonText={modal.confirm}
          secondaryButtonText={modal.cancel}
          onClose={closeModal}
          onSecondaryAction={closeModal}
          onPrimaryAction={onCancel}
          key={`${progress.steps[progress.current].heading}-modal`}
        >
          <ExitIcon color="text.base" boxSize={{ base: '57px', lg: '83px' }} />
          <Heading as="h3" variant="heading3" mt={4}>
            {modal.title}
          </Heading>
          <Text>{modal.subtitle}</Text>
        </SimpleModal>
      </Flex>
      <Flex borderBottom="solid 1px" display={{ base: 'none', sm: 'block' }} borderColor="border.base" w="100%" />
      <Flex p={{ base: 4, sm: 4, lg: 8 }} align="center" justify="center" display="flex" flexDirection="column">
        <Box w="100%" display="flex" align="center" justify="center" flexDirection="column" mt={{ base: 6, md: 8 }}>
          <Heading
            as="h1"
            variant="heading1"
            justifyContent={{ base: 'center', md: 'left' }}
            mb={{ base: 4, md: progress.steps[progress.current].subHeading ? 4 : 8 }}
            textAlign={{ base: 'left', sm: 'center' }}
          >
            {progress.steps[progress.current].heading}
          </Heading>
          {progress.steps[progress.current].subHeading && (
            <Heading
              style={{ letterSpacing: '.01em' }}
              lineHeight={'1.5'}
              color={'text.base'}
              fontSize="lg"
              fontWeight="400"
              justifyContent={{ base: 'center', md: 'left' }}
              mb={8}
              textAlign={{ base: 'left', sm: 'center' }}
              maxWidth={{ base: '100%', md: '416px' }}
              ml={'auto'}
              mr={'auto'}
            >
              {progress.steps[progress.current].subHeading}
            </Heading>
          )}
          {children}
        </Box>
      </Flex>
    </>
  );
};

export { StepTemplate, StepTemplateProps };
