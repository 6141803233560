import React from 'react';
import PropTypes from 'prop-types';
import { useSettlementAccounts } from '@send-data-hooks';
import { FundsInDetailsView } from '@send-legacy-components/DealerAssist';

const FundsInDetails = ({
  currency,
  amount,
  sellAmount,
  showTitle,
  currencyCloudReference,
  sendId,
  convId,
  userId,
  userType,
  complianceScore,
}) => {
  const [{ data: settlementAccounts, loading }] = useSettlementAccounts({
    currencyCloudReference,
    sendId,
    sellAmount,
    currency,
    userId,
    userType,
  });
  return (
    <FundsInDetailsView
      showTitle={showTitle}
      currency={currency}
      amount={amount}
      settlementAccounts={settlementAccounts}
      loading={loading}
      sendId={sendId}
      convId={convId}
      complianceScore={complianceScore}
    />
  );
};

FundsInDetails.propTypes = {
  currency: PropTypes.string,
  amount: PropTypes.number,
  sellAmount: PropTypes.number,
  showTitle: PropTypes.bool,
  currencyCloudReference: PropTypes.string,
  sendId: PropTypes.string,
  convId: PropTypes.string,
  currencyCloudId: PropTypes.string,
  userId: PropTypes.string,
  userType: PropTypes.string,
  complianceScore: PropTypes.number,
};

FundsInDetails.defaultProps = {
  currency: '',
  amount: 0,
  sellAmount: 0,
  showTitle: true,
  currencyCloudReference: undefined,
  sendId: undefined,
  convId: undefined,
  currencyCloudId: undefined,
  userId: undefined,
  userType: undefined,
  complianceScore: undefined,
};

export default FundsInDetails;
