import { Auth } from 'aws-amplify';
import { useRemoteDataEmptyHandler } from '@sendpayments/react-shared/hooks/useRemoteData';
import { useAppContext } from '../useAppContext';

const useLogout = () => {
  const [{ setUser, setRegistration, setShowBubbles, setLoading }] = useAppContext();
  const [, emptyRemoteData] = useRemoteDataEmptyHandler();

  const signOut = () => Auth.signOut();

  const logoutHandler = async () => {
    // Clear session storage
    window.sessionStorage.clear();
    setLoading(true);
    await Auth.signOut();
    setUser(null);
    setRegistration(null);
    setShowBubbles(false);
    setLoading(false);

    // queue into the Javascript task queue the action to clear the remote data so this happens after the UI.
    // is refreshed by the logout. Use anything lower than 200ms so it is not visible to the user.
    return new Promise((resolve) => {
      setTimeout(() => {
        emptyRemoteData();
        resolve();
      }, 180);
    });
  };

  return [{}, logoutHandler, signOut];
};

export default useLogout;
