import React from 'react';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';
import { Icon as IconAntd } from 'antd';
import classNames from 'classnames';
import { getConfigByKey, getIconTypePropsByKey, getLabelByKey } from '@send-services/resources';
import { CurrencyFlag } from '@sendpayments/react-shared/components/CurrencyFlag';

import styles from './Icon.module.less';

const path = '../../static/icons';

const rateRiseName = getLabelByKey({ key: 'rate_rise_name', default: 'rate-rise' });
const rateFallName = getLabelByKey({ key: 'rate_fall_name', default: 'rate-fall' });

export const sendIconTypes = {
  PROGRESS_AMOUNT: 'progress-amount',
  PROGRESS_PAYMENT: 'progress-payment',
  PROGRESS_RECIPIENT: 'progress-recipient',
  PROGRESS_TRANSFER: 'progress-transfer',
  SWAP: 'send-swap',
  SWAP2: 'send-swap2',
  ARROW: 'send-arrow',
  SELECT_RECIPIENT: 'send-shape',
  DOT: 'send-dot',
  RISE: rateRiseName,
  FALL: rateFallName,
};

export const outlines = {
  CIRCLE: 'circle',
};

const outlinesRef = {
  [outlines.CIRCLE]: styles.circle,
};

const iconTypesInverted = Object.entries(sendIconTypes).reduce((acc, [prop, value]) => {
  acc[value] = prop;
  return acc;
}, {});

const Icon = ({ type, outline, ...props }) => {
  const themeIconConfig = getConfigByKey({ key: 'iconsConfig' });
  const currentThemeIconProps = getIconTypePropsByKey({ key: type });

  if (type.includes('flag-')) {
    return (
      <CurrencyFlag
        className={classNames(outlinesRef[outline], outline, 'flag-icon')}
        currencyName={type.replace('flag-', '')}
        {...themeIconConfig}
        {...currentThemeIconProps}
      />
    );
  }

  if (type && iconTypesInverted[type]) {
    return (
      <ReactSVG
        wrapper="span"
        className={classNames(styles.base, 'send-icon', type, styles[type], outlinesRef[outline], outline)}
        src={`${path}/${type.replace('send-', '')}.svg`}
        {...themeIconConfig}
        {...currentThemeIconProps}
      />
    );
  }

  return (
    <IconAntd
      className={classNames(outlinesRef[outline], outline)}
      type={type}
      style={{ paddingLeft: type === 'credit-card' && '3.5%' }}
      {...props}
      {...themeIconConfig}
      {...currentThemeIconProps}
    />
  );
};

Icon.propTypes = {
  type: PropTypes.string,
  outline: PropTypes.string,
};

Icon.defaultProps = {
  type: undefined,
  outline: undefined,
};

export default Icon;
