import React from 'react';
import { useRegistration } from '@send-base/data-hooks';
import { triggerToast } from '@send-components/Toast';
import { logger, post } from '@sendpayments/js-utils/dist';
import { Auth } from 'aws-amplify';
import { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import Info from '@spectrum-icons/workflow/Info';
import Alert from '@spectrum-icons/workflow/Alert';
import { AxiosResponse } from 'axios';

interface CodeDeliveryDetails {
  AttributeName: string;
  DeliveryMedium: string;
  Destination: string;
}

interface SendSmsCodeSuccess {
  CodeDeliveryDetails: CodeDeliveryDetails;
}

interface useVerifyPhoneOptions {
  onVerifySuccess: () => void;
}

const getAccessToken = async () => {
  return (await Auth.currentSession()).getAccessToken().getJwtToken();
};

export const useVerifyPhone = ({ onVerifySuccess }: useVerifyPhoneOptions) => {
  const [{ data: account }] = useRegistration();
  const [hasBeenReSent, setHasBeenReSent] = useState(false);

  const sendSmsMutation = useMutation({
    mutationFn: async () => {
      const res = (await post('/profile/verifyPhone', {
        data: {
          accessToken: await getAccessToken(),
          regType: account.type,
        },
      })) as AxiosResponse<SendSmsCodeSuccess>;
      return res.data.CodeDeliveryDetails;
    },
    onSuccess: () => {
      verifyCodeMutation.reset();
    },
    onError: (error) => {
      logger.error('phoneSetting', 'verifyPhoneNumber', error);
      triggerToast({
        type: 'error',
        message: 'Error sending verification code. You may have sent too many recently. Please wait some minutes, and try again.',
        rightIcon: <Alert />,
      });
    },
  });

  const verifyCodeMutation = useMutation({
    mutationFn: async (code: string) => {
      // no response capturing necessary. If successfully verified, an empty 200 is returned
      await post('/profile/verifyPhoneSubmit', {
        data: {
          accessToken: await getAccessToken(),
          regType: account.type,
          code,
        },
      });
    },
    onSuccess: () => {
      onVerifySuccess();
      triggerToast({
        type: 'success',
        message: !account.phoneNumberVerified ? 'Your phone number has been verified' : 'Success!',
        rightIcon: <Info />,
      });
    },
    onError: (error) => {
      logger.error('phoneSetting', 'verifyPhoneNumberSubmit', error);
    },
  });

  const resendCode = () => {
    sendSmsMutation.mutate();
    setHasBeenReSent(true);
  };

  const resetMutations = () => {
    sendSmsMutation.reset();
    verifyCodeMutation.reset();
    setHasBeenReSent(false);
  };

  const codeValidity = useCodeValidity({ hasCodeBeenSent: sendSmsMutation.isSuccess });

  return {
    sendCode: { resend: resendCode, ...sendSmsMutation },
    verifyCode: verifyCodeMutation,
    code: { hasBeenReSent, deliveryDetails: sendSmsMutation.data, ...codeValidity },
    resetMutations,
  };
};

interface UseCodeValidityOptions {
  validityPeriodSeconds?: number;
  hasCodeBeenSent: boolean;
}

const useCodeValidity = ({ validityPeriodSeconds = 180, hasCodeBeenSent }: UseCodeValidityOptions) => {
  const [isValid, setIsValid] = useState(false);
  const [validSecondsRemaining, setValidSecondsRemaining] = useState(validityPeriodSeconds);

  const resetSeconds = () => setValidSecondsRemaining(validityPeriodSeconds);

  useEffect(() => {
    setIsValid(hasCodeBeenSent);
  }, [hasCodeBeenSent]);

  useEffect(() => {
    if (isValid) {
      const timer = setInterval(() => {
        setValidSecondsRemaining((val) => {
          const newVal = val - 1;
          if (newVal === 0) {
            clearInterval(timer);
            setIsValid(false);
          }
          return newVal;
        });
      }, 1000);

      return () => {
        clearInterval(timer);
        resetSeconds();
      };
    }

    return () => {};
  }, [isValid]);

  return { validSecondsRemaining, isValid };
};
