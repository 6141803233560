import React from 'react';
import { SendPaymentsTheme } from '@sendpayments/react-shared';
import { ToastDialog } from '@send-components/Toast';
import { projectName } from '@send-services/environment';

/**
 * Wrapper including our SendPaymentsTheme and ToastDialog.
 * @warn MUST be used within a <Router /> context as the ToastDialog uses links/navigation
 */
const SendPaymentsThemeWrapper = ({ children }) => {
  return (
    <>
      {/* ToastDialog must be rendered in the dom above or parallel to SendPaymentsTheme
           in order to ensure toasts are not obscured by other elements (e.g as modal overlays) */}
      <ToastDialog />
      <SendPaymentsTheme name={projectName} theme="LIGHT">
        {children}
      </SendPaymentsTheme>
    </>
  );
};

export default SendPaymentsThemeWrapper;
