import { appConfig } from '@send-base/app.config';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/**
 * A function that converts values or default values to options for the
 * transfer form
 */
export function useTransferOptions(
  {
    transferPurposes,
    transferAmounts,
    transferFrequencies,
  }: Record<'transferPurposes' | 'transferAmounts' | 'transferFrequencies', Array<string> | undefined>,
  {
    defaultTransferPurposes,
    defaultTransferAmounts,
    defaultTransferFrequency,
    defaultCurrencies,
  }: Record<'defaultTransferPurposes' | 'defaultTransferAmounts' | 'defaultTransferFrequency', Array<string>> & {
    defaultCurrencies: string[][];
  },
) {
  const transferPurposeOptions = (transferPurposes || defaultTransferPurposes)?.map((value) => ({
    value: value,
    label: value,
  }));

  const transferAmountOptions = (transferAmounts || defaultTransferAmounts)?.map((value) => ({
    value: value,
    label: value,
  }));

  const currencyOptions = defaultCurrencies
    ?.filter((value) => !appConfig.disabledCurrencies.includes(value[1]))
    .map((value) => ({
      value: value[1],
      label: value[0],
      currencyCode: value[1],
    }));

  const transferFrequencyOptions = (transferFrequencies || defaultTransferFrequency)?.map((value) => ({
    value: value,
    label: value,
  }));

  return { transferPurposeOptions, transferAmountOptions, currencyOptions, transferFrequencyOptions };
}
