import { isOutOfHours } from '@send-base/infrastructure/utils/isOutOfHours';
import { useQuery } from 'react-query';
import { appConfig } from '@send-base/app.config';
export const useOutOfHours = () => {
  const { data: australiaPublicHolidays = [] } = useQuery(
    'publicHolidays',
    () => fetch(appConfig.australiaPublicHolidaysURL).then((res) => res.json()),
    { cacheTime: 24 * 60 * 60 * 1000 /* one day */ },
  );
  return isOutOfHours(australiaPublicHolidays);
};
