import { query } from '@send-base/utilities/endpoint';
import * as queries from '@sendpayments/graphql/queries';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
import { buildUniqueKey } from '@sendpayments/js-utils/dist/services/build-unique-key';
import { useRemoteData } from '@sendpayments/react-shared/hooks/useRemoteData';

const fetchRecipients = async ({ owner }) => {
  const response = await query(queries.listCoconutsByOwner, {
    params: { owner, type: { eq: CoconutTypes.BENEFICIARY } },
  });

  return response.items;
};

const useRecipientsByOwner = ({ owner }) =>
  useRemoteData(
    () => ({
      key: buildUniqueKey('queries.listCoconutsByOwner', { type: CoconutTypes.BENEFICIARY }),
      onInitialFetch: () => fetchRecipients({ owner }),
      initialState: [],
    }),
    [owner],
  );

export default useRecipientsByOwner;
