import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmTradeView } from '@send-legacy-components/DealerAssist';

const ConfirmTrade = ({ showBookButton, onBookClick, initialToCurrency, initialFromCurrency, initialFromAmount }) => (
  <ConfirmTradeView
    showBookButton={showBookButton}
    onBookClick={onBookClick}
    initialFromCurrency={initialToCurrency}
    initialToCurrency={initialFromCurrency}
    initialFromAmount={initialFromAmount}
  />
);

ConfirmTrade.propTypes = {
  showBookButton: PropTypes.bool,
  onBookClick: PropTypes.func.isRequired,
  initialToCurrency: PropTypes.string,
  initialFromCurrency: PropTypes.string,
  initialFromAmount: PropTypes.number,
};

ConfirmTrade.defaultProps = {
  showBookButton: false,
  initialToCurrency: undefined,
  initialFromCurrency: undefined,
  initialFromAmount: undefined,
};

export default ConfirmTrade;
