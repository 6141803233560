import React, { useState, useEffect, useRef } from 'react';
import { Flex, View, Tooltip, TooltipTrigger, ActionButton } from '@adobe/react-spectrum';
import Cancel from '@spectrum-icons/workflow/Cancel';
import Alert from '@spectrum-icons/workflow/Alert';

import history from '@send-legacy-app/history';
import logger from '@sendpayments/js-utils/dist/services/logger';
import { breakpoints } from '@sendpayments/react-shared/design-system';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { PrimaryActionButton } from '@sendpayments/react-shared/components/buttons/PrimaryActionButton';
import { triggerToast } from '@send-components/Toast';
import { StatusBadge } from '@send-components/StatusBadge';
import { InfoPanel, Item } from '@send-components/InfoPanel';
import { CollapseSection } from '@send-components/CollapseSection';
import { VerifyCodeModal } from '@send-components/VerifyCodeModal';
import { InfoOutline, SMS } from '@send-components/Icons';
import { useOnClickOutside } from '@send-data-hooks';
import styles from './MFA.module.less';
import MFAHelper from './MFAHelper';
import { maskPhoneNumber } from '../../utils';

const MFAStatusBadge = ({ mfaIsEnabled }) => {
  if (mfaIsEnabled) {
    return <StatusBadge type="success" text="On" />;
  }
  return <StatusBadge type="danger" text="Off" />;
};

const SubTitle = ({ phoneNumber, isMobile }) => {
  const ref = useRef();
  const [toolTipOpen, setToolTipOpen] = useState(false);

  const onClickHandler = () => {
    setToolTipOpen(!toolTipOpen);
  };

  useOnClickOutside(ref, () => setToolTipOpen(false));

  if (phoneNumber) {
    return (
      <View width="85%">
        Add an extra layer of security with a unique verification code that’s sent to{'  '}
        <div style={{ display: isMobile ? 'block' : 'inline' }}>
          <span className={styles.bold}> {maskPhoneNumber(phoneNumber)} </span>
          {isMobile ? (
            <TooltipTrigger delay={0} isOpen={toolTipOpen}>
              <span ref={ref}>
                <ActionButton onPress={onClickHandler} UNSAFE_className={styles.overrideActionButton}>
                  <div className={styles.overRideStyle}>
                    <InfoOutline />
                  </div>
                </ActionButton>
              </span>
              <Tooltip variant="neutral">To update this phone number, visit your profile details to modify.</Tooltip>
            </TooltipTrigger>
          ) : (
            <TooltipTrigger delay={0}>
              <ActionButton UNSAFE_className={styles.overrideActionButton}>
                <div className={styles.overRideStyle}>
                  <InfoOutline />
                </div>
              </ActionButton>

              <Tooltip variant="neutral">To update this phone number, visit your profile details to modify.</Tooltip>
            </TooltipTrigger>
          )}
        </div>
      </View>
    );
  }
  return <View>Add an extra layer of security with a unique verification code</View>;
};

const PaperText = ({ phoneNumber, optOut }) => {
  const onChangePageHandler = () => {
    history.push({
      pathname: '/setting/profile',
      state: {
        openPhoneToast: true,
      },
    });
  };

  let parperTextResult;

  if (optOut) {
    parperTextResult = (
      <Item icon={Cancel}>
        Turning off 2-step verification will make{' '}
        <span className={styles.bold}>your account less secure without a security code.</span>
      </Item>
    );
  }

  if (optOut === false) {
    if (!phoneNumber) {
      parperTextResult = (
        <Item icon={SMS}>
          You currently don’t have a phone number attached to this account.{' '}
          <span className={styles.bold}>
            Would you like to
            <span onClick={onChangePageHandler} className={styles.link}>
              {' '}
              add a phone number{' '}
            </span>{' '}
            to use SMS?
          </span>
        </Item>
      );
    } else {
      parperTextResult = (
        <Item icon={SMS}>
          Before your change is confirmed, we’ll ask you to <span className={styles.bold}> verify your phone number. </span>{' '}
        </Item>
      );
    }
  }

  return parperTextResult;
};

const MFA = ({ phoneNumber, account, user, activeSection, sectionName, changeSectionActive }) => {
  const { isMedia } = useBreakpoint(breakpoints);
  const isMobile = isMedia({ to: breakpoints.sm });
  const [verifyModalIsOpen, setVerifyModalIsOpen] = useState(false);
  const [mfaIsEnabled, setMfaIsEnabled] = useState(null);

  useEffect(() => {
    MFAHelper.userHasMfaEnabled(user).then((val) => {
      setMfaIsEnabled(val);
    });
  }, []);

  const getButtonText = () => {
    if (mfaIsEnabled) {
      return 'off';
    }
    return 'on';
  };

  const onToggleMFAHandler = async () => {
    setVerifyModalIsOpen(true);
    try {
      await MFAHelper.getVerifyCode(user, account);
    } catch (err) {
      logger.error('MFA', 'Error getting verification code. Potentially too many requests, please wait a while', err);
      triggerToast({
        type: 'error',
        rightIcon: <Alert size="M" />,
        message: 'Error sending verification code. You may have sent too many recently. Please wait some minutes, and try again.',
      });
      setVerifyModalIsOpen(false);
    }
  };

  return (
    <>
      <CollapseSection
        buttonText="Manage"
        activeSection={activeSection}
        sectionName={sectionName}
        changeSectionActive={changeSectionActive}
      >
        <Flex direction={isMobile ? 'column' : 'row'} gap={isMobile ? 'size-40' : 'size-100'} alignItems="baseline">
          <span className={styles.itemTitle}>2-step verification</span>
          <MFAStatusBadge mfaIsEnabled={mfaIsEnabled} />
        </Flex>
        <SubTitle phoneNumber={phoneNumber} isMobile={isMobile} />
        <View marginTop="size-200">
          <InfoPanel>
            <PaperText phoneNumber={phoneNumber} optOut={mfaIsEnabled} />
          </InfoPanel>
          <View marginTop={28} />
          <PrimaryActionButton
            height="inherit"
            onPress={() => onToggleMFAHandler()}
            isDisabled={!phoneNumber}
            UNSAFE_className={`${styles.inline} ${styles.primaryButton} ${!phoneNumber && styles.disabledButton}`}
          >
            Turn {getButtonText()} 2-step verification
          </PrimaryActionButton>
          <VerifyCodeModal
            isOpen={verifyModalIsOpen}
            title="We’ve just sent you an SMS"
            description={`To log in, please enter the 6-digit security <strong>code we sent to ${phoneNumber}.</strong>`}
            onCodeComplete={async (input) => {
              setVerifyModalIsOpen(false);
              try {
                await MFAHelper.onCompleteCodeChanged(input, user, account, mfaIsEnabled);
              } catch (error) {
                logger.error('MFA', 'fail to verify code', error);
                setVerifyModalIsOpen(false);
                return;
              }

              setVerifyModalIsOpen(false);
              setMfaIsEnabled(!mfaIsEnabled);

              triggerToast({
                type: 'success',
                message: `2-step verification is now turned ${!mfaIsEnabled ? 'on' : 'off'}.`,
              });
            }}
            onResendCode={async () => {
              try {
                await MFAHelper.getVerifyCode(user, account);
              } catch (error) {
                logger.error('MFA', 'Error resending code', error);
                triggerToast({ type: 'error', message: 'There was an error sending the code. Please contact support.' });
              }
            }}
            onDismiss={() => {
              setVerifyModalIsOpen(false);
            }}
          />
        </View>
      </CollapseSection>
    </>
  );
};

export default MFA;
