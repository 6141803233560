import useInterval from 'use-interval';
import { query } from '@send-base/utilities/endpoint';
import * as queries from '@sendpayments/graphql/queries';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
import { useRemoteData } from '@sendpayments/react-shared/hooks/useRemoteData';

const POLLING_INTERVAL = 30000; // 30 seconds in milliseconds

const fetchNotifications = async ({ email }) => {
  if (email) {
    const response = await query(queries.listCoconutsByOwner, {
      params: {
        type: { eq: CoconutTypes.CUSTOMER_NOTIFICATION },
        owner: email,
      },
    });
    //map documents to notification if available
    const mappedResponse = await Promise.all(
      response.items.map(async (notification) => {
        if (notification.parentType === CoconutTypes.DOCUMENT) {
          const document = await query(queries.getCoconut, {
            params: {
              id: notification.parentId,
              type: CoconutTypes.DOCUMENT,
            },
          });
          return { ...notification, showToClient: document.showToClient };
        }
        return notification;
      }),
    );
    const filteredResponse = mappedResponse.filter((notification) => {
      //check if document is hidden from client
      if (notification.parentType === CoconutTypes.DOCUMENT) {
        return notification.showToClient;
      }
      return true;
    });
    return filteredResponse.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
  }
  return [];
};

const useNotifications = ({ email }) => {
  const [notifications, { doFetch: doFetchNotifications }] = useRemoteData(
    () => ({ key: 'hook.useNotifications', onInitialFetch: () => fetchNotifications({ email }), initialState: [] }),
    [email],
  );

  // TODO we will implement a queue connected to the WebSocket soon. For now poll for the update.
  useInterval(() => {
    if (email && !notifications.loading && !notifications.failed) {
      doFetchNotifications();
    }
  }, POLLING_INTERVAL);

  return [notifications, () => doFetchNotifications()];
};

export default useNotifications;
