import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { ThemedImage } from '@send-legacy-components/Common';
import styles from './SupportInfo.module.less';
import SupportInfo from './SupportInfo';

const SupportCard = ({ avatar, icon, email, name, title, bio, hobby, showBubbles, onShowBubbles, loading }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hover, setHover] = useState(false);

  const filesPath = icon && icon.split('/');
  const fileIcon = filesPath[filesPath.length - 1];

  const showHover = (!isOpen && showBubbles && !loading) || hover;

  return (
    <>
      {isOpen && (
        <div
          className="overlay"
          onClick={() => {
            setIsOpen(false);
          }}
        />
      )}
      <SupportInfo
        className={`support-info bottom-right ${isOpen ? 'visible' : 'invisible'}`}
        avatar={avatar}
        email={email}
        name={name}
        title={title}
        bio={bio}
        hobby={hobby}
      />
      <a
        className="bottom-right"
        onClick={() => {
          setIsOpen(!isOpen);
          onShowBubbles();
        }}
        style={{ zIndex: 5 }}
      >
        {isOpen ? (
          <Button className="close-button" icon="close" />
        ) : (
          <>
            <div className={`avatar ${styles.button} ${showHover && styles['button--hover']}`} />
            <ThemedImage
              width={54}
              style={{ position: 'absolute', right: 0, bottom: 0, maxWidth: 'none' }}
              className="avatar"
              alt="support_image"
              path="images"
              file={fileIcon}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            />
          </>
        )}
        {!isOpen && showBubbles && !loading && (
          <div className="bubble-group">
            <CSSTransition in appear timeout={3000} classNames="speech-bubble-transition">
              {
                <div className="speech-bubble" style={{ width: name !== 'Support' ? 80 : 50 }}>
                  Hi
                  {name !== 'Support' ? `, I'm ${name}` : ''}
                </div>
              }
            </CSSTransition>
            <CSSTransition in appear timeout={{ enter: 5000, exit: 0 }} classNames="speech-bubble-transition">
              <div className="speech-bubble two-line">Do you need any assistance?</div>
            </CSSTransition>
          </div>
        )}
      </a>
    </>
  );
};

SupportCard.propTypes = {
  avatar: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  bio: PropTypes.string.isRequired,
  hobby: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  showBubbles: PropTypes.bool.isRequired,
  onShowBubbles: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SupportCard;
