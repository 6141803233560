import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as RandExp from 'randexp';
import { Col, Form, Input, Tooltip } from 'antd';
import InfoIcon from '@send-static/icons/infoIcon.svg';

// TODO this file was moved entierly from add-recipient. We do not have enough time for refactoring it right now
// since it has been done in a way that taking out one little part means we have to re-do the whole functionality.
// I will leave it as it is and we will have to combe back later.
// Came across this in 2022, it's still here

function modifyAccountNumberPattern(regex) {
  const onlyMinDigitPattern = /\\d\{(\d+),\}/;
  if (onlyMinDigitPattern.test(regex)) {
    const match = regex.match(onlyMinDigitPattern);
    const maxLength = match[1];
    return regex.replace(/{(\d+),}/, `{$1,${maxLength}}`);
  } else if (regex === '^[0-9A-Z]{1,17}$') {
    return '^[0-9A-Z]{5,17}$';
  }
  return regex;
}

function FormItemInput(props) {
  const [example, setExample] = useState('');

  useEffect(() => {
    let randomExpression;
    if (props.fieldName === 'accountNumber') {
      const modifiedPattern = modifyAccountNumberPattern(props.regex);
      randomExpression = new RandExp(modifiedPattern);
    } else {
      randomExpression = new RandExp(props.regex);
    }

    randomExpression.defaultRange.subtract(0, 65535);
    randomExpression.defaultRange.add(48, 57);
    randomExpression.defaultRange.add(65, 90);
    setExample(props.fieldName === 'iban' ? 'SE35 5000 0000 0549 1000 0003' : randomExpression.gen());
  }, [props.regex]);

  const required = props.required || props.requiredAccountFields.includes(props.fieldName);
  return (
    (!props.requiredAccountFields.length || props.requiredAccountFields.includes(props.fieldName)) && (
      <Col md={props.md || 12} span={24}>
        <Form.Item
          label={<LabelWithTooltip fieldName={props.fieldName} label={props.label} />}
          colon={false}
          required={false}
          disabled={props.disabled}
          hasFeedback
        >
          {props.getFieldDecorator(props.fieldName, {
            rules: [
              {
                pattern: props.regex,
                required,
                message: props.regex === '.*' ? `${props.label} is required` : `Invalid format, needs to be like: ${example}`,
              },
            ],
          })(<Input placeholder={props.regex === '.*' ? '' : `eg: ${example}`} />)}
        </Form.Item>
      </Col>
    )
  );
}

const LabelWithTooltip = ({ fieldName, label }) => {
  if (['iban', 'bicSwiftCode'].includes(fieldName)) {
    return (
      <span>
        {label}
        <Tooltip title={tooltipText[fieldName]}>
          <img src={InfoIcon} width={12} style={{ margin: '4px 2px 8px 2px' }} />
        </Tooltip>
      </span>
    );
  }
  return <span> {label} </span>;
};
const tooltipText = {
  bicSwiftCode: (
    <>
      <p>
        A SWIFT code, sometimes known as a Bank identifier Code or BIC, is the unique ID that a bank uses to transfer money
        overseas.
      </p>
      <p>The recipient&#39;s bank SWIFT/BIC code should be easily found on the bank&#39;s website or online banking.</p>
    </>
  ),
  iban: (
    <>
      <p>
        An IBAN (International Bank Account Number) is used in certain countries to uniquely identify a customer&rsquo;s bank
        account. It&rsquo;s made up of letters and numbers and is generally 15-30 characters long{' '}
      </p>
      <p>The recipient&rsquo;s IBAN number should be easily found on a bank statement or online banking.</p>
    </>
  ),
};
FormItemInput.propTypes = {
  getFieldDecorator: PropTypes.func.isRequired,
  requiredAccountFields: PropTypes.array,
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  md: PropTypes.number,
  regex: PropTypes.string,
};

FormItemInput.defaultProps = {
  required: false,
  disabled: false,
  requiredAccountFields: [],
  md: 12,
  regex: '.*',
};

export default FormItemInput;
