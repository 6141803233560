import React from 'react';

export const AppContext = React.createContext({
  loading: false,
  setLoading: null,
  user: null,
  setUser: null,
  permissions: {},
  groups: [],
  showBubbles: true,
  setShowBubbles: null,
  steps: {},
});
