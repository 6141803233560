import React from 'react';
import IconContainer from './IconContainer';

export const SMS = () => {
  return (
    <IconContainer>
      <svg viewBox="0 0 36 36" focusable="false" aria-hidden="true" role="img">
        <path
          fillRule="evenodd"
          d="M33,4H3A1,1,0,0,0,2,5V27a1,1,0,0,0,1,1H8l3.536,6.839a.5.5,0,0,0,.928,0L16,28H33a1,1,0,0,0,1-1V5A1,1,0,0,0,33,4ZM6.66,21.145a6.547,6.547,0,0,1-3.006-.613.658.658,0,0,1-.314-.611V17.855l.406-.129a6.437,6.437,0,0,0,2.967.848c.688,0,1.51-.158,1.51-.908,0-.336-.109-.717-1.41-1.359l-.725-.318C4.16,15.084,3.34,14,3.34,12.369c0-2.174,1.647-3.578,4.2-3.578a5.9,5.9,0,0,1,2.631.477.539.539,0,0,1,.314.559v1.955l-.4.145-.242-.016a4.541,4.541,0,0,0-2.3-.535c-.443,0-1.475.082-1.475.842,0,.334.109.684,1.42,1.287l.613.271c2.072.951,2.953,2.062,2.953,3.719C11.049,19.713,9.326,21.145,6.66,21.145Zm17.3-.383-.049.057-.162.135-.228.035H21.381l-.189-.439c-.053-2.174-.084-4.713-.1-6.67-.377,1.342-.826,2.9-1.227,4.277l-.738,2.568-.422.25L17,20.988a.531.531,0,0,1-.553-.394c-.473-1.768-1.184-4.51-1.74-6.75-.061,1.978-.156,4.463-.248,6.643l-.006.133-.131.238-.314.119-2.035.012-.189-.461.639-11.41.457-.146,2.676-.008a.547.547,0,0,1,.543.367c.272.945,1.275,4.518,1.856,6.859.353-1.24.848-2.871,1.273-4.277.316-1.043.6-1.973.762-2.539l.027-.06.121-.176.275-.15,2.941-.024.207.369.48,11.225Zm4.314.383a6.546,6.546,0,0,1-3.006-.613.648.648,0,0,1-.314-.611V17.855l.406-.129a6.437,6.437,0,0,0,2.967.848c.688,0,1.51-.158,1.51-.908,0-.336-.109-.717-1.412-1.359l-.723-.318c-1.928-.9-2.748-1.986-2.748-3.619,0-2.174,1.646-3.578,4.2-3.578a5.914,5.914,0,0,1,2.631.477.539.539,0,0,1,.315.559v1.955l-.4.145-.242-.016a4.581,4.581,0,0,0-2.3-.535c-.443,0-1.475.082-1.475.842,0,.334.109.684,1.42,1.287l.613.271c2.07.951,2.953,2.062,2.953,3.719C32.66,19.713,30.938,21.145,28.271,21.145Z"
        ></path>
      </svg>
    </IconContainer>
  );
};
