import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './HyperLink.module.less';

const HyperlinkNewTab = ({ url, children, className }) => (
  <a href={url} className={classNames(styles.a, className)} target="_blank" rel="noreferrer">
    {children}
  </a>
);

HyperlinkNewTab.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.any,
};

HyperlinkNewTab.defaultProps = {
  className: undefined,
  children: undefined,
};

export default HyperlinkNewTab;
