import React from 'react';
import IconContainer from './IconContainer';

export const UserAdmin = () => {
  return (
    <IconContainer>
      <svg viewBox="0 0 36 36" focusable="false" aria-hidden="true" role="img">
        <path
          fillRule="evenodd"
          d="M13.62,25.92a12.287,12.287,0,0,1,5.427-10.2,1.48,1.48,0,0,1,.331-.753,10.775,10.775,0,0,0,1.962-3.679,9.906,9.906,0,0,0,.577-3.146A10.792,10.792,0,0,0,21.4,4.714,6.358,6.358,0,0,0,14.961,0a6.8,6.8,0,0,0-4.05,1.229,6.032,6.032,0,0,0-1.3,1.33A9.021,9.021,0,0,0,7.963,8.1a9.453,9.453,0,0,0,.276,2.133A10.975,10.975,0,0,0,10.5,15.007a1.443,1.443,0,0,1,.367.93c.031.837.083,1.466.083,2.032A1.431,1.431,0,0,1,9.7,19.413c-8.366.728-9.673,6.45-9.673,8.707,0,.251.048,1.526.048,1.526H14.2A12.284,12.284,0,0,1,13.62,25.92Z"
        ></path>
        <path
          fillRule="evenodd"
          d="M35.23,24.541H32.815a6.98,6.98,0,0,0-1.02-2.476l1.72-1.72a.69.69,0,0,0,0-.975L32.47,18.325a.69.69,0,0,0-.975,0l-1.72,1.72a6.983,6.983,0,0,0-2.475-1.02V16.61a.69.69,0,0,0-.69-.69H25.23a.69.69,0,0,0-.69.69v2.415a6.983,6.983,0,0,0-2.475,1.02l-1.72-1.72a.69.69,0,0,0-.975,0L18.325,19.37a.69.69,0,0,0,0,.975l1.72,1.72a6.98,6.98,0,0,0-1.02,2.476H16.61a.69.69,0,0,0-.69.69V26.61a.69.69,0,0,0,.69.69h2.415a6.98,6.98,0,0,0,1.02,2.476l-1.72,1.72a.689.689,0,0,0,0,.975l1.045,1.045a.69.69,0,0,0,.975,0l1.72-1.72a6.983,6.983,0,0,0,2.475,1.02V35.23a.69.69,0,0,0,.69.69H26.61a.69.69,0,0,0,.69-.69V32.815a6.983,6.983,0,0,0,2.475-1.02l1.72,1.72a.69.69,0,0,0,.975,0l1.045-1.045a.689.689,0,0,0,0-.975l-1.72-1.72a6.98,6.98,0,0,0,1.02-2.476H35.23a.69.69,0,0,0,.69-.69V25.23A.69.69,0,0,0,35.23,24.541Zm-9.31,4.975a3.6,3.6,0,1,1,3.6-3.6A3.6,3.6,0,0,1,25.92,29.515Z"
        ></path>
      </svg>
    </IconContainer>
  );
};
