import React from 'react';
import { Flex } from '@adobe/react-spectrum';
import Circle from '@spectrum-icons/workflow/Circle';
import styles from './StatusBadge.module.less';

const colorTypes = {
  success: 'positive',
  warning: 'notice',
  danger: 'negative',
};

const getTypeColor = (type) => {
  return colorTypes[type];
};

const StatusBadge = ({ type, text }) => {
  return (
    <Flex direction="row" gap="size-75" alignItems="center">
      <Circle size="XXS" color={getTypeColor(type)} marginRight={10} />
      <span className={styles.content}>{text}</span>
    </Flex>
  );
};

export default StatusBadge;
