/* eslint-disable no-param-reassign */
import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { listViewReferenceTypes as listTypes } from '@send-legacy-components/Common';
import { ListView, SpecialButtons } from '@send-legacy-components/Common/ListView';
import { useRegistration } from '@send-data-hooks';
import { FundsInDetails } from '@send-legacy-containers/DealerAssist';
import { conversionActivityStatus } from '@send-const/statuses';
import { useFeature } from '@sendpayments/react-shared/hooks/useFeature';
import { PD_2160_VRN_CREATION } from '@sendpayments/shared-constants/features';
import history from '@send-legacy-app/history';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import classNames from 'classnames';
import Payment from './Payment';
import styles from './Activities.module.less';

dayjs.extend(localizedFormat);

const getReferenceType = (status) => {
  if (status === conversionActivityStatus.CANCELLED) {
    return listTypes.ERROR;
  }
  if (status === conversionActivityStatus.PAYMENT_RECEIVED) {
    return listTypes.SUCCESS;
  }
  return listTypes.WARNING;
};

const onItemClick = ({ id }) => {
  history.push(`/conversion/${id}`);
};

const ConversionList = ({ conversions, paymentsByConvId, noLink }) => {
  const [{ data: reg }] = useRegistration();
  const convDataSource = conversions.map((item) => ({
    id: item.id,
    iconType: 'send-swap2',
    header:
      `${(item.bookedSellAmount || 0).toLocaleString()} ${item.sellCurrency}` +
      ` to ${(item.bookedBuyAmount || 0).toLocaleString()} ${item.buyCurrency}`,
    description: dayjs(item.createdAt).format('llll'),
    reference: {
      type: item.dbStatus === 'trade_settled' ? listTypes.SUCCESS : getReferenceType(item.status),
      text: item.dbStatus === 'trade_settled' ? conversionActivityStatus.PAYMENT_RECEIVED : item.status,
    },
    clickable: false,
    expandable: true,
    button: SpecialButtons.ICON_RIGHT,
    isActionNeeded: item.isActionNeeded,
    noLink,
  }));

  const conversionsAsMap = conversions.reduce((acct, item) => {
    acct[item.id] = item;
    return acct;
  }, {});

  // eslint-disable-next-line react/prop-types
  const OnRenderExpandedTemplateHandler = ({ id }) => {
    const conv = conversionsAsMap[id];
    const pays = paymentsByConvId[conv.id] || [];
    const fundsNeeded =
      [conversionActivityStatus.AWAITING_PAYMENT, conversionActivityStatus.PATIAL_PAYMENT_RECEIVED].indexOf(conv.status) >= 0;
    const [
      {
        data: { isEnabled: isCreateVRNEnabled },
      },
    ] = useFeature({
      feature: PD_2160_VRN_CREATION,
      functionName: 'VRNDetailsCreate',
    });

    return (
      <>
        {conv.isRecipientNeeded && (
          <Button
            style={{ marginBottom: '16px' }}
            onClick={() => history.push(`/transfer/${conv.id}/add-recipient`)}
            type="secondary"
          >
            Add recipient
          </Button>
        )}
        {fundsNeeded && (
          <div className={styles.fundsIn}>
            <FundsInDetails
              amount={conv.bookedSellAmount - (conv.fundsReceived || 0)}
              currency={conv.sellCurrency}
              showTitle={false}
              sellAmount={conv.bookedSellAmount}
              currencyCloudReference={reg.currencyCloudReference}
              complianceScore={reg.complianceScore}
              userId={reg.id}
              userType={reg.type}
              sendId={reg.sendId}
              convId={id}
            />
          </div>
        )}
        {pays.length > 0 && (
          <div className={styles.payments}>
            {fundsNeeded && <br />}
            {pays.map((pay) => (
              <Payment key={pay.id} pay={pay} />
            ))}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {convDataSource.map((item) => (
        <div key={item.id}>
          <ListView
            dataSource={[item]}
            expandedIds={[item.id]}
            onItemClick={onItemClick}
            className={
              item.isActionNeeded ? classNames(styles.activityListView, styles.activityHighlight) : styles.activityListView
            }
          >
            {OnRenderExpandedTemplateHandler}
          </ListView>
          <br />
        </div>
      ))}
    </>
  );
};

ConversionList.propTypes = {
  conversions: PropTypes.arrayOf(PropTypes.object).isRequired,
  paymentsByConvId: PropTypes.object.isRequired,
  noLink: PropTypes.bool,
};

ConversionList.defaultProps = {
  noLink: false,
};

export default ConversionList;
