import React from 'react';
import PropTypes from 'prop-types';
import { withAntdForm } from '@sendpayments/react-shared/HOC';
import { Row, Input, Select, Form } from 'antd';
import { Icon } from '@send-legacy-components/Common/Icon';
import styles from './Form.module.less';
import { getMaskedIdentifierText } from '@send-base/utilities/getMaskedIdentifierText';

const { Option } = Select;

const ConfirmRecipientView = ({
  form,
  onEditClick,
  name,
  accountNumber,
  bsbCode,
  email,
  sortCodeNumber,
  reason,
  reasonCodesList,
  reference,
  iban,
  clabeNumber,
  loading,
}) => {
  const { getFieldDecorator, getFieldsValue } = form;

  const data = getFieldsValue();
  const loadingToBool = !!loading;

  const isOtherReason = data.reason === 'OTHER';

  // field definition
  const onCreateReasonField = getFieldDecorator('reason', {
    initialValue: reason,
    rules: [{ required: true, message: 'Reason is required' }],
  });

  const onOtherReasonField = getFieldDecorator('otherReason', {
    initialValue: reason,
    rules: [{ required: isOtherReason, message: 'Reason is required' }],
  });

  const onCreateReferenceField = getFieldDecorator('reference', {
    initialValue: reference,
    rules: [{ required: true, message: 'Reference is required' }],
  });

  return (
    <Row className={styles.container}>
      <div className={styles.title}>
        <span>
          <Icon type="user-add" />
          <span className={styles.mainTitle}>Confirm recipient</span>
        </span>
        <span className={styles.link} onClick={onEditClick}>
          Edit recipient
        </span>
      </div>
      <div className={styles.reviewRecipient}>
        <div className={styles.containerRounded}>
          <Row>
            <div className={styles.fieldTitle}>{name}</div>
            <div className={styles.fieldValue}>{getMaskedIdentifierText({ accountNumber, iban, clabeNumber })}</div>
          </Row>
          <Row>
            <div className={styles.fieldTitle}>Email address</div>
            <div className={styles.fieldValue}>{email}</div>
          </Row>
          {sortCodeNumber && (
            <Row>
              <div className={styles.fieldTitle}>Sort code</div>
              <div className={styles.fieldValue}>{sortCodeNumber}</div>
            </Row>
          )}
          {accountNumber && (
            <Row>
              <div className={styles.fieldTitle}>Account number</div>
              <div className={styles.fieldValue}>{accountNumber}</div>
            </Row>
          )}
          {bsbCode && (
            <Row>
              <div className={styles.fieldTitle}>BSB Code</div>
              <div className={styles.fieldValue}>{bsbCode}</div>
            </Row>
          )}
          {iban && (
            <Row>
              <div className={styles.fieldTitle}>IBAN</div>
              <div className={styles.fieldValue}>{iban}</div>
            </Row>
          )}
          {clabeNumber && (
            <Row>
              <div className={styles.fieldTitle}>CLABE</div>
              <div className={styles.fieldValue}>{clabeNumber}</div>
            </Row>
          )}
        </div>
      </div>
      <Form>
        <Row className={styles.rowInput}>
          <div className={styles.fieldInputTitle}>What are your reasons for this transfer</div>
          <div className={styles.fieldInputValue}>
            <Form.Item hasFeedback>
              {reasonCodesList &&
                onCreateReasonField(
                  <Select
                    data-testid="test-reason-list"
                    notFoundContent="Reason not found"
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Enter a reason here…"
                    disabled={loadingToBool}
                  >
                    {reasonCodesList.map(({ code, description }) => (
                      <Option data-testid={`${code}-${description}`} key={`${code}-${description}`} value={code}>
                        {description}
                      </Option>
                    ))}
                  </Select>,
                )}
            </Form.Item>
            {isOtherReason && (
              <Form.Item>{onOtherReasonField(<Input placeholder="Enter a reason here…" disabled={loadingToBool} />)}</Form.Item>
            )}
          </div>
        </Row>
        <Row className={styles.lastRow}>
          <div className={styles.fieldInputTitle}>Would you like to add a reference message?</div>
          <div className={styles.fieldInputValue}>
            <Form.Item hasFeedback>
              {onCreateReferenceField(<Input placeholder="Enter a reference…" disabled={loadingToBool} />)}
            </Form.Item>
          </div>
        </Row>
      </Form>
    </Row>
  );
};

ConfirmRecipientView.propTypes = {
  form: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
  name: PropTypes.string,
  accountNumber: PropTypes.string,
  bsbCode: PropTypes.string,
  email: PropTypes.string,
  sortCodeNumber: PropTypes.string,
  reason: PropTypes.string,
  reference: PropTypes.string,
  iban: PropTypes.string,
  clabeNumber: PropTypes.string,
  reasonCodesList: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
};

ConfirmRecipientView.defaultProps = {
  name: undefined,
  accountNumber: undefined,
  bsbCode: undefined,
  email: undefined,
  sortCodeNumber: undefined,
  reason: undefined,
  reference: undefined,
  reasonCodesList: undefined,
  iban: undefined,
  clabeNumber: undefined,
  loading: false,
};

export default withAntdForm('ConfirmRecipientView', ConfirmRecipientView);
