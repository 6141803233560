import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Flex, View, Form, TextField } from '@adobe/react-spectrum';
import { StatusBadge } from '@send-components/StatusBadge';
import { CollapseSection } from '@send-components/CollapseSection';
import { breakpoints } from '@sendpayments/react-shared/design-system';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { PrimaryActionButton } from '@sendpayments/react-shared/components/buttons/PrimaryActionButton';
import { post } from '@send-base/utilities/endpoint';
import { triggerToast } from '@send-components/Toast';
import { VerifyCodeModal } from '@send-components/VerifyCodeModal';
import { ChangeEmailOptInModal } from '@send-components//ChangeEmailOptInModal';
import { EmailVerifyModal } from '@send-components/EmailVerifyModal';
import Alert from '@spectrum-icons/workflow/Alert';
import { logger } from '@sendpayments/js-utils/dist/services/logger';
import { useLogout } from '@send-data-hooks';
import history from '@send-legacy-app/history';
import MFAHelper from '../Security/MFA/MFAHelper';
import styles from './ChangeEmail.module.less';
import { analytics } from '@sendpayments/js-utils/dist';
import { LoginSourceTypes } from '@sendpayments/shared-constants/types';

const Icon = ({ text }) => {
  return <div className={styles.icon}>{text}</div>;
};

const ChangeEmail = ({ account, user, activeSection, sectionName, changeSectionActive }) => {
  const { isMedia } = useBreakpoint(breakpoints);
  const isMobile = isMedia({ to: breakpoints.xs });
  const [userInput, setUserInput] = useState('');
  const [error, setError] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState('');
  const [mfaIsEnabled, setMfaIsEnabled] = useState(null);
  const [optInConfirmModalOpen, setOptInConfirmModalOpen] = useState(false);
  const [verifyModalIsOpen, setVerifyModalIsOpen] = useState(false);
  const [confirmEmailCodeModalOpen, setConfirmEmailCodeModalOpen] = useState(false);
  const [errorVerifyPhone, setErrorVerifyPhone] = useState('');
  const [errorVerifyEmail, setErrorVerifyEmail] = useState('');
  const [customAuthUser, setCustomAuthUser] = useState(null);
  const [, doLogout] = useLogout();

  useEffect(() => {
    getMFAStatus();
  }, []);

  const getMFAStatus = async () => {
    await MFAHelper.userHasMfaEnabled(user).then((val) => {
      setMfaIsEnabled(val);
    });
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setErrorVerifyPhone('');
    setErrorVerifyEmail('');

    if (!userInput) {
      setError(true);
      setErrorMessage('Please enter an email.');
      return;
    }

    setUserInput(userInput.toLowerCase());

    const result = /\S+@\S+\.\S+/;
    setError(!result.test(userInput));

    if (!result.test(userInput)) {
      setErrorMessage('Please enter a valid email address');
      return;
    }

    setErrorMessage('');
    setError(false);

    const checkEmailAvailableRes = await post('/profile/checkEmailAvailable', {
      data: {
        userEmail: userInput,
      },
    });

    const emailAvailable = checkEmailAvailableRes.data.Users.length === 0;

    if (!emailAvailable) {
      setError(true);
      setErrorMessage('The email is already used by another user.');

      triggerToast({
        type: 'error',
        message: 'Please enter a new email address.',
        rightIcon: <Alert size="M" />,
      });
    } else if (!mfaIsEnabled) {
      setOptInConfirmModalOpen(true);
    } else {
      setVerifyModalIsOpen(true);
      await getVerifyCode();
    }
  };

  const onDismissOptInConfirmModal = () => {
    setOptInConfirmModalOpen(false);
  };

  const onOptIn2FAHandler = async () => {
    setOptInConfirmModalOpen(false);
    setVerifyModalIsOpen(true);
    await getVerifyCode();
  };

  const getVerifyCode = async () => {
    // Auth challenge refuse to send code to unverified phoneNumber so we have to use the api
    if (account.phoneNumberVerified) {
      const cognitoUser = await Auth.signIn(user.username);
      setCustomAuthUser(cognitoUser);
    } else {
      try {
        const payload = {
          accessToken: user.signInUserSession.accessToken.jwtToken,
          regType: account.type,
        };
        const verifyPhoneRes = await post('/profile/verifyPhone', {
          data: payload,
        });
        logger.log('phoneSetting', 'verifyPhoneNumber success', verifyPhoneRes);
      } catch (err) {
        logger.error('phoneSetting', 'verifyPhoneNumber', err);
      }
    }
  };

  const onCompleteCodeChanged = async (verifyCode) => {
    setErrorVerifyPhone('');

    try {
      // Because we have 2 ways of sending sms verification code. We need 2 ways of checking it because customAuthChallenge won't work for code that sent from api
      if (account.phoneNumberVerified) {
        const cognitoUser = await Auth.sendCustomChallengeAnswer(customAuthUser, verifyCode);
        if (!cognitoUser.getSignInUserSession()) {
          throw new Error('Verification code failed');
        }
      } else {
        const verifyResult = await post('/profile/verifyPhoneSubmit', {
          data: {
            accessToken: user.signInUserSession.accessToken.jwtToken,
            code: verifyCode,
            regType: account.type,
          },
        });

        if (verifyResult.status !== true) {
          throw new Error('Verification code failed');
        }
      }

      if (!mfaIsEnabled) {
        const toggleMFAResult = await post('/profile/toggleMFA', {
          data: {
            accessToken: user.signInUserSession.accessToken.jwtToken,
            mfaIsEnable: true,
            regType: account.type,
          },
        });

        if (toggleMFAResult.status === true) {
          await getMFAStatus();
          triggerToast({
            type: 'success',
            message: `2-step verification is now turned on.`,
          });
        }
      }
      setVerifyModalIsOpen(false);
      await post('/profile/verifyEmail', {
        data: {
          accessToken: user.signInUserSession.accessToken.jwtToken,
          preferEmail: userInput,
        },
      });
      setConfirmEmailCodeModalOpen(true);
    } catch (err) {
      setErrorVerifyPhone('The security code entered is not correct. Please try again');
      logger.error('MFA', 'fail to verify code', err);
    }
  };

  const onDismissVerifyEmailModal = () => {
    setConfirmEmailCodeModalOpen(false);
  };

  const onVerifyEmailCode = async (userInputCode) => {
    setErrorVerifyEmail('');

    try {
      await post('/profile/verifyEmailSubmit', {
        data: {
          accessToken: user.signInUserSession.accessToken.jwtToken,
          code: userInputCode,
          regType: account.type,
          newEmail: userInput,
        },
      });

      await doLogout();
      history.push({
        pathname: '/auth',
      });
      window.location.reload();
      analytics.push({ action: { type: 'form_submit', data: { name: 'ChangeEmailForm' } } });
    } catch (err) {
      setErrorVerifyEmail('The security code entered is not correct. Please try again');
      logger.error('Fail to verify Code', { err });
    }
  };

  const onResendCode = async () => {
    await post('/profile/verifyEmail', {
      data: {
        accessToken: user.signInUserSession.accessToken.jwtToken,
        preferEmail: userInput,
      },
    });
  };

  return (
    <>
      <CollapseSection
        buttonText={[LoginSourceTypes.GOOGLE, LoginSourceTypes.FACEBOOK].includes(account.loginSource) ? null : 'Change'}
        activeSection={activeSection}
        sectionName={sectionName}
        changeSectionActive={changeSectionActive}
      >
        <Flex direction={isMobile ? 'column' : 'row'} gap={isMobile ? 'size-40' : 'size-100'} alignItems="baseline">
          <span className={styles.itemTitle}>Email</span>
          <StatusBadge type="success" text="Verified" />
        </Flex>
        <View>
          Your primary email address is <strong>{account.email}</strong>
        </View>
        <View marginTop="size-200">
          <Form onSubmit={onSubmitHandler}>
            <TextField
              placeholder="name@email.com"
              label="Your new email"
              maxWidth={320}
              value={userInput}
              onChange={setUserInput}
              validationState={!error ? '' : 'invalid'}
              UNSAFE_className={styles.rightIcon}
            />
            {error && <span className={styles.validation}>{errorMessage}</span>}

            <View marginTop={8} />

            <div className={styles.blueBackground}>
              <Flex direction="column">
                <span className={styles.itemTitle} style={{ fontSize: '12px' }}>
                  Before your change is confirmed, we’ll ask you to:{' '}
                </span>

                <Flex
                  direction="row"
                  gap="size-125"
                  justifyContent="flex-start"
                  alignItems="center"
                  marginTop="size-200"
                  UNSAFE_style={{ fontSize: '14px' }}
                >
                  <Icon text="1" /> Enter a security code we send via SMS
                </Flex>
                <Flex
                  direction="row"
                  gap="size-125"
                  justifyContent="flex-start"
                  alignItems="center"
                  marginTop="size-175"
                  UNSAFE_style={{ fontSize: '14px' }}
                >
                  <Icon text="2" /> Verify your new email address
                </Flex>
              </Flex>
            </div>
            <PrimaryActionButton UNSAFE_className={styles.primaryButton} height="inherit" type="submit">
              Change Email
            </PrimaryActionButton>
          </Form>
        </View>
      </CollapseSection>
      {optInConfirmModalOpen && (
        <ChangeEmailOptInModal
          onOptIn2FAHandler={onOptIn2FAHandler}
          onDismissOptInConfirmModal={onDismissOptInConfirmModal}
          phoneNumber={`${account.phoneCountryCode}${account.phone}`}
        />
      )}
      <VerifyCodeModal
        isOpen={verifyModalIsOpen}
        title="We’ve just sent you an SMS"
        description={`To log in, Please enter the 6-digit security <strong>code we sent to ${account.phoneCountryCode}${account.phone}.</strong>`}
        onCodeComplete={onCompleteCodeChanged}
        onResendCode={getVerifyCode}
        onDismiss={() => {
          setVerifyModalIsOpen(false);
        }}
        error={errorVerifyPhone}
      />
      {confirmEmailCodeModalOpen && (
        <EmailVerifyModal
          onDismissVerifyEmailModal={onDismissVerifyEmailModal}
          onVerifyEmailCode={onVerifyEmailCode}
          onResendCode={() => onResendCode()}
          error={errorVerifyEmail}
        />
      )}
    </>
  );
};

export default ChangeEmail;
