import React, { ReactNode } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button, Stack, Box } from '@chakra-ui/react';
// @ts-ignore should add d.ts file for this.
import { PasswordField, InputField } from '@sendpayments/react-shared/components/molecules';
import { useToast } from '@sendpayments/react-shared/components/molecules/Toast';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { SendForm } from '../types';
import { analytics } from '@sendpayments/js-utils/dist';
import logger from '@sendpayments/js-utils/dist/services/logger';
import { ChevronIcon } from '@sendpayments/react-shared/components/Icons';
import credentials from '@send-base/services.config';
import { PD_1348_CAPTCHA } from '@sendpayments/shared-constants/features';
import { useFeature } from '@sendpayments/react-shared/hooks/useFeature';

const registerSchema = yup.object({
  email: yup
    .string()
    .email('This email is invalid. Please try this format: yourname@example.com')
    .required('Enter your email address in this format: yourname@example.com'),
  password: yup.string().min(8, 'Please create a password with at least 8 characters').required('Please create your password'),
});

type ComparisonRegisterFormData = yup.InferType<typeof registerSchema>;
export type ComparisonRegisterFormProps = SendForm<ComparisonRegisterFormData>;

export const ComparisonRegisterForm: React.FC<ComparisonRegisterFormProps> = ({ onSubmit, isLoading }) => {
  const toast = useToast();
  const recaptchaRef = React.createRef();

  const [
    {
      data: { isEnabled: isCaptchaEnabled },
    },
  ] = useFeature({
    feature: PD_1348_CAPTCHA,
    activeValue: undefined,
    functionName: `Captcha`,
    defaultValue: undefined,
  });

  const submitHandler = async (values, actions) => {
    try {
      if (isCaptchaEnabled) {
        const recaptchaValue = await (recaptchaRef.current as ReCAPTCHA).getValue();
        if (!recaptchaValue) throw new Error('Something went wrong on our end. Please try again!');
      }
      await onSubmit(values);
      analytics.push({ action: { type: 'form_submit', data: { name: 'RegisterForm' } } });
    } catch (error) {
      logger.error('RegisterForm', 'ERROR: ', error);
      actions.setSubmitting(false);
      toast({
        variant: 'negative',
        title: 'Something went wrong on our end. Please try again!',
      });
    }
  };

  return (
    <Box>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={registerSchema}
        onSubmit={async (values, actions) => {
          await submitHandler(values, actions);
        }}
      >
        {(props) => (
          <Form noValidate>
            <Stack spacing="3">
              <Field name="email">
                {({ field, form }) => (
                  <InputField
                    id="email"
                    label="Email address"
                    color={'text.base'}
                    placeholder="Enter your email address"
                    isInvalid={form.errors.email && form.touched.email}
                    errorText={form.errors.email}
                    inputProps={{
                      type: 'email',
                      size: 'md',
                      autoComplete: 'email',
                      placeholder: 'Enter your email address',
                      ...field,
                    }}
                  />
                )}
              </Field>
              <Field name="password">
                {({ field, form }) => (
                  <PasswordField
                    id="newPassword"
                    label="Create password"
                    color={'text.base'}
                    isInvalid={form.errors.password && form.touched.password}
                    errorText={form.errors.password}
                    inputProps={{ size: 'md', autoComplete: 'new-password', placeholder: 'Create a password', ...field }}
                  />
                )}
              </Field>
              {/* This will only be enabled in production, turned off in dev environments */}
              {isCaptchaEnabled && <ReCAPTCHA ref={recaptchaRef} sitekey={credentials.recaptcha.siteKey} />}

              <Button isLoading={props.isSubmitting || isLoading} type="submit" width="100%" alignSelf="center" fontSize="md">
                Create account <ChevronIcon direction={'forward'} h={2} w={2} ml={2} />
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
