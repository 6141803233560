/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { Row, Col, Spin, Layout, Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import CustomLayout from '@send-legacy-components/Common/CustomLayout';
import { appBreakpoints, MediaBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { DashboardBlock } from '@send-legacy-components/DashboardBlock';
import {
  usePendingDocuments,
  usePendingRecipients,
  useActivitiesByStatus,
  useCurrentEmail,
  useAppContext,
} from '@send-data-hooks';
import { useRegistration } from '@send-base/infrastructure/query';
import { ExchangeCurrency } from '@send-legacy-containers/ExchangeCurrency';
import { ListView } from '@send-legacy-components/Common/ListView';
import history from '@send-legacy-app/history';
import { conversionActivityStatus } from '@send-const/statuses';
import { getLabelByKey } from '@send-services/resources';
import { getCurrencyString } from '@sendpayments/js-utils/dist/utils/currency';
import { SMenu } from '@send-legacy-components/UI/SMenu';
import MfaStandalone from '@send-components/Account/Security/MFA/MfaStandalone';
import { saveEntity } from '@sendpayments/js-utils/dist/services/entities';
import { Auth } from 'aws-amplify';
import { VRNAcknowledgementBanner } from '@send-legacy-components/VRNAcknowledgementBanner';
import { getHubUrl } from '@send-services/environment';
import { useFeature } from '@sendpayments/react-shared/hooks/useFeature';
import { PD_2197_PORTAL_TO_HUB_AUTH_REDIRECT } from '@sendpayments/shared-constants/features';

const RegisterStatus = {
  New: 'New',
  WorkingOn: 'Working On',
  AwaitingDocs: 'Awaiting Docs',
  AwaitingInfo: 'Awaiting Info',
  PendingReview: 'Pending Review',
  Registered: 'Registered',
  Dealing: 'Dealing',
  Incomplete: 'Incomplete',
  RecipientAwaitingDocs: 'Recipient Awaiting Docs',
  Default: 'Default',
};

const { Content } = Layout;

const getStatusMapping = ({ firstName, businessName, supportPhone, isComparisonUser }) => ({
  Default: {
    title: getLabelByKey({ key: 'dashboard_title_default', default: `We've made foreign exchange easy` }),
    header: 'Start your registration',
    description: 'To get started, fill in your personal details',
    notification:
      "To help you move your money your way, we've removed borders for seamless transfers. " +
      'It only takes a few minutes to get started so you can begin sending money overseas.',
    clickable: true,
  },
  Incomplete: {
    title: `Let's get started ${firstName}!`,
    header: isComparisonUser ? 'Complete your registration' : 'Verify identity',
    description: isComparisonUser
      ? 'Please fill in the remainder of your details'
      : 'Fill in your personal details to comply with our regulatory requirements.',
    notification: 'You’re almost ready to start using your account. Verify your identity to unlock all account features.',
    clickable: true,
    highlight: true,
  },
  New: {
    title: "You're almost there",
    header: "We're working on it",
    description: "We'll contact you shortly to make those transfers happen",
    notification:
      'We are making sure we have your details correct for a safe and secure transaction. While you wait, please feel free to go-ahead and add a recipient.',
    clickable: false,
  },
  'Working On': {
    title: "You're almost there",
    header: "We're working on it",
    description: "We'll contact you shortly to make those transfers happen",
    notification:
      'We are making sure we have your details correct for a safe and secure transaction. While you wait, please feel free to go-ahead and add a recipient.',
    clickable: false,
  },
  'Awaiting Docs': {
    title: 'We need a few more things to get you going',
    header: 'Upload your documents',
    description: 'Please upload the requested documents.',
    notification:
      'We take security seriously, please provide the information requested below so we can get your transfer moving.',
    clickable: true,
  },
  'Pending Review': {
    title: 'Your account is currently under review',
    header: 'Your account is currently under review',
    description: `We are currently reviewing your supplied documentation, if you have any questions in the meantime please call us on ${supportPhone}`,
    notification: `We are currently reviewing your supplied documentation, if you have any questions in the meantime please call us on ${supportPhone}`,
    clickable: false,
  },
  'Awaiting Info': {
    title: 'We need a few more things to get you going',
    header: `Please call us on ${supportPhone}`,
    description: 'We require additional information to continue processing your account',
    notification: `We require additional information to continue processing your account. \nPlease call us on ${supportPhone}.`,
    clickable: false,
  },
  Registered: {
    title: "You're on your way!",
    header: 'Congratulations!',
    description: "You're ready to make your first transfer.",
    clickable: false,
  },
  Dealing: {
    title: `Hi, ${firstName || businessName}`,
    header: 'Send Money',
    description: 'You are all set up now',
    clickable: false,
  },
  Inactive: {
    title: 'Your account needs attention',
    header: 'Account Inactive',
    description: 'Your account needs attention',
    notification: `To reactivate your account, please call us on ${supportPhone}.`,
    clickable: false,
    disabled: true,
  },
  'Registration Failed': {
    title: 'Your account needs attention',
    header: 'Account Inactive',
    description: 'Your registration is incomplete.',
    notification: `We require additional information to continue processing your account. \nPlease call us on ${supportPhone}.`,
    clickable: false,
    disabled: true,
  },
  'Recipient Awaiting Docs': {
    title: 'We need a few more things to get you going',
    header: 'Document Required',
    description: 'Please upload the requested documents.',
    notification:
      'We take security seriously, please provide the information requested below so we can get your transfer moving.',
    clickable: true,
  },
});

const isFundsNeeded = (conv) =>
  [conversionActivityStatus.AWAITING_PAYMENT, conversionActivityStatus.PATIAL_PAYMENT_RECEIVED].indexOf(conv.status) >= 0;

function Dashboard(props) {
  const [{ data: userEmail }] = useCurrentEmail();
  const [{ user }] = useAppContext();
  const { data: registration = {}, isLoading: loadingRegistration } = useRegistration({
    params: { owner: user?.attributes?.email },
  });
  const [{ data: pendingDocs }] = usePendingDocuments({ owner: userEmail });
  const [
    {
      data: { conversions: allConvs },
      loading: loadingActivities,
    },
  ] = useActivitiesByStatus({
    email: userEmail,
  });

  const [
    {
      data: { isEnabled: isHubAuthRedirectEnabled },
    },
  ] = useFeature({
    feature: PD_2197_PORTAL_TO_HUB_AUTH_REDIRECT,
    functionName: `PortalToHubAuthRedirect`,
    defaultValue: 'isLoading',
  });

  const [{ data: pendingRecipients }] = usePendingRecipients({ owner: userEmail });

  const isComparisonUser = user?.attributes['custom:trackingId'] ?? null;

  const [collapsed, setCollapsed] = useState(true);

  const [mfaReminderState, setMfaReminderState] = useState(/** @type {MfaState} */ ('hidden'));

  const nonSettledConvs = allConvs.filter((conv) => conv.dbStatus !== 'trade_settled');
  const fundsNeededConvs = nonSettledConvs.filter((conv) => isFundsNeeded(conv) && !conv.isRecipientNeeded);
  const recipientNeededConvs = nonSettledConvs.filter((conv) => conv.isRecipientNeeded && !isFundsNeeded(conv));
  const bothNeededConvs = nonSettledConvs.filter((conv) => conv.isRecipientNeeded && isFundsNeeded(conv));

  let regStatus = registration.status || RegisterStatus.Default;
  let incompleteAndAwaitingDocs = false;
  const enableRecipient = [
    RegisterStatus.New,
    RegisterStatus.WorkingOn,
    RegisterStatus.AwaitingDocs,
    RegisterStatus.AwaitingInfo,
    RegisterStatus.PendingReview,
    RegisterStatus.Registered,
    RegisterStatus.Dealing,
  ].includes(regStatus);

  if (pendingDocs.length) {
    if (regStatus !== RegisterStatus.Registered && regStatus !== RegisterStatus.Dealing) {
      if (regStatus === RegisterStatus.Incomplete) {
        incompleteAndAwaitingDocs = true;
      }
      regStatus = RegisterStatus.AwaitingDocs;
    } else {
      regStatus = RegisterStatus.RecipientAwaitingDocs;
    }
  } else if (regStatus === RegisterStatus.AwaitingDocs) {
    regStatus = RegisterStatus.AwaitingInfo;
  }

  const supportPhone = registration && registration.country === 'Australia' ? '1800 982 418' : '+617 5619 5700';
  const statusMapping = getStatusMapping({
    firstName: registration && registration.firstName,
    lastName: registration && registration.lastName,
    supportPhone,
    isComparisonUser,
  });

  //The hub creates a stub that is in 'Incomplete' status.
  //As Corporates do not have a first name,
  //This defaults 'Corporate' registrations who are in the 'Incomplete' status to the Default status mapping
  //As to avoid Let's get started null!`, if not the pre-existing logic is executed
  const hasFirstName = registration && registration.firstName;
  const isCorporateRegistration = registration && registration.type === 'Corporate';
  const isIncompleteStatus = registration && registration.status === 'Incomplete';
  const isPexaUser = registration && registration.portalSource === 'pexa';

  const statusInfo =
    isCorporateRegistration && !hasFirstName && isIncompleteStatus
      ? statusMapping.Default
      : statusMapping[regStatus] || statusMapping.Default;

  const accountActive = [RegisterStatus.Registered, RegisterStatus.Dealing, RegisterStatus.RecipientAwaitingDocs].includes(
    regStatus,
  );

  const dataSource = [
    incompleteAndAwaitingDocs
      ? {
          id: 'mainStatus',
          clickable: true,
          disabled: false,
          iconType: 'edit',
          header: 'Complete your registration',
          description: 'Please fill in the remainder of your details',
          href: `/${registration.type || 'regselect'}`,
        }
      : null,

    pendingDocs.length
      ? {
          id: 'uploadYourDocs',
          clickable: true,
          disabled: false,
          highlight: true,
          iconType: 'cloud-upload',
          header: 'Upload your documents',
          description: 'Please upload the requested documents',
          href: '/document',
        }
      : null,

    ...fundsNeededConvs.map((conv) => ({
      id: conv.id,
      clickable: true,
      highlight: true,
      iconType: 'bank',
      header: 'Payment Needed',
      description: `${getCurrencyString({
        amount: conv.outstandingAmount,
        currency: conv.sellCurrency,
      })} payment for your ${getCurrencyString({ amount: conv.bookedBuyAmount, currency: conv.buyCurrency })} transfer`,
      href: `/conversion/${conv.id}`,
    })),

    ...recipientNeededConvs.map((conv) => ({
      id: conv.id,
      clickable: true,
      highlight: true,
      iconType: 'user-add',
      header: 'Recipient Needed',
      description: `Add recipient to transfer your ${getCurrencyString({
        amount: conv.bookedBuyAmount,
        currency: conv.buyCurrency,
      })}`,
      href: `/transfer/${conv.id}/add-recipient`,
    })),

    ...bothNeededConvs.map((conv) => ({
      id: conv.id,
      clickable: true,
      highlight: true,
      iconType: 'user-add',
      header: 'Payment Needed and Recipient Needed',
      description: `${getCurrencyString({
        amount: conv.outstandingAmount,
        currency: conv.sellCurrency,
      })} payment for your ${getCurrencyString({ amount: conv.bookedBuyAmount, currency: conv.buyCurrency })} transfer`,
      href: `/conversion/${conv.id}`,
    })),

    [RegisterStatus.Default, RegisterStatus.Incomplete, RegisterStatus.New, RegisterStatus.WorkingOn].includes(regStatus)
      ? {
          id: 'mainStatus',
          clickable: statusInfo.clickable,
          disabled: statusInfo.disabled,
          iconType: 'edit',
          header: statusInfo.header,
          description: statusInfo.description,
          href: `/${registration.type || 'regselect'}`,
        }
      : null,

    pendingRecipients.length && {
      id: 'recipientPending',
      iconType: 'clock-circle',
      header: 'Recipient pending',
      description: "We're currently in the process of approving your recipient",
      disabled: false,
      clickable: false,
    },

    !pendingRecipients.length && {
      id: 'addRecipient',
      iconType: 'user-add',
      header: 'Add a recipient',
      description: 'Who are you transferring money to?',
      disabled: !enableRecipient,
      clickable: enableRecipient,
      href: '/add-recipient',
    },

    {
      id: 'makeATransfer',
      iconType: 'swap',
      header: 'Make a transfer',
      description: accountActive ? `Let's make those transfers happen!` : 'Are you ready to send?',
      disabled: !accountActive,
      clickable: accountActive,
      href: '/transfer',
    },

    {
      id: 'activities',
      iconType: 'credit-card',
      header: 'View all your activities',
      description: 'See transfer status, details and account history',
      disabled: registration.status !== 'Dealing' && registration.status !== 'Registered',
      clickable: registration.status === 'Dealing' || registration.status === 'Registered',
      href: '/activity',
    },
  ];

  const pexaDataSource = [
    incompleteAndAwaitingDocs
      ? {
          id: 'mainStatus',
          clickable: true,
          disabled: false,
          iconType: 'edit',
          header: 'Complete your registration',
          description: 'Please fill in the remainder of your details',
          href: `/${registration.type || 'regselect'}`,
        }
      : null,

    pendingDocs.length
      ? {
          id: 'uploadYourDocs',
          clickable: true,
          disabled: false,
          highlight: true,
          iconType: 'cloud-upload',
          header: 'Upload your documents',
          description: 'Please upload the requested documents',
          href: '/document',
        }
      : null,
    [RegisterStatus.Default, RegisterStatus.Incomplete, RegisterStatus.New, RegisterStatus.WorkingOn].includes(regStatus)
      ? {
          id: 'mainStatus',
          clickable: statusInfo.clickable,
          disabled: statusInfo.disabled,
          iconType: 'edit',
          header: statusInfo.header,
          description: statusInfo.description,
          href: `/${registration.type || 'regselect'}`,
        }
      : null,
  ];

  const incompleteComparisonDataSource = [
    {
      id: 'mainStatus',
      clickable: statusInfo.clickable,
      disabled: statusInfo.disabled,
      iconType: 'edit',
      header: statusInfo.header,
      description: statusInfo.description,
      href: `/${registration.type || 'regselect'}`,
      highlight: true,
    },
  ];

  let displayDataSource;
  if (isPexaUser) {
    displayDataSource = registration.status === RegisterStatus.Incomplete ? incompleteComparisonDataSource : pexaDataSource;
  } else {
    displayDataSource =
      isComparisonUser && registration.status === RegisterStatus.Incomplete ? incompleteComparisonDataSource : dataSource;
  }

  const onItemClickHandler = ({ id: idItem }) => {
    const item = displayDataSource.find((dashItem) => dashItem && dashItem.id === idItem);

    if (item && item.href) {
      if (item.id === 'mainStatus' && item.href === '/Corporate' && isHubAuthRedirectEnabled) {
        return window.location.replace(`${getHubUrl()}/corporate/onboarding/country`);
      }

      if (item.href && item.href.includes('tel:')) {
        return window.open(item.href, '_self');
      }

      return history.push(item.href);
    }

    return undefined;
  };

  const onBookClickHandler = (trade) => {
    history.push('/transfer', trade);
  };

  const initializeMFAReminder = async () => {
    registration.mfaReminderEnabled = true;
    await saveEntity({ entity: { id: registration.id, mfaReminderEnabled: true }, type: registration.type });
  };

  const turnOffMFAReminder = async () => {
    registration.mfaReminderEnabled = false;
    await saveEntity({ entity: { id: registration.id, mfaReminderEnabled: false }, type: registration.type });
  };

  useEffect(() => {
    if (user && registration && registration.type) {
      Auth.getPreferredMFA(user, { bypassCache: true }).then((data) => {
        // Can be SMS TOTP or NOMFA we only use SMS
        if (data !== 'SMS_MFA') {
          // support old accounts who signed up before mfa reminder change
          registration.mfaReminderEnabled === null && initializeMFAReminder();
          if (registration.mfaReminderEnabled || registration.mfaReminderEnabled === undefined) {
            setMfaReminderState('shown');
          }
        } else if (registration.mfaReminderEnabled) {
          turnOffMFAReminder();
        }
      });
    }
  }, [registration, user]);

  const isLoading = loadingRegistration || loadingActivities;

  const PexaDisplay = () => {
    return (
      <>
        <Col style={{ marginTop: '50px' }}>
          <h1 className="bold">{statusInfo.title}</h1>
          {statusInfo.notification && (
            <h5 style={{ whiteSpace: 'pre-wrap', fontWeight: 'bold', marginBottom: '15px', color: '#1B2221' }}>
              {statusInfo.notification}
            </h5>
          )}
          {props.location.state && props.location.state.message && (
            <div className="block-group">
              <DashboardBlock notification header={props.location.state.message} />
            </div>
          )}
          <ListView size="big" dataSource={displayDataSource.filter((item) => item)} onItemClick={onItemClickHandler} />
        </Col>
      </>
    );
  };

  return (
    <Spin spinning={isLoading}>
      <CustomLayout onClick={() => setCollapsed(true)} showSupportButton>
        {!loadingActivities && !isPexaUser && (
          <MfaStandalone
            state={mfaReminderState}
            setState={setMfaReminderState}
            account={registration}
            user={user}
            onCancel={turnOffMFAReminder}
          />
        )}
        <Content>
          <div className="container">
            <Row>
              <SMenu activeItem="dashboard" registration={registration} collapsed={collapsed} setCollapsed={setCollapsed} />
              <Divider className="top-menu-divider" />
              <VRNAcknowledgementBanner />
              {isPexaUser ? (
                <PexaDisplay />
              ) : (
                <>
                  <Col sm={24} lg={{ span: 13, offset: 1 }} style={{ marginTop: '50px' }}>
                    <h1 className="bold">{statusInfo.title}</h1>
                    {statusInfo.notification && (
                      <h5 style={{ whiteSpace: 'pre-wrap', fontWeight: 'bold', marginBottom: '15px', color: '#1B2221' }}>
                        {statusInfo.notification}
                      </h5>
                    )}
                    {props.location.state && props.location.state.message && (
                      <div className="block-group">
                        <DashboardBlock notification header={props.location.state.message} />
                      </div>
                    )}
                    <MediaBreakpoint to={appBreakpoints.md}>
                      <div className="block-group mobile-exchange-widget">
                        <ExchangeCurrency collapsedWidget showBookButton={accountActive} onBookClick={onBookClickHandler} />
                      </div>
                    </MediaBreakpoint>
                    <ListView size="big" dataSource={displayDataSource.filter((item) => item)} onItemClick={onItemClickHandler} />
                  </Col>
                  <Col sm={24} lg={{ span: 7, offset: 2 }}>
                    <div className="desktop-exchange-widget">
                      <ExchangeCurrency showBookButton={accountActive} onBookClick={onBookClickHandler} />
                    </div>
                  </Col>
                </>
              )}
            </Row>
          </div>
        </Content>
      </CustomLayout>
    </Spin>
  );
}

Dashboard.propTypes = {
  location: PropTypes.object,
};

Dashboard.defaultProps = {
  location: {},
};

export default Dashboard;
