import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const typeSafeParseInt = (value: string | number) => {
  if (typeof value === 'string') {
    return parseInt(value, 10);
  }
  return value;
};

export const getDateFormats = (value: string | number): { string: string; unix: number } => {
  const isString = typeof value === 'string';
  const stringValue = isString ? value : dayjs.unix(typeSafeParseInt(value)).format('YYYY-MM-DD');
  const unixValue = isString ? dayjs(value).unix() : value;
  return { string: stringValue, unix: unixValue };
};
