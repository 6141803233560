import { useRemoteDataSelector } from '@sendpayments/react-shared/hooks/useRemoteData';
import { useRecipientsByOwner } from '../useRecipientsByOwner';

const filterRecipients = (recipients) => recipients.data.filter((item) => ['Pending', 'New'].includes(item.status));

const usePendingRecipients = ({ owner }) => {
  const [recipients] = useRecipientsByOwner({ owner });

  return useRemoteDataSelector(() => filterRecipients(recipients), [recipients]);
};

export default usePendingRecipients;
