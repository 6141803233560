import React, { useState } from 'react';
import { Content, Dialog, Footer, Heading, DialogContainer, View, useDialogContainer, Text } from '@adobe/react-spectrum';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { breakpoints } from '@sendpayments/react-shared/design-system';
import Close from '@spectrum-icons/workflow/Close';
import { PrimaryActionButton } from '@sendpayments/react-shared/components/buttons/PrimaryActionButton';
import { PrimaryButton } from '@send-components/PrimaryButton';
import { withTheme } from '@sendpayments/react-shared/components/ThemeProvider';
import styles from './ChangeEmailOptInModal.module.less';

const DialogContent = ({ onDismissOptInConfirmModal, onOptIn2FAHandler, phoneNumber }) => {
  const dialog = useDialogContainer();
  const { isMediaMobile } = useBreakpoint(breakpoints);

  const onDismissDialogHandler = () => {
    onDismissOptInConfirmModal();
    return dialog.dismiss;
  };

  return (
    <Dialog size="M">
      <div className={styles.exit} onClick={onDismissDialogHandler}>
        <Close />
      </div>
      <Heading>
        <div className={`${styles.title} ${isMediaMobile && styles.titleMobile}`}>
          Changing your email requires 2-step verification
        </div>
      </Heading>
      <Content marginTop={8}>
        <Text>
          Each time you sign in or update your Send account, you’ll need your password and a verification code. Bad guys won’t be
          able to get in even if they have your password.
        </Text>
        <View marginTop={20} />
        <Text UNSAFE_className={styles.bold}>Would you like to activate {phoneNumber} to your account?</Text>
      </Content>
      <Footer UNSAFE_className={styles.footer}>
        <PrimaryActionButton onPress={onOptIn2FAHandler} UNSAFE_className={styles.inline} height="inherit">
          Turn on 2-step verification
        </PrimaryActionButton>
        <PrimaryButton onPress={onDismissDialogHandler} marginX={isMediaMobile ? 0 : 12}>
          Not now thanks
        </PrimaryButton>
      </Footer>
    </Dialog>
  );
};

const ChangeEmailOptInModal = (props) => {
  const [isOpen, setIsOpen] = useState(true);

  return <DialogContainer onDismiss={() => setIsOpen(false)}>{isOpen && <DialogContent {...props} />}</DialogContainer>;
};

export default withTheme(ChangeEmailOptInModal);
