import { Registration, RegistrationType } from '@send-base/types';
import * as queries from '@sendpayments/graphql/queries';
import { query } from '@send-base/utilities/endpoint';
import { useQueries, useQuery, UseQueryOptions } from 'react-query';

export const queryKeysRegistration = {
  registration: (type: RegistrationType) => ['registration', type],
};

export const useRegistration = ({ params }: { params: { owner: string } }) => {
  const results = useQueries([
    {
      queryKey: queryKeysRegistration.registration('Retail'),
      queryFn: () => getRegistration(params.owner, 'Retail'),
    },
    {
      queryKey: queryKeysRegistration.registration('Corporate'),
      queryFn: () => getRegistration(params.owner, 'Corporate'),
    },
  ]);
  const [{ data: retail }, { data: corporate }] = results;

  const data = selectRegistration((retail?.items?.length ?? 0) > 0 ? retail : corporate);

  return {
    isLoading: results.some((result) => result.isLoading),
    isFetching: results.some((result) => result.isFetching),
    data: data,
  };
};

export const useRegistrationByType = ({
  params,
  options,
}: {
  params: { owner: string; type: RegistrationType };
  options?: UseQueryOptions<Registrations, unknown, Registration | undefined, any>;
}) =>
  useQuery(queryKeysRegistration.registration(params.type), () => getRegistration(params.owner, params.type), {
    ...options,
    select: selectRegistration,
  });

/**
 * Backward compatibility with existing hook
 */
const selectRegistration = (registrations: Registrations | undefined): Registration | undefined => {
  if (registrations && registrations?.items?.length > 0) {
    const registration = registrations.items.find(({ status }) => status !== 'Incomplete');
    return registration ? registration : registrations.items[0];
  }
  return undefined;
};

const getRegistration = (owner: string, type: RegistrationType): Promise<Registrations> =>
  query(queries.listCoconutsByOwner, {
    params: {
      owner: owner,
      type: { eq: type },
    },
    projection: {},
  });

export type Registrations = {
  items: Registration[];
};
