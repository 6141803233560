// DEPRECATED, DO NOT USE
export const setOwner = (user, perm, obj, id, owner) => {
  const newObj = { ...obj };
  if (id) {
    newObj.updatedBy = user.attributes.email;
  } else {
    newObj.createdBy = user.attributes.email;
  }
  if (perm.create === 'own' || !owner) {
    newObj.owner = user.attributes.email;
  } else {
    newObj.owner = owner;
  }
  return newObj;
};

// DEPRECATED, DO NOT USE
export const setOwnerFilter = (user, perm, esFilter) => {
  const newFilter = { ...esFilter };
  if (perm.read === 'own') {
    newFilter.owner = { eq: user.attributes.email };
  }
  return newFilter;
};

// DEPRECATED, DO NOT USE
export const isEditable = (user, obj, perm) => perm.update && (perm.update === 'all' || obj.owner === user.attributes.email);
