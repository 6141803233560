import React from 'react';
import { Box, Button, Flex, Heading } from '@chakra-ui/react';
import { ChevronIcon } from '@sendpayments/react-shared/components/Icons';

interface FullPageModalTemplateProps {
  heading?: string;
  subHeading?: string;
  backButtonText?: string;
  onNavigateBack?: () => void;
  children?: React.ReactNode;
}

export const FullPageModalTemplate = ({
  heading = 'Example',
  subHeading,
  backButtonText = 'Back',
  onNavigateBack,
  children,
}: FullPageModalTemplateProps) => {
  return (
    <>
      <Flex alignItems="center" p={4}>
        <Button w={250} variant={'outline'} zIndex={3} leftIcon={<ChevronIcon direction="back" />} onClick={onNavigateBack}>
          {backButtonText}
        </Button>
      </Flex>
      <Flex borderBottom="solid 1px" display={{ base: 'none', sm: 'block' }} borderColor="border.base" w="100%" />
      <Flex p={{ base: 4, sm: 4, lg: 8 }} align="center" justify="center" display="flex" flexDirection="column">
        <Box w="100%" display="flex" align="center" justify="center" flexDirection="column">
          <Heading
            as="h1"
            variant="title"
            justifyContent={{ base: 'center', md: 'left' }}
            mb={{ base: 4, md: subHeading ? 4 : 8 }}
            textAlign="center"
          >
            {heading}
          </Heading>
          {subHeading && (
            <Heading
              style={{ letterSpacing: '.01em' }}
              lineHeight={'1.5'}
              color={'text.base'}
              fontSize="lg"
              fontWeight="400"
              justifyContent={{ base: 'center', md: 'left' }}
              mb={8}
              textAlign={{ base: 'left', sm: 'center' }}
              maxWidth={{ base: '100%', md: '416px' }}
              ml={'auto'}
              mr={'auto'}
            >
              {subHeading}
            </Heading>
          )}
          {children}
        </Box>
      </Flex>
    </>
  );
};
