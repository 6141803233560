import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const VerifyPhoneIcon = (props: IconProps) => (
  <Icon width={136} height={130} viewBox="0 0 136 130" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <ellipse cx={68} cy={64.7819} rx={47.2855} ry={31.5237} transform="rotate(-48.3297 68 64.7819)" fill="#4AFFE2" />
    <rect x={43.1696} y={33.9072} width={39.2568} height={69.7899} fill="#6A6A6A" />
    <path
      d="M64.6761 98.6065H60.92C59.8833 98.6065 59.042 97.7652 59.042 96.7286C59.042 95.6919 59.8833 94.8506 60.92 94.8506H64.6761C65.7127 94.8506 66.5541 95.6919 66.5541 96.7286C66.5541 97.7652 65.7127 98.6065 64.6761 98.6065Z"
      fill="black"
    />
    <path
      d="M79.7001 106.119H45.8957C42.7894 106.119 40.2616 103.591 40.2616 100.485V36.6341C40.2616 33.528 42.7894 31.0002 45.8957 31.0002H79.7001C82.8064 31.0002 85.3342 33.528 85.3342 36.6341V100.485C85.3342 103.591 82.8064 106.119 79.7001 106.119ZM45.8957 34.7562C44.859 34.7562 44.0176 35.5975 44.0176 36.6341V100.485C44.0176 101.522 44.859 102.363 45.8957 102.363H79.7001C80.7368 102.363 81.5782 101.522 81.5782 100.485V36.6341C81.5782 35.5975 80.7368 34.7562 79.7001 34.7562H45.8957Z"
      fill="black"
    />
    <path
      d="M75.9442 91.094H49.6518C48.6152 91.094 47.7738 90.2527 47.7738 89.2161V40.389C47.7738 39.3523 48.6152 38.511 49.6518 38.511H75.9442C76.9809 38.511 77.8222 39.3523 77.8222 40.389V89.2161C77.8222 90.2527 76.9809 91.094 75.9442 91.094ZM51.5299 87.3381H74.0662V42.2669H51.5299V87.3381Z"
      fill="black"
    />
    <rect x={51.1663} y={41.9048} width={23.2633} height={45.0726} fill="white" />
    <path
      d="M83.0102 116.166L71.7602 108.639L64.6681 96.3894L60.2065 77.7185L80.4009 70.7548L104.773 77.7185L101.51 96.3894L93.5102 108.639L83.0102 116.166Z"
      fill="#0097CE"
    />
    <g clipPath="url(#clip0_2442_39339)">
      <path
        d="M82.6641 117.411C82.5349 117.411 82.4033 117.389 82.279 117.347C77.7969 115.853 72.1718 110.886 67.948 104.691C64.0996 99.0461 59.5103 89.6919 59.5103 77.1937C59.5103 76.521 60.0562 75.9751 60.7289 75.9751C67.5873 75.9751 78.3697 71.2809 81.989 68.8681C82.3985 68.5951 82.9322 68.5951 83.3417 68.8681C86.961 71.2809 97.7434 75.9751 104.602 75.9751C105.274 75.9751 105.82 76.521 105.82 77.1937C105.82 89.6919 101.231 99.0436 97.3827 104.691C93.1589 110.884 87.5337 115.853 83.0516 117.347C82.9273 117.389 82.7957 117.411 82.6666 117.411H82.6641ZM61.9621 78.3758C62.2375 89.6627 66.4272 98.137 69.9612 103.321C74.2727 109.643 79.3592 113.631 82.6641 114.9C85.969 113.631 91.0556 109.646 95.3671 103.321C98.9011 98.137 103.091 89.6627 103.366 78.3758C99.3983 78.1272 94.9771 76.7623 91.8379 75.5973C88.3819 74.3177 84.8942 72.6897 82.6641 71.3297C80.434 72.6897 76.9463 74.3177 73.4903 75.5973C70.3487 76.7599 65.93 78.1272 61.9621 78.3758Z"
        fill="black"
      />
      <path
        d="M80.2269 99.1314C79.9149 99.1314 79.603 99.012 79.3641 98.7756L74.4896 93.9011C74.0144 93.4258 74.0144 92.6532 74.4896 92.178C74.9649 91.7027 75.7375 91.7027 76.2128 92.178L80.2245 96.1897L91.5479 84.8662C92.0232 84.3909 92.7958 84.3909 93.2711 84.8662C93.7463 85.3415 93.7463 86.1141 93.2711 86.5893L81.0848 98.7756C80.846 99.0144 80.534 99.1314 80.222 99.1314H80.2269Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_2442_39339">
        <rect width={48.745} height={48.745} fill="white" transform="translate(59.5103 68.6658)" />
      </clipPath>
    </defs>
  </Icon>
);
