import React, { useState } from 'react';
import { logger } from '@sendpayments/js-utils/dist/services/logger';
import MfaReminderModal from '@send-components/MfaReminderModal';
import { VerifyCodeModal } from '@send-components/VerifyCodeModal';
import MFAHelper from '@send-components/Account/Security/MFA/MFAHelper';
import { triggerToast } from '@send-components/Toast';
import { useRegistration } from '@send-data-hooks';

/**
 * @typedef {"hidden" | "shown" | "confirm" | "sendCode"} MfaState
 */

/**
 * A lightweight component that can be used for MFA setup on generic
 * pages.
 *
 * @param {{
 *  state: MfaState;
 *  setState: (state: MfaState) => void;
 *  account: any; user: any
 *  onCancel: () => void;
 * }} props
 */
function MfaStandalone({ state, setState, account, user, onCancel }) {
  const [error, setError] = useState('');

  /**
   * Function that sends a code and then updates the internal state of
   * the component accordingly.
   */
  async function sendCode() {
    try {
      setState('confirm');
      await MFAHelper.getVerifyCode(user, account);
    } catch (err) {
      logger.error('Dashboard - MFA', 'Error resending code', err);
      triggerToast({ type: 'error', message: 'There was an error sending the code. Please contact support.' });
      setState('hidden');
    }
  }

  const [verifyModalIsOpen, setVerifyModalIsOpen] = useState(true);
  const mfaIsEnabled = false; // If this component is called, MFA should not be enabled
  const [{ data: registration }] = useRegistration();
  const phoneNumber = `${account.phoneCountryCode ? account.phoneCountryCode : ''}${account.phone}`;

  // Don't show modal if user hasn't started registration
  if (!registration.sendId) {
    return null;
  }

  if (state === 'shown') {
    return (
      <MfaReminderModal
        isOpen
        onClose={() => {
          logger.log('MfaReminderModal', 'closing...');
          setState('hidden');
        }}
        onAccept={() => {
          logger.log('Dashboard -- MFA reminder', 'attempting to enable MFA for user');
          setState('sendCode');
        }}
        onCancel={() => {
          logger.log('MfaReminderModal', 'Turned off MFA reminder...');
          onCancel();
          setState('hidden');
        }}
        title="Protect your account with 2&#8209;step verification"
      >
        <p>Each time you sign in or update your Send account, you’ll need your password and a verification code.</p>
        <p style={{ fontWeight: 'bold' }}>Would you like to use your phone {phoneNumber} as another form of authentication?</p>
      </MfaReminderModal>
    );
  }

  if (state === 'sendCode') {
    sendCode();
  }

  if (state === 'confirm') {
    return (
      <VerifyCodeModal
        isOpen={verifyModalIsOpen}
        title="We’ve just sent you an SMS"
        description={`To log in, please enter the 6-digit security <strong>code we sent to ${phoneNumber}.</strong>`}
        onCodeComplete={async (input) => {
          setError('');

          try {
            await MFAHelper.onCompleteCodeChanged(input, user, account, mfaIsEnabled);
          } catch (err) {
            logger.error('MFA', 'fail to verify code', err);
            setError('The security code entered is not correct. Please try again');
            return;
          }

          // Request succeeded
          setVerifyModalIsOpen(false);

          triggerToast({
            type: 'success',
            message: `2-step verification is now turned on.`,
          });
        }}
        onResendCode={async () => {
          await sendCode();
        }}
        onDismiss={() => {
          setVerifyModalIsOpen(false);
        }}
        error={error}
      />
    );
  }

  return null;
}

export default React.memo(MfaStandalone);
