import React from 'react';
import classNames from 'classnames';
import { Modal, Button, Col, Row } from 'antd';
import styles from '../Confirmation.module.less';
import modalStyles from './VRNConfirmation.module.less';
import { BankDetails } from '@send-components/Icons';
import { Link as ChakraLink } from '@chakra-ui/react';
import { appConfig } from '@send-base/app.config';

const ButtonWrapper = ({ okButtonText, onOkClick }) => {
  return (
    <div className={styles.buttonWrapper}>
      <Button className={styles.button} key="primary" type="primary" onClick={onOkClick}>
        {okButtonText}
      </Button>
    </div>
  );
};

export const Confirmation = ({ hidCloseCross, okButtonText, onOkClick }) => (
  <Modal
    wrapClassName={classNames(modalStyles.container, hidCloseCross && styles.hideCross)}
    visible
    onOk={onOkClick}
    closable={false}
    maskClosable={false}
    centered
    footer={[<ButtonWrapper key="buttonWrapper" okButtonText={okButtonText} onOkClick={onOkClick} />]}
    width={480}
  >
    <Col>
      <Row align="middle" justify="center" type="flex">
        <BankDetails with="104px" height="104px" />
      </Row>
      <h1 style={{ marginBottom: 24, marginTop: 24, color: '#000000' }}>Updated Bank Details</h1>
      <div>
        <p style={{ fontSize: 16 }}>
          We’ve updated our AUD pay in account details, please ensure that you update your records and send funds to settle this
          transaction to the correct account.
        </p>
        <p style={{ fontSize: 16 }}>
          Further information can be found{' '}
          <ChakraLink as="a" target="_blank" fontSize="16px" fontWeight={700} pr={1} href={appConfig.sendPaymentMethods}>
            here
          </ChakraLink>
          .
        </p>
        <p style={{ fontSize: 16 }}>Please acknowledge these changes before proceeding to payment.</p>
      </div>
    </Col>
  </Modal>
);
