import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Heading,
  Input,
  InputGroup,
  Link,
  SimpleGrid,
  Spinner,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import {
  accountDetailFields,
  getBankDetailsValidation,
  institutionDetailFields,
} from '@send-services/beneficiary/getBankDetailsValidation';
import { Beneficiary } from '@sendpayments/graphql/types';

const BeneEditGridItem = ({ formik, fieldName, text }: { formik: any; fieldName: string; text: string }) => {
  return (
    <FormControl isInvalid={!!formik.errors[fieldName] && !!formik.touched[fieldName]}>
      <FormLabel> {text} </FormLabel>
      <InputGroup>
        <Input
          id={fieldName}
          name={fieldName}
          value={formik.values[fieldName]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </InputGroup>
      <FormErrorMessage> {formik.errors[fieldName]} </FormErrorMessage>
    </FormControl>
  );
};

interface BeneficiaryEditFormProps {
  beneData: Beneficiary;
  onSubmit: (value: any) => Promise<void>;
  onClose: () => void;
  submitLoading: boolean;
}
export const BeneficiaryEditForm: React.FC<BeneficiaryEditFormProps> = ({ beneData, onSubmit, onClose, submitLoading }) => {
  const generateSchema = async () => {
    const beneRequirements = await getBankDetailsValidation({ beneficiary: beneData });
    const generateFieldValidation = ({ fieldName, message = 'invalid format' }: { fieldName: string; message?: string }) => {
      return yup.lazy(() =>
        beneRequirements[fieldName]
          ? yup
              .string()
              .required('This is a required field')
              .matches(new RegExp(beneRequirements[fieldName] as string), message)
          : yup.mixed(),
      );
    };
    return yup.object({
      bsbCode: generateFieldValidation({ fieldName: 'bsbCode' }),
      bicSwiftCode: generateFieldValidation({ fieldName: 'bicSwiftCode' }),
      sortCodeNumber: generateFieldValidation({ fieldName: 'sortCodeNumber' }),
      iban: generateFieldValidation({ fieldName: 'iban' }),
      clabeNumber: generateFieldValidation({ fieldName: 'clabeNumber' }),
      cnapsNumber: generateFieldValidation({ fieldName: 'cnapsNumber' }),
      abaCode: generateFieldValidation({ fieldName: 'abaCode' }),
      accountNumber: generateFieldValidation({ fieldName: 'accountNumber' }),
      bankName: generateFieldValidation({ fieldName: 'bankName' }),
      bankCode: generateFieldValidation({ fieldName: 'bankCode' }),
      ifscNumber: generateFieldValidation({ fieldName: 'ifscNumber' }),
    });
  };
  const [beneEditSchema, setBeneEditSchema] = useState<yup.AnyObjectSchema>();
  useEffect(() => {
    generateSchema().then((data) => setBeneEditSchema(data));
  }, []);
  const formik = useFormik({
    initialValues: {
      ...beneData,
    },
    validationSchema: beneEditSchema,
    onSubmit,
  });
  return (
    <>
      <Heading>Edit Recipient</Heading>
      <Text as="span">Only the recipient&#39;s banking details can be updated. </Text>

      {beneData?.businessName ? (
        <SimpleGrid columns={{ base: 1, md: 2 }}>
          <GridItem padding={'8px'}>
            <FormControl>
              <FormLabel>Company Name</FormLabel>
              <Input type="text" value={beneData.businessName || ''} disabled />
            </FormControl>
          </GridItem>
          <GridItem padding={'8px'}>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input type="text" value={beneData.email || ''} disabled />
            </FormControl>
          </GridItem>
        </SimpleGrid>
      ) : (
        <SimpleGrid columns={{ base: 2, md: 4 }}>
          <GridItem padding={'8px'}>
            <FormControl>
              <FormLabel>First Name</FormLabel>
              <Input type="text" value={beneData.firstName || ''} disabled />
            </FormControl>
          </GridItem>
          <GridItem padding={'8px'}>
            <FormControl>
              <FormLabel>Last Name</FormLabel>
              <Input type="text" value={beneData.lastName || ''} disabled />
            </FormControl>
          </GridItem>
          <GridItem colSpan={2} padding={'8px'}>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input type="text" value={beneData.email || ''} disabled />
            </FormControl>
          </GridItem>
        </SimpleGrid>
      )}

      <SimpleGrid columns={{ base: 2, md: 4 }}>
        <GridItem padding={'8px'}>
          <FormControl>
            <FormLabel>Unit #</FormLabel>
            <Input type="text" value={beneData.streetAddressUnitNumber || ''} disabled />
          </FormControl>
        </GridItem>
        <GridItem padding={'8px'}>
          <FormControl>
            <FormLabel>Street #</FormLabel>
            <Input type="text" value={beneData.streetAddressNumber || ''} disabled />
          </FormControl>
        </GridItem>
        <GridItem padding={'8px'}>
          <FormControl>
            <FormLabel>Street Name</FormLabel>
            <Input type="text" value={beneData.streetAddressName || ''} disabled />
          </FormControl>
        </GridItem>
        <GridItem padding={'8px'}>
          <FormControl>
            <FormLabel>Street Type</FormLabel>
            <Input type="text" value={beneData.streetAddressType || ''} disabled />
          </FormControl>
        </GridItem>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 2, md: 4 }}>
        <GridItem padding={'8px'}>
          <FormControl>
            <FormLabel>City</FormLabel>
            <Input type="text" value={beneData.city || ''} disabled />
          </FormControl>
        </GridItem>
        <GridItem padding={'8px'}>
          <FormControl>
            <FormLabel>State</FormLabel>
            <Input type="text" value={beneData.state || ''} disabled />
          </FormControl>
        </GridItem>
        <GridItem padding={'8px'}>
          <FormControl>
            <FormLabel>Postal Code</FormLabel>
            <Input type="text" value={beneData.postcode || ''} disabled />
          </FormControl>
        </GridItem>
        <GridItem padding={'8px'}>
          <FormControl>
            <FormLabel>Country</FormLabel>
            <Input type="text" value={beneData.country || ''} disabled />
          </FormControl>
        </GridItem>
      </SimpleGrid>
      {/* Account details */}
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <SimpleGrid columns={2}>
            <GridItem padding={'8px'}>
              {Object.keys(institutionDetailFields).map(
                (field) =>
                  beneData[field] && <BeneEditGridItem formik={formik} fieldName={field} text={institutionDetailFields[field]} />,
              )}
            </GridItem>
            <GridItem padding={'8px'}>
              {Object.keys(accountDetailFields).map(
                (field) =>
                  beneData[field] && <BeneEditGridItem formik={formik} fieldName={field} text={accountDetailFields[field]} />,
              )}
            </GridItem>
          </SimpleGrid>
          <ButtonGroup flexDir="row" spacing={2} pt={2}>
            <Button border="none" _hover={{ cursor: 'pointer' }} type="submit" disabled={!formik.isValid}>
              {submitLoading ? <Spinner /> : 'Submit'}
            </Button>
            <Button _hover={{ cursor: 'pointer' }} onClick={onClose} mb={{ base: 0, md: 2 }} variant={'outline'}>
              Cancel
            </Button>
          </ButtonGroup>
        </form>
      </FormikProvider>
      <Box paddingTop={'15px'}>
        <Text fontWeight="600" fontSize="14px" pb="10px">
          Any changes made to recipient details won&apos;t affect payments that have already been processed
        </Text>
        <Text as="span" fontWeight="400" fontSize="10px">
          If you wish to change any further information please contact us via{' '}
          {
            <Link fontWeight="400" fontSize="10px" color={'#1890FF'}>
              1800 982 418
            </Link>
          }{' '}
          within Australia or{' '}
          {
            <Link fontWeight="400" fontSize="10px" color={'#1890FF'}>
              +617 5619 5700
            </Link>
          }{' '}
          when overseas.
        </Text>
      </Box>
    </>
  );
};
