import useInterval from 'use-interval';
import { post } from '@send-base/utilities/endpoint';
import { buildUniqueKey } from '@sendpayments/js-utils/dist/services/build-unique-key';
import { useRemoteData } from '@sendpayments/react-shared/hooks/useRemoteData';
import { useCurrentEmail } from '../useCurrentEmail';
import { CurrencyPairNotSupportedError } from '@send-base/exception';
const POLLING_INTERVAL = 1000 * 10;

const MAX_TIMEOUT_QUOTE_SECONDS = 120; // 2 minutes (Down from 30 minutes)

const fetchRate = async ({ fromCurrency, toCurrency }) => {
  try {
    if (!fromCurrency || !toCurrency) {
      return {
        rate: 0,
        invertedRate: 0,
      };
    }

    if (fromCurrency === toCurrency) {
      return {
        rate: 1,
        invertedRate: 1,
      };
    }

    const response = await post('/quote/exchange-rate', {
      data: {
        fromCurrency,
        toCurrency,
        amount: 1,
        ttl: MAX_TIMEOUT_QUOTE_SECONDS,
      },
    });

    const { rate, invertedRate, quoteId, conversionDate } = response;

    return {
      quoteId,
      conversionDate,
      rate,
      invertedRate,
    };
  } catch (ex) {
    if (ex?.response?.data === 'currency_pair_not_supported') {
      // API provides this
      throw new CurrencyPairNotSupportedError('Currency pair not supported.', ex);
    } else {
      throw new Error('Error fetching currency rate from backend.', ex);
    }
  }
};

const useRate = ({ fromCurrency, toCurrency }) => {
  const [{ data: userEmail }] = useCurrentEmail();

  const [result, { doFetch, invalidateKey }] = useRemoteData(
    () => ({
      key: buildUniqueKey('hook.useRate', { fromCurrency, toCurrency }),
      onInitialFetch: () => fetchRate({ fromCurrency, toCurrency }),
      initialState: { rate: 0, invertedRate: 0 },
    }),
    [fromCurrency, toCurrency, userEmail],
  );

  // TODO we will implement a queue connected to the WebSocket soon. For now poll for the update.
  useInterval(() => {
    if (result.fetched && !result.loading && !result.failed && fromCurrency && toCurrency && userEmail) {
      doFetch();
    }
  }, POLLING_INTERVAL);

  return [
    {
      ...result,
      // this is so we only show loading the first time we load the rate.
      loading: !result.failed && !result.fetched,
    },
    { updateRate: doFetch, releaseRate: invalidateKey },
  ];
};

export default useRate;
