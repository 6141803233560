import { useFetch } from '@sendpayments/react-shared/hooks';
import { query } from '@send-base/utilities/endpoint';
import * as queries from '@sendpayments/graphql/queries';

const fetchCoconut = async ({ id, type }) => {
  if (!id || !type) {
    return {};
  }

  const response = await query(queries.getCoconut, {
    params: { id, type },
  });

  return response;
};

const useCoconut = ({ id, type }) =>
  useFetch({ id, type }, fetchCoconut, {
    initialState: {},
  });

export default useCoconut;
