/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_content_delivery_bucket": "portal-20191010092833-hostingbucket-tmozprod",
    "aws_content_delivery_bucket_region": "ap-southeast-2",
    "aws_content_delivery_url": "https://d3l1dq5moe5jac.cloudfront.net"
};


export default awsmobile;
