import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Grid,
  Box,
  ButtonGroup,
} from '@chakra-ui/react';

interface ButtonType {
  onClick: () => void;
  isLoading?: boolean;
  text: string;
}
export interface ActionableModalProps {
  isOpen: boolean;
  onClose: () => void;
  actions: {
    primary: ButtonType;
    secondary?: ButtonType;
  };
  children: React.ReactNode;
  disableCloseButton?: boolean;
}

export const ActionableModal = ({
  isOpen,
  onClose = () => {},
  actions,
  children,
  disableCloseButton = false,
}: ActionableModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW={{ base: '400px', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' }} mx={{ base: 5, sm: 0 }}>
        <Grid>
          <Box w="auto">
            <ModalCloseButton isDisabled={disableCloseButton} background="border.light" borderRadius="100%" />
          </Box>

          <ModalBody w="auto" textAlign="center" mt={8}>
            {children}
          </ModalBody>

          <ModalFooter w="auto" justifyContent="center" pt={0} mt={{ base: 2, sm: 4 }} mb={4}>
            <ButtonGroup w="100%" flexDir="column" spacing={{ base: 0, sm: 2 }}>
              <Button w={'inherit'} isLoading={actions.primary.isLoading} onClick={actions.primary.onClick}>
                {actions.primary.text}
              </Button>
              {actions?.secondary && (
                <Button
                  w={'inherit'}
                  onClick={actions.secondary?.onClick}
                  variant={'outline'}
                  isLoading={actions.secondary?.isLoading}
                  mt={{ base: 2, sm: 4 }}
                >
                  {actions.secondary?.text}
                </Button>
              )}
            </ButtonGroup>
          </ModalFooter>
        </Grid>
      </ModalContent>
    </Modal>
  );
};
