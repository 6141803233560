import React from 'react';
import { Text, Stack } from '@chakra-ui/react';
import { SocialLogin } from '@send-base/atomic-components/molecules';
import { AlertBoxData, RegisterFormValues } from '@send-base/atomic-components/pages';
import { AlertBox } from '@sendpayments/react-shared/components/molecules';
import { ComparisonRegistrationTemplate } from '@send-base/atomic-components/templates/ComparisonRegistrationTemplate';
import { ComparisonRegisterForm } from '@send-base/atomic-components/organisms/forms/ComparisonRegisterForm/ComparisonRegisterForm';
import { TrustPilotWidget } from '@send-base/atomic-components/molecules/TrustPilotWidget';

export interface RegistrationStackProps {
  handleSubmit(values: RegisterFormValues): Promise<void> | void;
  isLoading: boolean;
  onSocialLogin(provider: 'Google' | 'Facebook'): void;
  changeState(str: string): void;
  alertBox: AlertBoxData;
}

const RegistrationFormStack: React.FC<RegistrationStackProps> = ({
  handleSubmit,
  isLoading,
  onSocialLogin,
  changeState,
  alertBox,
}) => {
  return (
    <ComparisonRegistrationTemplate heading={'Fast fee-free international money transfers'} leftChildren={<TrustPilotWidget />}>
      <Stack spacing={8} mb={15}>
        <Text align="center" mt={'18px'}>
          <Text as="span" fontSize="lg" lineHeight={1.375}>
            {'Create an account, access live rates and save money with our award winning solution.'}
          </Text>
        </Text>
        {!!alertBox.message && (
          <AlertBox
            maxW="100%"
            my="4"
            title=""
            alignSelf="center"
            variant={alertBox.type === 'success' ? 'neutral' : 'negative'}
            description={alertBox.message}
            showIcon
          />
        )}
        <ComparisonRegisterForm onSubmit={handleSubmit} isLoading={isLoading} initialValues={{ email: '', password: '' }} />
        <SocialLogin content="Or" onSocialLogin={onSocialLogin} />
        <Text align="center" fontSize={'lg'}>
          <Text as="span" color={'text.base'}>
            {'Already registered? '}
          </Text>
          <Text as="a" fontWeight="semibold" color="primary.base" onClick={() => changeState('signIn')}>
            Log in
          </Text>
        </Text>
      </Stack>
    </ComparisonRegistrationTemplate>
  );
};

export { RegistrationFormStack };
