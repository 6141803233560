import { Button, Result } from 'antd';
import React from 'react';

const ErrorPage = () => (
  <Result
    status="500"
    title="500"
    subTitle="Oops, there is something wrong."
    extra={
      <Button type="primary" onClick={() => (window.location = `${window.location.origin}/dashboard`)}>
        Back Home
      </Button>
    }
  />
);

export default ErrorPage;
