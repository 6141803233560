import React from 'react';
import { BaseLayout } from '@send-legacy-containers/BaseLayout';
import { DocumentList } from '@send-legacy-containers/Documents';
import { usePendingDocuments, useUploadedDocuments, useCurrentEmail } from '@send-data-hooks';

const subtitleMapping = {
  emptyList:
    "There's nothing to show here yet. In some cases, we might ask for documents to verify transactions - this is where you can upload them.",
  uploadDoc:
    'Please upload the documents requested below. You can upload up to 10 files to support each request. We accept .pdf, .jpeg, .jpg, .png, .bmp, .gif, .doc or .docx formats. Each file must be less than 10Mb in size. ',
  caughtUp: "You're all caught up! There are no new documents required here, but you can view your previous uploads below.",
};

const Document = () => {
  const [{ data: userEmail }] = useCurrentEmail();
  const [{ data: pendingDocs, loading: loadingDocuments }] = usePendingDocuments({ owner: userEmail });
  const [{ data: acceptedDocs }] = useUploadedDocuments({ owner: userEmail });

  let subtitle = '';
  if (pendingDocs.length) {
    subtitle = subtitleMapping.uploadDoc;
  } else if (acceptedDocs.length) {
    subtitle = subtitleMapping.caughtUp;
  } else {
    subtitle = subtitleMapping.emptyList;
  }

  return (
    <BaseLayout title="My Documents" subtitle={subtitle} name="documents" loading={loadingDocuments}>
      <DocumentList />
      <div />
    </BaseLayout>
  );
};

export default Document;
