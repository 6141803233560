import { useRate } from '@send-base/infrastructure/query/useRate';
import { Registration } from '@send-base/types';
import { Setting } from '@send-base/infrastructure/query/useSetting';
import { Currency } from '@send-base/infrastructure/query/useCurrencies';

import { exchangeWidgetConfig } from './config';
import { calculateMaxAmount, extractDecimalPlaces, getToAmount } from './currency.utils';

export const useAudExchangeAmountLimit = ({
  currencies,
  registration,
  setting,
  currencyCode,
  onError,
  enabled,
  ttl,
}: {
  currencies: Currency[];
  registration: Registration;
  setting: Setting;
  currencyCode: string;
  enabled;
  ttl?: number;
  onError: (error: any) => void;
}) => {
  const { data, isLoading, isFetching, error } = useRate({
    params: { fromCurrency: currencyCode, toCurrency: 'AUD', amount: 1 },
    options: {
      enabled: enabled,
      staleTime: exchangeWidgetConfig.rateQuery.staleTime,
      refetchOnWindowFocus: true,
      onError: onError,
    },
  });

  const audExchangeRate = currencyCode === 'AUD' ? { rate: 1 } : data;

  const verifyLimit = (value: number) => {
    const rate = (currencyCode === 'AUD' ? { rate: 1 } : data)?.rate;
    const amount = getToAmount({
      fromAmount: value,
      rate: rate,
      toDecimalPlaces: extractDecimalPlaces(currencyCode, currencies),
    });
    const maxAmount = calculateMaxAmount(registration, setting);
    if (amount < setting.customerMinTradeValue) {
      return {
        valid: false,
        message: exchangeWidgetConfig.messages.minAmountError,
      };
    }
    if (amount > maxAmount) {
      return {
        valid: false,
        message: exchangeWidgetConfig.messages.maxAmountError,
      };
    }
    return {
      valid: true,
    };
  };

  return {
    data: audExchangeRate,
    isLoading,
    isFetching,
    verifyLimit,
    error,
  };
};
