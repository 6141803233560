import * as React from 'react';
import { SignUp } from 'aws-amplify-react';
import { RegisterPage } from '@send-base/atomic-components/pages';
import { ISignUpProps } from 'aws-amplify-react/lib-esm/Auth/SignUp';

class CustomSignUp extends SignUp {
  constructor(props: ISignUpProps) {
    super(props);
  }

  showComponent(): JSX.Element {
    return <RegisterPage changeState={this.changeState} />;
  }
}

export default CustomSignUp;
