import { useContext } from 'react';
import { AppContext } from '@send-legacy-app/Context';

const usePermission = (type) => {
  const { permissions } = useContext(AppContext);

  const permissionsSelected = permissions && permissions[type];

  return [permissionsSelected];
};

export default usePermission;
