import React from 'react';
import { Flex, Spinner, Stack, Text } from '@chakra-ui/react';

interface SpinnerOverlayProps {
  text?: string;
}
export const SpinnerOverlay = ({ text }: SpinnerOverlayProps) => {
  return (
    <Flex
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height="100%"
      bg="rgba(0, 0, 0, 0.4)"
      alignItems="center"
      justifyContent="center"
      cursor="default"
      zIndex={6} // ensure overlay appears above our <SupportCard /> component (which has a z-index of 5)
      onClick={(e) => {
        // prevent interaction with the ui
        e.stopPropagation();
      }}
    >
      <Stack spacing={8} align="center">
        <Spinner color="white" size="xl" />
        <Text ml={2} fontSize={{ base: 'lg', md: 'xl' }} fontWeight={600} color="white">
          {text}
        </Text>
      </Stack>
    </Flex>
  );
};
