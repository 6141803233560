/* eslint-disable react/jsx-one-expression-per-line */
import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button as ButtonAntd, InputNumber, Tooltip, Spin } from 'antd';
import { CurrenciesDropDown, Icon } from '@send-legacy-components/Common';
import { getCurrencyString } from '@sendpayments/js-utils/dist/utils/currency';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { appConfig } from '@send-base/app.config';
import { withAnalytics } from '@sendpayments/react-shared/HOC/withAnalytics';
import styles from './Widget.module.less';

const { Button } = withAnalytics({ Button: ButtonAntd }, { info: 'ExchangeWidget' });

const { australianPhoneNumber, internationalPhoneNumber } = appConfig;

const buildCurrenciesList = (list, { excludeCurrencyName, popularCurrencies }) =>
  list.reduce(
    (acc, currencySetting) => {
      const [currencyName, currencyCountry] = currencySetting.split(' - ');
      const entry = {
        currencyName: currencyName.toLowerCase(),
        currencyCountry,
      };
      if (!popularCurrencies.includes(currencyName)) {
        if (currencyName !== excludeCurrencyName) {
          acc.currenciesDropDownDataSource.push(entry);
        }
        return acc;
      }
      if (currencyName !== excludeCurrencyName) {
        acc.popularCurrenciesDropDownDataSource.push(entry);
      }
      return acc;
    },
    { currenciesDropDownDataSource: [], popularCurrenciesDropDownDataSource: [] },
  );

const showBottomSection = ({ showCollapsible, hiddenWidget }) => {
  if (!showCollapsible) {
    return true;
  }
  return !hiddenWidget;
};

const getPhoneText = ({ hasAnyError, isInternational }) => {
  const start = hasAnyError ? 'Call' : 'Book';

  if (isInternational) {
    return `${start} now ${internationalPhoneNumber}`;
  }

  return `${start} now ${australianPhoneNumber}`;
};

const ExchangeCurrencyWidget = ({
  loading,
  fromCurrency,
  fromDecimalPlaces,
  toCurrency,
  toDecimalPlaces,
  isToAmountLocked,
  fromAmount,
  toAmount,
  rate,
  bankComparisonAmount,
  currencies,
  popularCurrencies,
  onRequestCallbackClick,
  onBookClick,
  onFieldChange,
  hiddenWidget,
  showCollapsible,
  showBookButton,
  onCollapseToggle,
  dropDownClassName,
  className,
  audToAmount,
  minAmount,
  maxAmount,
  isInternational,
  isOutOfHours,
  loadingRequestCallback,
  requestCallbackAccepted,
  hasRateError,
  isBlacklistedCurrency,
  invalidCurrencyPair,
}) => {
  const { fromCurrencies, toCurrencies } = useMemo(() => {
    const fromCurrenciesLists = buildCurrenciesList(currencies, { popularCurrencies });
    const toCurrenciesLists = buildCurrenciesList(currencies, { popularCurrencies });
    return {
      fromCurrencies: fromCurrenciesLists,
      toCurrencies: toCurrenciesLists,
    };
  }, [currencies, fromCurrency, toCurrency]);

  const onChangeFromAmountDebouncedHandler = useCallback(
    debounce(
      (value) => {
        onFieldChange('fromAmount', Number.parseFloat(value));
      },
      250,
      { maxWait: 1000, trailing: true },
    ),
  );

  const onChangeToAmountDebouncedHandler = useCallback(
    debounce(
      (value) => {
        onFieldChange('toAmount', Number.parseFloat(value));
      },
      250,
      { maxWait: 1000, trailing: true },
    ),
  );

  const formatNumber = (n, decimalPlaces) => {
    const numberString = n.toString();
    const [integer, decimal] = numberString.split('.');
    if (!decimal) {
      return `${numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    }

    const startFormattedNumber = `${integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

    if (decimalPlaces > 0) {
      return `${startFormattedNumber}.${decimal.substring(0, 2)}`;
    }

    return startFormattedNumber;
  };

  const showMinAmountError = audToAmount !== undefined && minAmount ? !loading && audToAmount < minAmount : false;
  const showMaxAmountError = audToAmount !== undefined && maxAmount ? !loading && audToAmount > maxAmount : false;
  const hasAnyError = showMinAmountError || showMaxAmountError || hasRateError || isBlacklistedCurrency;

  return (
    <div className={classNames(className, !hiddenWidget && loading && styles.isSpinning)}>
      {showCollapsible && (
        <div
          className={classNames(styles.collapseSection, !hiddenWidget && styles.withCollapsibleSectionVisible)}
          onClick={onCollapseToggle}
        >
          <span>Currency Converter</span> <Icon type={hiddenWidget ? 'down' : 'up'} />
        </div>
      )}
      {showBottomSection({ showCollapsible, hiddenWidget }) && (
        <>
          <div className={classNames(styles.container, showCollapsible && styles.withCollapsibleSection)}>
            <Spin spinning={loading} wrapperClassName={classNames(loading && styles.isSpinning)}>
              <div
                className={classNames(
                  styles.currencySelect,
                  styles.fromCurrency,
                  styles.inputNumber,
                  styles.expandSelectAction,
                  (showMinAmountError || showMaxAmountError) && !hasRateError && styles.hasError,
                )}
              >
                <div className={styles.maxWith}>
                  <div className={styles.text}>You send</div>
                  <div style={{ display: 'flex' }}>
                    <CurrenciesDropDown
                      style={{ marginRight: '5px' }}
                      currencies={fromCurrencies.currenciesDropDownDataSource}
                      popularCurrencies={fromCurrencies.popularCurrenciesDropDownDataSource}
                      selectedCurrency={fromCurrency}
                      onCurrencyChanged={(currencyName) => onFieldChange('fromCurrency', currencyName.toUpperCase())}
                      dropDownClassName={dropDownClassName}
                    />
                    {!loading && (
                      <InputNumber
                        min={0}
                        step={1}
                        value={fromAmount}
                        formatter={(value) => value && formatNumber(value, fromDecimalPlaces)}
                        parser={(value) => value && value.replace(/[^0-9.]/g, '')}
                        onChange={onChangeFromAmountDebouncedHandler}
                      />
                    )}
                  </div>
                  {loading && <InputNumber />}
                  {showMinAmountError && !hasRateError && <p>This amount is below the minimum transfer value.</p>}
                  {showMaxAmountError && !hasRateError && (
                    <p>This exceeds the maximum online transfer amount, please call your account manager to book.</p>
                  )}
                  {hasRateError &&
                    (invalidCurrencyPair ? (
                      <p className={styles.hasErrorApi}>
                        This currency pair may not be supported. Please call your Account Manager for help.
                      </p>
                    ) : (
                      <p className={styles.hasErrorApi}>
                        Unable to retrieve rate data. Please refresh the page or contact your Account Manager for further
                        assistance.
                      </p>
                    ))}
                </div>
              </div>
              <div className={styles.rate}>
                <span>Rate {rate !== '0' ? rate : ''} </span>
                {'  '}
                <Tooltip
                  title="This amount is based on the market rate. As live rates vary minute to minute, the amount
                 indicated here may differ to your final transfer amount."
                >
                  <Icon type="exclamation-circle" />
                </Tooltip>
              </div>
              <div className={classNames(styles.toCurrency, !bankComparisonAmount && styles.noBankComparisonAmount)}>
                <div className={classNames(styles.inputNumber, styles.expandSelectAction)}>
                  <div className={styles.maxWith}>
                    <div className={styles.text}>Recipient gets</div>
                    <div style={{ display: 'flex' }}>
                      <CurrenciesDropDown
                        style={{ marginRight: '5px' }}
                        currencies={toCurrencies.currenciesDropDownDataSource}
                        popularCurrencies={toCurrencies.popularCurrenciesDropDownDataSource}
                        selectedCurrency={toCurrency}
                        onCurrencyChanged={(currencyName) => onFieldChange('toCurrency', currencyName.toUpperCase())}
                        dropDownClassName={dropDownClassName}
                      />
                      {!loading && (
                        <InputNumber
                          min={0}
                          step={1}
                          formatter={(value) => value && formatNumber(value, toDecimalPlaces)}
                          parser={(value) => value && value.replace(/[^0-9.]/g, '')}
                          value={toAmount}
                          onChange={onChangeToAmountDebouncedHandler}
                        />
                      )}
                    </div>
                    {loading && <InputNumber />}
                    <div className={styles.lockingAmount}>
                      {isToAmountLocked && (
                        <Tooltip
                          title={`We will send your recipient ${getCurrencyString({
                            amount: toAmount,
                            currency: toCurrency,
                            currencyDecimals: toDecimalPlaces,
                          })}.`}
                        >
                          <Icon className={styles.lock} type="lock" />
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
                {showBookButton && (
                  <div className={styles.buttonContainer}>
                    {!isBlacklistedCurrency && !showMinAmountError && !showMaxAmountError && !hasRateError && (
                      <Button
                        name="BookNow"
                        disabled={hasAnyError}
                        className={styles.buttonCall}
                        type="primary"
                        onClick={onBookClick}
                      >
                        Exchange Now
                      </Button>
                    )}
                    {hasAnyError && (
                      <>
                        {isOutOfHours && !hasRateError && (
                          <Button
                            name="Callback"
                            type="secondary"
                            loading={loadingRequestCallback}
                            disabled={loadingRequestCallback || requestCallbackAccepted}
                            className={classNames(styles.buttonCall, requestCallbackAccepted && styles.requestSent)}
                            onClick={onRequestCallbackClick}
                          >
                            <a>
                              {!loadingRequestCallback && !requestCallbackAccepted && <Icon type="phone" />}
                              {!loadingRequestCallback && requestCallbackAccepted && <Icon type="check" />} Request{' '}
                              {(!requestCallbackAccepted && 'callback') || 'sent'}
                            </a>
                          </Button>
                        )}
                        {(!isOutOfHours || hasRateError) && isInternational && (
                          <Button name="PhoneInternational" type="secondary" className={styles.buttonCall}>
                            <a href={`tel:${internationalPhoneNumber.replace(/\s+/g, '')}`}>
                              <Icon type="phone" /> {getPhoneText({ isInternational, hasAnyError })}
                            </a>
                          </Button>
                        )}
                        {(!isOutOfHours || hasRateError) && !isInternational && (
                          <Button name="PhoneAustralia" type="secondary" className={styles.buttonCall}>
                            <a href={`tel:${australianPhoneNumber.replace(/\s+/g, '')}`}>
                              <Icon type="phone" /> {getPhoneText({ isInternational, hasAnyError })}
                            </a>
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
              {bankComparisonAmount > 0 && (
                <div className={styles.bankComparison}>
                  <div>You could save</div>
                  <div className={styles.bankComparisonNumber}>
                    {getCurrencyString({
                      amount: bankComparisonAmount,
                      currency: fromCurrency,
                      currencyDecimals: fromDecimalPlaces,
                    })}
                    *
                  </div>
                </div>
              )}
            </Spin>
          </div>
          {showBookButton && (
            <div className={styles.tc}>
              *Based on an industry average of figures publicly advertised by the major banks. Please note the rate you could
              achieve with these providers may vary depending on availability and personal circumstances.
            </div>
          )}
          {!showBookButton && (
            <div className={styles.tc}>
              *Based on an industry average of figures publicly advertised by the major banks. Please note the above figures are
              based on indicative wholesale rates, and live rates will be available once registration is completed, which may
              differ from the above.
            </div>
          )}
        </>
      )}
    </div>
  );
};

ExchangeCurrencyWidget.propTypes = {
  // data
  fromCurrency: PropTypes.string,
  fromDecimalPlaces: PropTypes.number,
  toCurrency: PropTypes.string,
  toDecimalPlaces: PropTypes.number,
  fromAmount: PropTypes.number,
  toAmount: PropTypes.number,
  rate: PropTypes.string,
  bankComparisonAmount: PropTypes.number,
  currencies: PropTypes.arrayOf(PropTypes.string).isRequired,
  popularCurrencies: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  dropDownClassName: PropTypes.string,
  audToAmount: PropTypes.number,
  minAmount: PropTypes.number,
  maxAmount: PropTypes.number,

  // indicators
  loading: PropTypes.bool,
  hiddenWidget: PropTypes.bool,
  showCollapsible: PropTypes.bool,
  showBookButton: PropTypes.bool,
  isInternational: PropTypes.bool,
  isOutOfHours: PropTypes.bool,
  loadingRequestCallback: PropTypes.bool,
  requestCallbackAccepted: PropTypes.bool,
  hasRateError: PropTypes.bool,
  isBlacklistedCurrency: PropTypes.bool,
  invalidCurrencyPair: PropTypes.bool,
  isToAmountLocked: PropTypes.bool,

  // callbacks
  onBookClick: PropTypes.func.isRequired,
  onRequestCallbackClick: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onCollapseToggle: PropTypes.func,
};

ExchangeCurrencyWidget.defaultProps = {
  loading: true,
  isToAmountLocked: false,
  fromCurrency: 'AUD',
  fromDecimalPlaces: 2,
  toCurrency: 'USD',
  toDecimalPlaces: 2,
  fromAmount: 500,
  toAmount: undefined,
  rate: '0',
  bankComparisonAmount: undefined,
  showCollapsible: true,
  showBookButton: false,
  hiddenWidget: false,
  onCollapseToggle: () => {},
  className: undefined,
  dropDownClassName: undefined,
  audToAmount: undefined,
  minAmount: undefined,
  maxAmount: undefined,
  isInternational: true,
  isOutOfHours: false,
  loadingRequestCallback: false,
  requestCallbackAccepted: false,
  hasRateError: false,
  isBlacklistedCurrency: false,
};

export default ExchangeCurrencyWidget;
