const getLastFourDigits = (value: string) => {
  return value?.substring(value.length - 4, value.length);
};

interface IdentifierOptions {
  accountNumber?: string;
  iban?: string;
  clabeNumber?: string;
}

export const getMaskedIdentifierText = (identifierOptions: IdentifierOptions) => {
  if (!identifierOptions) {
    throw new Error('Identifier options are required');
  }

  const { accountNumber, iban, clabeNumber } = identifierOptions;

  if (accountNumber) {
    return `Account ending in ${getLastFourDigits(accountNumber)}`;
  }

  if (iban) {
    return `IBAN ending in ${getLastFourDigits(iban)}`;
  }

  if (clabeNumber) {
    return `CLABE ending in ${getLastFourDigits(clabeNumber)}`;
  }

  return '';
};
