import React from 'react';
import { Text, Link, Box, BoxProps } from '@chakra-ui/react';
import { appConfig } from '@send-base/app.config';

const Footer: React.FC<BoxProps> = (props) => {
  return (
    <Box align="center" lineHeight="1" w="100%" {...props}>
      <Text fontSize="xs" as="span" fontWeight="semibold" pr={1}>
        {appConfig.companyName}
      </Text>
      <Text fontSize="xs" as="span" pr={1}>
        {appConfig.companyACN}
      </Text>
      <Link fontSize="xs" as="a" color="text.base" pr={1} href={appConfig.sendTermsAndConditionsUrl} isExternal>
        Terms and Conditions
      </Link>
      <Link fontSize="xs" as="a" color="text.base" pr={1} href={appConfig.sendPrivacyAndPolicyUrl} isExternal>
        Privacy Policy
      </Link>
      <Link fontSize="xs" as="a" color="text.base" pr={1} href={appConfig.sendFinancialServicesGuideUrl} isExternal>
        Financial Services Guide
      </Link>
      <Link fontSize="xs" as="a" color="text.base" href={appConfig.sendProductDisclosureStatementUrl} isExternal>
        Product Disclosure Statements
      </Link>
    </Box>
  );
};

export { Footer };
