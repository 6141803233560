import React, { useEffect } from 'react';
import { Flex } from '@adobe/react-spectrum';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import logger from '@sendpayments/js-utils/dist/services/logger';
import styles from './Toast.module.less';
import 'react-toastify/dist/ReactToastify.css';
import { CloseIcon } from '@chakra-ui/icons';

const CustomToastCloseButton = ({ closeToast }) => {
  return (
    <div onClick={closeToast} className={styles.closeButton}>
      <CloseIcon />
    </div>
  );
};

const Toast = ({ message, rightIcon, link }) => {
  const iconContainerRef = React.useRef(null);

  useEffect(() => {
    const icon = iconContainerRef.current?.querySelector('svg');

    // some icons need to have their viewBox property adjusted to display correctly
    // adobe-spectrum seems to leave us no choice but to do it this way
    if (icon) {
      icon.viewBox.baseVal.x = 0;
      icon.viewBox.baseVal.y = 0;
      icon.viewBox.baseVal.width = 36;
      icon.viewBox.baseVal.height = 36;
    }
  }, [iconContainerRef.current]);

  const content = (
    <>
      {rightIcon && (
        <Flex
          UNSAFE_className={styles.leftButton}
          UNSAFE_style={{ minWidth: '22px', marginRight: '16px' }}
          justifyContent="center"
        >
          <div ref={iconContainerRef} style={{ width: '22px', height: '22px' }}>
            {rightIcon}
          </div>
        </Flex>
      )}
      <span className={styles.toastText}>{message}</span>
    </>
  );

  return (
    <Flex direction="row" alignItems="space-between" justifyContent="start">
      <Flex direction="row" alignItems="start" justifyContent="start">
        {link ? (
          <Link to={link} className={styles.link}>
            {content}
          </Link>
        ) : (
          content
        )}
      </Flex>
    </Flex>
  );
};

const ToastDialog = () => {
  return (
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      rtl={false}
      closeOnClick={false}
      closeButton={CustomToastCloseButton}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      style={{ width: 'auto', marginLeft: '12px' }}
      className={`${styles.childEl} ${styles.override}`}
    />
  );
};

/**
 * @param {{ type: import('react-toastify').TypeOptions; message: string; rightIcon?: JSX.Element }} props
 */
const triggerToast = ({ type, message, rightIcon, link }) => {
  logger.log('Toast', 'triggered toast');
  return toast(<Toast message={message} rightIcon={rightIcon} link={link} />, {
    type,
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    closeButton: CustomToastCloseButton,
  });
};

export { CustomToastCloseButton, ToastDialog, triggerToast };
