import { Button, ButtonProps } from '@chakra-ui/react';
import * as React from 'react';
import { Icon, IconProps } from '@chakra-ui/icons';

export type CurrencyExchangeSupportActionProps = {
  text: string;
  href?: string;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
};

export const CurrencyExchangeSupportAction = (props: CurrencyExchangeSupportActionProps & ButtonProps) => {
  const { href, text, onClick, isLoading, disabled, ...delegatedProps } = props;
  return href ? (
    <Button
      variant={'outline'}
      as={'a'}
      href={href}
      target={'_blank'}
      leftIcon={<PhoneIcon width={4} height={4} />}
      {...delegatedProps}
    >
      {text}
    </Button>
  ) : (
    <Button
      variant={'outline'}
      isLoading={isLoading}
      onClick={onClick}
      disabled={disabled}
      leftIcon={<PhoneIcon width={4} height={4} />}
      {...delegatedProps}
    >
      {text}
    </Button>
  );
};

const PhoneIcon = (props: IconProps) => (
  <Icon fill="currentColor" viewBox={'0 0 24 24'} {...props}>
    <g clipPath="url(#a)">
      <path
        d="M19.201 24c-2.125 0-4.386-.602-6.72-1.79-2.152-1.096-4.277-2.664-6.146-4.536-1.87-1.872-3.436-4-4.53-6.153C.619 9.186.017 6.925.017 4.8c0-1.378 1.284-2.708 1.834-3.216C2.645.854 3.893 0 4.8 0c.451 0 .979.295 1.664.929.51.473 1.085 1.113 1.66 1.853C8.47 3.228 10.2 5.507 10.2 6.6c0 .896-1.014 1.52-2.087 2.18-.415.254-.844.518-1.153.766-.331.265-.39.406-.4.437 1.139 2.839 4.62 6.32 7.458 7.458.025-.009.166-.064.436-.4.248-.31.512-.739.767-1.153.66-1.073 1.282-2.087 2.179-2.087 1.093 0 3.372 1.73 3.818 2.076.74.575 1.38 1.15 1.853 1.66.634.684.929 1.213.929 1.664 0 .907-.853 2.159-1.583 2.956C21.908 22.71 20.577 24 19.2 24L19.2 24ZM4.794 1.2c-.322.006-1.187.4-2.128 1.266-.893.823-1.448 1.717-1.448 2.334 0 8.075 9.917 18 17.983 18 .616 0 1.51-.558 2.333-1.456.867-.945 1.261-1.814 1.267-2.137-.038-.228-.67-1.115-2.396-2.444-1.485-1.143-2.688-1.756-2.998-1.763-.021.006-.156.058-.428.403-.237.301-.49.713-.736 1.111-.672 1.094-1.307 2.127-2.23 2.127-.148 0-.295-.029-.435-.085-3.15-1.26-6.875-4.985-8.135-8.135-.151-.378-.175-.971.569-1.645.396-.36.943-.695 1.473-1.022.399-.244.812-.498 1.112-.735.345-.273.397-.407.403-.429-.009-.31-.62-1.513-1.763-2.997-1.33-1.727-2.216-2.357-2.444-2.397V1.2Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </Icon>
);
