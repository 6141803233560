import React from 'react';
import cn from 'classnames';
import { Link } from '@adobe/react-spectrum';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import styles from './Item.module.less';

const Item = (props) => {
  const { routerTo, children } = props;
  const location = useLocation();
  const isSelected = location.pathname === routerTo;

  return (
    <div className={cn(styles.item, isSelected && styles.selected)}>
      <Link>
        <RouterLink to={routerTo}>{children}</RouterLink>
      </Link>
    </div>
  );
};

export default Item;
