import { useQuery, UseQueryOptions } from 'react-query';
import * as queries from '@sendpayments/graphql/queries';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
import { query } from '@send-base/utilities/endpoint';

export const queryKeysRecipient = {
  recipients: ['recipients'],
};

export const useRecipient = ({
  params,
  options,
}: {
  params: { owner: string; recipientId: string };
  options?: UseQueryOptions<Recipients, unknown, any, any>;
}) =>
  useQuery(queryKeysRecipient.recipients, () => getRecipients(params.owner), {
    ...options,
    select: (data) => data?.items?.find((item) => item?.id === params.recipientId),
  });

export const useRecipients = ({ params, options }: { params: { owner: string }; options?: UseQueryOptions<Recipients> }) =>
  useQuery<Recipients>(queryKeysRecipient.recipients, () => getRecipients(params.owner), { ...options });

const getRecipients = (owner: string): Promise<Recipients> =>
  query(queries.listCoconutsByOwner, {
    params: { owner: owner, type: { eq: CoconutTypes.BENEFICIARY } },
    projection: {},
  });

export type Recipients = {
  items: Recipient[];
};

export type Recipient = {
  id: string;
  type: string;
  owner: string;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  beneficiaryBankAccountCountry: string;
  currencyTo: string;
  beneficiaryType: string;
  businessName?: string;
  firstName: string;
  lastName: string;
  email: string;
  streetAddress?: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
  bicSwiftCode: string;
  bicSwiftAccountNumber?: string;
  ibanAccountNumber?: string;
  sortCodeNumber?: string;
  clabeNumber?: string;
  cnapsNumber?: string;
  abaCode?: string;
  abaAccountNumber?: string;
  bankName: string;
  accountNumber: string;
  ifscNumber?: string;
  status: string;
  parentId: string;
  parentType: string;
  middleName?: string;
  iban: string;
  streetAddressUnitNumber: string;
  streetAddressType: string;
  streetAddressNumber: string;
  streetAddressName: string;
  bankCode?: string;
};
