import React, { useState, useEffect } from 'react';
import { withTheme } from '@sendpayments/react-shared/components/ThemeProvider';
import {
  Content,
  Dialog,
  Footer,
  Heading,
  DialogContainer,
  Button,
  useDialogContainer,
  RadioGroup,
  TextArea,
} from '@adobe/react-spectrum';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { breakpoints } from '@sendpayments/react-shared/design-system';
import Close from '@spectrum-icons/workflow/Close';
import { PrimaryButton } from '@send-components/PrimaryButton';
import { PrimaryActionButton } from '@sendpayments/react-shared/components/buttons/PrimaryActionButton';
import { PrimaryRadioButton } from '@send-components/PrimaryRadioButton';
import styles from './Survey.module.less';

const reasons = [
  'Problems with my transfers',
  "Couldn't find the currency I wanted",
  'Not using the service anymore',
  'Other reasons',
];

const DialogContent = ({ onSubmit }) => {
  const { isMediaMobile } = useBreakpoint(breakpoints);
  const dialog = useDialogContainer();
  const [userInput, setUserInput] = useState('');
  const [selectedReason, setSelectedReason] = useState(null);
  const [textAreaOpen, setTextareaOpen] = useState(false);

  useEffect(() => {
    if (selectedReason === 'Other reasons') {
      setTextareaOpen(true);
    } else {
      setTextareaOpen(false);
    }
  }, [selectedReason]);

  const onSubmitHandler = async () => {
    const formData = {
      deactivateReason: selectedReason === 'Other reasons' ? userInput : selectedReason,
    };
    onSubmit(formData);
  };

  return (
    <Dialog size="L">
      <div className={styles.exit} onClick={dialog.dismiss}>
        <Close />
      </div>
      <Heading>
        <div className={styles.title}>Please tell us why you&apos;re leaving</div>
      </Heading>
      <Content marginTop={8}>
        <RadioGroup aria-label="radioGroup" value={selectedReason} onChange={setSelectedReason}>
          {reasons.map((reason, index) => (
            <PrimaryRadioButton value={reason} key={index} />
          ))}
          {textAreaOpen && (
            <TextArea
              value={userInput}
              onChange={setUserInput}
              placeholder="We would love to know more so we can improve our service"
              width="100%"
              marginTop="10px"
              maxLength={1000}
              aria-label="textarea"
            />
          )}
        </RadioGroup>
      </Content>
      <Footer UNSAFE_className={styles.buttonWrapper}>
        <PrimaryButton onPress={onSubmitHandler} isDisabled={!selectedReason}>
          Close Account
        </PrimaryButton>
        <PrimaryActionButton onPress={dialog.dismiss} UNSAFE_className={styles.keepButton} marginBottom={isMediaMobile && '19px'}>
          Keep Account
        </PrimaryActionButton>
      </Footer>
    </Dialog>
  );
};

const SurveyDialog = ({ onClose, onSubmit }) => {
  return (
    <DialogContainer
      onDismiss={() => {
        onClose();
      }}
    >
      <DialogContent onSubmit={onSubmit} />
    </DialogContainer>
  );
};

export default withTheme(SurveyDialog);
