const firstStepStyle = {
  w: '5%',
  ml: '47%',
  mr: '0%',
  borderLeftRadius: '10px',
  borderRightRadius: '0',
  backgroundColor: 'border.light',
  border: 'solid 2px var(--chakra-colors-border-base)',
  borderRight: 'none',
  borderLeft: 'solid 2px var(--chakra-colors-border-base)',
  pos: 'relative' as 'relative',
  zIndex: 0,
};

const middleStepStyle = {
  w: '51%',
  ml: '-1%',
  mr: '0%',
  backgroundColor: 'border.light',
  borderLeftRadius: '0',
  borderRightRadius: '0',
  border: 'solid 2px var(--chakra-colors-border-base)',
  borderRight: 'none',
  borderLeft: 'none',
  pos: 'relative' as 'relative',
  zIndex: 0,
};

const lastStepStyle = {
  w: '5%',
  ml: '0%',
  mr: '47%',
  borderRightRadius: '10px',
  borderLeftRadius: '0',
  backgroundColor: 'border.light',
  border: 'solid 2px var(--chakra-colors-border-base)',
  borderRight: 'solid 2px var(--chakra-colors-border-base)',
  borderLeft: 'none',
  pos: 'relative' as 'relative',
  zIndex: 0,
};

const currentStepStyle = {
  backgroundColor: 'primary.base',
  border: 'solid 2px primary.base',
  borderLeft: 'solid 2px primary.base',
  borderRight: 'solid 2px primary.base',
  zIndex: 1,
};

export const calculateProgressBarMobile = (totalSteps: number, currentStep: number) => {
  let width = '10%';
  let countSteps = totalSteps;

  if (currentStep > 0) {
    if (totalSteps % 2 === 1) {
      countSteps = countSteps + 1;
    }
    if (currentStep + 1 === countSteps) {
      width = '90%';
    } else {
      width = `${((currentStep + 1) / countSteps) * 100}%`;
    }
  }

  return width;
};

export const calculateProgressBarDesktopStyles = (index: number, totalSteps: number, currentStep: number) => {
  const firstStep = index === 0;
  const lastStep = index === totalSteps - 1;
  const indexIsStep = index === currentStep;
  const lessThanCurrentStep = index <= currentStep;

  let leftBoxStyle = middleStepStyle;
  let rightBoxStyle = middleStepStyle;

  if (firstStep) {
    leftBoxStyle = firstStepStyle;

    if (indexIsStep) {
      leftBoxStyle = {} as any;
      rightBoxStyle = { ...rightBoxStyle, ...{ ml: '50%' } };
    }
  }
  if (lastStep) {
    rightBoxStyle = lastStepStyle;

    if (indexIsStep) {
      rightBoxStyle = {} as any;
    }
  }

  if (lessThanCurrentStep) {
    leftBoxStyle = { ...leftBoxStyle, ...currentStepStyle };

    if (!indexIsStep || lastStep) {
      rightBoxStyle = { ...rightBoxStyle, ...currentStepStyle };
    }
  }

  return { leftBoxStyle, rightBoxStyle };
};
