import React from 'react';
import { Center, Box, Button } from '@chakra-ui/react';
import { AlternativeOptions } from '../../atoms';
import { GoogleIcon, FacebookIcon } from '@sendpayments/react-shared/components/Icons';

export interface SocialLoginProps {
  content: string;
  onSocialLogin: (provider: 'Google' | 'Facebook') => void;
}

const SocialLogin: React.FC<SocialLoginProps> = (props: SocialLoginProps) => {
  return (
    <Center>
      <Box width="100%" maxWidth="450px" mt={{ base: 1, md: 1 }} mb={{ base: 5 }}>
        <AlternativeOptions content={props.content} />
        <Box display="flex" flexDirection={{ base: 'column', md: 'row' }} mt="30px">
          <Button variant="social" onClick={() => props.onSocialLogin('Google')} width="100%" height="40px">
            <Box w="5%" ml="8px" mr={{ base: '0px', md: '10px' }}>
              <GoogleIcon width="18" height="18" />
            </Box>
            <Box
              position="relative"
              right={{ base: '5%', md: '0%' }}
              w={{ base: '100%', md: '95%' }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              Continue with Google
            </Box>
          </Button>
          <Button
            variant="social"
            onClick={() => props.onSocialLogin('Facebook')}
            width="100%"
            height="40px"
            ml={{ base: '0px', md: '10px' }}
            mt={{ base: '6px', md: '0px' }}
            display="flex"
            alignItems="center"
          >
            <Box w="5%" ml="8px" mr={{ base: '0px', md: '10px' }}>
              <FacebookIcon width="18" height="18" />
            </Box>
            <Box
              position="relative"
              right={{ base: '5%', md: '0%' }}
              w={{ base: '100%', md: '95%' }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              Continue with Facebook
            </Box>
          </Button>
        </Box>
      </Box>
    </Center>
  );
};

export { SocialLogin };
