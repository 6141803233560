import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const XIcon = (props: IconProps) => (
  <Icon width={9} height={9} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={18} cy={18} r={18} fill="#F5F5F5" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.286 14.4644L16.8215 17.9999L13.286 21.5354C12.9605 21.8609 12.9605 22.3885 13.286 22.7139C13.6114 23.0394 14.139 23.0394 14.4645 22.7139L18 19.1784L21.5355 22.7139C21.861 23.0394 22.3886 23.0394 22.714 22.7139C23.0395 22.3885 23.0395 21.8609 22.714 21.5354L19.1785 17.9999L22.714 14.4644C23.0395 14.1389 23.0395 13.6113 22.714 13.2858C22.3886 12.9604 21.861 12.9604 21.5355 13.2858L18 16.8214L14.4645 13.2858C14.139 12.9604 13.6114 12.9604 13.286 13.2858C12.9605 13.6113 12.9605 14.1389 13.286 14.4644Z"
      fill="#1B2221"
    />
  </Icon>
);
