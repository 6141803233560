import React from 'react';
import { Box, BoxProps, Divider } from '@chakra-ui/react';
import { RecipientListItem, RecipientListItemProps } from '../RecipientListItem';

const RecipientContainer: React.FC = (props) => {
  return <Box {...props} border="solid 1px" borderColor="border.base" borderRadius="5px" />;
};

export interface RecipientListProps {
  type?: 'Verified' | 'New' | 'Deleted';
  isRegistered?: boolean;
  items: Array<Omit<RecipientListItemProps, 'type'>>;
  refetchBenes: () => Promise<void>;
}

export const RecipientList: React.VFC<RecipientListProps & BoxProps> = (props) => {
  const [selectedListItem, setSelectedListItem] = React.useState<number | null>(null);
  return (
    <RecipientContainer {...props}>
      {props.items?.map((item, index) => (
        <Box key={item.key ?? `list-item-${index}`}>
          <RecipientListItem
            {...item}
            type={props.type}
            key={item.key ?? `r-list-item-${index}`}
            isOpen={selectedListItem === index}
            isDisabled={!props.isRegistered}
            refetchBenes={props.refetchBenes}
            onClick={() => {
              if (selectedListItem === index) {
                setSelectedListItem(null);
              } else {
                setSelectedListItem(index);
              }
            }}
          />
          <Divider opacity={1} />
        </Box>
      ))}
    </RecipientContainer>
  );
};
