import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';

async function getAccessToken() {
  return (await Auth.currentSession()).getAccessToken().getJwtToken();
}

export default function useAuth() {
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    getAccessToken().then((token) => {
      setAccessToken(token);
    });
  }, []);

  return { accessToken };
}
