import React, { useState } from 'react';
import { Content, Dialog, Footer, Heading, DialogContainer, View, useDialogContainer, Text, Button } from '@adobe/react-spectrum';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { breakpoints } from '@sendpayments/react-shared/design-system';
import Close from '@spectrum-icons/workflow/Close';
import { withTheme } from '@sendpayments/react-shared/components/ThemeProvider';
import styles from './CloseAccountModal.module.less';

const DialogContent = ({ onDismissCloseAccountModal }) => {
  const dialog = useDialogContainer();
  const { isMediaMobile } = useBreakpoint(breakpoints);

  const onDismissDialogHandler = () => {
    onDismissCloseAccountModal();
    return dialog.dismiss;
  };

  return (
    <Dialog size="L">
      <div className={styles.exit} onClick={onDismissDialogHandler}>
        <Close />
      </div>
      <Heading>
        <Text UNSAFE_className={`${styles.title} ${isMediaMobile && styles.titleMobile}`}>Your account is now closed</Text>
      </Heading>
      <Content marginTop={8}>
        <Text>
          If you’d like to reopen your account in the future you’ll need to contact Send, via our{' '}
          <a
            href="https://help.sendpayments.com/en/articles/4630192-dealer-desk"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            service team
          </a>{' '}
          to activate your account again.
        </Text>
        <View marginTop={20} />
        <Text UNSAFE_className={styles.bold}>Hope to see you again soon!</Text>
      </Content>
      <Footer UNSAFE_className={styles.footer}>
        <Button onPress={onDismissDialogHandler} UNSAFE_className={styles.primaryButton}>
          Done
        </Button>
      </Footer>
    </Dialog>
  );
};

const CloseAccountModal = (props) => {
  const [isOpen, setIsOpen] = useState(true);

  return <DialogContainer onDismiss={() => setIsOpen(false)}>{isOpen && <DialogContent {...props} />}</DialogContainer>;
};

export default withTheme(CloseAccountModal);
