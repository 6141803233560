import { useState, useEffect } from 'react';

const useCurrenciesFilter = (currenciesList) => {
  const [currenciesFiltered, setCurrenciesFilter] = useState(currenciesList);

  useEffect(() => setCurrenciesFilter(currenciesList), [currenciesList]);

  const filterCurrencies = (value) => {
    const upperValue = value.trim().toUpperCase();
    if (value.trim() === '') {
      setCurrenciesFilter(currenciesList);
    }

    const newList = currenciesList.filter(
      ({ currencyName, currencyCountry }) =>
        currencyName.trim().toUpperCase().includes(upperValue) || currencyCountry.trim().toUpperCase().includes(upperValue),
    );
    setCurrenciesFilter(newList);
  };

  return [currenciesFiltered, filterCurrencies];
};

export default useCurrenciesFilter;
