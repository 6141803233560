import * as queries from '@sendpayments/graphql/queries';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
import { query } from '@send-base/utilities/endpoint';
import { buildUniqueKey } from '@sendpayments/js-utils/dist/services/build-unique-key';
import { useRemoteData } from '@sendpayments/react-shared/hooks/useRemoteData';

const fetchConversions = async ({ email }) => {
  const conversionQuery = {
    owner: email,
    type: { eq: CoconutTypes.CONVERSION },
    limit: 300, // This is a major band aid. We should instead use listCoconutsByOwnerAndTypeAndCreatedAt (there is an index for it, but no query)
  };

  const { items } = await query(queries.listCoconutsByOwner, { params: conversionQuery });

  return items;
};

const useConversionsByOwner = ({ owner }) =>
  useRemoteData(
    () => ({
      key: buildUniqueKey('queries.listCoconutsByOwner', { type: CoconutTypes.CONVERSION }),
      onInitialFetch: () => fetchConversions({ email: owner }),
      initialState: [],
    }),
    [owner],
  );

export default useConversionsByOwner;
