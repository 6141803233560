import * as queries from '@sendpayments/graphql/queries';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
import { query } from '@send-base/utilities/endpoint';
import { buildUniqueKey } from '@sendpayments/js-utils/dist/services/build-unique-key';
import { useRemoteData } from '@sendpayments/react-shared/hooks/useRemoteData';

const fetchPayments = async ({ email }) => {
  const conversionQuery = {
    owner: email,
    type: { eq: CoconutTypes.PAYMENT },
    sortDirection: 'DESC',
    limit: 300, // TODO: we need pagination here very soon realistically
  };

  const { items } = await query(queries.listCoconutsByOwner, { params: conversionQuery });

  return items;
};

const usePaymentsByOwner = ({ owner }) =>
  useRemoteData(
    () => ({
      key: buildUniqueKey('queries.listCoconutsByOwner', { type: CoconutTypes.PAYMENT }),
      onInitialFetch: () => fetchPayments({ email: owner }),
      initialState: [],
    }),
    [owner],
  );

export default usePaymentsByOwner;
