import React from 'react';
import { withTheme } from '@sendpayments/react-shared/components/ThemeProvider';
import { Dialog, Heading, DialogContainer, View, Content, ButtonGroup } from '@adobe/react-spectrum';
import { CloseIcon } from '@sendpayments/react-shared/components/CloseIcon';
import styles from './VerifyPhoneReminderModal.module.less';

const VerifyPhoneReminderModal = ({ onDismiss, title, children, buttons, onClose }) => {
  return (
    <DialogContainer onDismiss={onDismiss}>
      <Dialog size="M" UNSAFE_className={styles.modal}>
        <CloseIcon className={styles.closeIcon} onClick={onClose} />
        <Heading marginBottom="size-200" UNSAFE_className={styles.title}>
          {title}
        </Heading>
        <Content UNSAFE_className={styles.content}>
          <View marginBottom="size-200">{children}</View>
        </Content>
        <ButtonGroup UNSAFE_className={styles.buttonGroup}>{buttons}</ButtonGroup>
      </Dialog>
    </DialogContainer>
  );
};

VerifyPhoneReminderModal.displayName = 'VerifyPhoneReminderModal';

export default withTheme(VerifyPhoneReminderModal);
