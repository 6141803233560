import * as React from 'react';
import { Button, Stack, Box, Link } from '@chakra-ui/react';
import { PasswordField, InputField } from '@sendpayments/react-shared/components/molecules';
import { useToast } from '@sendpayments/react-shared/components/molecules/Toast';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { SendForm } from '../types';
import { logger } from '@sendpayments/js-utils/dist/services/logger';
import { analytics } from '@sendpayments/js-utils/dist';

const loginScheme = yup.object({
  email: yup.string().email('Please enter a valid email address').required('Please enter your email address'),
  password: yup.string().required('Please enter your password'),
});

type LoginData = yup.InferType<typeof loginScheme>;

export interface LoginFormProps extends SendForm<LoginData> {
  onForgot: () => void;
}

export const LoginForm: React.FC<LoginFormProps> = ({ onForgot, onSubmit, isLoading }) => {
  const toast = useToast();
  return (
    <Box>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={loginScheme}
        onSubmit={async (values, actions) => {
          try {
            await onSubmit(values);
            analytics.push({ action: { type: 'form_submit', data: { name: 'LoginForm' } } });
          } catch (error) {
            logger.error('LoginForm', 'ERROR: ', error);
            actions.setSubmitting(false);
            toast({
              variant: 'negative',
              title: 'Something went wrong on our end. Please try again!',
            });
          }
        }}
      >
        {(props) => (
          // noValidate to disable normal html validator.
          <Form noValidate>
            <Stack spacing={3}>
              {/* Email */}
              <Field name="email">
                {({ field, form }) => (
                  <InputField
                    id="email"
                    label="Email address"
                    isInvalid={form.errors.email && form.touched.email}
                    errorText={form.errors.email}
                    inputProps={{
                      type: 'email',
                      size: 'md',
                      autoComplete: 'email',
                      placeholder: 'Enter your email address',
                      tabIndex: 0,
                      ...field,
                    }}
                  />
                )}
              </Field>
              {/* Password */}
              <Field name="password">
                {({ field, form }) => (
                  <PasswordField
                    id="password"
                    label="Password"
                    isInvalid={form.errors.password && form.touched.password}
                    errorText={form.errors.password}
                    inputProps={{
                      autoComplete: 'current-password',
                      size: 'md',
                      placeholder: 'Enter your password',
                      tabIndex: 0,
                      ...field,
                    }}
                  />
                )}
              </Field>
              {/* Forgot password link */}
              <Link color="primary.base" fontSize="md" onClick={onForgot}>
                Forgot Password
              </Link>

              <Button isLoading={props.isSubmitting || isLoading} type="submit" maxW="40" alignSelf="center" fontSize="md">
                Login now
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
