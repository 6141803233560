/* eslint-disable react/no-danger */
import React from 'react';
import { Form, Card, Checkbox, Col } from 'antd';
import PropTypes from 'prop-types';

function CommonAcceptInput(props) {
  const { getFieldDecorator } = props.form;
  const sm = props.sm ? props.sm : props.span;
  const md = props.md ? props.md : sm;
  return (
    <Col span={props.span} md={md} sm={sm}>
      <Form.Item colon={false} required style={{ paddingRight: '20px' }}>
        {getFieldDecorator(props.name, {
          valuePropName: 'checked',
          rules: [{ required: props.required, message: `Acceptance is required` }],
        })(
          <Card className="warning-checkbox">
            <Checkbox />
            <div style={{ display: 'inline' }} dangerouslySetInnerHTML={{ __html: props.label }} />
          </Card>,
        )}
      </Form.Item>
    </Col>
  );
}

CommonAcceptInput.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  span: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
};

CommonAcceptInput.defaultProps = {
  label: '',
  required: true,
  span: 24,
  sm: 0,
  md: 0,
};

export default CommonAcceptInput;
