import { getMaskedIdentifierText } from '@send-base/utilities';
import { Icon, sendIconTypes } from '@send-legacy-components/Common/Icon';
import { ListView } from '@send-legacy-components/Common/ListView';
import { useFeature } from '@sendpayments/react-shared/hooks/useFeature'; // can be removed in the future
import { PD_1617_BENE_HEADER } from '@sendpayments/shared-constants/features';
import { useSettings } from '@send-data-hooks';
import { Row, Spin } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import styles from './View.module.less';
import { getBeneName } from '@send-base/utilities/sortBenesByName';

export const recipients = {
  MYSELF: 'Myself',
  INDIVIDUAL: 'Individual',
  COMPANY: 'Company',
};

const View = ({ onRecipientClick, recipients: recipientsList, loading, toCurrency }) => {
  const [
    {
      data: { isEnabled: isBeneHeaderEnabled },
    },
  ] = useFeature({
    feature: PD_1617_BENE_HEADER,
    functionName: 'beneHeader',
  });

  const [{ data: settings }] = useSettings();

  const dataSourceDefaultRecipients = [
    { id: recipients.MYSELF, iconType: 'user-add', header: 'Myself' },
    { id: recipients.INDIVIDUAL, iconType: 'user-add', header: isBeneHeaderEnabled ? 'Someone else' : 'Individual' },
    { id: recipients.COMPANY, iconType: 'usergroup-add', header: isBeneHeaderEnabled ? 'A company' : 'Company' },
  ];

  const dataSourceRecipients = useMemo(
    () =>
      recipientsList
        .map((recipient) => ({
          id: recipient.id,
          iconType: 'user-add',
          header: getBeneName(recipient),
          description: getMaskedIdentifierText(recipient),
          descriptionIconType: `flag-${recipient.currencyTo}`,
          disabled:
            settings?.blacklistedCurrencies?.includes(recipient.currencyTo) ||
            recipient.currencyTo !== toCurrency ||
            recipient.status === 'Deleted',
        }))
        .sort((a, b) => {
          if (!a.disabled && b.disabled) {
            return -1;
          }
          if (a.disabled && !b.disabled) {
            return 1;
          }
          return a.createdAt > b.createdAt ? -1 : 1;
        }),
    [recipientsList],
  );
  return (
    <>
      {dataSourceRecipients && dataSourceRecipients.length > 0 && (
        <Row className={styles.listViewContainer}>
          <div className={styles.title}>
            <span>
              <Icon type={sendIconTypes.SELECT_RECIPIENT} />
              <span className={styles.mainTitle}>Existing recipient </span>
            </span>
          </div>
          {loading && <Spin spinning={loading} />}
          <ListView onItemClick={onRecipientClick} dataSource={dataSourceRecipients} scrollSize={3} />
        </Row>
      )}
      <Row type="flex" justify="center">
        <div className={classNames(styles.title, styles.secondTitle)}>
          <span>
            <Icon type="user-add" />
            <span className={styles.mainTitle}>New recipient </span>
          </span>
        </div>
        <ListView onItemClick={onRecipientClick} dataSource={dataSourceDefaultRecipients} />
      </Row>
    </>
  );
};

View.propTypes = {
  onRecipientClick: PropTypes.func.isRequired,
  recipients: PropTypes.array,
  loading: PropTypes.bool,
  toCurrency: PropTypes.string,
};

View.defaultProps = {
  recipients: [],
  loading: false,
  toCurrency: undefined,
};

export default View;
