import React from 'react';
import { Divider, Icon } from 'antd';
import PropTypes from 'prop-types';

export const SSubAreaTitle = (props) => (
  <div style={props.style}>
    <div className="subAreaTitle">
      {props.icon ? (
        <Icon type={props.icon} style={{ fontSize: 20 }} />
      ) : (
        <img alt="iamge" src={props.image} className="title-image" />
      )}
      <div className="title-label" style={{ fontSize: 16, marginLeft: 8 }}>
        {props.text}
      </div>
    </div>
    {props.underlineDivider && <Divider className="title-underline" />}
  </div>
);

SSubAreaTitle.propTypes = {
  text: PropTypes.string,
  image: PropTypes.string,
  icon: PropTypes.string,
  style: PropTypes.object,
  underlineDivider: PropTypes.bool,
};

SSubAreaTitle.defaultProps = {
  text: 'YOU',
  image: '/static/home-copy@2x.png',
  icon: '',
  style: {},
  underlineDivider: true,
};
