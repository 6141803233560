import React, { Fragment } from 'react';
import { Box, Flex, Grid, Text, GridProps } from '@chakra-ui/react';
import { calculateProgressBarDesktopStyles, calculateProgressBarMobile } from './calculateProgressBar';

export interface StepData {
  heading: string;
  subHeading?: string;
  step: string;
}
interface Progress {
  current: number;
  steps: StepData[];
}

interface ProgressBarProps extends GridProps {
  progress: Progress;
}

const ProgressBar = ({ progress, ...childProps }: ProgressBarProps) => {
  const width = calculateProgressBarMobile(progress.steps.length, progress.current);

  return (
    <>
      <Box
        display={{ base: 'block', sm: 'none' }}
        background="white"
        h={10}
        w="102%"
        position="fixed"
        top="0"
        left="49%"
        transform="translateX(-50%)"
        borderTop="solid 1px"
        borderColor="border.base"
      >
        <Box position="absolute" left={0} h="4px" backgroundColor="primary.base" display="block" w={width} />
      </Box>
      <Box w="100%" display={{ base: 'none', sm: 'block' }}>
        <Flex alignItems="center" w="100%" flexDir="column">
          <Flex flexDir="column" w="100%">
            <Grid
              {...childProps}
              w={{ base: '100%', sm: '70vw', md: '55vw' }}
              alignSelf="center"
              gridColumnStart={{ ml: '15px' }}
              gridColumnEnd={{ mr: '15px' }}
              templateColumns={`repeat(${progress.steps.length}, 2fr)`}
            >
              {progress.steps.map((stepData: StepData, index: number) => {
                const indexIsStep = index === progress.current;

                return (
                  <Flex key={`text-${stepData.step}-${index}`} w="auto" textAlign="center" flexDirection="column" mt="-2px">
                    <Text fontWeight={indexIsStep ? 'bold' : 'none'} fontSize={'md'} lineHeight={1.5}>
                      {stepData.step}
                    </Text>
                  </Flex>
                );
              })}

              {progress.steps.map((stepData: StepData, index: number) => {
                const indexIsStep = index === progress.current;
                const { leftBoxStyle, rightBoxStyle } = calculateProgressBarDesktopStyles(
                  index,
                  progress.steps.length,
                  progress.current,
                );

                return (
                  <Fragment key={`step-${stepData.step}-${index}`}>
                    <Flex w="auto" textAlign="center" flexDirection="column">
                      <Flex w="auto" textAlign="center" alignSelf="center" flexDirection="row">
                        <Box
                          h={indexIsStep ? '16px' : '4px'}
                          w={indexIsStep ? '16px' : '4px'}
                          mt={indexIsStep ? '4px' : '10px'}
                          zIndex={2}
                          borderRadius="100%"
                          backgroundColor={indexIsStep ? 'primary.dark' : 'primary.light'}
                          alignSelf="center"
                          border={indexIsStep ? '2px solid white' : 'none'}
                        />
                      </Flex>
                      <Flex mb="20px" mt={indexIsStep ? '-13px' : '-7px'} flexDirection="row">
                        <Box h="10px" {...leftBoxStyle} />
                        <Box h="10px" {...rightBoxStyle} />
                      </Flex>
                    </Flex>
                  </Fragment>
                );
              })}
            </Grid>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

export { ProgressBar, ProgressBarProps, Progress };
