import React, { useEffect, useState } from 'react';
import { Flex, View, Text } from '@adobe/react-spectrum';
import FlashOff from '@spectrum-icons/workflow/FlashOff';
import CalendarLocked from '@spectrum-icons/workflow/CalendarLocked';
import Alert from '@spectrum-icons/workflow/Alert';

import history from '@send-legacy-app/history';
import { breakpoints } from '@sendpayments/react-shared/design-system';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { PrimaryActionButton } from '@sendpayments/react-shared/components/buttons/PrimaryActionButton';
import { useLogout, useConversionsByOwner, usePaymentsByOwner } from '@send-data-hooks';
import { StatusBadge } from '@send-components/StatusBadge';
import { SurveyDialog } from '@send-components/Survey';
import { triggerToast } from '@send-components/Toast';
import { InfoPanel, Item } from '@send-components/InfoPanel';
import { CollapseSection } from '@send-components/CollapseSection';
import { post } from '@send-base/utilities/endpoint';
import { underwayStatuses } from '@send-const/activities';
import { GraphTrendAlert } from '@send-components/Icons';
import styles from './CloseAccount.module.less';

const CloseAccount = ({ email, account, activeSection, sectionName, changeSectionActive }) => {
  const { isMedia } = useBreakpoint(breakpoints);
  const isMobile = isMedia({ to: breakpoints.xs });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [, doLogout] = useLogout();
  const [conversions] = useConversionsByOwner({ owner: email });
  const [payments] = usePaymentsByOwner({ owner: email });
  const [validToCloseAccount, setValidToCloseAccount] = useState(true);

  useEffect(() => {
    isValidToCloseAccount();
  }, [payments, conversions]);

  const onCloseAccountHandler = () => {
    if (!validToCloseAccount) {
      triggerToast({
        type: 'error',
        message: 'You cannot close your account as you currently have a transfer underway.',
        rightIcon: <Alert size="M" />,
      });
    } else {
      setDialogOpen(true);
    }
  };

  const onDialogClose = () => {
    setDialogOpen(false);
  };

  const onSurveySubmit = async ({ deactivateReason }) => {
    await post('/profile/closeAccount', {
      data: {
        regId: account.id,
        regType: account.type,
        reason: deactivateReason,
      },
    });

    await doLogout();

    history.push({
      pathname: '/auth',
      state: {
        closeAccountModalisOpen: true,
      },
    });

    window.location.reload();
  };

  const isValidToCloseAccount = () => {
    conversions.data.forEach((conversion) => {
      if (underwayStatuses.includes(conversion.status)) {
        setValidToCloseAccount(false);
      }
    });

    payments.data.forEach((payment) => {
      if (underwayStatuses.includes(payment.status)) {
        setValidToCloseAccount(false);
      }
    });
  };

  return (
    <>
      <CollapseSection
        buttonText="Manage"
        activeSection={activeSection}
        sectionName={sectionName}
        changeSectionActive={changeSectionActive}
      >
        <Flex direction={isMobile ? 'column' : 'row'} gap={isMobile ? 'size-40' : 'size-100'} alignItems="baseline">
          <span className={styles.itemTitle}>Close account</span>
          {validToCloseAccount ? <StatusBadge type="success" text="Active" /> : <StatusBadge type="danger" text="Inactive" />}
        </Flex>
        <Text>By leaving Send, you will lose access to all Send products.</Text>
        <View marginTop="size-200">
          <InfoPanel UNSAFE_style={{ maxWidth: '400px' }} marginTop={22} marginBottom={22}>
            <span className={styles.itemTitle} style={{ fontSize: '12px' }}>
              Closing your account will instantly:
            </span>
            <Item icon={CalendarLocked}>Have access to your account suspended</Item>
            <Item icon={GraphTrendAlert}>Close your activity history</Item>
            <Item icon={FlashOff}>Loss of linked recipient details</Item>
          </InfoPanel>
          <PrimaryActionButton
            UNSAFE_className={styles.primaryButton}
            height="inherit"
            type="submit"
            onPress={() => onCloseAccountHandler()}
          >
            Close Account
          </PrimaryActionButton>
        </View>
      </CollapseSection>
      {dialogOpen && <SurveyDialog onClose={onDialogClose} onSubmit={onSurveySubmit} />}
    </>
  );
};

export default CloseAccount;
