import { statusesByCoconutType, fundsReceivedStatuses } from '../../const/activities';
import { conversionActivityStatus, paymentActivityStatus } from '../../const/statuses';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
export const getActivityStatus = (
  { status, type, fundsReceived, fullFundsReceived } = {},
  { isPaymentNeeded, isDocumentNeeded } = {},
) => {
  if (!status || !type) {
    throw Error('status and type must be present');
  }

  const statuses = statusesByCoconutType[type.toUpperCase()];
  const activityStatus = status.toUpperCase();

  if (type === CoconutTypes.CONVERSION) {
    if (statuses.UNDERWAY[activityStatus]) {
      if (fullFundsReceived || fundsReceivedStatuses[status]) {
        return conversionActivityStatus.PAYMENT_RECEIVED;
      }

      if (fundsReceived > 0) {
        return conversionActivityStatus.PATIAL_PAYMENT_RECEIVED;
      }

      return conversionActivityStatus.AWAITING_PAYMENT;
    }

    if (statuses.COMPLETED[activityStatus]) {
      if (isPaymentNeeded) {
        return conversionActivityStatus.AWAITING_PAYMENT;
      }

      return conversionActivityStatus.PAYMENT_RECEIVED;
    }

    if (statuses.FAILED[activityStatus]) {
      return conversionActivityStatus.CANCELLED;
    }
  }

  if (type === CoconutTypes.PAYMENT) {
    if (statuses.UNDERWAY[activityStatus]) {
      if (isDocumentNeeded) {
        return paymentActivityStatus.INFO_NEEDED;
      }

      return paymentActivityStatus.UNDERWAY;
    }

    if (statuses.COMPLETED[activityStatus]) {
      return paymentActivityStatus.COMPLETED;
    }

    if (statuses.FAILED[activityStatus]) {
      return paymentActivityStatus.FAILED;
    }
  }

  return undefined;
};
