import { useRemoteData } from '@sendpayments/react-shared/hooks/useRemoteData';
import { query } from '@send-base/utilities/endpoint';
import * as queries from '@sendpayments/graphql/queries';
import { buildUniqueKey } from '@sendpayments/js-utils/dist/services/build-unique-key';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
import { useCurrentEmail } from '../useCurrentEmail';

const fetchVRNs = async ({ owner }) => {
  const params = {
    owner,
    type: { eq: CoconutTypes.VIRTUAL_ACCOUNT },
  };
  const { items } = await query(queries.listCoconutsByOwner, { params });
  if (items?.length) {
    return items;
  }
  return undefined;
};

const useVRNs = () => {
  const [{ data: email }] = useCurrentEmail();
  const vrns = useRemoteData(
    () => ({
      key: buildUniqueKey('queries.listCoconutsByOwner', { owner: email, type: CoconutTypes.VIRTUAL_ACCOUNT }),
      onInitialFetch: () => fetchVRNs({ owner: email }),
      initialState: {},
    }),
    [email],
  );
  return vrns;
};

export default useVRNs;
