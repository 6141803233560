import * as React from 'react';
import { Button, Stack, Box } from '@chakra-ui/react';
import { InputField } from '@sendpayments/react-shared/components/molecules';
import { useToast } from '@sendpayments/react-shared/components/molecules/Toast';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { SendForm } from '../types';
import { analytics } from '@sendpayments/js-utils/dist';
import logger from '@sendpayments/js-utils/dist/services/logger';

const sendEmailSchema = yup.object({
  email: yup
    .string()
    .email('This email is invalid. Please try this format: yourname@example.com')
    .required('Enter your email address in this format: yourname@example.com'),
});

type SendEmailVerificationData = yup.InferType<typeof sendEmailSchema>;

export type SendEmailVerificationFormProps = SendForm<SendEmailVerificationData>;

export const SendEmailVerificationForm: React.FC<SendEmailVerificationFormProps> = ({ onSubmit, isLoading }) => {
  const toast = useToast();
  return (
    <Box>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={sendEmailSchema}
        onSubmit={async (values, actions) => {
          try {
            await onSubmit(values);
            analytics.push({ action: { type: 'form_submit', data: { name: 'SendEmailVerificationForm' } } });
          } catch (error) {
            logger.error('SendEmailVerificationForm', 'ERROR: ', error);
            actions.setSubmitting(false);
            toast({
              variant: 'negative',
              title: 'Something went wrong on our end. Please try again!',
            });
          }
        }}
      >
        {(props) => (
          // noValidate to disable normal html validator.
          <Form noValidate>
            <Stack spacing="3">
              {/* Email */}
              <Field name="email">
                {({ field, form }) => (
                  <InputField
                    id="email"
                    label="Email address"
                    placeholder="Enter your email address"
                    isInvalid={form.errors.email && form.touched.email}
                    errorText={form.errors.email}
                    inputProps={{ type: 'email', size: 'md', autoComplete: 'email', ...field }}
                  />
                )}
              </Field>
              <Button isLoading={props.isSubmitting || isLoading} type="submit" maxW="40" alignSelf="center" fontSize="md">
                Send Code
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
