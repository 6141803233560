import { Icon, sendIconTypes } from '@send-legacy-components/Common/Icon';
import { Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import PaymentOptions from './PaymentOptions';
import styles from './View.module.less';

const View = ({ currency, amount, settlementAccounts, loading, sendId, showTitle, convId, complianceScore }) => {
  return (
    <Row className={styles.container}>
      {showTitle && (
        <div className={styles.title}>
          <span>
            <Icon type={sendIconTypes.PROGRESS_PAYMENT} />
            <span className={styles.mainTitle}>Payment method </span>
          </span>
        </div>
      )}
      <PaymentOptions
        currency={currency}
        amount={amount}
        settlementAccounts={settlementAccounts}
        loading={loading}
        sendId={sendId}
        convId={convId}
        complianceScore={complianceScore}
      />
    </Row>
  );
};

View.propTypes = {
  currency: PropTypes.string,
  amount: PropTypes.number,
  settlementAccounts: PropTypes.object,
  loading: PropTypes.bool,
  sendId: PropTypes.string,
  convId: PropTypes.string,
  showTitle: PropTypes.bool,
  complianceScore: PropTypes.number,
  isCreateVRNEnabled: PropTypes.bool,
};

View.defaultProps = {
  currency: '',
  amount: 0,
  loading: false,
  settlementAccounts: {},
  sendId: undefined,
  convId: undefined,
  showTitle: true,
  complianceScore: undefined,
};

export default View;
