import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { ThemedImage } from '@send-legacy-components/Common';
import styles from './notfound.module.less';

const NotFoundPage = () => (
  <div className={styles.container}>
    <div className={styles.image}>
      <ThemedImage alt="404" file="404.svg" />
    </div>
    <p className={styles.status}>404</p>
    <p>Sorry, the page you visited does not exist.</p>
    <div className={styles.buttons}>
      <Link to="/">
        <Button type="primary">Back Home</Button>
      </Link>
    </div>
  </div>
);

export default NotFoundPage;
