import React from 'react';
import {
  Button,
  Stack,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Text,
  Box,
  Flex,
  Heading,
  Link,
  useDisclosure,
} from '@chakra-ui/react';
import {
  currencies as defaultCurrencies,
  transferAmounts as defaultTransferAmounts,
} from '@sendpayments/js-utils/dist/const/defaults';
import { CountrySelect } from '../../index';
import { SelectorWithFlags } from '../../../molecules';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { SendForm } from '../types';
import { logger } from '@sendpayments/js-utils/dist/services/logger';
import {
  CurrencySelect,
  InputField,
  PhoneExtSelect,
  Select,
  ValueContainer,
} from '@sendpayments/react-shared/components/molecules';
import { useToast } from '@sendpayments/react-shared/components/molecules/Toast';
import { CashDollarIcon } from '@sendpayments/react-shared/components/Icons/CashDollarIcon';
import { useTransferOptions } from './utils';
import { SimpleModal } from '../../index';
import { ChevronIcon } from '@sendpayments/react-shared/components/Icons';

export const ComparisonRegisterFormTransferSchema = yup.object({
  currencyFrom: yup
    .string()
    .required('Please Select a currency to send from')
    .notOneOf([yup.ref('currencyTo')], 'Currency to must be different to currency from'),
  currencyTo: yup
    .string()
    .required('Please Select a currency to send to')
    .notOneOf([yup.ref('currencyFrom')], 'Currency to must be different to currency from'),
  estimatedValue: yup.string().required('Please select a sending amount'),
  firstName: yup.string().trim().required('Please enter your name'),
  phoneCountryCode: yup.string().trim().required('Please select your country code'),
  phone: yup.string().trim().required('Please enter your mobile phone number'),
});

const unsupportedCurrencyPairs = new Set(['ILS-IDR', 'NZD-BRL', 'ZAR-EUR', 'MXN-CAD', 'NZD-INR', 'INR-AUD', 'AUD-MKD']);

export type ComparisonRegisterTransferData = yup.InferType<typeof ComparisonRegisterFormTransferSchema>;

export interface ComparisonRegisterTransferDataProps extends SendForm<ComparisonRegisterTransferData> {
  transferAmounts?: Array<string>;
}

const defaultFormValues = {
  estimatedValue: '',
  currencyFrom: '',
  currencyTo: '',
  firstName: '',
  phoneCountryCode: '',
  phone: '',
  status: 'Incomplete',
};

export const ComparisonRegisterFormStep2: React.FC<ComparisonRegisterTransferDataProps> = ({
  onSubmit,
  isLoading,
  initialValues,
  transferAmounts,
}: ComparisonRegisterTransferDataProps) => {
  const toast = useToast();
  const { transferAmountOptions, currencyOptions } = useTransferOptions(
    {
      transferAmounts,
    },
    { defaultTransferAmounts, defaultCurrencies },
  );

  const checkInvalidCurrencyPairing = (currencyFrom: string, currencyTo: string) => {
    if (unsupportedCurrencyPairs.has(`${currencyFrom}-${currencyTo}`)) {
      onOpen();
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const submitHandler = async (values, actions) => {
    try {
      await onSubmit(values);
    } catch (error) {
      logger.error('ComparisonRegisterTransferForm', 'ERROR: ', error);
      actions.setSubmitting(false);
      toast({
        variant: 'negative',
        title: 'Something went wrong on our end. Please try again!',
      });
    }
  };

  return (
    <Formik
      initialValues={{ ...defaultFormValues, ...initialValues }}
      validateOnMount={false}
      validationSchema={ComparisonRegisterFormTransferSchema}
      onSubmit={submitHandler}
    >
      {(props) => (
        <Form noValidate>
          <Stack spacing={4} textAlign="left">
            {/* Currency */}
            <Stack direction="row">
              <Field name="currencyFrom">
                {({ field, form }) => (
                  <FormControl id={field.name} isInvalid={form.errors[field.name] && form.touched[field.name]}>
                    <FormLabel mb={1}>You send</FormLabel>
                    <CurrencySelect
                      value={
                        currencyOptions.find((item) => item.value === field.value) ?? { label: 'Australian dollar', value: 'AUD' }
                      }
                      isInvalid={form.errors[field.name] && form.touched[field.name]}
                      onSelect={(option) => {
                        field.onChange(field.name)(option?.value);
                        checkInvalidCurrencyPairing(option?.value, props.values.currencyTo);
                      }}
                      currencies={[{ label: 'All currencies', options: currencyOptions }]}
                      dropdownTarget={SelectorWithFlags}
                      anchor="left"
                    />
                    <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="currencyTo">
                {({ field, form }) => (
                  <FormControl id={field.name} isInvalid={form.errors[field.name] && form.touched[field.name]}>
                    <FormLabel mb={1}>Recipient gets</FormLabel>
                    <CurrencySelect
                      value={
                        currencyOptions.find((item) => item.value === field.value) ?? {
                          label: 'New Zealand dollar',
                          value: 'NZD',
                        }
                      }
                      isInvalid={form.errors[field.name] && form.touched[field.name]}
                      onSelect={(option) => {
                        field.onChange(field.name)(option?.value);
                        checkInvalidCurrencyPairing(props.values.currencyFrom, option?.value);
                      }}
                      currencies={[{ label: 'All currencies', options: currencyOptions }]}
                      dropdownTarget={SelectorWithFlags}
                    />
                    <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <SimpleModal
                open={isOpen}
                onPrimaryAction={() => {
                  props.setFieldValue('currencyFrom', defaultFormValues.currencyFrom);
                  props.setFieldValue('currencyTo', defaultFormValues.currencyTo);
                  onClose();
                }}
                onClose={() => {
                  props.setFieldValue('currencyFrom', defaultFormValues.currencyFrom);
                  props.setFieldValue('currencyTo', defaultFormValues.currencyTo);
                  onClose();
                }}
                primaryButtonText="Proceed"
                key="currency-pair-modal"
              >
                <Flex flexDir="column">
                  <CashDollarIcon w={'8em'} h={'8em'} alignSelf="center" mb={1} />
                  <Heading as="h1" fontWeight="bold" fontSize="1.4rem" lineHeight="1" paddingStart={'.1rem'} paddingEnd={'.1rem'}>
                    We are unable to service the selected currency pairing.
                  </Heading>
                  <Box mt={3} fontSize="md" p={1}>
                    <Text as="span">To see a list of currency pairings we are unable to serve, please</Text>{' '}
                    <Link
                      href="https://help.sendpayments.com/en/articles/4617289-which-currencies-can-i-convert-my-money-to"
                      isExternal
                    >
                      click here
                    </Link>
                    {'. '}
                    <Text as="span">
                      To choose a different currency pairing, please click{' '}
                      <Text as="span" fontWeight="bold">
                        Proceed
                      </Text>
                      .
                    </Text>
                  </Box>
                </Flex>
              </SimpleModal>
            </Stack>

            {/* Estimated Value  */}
            <Field name="estimatedValue">
              {({ field, form }) => (
                <FormControl id={field.name} isInvalid={form.errors[field.name] && form.touched[field.name]}>
                  <FormLabel mb={1}>How much are you planning to send?</FormLabel>
                  <Select
                    placeholder={'Select from list'}
                    value={transferAmountOptions.find((item) => item.value === field.value)}
                    options={transferAmountOptions}
                    onChange={(option) => field.onChange(field.name)(option?.value)}
                    width="100%"
                    isInvalid={form.errors[field.name] && form.touched[field.name]}
                    components={{ ValueContainer }}
                  />
                  <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            {/* First name */}
            <Field name="firstName">
              {({ field, form }) => (
                <InputField
                  id={field.name}
                  label="First legal name"
                  placeholder="Enter your first legal name"
                  isInvalid={!!form.errors[field.name] && form.touched[field.name]}
                  isValid={!form.errors[field.name] && !!field.value}
                  errorText={form.errors[field.name]}
                  inputProps={{
                    ...field,
                    autoComplete: 'given-name',
                    size: 'md',
                  }}
                />
              )}
            </Field>

            {/* Mobile number */}
            <Box>
              <FormLabel mb={1}>Mobile number</FormLabel>
              <Stack direction="row">
                {/* Phone Extension */}
                <Field name="phoneCountryCode">
                  {({ field, form }) => (
                    <Box w={{ base: '40%', sm: '50%' }}>
                      <PhoneExtSelect
                        menuMinWidth="220px"
                        id="phone-extension"
                        isInvalid={!!form.errors[field.name] && form.touched[field.name]}
                        errorText={form.errors[field.name]}
                        value={field.value ? { label: '', value: field.value } : undefined}
                        label={''}
                        onSelect={(e) => field.onChange(field.name)(e?.value ?? '')}
                        placeholder="+61"
                      />
                    </Box>
                  )}
                </Field>

                {/* Phone Number */}
                <Field name="phone">
                  {({ field, form }) => (
                    <InputField
                      id={field.value}
                      placeholder="Enter your mobile number"
                      isInvalid={!!form.errors[field.name] && form.touched[field.name]}
                      isValid={!form.errors[field.name] && field.value}
                      errorText={form.errors[field.name]}
                      inputProps={{
                        ...field,
                        autoComplete: 'tel-national',
                        size: 'md',
                      }}
                    />
                  )}
                </Field>
              </Stack>
            </Box>
            <Button isLoading={props.isSubmitting || isLoading} type="submit" width="100%" alignSelf="center" fontSize="md">
              Create account <ChevronIcon direction={'forward'} h={2} w={2} ml={2} />
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
