import React from 'react';
import { FullPageModalTemplate } from '@send-base/atomic-components/templates/FullPageModalTemplate';
import { DirectDebitForm } from '@send-base/atomic-components/organisms/forms/DirectDebitForm';
import { Box } from '@chakra-ui/react';
import history from '@send-legacy-app/history';
import { DirectDebitCongratulationsPage } from '@send-base/atomic-components/pages/DirectDebitPage/DirectDebitCongratulationsPage';
import { useConversion, useRegistration } from '@send-base/infrastructure/query';
import { useDisclosure } from '@chakra-ui/react';
import { PageSpinner } from '@send-base/atomic-components/organisms';
import { useCurrentEmail } from '@send-base/data-hooks';
import { useParams } from 'react-router-dom';
import { useDirectDebitForm } from './useDirectDebitForm';

export type ConversionResponse = {
  id: string;
  bookedSellAmount: number;
};

export const DirectDebitPage = () => {
  const { isOpen: isReviewOpen, onOpen, onClose } = useDisclosure();
  const { id } = useParams<{ id: string }>();
  const [{ data: ownerEmail }] = useCurrentEmail();
  const { data: registration, isLoading: isRegistrationLoading } = useRegistration({
    params: { owner: ownerEmail ?? '' },
  });
  const { data: conversion, isLoading: isConversionLoading } = useConversion<ConversionResponse>({ id: id || '' });

  const { amount, formData, isLoading, isPostError, accountName, isPostSuccess, reset, setFormData, handleSubmit } =
    useDirectDebitForm({ registration, conversion });

  const handleFormSubmit = () => {
    handleSubmit(formData);
    onClose();
  };

  if (isRegistrationLoading || isConversionLoading) {
    return <PageSpinner />;
  }

  return (
    <>
      {isPostSuccess ? (
        <DirectDebitCongratulationsPage name={accountName} />
      ) : (
        <FullPageModalTemplate
          heading="Pay Using Direct Debit"
          backButtonText="Back to payment options"
          onNavigateBack={() => {
            history.push(`/conversion/${conversion?.id}`);
          }}
        >
          <Box w={{ base: '100%', sm: '66%', md: '66%', lg: '50%', xl: '32%' }} alignSelf={'center'}>
            <DirectDebitForm
              handleFormSubmit={handleFormSubmit}
              amount={amount}
              isLoading={isLoading}
              accountName={accountName}
              formData={formData}
              setFormData={setFormData}
              onClose={onClose}
              onOpen={onOpen}
              isReviewOpen={isReviewOpen}
              isErrorOpen={isPostError}
              reset={reset}
            />
          </Box>
        </FullPageModalTemplate>
      )}
    </>
  );
};
