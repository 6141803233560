export const appConfig = {
  breakpoints: {
    start: 0,
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
  },
  newFeatureGroups: ['NewFeatures'],
  australiaPublicHolidaysURL: 'https://public-holidays-for-sendfx.s3.ap-southeast-2.amazonaws.com/holidays.json',
  australiaWorkingDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
  australiaStartBusinessHoursTime: '8:00',
  australiaEndBusinessHoursTime: '18:00',
  defaultWidgetCurrency: 'AUD',
  firstDefaultCurrency: 'USD',
  secondDefaultCurrency: 'GBP',
  disabledCurrencies: ['BGN'], // Temp solution to disable currencies not currently supported by CC
  popularCurrencies: ['USD', 'AUD', 'NZD', 'GBP', 'EUR', 'SGD', 'CAD'],
  australianPhoneNumber: '1800 982 418',
  internationalPhoneNumber: '+617 5619 5700',
  companyACN: 'ACN 617 647 220 (AFSL: 509635)',
  companyName: `${new Date().getFullYear()} @ SendFX Limited`,
  sendSupportedCountriesUrl: 'https://help.sendpayments.com/en/articles/5879252',
  sendTermsAndConditionsUrl: 'https://help.sendpayments.com/en/articles/4617310-terms-and-conditions',
  sendPrivacyAndPolicyUrl: 'https://help.sendpayments.com/en/articles/4617313-privacy-policy',
  sendProductDisclosureStatementUrl: 'https://help.sendpayments.com/en/articles/4617309-product-disclosure-statement',
  sendFinancialServicesGuideUrl: 'https://help.sendpayments.com/en/articles/4617308-financial-services-guide',
  sendDealerHelpDeskUrl: 'https://help.sendpayments.com/en/articles/4630192-dealer-desk',
  sendPaymentMethods: 'https://help.sendpayments.com/en/articles/4617297-what-payment-methods-can-i-use-with-send',
  UNKNOWN_AFFILIATE_ID: '3cd476dc-7210-42df-854a-9ed9c9d8b381',
  supportedCountries: [
    'Guadeloupe (France)',
    'Australia',
    'Guernsey (United Kingdom)',
    'Monaco',
    'Austria',
    'Hong Kong',
    'Netherlands (Kingdom of the Netherlands)',
    'Belgium',
    'Hungary',
    'New Zealand',
    'Bulgaria',
    'Iceland',
    'Norway',
    'Canada',
    'India',
    'Poland',
    'Cayman Islands (United Kingdom)',
    'Ireland',
    'Portugal',
    'China',
    'Isle of Man (United Kingdom)',
    'Croatia',
    'Israel',
    'Romania',
    'Cyprus',
    'Italy',
    'Singapore',
    'Czech Republic',
    'Japan',
    'Slovakia',
    'Denmark (Kingdom of Denmark)',
    'Jersey (United Kingdom)',
    'Slovenia',
    'Estonia',
    'Korea, South',
    'South Africa',
    'Finland',
    'Latvia',
    'Spain',
    'France',
    'Liechtenstein',
    'Sweden',
    'French Guiana (France)',
    'Lithuania',
    'Switzerland',
    'Germany',
    'Luxembourg',
    'Taiwan',
    'Gibraltar (United Kingdom)',
    'Malta',
    'United Kingdom',
    'Greece',
    'Martinique (France)',
  ],
};
