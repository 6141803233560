import { docStatus } from '@send-const/statuses';
import { useConversionsByOwner, useDocumentsByOwner, usePaymentsByOwner } from '@send-data-hooks';
import { enrichConversion, getStatusMapping, isValidConversion } from '@send-legacy-utils/conversionHelpers';
import { useRemoteDataSelector } from '@sendpayments/react-shared/hooks/useRemoteData';

const ARCHIVED_STATUS = 'send_archived';

const mapActivitiesByStatus = ({ conversions, documents, payments }) => {
  const { data: allPays } = payments;
  const { data: allConvs } = conversions;
  const { data: allDocs } = documents;

  const docs = allDocs.filter((item) => item.docStatus === docStatus.REQUESTED);
  const docsByParentId = docs.reduce((acct, doc) => {
    const current = acct[doc.parentId] || [];
    current.push(doc);
    // eslint-disable-next-line no-param-reassign
    acct[doc.parentId] = current;
    return acct;
  }, {});

  const pays = allPays
    .filter((pay) => pay.status !== ARCHIVED_STATUS)
    .map((pay) => {
      const myDocs = docsByParentId[pay.id];
      const status = getStatusMapping(pay, { documents: myDocs });
      return { ...pay, status };
    })
    .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

  const paysByConvId = pays.reduce((acct, pay) => {
    const current = acct[pay.sendConversionId] || [];
    current.push(pay);
    // eslint-disable-next-line no-param-reassign
    acct[pay.sendConversionId] = current;
    return acct;
  }, {});

  const convs = allConvs
    .filter((conv) => isValidConversion(conv))
    .map((conv) => {
      const conversionPayments = paysByConvId[conv.id];
      const conversionDocs = docsByParentId[conv.id];
      return enrichConversion(conv, conversionPayments, conversionDocs);
    })
    .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

  return {
    payments: pays,
    conversions: convs,
    paymentsByConvId: paysByConvId,
    docsByParentId: docsByParentId,
  };
};

const useActivitiesByStatus2 = ({ email }) => {
  const [conversions] = useConversionsByOwner({ owner: email });
  const [documents] = useDocumentsByOwner({ owner: email });
  const [payments] = usePaymentsByOwner({ owner: email });

  return useRemoteDataSelector(
    () => mapActivitiesByStatus({ conversions, documents, payments }),
    [conversions, documents, payments],
  );
};

export default useActivitiesByStatus2;
