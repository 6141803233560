import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip, Icon } from 'antd';
import history from '@send-legacy-app/history';

function CloseButton({ onClick }) {
  return (
    <div className="exit-button-div">
      <Tooltip title="Close">
        <Button className="close-button" onClick={onClick}>
          <Icon className="close-icon" type="close" />
        </Button>
      </Tooltip>
    </div>
  );
}

CloseButton.propTypes = {
  onClick: PropTypes.func,
};

CloseButton.defaultProps = {
  onClick: () => history.push(`/`),
};

export default CloseButton;
