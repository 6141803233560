import { post } from '@send-base/utilities/endpoint';
import { Auth } from 'aws-amplify';

export default class MFAHelper {
  /**
   * Get a user's MFA status on Cognito.
   * @param {Record<string, any>} user
   */
  static async userHasMfaEnabled(user) {
    return user ? !!(await Auth.getMFAOptions(user)) : false;
  }

  /**
   * Get a user's MFA status on Cognito.
   * @param {string} userInput
   * @param {Record<string, any>} user
   * @param {Record<string, any>} account
   * @param {boolean} account
   */
  static async onCompleteCodeChanged(userInput, user, account, mfaIsEnabled) {
    const verifyResult = await post('/profile/verifyPhoneSubmit', {
      data: {
        accessToken: user.signInUserSession.accessToken.jwtToken,
        code: userInput,
        regType: account.type,
      },
    });

    if (verifyResult.status === true) {
      const toggleMFAResult = await post('/profile/toggleMFA', {
        data: {
          accessToken: user.signInUserSession.accessToken.jwtToken,
          mfaIsEnable: !mfaIsEnabled,
          regType: account.type,
        },
      });

      if (toggleMFAResult.status === true) {
        await this.userHasMfaEnabled(user);
      }
    }
  }

  /**
   * Send a verification code to a user.
   * @param {Record<string, any>} user
   * @param {Record<string, any>} account
   */
  static async getVerifyCode(user, account) {
    await post('/profile/verifyPhone', {
      data: {
        accessToken: user.signInUserSession.accessToken.jwtToken,
        regType: account.type,
      },
    });
  }
}
