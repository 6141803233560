import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRecipientsByOwner, useRegistration } from '@send-data-hooks';
import { SelectRecipientView, recipients as recipientsMap } from '@send-legacy-components/DealerAssist';
import { beneStatus } from '@send-const/statuses';
import { analytics } from '@sendpayments/js-utils/dist';
import { sortBenesByName } from '@send-base/utilities/sortBenesByName';

const SelectRecipient = ({ onRecipientClick, onReviewRecipient, toCurrency }) => {
  const [{ data: reg }] = useRegistration();
  const [{ data: recipients, loading }] = useRecipientsByOwner({ owner: reg.email });
  const recipientsSorted = sortBenesByName(recipients.filter((recipient) => recipient.status !== beneStatus.BENEFICIARY_FAILED));

  const recipientFireAnalyticsEvent = (recipient) => {
    analytics.push({ action: { type: 'existing_recipient_transfer', data: recipient.id } });
  };

  const onRecipientClickHandler = (e) => {
    if (e.id && recipientsMap[e.id.toUpperCase()]) {
      return onRecipientClick(e.id);
    }

    const recipient = recipients.find(({ id: idRecipient }) => idRecipient === e.id);
    recipientFireAnalyticsEvent(recipient);
    if (recipient) {
      return onReviewRecipient(recipient);
    }

    throw new Error('recipient need to be present');
  };

  return (
    <SelectRecipientView
      onRecipientClick={onRecipientClickHandler}
      toCurrency={toCurrency}
      loading={loading}
      recipients={recipientsSorted}
    />
  );
};

SelectRecipient.propTypes = {
  onRecipientClick: PropTypes.func.isRequired,
  onReviewRecipient: PropTypes.func.isRequired,
  toCurrency: PropTypes.string,
};

SelectRecipient.defaultProps = {
  toCurrency: undefined,
};

export default SelectRecipient;
