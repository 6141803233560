import { SVGProps } from 'react';
import * as React from 'react';

export const AvatarUpload = (props: SVGProps<any>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} {...props}>
    <g data-name="Group 78548">
      <g
        data-name="Ellipse 9380"
        style={{
          stroke: 'currentColor',
          strokeWidth: 2,
          strokeDasharray: '3 3',
          fill: 'none',
        }}
      >
        <circle
          cx={25}
          cy={25}
          r={25}
          style={{
            stroke: 'none',
          }}
          stroke="none"
        />
        <circle
          cx={25}
          cy={25}
          r={24}
          style={{
            fill: 'none',
          }}
        />
      </g>
      <path
        data-name="Path 42106"
        d="M16 10.6a.6.6 0 0 1-.6.6h-4.2v4.2a.6.6 0 0 1-.6.6H9.4a.6.6 0 0 1-.6-.6v-4.2H4.6a.6.6 0 0 1-.6-.6V9.4a.6.6 0 0 1 .6-.6h4.2V4.6a.6.6 0 0 1 .6-.6h1.2a.6.6 0 0 1 .6.6v4.2h4.2a.6.6 0 0 1 .6.6Z"
        transform="translate(15 15)"
        style={{
          fill: '#1a1f1e',
        }}
      />
    </g>
  </svg>
);
