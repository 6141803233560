import React, { useState, useEffect } from 'react';
import { Divider, Flex, View, Text } from '@adobe/react-spectrum';
import ChevronDown from '@spectrum-icons/workflow/ChevronDown';
import ChevronUp from '@spectrum-icons/workflow/ChevronUp';
import { PrimaryButton } from '@send-components/PrimaryButton';
import styles from './CollapseSection.module.less';

const CollapseSection = (props) => {
  const {
    noDivider,
    buttonText,
    children,
    isVerticalTrigger,
    onCollapseButtonClick,
    activeSection,
    sectionName,
    changeSectionActive,
  } = props;
  const [isExpand, setIsExpand] = useState(false);

  useEffect(() => {
    if (isExpand && onCollapseButtonClick) {
      onCollapseButtonClick();
    }
  }, [isExpand]);

  useEffect(() => {
    if (activeSection === sectionName) {
      setIsExpand(true);
    } else {
      setIsExpand(false);
    }
  }, [activeSection]);

  const onExpandHandler = () => {
    setIsExpand(!isExpand);
    changeSectionActive(sectionName);
  };

  const [header, body, expandContent] = React.Children.toArray(children);

  return (
    <>
      {!noDivider && <Divider size="S" marginBottom="20px" />}
      <div className={styles.container}>
        <Flex
          direction={isVerticalTrigger ? 'column' : 'row'}
          gap="size-100"
          alignItems={isVerticalTrigger ? 'flex-start' : 'center'}
          marginBottom="10px"
        >
          <View flexGrow="1">{header}</View>
          {buttonText && (
            <PrimaryButton onPress={() => onExpandHandler()}>
              {isExpand ? <ChevronUp /> : <ChevronDown />}
              <Text>{isExpand ? 'Hide' : buttonText}</Text>
            </PrimaryButton>
          )}
        </Flex>
        <View>{body}</View>
        {isExpand && expandContent}
      </div>
    </>
  );
};

export default CollapseSection;
