import { orderBy } from 'lodash';
import { Beneficiary } from '@sendpayments/graphql/types/Beneficiary.type';

export const getBeneName = (bene: Beneficiary) => {
  if (bene.beneficiaryType === 'Company') {
    return bene.businessName;
  }

  return `${bene.firstName} ${bene.lastName}`;
};

export const sortBenesByName = (benes: Beneficiary[]) => {
  const withSortableName = benes.map((bene) => {
    return { ...bene, sortableName: getBeneName(bene)?.trim()?.toLowerCase() };
  });

  return orderBy(withSortableName, ['sortableName', 'lastName'], ['asc', 'asc']);
};
