import React, { useEffect, useRef } from 'react';
import { ChevronLeftIcon, PaperPlaneIcon, VerifyPhoneIcon, WarningIcon } from '@send-base/atomic-components/atoms';
import { MessageBox } from '@send-base/atomic-components/molecules';
import { OtpCodeInput } from '@send-components/OtpCodeInput';
import { useVerifyPhone } from './useVerifyPhone';
import {
  Center,
  Heading,
  HStack,
  Link,
  LinkProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';

interface VerifyPhoneModalProps {
  description?: string;
  onVerifySuccess: () => void;
  isOpen: boolean;
  onClose: () => void;
}

export const VerifyPhoneModal = ({ onVerifySuccess, description, ...modal }: VerifyPhoneModalProps) => {
  const { sendCode, verifyCode, code, resetMutations } = useVerifyPhone({ onVerifySuccess });

  useEffect(() => {
    if (modal.isOpen) {
      sendCode.mutate();
    } else {
      resetMutations();
    }
  }, [modal.isOpen]);

  const renderMainContent = () => {
    switch (sendCode.status) {
      case 'loading':
        return (
          <HStack align="center" justify="center" spacing={4} pt={2} pb={1}>
            <Text mb={0}>{`We're sending you a security code`}</Text>
            <Spinner color="primary.base" />
          </HStack>
        );

      case 'error':
        return (
          <>
            <Text color="error" fontSize="sm">
              Error sending verification code. You may have sent too many recently. Please wait a few minutes, and try again.
            </Text>
            <ResendCodeLink text="Please resend the code" onResend={sendCode.resend} />
          </>
        );

      case 'success':
        return (
          <>
            <CodeInput
              onComplete={verifyCode.mutate}
              isLoading={verifyCode.isLoading}
              isError={verifyCode.isError}
              isCodeValid={code.isValid}
              onResendCode={sendCode.resend}
            />
            <CodeValidityBox code={code} onResend={sendCode.resend} />
            <Text>
              If this is no longer your mobile number, please{' '}
              <Link
                color="primary.base"
                fontSize="14px"
                textDecor="none"
                display="inline"
                href="https://help.sendpayments.com/en/articles/5331409-what-if-i-turned-on-2-step-verification-but-i-cannot-access-the-6-digit-code-on-my-phone"
                isExternal
              >
                contact us
              </Link>
              .
            </Text>
          </>
        );

      default:
        return <></>;
    }
  };

  const [isMobile] = useMediaQuery('(max-width: 767px)');

  return (
    <Modal {...modal} size={isMobile ? 'full' : 'lg'} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent minW={{ sm: '480px' }} pt={4} pb={8} borderRadius={{ base: 'none', md: '8px' }}>
        {isMobile ? (
          <ChevronLeftIcon mt={6} ml={6} color="primary.base" cursor="pointer" onClick={modal.onClose} />
        ) : (
          <ModalCloseButton border="none" borderRadius="full" cursor="pointer" h="36px" w="36px" mt={2} mr={2} />
        )}
        <ModalBody>
          <Stack align="center">
            <VerifyPhoneIcon />
            <Stack align="center" spacing="24px" textAlign="center">
              <Heading m={0} fontSize={{ base: '20px', sm: '24px' }}>
                {`Let's make sure it's really you!`}
              </Heading>
              <Text lineHeight="21px">
                {description}
                <br />
                {sendCode.isSuccess && (
                  <>Please enter the 6-digit security code we’ve just sent to {code?.deliveryDetails?.Destination.substring(1)}</>
                )}
              </Text>
              {renderMainContent()}
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

interface CodeInputProps {
  onComplete: (code: string) => void;
  onResendCode: () => void;
  isCodeValid: boolean;
  isLoading: boolean;
  isError: boolean;
}

const CodeInput = ({ onComplete, onResendCode, isLoading, isError, isCodeValid }: CodeInputProps) => {
  const otpContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Workaround to focus the first input field after the component has rendered.
    // <OtpCodeInput /> has autoFocus on by default, but the `isDisabled` prop is
    // briefly evaluating to true on render, preventing the focus from occuring
    otpContainerRef.current?.querySelector('input')?.focus();
  }, [otpContainerRef.current]);

  return (
    <Stack align="center" sx={isError ? { input: { borderColor: 'error', color: 'error' } } : undefined}>
      <Center pos="relative">
        {isLoading && <Spinner pos="absolute" color="primary.base" />}
        <Center opacity={isLoading ? '0.2' : '1'} ref={otpContainerRef}>
          <OtpCodeInput onComplete={onComplete} newStyles isDisabled={isLoading || !isCodeValid} isInputNum={true} />
        </Center>
      </Center>
      {isError && (
        <Text color="error" fontSize="sm" maxW="310px">
          The code doesn’t match the code sent or the code is expired. Please try again or{' '}
          <ResendCodeLink text="resend the code." onResend={onResendCode} fontSize="sm" />
        </Text>
      )}
    </Stack>
  );
};

interface CodeValidityProps {
  code: ReturnType<typeof useVerifyPhone>['code'];
  onResend: () => void;
}

const CodeValidityBox = ({ code, onResend }: CodeValidityProps) => {
  const validContent = code.hasBeenReSent ? (
    <MessageBox
      variant="success"
      icon={<PaperPlaneIcon />}
      w="full"
      content={
        <Text fontSize="12px">
          We’ve sent a new verification code to your mobile. <br />
          This code is valid for the next: <strong>{code.validSecondsRemaining} seconds</strong>
        </Text>
      }
    />
  ) : (
    <Text>
      This code is valid for the next: <strong>{code.validSecondsRemaining} seconds</strong>
    </Text>
  );

  const invalidContent = (
    <MessageBox
      variant="info"
      icon={<WarningIcon />}
      w="full"
      content={
        <Text>
          The code has expired. Please <ResendCodeLink onResend={onResend} /> the code
        </Text>
      }
    />
  );

  return code.isValid ? validContent : invalidContent;
};

interface ResendCodeLinkProps extends LinkProps {
  text?: string;
  onResend: () => void;
}

const ResendCodeLink = ({ text, onResend, ...props }: ResendCodeLinkProps) => {
  return (
    <Link color="primary.base" fontSize="14px" textDecor="none" onClick={onResend} display="inline" {...props}>
      {text ?? 'resend'}
    </Link>
  );
};
