import { useContext } from 'react';
import { AppContext } from '@send-legacy-app/Context';

const useAppContext = () => {
  const appContext = useContext(AppContext);

  if (!appContext) {
    throw Error('useAppContext need to be used under <AppProvider />');
  }

  return [appContext];
};

export default useAppContext;
