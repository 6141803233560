import * as React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const FailedCrossIcon = (props: IconProps): JSX.Element => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 87 87" {...props}>
      <g clipPath="url(#clip0_46_597)">
        <path
          d="M41.325 87C41.0944 87 40.8596 86.9608 40.6377 86.8869C32.6381 84.2203 22.5982 75.355 15.0597 64.2973C8.19105 54.2227 0 37.5274 0 15.2206C0 14.02 0.9744 13.0456 2.175 13.0456C14.4159 13.0456 33.6603 4.66754 40.12 0.361036C40.8508 -0.126164 41.8035 -0.126164 42.5343 0.361036C48.994 4.66754 68.2384 13.0456 80.4793 13.0456C81.6799 13.0456 82.6543 14.02 82.6543 15.2206C82.6543 37.5274 74.4633 54.2184 67.5947 64.2973C60.0561 75.3507 50.0163 84.2203 42.0167 86.8869C41.7948 86.9608 41.5599 87 41.3293 87H41.325ZM4.3761 17.3304C4.86765 37.4752 12.3453 52.6002 18.6528 61.8526C26.3479 73.1365 35.4264 80.2531 41.325 82.5195C47.2236 80.2531 56.302 73.1409 63.9972 61.8526C70.3047 52.6002 77.7823 37.4752 78.2739 17.3304C71.1921 16.8867 63.3012 14.4507 57.6984 12.3714C51.5301 10.0876 45.3052 7.18184 41.325 4.75454C37.3447 7.18184 31.1199 10.0876 24.9516 12.3714C19.3444 14.4463 11.4579 16.8867 4.3761 17.3304Z"
          fill="#1B2221"
        />
        <path
          d="M57.9637 52.722L44.6657 41.325L57.9637 29.928C58.8772 29.145 58.9816 27.7747 58.1986 26.8612C57.4156 25.9477 56.0453 25.8433 55.1318 26.6263L41.3206 38.4627L27.5093 26.6263C26.5958 25.8433 25.2256 25.9521 24.4426 26.8612C23.6596 27.7704 23.7683 29.145 24.6775 29.928L37.9754 41.325L24.6775 52.722C23.764 53.505 23.6596 54.8752 24.4426 55.7887C24.8732 56.289 25.4822 56.55 26.0956 56.55C26.5958 56.55 27.1004 56.376 27.5093 56.028L41.3206 44.1916L55.1318 56.028C55.5407 56.3803 56.0453 56.55 56.5456 56.55C57.1589 56.55 57.7679 56.2933 58.1986 55.7887C58.9816 54.8752 58.8728 53.505 57.9637 52.722Z"
          fill="#1B2221"
        />
      </g>
      <defs>
        <clipPath id="clip0_46_597">
          <rect width="87" height="87" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};
