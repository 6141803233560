import React from 'react';
import IconContainer from './IconContainer';

export const GraphTrendAlert = () => {
  return (
    <IconContainer>
      <svg viewBox="0 0 36 36" focusable="false" aria-hidden="true" role="img">
        <path
          fillRule="evenodd"
          d="M35,33.809,26.341,16.651a1.5,1.5,0,0,0-2.678,0L15,33.809A1.55,1.55,0,0,0,16.407,36H33.593A1.55,1.55,0,0,0,35,33.809ZM24.5,20h1a.5.5,0,0,1,.5.5v7a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-7A.5.5,0,0,1,24.5,20Zm1.491,12.4H24.009a.409.409,0,0,1-.409-.409V30.009a.409.409,0,0,1,.409-.409h1.982a.409.409,0,0,1,.409.409v1.983A.409.409,0,0,1,25.991,32.4Z"
        ></path>
        <path
          fillRule="evenodd"
          d="M33.094,2.061l-7.74,10.815a4.423,4.423,0,0,1,3.423,2.074L33.91,7.616A.5.5,0,0,0,34,7.33V2.352A.5.5,0,0,0,33.094,2.061ZM19.978,5.374l-5.45,14.992-4.081-4.082a.5.5,0,0,0-.674-.031L2.18,22.579a.5.5,0,0,0-.18.384v4.188a.5.5,0,0,0,.829.377l7.048-6.157,5.343,5.342L19.7,17.842l1.532-2.9a4.425,4.425,0,0,1,3.438-2.067L20.895,5.321A.5.5,0,0,0,19.978,5.374Z"
        ></path>
      </svg>
    </IconContainer>
  );
};
