import { VerifyContact } from 'aws-amplify-react';

class CustomVerifyContact extends VerifyContact {
  constructor(props) {
    super(props);
  }

  showComponent() {
    const { authData } = this.props;
    this.changeState('signedIn', authData);
    return null;
  }
}

export default CustomVerifyContact;
