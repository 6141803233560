import styled from '@emotion/styled';

export const IconContainer = styled.div`
  width: 18px;
  height: 18px;
  color: #0097ce;
  display: inline-block;

  svg {
    fill: currentColor;
  }
`;

export default IconContainer;
