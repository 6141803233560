import React from 'react';
import { Box, Button } from '@chakra-ui/react';

import { Setting } from '@send-base/infrastructure/query/useSetting';
import { Registration } from '@send-base/types';
import { Recipient } from '@send-base/infrastructure/query/useRecipient';

import { CurrencyExchangeWidget } from '@send-base/atomic-components/organisms/CurrencyExchangeWidget';
import { CurrencyPair, useCurrencyExchangeWidget } from './hooks/useCurrencyExchangeWidget';

import { Currency } from '@send-base/infrastructure/query/useCurrencies';
import { exchangeWidgetConfig } from './hooks/config';
import { extractDecimalPlaces, getNextCurrencyCode, getSupportInfo } from './hooks/currency.utils';
import { useCreateOpportunity } from '@send-base/infrastructure/query/useCreateOpportunity';
import { truncateLongName } from '@send-base/utilities/';
import { useOutOfHours } from '@send-data-hooks';

export type Props = {
  currencies: Currency[];
  setting: Setting;
  registration: Registration;
  recipient?: Recipient;
  actionName?: string;
  onSubmit: (data: CurrencyExchangeWidgetFormData) => void;
  ttl?: number;
};

export type CurrencyExchangeWidgetFormData = {
  currencyPair: CurrencyPair;
};

export const CurrencyExchangeWidgetForm = ({
  onSubmit,
  currencies,
  recipient,
  registration,
  setting,
  actionName = 'Exchange Now',
}: Props) => {
  const {
    isLoading,
    currencyOptions,
    currencyPairValue,
    onToAmountChange,
    onFromAmountChange,
    onFromSelectedCurrencyChange,
    onToSelectedCurrencyChange,
    comparisonAmount,
    error,
  } = useCurrencyExchangeWidget({
    initialCurrencyPairValues: toInitialCurrencyPairValues(currencies, recipient),
    currencies,
    registration,
    setting,
  });

  const {
    isLoading: isRequestCallbackLoading,
    mutate: createOpportunity,
    isSuccess: isRequestCallbackSuccess,
  } = useCreateOpportunity();

  const handleSubmit = (event: any) => {
    event.preventDefault();
    onSubmit({ currencyPair: currencyPairValue });
  };

  const support = useOutOfHours()
    ? {
        isLoading: isRequestCallbackLoading,
        text: `Request ${isRequestCallbackSuccess ? 'sent' : 'callback'}`,
        disabled: isRequestCallbackSuccess,
        onClick: () => createOpportunity(toNewOpportunity(registration, currencyPairValue)),
      }
    : {
        text: `Call now ${getSupportInfo(registration).phoneNumber}`,
        href: `tel:${getSupportInfo(registration).phoneNumber.replace(/\s+/g, '')}`,
      };

  const name = truncateLongName((recipient?.businessName || recipient?.firstName) ?? '');

  return (
    <Box as={'form'} maxW={400} onSubmit={handleSubmit}>
      <CurrencyExchangeWidget
        isLoading={isLoading}
        currencies={currencyOptions}
        currencyPairValue={currencyPairValue}
        onFromAmountChange={onFromAmountChange}
        onToAmountChange={onToAmountChange}
        onFromSelectedCurrencyChange={onFromSelectedCurrencyChange}
        onToSelectedCurrencyChange={onToSelectedCurrencyChange}
        recipient={recipient ? { isLocked: true, label: `${name} gets` } : undefined}
        support={support}
        comparison={!isLoading && comparisonAmount ? { text: comparisonAmount } : undefined}
        error={error}
      />
      <Button mt={6} type={'submit'} display={!isLoading && !error ? 'block' : 'none'}>
        {actionName}
      </Button>
    </Box>
  );
};

const toInitialCurrencyPairValues = (currencies: Currency[], recipient?: Recipient): CurrencyPair => {
  const toCurrencyCode = recipient?.currencyTo ?? exchangeWidgetConfig.initialToCurrency;
  const toAmountDecimal = recipient?.currencyTo
    ? extractDecimalPlaces(recipient.currencyTo, currencies)
    : exchangeWidgetConfig.initialCurrencyToDecimal;
  const fromCurrencyCode = getNextCurrencyCode({
    fromCurrency: exchangeWidgetConfig.initialFromCurrency,
    toCurrency: toCurrencyCode,
  });
  return {
    quoteId: undefined,
    conversionDate: undefined,
    fixedSide: 'sell',
    invertedRate: 0,
    rate: 0,
    fromCurrencyCode: fromCurrencyCode,
    fromAmountDecimal: exchangeWidgetConfig.initialFromCurrencyDecimal,
    toCurrencyCode: toCurrencyCode,
    toAmountDecimal: toAmountDecimal,
    fromAmount: exchangeWidgetConfig.initialFromAmount,
    toAmount: exchangeWidgetConfig.initialToAmount,
  };
};

const toNewOpportunity = (registration: Registration, currencyPair: CurrencyPair) => ({
  status: 'New',
  sendId: registration.sendId,
  parentId: registration.id,
  parentType: registration.type,
  currencyTo: currencyPair.toCurrencyCode,
  currencyFrom: currencyPair.fromCurrencyCode,
  clientBuyAmount: currencyPair.toAmount,
  clientSellAmount: currencyPair.fromAmount,
  clientRate: currencyPair.rate,
  fixedSide: currencyPair.fixedSide,
  owner: registration.owner as string,
});
