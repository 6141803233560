import React from 'react';
import { Box, FormControl, FormErrorMessage, FormHelperText, FormLabel } from '@chakra-ui/react';
import { Select, SelectedOption, ValueContainer } from '@sendpayments/react-shared/components/molecules';
import { countries } from '@sendpayments/js-utils/dist/const/defaults';

interface CountryOption {
  label: string;
  value: string;
}

export type CountrySelectProps = {
  id: string;
  label?: string;
  value?: string;
  placeholder?: string;
  helperText?: string;
  isInvalid?: boolean;
  errorText?: string;
  onCountryChange: (selectedCountry: CountryOption | null) => void;
};

export const CountrySelect: React.FC<CountrySelectProps> = ({
  id,
  label,
  value,
  placeholder,
  helperText,
  isInvalid = false,
  errorText,
  onCountryChange,
}: CountrySelectProps) => {
  const countryOptions: CountryOption[] = countries.map((country) => ({ label: country, value: country }));
  const selectedOption = (value?: string) => countryOptions.filter((option) => option.value === value);

  const formatOptionLabel = ({ value }, { context, selectValue }) => {
    if (context === 'value') {
      return <Box>{value}</Box>;
    } else if (context === 'menu') {
      return selectValue[0]?.value === value ? (
        <SelectedOption>
          <Box>{value}</Box>
        </SelectedOption>
      ) : (
        <Box>{value}</Box>
      );
    }
  };

  return (
    <FormControl id={id} isInvalid={isInvalid}>
      {label && (
        <FormLabel fontSize="sm" marginBottom="5px">
          {label}
        </FormLabel>
      )}
      <Select
        styles={{}}
        menuPlacement="auto"
        placeholder={placeholder}
        value={selectedOption(value)}
        options={countryOptions}
        onChange={onCountryChange}
        width="100%"
        isInvalid={isInvalid}
        formatOptionLabel={formatOptionLabel}
        components={{ ValueContainer }}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage display={errorText ? 'block' : 'none'}>{errorText}</FormErrorMessage>
    </FormControl>
  );
};
