import React, { ReactNode } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { InformationIcon } from '../icons/InformationIcon';

export interface StaticNotificationProps {
  children: ReactNode;
}

export const StaticNotification: React.FC<StaticNotificationProps> = ({ children }) => {
  return (
    <Flex backgroundColor="complimentary.base" p="16px" alignItems="center" borderRadius="4px">
      <Box>
        <InformationIcon color="secondary.tint" w="16px" h="16px" />
      </Box>
      <Box flexDirection="column" ml="16px">
        {children}
      </Box>
    </Flex>
  );
};
