import React from 'react';
import { Icon } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export const SNotification = (props) =>
  props.link ? (
    <Link className={`notification ${props.type}`} to={props.link}>
      <p className="small">
        {props.type === 'warning' && <Icon type="exclamation-circle" />}
        {props.type === 'success' && <Icon type="smile" />}
        {props.type === 'alert' && <Icon type="frown" />}
        <span>{props.content}</span>
      </p>
    </Link>
  ) : (
    <div className="notification">
      <p className="small">
        {props.type === 'warning' && <Icon type="exclamation-circle" />}
        {props.type === 'success' && <Icon type="smile" />}
        {props.type === 'alert' && <Icon type="frown" />}
        <span>{props.content}</span>
      </p>
    </div>
  );

SNotification.propTypes = {
  link: PropTypes.string,
  content: PropTypes.string.isRequired,
  type: PropTypes.string,
};

SNotification.defaultProps = {
  link: '',
  type: 'warning',
};
