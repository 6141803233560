import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Layout, Spin, Divider } from 'antd';
import CustomLayout from '@send-legacy-components/Common/CustomLayout';
import { SMenu } from '@send-legacy-components/UI/SMenu';
import { useRegistration } from '@send-data-hooks';
import styles from './LegacyLayout.module.less';

const { Content } = Layout;

const LegacyLayout = ({ loading, activeMenu, children }) => {
  const [{ data: registration, loading: loadingReg }] = useRegistration();
  const [collapsed, setCollapsed] = useState(true);

  return (
    <Spin spinning={loadingReg || loading}>
      <CustomLayout onClick={() => setCollapsed(true)} showSupportButton>
        <Content className="container">
          <Row>
            <SMenu activeItem={activeMenu} registration={registration} collapsed={collapsed} setCollapsed={setCollapsed} />
            <Divider className="top-menu-divider" />
            <div className={styles.sectionWrapper}>{children}</div>
          </Row>
        </Content>
      </CustomLayout>
    </Spin>
  );
};

LegacyLayout.propTypes = {
  loading: PropTypes.bool,
  activeMenu: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

LegacyLayout.defaultProps = {
  loading: false,
};

export default LegacyLayout;
