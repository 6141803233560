import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { SSubAreaTitle } from '@send-legacy-components/UI';
import { Form as NewRecipientForm } from '@send-legacy-components/Recipient';
import styles from './Form.module.less';
import { useFeature } from '@sendpayments/react-shared/hooks/useFeature'; // can be removed in the future
import { PD_1619_BENE_TEXT } from '@sendpayments/shared-constants/features';

const Form = ({ type, onSave, fixedCurrencyTo, onCancel }) => {
  const [
    {
      data: { isEnabled: isBeneTextEnabled },
    },
  ] = useFeature({
    feature: PD_1619_BENE_TEXT,
    functionName: 'beneText',
  });

  return (
    <>
      <Row>
        <Col>
          <Row className="flex">
            <SSubAreaTitle text="Recipient based on your currency" style={{ paddingRight: 0 }} icon="solution" />
            {isBeneTextEnabled && (
              <>
                <br />
                <p>
                  A Recipient is a person or a company that you are sending funds to. You will need to add at least one recipient
                  to your Send profile, this may also include yourself if you want to send funds to your own overseas bank account
                  by selecting the Myself option.
                </p>
                <p>
                  You may save as many recipients in your profile as you like for future use however, remember that you cannot
                  edit their details once saved so you will need to delete and re-add if you need to change the information.
                </p>
              </>
            )}
          </Row>
          <br />
        </Col>
      </Row>
      <NewRecipientForm
        submitText="Add recipient"
        beneType={type}
        cancelText="Back to recipients"
        hideCurrencyTo={!!fixedCurrencyTo}
        fixedCurrencyTo={fixedCurrencyTo}
        className={styles.container}
        onSubmit={onSave}
        onCancel={onCancel}
      />
    </>
  );
};

Form.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  fixedCurrencyTo: PropTypes.string,
  type: PropTypes.string,
};

Form.defaultProps = {
  type: undefined,
  fixedCurrencyTo: undefined,
};

export default Form;
