import React from 'react';
import { List } from '@chakra-ui/react';
import { RadioList } from '../../organisms/RadioList';
import { RadioOption } from '../../organisms/RadioList/RadioList';
import { HorizontalRadioItem } from '../HorizontalRadioItem';

export type HorizontalRadioProps = {
  name: string;
  options: RadioOption[];
  onRadioListChange: (value: string) => void;
  defaultValue?: string;
  errorMessage?: string;
};

/**
 * If error message omitted, considered valid
 */
export const HorizontalRadio: React.FC<HorizontalRadioProps> = (props) => {
  return (
    <>
      <List {...props}>
        <RadioList<string>
          defaultValue={props.defaultValue}
          errorMessage={props.errorMessage}
          radioListName={props.name}
          ListItemComponent={(p) => {
            return (
              <HorizontalRadioItem
                _first={{
                  borderTopLeftRadius: '5px',
                  borderBottomLeftRadius: '5px',
                }}
                borderRightWidth="0px"
                _last={{
                  borderTopRightRadius: '5px',
                  borderBottomRightRadius: '5px',
                  borderRightWidth: '1px',
                }}
                value={(p.radioProps.value as string) ?? ''}
                error={!!props.errorMessage}
                {...p}
              >
                {p.title ?? ''}
              </HorizontalRadioItem>
            );
          }}
          onRadioListChange={props.onRadioListChange}
          valid={!props.errorMessage}
          radioOptions={props.options}
          flexDirection="row"
          justifyContent="normal"
        />
      </List>
    </>
  );
};
