import React, { ReactNode } from 'react';
import { Grid, Flex, Heading, Box } from '@chakra-ui/react';
import { Footer } from '@send-base/atomic-components/atoms';

export interface ComparisonRegistrationTemplateProps {
  heading?: string;
  children: ReactNode;
  leftChildren?: ReactNode;
}

export const ComparisonRegistrationTemplate: React.FC<ComparisonRegistrationTemplateProps> = ({
  heading,
  children,
  leftChildren,
}): JSX.Element => {
  return (
    // bgColour isn't in theme, only used to match background on image
    <Grid gridTemplateColumns={{ base: '1fr', lg: '1fr 1fr' }} w="full" minHeight="100vh" bgColor="#f9f9f9">
      <Flex
        w="full"
        bgImage={{ base: '', lg: '/static/images/old-man-joe.png' }}
        bgSize="contain"
        bgRepeat="no-repeat"
        bgPosition="center"
        justify="center"
        align={{ base: 'center', lg: 'flex-end' }}
        px={{ base: '0', lg: '6' }}
        gridRow={{ base: 2, lg: 1 }}
        h={{ base: 'fit-content', lg: 'auto' }}
        mt={{ base: 'auto', lg: '8' }}
      >
        {leftChildren}
      </Flex>
      <Flex
        w="full"
        justify="center"
        align="center"
        bgColor="white"
        px={{ base: '4', lg: 'auto' }}
        pt={{ base: '15', lg: 'auto' }}
        pb={{ base: '12', lg: 'auto' }}
      >
        <Box bg="white" borderRadius={8} p={{ base: 0, md: '49' }} w="100%" maxW="514px">
          <Heading as="h2" variant="heading2" mb={2} textAlign="center" lineHeight={1.267}>
            {heading}
          </Heading>
          {children}
          <Footer mt={4} />
        </Box>
      </Flex>
    </Grid>
  );
};
