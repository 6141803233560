import React from 'react';
import { Switch, Router } from 'react-router-dom';
import { usePageViews } from '@sendpayments/react-shared/hooks';
import { trackPageView } from '@sendpayments/js-utils/dist/services/ads-manager';
import { GridProvider } from '@sendpayments/react-shared/design-system/grid-system';
import history from '@send-services/history';
import { getBreakpoints } from '@send-services/breakpoints';
import { ProfileSetting, SecuritySetting, NotificationSetting } from '@send-base/components/Account';
import { analytics } from '@sendpayments/js-utils/dist';
import ProtectedRoute from './ProtectedRoute';
import { SendPaymentsThemeWrapper } from '@send-common-components/SendPaymentsTheme';

const breakpoints = getBreakpoints();

export const Routes = () => {
  usePageViews(() => {
    analytics.push({
      page: {
        path: window.location.pathname,
      },
    });
    trackPageView();
  });

  return (
    <Switch>
      <ProtectedRoute exact path="/setting/profile" render={ProfileSetting} />
      <ProtectedRoute exact path="/setting/security" render={SecuritySetting} />
      <ProtectedRoute exact path="/setting/notification" render={NotificationSetting} hideFromAffiliates={['pexa']} />
    </Switch>
  );
};

export default () => (
  // for a while we are going to have a little problem. that the new grid system can be use only in new screen. we
  // cannot have a combination of 2 grid system on hte same screen.
  <GridProvider breakpoints={breakpoints}>
    <Router history={history}>
      <SendPaymentsThemeWrapper>
        <Routes />
      </SendPaymentsThemeWrapper>
    </Router>
  </GridProvider>
);
