import React, { useState } from 'react';
import {
  Button,
  Stack,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Text,
  Box,
  Flex,
  Heading,
  Link,
  useDisclosure,
} from '@chakra-ui/react';
import { CountrySelect } from '../..';
import { HorizontalRadio, SelectorWithFlags } from '../../../molecules';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import {
  transferFrequency as defaultTransferFrequency,
  transferPurposes as defaultTransferPurposes,
  currencies as defaultCurrencies,
  transferAmounts as defaultTransferAmounts,
} from '@sendpayments/js-utils/dist/const/defaults';
import { SendForm } from '../types';
import { logger } from '@sendpayments/js-utils/dist/services/logger';
import { analytics } from '@sendpayments/js-utils/dist';
import { CurrencySelect, InputField, Select, ValueContainer } from '@sendpayments/react-shared/components/molecules';
import { useToast } from '@sendpayments/react-shared/components/molecules/Toast';
import { useTransferOptions } from './util';
import { SimpleModal } from '../../';
import { CashDollarIcon } from '@sendpayments/react-shared/components/Icons/CashDollarIcon';

export const firstTransferSchema = yup.object({
  estimatedValue: yup.string().required('Please select a sending amount'),
  currencyFrom: yup
    .string()
    .required('Please Select a currency to send from')
    .notOneOf([yup.ref('currencyTo')], 'Currency to must be different to currency from'),
  currencyTo: yup
    .string()
    .required('Please Select a currency to send to')
    .notOneOf([yup.ref('currencyFrom')], 'Currency to must be different to currency from'),
  countryFrom: yup.string().required('Please select a country to send from'),
  countryTo: yup.string().required('Please select a country to send to'),
  purpose: yup.string().required('Please select a purpose'),
  otherPurpose: yup.string().when('purpose', {
    is: 'Other',
    then: yup.string().required('Please enter another purpose'),
  }),
  frequency: yup.string().required('Please select a frequency'),
  fundsFromCustomer: yup.string().required('Please select if you are sending from bank'),
});

const unsupportedCurrencyPairs = new Set(['ILS-IDR', 'NZD-BRL', 'ZAR-EUR', 'MXN-CAD', 'NZD-INR', 'INR-AUD', 'AUD-MKD']);

export type FirstTransferData = yup.InferType<typeof firstTransferSchema> & { fundsGoingtoDiffPerson: string };

const defaultFormValues = {
  estimatedValue: '',
  currencyFrom: '',
  currencyTo: '',
  countryFrom: '',
  countryTo: '',
  purpose: '',
  otherPurpose: '',
  frequency: '',
  fundsFromCustomer: '',
  fundsGoingtoDiffPerson: '',
};

export interface FirstTransferFormProps extends SendForm<FirstTransferData> {
  transferPurposes?: Array<string> & ['Other'];
  countries?: Array<string>;
  transferAmounts?: Array<string>;
  transferFrequencies?: Array<string>;
}

export const FirstTransferForm = ({
  onSubmit,
  isLoading,
  initialValues,
  transferPurposes,
  transferAmounts,
  transferFrequencies,
}: FirstTransferFormProps) => {
  const toast = useToast();
  const { transferAmountOptions, currencyOptions, transferPurposeOptions, transferFrequencyOptions } = useTransferOptions(
    {
      transferPurposes,
      transferAmounts,
      transferFrequencies,
    },
    { defaultTransferPurposes, defaultTransferAmounts, defaultTransferFrequency, defaultCurrencies },
  );

  const checkInvalidCurrencyPairing = (currencyFrom: string, currencyTo: string) => {
    if (unsupportedCurrencyPairs.has(`${currencyFrom}-${currencyTo}`)) {
      onOpen();
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Formik
      initialValues={{ ...defaultFormValues, ...initialValues }}
      validateOnMount={false}
      validationSchema={firstTransferSchema}
      onSubmit={async (values, actions) => {
        try {
          await onSubmit(values);
          analytics.push({ action: { type: 'form_submit', data: { name: 'FirstTransferForm' } } });
        } catch (error) {
          logger.error('FirstTransferForm', 'ERROR: ', error);
          actions.setSubmitting(false);
          toast({
            variant: 'negative',
            title: 'Something went wrong on our end. Please try again!',
          });
        }
      }}
    >
      {(props) => (
        <Form noValidate>
          <Stack spacing={4} textAlign="left">
            {/* Currency */}
            <Stack direction="row">
              <Field name="currencyFrom">
                {({ field, form }) => (
                  <FormControl id={field.name} isInvalid={form.errors[field.name] && form.touched[field.name]}>
                    <FormLabel mb={1}>You send</FormLabel>
                    <CurrencySelect
                      value={currencyOptions.find((item) => item.value === field.value) || { label: 'Not selected', value: '' }}
                      isInvalid={form.errors[field.name] && form.touched[field.name]}
                      onSelect={(option) => {
                        field.onChange(field.name)(option?.value);
                        checkInvalidCurrencyPairing(option?.value, props.values.currencyTo);
                      }}
                      currencies={[{ label: 'All currencies', options: currencyOptions }]}
                      dropdownTarget={SelectorWithFlags}
                      anchor="left"
                    />
                    <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="currencyTo">
                {({ field, form }) => (
                  <FormControl id={field.name} isInvalid={form.errors[field.name] && form.touched[field.name]}>
                    <FormLabel mb={1}>Recipient gets</FormLabel>
                    <CurrencySelect
                      value={currencyOptions.find((item) => item.value === field.value) || { label: 'Not selected', value: '' }}
                      isInvalid={form.errors[field.name] && form.touched[field.name]}
                      onSelect={(option) => {
                        field.onChange(field.name)(option?.value);
                        checkInvalidCurrencyPairing(props.values.currencyFrom, option?.value);
                      }}
                      currencies={[{ label: 'All currencies', options: currencyOptions }]}
                      dropdownTarget={SelectorWithFlags}
                    />
                    <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <SimpleModal
                open={isOpen}
                onPrimaryAction={() => {
                  props.setFieldValue('currencyFrom', defaultFormValues.currencyFrom);
                  props.setFieldValue('currencyTo', defaultFormValues.currencyTo);
                  onClose();
                }}
                onClose={() => {
                  props.setFieldValue('currencyFrom', defaultFormValues.currencyFrom);
                  props.setFieldValue('currencyTo', defaultFormValues.currencyTo);
                  onClose();
                }}
                primaryButtonText="Proceed"
                key="currency-pair-modal"
              >
                <Flex flexDir="column">
                  <CashDollarIcon w={'8em'} h={'8em'} alignSelf="center" mb={1} />
                  <Heading as="h1" fontWeight="bold" fontSize="1.4rem" lineHeight="1" paddingStart={'.1rem'} paddingEnd={'.1rem'}>
                    We are unable to service the selected currency pairing.
                  </Heading>
                  <Box mt={3} fontSize="md" p={1}>
                    <Text as="span">To see a list of currency pairings we are unable to serve, please</Text>{' '}
                    <Link
                      href="https://help.sendpayments.com/en/articles/4617289-which-currencies-can-i-convert-my-money-to"
                      isExternal
                    >
                      click here
                    </Link>
                    {'. '}
                    <Text as="span">
                      To choose a different currency pairing, please click{' '}
                      <Text as="span" fontWeight="bold">
                        Proceed
                      </Text>
                      .
                    </Text>
                  </Box>
                </Flex>
              </SimpleModal>
            </Stack>
            {/* Estimated Value  */}
            <Field name="estimatedValue">
              {({ field, form }) => (
                <FormControl id={field.name} isInvalid={form.errors[field.name] && form.touched[field.name]}>
                  <FormLabel mb={1}>How much are you planning to send?</FormLabel>
                  <Select
                    placeholder={'1K - 5K'}
                    value={transferAmountOptions.find((item) => item.value === field.value)}
                    options={transferAmountOptions}
                    onChange={(option) => field.onChange(field.name)(option?.value)}
                    width="100%"
                    isInvalid={form.errors[field.name] && form.touched[field.name]}
                    components={{ ValueContainer }}
                  />
                  <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            {/* Country From */}
            <Field name="countryFrom">
              {({ field, form }) => (
                <CountrySelect
                  id={field.name}
                  label="Country sending from"
                  value={field.value}
                  onCountryChange={(option) => field.onChange(field.name)(option?.value)}
                  placeholder={'Select your sending country...'}
                  isInvalid={form.errors[field.name] && form.touched[field.name]}
                  errorText={form.errors[field.name]}
                />
              )}
            </Field>

            {/* Country To */}
            <Field name="countryTo">
              {({ field, form }) => (
                <CountrySelect
                  id={field.name}
                  label="Country receiving"
                  value={field.value}
                  onCountryChange={(option) => field.onChange(field.name)(option?.value)}
                  placeholder={'Select your country receiving...'}
                  isInvalid={form.errors[field.name] && form.touched[field.name]}
                  errorText={form.errors[field.name]}
                />
              )}
            </Field>

            {/* Purpose */}
            <Field name="purpose">
              {({ field, form }) => (
                <FormControl id={field.name} isInvalid={form.errors[field.name] && form.touched[field.name]}>
                  <FormLabel mb={1}>Transfer purpose</FormLabel>
                  <Select
                    placeholder={'Select from list'}
                    value={transferPurposeOptions.find((item: { value: string }) => item.value === field.value)}
                    options={transferPurposeOptions}
                    onChange={(option) => field.onChange(field.name)(option?.value)}
                    width="100%"
                    isInvalid={form.errors[field.name] && form.touched[field.name]}
                    components={{ ValueContainer }}
                  />
                  <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            {/* Other Purpose */}
            {props.values.purpose === 'Other' && (
              <Field name="otherPurpose">
                {({ field, form }) => (
                  <InputField
                    id={field.name}
                    label="Other Purpose"
                    placeholder="Enter your purpose here"
                    isInvalid={!!form.errors[field.name] && form.touched[field.name]}
                    isValid={!form.errors[field.name] && !!field.value}
                    errorText={form.errors[field.name]}
                    inputProps={{
                      ...field,
                      size: 'md',
                    }}
                  />
                )}
              </Field>
            )}

            {/* Frequency */}
            <Field name="frequency">
              {({ field, form }) => (
                <FormControl id={field.name} isInvalid={form.errors[field.name] && form.touched[field.name]}>
                  <FormLabel mb={1}>Expected frequency for transfers</FormLabel>
                  <Select
                    placeholder={'Select your frequency of transfers...'}
                    value={transferFrequencyOptions.find((item: { value: string }) => item.value === field.value)}
                    options={transferFrequencyOptions}
                    onChange={(option) => field.onChange(field.name)(option?.value)}
                    width="100%"
                    isInvalid={form.errors[field.name] && form.touched[field.name]}
                    components={{ ValueContainer }}
                  />
                  <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            {/* Funds From Customer */}
            <Field name="fundsFromCustomer">
              {({ field, form }) => (
                <FormControl id={field.name} isInvalid={form.errors[field.name] && form.touched[field.name]}>
                  <FormLabel mb={1}>Are you sending payments from your own bank account?</FormLabel>
                  <HorizontalRadio
                    name="bank-account"
                    defaultValue={field.value}
                    options={[
                      { title: 'Yes', value: 'yes' },
                      { title: 'No', value: 'no' },
                    ]}
                    onRadioListChange={(option) => field.onChange(field.name)(option)}
                  />
                  <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Button isLoading={props.isSubmitting || isLoading} type="submit" alignSelf="center" fontSize="md">
              {'Finish onboarding'}
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
