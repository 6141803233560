import { CoconutTypes } from '@sendpayments/shared-constants/types';
import { useActivitiesByStatus, useCoconut, useRegistration } from '@send-data-hooks';
import { Details as ConversionDetails } from '@send-legacy-components/Conversion';
import { enrichConversion } from '@send-legacy-utils/conversionHelpers';
import { get, post } from '@send-base/utilities/endpoint';
import { message, Spin } from 'antd';
import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const poliErrorMessages = {
  Cancelled: 'Your POLi payment has been cancelled. Please retry or select another payment method.',
  Failed: 'An error has occurred in processing your POLi payment. Please retry or select another payment method.',
  ReceiptUnverified:
    'An error might have occurred in processing your POLi payment. ' +
    'Please check your bank account before processing another transaction to ensure ' +
    'that funds have not been deducted. Contact our Support team if you have any questions.',
  TimedOut: 'An error has occurred in processing your POLi payment. Please retry or select another payment method.',
  default: 'An error has occurred in processing your POLi payment. Please retry or select another payment method.',
};

const loadPdf = async (id, type) => {
  if (id && type === CoconutTypes.CONVERSION) {
    const params = '?dynamicPayInAccount=true';
    const resultPdf = await get(`/pdf/conversion/${id}${params}`).catch(() =>
      message.error('Error downloading PDF, please try again shortly'),
    );
    return resultPdf.data;
  }

  if (id && type === CoconutTypes.PAYMENT) {
    const response = await get(`/pdf/payment/${id}`).catch(() =>
      message.error('Error downloading PDF, please try again shortly'),
    );
    return response.data;
  }

  return undefined;
};

const checkPoli = async (id) => {
  const hide = message.loading('Checking POLi payment status ...', 0);

  const { status } = await post('/poli/check', {
    data: {
      convId: id,
    },
  });
  hide();
  if (status) {
    if (status === 'Completed') {
      message.success('Your POLi payment has been completed successfully.', 5);
    } else {
      message.error(poliErrorMessages[status] || poliErrorMessages.default, 5);
    }
  }
};

const useConversion = ({ id, email }) => {
  const [
    {
      data: { paymentsByConvId, docsByParentId },
      loading: loadingActivities,
    },
  ] = useActivitiesByStatus({ email });
  const [{ data: conv, loading: loadingConversion }] = useCoconut({ id: id, type: CoconutTypes.CONVERSION });

  const isLoading = loadingConversion || loadingActivities;

  if (!isLoading && conv) {
    const conversionPayments = paymentsByConvId[id];
    const conversionDocs = docsByParentId[id];
    const enrichedConversion = enrichConversion(conv, conversionPayments, conversionDocs);

    return { conv: enrichedConversion, paymentsByConvId, isLoading };
  }

  return { conv, paymentsByConvId, isLoading };
};

const Conversion = memo(({ id, currencyCloudReference, sendId, poliToken }) => {
  const history = useHistory();
  const [{ data: reg }] = useRegistration();

  const { conv, paymentsByConvId, isLoading } = useConversion({ id: id, email: reg.email });

  useEffect(() => {
    if (poliToken) {
      checkPoli(id);
    }
  }, [poliToken]);

  useEffect(() => {
    if (!isLoading && !conv?.id) {
      history.push('/404');
    }
  }, [isLoading, conv]);
  return !conv ? (
    <Spin />
  ) : (
    <ConversionDetails
      loading={isLoading}
      sellCurrency={conv.sellCurrency}
      bookedSellAmount={conv.bookedSellAmount}
      bookedBuyAmount={conv.bookedBuyAmount}
      bookedClientRate={conv.bookedClientRate}
      buyCurrency={conv.buyCurrency}
      status={conv.status}
      legacyContractId={conv.legacyContractId}
      id={conv.id}
      forwardContract={conv.forwardContract}
      createdAt={conv.createdAt}
      conversionDate={conv.conversionDate}
      businessName={reg.businessName}
      firstName={reg.firstName}
      lastName={reg.lastName}
      sendId={sendId}
      currencyCloudReference={currencyCloudReference}
      conversion={conv}
      payments={paymentsByConvId[id]}
      onDownloadClick={(downloadId, type) => loadPdf(downloadId, type)}
    />
  );
});

Conversion.propTypes = {
  id: PropTypes.string,
  currencyCloudReference: PropTypes.string,
  sendId: PropTypes.string,
  poliToken: PropTypes.string,
};

Conversion.defaultProps = {
  id: undefined,
  currencyCloudReference: undefined,
  sendId: undefined,
  poliToken: undefined,
};

export default Conversion;
