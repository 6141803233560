import React, { useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { Box, Flex, useDisclosure, Heading, Text, Link } from '@chakra-ui/react';
import { StepTemplate } from '@send-base/atomic-components/templates';
import {
  PageSpinner,
  ReviewDetailsData,
  ReviewDetailsForm,
  StepData,
  SimpleModal,
  CurrencyExchangeWidgetForm,
  CurrencyExchangeWidgetFormData,
} from '@send-base/atomic-components/organisms';
import { useToast } from '@sendpayments/react-shared/components/molecules/Toast';
import { CashDollarIcon } from '@sendpayments/react-shared/components/Icons';

import { logger } from '@sendpayments/js-utils/dist/services/logger';
import { post } from '@send-base/utilities/endpoint';

import { CoconutTypes } from '@sendpayments/shared-constants/types';
import { paymentActivityStatus } from '@send-const/statuses';

import { useConversion, useCurrentEmail } from '@send-data-hooks';
import { useRecipient, useSetting, useRegistration, useCurrencies } from '@send-base/infrastructure/query';
import { appConfig } from '@send-base/app.config';

import { useFeature } from '@sendpayments/react-shared/hooks/useFeature';
import { PD_2160_VRN_CREATION } from '@sendpayments/shared-constants/features';

type RecipientParams = {
  id: string;
};
const steps: StepData[] = [
  {
    heading: 'Transfer amount',
    step: 'Amount',
  },
  {
    heading: 'Review transfer details',
    step: 'Review',
  },
];

type Conversion = {
  quoteId: string;
  bookedBuyAmount: number;
  bookedSellAmount: number;
  buyCurrency: string;
  sellCurrency: string;
  conversionDate: string;
  fixedSide: 'buy' | 'sell';
  parentId: string;
  parentType: string;
};

export const RecipientTransferPage: React.FC<RouteComponentProps<RecipientParams>> = (props) => {
  const history = useHistory();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [stepForm, goToStepForm] = useState(0);
  const [isSubmitting, setSubmitting] = useState(false);
  const [exchangeData, setExchangeData] = useState<CurrencyExchangeWidgetFormData>();
  const [reasonData, setReasonData] = useState<ReviewDetailsData>();

  // Old hooks copying logic from old flows so not sure about some things.
  const [{ data: ownerEmail }] = useCurrentEmail();
  const [, { partialCreateOrUpdateEntity }] = useConversion({ id: undefined });
  // React Query hooks
  const { data: setting, isLoading: isSettingLoading } = useSetting();
  const { data: registration, isLoading: isRegistrationLoading } = useRegistration({ params: { owner: ownerEmail ?? '' } });
  const { data: currencies, isLoading: isCurrenciesLoading } = useCurrencies();
  const { data: recipient, isLoading: isRecipientLoading } = useRecipient({
    params: { owner: ownerEmail ?? '', recipientId: props.match.params.id },
    options: {
      onSettled: (data) => validRecipientGuard(data, history),
    },
  });

  const [
    {
      data: { isEnabled: isCreateVRNEnabled },
    },
  ] = useFeature({
    feature: PD_2160_VRN_CREATION,
    functionName: 'VRNDetailsCreate',
  });

  if (isRecipientLoading || isSettingLoading || isRegistrationLoading || isCurrenciesLoading) {
    return <PageSpinner />;
  }

  const handleCurrency = (values: CurrencyExchangeWidgetFormData) => {
    setExchangeData(values);
    // Since only two steps
    goToStepForm(1);
  };

  const handleReason = (values: ReviewDetailsData) => {
    setReasonData(values);
    onOpen();
  };

  const handleSubmit = async () => {
    try {
      onClose();
      setSubmitting(true);
      if (!partialCreateOrUpdateEntity) throw new Error('Error on storing data');
      if (!exchangeData || !reasonData || !registration || !recipient) throw new Error('Data not set');

      const { currencyPair } = exchangeData;

      if (!currencyPair || !currencyPair.quoteId || !currencyPair.conversionDate) throw new Error('invalid data');

      // Create conversion
      const conversion: Conversion & { createdAt: string } = {
        quoteId: currencyPair.quoteId,
        bookedBuyAmount: currencyPair.toAmount,
        bookedSellAmount: currencyPair.fromAmount,
        buyCurrency: currencyPair.toCurrencyCode,
        sellCurrency: currencyPair.fromCurrencyCode,
        conversionDate: currencyPair.conversionDate,
        fixedSide: currencyPair.fixedSide,
        parentId: registration.id,
        parentType: registration.type,
        createdAt: new Date().toISOString(),
      };

      const { id, status } = await post('/dealing/customer/create-conversion', {
        data: conversion,
      });

      const resultConversion = {
        id,
        status,
        type: CoconutTypes.CONVERSION,
        ...conversion,
      };

      partialCreateOrUpdateEntity(resultConversion);

      // Attach recipient and book payment.
      try {
        const paymentDetails = {
          parentId: registration.id,
          parentType: registration.type,
          amount: currencyPair.toAmount,
          currency: currencyPair.toCurrencyCode,
          reference: reasonData.reference,
          beneficiaryId: recipient.id,
          reason: reasonData.reason, // This might need to be changed to description currently (code)
          purposeCode: reasonData.reason,
          paymentDate: currencyPair.conversionDate,
          conversionId: id,
        };

        const payment = await post('/dealing/customer/create-payment', {
          data: paymentDetails,
        });

        partialCreateOrUpdateEntity({
          id: payment.id,
          type: CoconutTypes.PAYMENT,
          status: payment.status || paymentActivityStatus.UNDERWAY,
          ...paymentDetails,
        });
      } catch (error) {
        logger.error('handleSubmit', 'error', error);
        toast({
          variant: 'negative',
          title: 'Error attaching recipient',
        });
      }

      // Still redirect to conversion if conversion was successful.
      history.push({
        pathname: `/conversion/${id}`,
        state: { isCreateVRNEnabled: isCreateVRNEnabled, currency: conversion.sellCurrency },
      });
    } catch (error) {
      const { data } = (error as any).response ?? {};
      logger.error('RecipientTransferPage', 'error with creating conversion and payment', error);
      if (data?.errors?.[0] === 'quoteId is invalid') {
        toast({
          variant: 'negative',
          title: 'Your quote has expired. Please click the back button and select a new quote.',
        });
      } else {
        toast({
          variant: 'negative',
          title: 'Something went wrong on our end. Please try again!',
        });
      }
      setSubmitting(false);
    }
  };

  const handleBack = () => {
    goToStepForm((prev) => prev - 1);
  };

  const onCancel = () => {
    history.push('/dashboard');
  };

  return (
    <StepTemplate
      progress={{ current: stepForm, steps: steps }}
      onNavigateBack={handleBack}
      onCancel={onCancel}
      modal={{
        title: 'Are you sure you’d like to cancel this transfer?',
        subtitle: 'You may need to start again unless you continue.',
        cancel: 'No, continue transfer',
        confirm: 'Yes, cancel',
      }}
    >
      <SimpleModal
        open={isOpen}
        primaryButtonText="Confirm"
        secondaryButtonText="Back"
        onClose={onClose}
        onSecondaryAction={onClose}
        onPrimaryAction={handleSubmit}
        key={'ConfirmModal'}
      >
        <CashDollarIcon color="text.base" boxSize={{ base: '57px', lg: '83px' }} />
        <Heading as="h3" variant="heading3" mt={4}>
          Confirm your transfer
        </Heading>
        <Text>
          {"By clicking 'Confirm', you're entering a contract and agreeing to our "}
          <Link as="a" color="text.base" pr={1} href={appConfig.sendTermsAndConditionsUrl} isExternal>
            Terms and Conditions.
          </Link>
          {'We will automatically redirect you to the payment instructions.'}
        </Text>
      </SimpleModal>
      <Flex align="center" justify="center" display="flex">
        <Box w="100%" maxWidth={{ base: '100%', md: '800px' }}>
          {stepForm === 0 && (
            <CurrencyExchangeWidgetForm
              currencies={currencies!}
              recipient={recipient!}
              registration={registration!}
              setting={setting!}
              onSubmit={handleCurrency}
              actionName={'Continue'}
            />
          )}
          {stepForm === 1 && (
            <ReviewDetailsForm
              onSubmit={handleReason}
              isLoading={isSubmitting}
              recipient={recipient!}
              currencyPair={exchangeData?.currencyPair}
            />
          )}
        </Box>
      </Flex>
    </StepTemplate>
  );
};

const validRecipientGuard = (data, history) => {
  if (!data) {
    history.push('/dashboard');
  }
};
