import { query } from '@send-base/utilities/endpoint';
import * as queries from '@sendpayments/graphql/queries';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
import { useFetch } from '@sendpayments/react-shared/hooks';

const fetchDocuments = async ({ owner, type }) => {
  const params = {
    owner,
    type: { eq: type },
    sortDirection: 'DESC',
    limit: 1000, // hopefully a user has less than 1000 convs
  };

  const response = await query(queries.listCoconutsByOwner, {
    params,
  });

  return response;
};

const useDocuments = ({ owner }) => useFetch({ owner, type: CoconutTypes.DOCUMENT }, fetchDocuments, { initialState: [] });

export default useDocuments;
