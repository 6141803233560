import Amplify, { API, Auth, graphqlOperation } from 'aws-amplify';
import AWS from 'aws-sdk';
import awsconfig from '@send-base/aws-exports';
import { graphqlOperationEx } from '@sendpayments/graphql/fields';
import * as mutations from '@sendpayments/graphql/mutations';
import * as queries from '@sendpayments/graphql/queries';
import * as subscriptions from '@sendpayments/graphql/subscriptions';
import awsenvconfig from '@sendpayments/graphql/aws-env-config';
import { logger } from '../logger';

let isLoggerEnabled;
let isInitialized;
let isAppOnline = true;

class ConnectionError extends Error {
  constructor() {
    super('There is no connection right now, please try again later...');
    this.name = 'ConnectionError';
  }
}

const queriesEntries = Object.entries(queries);
const mutationsEntries = Object.entries(mutations);
const subscriptionsEntries = Object.entries(subscriptions);

const log = {
  error: (method, err, ex) => {
    if (isLoggerEnabled) {
      logger.error(`@send-services/endpoint - Method: ${method}`, `Error: ${err}`, ex);
    }
  },
  info: (method, info, data) => {
    if (isLoggerEnabled) {
      logger.info(`@send-services/endpoint - Method: ${method}`, `Info: ${info}`, data);
    }
  },
};

// we can now implement caching if we want
export const initialize = ({ env, useLogging = false }) => {
  if (!isInitialized) {
    logger.info('initialize', `awsconfig:`, awsconfig);

    isInitialized = true;

    const awsEnvConfig = awsenvconfig[env];

    const customApis = [...awsEnvConfig.aws_cloud_logic_custom].map((api) =>
      env === 'prod' && api.name === 'LedgerAPIv2' ? { ...api, endpoint: 'https://ledger-v2-api.sendpayments.com/v1' } : api,
    );

    Amplify.configure({ ...awsEnvConfig, ...awsconfig, aws_cloud_logic_custom: customApis });

    logger.info('initialize', `apiVersions:`, AWS.config.apiVersions);

    isLoggerEnabled = useLogging;
  }
};

export const getQueryF = (queryName) => {
  if (!queryName || !queries[queryName]) {
    throw new Error('must be present and defined in `@sendpayments/graphql/queries` or be a new function');
  }

  return queries[queryName];
};

export const getMutationF = (mutationName) => {
  if (!mutationName || !mutations[mutationName]) {
    throw new Error('must be present and defined in `@sendpayments/graphql/mutations` or be a new function');
  }

  return mutations[mutationName];
};

const getName = (func, entries) => {
  const [key] = entries.find(([, f]) => f === func);

  if (key) {
    return key;
  }

  return undefined;
};

export const query = async (queryF, { params = {}, projection } = {}) => {
  const queryName = getName(queryF, queriesEntries) || 'newQuery';

  try {
    if (!queryF) {
      throw new Error('must be present and defined in `@sendpayments/graphql/queries` or be a new function');
    }

    log.info('query', `getting the query name`);

    log.info('query', `query name`, queryName);
    log.info('query', `querying for ${queryName} with params:`, params);

    let queryObj;
    if (Object.keys(params).length > 0) {
      queryObj = graphqlOperationEx(
        queryF,
        {
          ...params,
        },
        projection,
      );
    } else {
      queryObj = graphqlOperation(queryF, projection);
    }

    log.info('query', `querying for ${queryName} to execute:`, queryObj);

    const { data } = await API.graphql(queryObj);

    log.info('query', `querying for ${queryName} with result:`, data);

    return data && data[queryName];
  } catch (ex) {
    log.error('query', `Error while querying for ${queryName} with ex:`, ex);
    throw ex;
  }
};

export const get = async (url, { data } = {}, apiName = 'SendApi') => {
  try {
    if (!url) {
      throw new Error('url need to be present');
    }

    const session = await Auth.currentSession();

    if (!session || !session.idToken || !session.idToken.jwtToken) {
      throw new Error('User not authenticated');
    }

    log.info('get', `Executing get with url ${url} and data:`, data);

    if (!isAppOnline) {
      throw new ConnectionError();
    }

    const params = {
      headers: {
        Token: session.idToken.jwtToken,
      },
    };

    if (data) {
      params.queryStringParameters = { ...data };
    }

    const response = await API.get(apiName, url, params);

    log.info('get', `Result get from ${apiName} with url ${url} response:`, response);

    return response;
  } catch (ex) {
    log.error('get', `Error while executing get to ${url}`, ex);
    throw ex;
  }
};

export const put = async (url, { data } = {}) => {
  try {
    if (!url) {
      throw new Error('url need to be present');
    }

    const session = await Auth.currentSession();

    if (!session || !session.idToken || !session.idToken.jwtToken) {
      throw new Error('User not authenticated');
    }

    log.info('put', `Executing put with url ${url} and data:`, data);

    const params = {
      headers: {
        Token: session.idToken.jwtToken,
      },
      body: Object.keys(data).length > 0 ? data : undefined,
    };

    const response = await API.post('SendApi', url, params);

    log.info('put', `Result put with url ${url} response:`, response);

    return response;
  } catch (ex) {
    log.error('put', `Error while executing put to ${url}`, ex);
    throw ex;
  }
};

export const post = async (url, { data } = {}, apiName = 'SendApi') => {
  try {
    if (!url) {
      throw new Error('url need to be present');
    }

    const session = await Auth.currentSession();

    if (!session || !session.idToken || !session.idToken.jwtToken) {
      throw new Error('User not authenticated');
    }

    log.info('post', `Executing post to ${apiName} with url ${url} and data:`, data);

    if (!isAppOnline) {
      throw new ConnectionError();
    }

    const params = {
      headers: {
        Token: session.idToken.jwtToken,
      },
      body: Object.keys(data).length > 0 ? data : undefined,
    };

    const response = await API.post(apiName, url, params);

    log.info('post', `Result post to ${apiName} with url ${url} response:`, response);

    return response;
  } catch (ex) {
    log.error('post', `Error while executing post to ${url}`, ex);
    throw ex;
  }
};

export const mutation = async (mutationF, { data: dataToSend = {}, projection }) => {
  const mutationName = getName(mutationF, mutationsEntries) || 'newMutation';

  try {
    if (!mutationF) {
      throw new Error('must be present and defined in `@sendpayments/graphql/mutations`');
    }

    log.info('mutation', `mutation for ${mutationName} with params:`, dataToSend);

    const mutationObj = graphqlOperationEx(
      mutationF,
      {
        input: dataToSend,
      },
      projection,
    );

    log.info('mutation', `mutation with ${mutationName} to execute:`, mutationObj);

    const { data } = await API.graphql(mutationObj);

    log.info('mutation', `mutation with ${mutationName} with result:`, data);

    return data && data[mutationName];
  } catch (ex) {
    log.error('mutation', `Error while mutating for ${mutationName} with ex:`, ex);
    throw ex;
  }
};

export const subscribe =
  (subscriptionF, { params = {} } = {}) =>
  (onSubscription, onError) => {
    const subscriptionName = getName(subscriptionF, subscriptionsEntries);

    try {
      if (!subscriptionF) {
        throw new Error('must be present and defined in `@sendpayments/graphql/subscriptions`');
      }

      if (!onSubscription) {
        throw new Error('subscribe must be present param onSubscription');
      }

      const subscriptionObj = graphqlOperationEx(subscriptionF, params);

      log.info('subscribe', `subscribing for ${subscriptionName} to execute:`, subscriptionObj);

      if (!isAppOnline) {
        throw new ConnectionError();
      }

      const subObject = API.graphql(subscriptionObj).subscribe({
        next: (subResult) => {
          log.info('subscribe', `subscription for ${subscriptionName} with result:`, subResult);
          const data = subResult && subResult.value && subResult.value.data && subResult.value.data[[subscriptionName]];
          onSubscription(data);
        },
        error: (err) => {
          log.error('subscribe', `subscription for ${subscriptionName} throw an error`, err);

          if (onError) {
            onError(err);
          }
        },
      });

      return () => {
        log.info('subscribe', `REMOVING subscription for ${subscriptionName} to execute`);
        subObject.unsubscribe();
      };
    } catch (ex) {
      log.error('subscribe', `Error while querying for ${subscriptionName} with ex:`, ex);
      throw ex;
    }
  };
