import React from 'react';
import { useRate } from '@send-base/infrastructure/query/useRate';
import { Currency } from '@send-base/infrastructure/query/useCurrencies';

import { Registration } from '@send-base/types';
import { Setting } from '@send-base/infrastructure/query/useSetting';
import { exchangeWidgetConfig } from './config';
import {
  extractDecimalPlaces,
  getBankComparisonRate,
  getFromAmount,
  getToAmount,
  isBlacklistedCurrency,
  toCurrencyOptions,
} from './currency.utils';

import { useAudExchangeAmountLimit } from './useAudExchangeAmountLimit';
import { getCurrencyString } from '@sendpayments/js-utils/dist/utils/currency';

type State = {
  currencyPair: CurrencyPair;
  error?: WidgetError;
};

type Action =
  | { type: 'success'; currencyPair: CurrencyPair }
  | { type: 'failure'; currencyPair?: CurrencyPair; error?: WidgetError };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'success':
      return { ...state, currencyPair: action.currencyPair, error: undefined };
    case 'failure':
      return { ...state, currencyPair: action.currencyPair ?? state.currencyPair, error: action.error };
  }
};

export type CurrencyPair = {
  quoteId?: string;
  conversionDate?: string;
  fixedSide: 'buy' | 'sell';

  fromAmount: number;
  fromAmountDecimal: number;
  fromCurrencyCode: string;

  toAmount: number;
  toAmountDecimal: number;
  toCurrencyCode: string;

  rate: number;
  invertedRate: number;
};

/**
 * Input Validation => Min/Max amount
 * BlackListed Currency => This is used so we don't hammer our API, so if it's blacklisted, we don't make the call.
 * Check the `enabled` flag on useRate on how it's implemented.
 * System Error => Kaboom, more likely API is down when trying to fetch a rate
 */
type WidgetError = {
  type: 'input_validation' | 'blacklisted_currency' | 'system_error';
  sender?: string;
  recipient?: string;
  general?: string;
};

export const useCurrencyExchangeWidget = ({
  initialCurrencyPairValues,
  currencies,
  registration,
  setting,
}: {
  initialCurrencyPairValues: CurrencyPair;
  currencies: Currency[];
  registration: Registration;
  setting: Setting;
}) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    isBlacklistedCurrency(initialCurrencyPairValues.fromCurrencyCode, setting) ||
      isBlacklistedCurrency(initialCurrencyPairValues.toCurrencyCode, setting)
      ? {
          currencyPair: toInvalidRateCurrencyPair(initialCurrencyPairValues),
          error: { type: 'blacklisted_currency', general: exchangeWidgetConfig.messages.currencyPairNotSupportedError },
        }
      : { currencyPair: initialCurrencyPairValues },
  );

  const {
    data: audExchange,
    isLoading: isAudExchangeLimitLoading,
    isFetching: isAudExchangeLimitFetching,
    verifyLimit,
  } = useAudExchangeAmountLimit({
    currencies,
    registration,
    setting,
    currencyCode: state.currencyPair.fromCurrencyCode,
    enabled: state.currencyPair.fromCurrencyCode !== 'AUD' && !(state.error?.type === 'blacklisted_currency'),
    onError: (error) =>
      dispatch({
        type: 'failure',
        currencyPair: toInvalidRateCurrencyPair(state.currencyPair),
        error: toRateError(error),
      }),
  });

  const {
    data: exchangeRate,
    isLoading: isRateLoading,
    isFetching: isRateFetching,
  } = useRate({
    params: {
      fromCurrency: state.currencyPair.fromCurrencyCode,
      toCurrency: state.currencyPair.toCurrencyCode,
      amount: 1,
    },
    options: {
      enabled: !!audExchange && !(state.error?.type === 'blacklisted_currency'),
      staleTime: exchangeWidgetConfig.rateQuery.staleTime,
      refetchOnWindowFocus: true,
      onError: (error) =>
        dispatch({
          type: 'failure',
          currencyPair: toInvalidRateCurrencyPair(state.currencyPair),
          error: toRateError(error),
        }),
    },
  });

  React.useEffect(() => {
    if (exchangeRate) {
      const updatedCurrencyPair: CurrencyPair = {
        ...state.currencyPair,
        rate: exchangeRate.rate,
        invertedRate: exchangeRate.invertedRate,
        quoteId: exchangeRate.quoteId,
        conversionDate: exchangeRate.conversionDate,
        toAmount: getToAmount({
          fromAmount: state.currencyPair.fromAmount,
          rate: exchangeRate.rate,
          toDecimalPlaces: state.currencyPair.toAmountDecimal,
        }),
        toAmountDecimal: extractDecimalPlaces(state.currencyPair.toCurrencyCode, currencies),
      };
      const { valid, message } = verifyLimit(updatedCurrencyPair.fromAmount);
      if (valid) {
        dispatch({ type: 'success', currencyPair: updatedCurrencyPair });
      } else {
        dispatch({
          type: 'failure',
          currencyPair: updatedCurrencyPair,
          error: { type: 'input_validation', sender: message },
        });
      }
    }
  }, [exchangeRate]);

  const onFromSelectedCurrencyChange = (fromCurrencyCode: string) => {
    const fromAmountDecimal = extractDecimalPlaces(fromCurrencyCode, currencies);
    const updatedCurrencyPair = { ...state.currencyPair, fromCurrencyCode, fromAmountDecimal };
    if (isBlacklistedCurrency(fromCurrencyCode, setting) || isBlacklistedCurrency(state.currencyPair.toCurrencyCode, setting)) {
      dispatch({
        type: 'failure',
        currencyPair: toInvalidRateCurrencyPair(updatedCurrencyPair),
        error: { type: 'blacklisted_currency', general: exchangeWidgetConfig.messages.currencyPairNotSupportedError },
      });
    } else {
      dispatch({ type: 'success', currencyPair: updatedCurrencyPair });
    }
  };

  const onToSelectedCurrencyChange = (toCurrencyCode: string) => {
    const toAmountDecimal = extractDecimalPlaces(toCurrencyCode, currencies);
    const updatedCurrencyPair = { ...state.currencyPair, toCurrencyCode, toAmountDecimal };
    if (isBlacklistedCurrency(toCurrencyCode, setting) || isBlacklistedCurrency(state.currencyPair.fromCurrencyCode, setting)) {
      dispatch({
        type: 'failure',
        currencyPair: toInvalidRateCurrencyPair(updatedCurrencyPair),
        error: { type: 'blacklisted_currency', general: exchangeWidgetConfig.messages.currencyPairNotSupportedError },
      });
    } else {
      dispatch({ type: 'success', currencyPair: updatedCurrencyPair });
    }
  };

  const onFromAmountChange = (fromAmount: number) => {
    if (state?.error?.type !== 'system_error' && state?.error?.type !== 'blacklisted_currency') {
      const updatedCurrencyPair: CurrencyPair = {
        ...state.currencyPair,
        fixedSide: 'sell',
        fromAmount: fromAmount,
        toAmount: getToAmount({
          fromAmount: fromAmount,
          rate: state.currencyPair.rate,
          toDecimalPlaces: state.currencyPair.toAmountDecimal,
        }),
      };
      const { valid, message } = verifyLimit(updatedCurrencyPair.fromAmount);
      if (valid) {
        dispatch({ type: 'success', currencyPair: updatedCurrencyPair });
      } else {
        dispatch({
          type: 'failure',
          currencyPair: updatedCurrencyPair,
          error: { type: 'input_validation', sender: message },
        });
      }
    }
  };

  const onToAmountChange = (toAmount: number) => {
    if (state?.error?.type !== 'system_error' && state?.error?.type !== 'blacklisted_currency') {
      const updatedCurrencyPair: CurrencyPair = {
        ...state.currencyPair,
        fixedSide: 'buy',
        toAmount: toAmount,
        fromAmount: getFromAmount({
          toAmount: toAmount,
          invertedRate: state.currencyPair.invertedRate,
          fromDecimalPlaces: state.currencyPair.fromAmountDecimal,
        }),
      };
      const { valid, message } = verifyLimit(updatedCurrencyPair.fromAmount);
      if (valid) {
        dispatch({ type: 'success', currencyPair: updatedCurrencyPair });
      } else {
        dispatch({
          type: 'failure',
          currencyPair: updatedCurrencyPair,
          error: { type: 'input_validation', recipient: message },
        });
      }
    }
  };

  const comparisonAmount = () => {
    const amount = getBankComparisonRate(state.currencyPair.fromAmount, setting, audExchange?.rate);
    return amount
      ? getCurrencyString({
          amount: amount,
          currency: state.currencyPair.fromCurrencyCode,
          currencyDecimals: extractDecimalPlaces(state.currencyPair.fromCurrencyCode, currencies),
        })
      : undefined;
  };

  return {
    isLoading: isRateLoading || isRateFetching || isAudExchangeLimitLoading || isAudExchangeLimitFetching,
    onToSelectedCurrencyChange,
    onToAmountChange,
    onFromAmountChange,
    onFromSelectedCurrencyChange,
    currencyPairValue: state.currencyPair,
    comparisonAmount: comparisonAmount(),
    currencyOptions: {
      from: toCurrencyOptions({ allCurrencies: currencies, popularCurrencies: exchangeWidgetConfig.popularCurrencies }),
      to: toCurrencyOptions({ allCurrencies: currencies, popularCurrencies: exchangeWidgetConfig.popularCurrencies }),
    },
    error: state.error,
  };
};

/**
 * This is used when an error happens when we can't fetch the rate, so we reset everything to 0
 * So that makes the toAmount field goes to 0
 */
const toInvalidRateCurrencyPair = (currencyPair: CurrencyPair) => ({
  ...currencyPair,
  rate: 0,
  invertedRate: 0,
  toAmount: 0,
  toAmountDecimal: 0,
});

const toRateError = (error: any): WidgetError => {
  if (error?.response?.data === 'currency_pair_not_supported') {
    return {
      type: 'system_error',
      general: exchangeWidgetConfig.messages.currencyPairNotSupportedError,
    };
  }
  return {
    type: 'system_error',
    general: exchangeWidgetConfig.messages.rateFetchError,
  };
};
