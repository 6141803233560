import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Spin } from 'antd';
import { Steps, StepContainer, footerButtonsPositions, useSteps, StepsProvider } from '@send-legacy-components/Common/Steps';
import { SelectRecipient, NewRecipient, ConfirmRecipient } from '@send-legacy-containers/DealerAssist';
import { ConfirmRecipientView } from '@send-legacy-components/AddTransferRecipient';
import { Confirmation, useConfirmation } from '@send-legacy-components/Common/Confirmation';
import { Icon, sendIconTypes } from '@send-legacy-components/Common/Icon';
import history from '@send-legacy-app/history';
import { useRegistration, useCoconut } from '@send-data-hooks';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
import { StepsLayout } from './StepLayout';
import styles from './Steps.module.less';

const id = 'add-recipient';

const steps = {
  SELECT_RECIPIENT: 1,
  NEW_RECIPIENT: 2,
  RECIPIENT: 3,
  RECIPIENT_CONFIRMATION: 4,
};

const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD');

const AddRecipientSteps = ({ conversionId, conversionDate, toCurrency, toAmount, parentType }) => {
  const [
    isVisibleRecipientConfirmation,
    { setConfirmationVisible: setRecipientConfirmationVisible, setConfirmationHidden: setRecipientConfirmationHidden },
  ] = useConfirmation();

  const [{ currentIndex }, { setStepIndex, getStepResult, setStepResult }] = useSteps({ id });

  const [selectedRecipient, setSelectedRecipient] = useState();
  const [
    {
      data: { id: idReg, unknown },
    },
  ] = useRegistration();

  const onStepsCloseButtonClick = () => {
    setRecipientConfirmationVisible();
  };

  const onStepsFinishedHandler = () => {
    history.push({ pathname: `/conversion/${conversionId}` });
  };

  const onViewActivities = () => {
    history.push({ pathname: `/activity` });
    setRecipientConfirmationHidden();
  };

  const onRecipientClick = (recipientId) => {
    setSelectedRecipient(recipientId);
  };

  const onReviewRecipient = (recipient) => {
    setStepResult(steps.RECIPIENT, {
      name: recipient.businessName || `${recipient.firstName} ${recipient.lastName}`,
      ...recipient,
    });
    setStepIndex(steps.RECIPIENT);
  };

  const onCancelNewRecipient = () => {
    setSelectedRecipient(undefined);
    setStepIndex(steps.SELECT_RECIPIENT);
  };

  const onEditRecipientClick = () => {
    setStepIndex(steps.SELECT_RECIPIENT);
  };

  const onContinueRecipient = () => {
    setRecipientConfirmationHidden();
  };

  useEffect(() => {
    if (selectedRecipient) {
      setStepIndex(steps.NEW_RECIPIENT);
    }
  }, [selectedRecipient]);

  useEffect(() => {
    if (unknown === true) {
      history.push({ pathname: `/dashboard` });
    }
  }, [unknown]);

  const stepRecipient = getStepResult(steps.RECIPIENT);

  const {
    name,
    accountNumber,
    bsbCode,
    email,
    sortCodeNumber,
    reason,
    reference,
    beneficiaryBankAccountCountry,
    beneficiaryType,
    iban,
    clabeNumber,
  } = stepRecipient || {};

  return (
    <>
      {isVisibleRecipientConfirmation && (
        <Confirmation
          title="Attention"
          bodyText="Are you sure you’d like to add a recipient later?"
          bodySubtext="You can add a recipient on the dashboard."
          onOkClick={onViewActivities}
          okButtonText="View activities"
          onCancelClick={onContinueRecipient}
          cancelButtonText="Continue"
        />
      )}
      <Steps
        id={id}
        footerButtonsPosition={footerButtonsPositions.CENTER}
        onStepsFinished={onStepsFinishedHandler}
        onStepsCloseButtonClick={onStepsCloseButtonClick}
        footerClassName={styles.footer}
        className={classNames(currentIndex === steps.RECIPIENT_CONFIRMATION && styles.hideSteps)}
        LayoutComponent={StepsLayout}
      >
        <StepContainer
          title="Who are you transferring money to?"
          name="recipient"
          showBack={false}
          showNext={false}
          index={steps.SELECT_RECIPIENT}
        >
          <SelectRecipient
            parentId={idReg}
            parentType={parentType}
            onRecipientClick={onRecipientClick}
            onReviewRecipient={onReviewRecipient}
            toCurrency={toCurrency}
          />
        </StepContainer>
        <StepContainer
          name="addRecipient"
          containerSize={{ lg: 24 }}
          position="Recipient"
          icon={<Icon type={sendIconTypes.PROGRESS_RECIPIENT} />}
          title="Add a new recipient"
          showBack={false}
          showNext={false}
          hiddenStep
          index={steps.NEW_RECIPIENT}
        >
          <NewRecipient
            type={selectedRecipient}
            fixedCurrencyTo={toCurrency}
            onSave={onReviewRecipient}
            onCancel={onCancelNewRecipient}
          />
        </StepContainer>
        <StepContainer
          position="Confirm recipient"
          icon={<Icon type={sendIconTypes.PROGRESS_PAYMENT} />}
          name="confirmRecipient"
          title="Is this the correct recipient?"
          showBack={false}
          nextButtonText="Confirm recipient"
          index={steps.RECIPIENT}
          callOnSaveImperativeHandler
        >
          <ConfirmRecipient
            onEditClick={onEditRecipientClick}
            name={name}
            accountNumber={accountNumber}
            bsbCode={bsbCode}
            email={email}
            sortCodeNumber={sortCodeNumber}
            toCurrency={toCurrency}
            bankCountry={beneficiaryBankAccountCountry}
            beneficiaryType={beneficiaryType}
            reason={reason}
            reference={reference}
            parentId={idReg}
            parentType={parentType}
            conversionId={conversionId}
            paymentDate={conversionDate > tomorrow ? conversionDate : tomorrow}
            toAmount={toAmount}
            iban={iban}
            clabeNumber={clabeNumber}
          />
        </StepContainer>
        <StepContainer
          name="recipientConfirmation"
          showBack={false}
          containerSize={{ lg: 24 }}
          submitButtonText="View transfer details >"
          index={steps.RECIPIENT_CONFIRMATION}
        >
          <ConfirmRecipientView recipientFullName={name} />
        </StepContainer>
      </Steps>
    </>
  );
};

AddRecipientSteps.propTypes = {
  conversionId: PropTypes.string.isRequired,
  conversionDate: PropTypes.string.isRequired,
  toCurrency: PropTypes.string.isRequired,
  toAmount: PropTypes.number.isRequired,
  parentType: PropTypes.string.isRequired,
};

const StepsScreen = ({ match }) => {
  const [{ data: conv, loading }] = useCoconut({ id: match.params.id, type: CoconutTypes.CONVERSION });
  const { id: conversionId, conversionDate, buyCurrency: toCurrency, bookedBuyAmount: toAmount, parentType } = conv;

  return loading ? (
    <Spin spinning />
  ) : (
    <StepsProvider>
      <AddRecipientSteps
        conversionId={conversionId}
        conversionDate={conversionDate}
        toCurrency={toCurrency}
        toAmount={toAmount}
        parentType={parentType}
      />
    </StepsProvider>
  );
};

StepsScreen.propTypes = {
  match: PropTypes.object.isRequired,
};

export default StepsScreen;
