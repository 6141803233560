import OtpInput from 'react-otp-input';
import React, { useState, useRef } from 'react';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { breakpoints } from '@sendpayments/react-shared/design-system';
import styles from './OtpCodeInput.module.less';
import { withTheme } from '@sendpayments/react-shared/components/ThemeProvider';

const OtpCodeInput = ({ numInputs = 6, onComplete, newStyles, isDisabled, isInputNum }) => {
  const ref = useRef(null);
  const { isMedia } = useBreakpoint(breakpoints);
  const isMobile = isMedia({ to: breakpoints.sm });

  const [otp, setOtp] = useState('');

  const handleChange = (code) => {
    setOtp(code);
    if (onComplete && code.length === numInputs) {
      onComplete(code);
    }
  };

  const getInputWidth = ({ maxWidth = 45 } = {}) => {
    if (isMobile) {
      if (ref.current && ref.current.clientWidth) {
        const width = (ref.current.clientWidth - (numInputs - 1) * 8) / numInputs;
        return width > maxWidth ? maxWidth : width;
      }
    }
    return maxWidth;
  };
  const inputWidth = getInputWidth();

  return (
    <div ref={ref}>
      <OtpInput
        containerStyle={newStyles ? styles.updatedStyles : styles.container}
        inputStyle={{ '--width': `${inputWidth}px` }}
        value={otp}
        onChange={handleChange}
        numInputs={numInputs}
        isDisabled={isDisabled}
        isInputNum={isInputNum}
        shouldAutoFocus
      />
    </div>
  );
};

export default withTheme(OtpCodeInput);
