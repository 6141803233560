import { useMutation } from 'react-query';
import { post } from '@send-base/utilities/endpoint';

export type PostData = {
  registrationId: string;
  registrationType: string;
  conversionId: string;
  amount: number;
} & FormState;

export type FormState = {
  bankDetails: {
    accountName: string;
    accountNumber: string;
    bsbNumber: string;
  };
  termsAndConditions: boolean;
};

export const useDirectDebit = (options?: Parameters<typeof useMutation>[2]) => {
  return useMutation((postData: PostData) => post(`/v1/funds/direct-debit`, { data: postData }, 'InternalApi'), options);
};
