import React from 'react';
import { Row } from 'antd';
import PropTypes from 'prop-types';
import styles from './View.module.less';

const View = ({ recipientFullName }) => (
  <Row className={styles.container} type="flex" justify="center">
    <Row className={styles.title}>Hooray</Row>
    <Row className={styles.title}>{`${recipientFullName} has been added`}</Row>
    <Row className={styles.subtitle}>
      <p>In the meantime you can check how to provide a payment next.</p>
    </Row>
  </Row>
);

View.propTypes = {
  recipientFullName: PropTypes.string.isRequired,
};

export default View;
