/* eslint-disable no-param-reassign */
import { Row, Layout, Spin, Icon, Button, Divider } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CustomLayout from '@send-legacy-components/Common/CustomLayout';
import { ListView } from '@send-legacy-components/Common/ListView';
import { useRegistration, useActivitiesByStatus, useAppContext } from '@send-data-hooks';
import { SMenu } from '@send-legacy-components/UI/SMenu';
import history from '@send-legacy-app/history';
import classNames from 'classnames';
import ConversionList from './ConversionList';
import styles from './Activities.module.less';
import { VRNAcknowledgementBanner } from '@send-legacy-components/VRNAcknowledgementBanner';

const { Content } = Layout;

const emptyIds = {
  MAKE_TRANSFER: 'make-transfer',
  ADD_RECIPIENT: 'add-recipient',
};

const onItemClick = ({ id }) => {
  if (id === emptyIds.ADD_RECIPIENT) {
    history.push(`/add-recipient`);
  } else if (id === emptyIds.MAKE_TRANSFER) {
    history.push(`/transfer`);
  }
};

const Activities = () => {
  const [{ data: reg }] = useRegistration();
  const [collapsed, setCollapsed] = useState(true);
  const [{ loading: pageLoading, user }] = useAppContext();

  const email = user && user.attributes && user.attributes.email;

  const [
    {
      data: { paymentsByConvId, conversions },
      loading,
    },
  ] = useActivitiesByStatus({
    email,
  });

  const accountActive = ['Registered', 'Dealing'].includes(reg.status);

  const dataSource = [
    {
      id: emptyIds.MAKE_TRANSFER,
      iconType: 'sync',
      header: 'Make a transfer',
      description: `Let's make those transfers happen`,
      visible: accountActive,
    },
    {
      id: emptyIds.ADD_RECIPIENT,
      iconType: 'user-add',
      header: 'Add a recipient ',
      description: `Who are you transferring money to?`,
    },
  ].filter(Boolean);

  return (
    <Spin spinning={pageLoading || loading}>
      <CustomLayout onClick={() => setCollapsed(true)} showSupportButton>
        <Content>
          <div className={classNames(styles.container, 'container')}>
            <Row>
              <SMenu activeItem="activity" registration={reg} collapsed={collapsed} setCollapsed={setCollapsed} />
              <Divider className="top-menu-divider" />
              <VRNAcknowledgementBanner />
              <h1 className="bold" style={{ marginTop: '50px' }}>
                My Activities
              </h1>
              <Row type="flex" justify="space-between">
                <div>
                  <h5 className={styles.title}>Here you will find all your transfer details, account history and more.</h5>
                </div>
                {accountActive && (
                  <Link to="/transfer" style={{ alignSelf: 'flex-end' }}>
                    <Button type="primary">
                      <Icon type="plus" />
                      Make a transfer
                    </Button>
                  </Link>
                )}
              </Row>
              <br />
              <Divider className="title-underline secondary" />
              <div className={styles.listContainer}>
                <ConversionList conversions={conversions} paymentsByConvId={paymentsByConvId} />
                {!pageLoading && !loading && conversions.length === 0 && (
                  <ListView dataSource={dataSource} onItemClick={onItemClick} />
                )}
              </div>
            </Row>
          </div>
        </Content>
      </CustomLayout>
    </Spin>
  );
};

export default Activities;
