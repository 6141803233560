import * as React from 'react';
import { ForgotPassword } from 'aws-amplify-react';
import { ForgotPasswordPage } from '@send-base/atomic-components/pages';
import { IAuthPieceProps } from 'aws-amplify-react/lib-esm/Auth/AuthPiece';

class ForgotPasswordComponent extends ForgotPassword {
  constructor(props: IAuthPieceProps) {
    super(props);
  }

  showComponent(): JSX.Element {
    return <ForgotPasswordPage changeState={this.changeState} />;
  }
}

export default ForgotPasswordComponent;
