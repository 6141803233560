import React from 'react';
import { Checkbox as SpectrumCheckbox } from '@adobe/react-spectrum';
import { withTheme } from '@sendpayments/react-shared/components/ThemeProvider';
import styles from './Checkbox.module.less';

/**
 * @typedef {{ props: import('@react-types/checkbox').CheckboxProps; children: any }} CheckboxProps
 */

/**
 * @param {CheckboxProps} props
 */
const Checkbox = ({ children, ...props }) => {
  return (
    <SpectrumCheckbox UNSAFE_className={styles['overrides--PrimaryButton']} {...props}>
      {children}
    </SpectrumCheckbox>
  );
};

Checkbox.displayName = 'Checkbox';

export default withTheme(Checkbox);
