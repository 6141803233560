import { useState, useEffect } from 'react';

const useBankComparisonAmount = ({ amount }, bankComparisonSettings) => {
  const [rate, setRate] = useState(0);

  useEffect(() => {
    if (amount && bankComparisonSettings) {
      setRate(
        (amount * bankComparisonSettings.pricingPlanBankRateComparison) / 100 +
          bankComparisonSettings.pricingPlanBankSendingFee * bankComparisonSettings.audRate,
      );
    }
  }, [amount, bankComparisonSettings]);

  return [rate, bankComparisonSettings];
};

export default useBankComparisonAmount;
