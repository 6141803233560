import { Layout, Menu, Row, Col, Progress, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import { CloseButton, ThemedImage, Icon } from '@send-legacy-components/Common';
import { Notifications } from '@send-legacy-containers/Notifications';
import { appBreakpoints, MediaBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { SUser } from './SUser';
import SUserMenu from './SUserMenu';

const { Header, Sider } = Layout;

export const SMenu = ({ registration, collapsed, setCollapsed, activeItem, showProgress }) => {
  const regStatus = registration.status || '';
  const showRecipientMenuItem = [
    'New',
    'Working On',
    'Awaiting Docs',
    'Awaiting Info',
    'Pending Review',
    'Registered',
    'Dealing',
  ].includes(regStatus);

  const isPexaUser = registration.portalSource === 'pexa';

  const menu =
    showRecipientMenuItem && !isPexaUser ? (
      <Menu selectedKeys={[activeItem]} mode="horizontal">
        <Menu.Item key="dashboard">
          <Icon type="wallet" />
          Dashboard
          <Link to="/" />
        </Menu.Item>
        <Menu.Item key="recipients">
          <Icon type="team" />
          Recipients
          <Link to="/recipient" />
        </Menu.Item>
        <Menu.Item key="activity">
          <Icon type="credit-card" />
          Activity
          <Link to="/activity" />
        </Menu.Item>
      </Menu>
    ) : (
      <Menu selectedKeys={[activeItem]} mode="horizontal">
        <Menu.Item key="dashboard">
          <Icon type="wallet" />
          Dashboard
          <Link to="/" />
        </Menu.Item>
      </Menu>
    );

  const mobileMenu = (
    <>
      <div className="mobile-menu-overlay" style={{ display: collapsed ? 'none' : 'block' }} />
      <Sider
        collapsedWidth="0"
        collapsible
        collapsed={collapsed}
        className="mobile-menu"
        width={307}
        onClick={(e) => e.stopPropagation()}
      >
        <div>
          <Row style={{ margin: '25px 0px 25px 25px' }}>
            <Link to="/dashboard" className="logo">
              <ThemedImage alt="logo" file="logo.svg" />
            </Link>
          </Row>
          {showRecipientMenuItem && !isPexaUser ? (
            <Menu selectedKeys={[activeItem]}>
              <Menu.Item key="dashboard">
                <Icon type="wallet" />
                Dashboard
                <Link to="/" />
              </Menu.Item>
              <Menu.Item key="recipients">
                <Icon type="team" />
                Recipients
                <Link to="/recipient" />
              </Menu.Item>
              <Menu.Item key="activity">
                <Icon type="credit-card" />
                Activity
                <Link to="/activity" />
              </Menu.Item>
            </Menu>
          ) : (
            <Menu selectedKeys={[activeItem]}>
              <Menu.Item key="dashboard">
                <Icon type="wallet" />
                Dashboard
                <Link to="/" />
              </Menu.Item>
            </Menu>
          )}
        </div>
        <SUserMenu registration={registration} />
        <CloseButton onClick={() => setCollapsed(true)} />
      </Sider>
    </>
  );

  return (
    <Layout onClick={() => setCollapsed(true)}>
      {mobileMenu}
      <Layout style={{ overflowX: 'initial' }}>
        <Header>
          <Row type="flex" justify="start" style={{ minHeight: '76px' }}>
            <Col style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
              <Link to="/dashboard" className="logo">
                <MediaBreakpoint to={appBreakpoints.md}>
                  <ThemedImage alt="logo" file="logo.svg" />
                </MediaBreakpoint>

                <MediaBreakpoint from={appBreakpoints.md}>
                  <ThemedImage alt="logo" file="logo.svg" />
                </MediaBreakpoint>
              </Link>
            </Col>

            <Col className="menu" xs={0}>
              {menu}
            </Col>

            <Row className="float-menu-right">
              {!isPexaUser && (
                <Col style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                  <Notifications />
                </Col>
              )}

              {/* hamburger menu */}
              <MediaBreakpoint to={appBreakpoints.md}>
                <Col className="mobile-menu-btn" style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                  <a
                    onClick={(e) => {
                      e.stopPropagation();
                      setCollapsed(!collapsed);
                    }}
                  >
                    <Avatar className="user-avatar" size="large" icon="menu" />
                  </a>
                </Col>
              </MediaBreakpoint>

              <MediaBreakpoint from={appBreakpoints.md}>
                <Col style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                  <SUser registration={registration} />
                </Col>
              </MediaBreakpoint>
            </Row>
          </Row>
        </Header>
        {showProgress && <Progress className="top-progress" percent={0} showInfo={false} size="small" />}
      </Layout>
    </Layout>
  );
};

SMenu.propTypes = {
  showProgress: PropTypes.bool,
  activeItem: PropTypes.string,
  registration: PropTypes.object.isRequired,
  collapsed: PropTypes.bool.isRequired,
  setCollapsed: PropTypes.func.isRequired,
};

SMenu.defaultProps = {
  showProgress: false,
  activeItem: null,
};
