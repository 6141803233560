import { useRemoteDataSelector } from '@sendpayments/react-shared/hooks/useRemoteData';
import { useRecipientsByOwner } from '../useRecipientsByOwner';
import { BENEFICIARY_STATUS } from '@sendpayments/shared-constants/statuses';
import { sortBenesByName } from '@send-base/utilities/sortBenesByName';

const convertData = ({ data }) => {
  const sortedData = sortBenesByName(data);

  const pendingBeneficiaries = [];
  const verifiedBeneficiaries = [];
  const deletedBeneficiaries = [];

  for (let i = 0; i < sortedData.length; i++) {
    const item = sortedData[i];

    if (item.status === 'Verified') {
      verifiedBeneficiaries.push(item);
    } else if (['New', 'Awaiting Docs', 'Awaiting Info'].includes(item.status)) {
      pendingBeneficiaries.push(item);
    } else if ([BENEFICIARY_STATUS.Deleted].includes(item.status)) {
      deletedBeneficiaries.push(item);
    }
  }

  return [pendingBeneficiaries, verifiedBeneficiaries, deletedBeneficiaries];
};

const useRecipientsByStatus = ({ owner }) => {
  const [recipients, methods] = useRecipientsByOwner({ owner });

  const [state] = useRemoteDataSelector(() => convertData(recipients), [recipients]);

  return [{ ...state, refetch: methods.doFetch }];
};

export default useRecipientsByStatus;
