import { useState, useCallback, useMemo } from 'react';

const useConfirmation = () => {
  const [isVisible, setVisible] = useState(false);

  const setConfirmationVisible = useCallback(() => setVisible(true));
  const setConfirmationHidden = useCallback(() => setVisible(false));

  const { awaitConfirmationResponse, onConfirmCallback, onCancelCallback } = useMemo(() => {
    let resolvePromise;
    let rejectPromise;

    const createPromise = () =>
      new Promise((resolve, reject) => {
        resolvePromise = resolve;
        rejectPromise = reject;
      });

    return {
      awaitConfirmationResponse: () => {
        setConfirmationVisible();
        return createPromise();
      },

      onConfirmCallback: () => {
        setConfirmationHidden();
        resolvePromise({ isConfirmed: true });
      },

      onCancelCallback: () => {
        setConfirmationHidden();
        rejectPromise({ isConfirmed: false });
      },
    };
  }, []);

  return [
    isVisible,
    {
      setConfirmationVisible,
      setConfirmationHidden,
      asAwaitConfirmation: {
        awaitConfirmationResponse,
        onConfirmCallback,
        onCancelCallback,
      },
    },
  ];
};

export default useConfirmation;
