import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Text, Stack, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';

import { ComparisonRegistrationTemplate } from '@send-base/atomic-components/templates/ComparisonRegistrationTemplate';
import { AlertBoxData } from '@send-base/atomic-components/pages';
import { AlertBox } from '@sendpayments/react-shared/components/molecules';
import {
  ComparisonRegisterFormStep2,
  ComparisonRegisterTransferData,
} from '@send-base/atomic-components/organisms/forms/ComparisonRegisterFormStep2';

import { CoconutTypes } from '@sendpayments/shared-constants/types';
import { withPortalSource } from '@send-services/white-label';
import { saveEntity } from '@sendpayments/js-utils/dist/services/entities';
import { logger } from '@sendpayments/js-utils/dist';
import { TrustPilotWidget } from '@send-base/atomic-components/molecules/TrustPilotWidget';
import { AppContext } from '@send-legacy-app/Context';
import { Auth } from 'aws-amplify';
import { useRegistration } from '@send-base/data-hooks';

const syncAndGetTrackingIdWithCognito = async (user: {
  attributes: { 'custom:trackingId'?: string };
}): Promise<{ updatedUser?: unknown; trackingId?: string }> => {
  const localStorageTrackingId = window.localStorage.getItem('trackingId');
  const userAttributeTrackingId = user?.attributes?.['custom:trackingId'];

  // If we have a trackingId in Cognito, use that
  if (userAttributeTrackingId) {
    return { trackingId: userAttributeTrackingId };
  }

  // If we have a trackingId in localstorage but not Cognito, update Cognito with trackingId
  if (!userAttributeTrackingId && localStorageTrackingId) {
    try {
      await Auth.updateUserAttributes(user, {
        'custom:trackingId': localStorageTrackingId,
      });
    } catch (e) {
      logger.error('Register step 2 OnSubmit', 'attempted to set tracking ID on cognito user', e);
    }
    const updatedUser = await Auth.currentAuthenticatedUser();
    return { updatedUser, trackingId: updatedUser?.attributes?.['custom:trackingId'] };
  }

  // If no trackingId found, return undefined
  return { trackingId: undefined };
};

const ComparisonRegisterStep2 = () => {
  const [isLoading, setLoading] = useState(false);
  const [alertBox, setAlertBox] = useState<AlertBoxData>({ message: undefined, type: undefined });
  const history = useHistory();
  const { user, setUser }: { user: any; setUser: any } = useContext(AppContext);
  const [{ data: account, loading }] = useRegistration();

  useEffect(() => {
    if (account?.status) {
      //If the user has a status of anything, they should not be able to go back to this page
      history.push('/dashboard');
    }
  }, [account, loading]);

  const handleSubmit = async (values: ComparisonRegisterTransferData, type: CoconutTypes) => {
    setLoading(true);

    const { updatedUser, trackingId } = await syncAndGetTrackingIdWithCognito(user);
    if (updatedUser) {
      setUser(updatedUser);
    }

    // Check if person referred from affiliate
    const affiliateId = window.localStorage.getItem('affiliateId');
    logger.log('RetailPage', '(optional) affiliateId cookie 🍪', affiliateId);
    try {
      await saveEntity({
        entity: withPortalSource({
          ...values,
          trackingId,
          ...(affiliateId && { affiliateId }),
          email: user.attributes.email,
        }),
        type,
        owner: user.attributes.email,
      });
      window.localStorage.removeItem('trackingId');
      window.localStorage.removeItem('affiliateId');
      history.push('/dashboard');
    } catch (err) {
      logger.error('Register step 2 OnSubmit', 'error signing up', err);
      setAlertBox({ message: 'Something went wrong. Please try again.', type: 'error' });
    }
    setLoading(false);
  };

  return (
    <ComparisonRegistrationTemplate heading={'Almost there...'} leftChildren={<TrustPilotWidget />}>
      <Stack spacing={8} mb={15}>
        <Text as="span" fontSize="lg" lineHeight={1.375} align="center" mt={'18px'}>
          {'We need a few more details from you to comply with our high security standards. '}
        </Text>

        {!!alertBox?.message && (
          <AlertBox
            maxW="100%"
            my="4"
            title=""
            alignSelf="center"
            variant={alertBox.type === 'success' ? 'neutral' : 'negative'}
            description={alertBox.message}
            showIcon
          />
        )}

        <Tabs isFitted variant={'line'}>
          <TabList mb={3}>
            <Tab
              _selected={{ color: 'gray.900', borderColor: 'gray.900' }}
              color={'gray.600'}
              fontSize={'1.25rem'}
              fontWeight={'600'}
              textAlign={'center'}
            >
              Personal
            </Tab>
            <Tab
              _selected={{ color: 'gray.900', borderColor: 'gray.900' }}
              color={'gray.600'}
              fontSize={'1.25rem'}
              fontWeight={'600'}
              textAlign={'center'}
            >
              Business
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <ComparisonRegisterFormStep2
                onSubmit={async (formvalues) => await handleSubmit(formvalues, CoconutTypes.RETAIL)}
                isLoading={isLoading}
                initialValues={{
                  estimatedValue: '',
                  currencyFrom: 'AUD',
                  currencyTo: 'NZD',
                  firstName: '',
                  phoneCountryCode: '',
                  phone: '',
                }}
              />
            </TabPanel>
            <TabPanel>
              <ComparisonRegisterFormStep2
                onSubmit={async (formvalues) => await handleSubmit(formvalues, CoconutTypes.CORPORATE)}
                isLoading={isLoading}
                initialValues={{
                  estimatedValue: '',
                  currencyFrom: 'AUD',
                  currencyTo: 'NZD',
                  firstName: '',
                  phoneCountryCode: '',
                  phone: '',
                }}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </ComparisonRegistrationTemplate>
  );
};

export { ComparisonRegisterStep2 };
