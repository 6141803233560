import React, { ReactNode } from 'react';
import { Flex, Box, Heading, Text } from '@chakra-ui/react';

export interface FullPageNotificationTemplateProps {
  icon: JSX.Element;
  heading: string;
  subHeading?: string;
  description: string;
  children: ReactNode;
}

export const FullPageNotificationTemplate = ({
  icon,
  heading,
  subHeading,
  description,
  children,
}: FullPageNotificationTemplateProps): JSX.Element => {
  return (
    <Flex minH="100vh" p={{ base: '4', lg: '8' }} direction="column">
      <Box
        pt={{ base: '100px', lg: '140px' }}
        flex="4"
        height="75vh"
        mx="auto"
        w={{ base: '100%' }}
        maxW="416px"
        textAlign="center"
      >
        {icon}
        <Heading mt={5}>{heading}</Heading>
        <Heading as="h3" variant="heading3" mt={2}>
          {subHeading}
        </Heading>
        <Text fontSize="lg" mt={2} mb={5}>
          {description}
        </Text>
        {children}
      </Box>
    </Flex>
  );
};
