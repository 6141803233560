import StepFormLayout from '@send-legacy-components/UI/StepFormLayout';
import { getLabelByKey } from '@send-services/resources';
import { withAnalytics } from '@sendpayments/react-shared/HOC/withAnalytics';
import { Button as ButtonAntd, Col, Layout, Progress, Row, message } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import DefaultLayout from './DefaultLayout';
import styles from './Steps.module.less';
import useSteps from './useSteps';

const startColor = getLabelByKey({ key: 'steps_start_color', default: '#00A7BA' });
const endColor = getLabelByKey({ key: 'step_end_color', default: '#5CDEED' });

const { Button } = withAnalytics({ Button: ButtonAntd });

export const footerButtonsPositions = {
  START: 'start',
  END: 'end',
  CENTER: 'center',
};

const findNext = (index, { steps }) => {
  const thisStep = steps.find((step) => step.index > index && !step.hiddenStep);
  return thisStep && thisStep.index;
};

const findPrevious = (index, { steps }) => {
  const thisStep = steps.reverse().find((step) => step.index < index && !step.hiddenStep);
  return thisStep && thisStep.index;
};

const Steps = ({
  id,
  className,
  LayoutComponent,
  children,
  onStepsFinished,
  onStepsFailed,
  onStepChange,
  footerButtonsPosition,
  footerClassName,
  onStepsCloseButtonClick,
}) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [
    { currentIndex: currentIndexState, data: stepsState },
    { setStepIndex: setCurrentStep, setStepData: setCurrentStepData },
  ] = useSteps({
    id,
    initialState: { index: 1 },
  });
  const childRef = useRef(null);
  const [results, setResult] = useState({});
  const ChildComponents = React.Children.toArray(children);

  const size = ChildComponents.length;

  const childrenRef = ChildComponents.reduce(
    (acc, element) => {
      const {
        name,
        index,
        title,
        icon,
        alert,
        subtitle,
        onSave,
        showNext,
        showBack,
        nextButtonText,
        submitButtonText,
        backButtonText,
        showProgress,
        footerText,
        hiddenStep,
        ...remainingProps
      } = element.props;

      const stepData = {
        name,
        index,
        title,
        subtitle,
        alert,
        icon,
        onSave,
        children: element,
        nextButtonText,
        submitButtonText,
        backButtonText,
        showProgress: showProgress !== undefined ? showProgress : true,
        footerText,
        showNext: showNext !== undefined ? showNext : true,
        showBack: showBack !== undefined ? showBack : true,
        hiddenStep,
        ...remainingProps,
      };

      if (index === currentIndexState) {
        acc.current = stepData;
      }

      acc.steps.push(stepData);

      acc.stepDataOnly.push({
        name: stepData.name,
        index: stepData.index,
        title: stepData.title,
        subtitle: stepData.subtitle,
        hiddenStep: stepData.hiddenStep,
      });

      return acc;
    },
    { current: null, steps: [], stepDataOnly: [] },
  );

  const getStepIndexData = useCallback(() => ({
    stepsData: results,
    previous: {
      ...(childrenRef.stepDataOnly.find(({ index }) => index === currentIndexState - 1) || {}),
      size,
    },
    current: {
      ...(childrenRef.stepDataOnly.find(({ index }) => index === currentIndexState) || {}),
      size,
    },
    next: {
      ...(childrenRef.stepDataOnly.find(({ index }) => index === currentIndexState + 1) || {}),
      size,
    },
  }));

  const onCreateHandler = async () => {
    try {
      setLoading(true);

      const stepsDataAsMap = Object.values(stepsState.stepsData).reduce((acc, stepResult, index) => {
        acc[childrenRef.stepDataOnly[index].name] = stepResult;
        return acc;
      }, {});

      const result =
        childrenRef.current.callOnSaveImperativeHandler && childRef.current.onSave
          ? await childRef.current.onSave(stepsState.stepsData[currentIndexState], stepsDataAsMap)
          : {};

      if (childrenRef.current.callOnSaveImperativeHandler && childRef.current.onSave && !result) {
        setLoading(false);
        return;
      }

      setResult({
        ...results,
        [currentIndexState]: {
          ...(results[currentIndexState] || {}),
          ...(result || {}),
        },
      });

      setLoading(false);

      if (size === currentIndexState) {
        setSubmitted(true);
      } else if (!childrenRef.current.onButtonNextClick) {
        setCurrentStep(findNext(currentIndexState, { steps: childrenRef.steps }));
      } else {
        childrenRef.current.onButtonNextClick(findNext(currentIndexState, { steps: childrenRef.steps }));
      }
    } catch (ex) {
      setLoading(false);
      if (ex) {
        message.error(ex.message);
        onStepsFailed(ex.message);
      }
    }
  };

  const onBackHandler = () => {
    if (!childrenRef.current.onButtonBackClick) {
      setCurrentStep(findPrevious(currentIndexState, { steps: childrenRef.steps }));
    } else {
      childrenRef.current.onButtonBackClick(findPrevious(currentIndexState, { steps: childrenRef.steps }));
    }
  };

  const onStepsCloseButtonClickHandler = () => {
    const stepData = getStepIndexData();
    setCurrentStepData(stepData);
    onStepsCloseButtonClick(stepData);
  };

  useEffect(() => {
    const stepData = getStepIndexData();
    setCurrentStepData(stepData);
    onStepChange(stepData);
  }, [currentIndexState]);

  useEffect(() => {
    const stepData = getStepIndexData();
    if (size === currentIndexState && submitted) {
      setCurrentStepData(stepData);
      onStepsFinished(stepData);
    }
  }, [currentIndexState, submitted]);

  return (
    <StepFormLayout
      className={classNames(className)}
      loading={loading}
      onCloseClick={onStepsCloseButtonClick && onStepsCloseButtonClickHandler}
    >
      <Layout>
        {childrenRef.current.showProgress && (
          <Progress
            strokeColor={{
              '0%': endColor,
              '100%': startColor,
            }}
            className="top-progress"
            percent={(currentIndexState * 100) / size}
            showInfo={false}
            size="small"
          />
        )}
        <div className="container">
          <LayoutComponent
            onCreate={onCreateHandler}
            title={childrenRef.current.title}
            subtitle={childrenRef.current.subtitle}
            icon={childrenRef.current.icon}
            index={currentIndexState}
            size={size}
            loading={loading}
            steps={childrenRef.steps}
            alert={childrenRef.current.alert}
            containerSize={childrenRef.current.containerSize}
          >
            {React.cloneElement(
              childrenRef.current.children,
              childrenRef.current.callOnSaveImperativeHandler ? { ref: childRef } : {},
            )}
          </LayoutComponent>
          <Row gutter={16} type="flex" justify={footerButtonsPosition}>
            {currentIndexState > 1 && childrenRef.current.showBack && (
              <Col>
                <Button name="next" type="secondary" onClick={onBackHandler} loading={loading}>
                  {childrenRef.current.backButtonText || 'Back'}
                </Button>
              </Col>
            )}
            {currentIndexState !== size && childrenRef.current.showNext && (
              <Col>
                <Button name="next" onClick={onCreateHandler} type="primary" loading={loading} disabled={loading}>
                  {childrenRef.current.nextButtonText || 'Next'}
                </Button>
              </Col>
            )}
            {currentIndexState === size && (
              <Col>
                <Button name="submit" loading={loading} onClick={onCreateHandler} type="primary" disabled={submitted}>
                  {childrenRef.current.submitButtonText || 'Submit'}
                </Button>
              </Col>
            )}
          </Row>
          {childrenRef.current.footerText && (
            <div className={classNames(styles.footer, footerClassName)}>{childrenRef.current.footerText}</div>
          )}
          <br />
        </div>
      </Layout>
    </StepFormLayout>
  );
};

Steps.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onStepsFinished: PropTypes.func.isRequired,
  onStepsFailed: PropTypes.func,
  onStepChange: PropTypes.func,
  onStepsCloseButtonClick: PropTypes.func,
  LayoutComponent: PropTypes.elementType,
  footerButtonsPosition: PropTypes.oneOf(Object.values(footerButtonsPositions)),
  footerClassName: PropTypes.string,
};

Steps.defaultProps = {
  className: undefined,
  LayoutComponent: DefaultLayout,
  onStepsFailed: () => {},
  onStepChange: () => {},
  onStepsCloseButtonClick: undefined,
  footerButtonsPosition: 'end',
  footerClassName: undefined,
};

export default Steps;
