import React from 'react';
import PropTypes from 'prop-types';

const HyperLink = (props) => (
  <a href={props.href} className={props.className} style={props.style}>
    {props.children}
  </a>
);

HyperLink.propTypes = {
  href: PropTypes.string.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.any,
};

HyperLink.defaultProps = {
  style: {},
  className: '',
  children: undefined,
};

export default HyperLink;
