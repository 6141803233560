import { useEffect, useState, useContext } from 'react';
import { message } from 'antd';
import { API } from 'aws-amplify';
import { query } from '@send-base/utilities/endpoint';
import * as queries from '@sendpayments/graphql/queries';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
import { AppContext } from '@send-legacy-app/Context';
import * as subscriptions from '@sendpayments/graphql/subscriptions';
import { logger } from '@sendpayments/js-utils/dist/services/logger';
import { graphqlOperationEx } from '@sendpayments/graphql/fields';

const getConv = async (id) => {
  const params = { type: CoconutTypes.CONVERSION, id };
  const conv = await query(queries.getCoconut, { params });
  return conv;
};

function useConversions({ statuses }) {
  const { user, setLoading } = useContext(AppContext);
  const [convs, setConvs] = useState([]);
  const [newConv, setNewConv] = useState();

  const email = user && user.attributes && user.attributes.email;

  useEffect(() => {
    if (newConv && newConv.bookedSellAmount && newConv.bookedBuyAmount) {
      if (statuses.includes(newConv.status)) {
        if (!convs.map((conv) => conv.id).includes(newConv.id)) {
          setConvs(convs.concat([newConv]));
        } else {
          setConvs(convs.map((conv) => (conv.id === newConv.id ? newConv : conv)));
        }
      } else {
        setConvs(convs.filter((conv) => conv.id !== newConv.id));
      }
    }
  }, [newConv]);

  useEffect(() => {
    if (email) {
      // create subscribe
      const subscription = API.graphql(
        graphqlOperationEx(
          subscriptions.onCreateCoconutByOwnerType,
          {
            owner: email,
            type: CoconutTypes.CONVERSION,
          },
          ['id'],
        ),
      ).subscribe({
        next: (coconut) => {
          const { id } = coconut.value.data.onCreateCoconutByOwnerType;
          getConv(id).then((conv) => setNewConv(conv));
        },
      });
      // update subscribe
      const updateSubscription = API.graphql(
        graphqlOperationEx(
          subscriptions.onUpdateCoconutByOwnerType,
          {
            owner: email,
            type: CoconutTypes.CONVERSION,
          },
          ['id'],
        ),
      ).subscribe({
        next: (coconut) => {
          const { id } = coconut.value.data.onUpdateCoconutByOwnerType;
          getConv(id).then((doc) => setNewConv(doc));
        },
      });
      return function cleanup() {
        subscription.unsubscribe();
        updateSubscription.unsubscribe();
      };
    }

    return undefined;
  }, [email]);

  const getConvs = async () => {
    try {
      setLoading(true);
      const params = {
        owner: email,
        type: { eq: CoconutTypes.CONVERSION },
        sortDirection: 'DESC',
        limit: 1000, // hopefully a user has less than 1000 convs
      };
      const { items } = await query(queries.listCoconutsByOwner, { params });
      setLoading(false);
      setConvs(
        items.filter(
          (item) =>
            statuses.includes(item.status) && item.bookedSellAmount && item.bookedBuyAmount && !item.hideFromCustomerActivity,
        ),
      );
    } catch (error) {
      logger.error('getConvs', 'Error loading transfers', error);
      message.error(`Error loading transfers`);
    }
  };

  useEffect(() => {
    if (email) {
      getConvs();
    }
  }, [email]);

  return [convs];
}

export default useConversions;
