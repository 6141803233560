import { Grid, Stack, InputProps } from '@chakra-ui/react';
// @ts-ignore
import { InputField } from '@sendpayments/react-shared/components/molecules';
import { CountrySelect } from '../..';
import React from 'react';

export type Field =
  | 'streetAddressUnitNumber'
  | 'streetAddressNumber'
  | 'streetAddressName'
  | 'streetAddressType'
  | 'city'
  | 'state'
  | 'postcode'
  | 'country';

export const DetailedAddressSection: React.FC<{
  values: {
    [T in Field]?: string;
  };
  errors: {
    [T in Field]?: string;
  };
  touched: {
    [T in Field]?: boolean;
  };
  onChange: (name: Field, value: string) => void;
  onBlur: (name: Field, e: React.FocusEvent<any>) => void;
  w?: string;
}> = (props) => {
  function getInputProps(key: Field) {
    return {
      value: props.values[key],
      size: 'md',
      onChange: (e) => props.onChange(key, e.target.value),
      onBlur: (e) => props.onBlur(key, e),
    };
  }

  function getOtherProps(key: Field, inputProps?: InputProps) {
    return {
      isInvalid: props.touched[key] ? !!props.errors?.[key] : false,
      isValid: !Boolean(props.errors[key]) && !!props.values[key],
      errorText: props.touched[key] ? props.errors?.[key] : undefined,
      inputProps: { ...getInputProps(key), ...inputProps },
    };
  }

  return (
    // @ts-ignore
    <Stack>
      <Stack direction="row">
        <InputField
          id="streetAddressUnitNumber"
          label="Unit (if needed)"
          placeholder="Unit"
          inputProps={getInputProps('streetAddressUnitNumber')}
        />
        <InputField
          id="streetAddressNumber"
          label="Street number"
          placeholder="Enter street number"
          {...getOtherProps('streetAddressNumber')}
        />
      </Stack>
      <InputField
        id="streetAddressName"
        label="Street name"
        placeholder="Enter street name"
        {...getOtherProps('streetAddressName')}
      />
      <InputField
        id="streetAddressType"
        label="Street type"
        placeholder="Enter street type"
        {...getOtherProps('streetAddressType')}
      />
      <InputField
        id="city"
        label="City"
        placeholder="Enter city"
        {...getOtherProps('city', { autoComplete: 'address-level2' })}
      />
      <InputField
        id="state"
        label="State / Province"
        placeholder="Enter state or province"
        {...getOtherProps('state', { autoComplete: 'address-level1' })}
      />
      <InputField
        id="postcode"
        label="Post code / Zip code"
        placeholder="Enter post code"
        {...getOtherProps('postcode', { autoComplete: 'postal-code' })}
      />
      <CountrySelect
        id="country"
        label="Country"
        value={props.values['country']}
        errorText={props.errors?.['country']}
        isInvalid={!!props.errors?.['country']}
        onCountryChange={(e) => props.onChange('country', e?.value ?? '')}
      />
    </Stack>
  );
};
