import { View as ConfirmTradeView } from './ConfirmTradeView';
import { View as ReviewAmountView } from './ReviewAmountView';
import { View as FundsInDetailsView } from './FundsInDetailsView';
// eslint-disable-next-line
import { View as SelectRecipientView, recipients } from './SelectRecipientView';
import { Form as NewRecipientForm } from './NewRecipientForm';
import { Form as ConfirmRecipientForm } from './ConfirmRecipientForm';
// eslint-disable-next-line
import { View as TradeConfirmationView } from './TradeConfirmationView';

export {
  ConfirmTradeView,
  SelectRecipientView,
  ReviewAmountView,
  NewRecipientForm,
  ConfirmRecipientForm,
  TradeConfirmationView,
  recipients,
  FundsInDetailsView,
};
