import React from 'react';
import { View, Divider, Flex } from '@adobe/react-spectrum';
import withTheme from '@sendpayments/react-shared/components/ThemeProvider/withTheme';
import accountStyles from '../../Account.module.less';

const SettingItem = ({ title, desc, children, isMobile }) => {
  return (
    <View>
      <Divider size="S" marginBottom="20px" />
      <Flex direction={isMobile ? 'column' : 'row'} gap="size-100" marginBottom="40px">
        <View flexGrow="1">
          <View>
            <div className={accountStyles.itemTitle}>{title}</div>
            <View>{desc}</View>
          </View>
        </View>
        <Flex direction="row" gap="size-100">
          {children}
        </Flex>
      </Flex>
    </View>
  );
};

export default withTheme(SettingItem);
