import { Heading, Text } from '@chakra-ui/react';
import { FailedCrossIcon } from '@send-base/atomic-components/atoms/icons/failedCrossIcon';
import { ActionableModal } from '@send-base/atomic-components/organisms';
import React from 'react';

interface ErrorModalProps {
  isOpen: boolean;
  onClose: () => void;
  reset: () => void;
}

export const ErrorModal: React.FC<ErrorModalProps> = ({ isOpen, onClose, reset }) => {
  const forceCloseButton = () => {
    reset();
    onClose();
  };

  return (
    <ActionableModal
      isOpen={isOpen}
      actions={{
        primary: {
          text: 'Try again',
          onClick: forceCloseButton,
        },
      }}
      onClose={forceCloseButton}
      key="DirectDebitFailedModal"
    >
      <FailedCrossIcon w="87px" h="87px" />
      <Heading as="h4" size="md" pt={6} pb={6}>
        {`We couldn't validate the provided details`}
      </Heading>
      <Text fontSize={{ sm: 'sm', md: 'md' }}>Please re-enter the details</Text>
    </ActionableModal>
  );
};
