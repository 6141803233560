import React, { useCallback, memo } from 'react';
import { useAppContext, useCurrentEmail } from '@send-data-hooks';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = memo(({ hideFromAffiliates, render: C, ...props }) => {
  const [{ loading, user }] = useAppContext();
  const [{ data: userEmail }] = useCurrentEmail();

  // eslint-disable-next-line prettier/prettier
  const redirectToDashboard = hideFromAffiliates?.includes(user?.attributes['custom:portalSource']);

  const renderer = useCallback((rProps) => {
    if (userEmail || loading) {
      if (redirectToDashboard) {
        return <Redirect to={'/dashboard'} />;
      }
      return <C {...rProps} />;
    }

    return <Redirect to={`/auth?redirect=${rProps.location.pathname}${rProps.location.search}`} />;
  });

  return <Route {...props} render={renderer} />;
});

export default ProtectedRoute;
