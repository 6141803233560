import PropTypes from 'prop-types';
import React, { useState, useEffect, memo } from 'react';
import { Row, message, Layout, Spin, Divider, Icon } from 'antd';
import { API } from 'aws-amplify';
import CustomLayout from '@send-legacy-components/Common/CustomLayout';
import { useRegistration } from '@send-data-hooks';
import { PaymentDetails } from '@send-legacy-components/Payment';
import { ListView } from '@send-legacy-components/Common/ListView';
import { SSubAreaTitle } from '@send-legacy-components/UI';
import { Link } from 'react-router-dom';
import { underwayStatuses, completedStatuses } from '@send-const/activities';
import { DownloadButton as DownloadPdfButton } from '@sendpayments/react-shared/components/buttons/DownloadButton';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { get } from '@send-base/utilities/endpoint';
import { graphqlOperationEx } from '@sendpayments/graphql/fields';
import * as queries from '@sendpayments/graphql/queries';
import { SMenu } from '@send-legacy-components/UI/SMenu';

dayjs.extend(localizedFormat);

const { Content } = Layout;

const PaymentDetail = memo((props) => {
  const [{ data: reg }] = useRegistration();
  const [loading, setLoading] = useState(true);
  const [pay, setPay] = useState({});
  const [collapsed, setCollapsed] = useState(true);

  const downloadPdf = async () => {
    const response = await get(`/pdf/payment/${props.match.params.id}`);
    return response.data;
  };

  useEffect(() => {
    const query = graphqlOperationEx(queries.getCoconut, {
      id: props.match.params.id,
      type: CoconutTypes.PAYMENT,
    });
    API.graphql(query)
      .then(({ data: { getCoconut } }) => {
        setLoading(false);
        setPay(getCoconut);
      })
      .catch((error) => message.error(`Error loading Payment: ${error.message}`));
  }, []);

  const dataSource = [
    {
      id: 'payment',
      iconType: 'arrow-right',
      description: dayjs(pay.paymentDate).format('LL'),
      header: `${(pay.amount || 0).toLocaleString()} ${pay.currency} to ${pay.recipientName}`,
      clickable: false,
      reference: {
        // eslint-disable-next-line no-nested-ternary
        type: underwayStatuses.includes(pay.status) ? 'warning' : completedStatuses.includes(pay.status) ? 'success' : 'error',
        text: (pay.status || '').replace('send_', '').split('_').join(' '),
      },
    },
  ];

  return (
    <Spin spinning={loading}>
      <CustomLayout onClick={() => setCollapsed(true)} showSupportButton>
        <Content>
          <div className="container">
            <SMenu registration={reg} collapsed={collapsed} setCollapsed={setCollapsed} />
            <Divider className="top-menu-divider" />
            <Row>
              <br />
              <Link to="/activity" className="back">
                <Icon type="left" /> Your Activities
              </Link>
            </Row>
            <h1 className="bold">{`${(pay.amount || 0).toLocaleString()} ${pay.currency} to ${pay.recipientName}`}</h1>
            <Row type="flex" justify="space-between">
              <div>
                <p style={{ letterSpacing: 1 }}>Here you will find all your payment details and downloadable a payment receipt</p>
              </div>
              <DownloadPdfButton
                className="download-btn"
                onClick={downloadPdf}
                fileName={`Payment-${pay.id && pay.id.slice(0, 8).toUpperCase()}`}
                text="Payment Receipt"
              />
            </Row>
            <br />
            <Divider className="title-underline secondary" />
            <br />
            <ListView dataSource={dataSource} button={false} />
            <br />
            <SSubAreaTitle text="Transfer overview" icon="wallet" underlineDivider style={{ marginBottom: 22 }} />
            <PaymentDetails payment={pay} />
          </div>
        </Content>
      </CustomLayout>
    </Spin>
  );
});

PaymentDetail.propTypes = {
  match: PropTypes.any.isRequired,
};

export default PaymentDetail;
