import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ContextDevTool } from 'react-context-devtool';
import useStepsState from './useStepsState';

const StepsContext = React.createContext();

export const useStepsContext = () => {
  const stepsContext = useContext(StepsContext);
  return [stepsContext];
};

function StepsProvider({ children }) {
  const [steps, { getStepIndex, setStepIndex, clearSteps, setStepData, getStepData, setStepResult }] = useStepsState();

  return (
    <StepsContext.Provider
      value={{
        steps: { steps, getStepIndex, setStepIndex, clearSteps, setStepData, getStepData, setStepResult },
      }}
    >
      <ContextDevTool context={StepsContext} id="StepsContext" displayName="StepsContext" />
      {children}
    </StepsContext.Provider>
  );
}

StepsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StepsProvider;
