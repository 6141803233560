import React from 'react';
import { Flex } from '@adobe/react-spectrum';
import { SMSKey } from '@send-components/Icons';
import { PrimaryLink } from '@send-components/PrimaryLink';
import { InfoPanel, Item as InfoPanelItem } from '@send-components/InfoPanel';

const InfoPanelInner = ({ onClick, loading }) => (
  <InfoPanel style={{ width: '100%' }}>
    <InfoPanelItem isLoading={loading} icon={SMSKey}>
      <Flex>
        <div>
          You need to verify your phone number to receive SMS notifications and alerts. Would you like to{' '}
          <PrimaryLink onClick={onClick} isDisabled={!onClick} style={{ display: 'inline' }}>
            verify your phone number
          </PrimaryLink>{' '}
          now?
        </div>
      </Flex>
    </InfoPanelItem>
  </InfoPanel>
);

export default InfoPanelInner;
