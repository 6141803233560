import { saveEntity } from '@sendpayments/js-utils/dist/services/entities';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(advancedFormat);

const PRIMARY_ID_TAG = 'Primary ID';

const hasDocumentInfoChanged = ({ registration, primaryDocument, isCardNumberEnabled = false }) => {
  const fields = [
    'docIssuingCountry',
    'docLicenceVersion',
    'docRegion',
    'docType',
    'docId',
    'docExpiryDate',
    'docExpiryDateString',
  ];
  isCardNumberEnabled && fields.push('docCardNumber');
  return fields.some((field) => `${registration[field]}` !== `${primaryDocument[field]}`);
};

export const saveRegistrationDocument = async (
  { registration, documents = [], isCardNumberEnabled = false } = { documents: [] },
) => {
  if (!registration) {
    throw Error('registration is required');
  }

  const primaryIdDocNotCancelled = documents.find(
    ({ docTag, docStatus }) => docTag && docTag.includes(PRIMARY_ID_TAG) && ['New', 'Accepted'].includes(docStatus),
  );

  const hasChanged =
    primaryIdDocNotCancelled &&
    hasDocumentInfoChanged({ registration, primaryDocument: primaryIdDocNotCancelled, isCardNumberEnabled });

  if (primaryIdDocNotCancelled && hasChanged) {
    await saveEntity({
      entity: {
        ...primaryIdDocNotCancelled,
        docTag: JSON.stringify(
          ((primaryIdDocNotCancelled.docTag && JSON.parse(primaryIdDocNotCancelled.docTag)) || []).filter(
            (tag) => tag !== PRIMARY_ID_TAG,
          ),
        ),
        docStatus: 'Cancelled',
        docNotApprovedReason: 'User updated details - new doc created',
      },
      type: CoconutTypes.DOCUMENT,
    });
  }

  if (!primaryIdDocNotCancelled || hasChanged) {
    await saveEntity({
      entity: {
        type: CoconutTypes.DOCUMENT,
        docStatus: 'New',
        parentId: registration.id,
        parentType: registration.type,
        docIssuingCountry: registration.docIssuingCountry,
        docLicenceVersion: registration.docLicenceVersion,
        docRegion: registration.docRegion,
        docType: registration.docType,
        docId: registration.docId,
        docExpiryDate:
          registration.docExpiryDateString && parseInt(dayjs(registration.docExpiryDateString, 'YYYY-MM-DD').format('X')),
        docExpiryDateString: registration.docExpiryDateString,
        docTag: JSON.stringify([PRIMARY_ID_TAG]),
        ...(isCardNumberEnabled && { docCardNumber: registration.docCardNumber }),
      },
      type: CoconutTypes.DOCUMENT,
    });
  }
};
