import React from 'react';
import PropTypes from 'prop-types';
import { usePayInBPay, useRegistration } from '@send-data-hooks';
import { ListView } from '@send-legacy-components/Common/ListView';
import { getCurrencyString } from '@sendpayments/js-utils/dist/utils/currency';
import { getBackgroundByKey } from '@send-services/resources';
import { useFeature } from '@sendpayments/react-shared/hooks/useFeature';
import { PD_2160_VRN_CREATION } from '@sendpayments/shared-constants/features';

const dataSourceBPay = [
  {
    id: 'bPay',
    headImage: getBackgroundByKey({ key: 'bpay_image', default: '/static/images/bpay.svg' }),
    clickable: true,
    expandable: true,
  },
];
export const BankDetailsPayInBPay = ({ amount, currency, convId }) => {
  const [
    {
      data: { isEnabled: vrnCreationEnabled },
    },
  ] = useFeature({
    feature: PD_2160_VRN_CREATION,
    functionName: 'VRNCreationFunction',
    activeValue: undefined,
    defaultValue: undefined,
  });
  const [{ data: userAccount }] = useRegistration();

  const [payInBPayDetail, loading] = usePayInBPay({ userAccount });

  return !vrnCreationEnabled && payInBPayDetail?.payInBPayCRN && !loading ? (
    <ListView highlight={false} dataSource={dataSourceBPay}>
      <div>
        <div className="small-title">Please pay the settlement payment to Send by using the below details:</div>
        <table className="bank-details">
          <tbody>
            <tr>
              <td>Amount Outstanding</td>
              <td className="info">{getCurrencyString({ amount, currency })}</td>
            </tr>
            <tr>
              <td>Biller Code</td>
              <td className="info">{payInBPayDetail.payInBPayBillerCode}</td>
            </tr>
            <tr>
              <td>CRN</td>
              <td className="info">{payInBPayDetail.payInBPayCRN}</td>
            </tr>
            <tr>
              <td>Payment Reference</td>
              <td className="info">{convId.substring(0, 5).toUpperCase()}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </ListView>
  ) : (
    ''
  );
};

BankDetailsPayInBPay.propTypes = {
  dataSourceBPay: PropTypes.array,
  amount: PropTypes.number,
  currency: PropTypes.string,
  convId: PropTypes.string,
};

BankDetailsPayInBPay.defaultProps = {
  dataSourceBPay: [],
  amount: 0,
  currency: '',
  convId: '',
};
