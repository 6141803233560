import React from 'react';
import { ActionableModal } from '@send-base/atomic-components/organisms';
import { SuccessCheckIcon } from '@send-base/atomic-components/atoms/icons/successCheckIcon';
import { Heading, Text, Link, Grid, GridItem, Button, Box } from '@chakra-ui/react';
import { FormState } from '@send-base/infrastructure/query';

interface ReviewModalProps {
  formData: FormState | undefined;
  isOpen: boolean;
  onClose: () => void;
  onPrimaryAction: () => void;
  isLoading: boolean;
  bankName?: string;
}

export const ReviewModal: React.FC<ReviewModalProps> = ({ isOpen, onClose, onPrimaryAction, formData, isLoading, bankName }) => {
  const { bsbNumber, accountName, accountNumber } = formData?.bankDetails || {};

  return (
    <ActionableModal
      isOpen={isOpen}
      actions={{
        primary: { text: 'Confirm', onClick: onPrimaryAction, isLoading: isLoading },
      }}
      onClose={onClose}
      key="DirectDebitReviewModal"
      disableCloseButton={isLoading}
    >
      <SuccessCheckIcon w="87px" h="87px" />
      <Heading as="h4" size="md" pt={6} pb={6}>
        Check your details are correct
      </Heading>
      <Text fontSize={{ sm: 'sm', md: 'md' }} pb={6}>
        By confirming to those terms, you are giving Send permission to deduct money directly from your bank account.{' '}
      </Text>
      <Box pb={6}>
        <Link _hover={{ color: 'primary.base' }} color="primary.base" href="https://www.monoova.com/ddrsa" target="_blank">
          <Text color="primary.base" fontWeight="medium">
            View Direct Debit Agreement
          </Text>
        </Link>
      </Box>
      <Box bg="gray.50" padding={{ base: 2, sm: 3, md: 4, lg: 5 }} borderRadius="md" border="1px" borderColor="gray.300">
        <Grid templateColumns="repeat(8, 1fr)" mb={3}>
          <GridItem colSpan={3}>
            <Text fontSize={{ base: '0.7rem', md: 'sm', lg: 'md' }} align="left" fontWeight="medium">
              BSB:
            </Text>
          </GridItem>
          <GridItem colSpan={3}>
            <Text fontSize={{ base: '0.7rem', md: 'sm', lg: 'md' }} align="left" position={'relative'}>
              {bsbNumber}
            </Text>
            {bankName && (
              <Text
                fontSize={{ base: '0.5rem', sm: 'sm', md: 'sm', lg: 'sm' }}
                bg={bankName ? 'yellow.300' : 'red.200'}
                borderRadius="5px"
                padding={'5px'}
                align="left"
                width={'fit-content'}
              >
                {bankName}
              </Text>
            )}
          </GridItem>
          <GridItem colSpan={2}>
            <Button onClick={onClose} variant="outline" size="sm" disabled={isLoading}>
              Change
            </Button>
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(8, 1fr)" mb={3}>
          <GridItem colSpan={3}>
            <Text
              fontSize={{
                base: '0.7rem',
                md: 'sm',
                lg: 'md',
              }}
              align="left"
              fontWeight="medium"
            >
              Account number:
            </Text>
          </GridItem>
          <GridItem colSpan={3}>
            <Text fontSize={{ base: '0.7rem', md: 'sm', lg: 'md' }} align="left">
              {accountNumber}
            </Text>
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(8, 1fr)">
          <GridItem colSpan={3}>
            <Text
              fontSize={{
                base: '0.7rem',
                md: 'sm',
                lg: 'md',
              }}
              align="left"
              fontWeight="medium"
            >
              Account name:
            </Text>
          </GridItem>
          <GridItem colSpan={3}>
            <Text fontSize={{ base: '0.7rem', md: 'sm', lg: 'md' }} align="left" mb={0}>
              {accountName}
            </Text>
          </GridItem>
        </Grid>
      </Box>
    </ActionableModal>
  );
};
