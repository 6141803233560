/**
 * A custom error when currency pair not supported
 */
export class CurrencyPairNotSupportedError extends Error {
  constructor(message = '', ...args) {
    // @ts-ignore Want to replicate Error as close as possible
    super(message, ...args);
    this.message = message;
    this.name = 'CurrencyPairNotSupportedError';
    Object.setPrototypeOf(this, CurrencyPairNotSupportedError.prototype);
  }
}
