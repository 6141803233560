import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, Input, Divider, Icon } from 'antd';
import OutsideClickHandler from 'react-outside-click-handler';
import classNames from 'classnames';
import { CurrencyFlag } from '@sendpayments/react-shared/components/CurrencyFlag';
import useCurrenciesFilter from './useCurrenciesFilter';
import styles from './CurrenciesDropDown.module.less';

const { Option, OptGroup } = Select;

/* eslint-disable */
// eslint is treating this as a component and it is not, so we do not need prop-types.
const renderOption = ({ currencyName, currencyCountry }) => (
  <Option key={currencyName.toLowerCase()} value={currencyName.toLowerCase()}>
    <CurrencyFlag currencyName={currencyName} />
    {'   '}
    <strong>{currencyName.toUpperCase()}</strong>
    {'   '}
    <span>{currencyCountry}</span>
  </Option>
);
/* eslint-enable */

const filterOption = (inputValue, { key, value }) =>
  (value && value.includes(inputValue.toLowerCase())) || (key && key.includes(inputValue.toLowerCase()));

const CurrenciesDropDown = ({ currencies, popularCurrencies, selectedCurrency, onCurrencyChanged, style, dropDownClassName }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const [currenciesFiltered, setCurrenciesFilter] = useCurrenciesFilter(currencies);
  const [currenciesPopularFiltered, setCurrenciesPopularFilter] = useCurrenciesFilter(popularCurrencies);

  const onChangeHandler = (e) => {
    const { value } = e.target;
    setCurrenciesFilter(value);
    setCurrenciesPopularFilter(value);
  };

  useEffect(() => {
    setMenuOpen(false);
  }, [selectedCurrency]);

  return (
    <div
      className={styles.main}
      style={style}
      onClick={() => {
        setMenuOpen(true);
      }}
    >
      <Select
        className={styles.container}
        showArrow={false}
        value={selectedCurrency && selectedCurrency.toLowerCase()}
        optionFilterProp="children"
        onChange={onCurrencyChanged}
        dropdownMatchSelectWidth={false}
        notFoundContent="Currency not found"
        dropdownClassName={classNames(styles.dropdown, dropDownClassName)}
        filterOption={filterOption}
        dropdownRender={(menu) => (
          <OutsideClickHandler
            onOutsideClick={() => {
              setMenuOpen(false);
            }}
          >
            <div className={styles.containerMenu}>
              <div className={styles.inputSearch}>
                <Icon type="search" />
                <Input placeholder="Type a currency or country" onChange={onChangeHandler} />
              </div>
              <Divider />
              {menu}
            </div>
          </OutsideClickHandler>
        )}
        open={isMenuOpen}
      >
        {popularCurrencies.length === 0 && currencies.length === 0 && renderOption({ currencyName: selectedCurrency })}
        <OptGroup key="popular" label="Popular Currencies">
          {currenciesPopularFiltered.map(({ currencyName, currencyCountry }) => renderOption({ currencyName, currencyCountry }))}
        </OptGroup>
        <OptGroup key="all" label="All Currencies">
          {currenciesFiltered.map(({ currencyName, currencyCountry }) => renderOption({ currencyName, currencyCountry }))}
        </OptGroup>
      </Select>
      <Icon className={styles.arrow} type={!isMenuOpen ? 'down' : 'up'} />
    </div>
  );
};

CurrenciesDropDown.propTypes = {
  selectedCurrency: PropTypes.string,
  onCurrencyChanged: PropTypes.func.isRequired,
  currencies: PropTypes.arrayOf(PropTypes.object).isRequired,
  popularCurrencies: PropTypes.arrayOf(PropTypes.object).isRequired,
  dropDownClassName: PropTypes.string,
  style: PropTypes.object,
};

CurrenciesDropDown.defaultProps = {
  selectedCurrency: undefined,
  dropDownClassName: undefined,
};

export default CurrenciesDropDown;
