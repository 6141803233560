import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal, Button } from 'antd';
import { Icon } from '@send-legacy-components/Common/Icon';
import styles from './Confirmation.module.less';

const ButtonWrapper = ({ okButtonText, cancelButtonText, onOkClick, onCancelClick }) => {
  return (
    <div className={styles.buttonWrapper}>
      <Button className={styles.button} type="secondary" key="secondary" onClick={onCancelClick}>
        {cancelButtonText}
      </Button>
      <Button className={styles.button} key="primary" type="primary" onClick={onOkClick}>
        {okButtonText}
      </Button>
    </div>
  );
};

const Confirmation = ({
  title,
  titleIcon,
  bodyText,
  bodySubText,
  hidCloseCross,
  okButtonText,
  onOkClick,
  cancelButtonText,
  onCancelClick,
}) => (
  <Modal
    wrapClassName={classNames(styles.container, hidCloseCross && styles.hideCross)}
    title={
      // eslint-disable-next-line react/jsx-wrap-multilines
      <div className={styles.title}>
        <Icon type={titleIcon} />
        <span>{title}</span>
      </div>
    }
    visible
    onOk={onOkClick}
    onCancel={onCancelClick}
    centered
    footer={[
      <ButtonWrapper
        key="buttonWrapper"
        okButtonText={okButtonText}
        cancelButtonText={cancelButtonText}
        onOkClick={onOkClick}
        onCancelClick={onCancelClick}
      />,
    ]}
    width={402}
  >
    {typeof bodyText === 'string' ? <h1>{bodyText}</h1> : bodyText}
    {typeof bodySubText === 'string' ? <p>{bodySubText}</p> : bodySubText}
  </Modal>
);

Confirmation.propTypes = {
  title: PropTypes.string,
  titleIcon: PropTypes.string,
  bodyText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  bodySubText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  okButtonText: PropTypes.string,
  onOkClick: PropTypes.func.isRequired,
  cancelButtonText: PropTypes.string,
  onCancelClick: PropTypes.func.isRequired,
  hidCloseCross: PropTypes.bool,
};

Confirmation.defaultProps = {
  title: 'Confirmation needed',
  titleIcon: 'exclamation-circle',
  bodyText: 'Do you want to confirm this action?',
  bodySubText: 'Changes will be discarded',
  okButtonText: 'Ok',
  cancelButtonText: 'Cancel',
  hidCloseCross: true,
};

export default Confirmation;
