import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'antd/dist/antd.less';
import { Auth, Cache } from 'aws-amplify';
import React, { useState, useEffect } from 'react';
import { Router } from 'react-router-dom';
import history from '@send-services/history';
import { query } from '@send-base/utilities/endpoint';
import { logger } from '@sendpayments/js-utils/dist/services/logger';
import { useAppContext } from '@send-data-hooks';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
import * as queries from '@sendpayments/graphql/queries';
import '@send-legacy-components/UI/index.less';
import { Routes } from './Route';
import { SendPaymentsThemeWrapper } from '@send-common-components/SendPaymentsTheme';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [{ loading, user, setLoading, setPermissions, setGroups, setAllowedFields }] = useAppContext();

  const loadSettings = async () => {
    try {
      logger.log('@App', 'initializing app settings');
      logger.log('@App', 'fetching new settings');

      setLoading(true);

      await Auth.currentAuthenticatedUser();

      const appConfig = await query(queries.getCoconut, {
        params: { id: 'Setting', type: CoconutTypes.SETTING },
        projection: ['affiliates', 'unqualifiedReasons', 'transferPurposes', 'transferAmounts', 'countries', 'currencies'],
      });

      logger.log('@App', 'using settings', appConfig);

      for (let i = 0; i < appConfig.currencies.length; i++) {
        const currency = appConfig.currencies[i].split(' - ');
        appConfig.currencies[i] = [currency[1], currency[0]];
      }

      setLoading(false);

      const expiration = new Date();
      expiration.setHours(expiration.getHours() + 1);

      logger.log('@App', `saving config until ${expiration}`, appConfig);

      Cache.setItem('settings', appConfig, { expires: expiration.getTime() });
    } catch {
      history.push(`/auth`);
    }
  };

  useEffect(() => {
    if (user) {
      if (!Cache.getItem('settings')) {
        loadSettings();
      } else {
        logger.log('@App', `using cached settings`);
      }
    }
  }, [user]);

  return (
    <div className="App">
      <Router history={history}>
        <SendPaymentsThemeWrapper>
          <Routes
            childProps={{
              user,
              isLoggedIn,
              setIsLoggedIn,
              loading,
              setLoading,
              setPermissions,
              setGroups,
              setAllowedFields,
            }}
          />
        </SendPaymentsThemeWrapper>
      </Router>
    </div>
  );
};

export default App;
