import React from 'react';
import { Col, Progress, Row } from 'antd';
import PropTypes from 'prop-types';
import { SFooter, SSubAreaTitle } from '@send-legacy-components/UI';
import StepFormLayout from '@send-legacy-components/UI/StepFormLayout';
import { Form as RecipientForm } from '@send-legacy-components/Recipient';
import { useFeature } from '@sendpayments/react-shared/hooks/useFeature'; // can be removed in the future
import { PD_1619_BENE_TEXT } from '@sendpayments/shared-constants/features';

const AddStep = ({ loading }) => {
  const [
    {
      data: { isEnabled: isBeneTextEnabled },
    },
  ] = useFeature({
    feature: PD_1619_BENE_TEXT,
    functionName: 'beneText',
  });
  return (
    <StepFormLayout loading={loading}>
      <Progress
        strokeColor={{
          '0%': '#108ee9',
          '100%': '#87d068',
        }}
        className="top-progress"
        showInfo={false}
        size="small"
      />
      <div className="container">
        <Row gutter={{ xs: 0, md: 16 }}>
          <Col md={24} lg={{ span: 16, offset: 4 }}>
            <Row className="flex">
              <h1 className="bold" style={{ marginTop: 20 }}>
                Add recipient
              </h1>
              <SSubAreaTitle text="Recipient" icon="solution" />
              {isBeneTextEnabled && (
                <>
                  <br />
                  <p>
                    A Recipient is a person or a company that you are sending funds to. You will need to add at least one
                    recipient to your Send profile, this may also include yourself if you want to send funds to your own overseas
                    bank account by selecting the Myself option.
                  </p>
                  <p>
                    You may save as many recipients in your profile as you like for future use however, remember that you cannot
                    edit their details once saved so you will need to delete and re-add if you need to change the information.
                  </p>
                </>
              )}
            </Row>
          </Col>
        </Row>
        <Row gutter={{ xs: 0, md: 16 }}>
          <Col md={24} lg={{ span: 16, offset: 4 }}>
            <RecipientForm />
          </Col>
        </Row>
      </div>
      <div />
      <SFooter style={{ marginTop: 0 }} />
    </StepFormLayout>
  );
};

AddStep.propTypes = {
  loading: PropTypes.bool,
};

AddStep.defaultProps = {
  loading: false,
};

export default AddStep;
