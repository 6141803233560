/* eslint-disable react/prop-types */
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Select as SelectAntd, Tooltip } from 'antd';
import React, { useState, forwardRef } from 'react';
import { withAnalytics } from '@sendpayments/react-shared/HOC/withAnalytics';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

const { Select } = withAnalytics({ Select: SelectAntd }, { info: 'DateSelect' });

const { Option } = SelectAntd;

const getDateObj = (value) => (typeof value === 'number' ? dayjs(value * 1000) : value);

const style = {
  minWidth: 60,
  width: '30%',
};

const DateSelect = forwardRef((props, ref) => {
  const [state, setState] = useState();

  const handleChange = (key, changed) => {
    const { onChange } = props;
    const { value: input } = props;

    const value = getDateObj(input);

    let date = value ? { day: dayjs.utc(value).date(), month: dayjs.utc(value).month() + 1, year: dayjs.utc(value).year() } : {};
    date = { ...date, ...state, [key]: changed };
    setState(date);

    if (date.day && date.month && date.year) {
      const newDate = dayjs.utc(`${date.year}-${date.month}-${date.day} 00:00`, 'YYYY-MM-DD mm:ss');
      if (onChange) {
        onChange(newDate);
      }
    }
  };

  const { value: input } = props;
  const value = getDateObj(input);

  let date = value ? { day: dayjs.utc(value).date(), month: dayjs.utc(value).month() + 1, year: dayjs.utc(value).year() } : {};
  date = { ...date, ...state };

  let yearRange = Array(200)
    .fill(1910)
    .map((x, y) => x + y);

  if (props.forwardOnly) {
    yearRange = Array(100)
      .fill(dayjs().year())
      .map((x, y) => x + y);
  }

  if (props.pastOnly) {
    yearRange = Array(100)
      .fill(dayjs().year())
      .map((x, y) => x - y);
  }

  if (props.isDob) {
    yearRange = Array(100)
      .fill(dayjs().year())
      .map((x, y) => x - y - 17);
  }

  return (
    <span ref={ref} style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Tooltip title="Day">
        <Select
          disabled={props.disabled}
          value={date.day || undefined}
          placeholder="Day"
          style={style}
          onChange={(v) => handleChange('day', v)}
        >
          {Array(31)
            .fill(1)
            .map((x, y) => (
              <Option key={`${x + y}`}>{x + y}</Option>
            ))}
        </Select>
      </Tooltip>
      <Tooltip title="Month">
        <Select
          disabled={props.disabled}
          value={date.month || undefined}
          placeholder="Month"
          style={style}
          onChange={(v) => handleChange('month', v)}
        >
          {Array(12)
            .fill(1)
            .map((x, y) => (
              <Option key={`${x + y}`}>{x + y}</Option>
            ))}
        </Select>
      </Tooltip>
      <Tooltip title="Year">
        <Select
          disabled={props.disabled}
          value={date.year || undefined}
          placeholder="Year"
          style={style}
          onChange={(v) => handleChange('year', v)}
        >
          {yearRange.map((year) => (
            <Option key={year}>{year}</Option>
          ))}
        </Select>
      </Tooltip>
    </span>
  );
});

export default DateSelect;
