import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { getAppConfig } from '@send-legacy-utils/appConfig';
import { SupportCard } from '@send-legacy-components/SupportCard';
import { useLoading, useShowBubbles } from '@send-data-hooks';

const SupportCardContainer = memo(({ supportUsername }) => {
  const [loading] = useLoading();
  const [showBubbles, setShowBubbles] = useShowBubbles();
  const appConfig = getAppConfig();
  const accountManager =
    supportUsername && appConfig.accountManagers[supportUsername]
      ? appConfig.accountManagers[supportUsername]
      : appConfig.accountManagers.default;

  return (
    <SupportCard
      avatar={accountManager.avatar}
      icon={accountManager.icon || accountManager.avatar}
      email={accountManager.email}
      name={accountManager.name}
      title={accountManager.title}
      bio={accountManager.bio}
      hobby={accountManager.hobby}
      showBubbles={showBubbles}
      onShowBubbles={() => setShowBubbles(false)}
      loading={loading}
    />
  );
});

SupportCardContainer.propTypes = {
  supportUsername: PropTypes.string,
};

SupportCardContainer.defaultProps = {
  supportUsername: '',
};

export default SupportCardContainer;
