import React, { useState, useEffect } from 'react';
import { List } from '@send-legacy-components/Documents';
import { Prompt, useHistory } from 'react-router-dom';
import { Confirmation, useConfirmation } from '@send-legacy-components/Common/Confirmation';
import { usePendingDocuments, useUploadedDocuments, useCurrentEmail } from '@send-data-hooks';

function Document() {
  const [{ data: userEmail }] = useCurrentEmail();
  const history = useHistory();
  const [isDirty, setDirtyState] = useState(false);
  const [navigateOut, setNavigateOut] = useState();
  const [toLocation, setToLocation] = useState();
  const [{ data: pendingDocs, loading: loadingPendingDocuments }] = usePendingDocuments({ owner: userEmail });
  const [{ data: acceptedDocs, loading: loadingAcceptedDocuments }] = useUploadedDocuments({ owner: userEmail });
  const [isConfirmationVisible, { setConfirmationVisible, setConfirmationHidden }] = useConfirmation();

  const onBlockedNavigationChangeHandler = (location) => {
    if (isDirty && !isConfirmationVisible) {
      setToLocation(location.pathname);
      setConfirmationVisible();
      return false;
    }

    return true;
  };

  const onDirtyStateChangeHandler = (dirtyStateChanged) => {
    setDirtyState(dirtyStateChanged);
  };

  const onConfirmCallback = () => {
    setNavigateOut(toLocation);
  };

  const onCancelCallback = () => {
    setConfirmationHidden();
    setNavigateOut(undefined);
  };

  useEffect(() => {
    if (typeof navigateOut === 'string') {
      history.push(navigateOut);
    }
  }, [navigateOut]);

  return (
    <>
      <Prompt when={isDirty} message={onBlockedNavigationChangeHandler} />
      {isConfirmationVisible && (
        <Confirmation
          title="Cancel file upload?"
          bodyText="Are you sure you want to cancel this upload?"
          bodySubText="Your files haven’t yet been submitted.
          To finish uploading, click ‘Return to Upload’ and then ‘Upload Now’."
          okButtonText="Cancel upload"
          cancelButtonText="Return to upload"
          onOkClick={onConfirmCallback}
          onCancelClick={onCancelCallback}
        />
      )}
      <List
        onDirtyStateChange={onDirtyStateChangeHandler}
        loading={loadingPendingDocuments || loadingAcceptedDocuments}
        pending={pendingDocs}
        uploaded={acceptedDocs}
        owner={userEmail}
      />
    </>
  );
}

export default Document;
