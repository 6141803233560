import { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import * as subscriptions from '@sendpayments/graphql/subscriptions';
import { graphqlOperationEx } from '@sendpayments/graphql/fields';
import { logger } from '@sendpayments/js-utils/dist';
import * as queries from '@sendpayments/graphql/queries';

const usePayInBPay = ({ userAccount: { id, type, email } }) => {
  const [payInBPayDetail, setPayInBPayDetail] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!payInBPayDetail.payInBPayCRN) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [payInBPayDetail]);

  useEffect(() => {
    if (id && type) {
      API.graphql(graphqlOperationEx(queries.getCoconut, { id, type }))
        .then(({ data: { getCoconut: account } }) => {
          const { payInBPayCRN, payInBPayBillerCode } = account;
          if (!payInBPayCRN) {
            setLoading(true);
          }
          setPayInBPayDetail({
            payInBPayCRN,
            payInBPayBillerCode,
          });
        })
        .catch((error) => logger.error(error));
    }
  }, [id, type]);

  useEffect(() => {
    // create subscribe
    const subscription = API.graphql(
      graphqlOperationEx(
        subscriptions.onCreateCoconutByOwnerType,
        {
          owner: email,
          type,
        },
        ['payInBPayCRN'],
      ),
    ).subscribe({
      next: (coconut) => {
        const { payInBPayCRN } = coconut.value.data.onCreateCoconutByOwnerType;

        setPayInBPayDetail({
          payInBPayCRN,
        });
      },
      error: (err) => logger.error('create', 'payInBPayCRN', err),
    });
    // update subscribe
    const updateSubscription = API.graphql(
      graphqlOperationEx(
        subscriptions.onUpdateCoconutByOwnerType,
        {
          owner: email,
          type,
        },
        ['payInBPayCRN'],
      ),
    ).subscribe({
      next: (coconut) => {
        const { payInBPayCRN } = coconut.value.data.onUpdateCoconutByOwnerType;
        setPayInBPayDetail({
          payInBPayCRN,
        });
      },
      error: (err) => logger.error('update', 'payInBPayCRN', err),
    });
    return function cleanup() {
      subscription?.unsubscribe();
      updateSubscription?.unsubscribe();
    };
  }, []);

  return [payInBPayDetail, loading];
};

export default usePayInBPay;
