import PropTypes from 'prop-types';
import { Avatar, Dropdown } from 'antd';
import { useProfilePhoto } from '@send-data-hooks';
import React from 'react';
import SUserMenu from './SUserMenu';

export const SUser = ({ registration }) => {
  const profilePhotoUrl = useProfilePhoto(registration.profilePhoto);
  const avatarProps = {};
  if (profilePhotoUrl) {
    Object.assign(avatarProps, {
      src: profilePhotoUrl,
      style: { border: 'none' },
    });
  }
  return (
    <div className="user">
      <Dropdown overlay={<SUserMenu registration={registration} />}>
        <Avatar className="user-avatar" size="large" icon="user" {...avatarProps} />
      </Dropdown>
    </div>
  );
};

SUser.propTypes = {
  registration: PropTypes.object,
};

SUser.defaultProps = {
  registration: {},
};
