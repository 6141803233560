import React from 'react';
import { withTheme } from '@sendpayments/react-shared/components/ThemeProvider';
import { Dialog, Heading, DialogContainer, View, Content } from '@adobe/react-spectrum';
import { useBreakpoint } from '@sendpayments/react-shared/components/MediaBreakpoint';
import { breakpoints } from '@sendpayments/react-shared/design-system';
import { CloseIcon } from '@sendpayments/react-shared/components/CloseIcon';
import { PrimaryActionButton } from '@sendpayments/react-shared/components/buttons/PrimaryActionButton';
import { PrimaryButton } from '@send-components/PrimaryButton';
import styles from './MfaReminderModal.module.less';

const DialogContent = ({ title, onClose, onAccept, onCancel, children }) => {
  const { isMediaXs } = useBreakpoint(breakpoints);

  return (
    <Dialog size="M" UNSAFE_className={styles.modal}>
      <CloseIcon className={styles.closeIcon} onClick={onClose} />
      <Heading UNSAFE_className={styles.title}>{title}</Heading>
      <Content UNSAFE_className={styles.content}>
        <View>{children}</View>

        <div className={styles.buttonGroup} style={{ flexDirection: isMediaXs ? 'column' : 'row' }}>
          <PrimaryActionButton
            UNSAFE_className={`${styles.inline} ${styles.button}`}
            marginBottom={isMediaXs ? 'size-150' : '0'}
            onClick={onAccept}
          >
            Turn on 2-step verification
          </PrimaryActionButton>
          <PrimaryButton UNSAFE_className={styles.button} onClick={onCancel}>
            Don&apos;t show this again
          </PrimaryButton>
        </div>
      </Content>
    </Dialog>
  );
};

const MfaReminderModal = ({ isOpen, onDismiss, ...props }) => {
  return <DialogContainer onDismiss={onDismiss}>{isOpen && <DialogContent {...props} />}</DialogContainer>;
};

MfaReminderModal.displayName = 'MfaReminderModal';

export default withTheme(MfaReminderModal);
