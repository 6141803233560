import React from 'react';
import { CurrencyFlag } from '@sendpayments/react-shared/components/molecules/CurrencyFlag';
import { Flex, Box, Grid } from '@chakra-ui/react';
import { DownChevron } from '@sendpayments/react-shared/components/Icons';

export interface SelectorWithFlagsProps {
  isOpen: boolean;
  isInvalid: boolean;
  currencyCode: string;
  toggleOpen: () => void;
}

export const SelectorWithFlags: React.FC<SelectorWithFlagsProps> = ({ isOpen, isInvalid, currencyCode, toggleOpen }) => {
  let borderColor = 'border.base';
  if (isOpen) {
    borderColor = 'secondary.tint';
  } else if (isInvalid) {
    borderColor = 'error';
  }
  return (
    <Flex
      _hover={{ cursor: 'pointer', border: 'light', borderColor: 'secondary.tint' }}
      border={'1px solid'}
      borderColor={borderColor}
      borderRadius="4px"
      h={12}
      flex={1}
      onClick={() => toggleOpen()}
    >
      {currencyCode && (
        <Box p={1} alignSelf="center" ml={1} mr={1}>
          <CurrencyFlag currencyCode={currencyCode} size={7} />
        </Box>
      )}
      <Box alignSelf="center" mr={1} w={'100%'} fontSize="md">
        {currencyCode || <Box paddingLeft={3}>Not selected</Box>}
      </Box>
      <Grid
        borderLeft={'1px solid'}
        borderLeftColor={borderColor}
        borderLeftRadius="0px"
        borderRightRadius="4px"
        backgroundColor="border.light"
        p="0 3px"
      >
        <Box alignSelf="center">
          <DownChevron height="100%" display="flex" fontSize="lg" m="0 5px" alignSelf="center" color={'primary.base'} w="25px" />
        </Box>
      </Grid>
    </Flex>
  );
};
