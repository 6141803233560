import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { SupportCard } from '@send-legacy-containers/SupportCard';
import { SFooter } from '@send-legacy-components/UI';
import { useRegistration } from '@send-data-hooks';

const CustomLayout = (props) => {
  const [{ data: reg }] = useRegistration();

  const layoutProps = Object.keys(props).reduce((acc, key) => {
    if (key !== 'showSupportButton') {
      acc[key] = props[key];
    }
    return acc;
  }, {});
  return (
    <Layout {...layoutProps}>
      {props.children}
      <SFooter />
      {props.showSupportButton && <SupportCard supportUsername={reg.accountManager} />}
    </Layout>
  );
};

CustomLayout.propTypes = {
  showSupportButton: PropTypes.bool,
  children: PropTypes.any,
};

CustomLayout.defaultProps = {
  showSupportButton: false,
};

export default CustomLayout;
