import * as queries from '@sendpayments/graphql/queries';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
import { query } from '@send-base/utilities/endpoint';
import { buildUniqueKey } from '@sendpayments/js-utils/dist/services/build-unique-key';
import { useRemoteData } from '@sendpayments/react-shared/hooks/useRemoteData';

const fetchDocuments = async ({ email }) => {
  const documentQuery = {
    owner: email,
    type: { eq: CoconutTypes.DOCUMENT },
  };

  const { items } = await query(queries.listCoconutsByOwner, { params: documentQuery });

  return items;
};

const useDocumentsByOwner = ({ owner }) =>
  useRemoteData(
    () => ({
      key: buildUniqueKey('queries.listCoconutsByOwner', { type: CoconutTypes.DOCUMENT }),
      onInitialFetch: () => fetchDocuments({ email: owner }),
      initialState: [],
    }),
    [owner],
  );

export default useDocumentsByOwner;
