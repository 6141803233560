import { useContext } from 'react';
import * as queries from '@sendpayments/graphql/queries';
import history from '@send-legacy-app/history';
import { AppContext } from '@send-legacy-app/Context';
import { query } from '@send-base/utilities/endpoint';
import { buildUniqueKey } from '@sendpayments/js-utils/dist/services/build-unique-key';
import { logger } from '@sendpayments/js-utils/dist/services/logger';
import { useRemoteData } from '@sendpayments/react-shared/hooks/useRemoteData';
import { analytics } from '@sendpayments/js-utils/dist';
import memoizeOne from 'memoize-one';
import isEqual from 'lodash/isEqual';
import { CorporateRegistration, RetailRegistration } from '../../types';

const analyticsPush = memoizeOne(
  ({ type, status, estimatedValue, affiliate, country, corporateCountry, frequency, purpose }: Record<string, string>) => {
    analytics.push({
      user: {
        type,
        status,
        expectedFirstTransfer: estimatedValue,
        affiliate,
        country: country ?? corporateCountry,
        frequency,
        purpose,
      },
    });
  },
  isEqual,
);

const getRegistration = async (email: string, type: 'Retail' | 'Corporate') => {
  try {
    const { items: registrations } = await query(queries.listCoconutsByOwner, {
      params: {
        owner: email,
        type: { eq: type },
      },
      projection: {},
    });

    if (registrations.length > 0) {
      const foundReg = registrations.find(({ status }) => status !== 'Incomplete');

      if (foundReg) {
        logger.log('useRegistration', 'getRegistration, reg found', foundReg);
        return foundReg;
      }

      logger.log('useRegistration', `we could not find a Completed reg using ${email} and ${type}, returning`, registrations[0]);
      return registrations[0];
    }

    // default to an empty object
    return {};
  } catch (err) {
    logger.error('useRegistration', 'getRegistration', err);
    history.push(`/error`);
    throw err;
  }
};

function useRegistrationByType<T extends RetailRegistration | CorporateRegistration>(
  type: 'Retail' | 'Corporate',
): [T, ((entity: any) => void) | undefined] {
  const { user }: { user: any } = useContext(AppContext);

  const owner = user && user.attributes && user.attributes.email;

  const [registration, { createOrUpdateEntity: setRegistrationRetail }] = useRemoteData(() => {
    return {
      key: buildUniqueKey('queries.listCoconutsByOwner', { type }),
      onInitialFetch: () => getRegistration(owner, type),
      initialState: {},
    };
  }, [owner, type]);

  // Push analytics for user. Be careful to not push any disallowed identfying data (e.g. emails)
  if (registration?.data && Object.keys(registration.data).length !== 0 && registration.data.id) {
    const { status, estimatedValue, affiliate, country, corporateCountry, frequency, purpose } = registration?.data;
    analyticsPush({ type, status, estimatedValue, affiliate, country, corporateCountry, frequency, purpose });
  }

  return [registration as unknown as T, setRegistrationRetail];
}

export default useRegistrationByType;
