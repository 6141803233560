import React, { useState } from 'react';
import {
  Stack,
  Button,
  Link,
  Text,
  Box,
  FormLabel,
  FormControl,
  FormErrorMessage,
  useDisclosure,
  Heading,
} from '@chakra-ui/react';
// Might need to export these together.
import {
  Address,
  getAddressDetailsFromPlaceId,
  useGoogleMaps,
  getDisplayValueFromId,
  renderSuggestions,
} from '@sendpayments/react-shared/components/molecules/AutocompleteInput/AutocompleteInput';
import {
  AutocompleteInput,
  InputField,
  PhoneExtSelect,
  Select,
  DateInput,
} from '@sendpayments/react-shared/components/molecules';
import { useToast } from '@sendpayments/react-shared/components/molecules/Toast';
import { Field, Form, Formik } from 'formik';
import { CountrySelect, DetailedAddressSection, SimpleModal } from '@send-base/atomic-components/organisms';
import * as yup from 'yup';
import { businessRoles, countries } from '@sendpayments/js-utils/dist/const/defaults';
import logger from '@sendpayments/js-utils/dist/services/logger';
import { analytics, lookup } from '@sendpayments/js-utils/dist';
import { SendForm } from '../types';

import merge from 'lodash/merge';
import pick from 'lodash/pick';
import { ChevronIcon, EarthIcon } from '@sendpayments/react-shared/components/Icons';
import { appConfig } from '@send-base/app.config';
import dayjs from 'dayjs';

const address = {
  streetAddressUnitNumber: '',
  streetAddressName: '',
  streetAddressNumber: '',
  streetAddressType: '',
  city: '',
  state: '',
  postcode: '',
  country: '',
};

const fields = {
  businessRole: '',
  firstName: '',
  middleName: '',
  lastName: '',
  birthDate: '',
  countryOfBirth: '',
  phone: '',
  phoneCountryCode: '+61',
  ...address,
};

const CorporateInformationSchema = yup.object({
  businessRole: yup.string().required('Please select your role'),
  firstName: yup.string().trim().required('Please enter your name'),
  middleName: yup.string().trim().nullable(),
  lastName: yup.string().trim().required('Please enter your last name'),
  birthDate: yup.string().nullable().required('Please enter your date of birth'),
  countryOfBirth: yup.string().trim().required('Please enter your country of birth'),
  phoneCountryCode: yup.string().trim().required('Please select your country code'),
  phone: yup.string().trim().required('Please enter your mobile phone number'),
  streetAddressUnitNumber: yup.string().trim().nullable(),
  streetAddressName: yup.string().trim().required('Please enter your street name'),
  streetAddressNumber: yup.string().trim().required('Please enter your street number'),
  streetAddressType: yup.string().trim().required('Please select your street type'),
  city: yup.string().trim().required('Please enter your city'),
  state: yup.string().trim().required('Please select your state'),
  postcode: yup.string().trim().required('Please enter your post code'),
  country: yup
    .string()
    .oneOf(appConfig.supportedCountries, 'Please select a country we serve')
    .required('Please select your country'),
});

export type CorporateInformationData = Omit<yup.InferType<typeof CorporateInformationSchema>, 'birthDate'> & {
  birthDateString?: string;
  birthDate?: number | string;
};

export interface CorporateInformationFormProps extends SendForm<CorporateInformationData> {
  onCancel: () => void;
  showDetailedAddressSection?: boolean;
}

interface PhoneExt {
  phoneExtension?: string;
  country: string;
  currencyCode: string;
  currencyName?: string;
}

export const CorporateInformationForm: React.FC<CorporateInformationFormProps> = ({
  onCancel,
  showDetailedAddressSection,
  onSubmit,
  isLoading,
  initialValues,
}) => {
  const { value, setValue, data, loading } = useGoogleMaps();
  const [selectedLocationId, setSelectedLocationId] = useState('');
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showDetailedAddressForm, setShowDetailedAddressForm] = useState(showDetailedAddressSection ?? false);

  const findPhoneExtFromCountry = (country: string): string => {
    const countryObject = Object.entries<PhoneExt>(lookup).find(([, value]) => value.country === country)?.[1];
    return countryObject?.phoneExtension ?? '';
  };

  const NotFoundSection = (
    <Text fontSize="12px">
      <Text as="b" fontSize="inherit">
        No matches found.
      </Text>{' '}
      Please try again or{' '}
      <Link color="primary" textDecoration="underline !important" onClick={() => setShowDetailedAddressForm(true)}>
        enter the details manually here.
      </Link>
    </Text>
  );

  return (
    <Box>
      <Formik
        initialValues={merge(fields, initialValues)}
        validationSchema={CorporateInformationSchema.shape({
          birthDate: yup
            .string()
            .nullable()
            .required('Please enter your date of birth')
            .test('test-date-format', 'Birthdate is invalid.', (value) => !value || dayjs(value, 'YYYY-MM-DD', true).isValid())
            .test(
              'test-age',
              'You must be 18 years or older to use Send.',
              (value) => !value || dayjs().subtract(18, 'year') > dayjs(value),
            ),
        })}
        onSubmit={async (values, actions) => {
          try {
            await onSubmit(values);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            analytics.push({ action: { type: 'form_submit', data: { name: 'CorporateInformationForm' } } });
          } catch (error) {
            logger.error('CorporateInformationForm', 'ERROR: ', error);
            actions.setSubmitting(false);
            toast({
              variant: 'negative',
              title: 'Something went wrong on our end. Please try again!',
            });
          }
        }}
      >
        {(props) => {
          return (
            <>
              <SimpleModal
                open={isOpen}
                primaryButtonText="Proceed"
                secondaryButtonText="Cancel onboarding"
                onClose={onClose}
                onSecondaryAction={onCancel}
                onPrimaryAction={() => {
                  Object.keys(address).map((key) => props.setFieldValue(`${key}`, ''));
                  setSelectedLocationId('');
                  setValue('');
                  onClose();
                }}
                key={'ValidCountryModal'}
              >
                <EarthIcon color="text.base" boxSize={{ base: '57px', lg: '83px' }} />
                <Heading as="h3" variant="heading3" mt={4}>
                  We are sorry, we don&apos;t serve your country of residence yet.
                </Heading>
                <Text as="span">If you have a primary address that is on our </Text>
                <Link as="a" fontSize="md" color="text.base" pr={1} href={appConfig.sendSupportedCountriesUrl} isExternal>
                  list of countries we serve,
                </Link>
                <Text as="span">please choose &apos;proceed&apos; and input your primary address details.</Text>
              </SimpleModal>
              <Form noValidate>
                <Stack>
                  {/* Business Role */}
                  <Field name="businessRole">
                    {({ field, form }) => (
                      <FormControl id={field.name} isInvalid={form.errors[field.name] && form.touched[field.name]}>
                        <FormLabel mb={1}>Role</FormLabel>
                        <Select
                          placeholder={'Select your role...'}
                          value={businessRoles
                            .map((it) => ({ value: it, label: it }))
                            .find((item: { value: string }) => item.value === field.value)}
                          options={businessRoles.map((businessRole: string) => ({ label: businessRole, value: businessRole }))}
                          onChange={(option) => field.onChange(field.name)(option?.value)}
                          width="100%"
                          isInvalid={form.errors[field.name] && form.touched[field.name]}
                        />
                        <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  {/* First name */}
                  <Field name="firstName">
                    {({ field, form }) => (
                      <InputField
                        id={field.name}
                        label="First legal name"
                        placeholder="Enter your first legal name"
                        isInvalid={!!form.errors[field.name] && form.touched[field.name]}
                        isValid={!form.errors[field.name] && !!field.value}
                        errorText={form.errors[field.name]}
                        inputProps={{
                          ...field,
                          autoComplete: 'given-name',
                          size: 'md',
                        }}
                      />
                    )}
                  </Field>

                  {/* Middle name */}
                  <Field name="middleName">
                    {({ field, form }) => (
                      <InputField
                        id={field.name}
                        label="Middle name"
                        placeholder="Enter your middle name"
                        isInvalid={!!form.errors[field.name] && form.touched[field.name]}
                        isValid={!form.errors[field.name] && !!field.value}
                        errorText={form.errors[field.name]}
                        inputProps={{
                          ...field,
                          autoComplete: 'additional-name',
                          size: 'md',
                        }}
                      />
                    )}
                  </Field>

                  {/* Last name */}
                  <Field name="lastName">
                    {({ field, form }) => (
                      <InputField
                        id={field.name}
                        label="Last legal name"
                        placeholder="Enter your last legal name"
                        isInvalid={!!form.errors[field.name] && form.touched[field.name]}
                        isValid={!form.errors[field.name] && !!field.value}
                        errorText={form.errors[field.name]}
                        inputProps={{
                          ...field,
                          autoComplete: 'family-name',
                          size: 'md',
                        }}
                      />
                    )}
                  </Field>

                  {/* Birth date */}
                  <Field name="birthDate">
                    {({ field, form }) => (
                      <DateInput
                        id={field.name}
                        label="Date of Birth"
                        onChange={(value) => props.setFieldValue(field.name, value)}
                        isInvalid={!!form.errors[field.name] && form.touched[field.name]}
                        error={form.errors[field.name]}
                        value={
                          field.value && dayjs.unix(field.value).isValid()
                            ? dayjs.unix(field.value).format('YYYY-MM-DD')
                            : undefined
                        }
                      />
                    )}
                  </Field>

                  {/* Birth country */}
                  <Field name="countryOfBirth">
                    {({ field, form }) => (
                      <CountrySelect
                        id={field.name}
                        label="Country of birth"
                        placeholder="Select your country of birth..."
                        value={field.value}
                        isInvalid={!!form.errors[field.name] && form.touched[field.name]}
                        errorText={form.errors[field.name]}
                        onCountryChange={(e) => field.onChange(field.name)(e?.value ?? '')}
                      />
                    )}
                  </Field>
                  {!showDetailedAddressForm && (
                    <Box>
                      <AutocompleteInput
                        value={value}
                        label="Address"
                        selectedItem={getDisplayValueFromId(selectedLocationId, data)}
                        setValue={(_, content) => {
                          setValue(content);
                        }}
                        inputProps={{ size: 'md' }}
                        autoComplete="street-address"
                        errorText={
                          Object.keys(address).some((key) => props.errors[key] && props.touched[key]) &&
                          !(showDetailedAddressForm || selectedLocationId)
                            ? 'Address not found. Please try again and choose from the list.'
                            : ''
                        }
                        items={renderSuggestions(data)}
                        loading={loading}
                        onItemSelect={async (e, id) => {
                          const data: Address = await getAddressDetailsFromPlaceId(id);
                          // Might be able to block here so they can repick from the list
                          if (
                            data.country &&
                            countries.includes(data.country) &&
                            !appConfig.supportedCountries.includes(data.country)
                          ) {
                            onOpen();
                          }
                          Object.keys(address).map((key) => props.handleChange(key)(data[key] ?? ''));

                          if (data.country && countries.includes(data.country)) {
                            props.setFieldValue('phoneCountryCode', findPhoneExtFromCountry(data.country));
                          }
                          setSelectedLocationId(id);
                        }}
                        notFoundSection={NotFoundSection}
                      />

                      {!selectedLocationId && (
                        <Link
                          onClick={() => {
                            setShowDetailedAddressForm(true);
                          }}
                          fontSize="md"
                          color="primary.base"
                        >
                          Can&apos;t find your address?
                        </Link>
                      )}
                    </Box>
                  )}
                  {(showDetailedAddressForm || selectedLocationId) && (
                    <DetailedAddressSection
                      errors={pick(props.errors, Object.keys(address))}
                      values={pick(props.values, Object.keys(address))}
                      touched={pick(props.touched, Object.keys(address))}
                      onBlur={(key) => {
                        props.setFieldTouched(`${key}`, true);
                      }}
                      onChange={(key, value) => {
                        if (key === 'country' && value && !appConfig.supportedCountries.includes(value)) {
                          onOpen();
                        } else {
                          props.setFieldValue(`${key}`, value);
                        }

                        if (key === 'country') {
                          props.setFieldValue('phoneCountryCode', findPhoneExtFromCountry(value));
                        }
                      }}
                    />
                  )}
                  <Box>
                    <FormLabel mb={1}>Mobile number</FormLabel>
                    <Stack direction="row">
                      {/* Phone Extension */}
                      <Field name="phoneCountryCode">
                        {({ field, form }) => (
                          <Box w={{ base: '40%', sm: '50%' }}>
                            <PhoneExtSelect
                              menuMinWidth="220px"
                              id="phone-extension"
                              isInvalid={!!form.errors[field.name] && form.touched[field.name]}
                              errorText={form.errors[field.name]}
                              value={field.value ? { label: '', value: field.value } : undefined}
                              label={''}
                              onSelect={(e) => field.onChange(field.name)(e?.value ?? '')}
                              placeholder="+61"
                            />
                          </Box>
                        )}
                      </Field>

                      {/* Phone Number */}
                      <Field name="phone">
                        {({ field, form }) => (
                          <InputField
                            id={field.value}
                            placeholder="Enter your mobile number"
                            isInvalid={!!form.errors[field.name] && form.touched[field.name]}
                            isValid={!form.errors[field.name] && field.value}
                            errorText={form.errors[field.name]}
                            inputProps={{
                              ...field,
                              autoComplete: 'tel-national',
                              size: 'md',
                            }}
                          />
                        )}
                      </Field>
                    </Stack>
                  </Box>
                  <Button
                    isLoading={props.isSubmitting || isLoading}
                    type="submit"
                    alignSelf="center"
                    fontSize="md"
                    rightIcon={<ChevronIcon h="8px" w="6px" direction="forward" />}
                  >
                    Next
                  </Button>
                </Stack>
              </Form>
            </>
          );
        }}
      </Formik>
    </Box>
  );
};
