import { CorporateRegistration, Registration, RetailRegistration } from '../../types';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
import { useRemoteDataSelector } from '@sendpayments/react-shared/hooks/useRemoteData';
import useRegistrationByType from './useRegistrationByType';

/**
 * A helper function to map the data from the two registration types.
 * Types technically can be in any order
 */
const mapRegistration = ({
  registrationRetail,
  registrationCorporate,
}: {
  registrationRetail?: RetailRegistration | CorporateRegistration;
  registrationCorporate?: CorporateRegistration | RetailRegistration;
}) => {
  if (registrationRetail && registrationRetail.data && registrationRetail.data.id) {
    return registrationRetail.data;
  }

  if (registrationCorporate && registrationCorporate.data && registrationCorporate.data.id) {
    return registrationCorporate.data;
  }

  return { data: {}, unknown: true };
};

/**
 * A handy hook to get the DynamoDB data for a customer
 */
function useRegistration(): [
  {
    data: Registration;
    loading: boolean;
    failed?: boolean;
  },
] {
  const [registrationRetail] = useRegistrationByType(CoconutTypes.RETAIL as 'Retail');
  const [registrationCorporate] = useRegistrationByType(CoconutTypes.CORPORATE as 'Corporate');

  const [registrationData] = useRemoteDataSelector(
    () => mapRegistration({ registrationRetail, registrationCorporate }),
    [registrationRetail, registrationCorporate],
  );

  return [registrationData];
}

export default useRegistration;
