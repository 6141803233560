/* eslint-disable no-console */
import { useEffect } from 'react';
import { useRegistration, useLogout } from '@send-data-hooks';
import { getPortalSource } from '@send-services/white-label';
import { isEmpty } from 'lodash';
import { getDomain, getSiteSubdomain, whiteLabelsList } from '@sendpayments/js-utils/dist/services/white-label-sources';

const WhiteLabelRedirect = ({ children }) => {
  const [{ data: registration }] = useRegistration();
  const [, , doSignOut] = useLogout();

  const doRedirect = async () => {
    // Avoid redirecting if this is development
    if (process.env.NODE_ENV !== 'development') {
      const source = isEmpty(registration.portalSource) ? 'sendfx' : registration.portalSource;
      const domain = getDomain({ source }, { sourcesList: whiteLabelsList, ENV: process.env.ENV });
      const subDomain = getSiteSubdomain({ source }, { sourcesList: whiteLabelsList, ENV: process.env.ENV });
      if (source !== getPortalSource()) {
        await doSignOut();
        window.location.replace(`https://${subDomain}.${domain}`);
      }
    }
  };
  useEffect(() => {
    if (registration.email) {
      doRedirect();
    }
  }, [registration]);

  return children;
};

export default WhiteLabelRedirect;
