import { withDecimals } from '@sendpayments/js-utils/dist/utils/numbers';
import { appConfig } from '@send-base/app.config';

const { secondDefaultCurrency, firstDefaultCurrency } = appConfig;

export const getFromAmount = (fixedAmount, { fromAmount, toAmount, invertedRate, fromDecimalPlaces = 2 }) => {
  if (fixedAmount === 'toAmount') {
    return withDecimals(toAmount * parseFloat(invertedRate) || 0, fromDecimalPlaces);
  }
  return withDecimals(fromAmount || 0, fromDecimalPlaces);
};

export const getToAmount = (fixedAmount, { fromAmount, toAmount, rate, toDecimalPlaces = 2 }) => {
  if (fixedAmount === 'fromAmount') {
    return withDecimals(fromAmount * parseFloat(rate) || 0, toDecimalPlaces);
  }
  return withDecimals(toAmount || 0, toDecimalPlaces);
};

export const getFromCurrency = (currency, { toCurrency }) => {
  if (toCurrency === currency && currency !== firstDefaultCurrency) {
    return firstDefaultCurrency;
  }

  return secondDefaultCurrency;
};

export const getToCurrency = (currency, { fromCurrency }) => {
  if (fromCurrency === currency && currency !== firstDefaultCurrency) {
    return firstDefaultCurrency;
  }

  return secondDefaultCurrency;
};
