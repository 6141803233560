import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Row } from 'antd';
import { Icon } from '@send-legacy-components/Common/Icon';
import { MediaBreakpoint, appBreakpoints } from '@sendpayments/react-shared/components/MediaBreakpoint';
import styles from './ListView.module.less';

export const listViewReferenceTypes = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
};

export const SpecialButtons = {
  ICON_RIGHT: 'right',
};

const ListView = ({
  dataSource,
  onItemClick,
  scrollSize,
  highlight,
  expandedIds,
  children,
  size,
  enableMobileView,
  className,
  loading,
  autoAdjustHeight,
}) => {
  const [indexPosition, setIndexPosition] = useState(1);
  const [expandedItemIds, setExpandedItemIds] = useState(expandedIds || []);
  const maxElementsToShow = scrollSize || dataSource.length;
  const ref = useRef(null);

  if (autoAdjustHeight && scrollSize) {
    throw Error('autoAdjustHeight and scrollSize cannot be used together since scrollSize uses a fixed height');
  }

  useEffect(() => {
    if (indexPosition > 1) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [indexPosition]);

  useEffect(() => {
    setExpandedItemIds(expandedIds || []);
  }, [expandedIds]);

  const oneOnlyItem = dataSource.length === 1;
  const slicedDataSource = dataSource.slice(0, maxElementsToShow * indexPosition);

  const onClick = ({ id, disabled, clickable }) => {
    if (clickable && onItemClick) {
      if (!disabled) {
        onItemClick({ id });
      }
    } else if (expandedItemIds.includes(id)) {
      setExpandedItemIds(expandedItemIds.filter((item) => item !== id));
    } else {
      setExpandedItemIds([...expandedItemIds, id]);
    }
  };

  const needScroll = dataSource.length > scrollSize;

  // eslint-disable-next-line react/prop-types
  const detailLink = ({ itemButton, id, disabled }) =>
    Object.values(SpecialButtons).includes(itemButton) ? (
      <div className={styles.buttonIcon} onClick={() => onClick({ id, disabled, clickable: true })}>
        <Icon type={itemButton} />
      </div>
    ) : (
      <div className={styles.buttonText} onClick={() => onClick({ id, disabled, clickable: true })}>
        {itemButton}
      </div>
    );

  // eslint-disable-next-line react/prop-types
  const renderRightSection = ({ reference, disabled, expandable, id, itemButton, clickable, noLink }) => (
    <>
      {reference && (
        // eslint-disable-next-line react/prop-types
        <div className={classNames(styles.reference, reference.type, reference.pill && styles.pill, 'reference')}>
          {/* eslint-disable-next-line react/prop-types */}
          <div className={classNames('referenceText')}>{reference.text}</div>
        </div>
      )}

      {!disabled && !loading && !noLink && (
        <>
          {expandable && clickable && !itemButton && !onItemClick && (
            <div className={classNames(styles.right, 'right')}>
              <Icon type={`${expandedItemIds.includes(id) ? 'up' : 'down'}`} />
            </div>
          )}
          {!itemButton && clickable && onItemClick && (
            <div className={classNames(styles.right, 'right')}>
              <Icon type="right" />
            </div>
          )}
          {itemButton && onItemClick && (
            <MediaBreakpoint from={appBreakpoints.sm}>{detailLink({ disabled, id, itemButton })}</MediaBreakpoint>
          )}
        </>
      )}
      {loading && !noLink && (
        <div className={classNames(styles.right, 'right')}>
          <Icon type="loading" />
        </div>
      )}
    </>
  );

  return (
    <>
      <div
        className={classNames(
          className,
          styles.blockGroup,
          scrollSize && styles.scroll,
          highlight && [styles.highlight, 'list-view-highlight'],
          'list-view-container',
        )}
        ref={ref}
      >
        {slicedDataSource.map(
          ({
            id,
            disabled = false,
            expandable = false,
            iconType,
            headImage,
            header,
            title,
            clickable = true,
            description,
            highlight: itemHighlight,
            descriptionIconType,
            reference,
            button: itemButton,
            noLink,
            visible = true,
          }) =>
            visible ? (
              <div
                key={id}
                data-testid={id}
                className={classNames(
                  styles.block,
                  !loading && clickable && !disabled && styles.clickable,
                  itemHighlight && styles.highlight,
                  disabled && styles.disabled,
                  oneOnlyItem && styles.oneItem,
                  `row-id-${id}`,
                  'list-view-item',
                )}
              >
                <Row
                  className={classNames(styles.head, styles[size], 'head')}
                  onClick={() => !itemButton && !loading && clickable && onClick({ id, disabled, clickable })}
                >
                  {iconType && (
                    <div className={classNames(styles.leftIcon, disabled && styles.leftIconDisabled, 'left-icon')}>
                      <span>
                        {!disabled && <Icon type={iconType} />}
                        {disabled && <Icon type="lock" />}
                      </span>
                    </div>
                  )}
                  {headImage && (
                    <div>
                      <img
                        width="100"
                        className={classNames(styles.headImage, !iconType && styles.noIcon)}
                        src={headImage}
                        alt={headImage}
                      />
                    </div>
                  )}
                  <div className={classNames(styles.center, 'center')}>
                    <div
                      className={classNames(
                        styles.descItem,
                        autoAdjustHeight && styles.autoAdjustHeight,
                        'description-item',
                        !iconType && styles.noIcon,
                      )}
                    >
                      {title && <div className={classNames(styles.title, 'title')}>{title}</div>}
                      {header && <div className={classNames(styles.header, 'header')}>{header}</div>}
                      {description && (
                        <div className={classNames(styles.desc, 'desc')}>
                          {descriptionIconType && <Icon type={descriptionIconType} />}
                          {description}
                        </div>
                      )}
                    </div>
                    {itemButton && !noLink && (
                      <MediaBreakpoint to={appBreakpoints.sm}>
                        <div className={styles.absoluteRight}>{detailLink({ disabled, id, itemButton })}</div>
                      </MediaBreakpoint>
                    )}
                    {/* 0 to sm */}
                    {(reference || itemButton) && enableMobileView && (
                      <MediaBreakpoint to={appBreakpoints.sm}>
                        <Row
                          className={classNames(
                            styles.mobileRow,
                            autoAdjustHeight && styles.autoAdjustHeightMobile,
                            'mobile-row',
                            !iconType && styles.noIcon,
                          )}
                        >
                          {renderRightSection({
                            id,
                            disabled,
                            expandable,
                            iconType,
                            header,
                            description,
                            descriptionIconType,
                            reference,
                            itemButton,
                            clickable,
                            noLink,
                          })}
                        </Row>
                      </MediaBreakpoint>
                    )}
                  </div>
                  {/* 0 to sm */}
                  {!itemButton && !reference && enableMobileView && (
                    <MediaBreakpoint to={appBreakpoints.sm}>
                      {renderRightSection({
                        id,
                        disabled,
                        expandable,
                        iconType,
                        header,
                        description,
                        descriptionIconType,
                        reference,
                        itemButton,
                        clickable,
                        noLink,
                      })}
                    </MediaBreakpoint>
                  )}
                  {/* xs  onwards */}
                  <MediaBreakpoint from={(enableMobileView && appBreakpoints.sm) || appBreakpoints.start}>
                    {renderRightSection({
                      id,
                      disabled,
                      expandable,
                      iconType,
                      header,
                      description,
                      descriptionIconType,
                      reference,
                      itemButton,
                      clickable,
                      noLink,
                    })}
                  </MediaBreakpoint>
                  {/* 0 to xs */}
                </Row>
                {expandable && expandedItemIds.includes(id) && (
                  <Row className={classNames(styles.content, 'expandable-content')}>
                    {typeof children === 'function'
                      ? children({ id, disabled, expandable, iconType, header, description, descriptionIconType })
                      : children}
                  </Row>
                )}
              </div>
            ) : null,
        )}
      </div>
      {needScroll && scrollSize && dataSource.length > maxElementsToShow * indexPosition && (
        <Row className={styles.viewMore} onClick={() => setIndexPosition(indexPosition + 1)}>
          <Icon type="down" />
          View More
        </Row>
      )}
      {needScroll && scrollSize && dataSource.length <= maxElementsToShow * indexPosition && dataSource.length > 0 && (
        <Row className={styles.viewMore} onClick={() => setIndexPosition(1)}>
          <Icon type="up" />
          View Less
        </Row>
      )}
    </>
  );
};

ListView.propTypes = {
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      disabled: PropTypes.bool,
      iconType: PropTypes.string,
      headImage: PropTypes.string,
      header: PropTypes.string,
      title: PropTypes.string,
      highlight: PropTypes.bool,
      expandable: PropTypes.bool,
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      clickable: PropTypes.bool,
      reference: PropTypes.shape({
        type: PropTypes.oneOf(Object.values(listViewReferenceTypes)),
        text: PropTypes.string,
        pill: PropTypes.bool,
      }),
      descriptionIconType: PropTypes.string,
      button: PropTypes.string,
      noLink: PropTypes.bool,
    }),
  ).isRequired,
  onItemClick: PropTypes.func,
  scrollSize: PropTypes.number,
  highlight: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.bool,
  enableMobileView: PropTypes.bool,
  expandedIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  autoAdjustHeight: PropTypes.bool,
};

ListView.defaultProps = {
  scrollSize: undefined, // no limit
  highlight: false,
  size: undefined,
  expandedIds: [],
  onItemClick: undefined,
  children: undefined,
  enableMobileView: true,
  className: '',
  loading: false,
  autoAdjustHeight: false,
};

export default ListView;
