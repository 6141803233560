export const ARCHIVED = 'archived';

export const docStatus = {
  REQUESTED: 'Requested',
  ACCEPTED: 'Accepted',
  UPLOADED: 'Uploaded',
};

export const conversionActivityStatus = {
  PAYMENT_RECEIVED: 'Payment Received',
  PATIAL_PAYMENT_RECEIVED: 'Partial Payment Received',
  AWAITING_PAYMENT: 'Awaiting Payment',
  RECIPIENT_NEEDED: 'Recipient Needed',
  CANCELLED: 'Cancelled',
};

export const paymentActivityStatus = {
  INFO_NEEDED: 'Info Needed',
  UNDERWAY: 'Underway',
  CANCELLED: 'Cancelled',
  COMPLETED: 'Completed',
  FAILED: 'failed',
};

export const beneStatus = {
  NEW: 'New',
  AWAITING_DOCS: 'Awaiting Docs',
  AWAITING_INFO: 'Awaiting Info',
  VERIFIED: 'Verified',
  BENEFICIARY_FAILED: 'Beneficiary Failed',
};
