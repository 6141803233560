import { types } from './types';

export const reducer = (state, action) => {
  switch (action.type) {
    case types.SET_STEP_INDEX: {
      const nextState = {
        ...state,
        [action.payload.id]: {
          ...(state[action.payload.id] || {}),
          currentIndex: action.payload.index,
        },
      };

      return { ...nextState };
    }

    case types.SET_STEP_DATA: {
      const { id, data } = action.payload;
      const oldData = state[id] || { data: { stepsData: {} } };

      const nextState = {
        ...state,
        [id]: {
          ...(oldData || {}),
          data: {
            ...(oldData.data || {}),
            ...data,
            stepsData: {
              ...((oldData.data && oldData.data.stepsData) || {}),
              ...(data.stepsData || {}),
            },
          },
        },
      };

      return nextState;
    }

    case types.CLEAR_STEPS: {
      const { id } = action.payload;
      return {
        ...state,
        [id]: undefined,
      };
    }

    case types.SET_STEP_RESULT: {
      const { id, index, result } = action.payload;
      const oldData = state[id];

      const nextState = {
        ...state,
        [id]: {
          ...(oldData || {}),
          data: {
            ...(oldData.data || {}),
            stepsData: {
              ...((oldData.data && oldData.data.stepsData) || {}),
              [index]: result,
            },
          },
        },
      };

      return nextState;
    }
    default:
      throw new Error('not implemented action');
  }
};
