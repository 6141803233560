import React from 'react';
import PropType from 'prop-types';
import { ImageUploads } from '@sendpayments/react-shared/components/ImageUploads';
import { documentSources } from '@sendpayments/js-utils/dist/const/defaults';
import styles from './ListItem.module.less';

const RequestDocument = ({ document, onIsListLoaded, onFilesSubmitted, onValidateFile }) => {
  const { docRequestType, docInstructions, docType } = document;
  const { value: documentLabel } = documentSources.find(({ key }) => key === docType) || {};

  const onChangeHandler = (list) => {
    onIsListLoaded(list.length > 0);
  };

  return (
    <div>
      <div className={styles.header}>{docRequestType}</div>
      <div className={styles.desc}>
        {documentLabel}
        {docInstructions}
      </div>
      <div className={styles.imagesUpload}>
        <ImageUploads onChange={onChangeHandler} onValidateFile={onValidateFile} onFilesSubmitted={onFilesSubmitted} />
      </div>
    </div>
  );
};

RequestDocument.propTypes = {
  document: PropType.object,
  onIsListLoaded: PropType.func.isRequired,
  onFilesSubmitted: PropType.func.isRequired,
  onValidateFile: PropType.func.isRequired,
};

RequestDocument.defaultProps = {
  document: undefined,
};

export default RequestDocument;
