import React from 'react';
import { Flex, Text, Divider, FlexProps } from '@chakra-ui/react';

export interface PaySummaryProps extends FlexProps {
  payLines: PayLine[];
}

export interface PayLine {
  description: string;
  amount: string;
  size?: 'sm' | 'lg';
}

export const PaySummary: React.FC<PaySummaryProps> = (props: PaySummaryProps) => {
  const { payLines, ...delegatedProps } = props;
  return (
    <Flex
      flexDirection="column"
      padding={8}
      borderRadius="8px"
      border="light"
      borderColor="border.base"
      backgroundColor="border.light"
      {...delegatedProps}
    >
      <Text as="span" mb={4} fontWeight="bold" fontSize="sm" color="secondary.base">
        Transfer summary
      </Text>
      <Divider />
      <Flex flexDirection="column">
        {payLines.map((payLine: PayLine) => (
          <Flex key={payLine.description} w="100%" justifyContent="space-between" mt={4}>
            <Text as="span" fontSize="md">
              {payLine.description}
            </Text>
            <Text as="span" fontWeight="bold" fontSize={payLine.size}>
              {payLine.amount}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
