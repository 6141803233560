import { get } from '@sendpayments/js-utils/dist';
import { useQuery, UseQueryOptions } from 'react-query';

export const queryKeysCurrencies = {
  currencies: ['currencies'],
};

export const useCurrencies = ({
  options,
}: {
  options?: UseQueryOptions<any>;
} = {}) => useQuery<Currency[]>(queryKeysCurrencies.currencies, () => getCurrencies(), { ...options });

const getCurrencies = (): Promise<Currency[]> => get('/dealing/currencies');

export type Currency = {
  code: string;
  decimal_places: number;
  name: string;
  online_trading: boolean;
  can_buy: boolean;
  can_sell: boolean;
};
