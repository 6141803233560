import * as React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SuccessCheckIcon = (props: IconProps): JSX.Element => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 87 87" {...props}>
      <path
        d="M71.7751 87H10.8751C7.27765 87 4.3501 84.0724 4.3501 80.475V19.575C4.3501 15.9775 7.27765 13.05 10.8751 13.05H15.2251C16.4257 13.05 17.4001 14.0244 17.4001 15.225C17.4001 16.4256 16.4257 17.4 15.2251 17.4H10.8751C9.6745 17.4 8.7001 18.3744 8.7001 19.575V80.475C8.7001 81.6756 9.6745 82.65 10.8751 82.65H71.7751C72.9757 82.65 73.9501 81.6756 73.9501 80.475V19.575C73.9501 18.3744 72.9757 17.4 71.7751 17.4H67.4251C66.2245 17.4 65.2501 16.4256 65.2501 15.225C65.2501 14.0244 66.2245 13.05 67.4251 13.05H71.7751C75.3725 13.05 78.3001 15.9775 78.3001 19.575V80.475C78.3001 84.0724 75.3725 87 71.7751 87Z"
        fill="#1B2221"
      />
      <path
        d="M58.7294 21.75C58.7294 21.75 58.7294 21.75 58.725 21.75H23.925C22.7244 21.75 21.75 20.7756 21.75 19.575C21.75 15.2032 23.7205 11.8059 27.3006 10.018C28.4969 9.41775 29.68 9.0915 30.624 8.91315C31.5505 3.84975 35.9962 0 41.3206 0C46.645 0 51.0907 3.84975 52.0173 8.91315C52.9656 9.0915 54.1445 9.41775 55.3407 10.018C58.8903 11.7928 60.8565 15.1424 60.8913 19.4576C60.8956 19.4967 60.8956 19.5359 60.8956 19.575C60.8956 20.7756 59.9212 21.75 58.7206 21.75H58.7294ZM26.361 17.4H56.289C55.8714 15.8514 54.9535 14.7161 53.5137 13.9635C51.8216 13.0805 50.0337 13.05 50.0119 13.05C48.8113 13.05 47.85 12.0756 47.85 10.875C47.85 7.27755 44.9224 4.35 41.325 4.35C37.7275 4.35 34.8 7.27755 34.8 10.875C34.8 12.0756 33.8256 13.05 32.625 13.05C32.6206 13.05 30.8284 13.0805 29.1363 13.9635C27.6964 14.7161 26.7786 15.847 26.361 17.4Z"
        fill="#1B2221"
      />
      <path
        d="M41.3249 13.05C40.7507 13.05 40.1896 12.8195 39.7894 12.4106C39.3892 12.0017 39.1499 11.4449 39.1499 10.875C39.1499 10.3052 39.3805 9.73966 39.7894 9.33946C40.1939 8.93491 40.7551 8.70001 41.3249 8.70001C41.8948 8.70001 42.4603 8.93056 42.8605 9.33946C43.265 9.74401 43.4999 10.3052 43.4999 10.875C43.4999 11.4449 43.2694 12.006 42.8605 12.4106C42.4559 12.8151 41.8948 13.05 41.3249 13.05Z"
        fill="#1B2221"
      />
      <path
        d="M34.8 60.9C34.2432 60.9 33.6864 60.6869 33.2601 60.2649L26.7351 53.7399C25.8868 52.8917 25.8868 51.5127 26.7351 50.6645C27.5833 49.8162 28.9623 49.8162 29.8105 50.6645L34.7956 55.6496L52.8307 37.6145C53.679 36.7662 55.0579 36.7662 55.9062 37.6145C56.7544 38.4627 56.7544 39.8417 55.9062 40.6899L36.3312 60.2649C35.9049 60.6912 35.3481 60.9 34.7913 60.9H34.8Z"
        fill="#1B2221"
      />
    </Icon>
  );
};
