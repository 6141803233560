// the statuses need to be review by George
export const underwayStatuses = [
  // conversion
  'send_compliance_pending',
  'send_approved',
  'awaiting_funds',
  'funds_sent',
  'funds_arrived',
  // payment
  'send_compliance_pending',
  'send_approved',
  'new',
  'ready_to_send',
  'released',
  'awaiting_authorisation',
  'submitted',
  'authorised',
];

export const completedStatuses = [
  // conversion
  'trade_settled',
  // payment
  'completed',
];

export const failedStatuses = ['closed', 'failed', 'suspended'];
export const fundsReceivedStatuses = {
  funds_sent: 'funds_sent',
  funds_arrived: 'funds_arrived',
};

const asMap = (statuses) =>
  statuses.reduce((acc, item) => {
    acc[item.toUpperCase()] = item;
    return acc;
  }, {});

export const statusesByCoconutType = {
  CONVERSION: {
    UNDERWAY: asMap(['send_compliance_pending', 'send_approved', 'awaiting_funds', 'funds_sent', 'funds_arrived']),
    COMPLETED: asMap(['trade_settled']),
    FAILED: asMap(failedStatuses),
  },
  PAYMENT: {
    UNDERWAY: asMap([
      'send_compliance_pending',
      'send_approved',
      'new',
      'ready_to_send',
      'released',
      'awaiting_authorisation',
      'submitted',
      'authorised',
    ]),
    COMPLETED: asMap(['completed']),
    FAILED: asMap(failedStatuses),
  },
};

export const statuses = {
  UNDERWAY: asMap(underwayStatuses),
  COMPLETED: asMap(completedStatuses),
  FAILED: asMap(failedStatuses),
};
