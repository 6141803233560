import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
import { withPortalSource } from '@send-services/white-label';
import { useRegistrationByType, useCurrentEmail, useMarketingSettings, useSettings, useDocumentsByOwner } from '@send-data-hooks';
import { saveRegistrationDocument } from '@send-services/registration-document';
// js-utils
import { getUtmSource } from '@sendpayments/js-utils/dist/services/analytics/legacy';
import { objBlankFieldsToNull } from '@sendpayments/js-utils/dist/utils/common';
import { logger } from '@sendpayments/js-utils/dist/services/logger';
import { saveEntity } from '@sendpayments/js-utils/dist/services/entities';
// Layout
import { Box, Flex, Spinner } from '@chakra-ui/react';
import { StepTemplate } from '../../templates';
import {
  PersonalInformationForm,
  IdentityForm,
  FirstTransferForm,
  PersonalInformationData,
  IdentityData,
  FirstTransferData,
  StepData,
} from '../../organisms';
import { CongratulationsPage } from '../../pages/CongratulationsPage';
import { useToast } from '@sendpayments/react-shared/components/molecules/Toast';

import { SupportCard } from '@send-legacy-containers/SupportCard';

import { AppContext } from '@send-legacy-app/Context';
import * as queries from '@sendpayments/graphql/queries';
import { query } from '@send-base/utilities/endpoint';
import { Auth } from 'aws-amplify';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { getDateFormats } from '@send-base/utilities';

dayjs.extend(utc);
dayjs.extend(timezone);

import { useFeature } from '@sendpayments/react-shared/hooks/useFeature';
import { PD_1565_CARD_NUMBER } from '@sendpayments/shared-constants/features';

const steps: StepData[] = [
  {
    heading: 'Your personal details',
    step: 'You',
    subHeading:
      'Please provide your personal details as shown on your identification documents. ' +
      'Any details that do not match your identification may delay account verification.',
  },
  {
    heading: 'Verify your identity',
    step: 'Identity',
    subHeading: 'You’ll need to match your details against one identity type.',
  },
  {
    heading: 'Tailoring your experience',
    step: 'Experience',
    subHeading: 'Please provide us with the following regulatory information.',
  },
];

interface FormMetaData {
  id: string;
  type: 'Retail' | 'Corporate';
  loginSource?: string;
  email: string;
  updatedBy: string;
  createdBy: string;
  owner: string;
  status: string;
  affiliateId?: string;
  smsActivityNotificationsEnabled?: boolean;
  smsTransferStatusNotificationsEnabled?: boolean;
  emailMarketingNotificationsEnabled?: boolean;
  emailTransferStatusNotificationsEnabled?: boolean;
  mfaReminder?: boolean;
}

export const RetailPage: React.FC = () => {
  const { user }: { user: any } = useContext(AppContext);
  // Check if user is logged in via Social Login
  const identities = user?.attributes?.identities ? JSON.parse(user.attributes.identities)[0] : {};
  const [stepForm, goToStepForm] = useState(0);
  const [loadingNext, setLoadingNext] = useState(false);
  const history = useHistory();
  const toast = useToast();

  // Data retrived from endpoints
  const [{ data: email }] = useCurrentEmail();
  // @ts-ignore need to ignore type
  const [{ data, loading: loadingRegistration }, setData] = useRegistrationByType(CoconutTypes.RETAIL);
  const [{ data: documents }] = useDocumentsByOwner({ owner: email });

  const [{ data: settings }] = useSettings();
  const { countries, transferAmounts, transferPurposes } = settings;

  // To pull current registration stuff.
  // Marketing feature
  const currentUtmSource = getUtmSource();
  const [{ data: marketingMappings }] = useMarketingSettings({ utmSource: currentUtmSource });

  const getFormMetaData = () => {
    return {
      id: data.id,
      loginSource: identities?.providerType?.toLowerCase(),
      type: data.type,
      email,
      updatedBy: email,
      createdBy: email,
      owner: email,
      status: stepForm < 2 ? 'Incomplete' : 'New',
    };
  };

  // Can be removed in the future
  const [
    {
      data: { isEnabled: isCardNumberEnabled },
    },
  ] = useFeature({
    feature: PD_1565_CARD_NUMBER,
    functionName: 'CardNumber',
  });

  const handleCreate = async (values: PersonalInformationData | IdentityData | FirstTransferData) => {
    // Check if person referred from affiliate
    const affiliateId = localStorage.getItem('affiliateId');
    localStorage.removeItem('affiliateId');
    logger.log('RetailPage', '(optional) affiliateId cookie 🍪', affiliateId);

    try {
      setLoadingNext(true);
      // Preparing form data
      // Meta data on the form for customer support to help live registration
      const meta = getFormMetaData();

      // @ts-ignore need to ignore type
      let retail: FormMetaData & Partial<PersonalInformationData & IdentityData & FirstTransferData> = {
        ...meta,
        ...(affiliateId && { affiliateId }),
        ...values,
        ...(stepForm === 2
          ? {
              smsActivityNotificationsEnabled: false,
              smsTransferStatusNotificationsEnabled: false,
              emailMarketingNotificationsEnabled: true,
              emailTransferStatusNotificationsEnabled: true,
              mfaReminderEnabled: true,
            }
          : {}),
      };
      // @ts-ignore
      retail = objBlankFieldsToNull(retail);

      if (stepForm === 0 && retail.birthDate) {
        const { string: birthDateString, unix: birthDateUnix } = getDateFormats(retail.birthDate);
        retail.birthDate = birthDateUnix;
        retail.birthDateString = birthDateString;
      }

      if (stepForm === 1 && retail.docExpiryDateString) {
        const { string: docExpiryString, unix: docExpiryUnix } = getDateFormats(retail.docExpiryDateString);
        retail.docExpiryDate = docExpiryUnix;
        retail.docExpiryDateString = docExpiryString;
      }

      const registration = {
        ...data,
        ...retail,
      };
      if (stepForm === 1) {
        await saveRegistrationDocument({
          registration,
          documents,
          isCardNumberEnabled,
        });
      }

      // // Marketing feature flag need to see if this is still needed or if we can not use the flag
      if (!registration.affiliateId && !registration.sendId) {
        logger.log('Retail', 'handleCreate currentUtmSource', currentUtmSource);

        if (marketingMappings) {
          const mappedAffiliateId = marketingMappings.mappedAffiliateId;
          logger.log('Retail', 'handleCreate mappedAffiliateId', mappedAffiliateId);

          if (mappedAffiliateId) {
            retail.affiliateId = mappedAffiliateId;
            logger.log('Retail', 'handleCreate setting affiliateId to ', mappedAffiliateId);
          }
        }
      }

      const cognitoUser = await Auth.currentAuthenticatedUser();

      // In case the user failed useRegistrationByType but an entity is already created in DynamoDB
      // then we replace all the data from the form above but replace the id from null to the existing ID in DynamoDB
      const { items: possibleExistingReg } = await query(queries.listCoconutsByOwner, {
        params: {
          owner: cognitoUser?.attributes?.email,
          type: { eq: 'Retail' },
        },
        projection: {},
      });

      if (possibleExistingReg && possibleExistingReg.length > 0) {
        retail.id = possibleExistingReg[0].id;
      }

      // Saving the state
      await saveEntity({
        entity: withPortalSource(retail),
        type: CoconutTypes.RETAIL,
        owner: retail.owner,
      });

      // @ts-ignore this experssion IS callable.
      setData({ registration, type: CoconutTypes.RETAIL });

      // Move to next step (might be able to save this in the state)
      if (stepForm < 3) {
        goToStepForm((prev) => prev + 1);
      }
    } catch (error) {
      logger.error('handleCreate', 'error', error);
      toast({
        variant: 'negative',
        title: 'Something went wrong on our end. Please try again!',
      });
    } finally {
      setLoadingNext(false);
    }
  };

  const handleBack = () => {
    goToStepForm((prev) => prev - 1);
  };

  const onCancel = () => {
    // Maybe save here
    history.push('/dashboard');
  };

  return (data?.status && data?.status !== 'Incomplete') || stepForm > 2 ? (
    <CongratulationsPage name={data?.firstName} />
  ) : (
    <>
      {loadingRegistration && <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="gray.900" size="xl" />}
      <StepTemplate progress={{ current: stepForm, steps }} onNavigateBack={handleBack} onCancel={onCancel}>
        <Flex align="center" justify="center" display="flex" flexDirection="column">
          <Box w="100%" maxWidth={{ base: '100%', md: '416px' }}>
            {stepForm === 0 && (
              <PersonalInformationForm
                onCancel={onCancel}
                onSubmit={async (values) => {
                  await handleCreate(values);
                }}
                isLoading={loadingNext}
                showDetailedAddressSection={!!data.streetAddressName}
                initialValues={{
                  firstName: data.firstName ? data.firstName : user?.attributes?.given_name,
                  middleName: data.middleName ?? '',
                  lastName: data.lastName ? data.lastName : user?.attributes?.family_name,
                  birthDate: data.birthDate ?? '',
                  countryOfBirth: data.countryOfBirth ?? '',
                  phoneCountryCode: data.phoneCountryCode ? data.phoneCountryCode : user?.attributes?.countryCode,
                  phone: data.phone ? data.phone : user?.attributes?.number,
                  streetAddressUnitNumber: data.streetAddressUnitNumber ?? '',
                  streetAddressName: data.streetAddressName ?? '',
                  streetAddressNumber: data.streetAddressNumber ?? '',
                  streetAddressType: data.streetAddressType ?? '',
                  city: data.city ?? '',
                  state: data.state ?? '',
                  postcode: data.postcode ?? '',
                  country: data.country ?? '',
                }}
              />
            )}
            {stepForm === 1 && (
              <IdentityForm
                onSubmit={async (values) => {
                  await handleCreate(values);
                }}
                isLoading={loadingNext}
                initialValues={{
                  docType: data.docType ?? '',
                  docId: data.docId ?? '',
                  docIssuingCountry: data.docIssuingCountry ?? '',
                  docExpiryDateString: data.docExpiryDateString ?? '',
                  termsAccepted: data.termsAccepted ?? false,
                  ...(data?.docRegion && { docRegion: data?.docRegion }),
                  ...(data?.docLicenceVersion && { docLicenceVersion: data?.docLicenceVersion }),
                  ...(isCardNumberEnabled && { docCardNumber: data.docCardNumber ?? '' }),
                }}
              />
            )}
            {stepForm === 2 && (
              <FirstTransferForm
                onSubmit={async (values) => {
                  await handleCreate(values);
                }}
                isLoading={loadingNext}
                initialValues={{
                  otherPurpose: data.otherPurpose ?? '',
                  estimatedValue: data.estimatedValue ?? '',
                  currencyFrom: data.currencyFrom ?? '',
                  currencyTo: data.currencyTo ?? '',
                  countryFrom: data.countryFrom ?? '',
                  countryTo: data.countryTo ?? '',
                  purpose: data.purpose ?? '',
                  frequency: data.frequency ?? '',
                  fundsFromCustomer: data.fundsFromCustomer ?? '',
                  fundsGoingtoDiffPerson: data.fundsGoingtoDiffPerson ?? '',
                }}
                transferPurposes={transferPurposes}
                countries={countries}
                transferAmounts={transferAmounts?.filter(
                  (option) => !['<$25k', '$25k to $100k', '$100k to $500k', '$500k to $1m', '>$1m'].includes(option),
                )}
              />
            )}
          </Box>
        </Flex>
      </StepTemplate>
      <SupportCard />
    </>
  );
};
