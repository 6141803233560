import { post } from '@send-base/utilities/endpoint';
import { useQuery, UseQueryOptions } from 'react-query';

const MAX_TIMEOUT_QUOTE_SECONDS = 120; // 2 minutes (down from 30)

const queryKeysRate = {
  rate: (fromCurrency: string, toCurrency: string) => ['rate', `${fromCurrency}/${toCurrency}`],
};

export const useRate = ({ params, options }: { params: RateRequest; options?: UseQueryOptions<RateResponse> }) =>
  // Very important that you use a unique query key for each request. If you change params, add key, or it will be cached
  useQuery<RateResponse>(queryKeysRate.rate(params.fromCurrency, params.toCurrency), () => getRate(params), {
    ...options,
  });

const getRate = (req: RateRequest): Promise<RateResponse> =>
  post('/quote/exchange-rate', {
    data: { ...req, ttl: MAX_TIMEOUT_QUOTE_SECONDS },
  });

type RateRequest = {
  amount: number;
  fromCurrency: string;
  toCurrency: string;
};

type RateResponse = {
  conversionDate: string;
  quoteId: string;
  fromCurrency: string;
  toCurrency: string;
  customerEmail: string;
  invertedRate: number;
  rate: number;
};
