import * as React from 'react';
import { Stack, Text, Link, Box, Button, Checkbox, FormErrorMessage, FormLabel, FormControl, Tooltip } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { InputField, Select, ValueContainer, DateInput } from '@sendpayments/react-shared/components/molecules';
import { useToast } from '@sendpayments/react-shared/components/molecules/Toast';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { SendForm } from '../types';
import { logger } from '@sendpayments/js-utils/dist/services/logger';
import { analytics } from '@sendpayments/js-utils/dist';
import { australiaStates, countries, documentSources } from '@sendpayments/js-utils/dist/const/defaults';
import { findRegistrationDocuments } from '../../../../selectors/registration-documents';
import { appConfig } from '@send-base/app.config';
import { ChevronIcon } from '@sendpayments/react-shared/components/Icons';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useFeature } from '@sendpayments/react-shared/hooks/useFeature'; // can be removed in the future
import { PD_1565_CARD_NUMBER } from '@sendpayments/shared-constants/features'; // can be removed in the future
dayjs.extend(customParseFormat);

const documentTypes = findRegistrationDocuments(documentSources).map(({ key, value }) => ({
  value: key,
  label: value,
}));

const countryOptions = countries.map((value) => ({
  value: value,
  label: value,
}));

const australiaStateOptions = australiaStates.map((value) => ({
  value: value,
  label: value,
}));

export type IdentityData = Omit<yup.InferType<typeof identitySchema>, 'docRegion' | 'docLicenceVersion'> & {
  docRegion?: string;
  docLicenceVersion?: string;
  businessRepConfirmed?: boolean;
  docExpiryDate?: string | number;
  docCardNumber: string;
};

const identitySchema = yup.object({
  docType: yup.string().trim().required('Please select your identity type'),
  docId: yup.string().trim().required('Please enter your identity number'),
  docIssuingCountry: yup.string().required('Please select your issuing country'),
  docExpiryDateString: yup
    .string()
    .nullable()
    .required('Please enter your expiry date')
    .test('test-date', 'Document is expired', (value) => !value || dayjs(value, 'YYYY-MM-DD') > dayjs()),
  docRegion: yup.string().when(['docIssuingCountry', 'docType'], {
    is: (docIssuingCountry: string, docType: string) => docIssuingCountry === 'Australia' && docType === 'DRIVERS_LICENCE',
    then: yup.string().required('Please select your issuing state'),
  }),
  docLicenceVersion: yup.string().when(['docIssuingCountry', 'docType'], {
    is: (docIssuingCountry: string, docType: string) => docIssuingCountry === 'New Zealand' && docType === 'DRIVERS_LICENCE',
    then: yup.string().trim().required('Please enter your licence version'),
  }),
  termsAccepted: yup
    .boolean()
    .required('Please accept the terms and conditions')
    .oneOf([true], 'Please accept the terms and conditions'),
});
export interface IdentityFormProps extends SendForm<IdentityData> {
  isRepresentative?: boolean;
}

const defaultFormValues = {
  docType: '',
  docId: '',
  docIssuingCountry: '',
  docExpiryDateString: '',
  termsAccepted: false,
  docCardNumber: '',
};

export const IdentityForm: React.FC<IdentityFormProps> = ({ initialValues, onSubmit, isLoading, isRepresentative }) => {
  const toast = useToast();

  // Can be removed in the future
  const [
    {
      data: { isEnabled: isCardNumberEnabled },
    },
  ] = useFeature({
    feature: PD_1565_CARD_NUMBER,
    functionName: 'CardNumber',
  });

  return (
    <Box>
      <Formik
        initialValues={{ ...defaultFormValues, ...initialValues }}
        validateOnMount={false}
        validationSchema={() => {
          let newIdentitySchema = identitySchema;
          if (isRepresentative) {
            newIdentitySchema = newIdentitySchema.shape({
              businessRepConfirmed: yup
                .boolean()
                .required('Please agree you have permission to authorise')
                .oneOf([true], 'Please agree you have permission to authorise'),
            });
          }

          if (isCardNumberEnabled) {
            newIdentitySchema = newIdentitySchema.shape({
              docCardNumber: yup.string().when(['docIssuingCountry', 'docType'], {
                is: (docIssuingCountry: string, docType: string) =>
                  docIssuingCountry === 'Australia' && docType === 'DRIVERS_LICENCE',
                then: yup
                  .string()
                  .trim()
                  .required('Please enter your card number')
                  .test('test-card-number', 'Max 10 alphanumeric characters for card number', (value) => {
                    if (value) {
                      return new RegExp(/^[a-zA-Z0-9]{1,10}$/).test(value);
                    }
                    return false;
                  }),
                otherwise: yup.string().test('test-card-number', 'Max 10 alphanumeric characters for card number', (value) => {
                  if (value) {
                    return new RegExp(/^[a-zA-Z0-9]{1,10}$/).test(value);
                  }
                  return true;
                }),
              }),
              docRegion: yup.string().when(['docType', 'docIssuingCountry'], {
                is: (docType: string, docIssuingCountry: string) =>
                  docType === 'DRIVERS_LICENCE' && docIssuingCountry === 'Australia',
                then: yup.string().trim().required('Please select your issuing state'),
              }),
            });
          }

          return newIdentitySchema.shape({
            docExpiryDateString: yup
              .string()
              .nullable()
              .required('Please enter your expiry date')
              .test(
                'test-date-format',
                'Expiry Date is invalid.',
                (value) => !value || dayjs(value, 'YYYY-MM-DD', true).isValid(),
              )
              .test('test-expiry-date', 'The document has expired.', (value) => !value || dayjs() < dayjs(value)),
          });
        }}
        onSubmit={async (values, actions) => {
          try {
            // If block can be removed in the future
            if (!isCardNumberEnabled) {
              const { docCardNumber, ...rest } = values;
              // @ts-ignore - this is a hack to remove the card number from the values
              // cause this is a feature flag and will be removed in the future
              await onSubmit(rest);
            } else {
              await onSubmit(values);
            }
            window.scrollTo({ top: 0, behavior: 'smooth' });
            analytics.push({ action: { type: 'form_submit', data: { name: 'IdentityForm' } } });
          } catch (error) {
            logger.error('IdentityForm', 'ERROR: ', error);
            actions.setSubmitting(false);
            toast({
              variant: 'negative',
              title: 'Something went wrong on our end. Please try again!',
            });
          }
        }}
      >
        {(props) => (
          <Form noValidate>
            <Stack spacing={4} textAlign="left">
              {/* Document Type */}

              <Field name="docType">
                {({ field, form }) => (
                  <FormControl id={field.name} isInvalid={form.errors[field.name] && form.touched[field.name]}>
                    <FormLabel mb={1}>Identity Type</FormLabel>
                    <Select
                      placeholder={'Select your identity type...'}
                      value={documentTypes.find((item: { value: string }) => item.value === field.value)}
                      options={documentTypes}
                      onChange={(option) => field.onChange(field.name)(option?.value)}
                      width="100%"
                      isInvalid={form.errors[field.name] && form.touched[field.name]}
                      components={{ ValueContainer }}
                    />
                    <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              {/* Issuing Country */}
              <Field name="docIssuingCountry">
                {({ field, form }) => (
                  <FormControl id={field.name} isInvalid={form.errors[field.name] && form.touched[field.name]}>
                    <FormLabel mb={1}>Issuing Country</FormLabel>
                    <Select
                      placeholder={'Select your issuing country...'}
                      value={countryOptions.find((item: { value: string }) => item.value === field.value)}
                      options={countryOptions}
                      onChange={(option) => {
                        field.onChange(field.name)(option?.value);
                        form.setFieldValue('docRegion', undefined);
                        form.setFieldValue('docLicenceVersion', undefined);
                      }}
                      width="100%"
                      isInvalid={form.errors[field.name] && form.touched[field.name]}
                      components={{ ValueContainer }}
                    />
                    <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              {/* Identity number */}
              <Field name="docId">
                {({ field, form }) => (
                  <InputField
                    id={field.name}
                    label="Identity number"
                    placeholder="Enter your identity number"
                    isInvalid={!!form.errors[field.name] && form.touched[field.name]}
                    isValid={!form.errors[field.name] && !!field.value}
                    errorText={form.errors[field.name]}
                    inputProps={{
                      ...field,
                      type: 'text',
                      size: 'md',
                    }}
                  />
                )}
              </Field>

              {/* Issuing State */}
              {props.values.docType === 'DRIVERS_LICENCE' && props.values.docIssuingCountry === 'Australia' && (
                <Field name="docRegion">
                  {({ field, form }) => (
                    <FormControl id={field.name} isInvalid={form.errors[field.name]}>
                      <FormLabel mb={1}>Issuing State</FormLabel>
                      <Select
                        placeholder={'Select your issuing state...'}
                        value={australiaStateOptions.find((item: { value: string }) => item.value === field.value)}
                        options={australiaStateOptions}
                        onChange={(option) => field.onChange(field.name)(option?.value)}
                        width="100%"
                        isInvalid={form.errors[field.name] && !form.value}
                        components={{ ValueContainer }}
                      />
                      <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              )}
              {props.values.docType === 'DRIVERS_LICENCE' && props.values.docIssuingCountry === 'New Zealand' && (
                <Field name="docLicenceVersion">
                  {({ field, form }) => (
                    <InputField
                      id={field.name}
                      label="Licence Version"
                      placeholder="Enter your licence version number"
                      isInvalid={!!form.errors[field.name] && form.touched[field.name]}
                      isValid={!form.errors[field.name] && !!field.value}
                      errorText={form.errors[field.name]}
                      inputProps={{
                        ...field,
                        type: 'text',
                        size: 'md',
                      }}
                    />
                  )}
                </Field>
              )}

              {/* Doc Expiry Date */}
              <Field name="docExpiryDateString">
                {({ field, form }) => (
                  <Box w={{ base: '100%', lg: '80%' }} maxWidth="200px">
                    <DateInput
                      id={field.name}
                      label="Expiry Date"
                      onChange={(value) => props.setFieldValue(field.name, value)}
                      isInvalid={!!form.errors[field.name] && form.touched[field.name]}
                      error={form.errors[field.name]}
                      value={field.value && dayjs(field.value, 'YYYY-MM-DD').isValid() ? field.value : undefined}
                    />
                  </Box>
                )}
              </Field>

              {/* Driver licence card number */}
              {isCardNumberEnabled &&
                props.values.docType === 'DRIVERS_LICENCE' &&
                props.values.docIssuingCountry === 'Australia' && (
                  <Field name="docCardNumber">
                    {({ field, form }) => {
                      return (
                        <Box position="relative">
                          <InputField
                            id={field.name}
                            label="Card Number"
                            placeholder="Enter your card number"
                            isInvalid={!!form.errors[field.name] && form.touched[field.name]}
                            isValid={!form.errors[field.name] && !!field.value}
                            errorText={form.errors[field.name]}
                            inputProps={{
                              ...field,
                              type: 'text',
                              size: 'md',
                            }}
                          />
                          <Tooltip label="Please note: License number & Card number are different. You can locate your card number on the front or back of your driver’s licence. For VIC issued licences, the card number is in small print on the back, just above the reverse printed licence expiry">
                            <InfoOutlineIcon position="absolute" left="5.5rem" top="0.1rem" color="primary.base" />
                          </Tooltip>
                        </Box>
                      );
                    }}
                  </Field>
                )}
              {/* Representative confirmation */}
              {isRepresentative && (
                <Field name="businessRepConfirmed">
                  {({ field, form }) => (
                    <FormControl id={field.name} isInvalid={form.errors[field.name] && form.touched[field.name]}>
                      <Box
                        border="light"
                        borderColor={form.errors[field.name] && form.touched[field.name] ? 'error' : 'border.base'}
                        borderRadius={8}
                        p={4}
                        w="100%"
                        h="100%"
                      >
                        <Checkbox
                          alignItems="top"
                          size="md"
                          isInvalid={form.errors[field.name] && form.touched[field.name]}
                          isChecked={field.value}
                          onChange={(e) => form.setFieldValue(field.name, e.target.checked)}
                        >
                          <Text as="span" fontSize="sm" pr={1}>
                            I confirm I am an authorised representative of the business and have authorisation to make payments
                            and provide instructions on the business’s behalf.
                          </Text>
                        </Checkbox>
                      </Box>
                      <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              )}
              {/* Terms checkbox */}
              <Field name="termsAccepted">
                {({ field, form }) => (
                  <FormControl id={field.name} isInvalid={form.errors[field.name] && form.touched[field.name]}>
                    <Box
                      border="light"
                      borderColor={form.errors[field.name] && form.touched[field.name] ? 'error' : 'border.base'}
                      borderRadius={8}
                      p={4}
                      w="100%"
                      h="100%"
                    >
                      <Checkbox
                        alignItems="top"
                        size="md"
                        isInvalid={form.errors[field.name] && form.touched[field.name]}
                        isChecked={field.value}
                        onChange={(e) => form.setFieldValue(field.name, e.target.checked)}
                      >
                        <Text as="span" fontSize="sm" pr={1}>
                          I have read and understood, and agree to be bound by, the
                        </Text>
                        <Link as="a" fontSize="sm" color="brandPink" pr={1} href={appConfig.sendTermsAndConditionsUrl} isExternal>
                          Terms and Conditions,
                        </Link>
                        <Link as="a" fontSize="sm" color="brandPink" pr={1} href={appConfig.sendPrivacyAndPolicyUrl} isExternal>
                          Privacy Policy,
                        </Link>
                        <Link
                          as="a"
                          fontSize="sm"
                          color="brandPink"
                          href={appConfig.sendProductDisclosureStatementUrl}
                          isExternal
                        >
                          Product Disclosure Statement
                        </Link>
                        <Text as="span" fontSize="sm" pr={1}>
                          {' and'}
                        </Text>
                        <Link
                          as="a"
                          fontSize="sm"
                          color="brandPink"
                          pr={1}
                          href={appConfig.sendFinancialServicesGuideUrl}
                          isExternal
                        >
                          Financial Services Guide.
                        </Link>
                      </Checkbox>
                    </Box>
                    <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Button
                isLoading={props.isSubmitting || isLoading}
                type="submit"
                alignSelf="center"
                fontSize="md"
                rightIcon={<ChevronIcon h="8px" w="6px" direction="forward" />}
              >
                Next
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
