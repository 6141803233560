import { Setting } from '@send-base/infrastructure/query/useSetting';
import { Currency } from '@send-base/infrastructure/query/useCurrencies';
import { withDecimals } from '@sendpayments/js-utils/dist/utils/numbers';
import { exchangeWidgetConfig } from './config';
import { Registration } from '@send-base/types';
import { appConfig } from '@send-base/app.config';

const { australianPhoneNumber, internationalPhoneNumber } = appConfig;

export const extractDecimalPlaces = (currencyCode: string, currencies?: Currency[]) => {
  const defaultDecimalPlaces = 2;
  const result = currencies?.find(({ code }) => code === currencyCode);
  return result?.decimal_places ?? defaultDecimalPlaces;
};

export const getFromAmount = ({ toAmount, invertedRate, fromDecimalPlaces }) => {
  return withDecimals(toAmount * invertedRate || 0, fromDecimalPlaces);
};

export const getToAmount = ({ fromAmount, rate, toDecimalPlaces }) => {
  return withDecimals(fromAmount * rate || 0, toDecimalPlaces);
};

export const isBlacklistedCurrency = (currencyCode: string, setting: Setting) => {
  return Boolean(setting?.blacklistedCurrencies.find((blacklisted) => blacklisted.toUpperCase() === currencyCode.toUpperCase()));
};

export const getBankComparisonRate = (amount: number, setting: Setting, audRate = 0) => {
  return (amount * setting.pricingPlanBankRateComparison) / 100 + setting.pricingPlanBankSendingFee * audRate;
};

export const toCurrencyOptions = ({
  allCurrencies = [],
  popularCurrencies = [],
}: {
  allCurrencies?: Currency[];
  popularCurrencies?: string[];
}) => {
  const transformedCurrencies = allCurrencies.map((it) => ({
    currencyCode: it.code,
    currencyName: it.name,
  }));
  return [
    {
      groupName: 'Popular Currencies',
      options: transformedCurrencies.filter((it) => popularCurrencies.includes(it.currencyCode)),
    },
    { groupName: 'All Currencies', options: transformedCurrencies },
  ];
};

export const calculateMaxAmount = (registration: Registration, setting: Setting) => {
  const complianceScoreLimit = 15;
  return registration &&
    registration.complianceScore &&
    parseInt(registration.complianceScore as string, 10) < complianceScoreLimit
    ? setting.customerDailyTradeLimit
    : setting.highRiskCustomerDailyTradeLimit;
};

export const getNextCurrencyCode = ({ fromCurrency, toCurrency }) => {
  if (toCurrency === fromCurrency && fromCurrency !== exchangeWidgetConfig.defaultFirstCurrency) {
    return exchangeWidgetConfig.defaultFirstCurrency;
  }
  if (toCurrency === fromCurrency && fromCurrency !== exchangeWidgetConfig.defaultSecondCurrency) {
    return exchangeWidgetConfig.defaultSecondCurrency;
  }
  return fromCurrency;
};

export const getSupportInfo = ({ country }: Registration) => {
  const phoneNumber = country !== 'Australia' ? internationalPhoneNumber : australianPhoneNumber;
  return {
    phoneNumber: phoneNumber,
  };
};
