import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Widget as ExchangeCurrencyWidget } from '@send-legacy-components/ExchangeCurrency';
import { useSettings, useRate, useActiveCurrencies, useRegistration, useOutOfHours } from '@send-data-hooks';
import { usePersistedState } from '@sendpayments/react-shared/hooks';
import { appConfig } from '@send-base/app.config';
import { findAllowedDecimalPlaces } from '@sendpayments/js-utils/dist/utils/currency';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
import { saveEntity } from '@sendpayments/js-utils/dist/services/entities';
import { getFromAmount, getToAmount, getFromCurrency, getToCurrency } from './helpers';
import useBankComparisonAmount from './useBankComparisonAmount';
import { analytics } from '@sendpayments/js-utils/dist';
import { CurrencyPairNotSupportedError } from '@send-base/exception';

const { popularCurrencies, defaultWidgetCurrency } = appConfig;

const currenciesFields = {
  FROM_FIELD: 'fromAmount',
  TO_FIELD: 'toAmount',
};

const ExchangeCurrency = ({
  initialFromCurrency,
  initialToCurrency,
  initialFromAmount,
  initialFixedAmount,
  collapsedWidget,
  showBookButton,
  onBookClick,
  className,
  dropDownClassName,
}) => {
  const [state, setExchangeCurrencyState] = usePersistedState('ExchangeCurrency', {
    fromCurrency: initialFromCurrency,
    toCurrency: initialToCurrency,
    fromAmount: initialFromAmount,
    fixedAmount: initialFixedAmount,
  });
  const { fromCurrency, toCurrency, fromAmount, fixedAmount } = state;
  const [{ data: reg }] = useRegistration();
  const [collapsedState, setCollapseToggle] = useState(collapsedWidget);
  const [requestCallbackAccepted, setRequestCallbackAccepted] = useState(false);
  const [loadingRequestCallback, setLoadingRequestCallback] = useState(false);
  const [requestedId, setLastRequestedId] = useState();

  const [
    {
      ex,
      data: { rate, invertedRate, quoteId, conversionDate },
      loading: loadingRate,
      failed,
    },
  ] = useRate({
    fromCurrency,
    toCurrency,
  });

  const [
    {
      data: { rate: audRate },
      failed: failedAud,
      loading: loadingAud,
    },
  ] = useRate({
    fromCurrency,
    toCurrency: defaultWidgetCurrency,
  });

  const [{ data: settings }] = useSettings();
  const [{ data: currenciesConfig, loading: loadingCurrencies }] = useActiveCurrencies(settings);

  const currencies = useMemo(() => currenciesConfig.map(({ currencyName }) => currencyName), [currenciesConfig]);
  const fromDecimalPlaces = findAllowedDecimalPlaces(fromCurrency, { currenciesConfig });
  const toDecimalPlaces = findAllowedDecimalPlaces(toCurrency, { currenciesConfig });

  const calculatedFromAmount = getFromAmount(fixedAmount, { ...state, invertedRate, fromDecimalPlaces });
  const toAmount = getToAmount(fixedAmount, { ...state, rate, toDecimalPlaces });

  const [bankComparisonAmount] = useBankComparisonAmount(
    { amount: calculatedFromAmount },
    {
      ...settings,
      audRate,
    },
  );

  const calculatedToAmount = getToAmount(fixedAmount, { ...state, rate, toDecimalPlaces });
  const calculatedAudToAmount = getToAmount(currenciesFields.FROM_FIELD, {
    fromAmount: calculatedFromAmount,
    rate: audRate,
    toDecimalPlaces,
  });

  const calculatedAmountMap = {
    fromAmount: calculatedFromAmount,
    toAmount: calculatedToAmount,
    audAmount: calculatedAudToAmount,
  };

  const onFieldChangeHandler = (fieldName, value) => {
    // reset state
    if (requestCallbackAccepted) {
      setRequestCallbackAccepted(false);
    }

    const nextState = {};

    if (calculatedAmountMap[fieldName] !== value) {
      const fixedAmountCalculation = fieldName === 'fromAmount' || fieldName === 'toAmount' ? fieldName : fixedAmount;

      nextState.fromCurrency = fromCurrency || defaultWidgetCurrency;
      nextState.toCurrency = toCurrency;
      nextState.fromAmount = fromAmount;
      nextState.toAmount = toAmount;
      nextState.fixedAmount = fixedAmountCalculation;
      nextState[fieldName] = value;

      if (nextState.fromCurrency === nextState.toCurrency) {
        if (fieldName === 'fromCurrency') {
          nextState.toCurrency = getToCurrency(nextState.toCurrency, {
            fromCurrency: nextState.fromCurrency,
          });
        }

        if (fieldName === 'toCurrency') {
          nextState.fromCurrency = getFromCurrency(nextState.fromCurrency, {
            toCurrency: nextState.toCurrency,
          });
        }
      }

      setExchangeCurrencyState(nextState);
    }
  };

  const maxAmount =
    reg && reg.complianceScore && parseInt(reg.complianceScore, 10) < 15
      ? settings.customerDailyTradeLimit
      : settings.highRiskCustomerDailyTradeLimit;

  const isOutOfHours = useOutOfHours();

  const onRequestCallbackClickHandler = async () => {
    setLoadingRequestCallback(true);
    // send info to server and await
    const { id } = await saveEntity({
      entity: {
        id: requestedId || undefined,
        status: 'New',
        sendId: reg.sendId,
        parentId: reg.id,
        parentType: reg.type,
        currencyTo: toCurrency,
        currencyFrom: fromCurrency,
        fixedSide: fixedAmount,
        clientBuyAmount: calculatedToAmount,
        clientSellAmount: calculatedFromAmount,
        clientRate: rate,
      },
      type: CoconutTypes.OPPORTUNITY,
    });
    setLastRequestedId(id);
    setRequestCallbackAccepted(true);
    setLoadingRequestCallback(false);
    // we do not want to call book
    return id;
  };

  const onBookClickHandler = () => {
    const fromAmount = calculatedFromAmount;
    const toAmount = calculatedToAmount;
    const conversion = {
      fromCurrency,
      toCurrency,
      fromAmount: fromAmount,
      toAmount: toAmount,
      fixedAmount,
      quoteId,
      rate,
      invertedRate,
      conversionDate,
    };
    analytics.push({
      action: {
        type: 'transaction',
        data: conversion,
      },
    });

    return onBookClick(conversion);
  };

  const { blacklistedCurrencies = [] } = settings || {};

  const isBlackListedCurrency = useMemo(() => {
    return !!(
      blacklistedCurrencies.find((blacklistedCurrency) => blacklistedCurrency.toUpperCase() === fromCurrency.toUpperCase()) ||
      blacklistedCurrencies.find((blacklistedCurrency) => blacklistedCurrency.toUpperCase() === toCurrency.toUpperCase())
    );
  }, [blacklistedCurrencies, fromCurrency, toCurrency]);

  const isRateError = failed || failedAud;

  return (
    <ExchangeCurrencyWidget
      fromDecimalPlaces={fromDecimalPlaces}
      fromCurrency={fromCurrency}
      toCurrency={toCurrency}
      toDecimalPlaces={toDecimalPlaces}
      fromAmount={calculatedFromAmount}
      toAmount={calculatedToAmount}
      loading={(loadingRate || loadingAud || loadingCurrencies || !audRate) && !isRateError}
      rate={rate !== undefined && `${rate}`}
      bankComparisonAmount={(!failed && bankComparisonAmount) || 0}
      currencies={currencies}
      popularCurrencies={popularCurrencies}
      onBookClick={onBookClickHandler}
      onRequestCallbackClick={onRequestCallbackClickHandler}
      onFieldChange={onFieldChangeHandler}
      onCollapseToggle={() => setCollapseToggle(!collapsedState)}
      hiddenWidget={collapsedState}
      showCollapsible={collapsedWidget}
      showBookButton={showBookButton}
      audToAmount={toCurrency === 'AUD' ? calculatedToAmount : calculatedAudToAmount}
      minAmount={settings.customerMinTradeValue}
      maxAmount={maxAmount}
      className={className}
      dropDownClassName={dropDownClassName}
      isInternational={reg && reg.country !== 'Australia'}
      isOutOfHours={isOutOfHours}
      loadingRequestCallback={loadingRequestCallback}
      requestCallbackAccepted={requestCallbackAccepted}
      hasRateError={isRateError}
      invalidCurrencyPair={ex instanceof CurrencyPairNotSupportedError}
      isBlacklistedCurrency={isBlackListedCurrency}
      isToAmountLocked={fixedAmount === currenciesFields.TO_FIELD}
    />
  );
};

ExchangeCurrency.propTypes = {
  initialFromCurrency: PropTypes.string,
  initialToCurrency: PropTypes.string,
  initialFromAmount: PropTypes.number,
  initialFixedAmount: PropTypes.string,
  collapsedWidget: PropTypes.bool,
  showBookButton: PropTypes.bool,
  onBookClick: PropTypes.func,
  className: PropTypes.string,
  dropDownClassName: PropTypes.string,
};

ExchangeCurrency.defaultProps = {
  initialFromCurrency: defaultWidgetCurrency,
  initialToCurrency: 'GBP',
  initialFromAmount: 500,
  initialFixedAmount: currenciesFields.FROM_FIELD,
  collapsedWidget: false,
  showBookButton: false,
  onBookClick: () => {},
  className: undefined,
  dropDownClassName: undefined,
};

export default ExchangeCurrency;
