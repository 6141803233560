/* eslint-disable guard-for-in */
import { useActiveCurrencies, useCurrentEmail, useRecipientsByOwner, useRegistration, useSettings } from '@send-data-hooks';
import { AppContext } from '@send-legacy-app/Context';
import history from '@send-legacy-app/history';
import { AddressAutoComplete } from '@send-legacy-components/Common';
import { SSubAreaTitle } from '@send-legacy-components/UI';
import { getAppConfig } from '@send-legacy-utils/appConfig';
import { setOwner } from '@send-legacy-utils/permission';
import { graphqlOperationEx } from '@sendpayments/graphql/fields';
import * as mutations from '@sendpayments/graphql/mutations';
import { findDuplicateRecipient } from '@sendpayments/js-utils/dist/selectors/recipients';
import { getCountryCode, objBlankFieldsToNull } from '@sendpayments/js-utils/dist/utils/common';
import { useFeature } from '@sendpayments/react-shared/hooks/useFeature'; // can be removed in the future
import { PD_1617_BENE_HEADER } from '@sendpayments/shared-constants/features';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
import { Button, Col, Form, Input, Radio, Row, Select, Spin, Checkbox, message } from 'antd';
import { API, Auth } from 'aws-amplify';
import 'currency-flags/dist/currency-flags.css';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import uuidv4 from 'uuid/v4';
import FormItemInput from './FormItemInput';

import { analytics } from '@sendpayments/js-utils/dist';

const { Option } = Select;

// TODO this file was moved entierly from add-recipient. We do not have enough time for refactoring it right now
// since it has been done in a way that taking out one little part means we have to re-do the whole functionality.
// I will leave it as it is and we will have to combe back later.

const getPaymentTypeRequiredFields = (item) => {
  const keys = Object.keys(item);
  const result = [];
  if (keys.includes('bic_swift')) {
    result.push('bicSwiftCode');
  }
  if (keys.includes('bsb_code')) {
    result.push('bsbCode');
  }
  if (keys.includes('sort_code')) {
    result.push('sortCodeNumber');
  }
  if (keys.includes('iban')) {
    result.push('iban');
  }
  if (keys.includes('clabe')) {
    result.push('clabeNumber');
  }
  if (keys.includes('cnaps')) {
    result.push('cnapsNumber');
  }
  if (keys.includes('aba')) {
    result.push('abaCode');
  }
  if (keys.includes('acct_number')) {
    result.push('accountNumber');
  }
  if (keys.includes('bank_name')) {
    result.push('bankName');
  }
  if (keys.includes('bank_code')) {
    result.push('bankCode');
  }
  if (keys.includes('ifsc')) {
    result.push('ifscNumber');
  }
  return result;
};

function BeneficiaryFormImpl(props) {
  const [{ data: reg }] = useRegistration();
  const [address, setAddress] = useState(null);
  const { permissions, user } = useContext(AppContext);
  const perm = permissions.Beneficiary || {};

  const [beneType, setBeneType] = useState(props.beneType);
  const [accountSource, setAccountSource] = useState({});
  const [requiredAccountFields, setRequiredAccountsFields] = useState(['bankName', 'accountNumber']);
  const [loadingRequiredAccountFields, setLoadingRequiredAccountFields] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [regexList, setRegexList] = useState(null);
  const appConfig = getAppConfig();
  const [{ data: settings }] = useSettings();
  const [{ data: currencies, loading: loadingCurrencies }] = useActiveCurrencies(settings);
  const [{ data: currentUserEmail }] = useCurrentEmail();
  const [{ data: recipients }] = useRecipientsByOwner({ owner: currentUserEmail });
  const { getFieldDecorator } = props.form;
  const showNoSwiftBicOption = useMemo(
    () =>
      (accountSource.currency === 'AUD' || props.fixedCurrencyTo === 'AUD') &&
      requiredAccountFields.find((field) => field === 'bicSwiftCode' || field === 'bsbCode'),
    [accountSource.currency, requiredAccountFields],
  );
  const [noSwiftBic, setNoSwiftBic] = useState(false);

  const [
    {
      data: { isEnabled: isBeneHeaderEnabled },
    },
  ] = useFeature({
    feature: PD_1617_BENE_HEADER,
    functionName: 'beneHeader',
  });

  const [showAddressFields, setShowAddressFields] = useState(false);

  const onChangeAccountSource = (name, value) => {
    const source = { ...accountSource };
    source[name] = value;
    setAccountSource(source);
  };

  const onSubmitHandler = () => {
    props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return;
      }
      let beneficiary = {
        id: uuidv4(),
        type: CoconutTypes.BENEFICIARY,
        parentId: reg.id,
        parentType: reg.type,
        ...values,
      };
      if (props.fixedCurrencyTo) {
        beneficiary.currencyTo = props.fixedCurrencyTo;
      }
      if (beneType === 'Myself') {
        if (reg.type === 'Retail') {
          beneficiary.beneficiaryType = 'Individual';
        } else {
          beneficiary.beneficiaryType = 'Company';
        }

        beneficiary.phone = reg.phone;
        beneficiary.phoneCountryCode = reg.phoneCountryCode;
        beneficiary.middleName = reg.middleName;
        beneficiary.birthDate = reg.birthDate;
        beneficiary.birthDateString = reg.birthDateString;
      }

      beneficiary = setOwner(user, perm, beneficiary, '', '');
      beneficiary = objBlankFieldsToNull(beneficiary);

      const keys = Object.keys(beneficiary);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (!beneficiary[key]) {
          delete beneficiary[key];
        }
      }

      if (findDuplicateRecipient(beneficiary, { recipientList: recipients })) {
        message.error('A recipient with the same details already exist.');
        return;
      }

      setSubmitting(true);
      API.graphql(graphqlOperationEx(mutations.createCoconut, { input: beneficiary }))
        .then(() => {
          setSubmitting(false);
          message.success(`Recipient created`);
          analytics.push({ action: { type: 'added_recipient' } });
          if (!props.onSubmit) {
            history.push('/recipient');
          } else {
            props.onSubmit(beneficiary);
          }
        })
        .catch((error) => {
          message.error(`Error saving recipient: ${error.errors[0].message}`);
        })
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  useEffect(() => {
    if (address) {
      const data = {};
      for (let i = 0, iLen = address.address_components.length; i < iLen; i++) {
        if (address.address_components[i].types.includes('route')) {
          data.streetAddressName = address.address_components[i].long_name;
          appConfig.streetTypes.forEach((element) => {
            if (address.address_components[i].long_name.includes(element[0])) {
              [data.streetAddressType] = element;
              data.streetAddressName = address.address_components[i].long_name.replace(element[0], '').trim();
            }
          });
        }
        if (address.address_components[i].types.includes('subpremise')) {
          data.streetAddressUnitNumber = address.address_components[i].short_name;
        }
        if (address.address_components[i].types.includes('street_number')) {
          data.streetAddressNumber = address.address_components[i].short_name;
        }
        if (address.address_components[i].types.includes('locality')) {
          data.city = address.address_components[i].short_name;
        }
        if (address.address_components[i].types.includes('administrative_area_level_1')) {
          data.state = address.address_components[i].short_name;
        }
        if (address.address_components[i].types.includes('postal_code')) {
          data.postcode = address.address_components[i].short_name;
        }
        if (address.address_components[i].types.includes('country')) {
          data.country = address.address_components[i].long_name;
        }
      }
      props.form.setFieldsValue(data);
    }
  }, [address]);

  useEffect(() => {
    async function getBankDetails() {
      const countryCode = getCountryCode(accountSource.bankAccountCountry);
      const session = await Auth.currentSession();
      const params = {
        headers: {
          Token: session.idToken.jwtToken,
        },
        queryStringParameters: {
          currency: accountSource.currency,
          bank_account_country: countryCode,
        },
      };
      setLoadingRequiredAccountFields(true);
      API.get('SendApi', '/dealing/beneficiary-required-details', params)
        .then((response) => {
          setLoadingRequiredAccountFields(false);
          const isBeneTypeCompany = beneType === 'Company' || (beneType === 'Myself' && reg.type === 'Corporate');
          // eslint-disable-next-line no-confusing-arrow
          const details = response.filter((item) =>
            isBeneTypeCompany ? item.beneficiary_entity_type === 'company' : item.beneficiary_entity_type === 'individual',
          );
          setRegexList(details);
          let regulars = details.filter((item) => item.payment_type === 'regular');
          let priorities = details.filter((item) => item.payment_type === 'priority');

          regulars = regulars.map((item) => getPaymentTypeRequiredFields(item));
          priorities = priorities.map((item) => getPaymentTypeRequiredFields(item));

          // pick a priority payment type which has common fields with one of the regular payment type
          let selectedPriority = null;
          priorities.forEach((priority) => {
            for (let i = 0; i < regulars.length; i++) {
              if (regulars[i].every((key) => priority.includes(key))) {
                selectedPriority = priority;
              }
            }
          });
          if (!selectedPriority) {
            selectedPriority = priorities.length ? priorities[0] : null;
          }
          if (!selectedPriority) {
            selectedPriority = regulars.length ? regulars[0] : null;
          }

          if (selectedPriority) {
            setRequiredAccountsFields(selectedPriority);
          } else {
            setRequiredAccountsFields(['bankName', 'accountNumber']);
          }
        })
        .catch(() => {
          message.error('Cannot get bank details. Please check your internet connection. Trying again in 3s...');
          setTimeout(() => getBankDetails(), 3000);
        });
    }
    if (accountSource.currency && accountSource.bankAccountCountry) {
      getBankDetails();
    }
  }, [accountSource]);

  const prefill = () => {
    const {
      businessName,
      firstName,
      lastName,
      streetAddressUnitNumber,
      streetAddressNumber,
      streetAddressName,
      streetAddressType,
      city,
      state,
      postcode,
      country,
      email,
    } = reg;
    props.form.setFieldsValue({
      businessName,
      firstName,
      lastName,
      streetAddressUnitNumber,
      streetAddressNumber,
      streetAddressName,
      streetAddressType,
      city,
      state,
      postcode,
      country,
      email,
    });
  };

  useEffect(() => {
    if (beneType === 'Myself') {
      prefill();
    } else {
      props.form.resetFields([
        'businessName',
        'firstName',
        'lastName',
        'streetAddressUnitNumber',
        'city',
        'state',
        'postcode',
        'country',
        'email',
        'streetAddressNumber',
        'streetAddressName',
        'streetAddressType',
      ]);
    }
  }, [beneType, reg]);

  const search = (theObject, field) => {
    let result = null;
    if (theObject instanceof Array) {
      for (let i = 0; i < theObject.length; i++) {
        result = search(theObject[i], field);
        if (result) {
          break;
        }
      }
    } else {
      // eslint-disable-next-line no-restricted-syntax
      for (const prop in theObject) {
        if (prop.indexOf(field) !== -1) {
          return theObject[prop];
        }
        if (theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
          result = search(theObject[prop], field);
          if (result) {
            break;
          }
        }
      }
    }
    return result;
  };

  const getRegex = (fieldType) => {
    let regex = search(regexList, fieldType);
    if (!regex) {
      regex = '.*';
    }
    return regex;
  };

  const toggleNoSwiftBic = useCallback(() => {
    const value = !noSwiftBic;
    setNoSwiftBic(value);
    if (value) {
      setRequiredAccountsFields([...requiredAccountFields.filter((v) => !['bicSwiftCode'].includes(v)), 'bsbCode']);
    } else {
      setRequiredAccountsFields([...requiredAccountFields.filter((v) => !['bsbCode'].includes(v)), 'bicSwiftCode']);
    }
  }, [noSwiftBic, requiredAccountFields]);

  useEffect(() => {
    if (props.fixedCurrencyTo) {
      onChangeAccountSource('currency', props.fixedCurrencyTo);
    }
  }, [props.fixedCurrencyTo]);

  const isBeneTypeCompany = beneType === 'Company' || (beneType === 'Myself' && reg.type === 'Corporate');
  const isBeneTypeIndividual = beneType === 'Individual' || (beneType === 'Myself' && reg.type === 'Retail');

  return (
    <>
      <Row className={props.className}>
        <Col>
          <Row>
            <Col md={24} span={24}>
              <br />
              <Form.Item>
                {getFieldDecorator('beneficiaryType', {
                  initialValue: beneType,
                  rules: [{ required: true, message: 'Benficiary Type is required' }],
                })(
                  <Radio.Group buttonStyle="solid" onChange={(event) => setBeneType(event.target.value)}>
                    <Radio.Button value="Myself">Myself</Radio.Button>
                    <Radio.Button value="Individual">{isBeneHeaderEnabled ? 'Someone else' : 'Individual'} </Radio.Button>
                    <Radio.Button value="Company">{isBeneHeaderEnabled ? 'A company' : 'Company'}</Radio.Button>
                  </Radio.Group>,
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 0, md: 16 }}>
            <Col md={12} span={24}>
              <Form.Item label="Country where you are transferring to" required={false} colon={false} hasFeedback>
                {getFieldDecorator('beneficiaryBankAccountCountry', {
                  rules: [{ required: true, message: 'Country where you are transferring to is required' }],
                })(
                  <Select
                    showSearch
                    placeholder="Select country where you are transferring to"
                    onChange={(value) => onChangeAccountSource('bankAccountCountry', value)}
                    style={{ width: '100%' }}
                  >
                    {appConfig.countries.map((value) => (
                      <Option key={value} value={value}>
                        {value}
                      </Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            {!props.hideCurrencyTo && (
              <Col md={8} span={24}>
                <Form.Item label="Currency" required={false} colon={false} hasFeedback>
                  {getFieldDecorator('currencyTo', {
                    initialValue: props.fixedCurrencyTo,
                    rules: [{ required: true, message: 'Currency is required' }],
                  })(
                    <Select
                      showSearch
                      placeholder="Select Currency"
                      onChange={(value) => onChangeAccountSource('currency', value)}
                      style={{ width: '100%' }}
                      disabled={!!props.fixedCurrencyTo}
                      loading={loadingCurrencies}
                    >
                      {currencies?.length &&
                        currencies.map(({ code, currencyName }) => (
                          <Option key={code} value={code}>
                            {currencyName}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            )}
          </Row>
          {isBeneTypeCompany && (
            <Row>
              <FormItemInput
                getFieldDecorator={(value) =>
                  getFieldDecorator(value, {
                    rules: [
                      { transform: (value) => (typeof value === 'string' ? value.trim() : value) },
                      { required: isBeneTypeCompany, message: 'Company name is required' },
                      {
                        pattern: /^(?=.*[a-zA-ZÀ-ÿ])[a-zA-ZÀ-ÿ\s\d!@#$%^&*()+=;:'",.<>?/\\`~-]+$/,
                        message: 'Company name must contain at least one letter in the alphabet',
                      },
                    ],
                  })
                }
                fieldName="businessName"
                label="Company Name"
              />
            </Row>
          )}
          {isBeneTypeIndividual && (
            <Row gutter={{ xs: 0, md: 16 }}>
              <FormItemInput
                getFieldDecorator={(value) =>
                  getFieldDecorator(value, {
                    rules: [
                      { transform: (value) => (typeof value === 'string' ? value.trim() : value) },
                      { required: isBeneTypeIndividual, message: 'First name is required' },
                      { pattern: /^[a-zA-ZÀ-ÿ\s!@#$%^&*()+=;:'",.<>?/\\`~-]+$/, message: 'First name cannot include numbers' },
                    ],
                  })
                }
                fieldName="firstName"
                label="First Name"
              />
              <FormItemInput
                getFieldDecorator={(value) =>
                  getFieldDecorator(value, {
                    rules: [
                      { transform: (value) => (typeof value === 'string' ? value.trim() : value) },
                      { required: isBeneTypeIndividual, message: 'Last name is required' },
                      { pattern: /^[a-zA-ZÀ-ÿ\s!@#$%^&*()+=;:'",.<>?/\\`~-]+$/, message: 'Last name cannot include numbers' },
                    ],
                  })
                }
                fieldName="lastName"
                label="Last Name"
              />
            </Row>
          )}
          <Row gutter={{ xs: 0, md: 16 }} style={{ marginTop: 24, marginBottom: 16 }}>
            <AddressAutoComplete
              setter={(address) => {
                setShowAddressFields(true);
                return setAddress(address);
              }}
              sm={24}
              md={20}
            />
          </Row>
          {showAddressFields || beneType == 'Myself' ? (
            <>
              <Row gutter={{ xs: 0, md: 16 }}>
                <FormItemInput
                  getFieldDecorator={(value) =>
                    getFieldDecorator(value, {
                      rules: [{ required: !props.form.getFieldValue('streetAddressNumber'), message: ' ' }],
                    })
                  }
                  label="Unit #"
                  fieldName="streetAddressUnitNumber"
                  md={4}
                />
                <FormItemInput
                  getFieldDecorator={(value) =>
                    getFieldDecorator(value, {
                      rules: [
                        {
                          required: !props.form.getFieldValue('streetAddressUnitNumber'),
                          message: 'Street # or Unit # is required',
                        },
                      ],
                    })
                  }
                  label="Street #"
                  fieldName="streetAddressNumber"
                  md={6}
                />

                <FormItemInput getFieldDecorator={getFieldDecorator} label="Street Name" fieldName="streetAddressName" required />
              </Row>
              <Row gutter={{ xs: 0, md: 16 }}>
                <FormItemInput getFieldDecorator={getFieldDecorator} label="Street Type" fieldName="streetAddressType" md={6} />
                <FormItemInput getFieldDecorator={getFieldDecorator} fieldName="city" label="City" md={8} required />
                <FormItemInput getFieldDecorator={getFieldDecorator} fieldName="state" label="State" md={6} />
              </Row>
              <Row gutter={{ xs: 0, md: 16 }}>
                <FormItemInput getFieldDecorator={getFieldDecorator} fieldName="postcode" label="Postal Code" md={6} required />
                <Col md={12} span={24}>
                  <Form.Item label="Country" required={false} colon={false}>
                    {getFieldDecorator('country', {
                      rules: [{ required: true, message: 'Country is required' }],
                    })(
                      <Select showSearch style={{ width: '100%' }} placeholder="Select Country">
                        {appConfig.countries.map((value) => (
                          <Option key={value} value={value}>
                            {value}
                          </Option>
                        ))}
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </>
          ) : (
            <Row>
              <Button onClick={setShowAddressFields}>Manually enter address</Button>
            </Row>
          )}
          <Row gutter={{ xs: 0, md: 16 }}>
            <FormItemInput getFieldDecorator={getFieldDecorator} fieldName="email" label="Email" md={8} />
          </Row>
          <SSubAreaTitle text="Account" style={{ paddingRight: 0 }} icon="bank" />
          <Spin spinning={loadingRequiredAccountFields}>
            <Row gutter={{ xs: 0, md: 16 }}>
              <FormItemInput
                getFieldDecorator={getFieldDecorator}
                requiredAccountFields={requiredAccountFields}
                fieldName="bicSwiftCode"
                label="BIC/SWIFT"
                regex={getRegex('bic_swift')}
              />
              <FormItemInput
                getFieldDecorator={getFieldDecorator}
                requiredAccountFields={requiredAccountFields}
                fieldName="iban"
                label="IBAN"
                regex={getRegex('iban')}
              />
              <FormItemInput
                getFieldDecorator={getFieldDecorator}
                requiredAccountFields={requiredAccountFields}
                fieldName="sortCodeNumber"
                label="Sort Code"
                placeholder="eg. 401276"
                regex={getRegex('sort_code')}
              />
              <FormItemInput
                getFieldDecorator={getFieldDecorator}
                requiredAccountFields={requiredAccountFields}
                fieldName="clabeNumber"
                label="Clabe Number"
                regex={getRegex('clabe')}
              />
              <FormItemInput
                getFieldDecorator={getFieldDecorator}
                requiredAccountFields={requiredAccountFields}
                fieldName="cnapsNumber"
                label="CNAPS Number"
                regex={getRegex('cnaps')}
              />
              <FormItemInput
                getFieldDecorator={getFieldDecorator}
                requiredAccountFields={requiredAccountFields}
                fieldName="abaCode"
                label="ABA Number"
                regex={getRegex('aba')}
              />
              <FormItemInput
                getFieldDecorator={getFieldDecorator}
                requiredAccountFields={requiredAccountFields}
                fieldName="bankName"
                label="Bank Name"
                regex={getRegex('bank_name')}
              />
              <FormItemInput
                getFieldDecorator={getFieldDecorator}
                requiredAccountFields={requiredAccountFields}
                fieldName="bankCode"
                label="Bank Code"
                regex={getRegex('bank_code')}
              />
              {!noSwiftBic && (
                <FormItemInput
                  getFieldDecorator={getFieldDecorator}
                  requiredAccountFields={requiredAccountFields}
                  fieldName="accountNumber"
                  label={accountSource.currency === 'NZD' ? 'Full BSB & Account Number' : 'Account Number'}
                  regex={getRegex('acct_number')}
                />
              )}
              <FormItemInput
                getFieldDecorator={getFieldDecorator}
                requiredAccountFields={requiredAccountFields}
                fieldName="ifscNumber"
                label="IFSC"
                regex={getRegex('ifsc')}
              />
              <Form.Item label="Status" style={{ display: 'none' }}>
                {getFieldDecorator('status', {
                  initialValue: 'New',
                })(<Input />)}
              </Form.Item>
            </Row>
            {showNoSwiftBicOption && noSwiftBic && (
              <>
                <Row gutter={{ xs: 0, md: 16 }}>
                  <FormItemInput
                    getFieldDecorator={getFieldDecorator}
                    requiredAccountFields={requiredAccountFields}
                    fieldName="bsbCode"
                    label="BSB"
                    regex={getRegex('bsb_code')}
                  />
                  <FormItemInput
                    getFieldDecorator={getFieldDecorator}
                    requiredAccountFields={requiredAccountFields}
                    fieldName="accountNumber"
                    label="Account Number"
                    regex={getRegex('acct_number')}
                  />
                </Row>
              </>
            )}
            {showNoSwiftBicOption && (
              <Row gutter={{ xs: 0, md: 16 }}>
                <Form.Item>
                  <Checkbox onChange={toggleNoSwiftBic} checked={noSwiftBic}>
                    No BIC/SWIFT code available?
                  </Checkbox>
                </Form.Item>
              </Row>
            )}
            {showNoSwiftBicOption && noSwiftBic && (
              <Row gutter={{ xs: 0, md: 16 }}>
                <p>When selecting AUD BSB and Account Number, your payment will be dispatched on the next business day</p>
              </Row>
            )}
          </Spin>
          <Row gutter={16} type="flex" justify="end">
            <Col>
              <Button
                type="secondary"
                onClick={() => {
                  if (props.onCancel) {
                    return props.onCancel();
                  }
                  return history.push('/recipient');
                }}
              >
                {props.cancelText}
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                style={{ alignSelf: 'flex-end' }}
                onClick={async () => {
                  await setShowAddressFields(true);
                  return onSubmitHandler();
                }}
                loading={submitting}
              >
                {props.submitText}
              </Button>
            </Col>
          </Row>
          <br />
        </Col>
      </Row>
    </>
  );
}

BeneficiaryFormImpl.propTypes = {
  form: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  submitText: PropTypes.string,
  onCancel: PropTypes.func,
  fixedCurrencyTo: PropTypes.string,
  hideCurrencyTo: PropTypes.bool,
  cancelText: PropTypes.string,
  className: PropTypes.string,
  beneType: PropTypes.string,
};

BeneficiaryFormImpl.defaultProps = {
  onSubmit: undefined,
  onCancel: undefined,
  fixedCurrencyTo: undefined,
  hideCurrencyTo: false,
  submitText: 'Submit',
  cancelText: 'Cancel',
  className: undefined,
  beneType: 'Myself',
};

// TODO: Remove this when Ant supports function based component
class BeneficiaryFormComponent extends React.PureComponent {
  render() {
    return <BeneficiaryFormImpl {...this.props} />;
  }
}

const form = Form.create({ name: 'add-feneficiary' })(BeneficiaryFormComponent);
export default form;
