import React from 'react';
import { Form, Input as InputAntd, Icon, Tooltip, Col } from 'antd';
import PropTypes from 'prop-types';
import { withAnalytics } from '@sendpayments/react-shared/HOC/withAnalytics';

const { Input } = withAnalytics({ Input: InputAntd });

function CommonFormInput(props) {
  const { getFieldDecorator } = props.onGetFieldDecorator ? { getFieldDecorator: props.onGetFieldDecorator } : props.form;
  const sm = props.sm ? props.sm : props.span;
  const md = props.md ? props.md : sm;
  return (
    <Col span={props.span} md={md} sm={sm}>
      <Form.Item hasFeedback colon={false} required={false} label={props.label}>
        {getFieldDecorator(props.name, {
          initialValue: props.data,
          rules: [{ required: props.required, message: `${props.label} is required` }],
        })(
          <Input
            data-testid={props.name}
            name={props.name}
            prefix={
              props.tooltip ? (
                <Tooltip title={props.tooltip}>
                  <Icon type="info-circle" />
                </Tooltip>
              ) : null
            }
            placeholder={props.placeholder || `Enter your ${props.label}`}
          />,
        )}
      </Form.Item>
    </Col>
  );
}

CommonFormInput.propTypes = {
  form: PropTypes.object,
  onGetFieldDecorator: PropTypes.func,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  span: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  data: PropTypes.string,
};

CommonFormInput.defaultProps = {
  placeholder: undefined,
  form: {},
  onGetFieldDecorator: undefined,
  span: 24,
  sm: 0,
  md: 0,
  label: '',
  required: true,
  tooltip: null,
  data: null,
};

export default CommonFormInput;
