import { Center, Spinner } from '@chakra-ui/react';
import React from 'react';

export const PageSpinner = () => {
  return (
    <Center w="100vw" h="100vh">
      <Spinner size="xl" color="primary.base" />
    </Center>
  );
};
