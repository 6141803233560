import React from 'react';
import { Box, Button, ButtonGroup, Heading, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { OpenConversionProps } from './BeneModal';
import dayjs from 'dayjs';

export interface OpenConversionsProps {
  openConversions: OpenConversionProps[];
  onSetAccepted?: () => void;
  onClose: () => void;
  type: 'edit' | 'delete';
  allowBeneEdit?: boolean;
}

export const OpenConversions = ({ openConversions, onSetAccepted, onClose, type, allowBeneEdit }: OpenConversionsProps) => {
  return (
    <>
      <Heading>Open Transactions</Heading>
      <Text as="span">
        {type === 'delete'
          ? 'You have these open transactions associated with your account.'
          : 'The following open transactions will be updated with your change.'}
      </Text>
      <TableContainer maxHeight={'400px'} overflowY="scroll" display={{ base: 'none', md: 'block' }}>
        <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              <Th>date created</Th>
              <Th isNumeric>currency from</Th>
              <Th>currency from value</Th>
              <Th isNumeric>currency to</Th>
              <Th>currency to value</Th>
            </Tr>
          </Thead>
          <Tbody>
            {openConversions?.map((conversion, key) => (
              <Tr key={key}>
                <Td>{dayjs(conversion.createdAt).format('DD/MM/YYYY')}</Td>
                <Td isNumeric>{conversion.sellCurrency}</Td>
                <Td>{conversion.clientSellAmount}</Td>
                <Td isNumeric>{conversion.buyCurrency}</Td>
                <Td>{conversion.clientBuyAmount}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <TableContainer maxHeight={'400px'} overflowY="scroll" overflowX="hidden" display={{ base: 'block', md: 'none' }} pt={2}>
        <Table variant="striped" colorScheme="gray" size="sm" styles={{ th: { padding: 0.5 }, td: { padding: 0.5 } }}>
          <Thead>
            <Tr>
              <Th>date created</Th>
              <Th>from</Th>
              <Th>to</Th>
            </Tr>
          </Thead>
          <Tbody>
            {openConversions?.map((conversion, key) => (
              <Tr key={key} gridRowGap={1}>
                <Td>{dayjs(conversion.createdAt).format('DD/MM/YYYY')}</Td>
                <Td>
                  {conversion.sellCurrency} {conversion.clientSellAmount}
                </Td>
                <Td>
                  {conversion.buyCurrency} {conversion.clientBuyAmount}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Box paddingTop={'20px'}>
        {type === 'delete' ? (
          <Text as="span">
            This recipient is currently in use and cannot be deleted until all open transactions are completed.
          </Text>
        ) : allowBeneEdit ? (
          <Text as="span">Please only proceed if you are happy to apply this change to all open transactions. </Text>
        ) : (
          <Text as="span">This recipient is currently in use and cannot be edited until all open transactions are completed</Text>
        )}
      </Box>
      <ButtonGroup flexDir="row" spacing={2} pt={2}>
        {/* Disable the Proceed button if the benes aren't allowed to be edited */}
        {type === 'edit' && allowBeneEdit && (
          <Button _hover={{ cursor: 'pointer' }} onClick={onSetAccepted} borderWidth={0}>
            Proceed
          </Button>
        )}
        <Button _hover={{ cursor: 'pointer' }} onClick={onClose} mb={{ base: 2, sm: 0 }} variant={'outline'}>
          Cancel
        </Button>
      </ButtonGroup>
    </>
  );
};
