import React from 'react';
import { Button, Heading, Stack, Flex, useBreakpointValue, Text, Spinner } from '@chakra-ui/react';
import { LegacyNav } from '../utils/LegacyNav';
import { useRegistration, useCurrentEmail, useRecipientsByStatus } from '@send-base/data-hooks';
import { RecipientList } from '@send-base/atomic-components/organisms/recipient/RecipientList';
import { useHistory } from 'react-router-dom';
import { AddIcon } from '@chakra-ui/icons';
import { StaticNotification } from '@send-base/atomic-components/atoms';
import { VRNAcknowledgementBanner } from '@send-legacy-components/VRNAcknowledgementBanner';
import { getBeneName } from '@send-base/utilities/sortBenesByName';

const getDescription = ({ accountNumber, iban, clabeNumber }) =>
  accountNumber || iban || clabeNumber
    ? `${accountNumber ? 'Account' : iban ? 'IBAN' : 'CLABE'} ending in ${(accountNumber || iban || clabeNumber).substring(
        (accountNumber || iban || clabeNumber).length - 4,
        (accountNumber || iban || clabeNumber).length,
      )}`
    : '';

export const RecipientPage = () => {
  const [{ data: userEmail }] = useCurrentEmail();
  const [{ data: reg, loading: loadingRegistration }] = useRegistration();
  const [
    {
      loading: loadingBenes,
      data: [pendingBenes, verifiedBenes, deletedBenes],
      refetch: refetchBenes,
    },
  ] = useRecipientsByStatus({ owner: userEmail });
  const history = useHistory();
  const addText = useBreakpointValue({ base: 'Add new', md: 'Add recipient' });
  const heading = useBreakpointValue({ base: 'heading3', md: 'heading1' });

  return (
    <LegacyNav activeItem="recipients" registration={reg}>
      <VRNAcknowledgementBanner />
      <Stack spacing={7} ml="auto" mr="auto" mt="10">
        <Flex justifyContent="space-between" alignItems="center" flexWrap="wrap">
          <Heading textAlign="center" variant={heading} as={heading == 'heading3' ? 'h1' : 'h3'}>
            My recipients
          </Heading>
          <Button leftIcon={<AddIcon />} variant="outline" onClick={() => history.push('/add-recipient')}>
            {addText}
          </Button>
        </Flex>
        {reg.status !== 'Registered' && reg.status !== 'Dealing' && (
          <StaticNotification>
            <Text as="span">Your account is currently under review, once complete you can start making transfers.</Text>{' '}
            <Text as="span" fontWeight="bold">
              This is usually done instantly but might take up to 48 hours.
            </Text>
          </StaticNotification>
        )}
        {loadingRegistration || loadingBenes ? (
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="gray.900" size="xl" alignSelf="center" mt={10} />
        ) : (
          <>
            {!!verifiedBenes.length && (
              <RecipientList
                type="Verified"
                isRegistered={reg.status === 'Registered' || reg.status === 'Dealing'}
                refetchBenes={refetchBenes}
                items={verifiedBenes.map((recipient) => ({
                  ...recipient,
                  recipient,
                  userEmail: userEmail,
                  currency: recipient.currencyTo,
                  name: getBeneName(recipient),
                  description: getDescription(recipient),
                  onSendMoney: () =>
                    history.push({
                      pathname: `/recipient/transfer/${recipient.id}`,
                      state: { recipient },
                    }),
                }))}
              />
            )}
            {!!pendingBenes.length && (
              <RecipientList
                type="New"
                isRegistered={reg.status === 'Registered'}
                refetchBenes={refetchBenes}
                items={pendingBenes.map((recipient) => ({
                  ...recipient,
                  recipient,
                  userEmail: userEmail,
                  currency: recipient.currencyTo,
                  name: getBeneName(recipient),
                  description: getDescription(recipient),
                }))}
              />
            )}
            {!!deletedBenes.length && (
              <RecipientList
                type="Deleted"
                isRegistered={reg.status === 'Registered'}
                refetchBenes={refetchBenes}
                items={deletedBenes.map((recipient) => ({
                  ...recipient,
                  recipient,
                  userEmail: userEmail,
                  currency: recipient.currencyTo,
                  name: recipient.businessName || `${recipient.firstName} ${recipient.lastName}`,
                  description: getDescription(recipient),
                }))}
              />
            )}
          </>
        )}
      </Stack>
    </LegacyNav>
  );
};
