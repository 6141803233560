import React, { useState, useEffect } from 'react';
import { PageSpinner, RadioList, RadioOption, SimpleModal } from '../../organisms';
import { RadioCard } from '../../molecules';
import { Button, Box, Heading, Flex, Text, Stack, useDisclosure } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
import { useLogout, useRegistration } from '@send-data-hooks';
import { PersonalIcon, BusinessIcon, ChevronIcon, CloseIcon, ExitIcon } from '@sendpayments/react-shared/components/Icons';

interface AccountTypePageProps {
  onNextStep?: () => void;
}

const AccountTypePage: React.FC<AccountTypePageProps> = ({ onNextStep = () => {} }) => {
  const history = useHistory();
  const [, doLogout] = useLogout();
  const [accountType, setAccountType] = useState('');
  const [formValid, setFormValid] = useState(true);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [{ data: registration, loading, failed }] = useRegistration();

  useEffect(() => {
    if (failed) {
      return (() =>
        (doLogout as any)().then(() => {
          history.push('/auth');
        })) as any;
    }
    if (registration?.type === CoconutTypes.RETAIL) {
      return history.push('/retail');
    }
    if (registration?.type === CoconutTypes.CORPORATE) {
      return history.push('/corporate');
    }
  }, [registration, failed]);

  const onRadioListChange = (selectedAccountType: string) => {
    setFormValid(true);
    setAccountType(selectedAccountType);
  };

  const options: RadioOption[] = [
    {
      title: '',
      value: 'Personal',
      description: (
        <>
          Click here to set up{' '}
          <Text as="span" fontWeight="bold">
            personal
          </Text>{' '}
          account
        </>
      ),
      icon: <PersonalIcon h="12" w="12" display="flex" />,
    },
    {
      title: '',
      value: 'Business',
      description: (
        <>
          Click here to set up{' '}
          <Text as="span" fontWeight="bold">
            business
          </Text>{' '}
          account
        </>
      ),
      icon: <BusinessIcon h="12" w="12" display="flex" />,
    },
  ];

  const onSubmit = () => {
    if (accountType === '') {
      setFormValid(false);
      return;
    }

    let location = 'retail';
    if (accountType === 'Business') {
      location = 'corporate';
    }

    onNextStep();
    history.push(`/${location}`);
  };

  const onConfirm = () => {
    history.push('/dashboard');
  };

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <Box p={{ base: '4', lg: '8' }}>
      <CloseIcon
        onClick={onOpen}
        color="text.base"
        minW={10}
        ml="auto"
        mb="auto"
        w={10}
        h={10}
        p={3}
        zIndex={3}
        border="medium"
        bgColor="white"
        display="absolute"
        borderColor="border.base"
        _hover={{
          cursor: 'pointer',
          bgColor: 'white',
          borderColor: 'primary.base',
          color: 'primary.base',
        }}
        _active={{
          bgColor: 'white',
          borderColor: 'primary.dark',
          color: 'primary.dark',
        }}
        borderRadius="100%"
      />
      <SimpleModal
        open={isOpen}
        primaryButtonText="Yes, leave"
        secondaryButtonText="Cancel"
        onClose={onClose}
        onSecondaryAction={onClose}
        onPrimaryAction={onConfirm}
        key={'accountTypeModal'}
      >
        <ExitIcon color="text.base" boxSize={{ base: '57px', lg: '83px' }} />
        <Heading as="h3" variant="heading3" mt={4}>
          Are you sure you want to leave this process?
        </Heading>
        <Text>Changes you made may not be saved.</Text>
      </SimpleModal>
      <Flex justifyContent="center" mt={{ base: '107px', md: '150px' }}>
        <Stack spacing={6}>
          <Heading textAlign={{ base: 'left', md: 'center' }} variant="heading1">
            {'Account types'}
          </Heading>
          <Box w={'fit-content'} justifyContent="center" alignSelf="center">
            <RadioList
              radioListName="accountType"
              defaultValue=""
              onRadioListChange={onRadioListChange}
              valid={formValid}
              errorMessage="Please select an account type"
              radioOptions={options}
              ListItemComponent={RadioCard}
            />
          </Box>
          <Button
            alignSelf="center"
            w={'fit-content'}
            onClick={onSubmit}
            size={'md'}
            rightIcon={<ChevronIcon h="8px" w="6px" direction="forward" />}
          >
            Next
          </Button>
        </Stack>
      </Flex>
    </Box>
  );
};

export { AccountTypePage, AccountTypePageProps };
