import React from 'react';
import { Flex, View, ProgressCircle } from '@adobe/react-spectrum';
import styles from './Item.module.less';

const renderItemIcon = ({ icon: ItemIcon, isLoading }) => {
  if (isLoading) {
    return <ProgressCircle size="S" aria-label="Loading…" isIndeterminate />;
  }

  if (!ItemIcon) {
    return '';
  }

  return <ItemIcon size="S" />;
};

const Item = ({ icon, isLoading, children }) => {
  return (
    <Flex gap="size-125" justifyContent="start" alignItems="start">
      <View flexShrink={0} UNSAFE_className={styles.icon}>
        {renderItemIcon({ icon, isLoading })}
      </View>
      <View>{children}</View>
    </Flex>
  );
};

export default Item;
