import React from 'react';
import { Form, Select as SelectAntd, Radio, Col } from 'antd';
import PropTypes from 'prop-types';
import { withAnalytics } from '@sendpayments/react-shared/HOC/withAnalytics';

const { Select, RadioGroup } = withAnalytics({ Select: SelectAntd, RadioGroup: Radio.Group }, { info: 'DropDown' });

function CommonFormSelect(props) {
  const { getFieldDecorator } = props.onGetFieldDecorator ? { getFieldDecorator: props.onGetFieldDecorator } : props.form;
  const { Option } = SelectAntd;

  const handleChange = (e) => {
    if (props.radio) {
      props.action(e.target.value);
    } else {
      props.action(e);
    }
  };
  const sm = props.sm ? props.sm : props.span;
  const md = props.md ? props.md : sm;
  return (
    <Col span={props.span} md={md} sm={sm}>
      <Form.Item hasFeedback={!props.radio} colon={false} required={false} label={props.label}>
        {getFieldDecorator(props.name, {
          initialValue: props.data ? props.data : props.default,
          rules: [{ required: props.required, message: `${props.label} is required` }, ...props.validationRules],
        })(
          props.radio ? (
            <RadioGroup
              data-testid={props.name}
              initialValue={props.default}
              buttonStyle="solid"
              onChange={props.action ? handleChange : null}
            >
              {props.dataSource
                ? props.dataSource.map((item) => <Radio.Button key={`${props.name}${item.key}`}>{item.value}</Radio.Button>)
                : props.dataList.map((value) => (
                    <Radio.Button data-testid={`${props.name}-${value}`} key={`${props.name}${value}`} value={value}>
                      {value}
                    </Radio.Button>
                  ))}
            </RadioGroup>
          ) : (
            <Select
              name={props.name}
              data-testid={props.name}
              showSearch
              placeholder={props.placeholder || `Select your ${props.label}`}
              onChange={props.action ? handleChange : null}
            >
              {props.dataSource
                ? props.dataSource.map((item) => <Option key={item.key}>{item.value}</Option>)
                : props.dataList.map((value) => (
                    <Option key={value} value={value}>
                      {value}
                    </Option>
                  ))}
            </Select>
          ),
        )}
      </Form.Item>
    </Col>
  );
}

CommonFormSelect.propTypes = {
  form: PropTypes.object,
  onGetFieldDecorator: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  span: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  required: PropTypes.bool,
  dataList: PropTypes.array,
  dataSource: PropTypes.array,
  action: PropTypes.func,
  radio: PropTypes.bool,
  default: PropTypes.string,
  data: PropTypes.string,
  placeholder: PropTypes.string,
  validationRules: PropTypes.array,
};

CommonFormSelect.defaultProps = {
  form: {},
  onGetFieldDecorator: undefined,
  span: 24,
  sm: 0,
  md: 0,
  label: '',
  required: true,
  action: null,
  radio: false,
  default: undefined,
  data: null,
  dataList: [],
  dataSource: null,
  placeholder: '',
  validationRules: [],
};

export default CommonFormSelect;
