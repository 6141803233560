/* eslint-disable camelcase */
import { get } from '@send-base/utilities/endpoint';
import { withCachedPromiseResult, buildUniqueKey, types } from '@sendpayments/js-utils/dist/services/caching';
import { useSettings } from '@send-data-hooks';
import { useRemoteData, useRemoteDataSelector } from '@sendpayments/react-shared/hooks/useRemoteData';

const fetchReasons = async ({ toCurrency, bankCountryCode, entityType = 'individual' }) => {
  const cache = withCachedPromiseResult(
    buildUniqueKey('/dealing/payment-purpose-codes', { toCurrency, bankCountryCode, entityType }),
    {
      type: types.LOCAL_STORAGE,
    },
  );

  const response = await cache(async () => {
    const result = await get('/dealing/payment-purpose-codes', {
      data: { currency: toCurrency, bank_account_country: bankCountryCode, entity_type: entityType.toLowerCase() },
    });

    return result.map(({ purpose_code, purpose_description }) => ({
      code: purpose_code,
      description: purpose_description,
    }));
  });

  return response;
};

const reasonsTransformer = ({ data: settings } = { data: {} }, { data: paymentReasons } = { data: [] }) => {
  const { transferPurposes } = settings || { transferPurposes: [] };

  const transferReasons = (transferPurposes || []).map((name) => ({
    code: name.replace(/\s+/g, '').toUpperCase(),
    description: name,
  }));

  return {
    transferReasons,
    paymentReasons,
  };
};

const useReasonCodes = ({ toCurrency, bankCountryCode, entityType }) => {
  const [settingsHook] = useSettings();

  const [reasonsHook] = useRemoteData(
    () => ({
      key: `get./dealing/payment-purpose-codes:${bankCountryCode}-entityType:${entityType}-toCurrency:${toCurrency}`,
      onInitialFetch: () => fetchReasons({ toCurrency, bankCountryCode, entityType }),
      initialState: [],
    }),
    [toCurrency, bankCountryCode, entityType],
  );

  return useRemoteDataSelector(() => reasonsTransformer(settingsHook, reasonsHook), [settingsHook, reasonsHook]);
};

export default useReasonCodes;
