import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Menu, Icon } from '@send-legacy-components/Common';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import styles from './Notifications.module.less';

dayjs.extend(localizedFormat);

const Notifications = ({ notifications, onItemClick, loading }) => {
  const dataSource = useMemo(
    () =>
      notifications.map(({ id, createdAt, header, description, unread }) => ({
        id,
        iconType: 'send-dot',
        title: header,
        header: description,
        description: dayjs(createdAt).format('llll'),
        reference: (unread && { type: 'error', text: 'new', pill: true }) || undefined,
      })),
    [notifications],
  );

  return (
    <Menu
      loading={loading}
      overlayClassName={styles.container}
      icon="bell"
      badgeNumber={(notifications.length > 0 && notifications.length) || undefined}
      dataSource={dataSource}
      onItemClick={onItemClick}
      enableItemMobileView={false}
      emptyMessage={
        <div className={styles.empty}>
          <Icon type="check" outline="circle" />
          <Icon type="bell" />
          <h4>Notifications</h4>
          <h3>Nothing here!</h3>
          <div className={styles.text}>An alert will appear when you have a notification</div>
        </div>
      }
    />
  );
};

Notifications.propTypes = {
  notifications: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  onItemClick: PropTypes.func.isRequired,
};

Notifications.defaultProps = {
  loading: false,
};

export default Notifications;
