import { useFetch } from '@sendpayments/react-shared/hooks';
import { API, Auth } from 'aws-amplify';
import { logger } from '@sendpayments/js-utils/dist/services/logger';

const audAccount = {
  name: 'SENDFX Client ACC AUD',
  payId: 'info@sendfx.com.au',
  sortCode: '084917',
  accountNumber: '882399657',
  swift: 'NATAAU33',
  bank: 'NAB',
  currency: 'AUD',
  bankAddress: '27 Scarborough Street, Southport, 4215',
};

export const doFetch = async ({ currencyCloudReference, sendId, currency, sellAmount, userId, userType }) => {
  try {
    const token = await Auth.currentSession().then((session) => {
      return session.idToken.jwtToken;
    });

    const params = {
      headers: {
        Token: token,
      },
      queryStringParameters: {
        currency: currency,
        sellAmount: sellAmount,
        userId: userId,
        userType: userType,
      },
    };

    const result = await API.get('SendApi', '/dealing/settlement-accounts', params).then((response) => {
      const accounts = {};
      response.forEach((item) => {
        accounts[item.currency] = item;
      });
      return response;
    });

    const swiftCodeResult = (account) => {
      if (account.routing_code_type && account.routing_code_type === 'bic_swift') {
        return account.routing_code;
      } else if (account.routing_code_type && account.routing_code_type === 'sort_code') {
        return '';
      }

      return account.bic_swift;
    };

    const sortCodeResult = (account) => {
      if (account.routing_code_type && account.routing_code_type === 'sort_code') {
        return account.routing_code;
      } else if (account.routing_code_type && account.routing_code_type === 'bic_swift') {
        return '';
      }

      return account.sort_code;
    };

    const ibanResult = (account) => {
      if (account.account_number_type && account.account_number_type === 'iban') {
        return account.account_number;
      }

      return account.iban;
    };

    const accounts = result.reduce((acct, account) => {
      acct[account.currency] = {
        name: account.account_holder_name || account.bank_account_holder_name,
        bank: account.bank_name,
        bankAddress: account.bank_address,
        currency: account.currency,
        accountNumber: account.account_number,
        swift: swiftCodeResult(account),
        iban: ibanResult(account),
        sortCode: sortCodeResult(account),
        reference: currencyCloudReference,
      };
      return acct;
    }, {});
    accounts.AUD = { ...audAccount, reference: sendId };
    return accounts;
  } catch (error) {
    logger.error(`ERROR while fetching for /dealing/settlement-accounts api`, error);
  }
};

const useSettlementAccounts = ({ currencyCloudReference, sendId, currency, sellAmount, userId, userType }) =>
  useFetch({ currencyCloudReference, sendId, currency, sellAmount, userId, userType }, doFetch);

export default useSettlementAccounts;
