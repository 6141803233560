import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Layout, Spin, Divider } from 'antd';
import CustomLayout from '@send-legacy-components/Common/CustomLayout';
import { SMenu } from '@send-legacy-components/UI/SMenu';
import classNames from 'classnames';
import styles from './BaseLayout.module.less';

const { Content } = Layout;

const BaseLayout = ({ loading, name, registration, title, subtitle, children, actionableButtonsComponent }) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <Spin spinning={loading}>
      <CustomLayout onClick={() => setCollapsed(true)} showSupportButton>
        <Content>
          <div className={classNames(styles.container, 'container')}>
            <Row>
              <SMenu activeItem={name} registration={registration} collapsed={collapsed} setCollapsed={setCollapsed} />
              <h1 className="bold" style={{ marginTop: '50px' }}>
                {title}
              </h1>
              <Row type="flex" justify="space-between">
                <div>
                  <h5 className={styles.title}>{subtitle}</h5>
                </div>
                {actionableButtonsComponent}
              </Row>
              <br />
              <Divider className="title-underline secondary" />
              {children}
            </Row>
          </div>
        </Content>
      </CustomLayout>
    </Spin>
  );
};

BaseLayout.propTypes = {
  loading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  registration: PropTypes.object,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  actionableButtonsComponent: PropTypes.node,
};

BaseLayout.defaultProps = {
  loading: false,
  registration: {},
  actionableButtonsComponent: undefined,
};

export default BaseLayout;
