import { useEffect } from 'react';
import { useStepsContext } from './context';

const useSteps = ({ id, initialState }) => {
  const context = useStepsContext();

  if (!context || !context[0]) {
    throw Error('useSteps need to be used inside an <StepsProvider>');
  }

  const [
    {
      steps: { getStepIndex, setStepIndex, clearSteps, setStepData, getStepData, setStepResult },
    },
  ] = context;

  const currentIndex = getStepIndex({ id });
  const initialIndex = initialState && initialState.index;

  useEffect(() => {
    // only if both are passed we want to initialize the state.
    // we can use it also so we can change the state from outside the steps common component.
    if (
      id &&
      initialState &&
      (initialState.index !== undefined || initialState.data !== undefined) &&
      currentIndex !== initialIndex
    ) {
      setStepIndex({ id, index: initialState.index, result: initialState.data });
    }

    return () => {
      if (id && initialState) {
        clearSteps({ id });
      }
    };
  }, [id, initialIndex]);

  const dataResult = getStepData({ id });
  const { stepsData } = dataResult || {};

  return [
    {
      currentIndex: currentIndex || initialIndex,
      data: dataResult,
    },
    {
      setStepIndex: (index) => setStepIndex({ id, index }),
      setStepData: (data) => setStepData({ id, data }),
      getStepResult: (index) => stepsData && stepsData[index],
      setStepResult: (index, result) => setStepResult({ id, index, result }),
    },
  ];
};

export default useSteps;
