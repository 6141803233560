import React from 'react';
import {
  Button,
  Stack,
  FormErrorMessage,
  FormLabel,
  FormControl,
  GridItem,
  Divider,
  SimpleGrid,
  Grid,
  Text,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { SendForm } from '../types';
import { logger } from '@sendpayments/js-utils/dist/services/logger';
import { InputField, Select, ValueContainer } from '@sendpayments/react-shared/components/molecules';
import { useToast } from '@sendpayments/react-shared/components/molecules/Toast';
import { PaySummary } from '@send-base/atomic-components/atoms';
import { Recipient } from '@send-base/infrastructure/query/useRecipient';
import { CurrencyPair } from '../CurrencyExchangeWidgetForm/hooks/useCurrencyExchangeWidget';
import { useReasonCodes } from '@send-data-hooks';
import { truncateLongName } from '@send-base/utilities/';
import { getCountryCode } from '@sendpayments/js-utils/dist/utils/common';

export const reviewDetailsSchema = yup.object({
  reason: yup.string().required('Please select a purpose'),
  otherReason: yup.string().when('reason', {
    is: 'OTHER',
    then: yup.string().required('Please enter a purpose'),
  }),
  reference: yup.string().required('Please enter a reference message'),
});

export type ReviewDetailsData = yup.InferType<typeof reviewDetailsSchema>;

const defaultFormValues = {
  reason: '',
  reference: '',
  otherReason: '',
};

export interface ReviewDetailsFormProps extends SendForm<ReviewDetailsData> {
  recipient: Recipient;
  currencyPair?: CurrencyPair;
}

export const ReviewDetailsForm: React.FC<ReviewDetailsFormProps> = ({
  onSubmit,
  isLoading,
  initialValues,
  recipient,
  currencyPair,
}: ReviewDetailsFormProps) => {
  const toast = useToast();
  const [
    {
      data: { transferReasons, paymentReasons },
    },
  ] = useReasonCodes({
    toCurrency: currencyPair?.toCurrencyCode,
    bankCountryCode: getCountryCode(recipient.beneficiaryBankAccountCountry),
    entityType: recipient.beneficiaryType,
  });
  const reasonCodesList = paymentReasons.length > 0 ? paymentReasons : transferReasons;
  const name = truncateLongName((recipient?.businessName || recipient?.firstName) ?? '');

  return (
    <Formik
      initialValues={{ ...defaultFormValues, ...initialValues }}
      validateOnMount={false}
      validationSchema={reviewDetailsSchema}
      onSubmit={async (values, actions) => {
        try {
          await onSubmit(values);
          // analytics.push({ action: { type: 'form_submit', data: { name: 'ReviewDetailsForm' } } });
        } catch (error) {
          logger.error('ReviewDetailsForm', 'ERROR: ', error);
          actions.setSubmitting(false);
          toast({
            variant: 'negative',
            title: 'Something went wrong on our end. Please try again!',
          });
        }
      }}
    >
      {(props) => (
        <Form noValidate>
          <Stack spacing={4} textAlign="left">
            {/* Left box */}
            <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(5, 1fr)' }} gap={4}>
              <GridItem colSpan={{ base: 1, md: 3 }} border="light" borderColor="border.base" p={8} borderRadius="8px">
                <Stack spacing={4}>
                  <Text fontWeight="bold" fontSize="sm" color="secondary.base">
                    Recipient
                  </Text>
                  <Divider />
                  <SimpleGrid templateColumns={'1fr 2fr'} spacing={4} pb={4}>
                    <Text fontSize="md">Name</Text>
                    <Text fontSize="md" fontWeight="bold" textAlign="right" isTruncated>
                      {recipient.businessName || `${recipient.firstName} ${recipient.lastName}`}
                    </Text>
                    {recipient.accountNumber && (
                      <>
                        <Text fontSize="md">Account number </Text>
                        <Text fontSize="md" fontWeight="bold" textAlign="right">
                          {recipient.accountNumber}
                        </Text>
                      </>
                    )}
                    {recipient.iban && (
                      <>
                        <Text fontSize="md">IBAN </Text>
                        <Text fontSize="md" fontWeight="bold" textAlign="right">
                          {recipient.iban}
                        </Text>
                      </>
                    )}
                    {recipient.email && (
                      <>
                        <Text fontSize="md">Email</Text>
                        <Text fontSize="md" fontWeight="bold" textAlign="right" isTruncated>
                          {recipient.email}
                        </Text>
                      </>
                    )}
                  </SimpleGrid>
                  <Divider />
                  {/* Purpose */}
                  <Field name="reason">
                    {({ field, form }) => (
                      <FormControl id={field.name} isInvalid={form.errors[field.name] && form.touched[field.name]}>
                        <FormLabel mb={1}>Reason</FormLabel>
                        <Select
                          placeholder={'Select your transfer purpose...'}
                          value={reasonCodesList.find((item: { value: string }) => item.value === field.value)}
                          options={reasonCodesList.map(({ code, description }) => ({ label: description, value: code }))}
                          onChange={(option) => field.onChange(field.name)(option?.value)}
                          width="100%"
                          isDisabled={isLoading}
                          isInvalid={form.errors[field.name] && form.touched[field.name]}
                          components={{ ValueContainer }}
                        />
                        <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  {/* Other Purpose */}
                  {props.values.reason === 'OTHER' && (
                    <Field name="otherReason">
                      {({ field, form }) => (
                        <InputField
                          id={field.name}
                          placeholder="Enter your reason here"
                          isInvalid={!!form.errors[field.name] && form.touched[field.name]}
                          isValid={!form.errors[field.name] && !!field.value}
                          errorText={form.errors[field.name]}
                          inputProps={{
                            ...field,
                            size: 'md',
                            disabled: isLoading,
                          }}
                        />
                      )}
                    </Field>
                  )}
                  {/* Reference */}
                  <Field name="reference">
                    {({ field, form }) => (
                      <InputField
                        id={field.name}
                        label="Reference"
                        placeholder="Enter your reference here"
                        isInvalid={!!form.errors[field.name] && form.touched[field.name]}
                        isValid={!form.errors[field.name] && !!field.value}
                        errorText={form.errors[field.name]}
                        inputProps={{
                          ...field,
                          size: 'md',
                          disabled: isLoading,
                        }}
                      />
                    )}
                  </Field>
                </Stack>
              </GridItem>
              <GridItem colSpan={{ base: 1, md: 2 }} h="fit-content">
                <PaySummary
                  payLines={[
                    {
                      description: 'You send',
                      amount: `${currencyPair?.fromAmount} ${currencyPair?.fromCurrencyCode}`,
                      size: 'lg',
                    },
                    {
                      description: `${name} gets`,
                      amount: `${currencyPair?.toAmount}  ${currencyPair?.toCurrencyCode}`,
                      size: 'lg',
                    },
                    { description: 'Locked rate', amount: `${currencyPair?.rate}` },
                  ]}
                />
              </GridItem>
            </Grid>
            <Button isLoading={props.isSubmitting || isLoading} type="submit" alignSelf="center" fontSize="md">
              Confirm transfer
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
