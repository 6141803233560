import { Layout, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { ThemedImage } from '../Common/ThemedImage';
import { CloseButton } from '../Common/CloseButton';
import './index.less';

const { Header } = Layout;

function StepFormLayout(props) {
  return (
    <Layout className={props.className}>
      <Header>
        <div className="container">
          <Row type="flex" justify="space-between" style={{ width: '100%' }}>
            <Col xl={3} lg={4} xs={6}>
              <a className="logo" href="/">
                <ThemedImage alt="logo" file="logo.svg" />
              </a>
            </Col>

            <Col style={{ alignSelf: 'flex-end' }}>
              <Row>
                <Col>
                  <CloseButton onClick={props.onCloseClick} />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Header>
      {props.children}
    </Layout>
  );
}

StepFormLayout.propTypes = {
  children: PropTypes.node.isRequired,
  onCloseClick: PropTypes.func,
  className: PropTypes.string,
};

StepFormLayout.defaultProps = {
  onCloseClick: undefined,
  className: undefined,
};

export default StepFormLayout;
