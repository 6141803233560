import { useEffect, useState, useContext } from 'react';
import { message } from 'antd';
import { API } from 'aws-amplify';
import * as queries from '@sendpayments/graphql/queries';
import { CoconutTypes } from '@sendpayments/shared-constants/types';
import { AppContext } from '@send-legacy-app/Context';
import * as subscriptions from '@sendpayments/graphql/subscriptions';
import { graphqlOperationEx } from '@sendpayments/graphql/fields';
import { logger } from '@sendpayments/js-utils/dist/services/logger';

const getDoc = (id) =>
  new Promise((resolve) => {
    API.graphql(graphqlOperationEx(queries.getCoconut, { id, type: CoconutTypes.DOCUMENT }))
      .then(({ data: { getCoconut: doc } }) => {
        resolve(doc);
      })
      .catch((error) => logger.error(error));
  });

function usePendingDocsState() {
  const { user, setLoading } = useContext(AppContext);
  const [pendingDocs, setPendingDocs] = useState([]);
  const [newDoc, setnewDoc] = useState(null);

  useEffect(() => {
    if (newDoc) {
      if (newDoc.docStatus === 'Requested') {
        if (!pendingDocs.map((doc) => doc.id).includes(newDoc.id)) {
          setPendingDocs(pendingDocs.concat([newDoc]));
        } else {
          setPendingDocs(pendingDocs.map((doc) => (doc.id === newDoc.id ? newDoc : doc)));
        }
      } else {
        setPendingDocs(pendingDocs.filter((doc) => doc.id !== newDoc.id));
      }
    }
  }, [newDoc]);

  useEffect(() => {
    // create subscribe
    const subscription = API.graphql(
      graphqlOperationEx(
        subscriptions.onCreateCoconutByOwnerType,
        {
          owner: user.attributes.email,
          type: CoconutTypes.DOCUMENT,
        },
        ['id'],
      ),
    ).subscribe({
      next: (coconut) => {
        const { id } = coconut.value.data.onCreateCoconutByOwnerType;
        getDoc(id).then((doc) => setnewDoc(doc));
      },
    });
    // update subscribe
    const updateSubscription = API.graphql(
      graphqlOperationEx(
        subscriptions.onUpdateCoconutByOwnerType,
        {
          owner: user.attributes.email,
          type: CoconutTypes.DOCUMENT,
        },
        ['id'],
      ),
    ).subscribe({
      next: (coconut) => {
        const { id } = coconut.value.data.onUpdateCoconutByOwnerType;
        getDoc(id).then((doc) => setnewDoc(doc));
      },
    });
    return function cleanup() {
      subscription.unsubscribe();
      updateSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const query = graphqlOperationEx(queries.listCoconutsByOwner, {
      owner: user.attributes.email,
      type: { eq: CoconutTypes.DOCUMENT },
      sortDirection: 'DESC',
      limit: 100,
    });
    setLoading(true);
    API.graphql(query)
      .then(({ data: { listCoconutsByOwner } }) => {
        setLoading(false);
        const pendingDocuments = listCoconutsByOwner.items.filter((item) => item.docStatus === 'Requested');
        setPendingDocs(pendingDocuments);
      })
      .catch((error) => message.error(`Error loading Documents: ${error.errors[0].message}`));
  }, []);

  return [pendingDocs, setPendingDocs];
}

export default usePendingDocsState;
