import PropTypes from 'prop-types';
import React, { memo, useState, useEffect } from 'react';
import { Layout, Spin, Row, Divider } from 'antd';
import { useRegistration, useAppContext } from '@send-data-hooks';
import { Link, useLocation } from 'react-router-dom';
import CustomLayout from '@send-legacy-components/Common/CustomLayout';
import { Icon } from '@send-legacy-components/Common';
import { Conversion } from '@send-legacy-containers/Conversion';
import { SMenu } from '@send-legacy-components/UI/SMenu';
import history from '@send-legacy-app/history';
import styles from './Conversion.module.less';
import { VRNAcknowledgementBanner } from '@send-legacy-components/VRNAcknowledgementBanner';
import { useConfirmation } from '@send-legacy-components/Common/Confirmation';
import { Confirmation as VRNConfirmation } from '@send-legacy-components/Common/Confirmation/VRN/Confirmation';
import { VRN_CURRENCIES as ALLOWED_VRN_CURRENCIES } from '@sendpayments/shared-constants/vrn';
import { saveEntity } from '@sendpayments/js-utils/dist/services/entities';

import dayjs from 'dayjs';

const { Content } = Layout;

const ConversionDetail = memo(({ match }) => {
  const [{ data: reg }] = useRegistration();
  const [collapsed, setCollapsed] = useState(true);
  const [{ loading: pageLoading }] = useAppContext();
  const urlParams = new URLSearchParams(history.location.search);
  const poliToken = urlParams.get('token');
  const location = useLocation();
  const [
    isVisibleUpdatedBankDetailsConfirmation,
    {
      setConfirmationVisible: setUpdatedBankDetailsConfirmationVisible,
      setConfirmationHidden: setUpdatedBankDetailsConfirmationHidden,
    },
  ] = useConfirmation();

  useEffect(() => {
    const { isCreateVRNEnabled, currency } = location?.state || {};

    if (isCreateVRNEnabled) {
      const vrnAcknowledgement = JSON.parse(reg.vrnLegalAcknowledgement)?.[currency] ? true : false;

      if (!vrnAcknowledgement && ALLOWED_VRN_CURRENCIES.includes(currency)) {
        setUpdatedBankDetailsConfirmationVisible();
      }
    }
  }, [location]);

  const onContinuePayment = async () => {
    await saveEntity({
      entity: {
        id: reg.id,
        vrnLegalAcknowledgement: JSON.stringify({
          AUD: dayjs().format(),
        }),
      },
      type: reg.type,
    });

    setUpdatedBankDetailsConfirmationHidden();
  };

  return (
    <Spin spinning={pageLoading}>
      <CustomLayout onClick={() => setCollapsed(true)} showSupportButton>
        <Content>
          <div className="container">
            <Row>
              <SMenu registration={reg} collapsed={collapsed} setCollapsed={setCollapsed} />
            </Row>
            <Divider className="top-menu-divider" />
            <VRNAcknowledgementBanner />
            {isVisibleUpdatedBankDetailsConfirmation && <VRNConfirmation okButtonText="Confirm" onOkClick={onContinuePayment} />}
            <Row className={styles.margin}>
              <Link className={styles.link} to={`/activity`}>
                <Icon type="left" />
                Your activities
              </Link>
            </Row>
            <Conversion
              id={match.params.id}
              currencyCloudReference={reg.currencyCloudReference}
              sendId={reg.sendId}
              poliToken={poliToken}
            />
          </div>
        </Content>
      </CustomLayout>
    </Spin>
  );
});

ConversionDetail.propTypes = {
  match: PropTypes.any.isRequired,
};

export default ConversionDetail;
