import allSettled from 'promise.allsettled';
import React from 'react';
import { render } from 'react-dom';
import { initializeResource } from '@send-services/resources';
import { getCurrentResource, getCurrentImagesPath } from '@send-legacy-themes/provider';
import { App } from './route-components/App';
import { Router } from './route-components/Router';
import LegacyApp from './legacy/app/App';

allSettled.shim();

const root = document.getElementById('root');

initializeResource(getCurrentResource(), { imagesPath: getCurrentImagesPath() });

render(
  <App>
    <LegacyApp />
    <Router />
  </App>,
  root,
);
