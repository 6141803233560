import React from 'react';
import { Icon } from 'antd';
import PropTypes from 'prop-types';

export const SCard = (props) => (
  <div className={`card ${props.className}`} onClick={() => (props.onClick ? props.onClick() : '')}>
    <div className="left" style={{ backgroundColor: props.color }}>
      <Icon type="solution" />
    </div>
    <div className="right">{props.children}</div>
  </div>
);

SCard.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

SCard.defaultProps = {
  color: '#f52da5',
  className: '',
  onClick: null,
};
