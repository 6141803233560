import React from 'react';
import { Button } from '@chakra-ui/react';
import { FullPageNotificationTemplate } from '../../templates';
import { useHistory } from 'react-router-dom';
import { ThumbsUp } from '@sendpayments/react-shared/components/Icons/ThumbsUp';

export const CongratulationsPage: React.VFC<{ name?: string }> = (props) => {
  const history = useHistory();

  const onClick = () => {
    history.push('/dashboard');
  };
  return (
    <FullPageNotificationTemplate
      icon={<ThumbsUp color={'text.base'} boxSize={{ base: '40px', lg: '83px' }} />}
      heading={`Nice one${props.name ? ` ${props.name}` : ``}!`}
      subHeading={`Your onboarding is complete.`}
      description="We’ll look over your details so you can start sending."
    >
      <Button size="md" onClick={onClick}>
        Return to home
      </Button>
    </FullPageNotificationTemplate>
  );
};
