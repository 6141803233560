import React, { useEffect, useState, useRef } from 'react';
import { withTheme } from '@sendpayments/react-shared/components/ThemeProvider';
import { HtmlText } from '@sendpayments/react-shared/components/HtmlText';
import { Content, Dialog, Heading, DialogContainer, View, useDialogContainer } from '@adobe/react-spectrum';
import { PrimaryLink } from '@send-components/PrimaryLink';
import { OtpCodeInput } from '@send-components/OtpCodeInput/index';
import Close from '@spectrum-icons/workflow/Close';
import styles from './VerifyCodeModal.module.less';

const DialogContent = ({ title, description, resendDelaySeconds = 180, onCodeComplete, onResendCode, error }) => {
  const ref = useRef(null);
  const [resendSeconds, setResendSeconds] = useState(resendDelaySeconds);
  const [isEnableResend, setIsEnableResend] = useState(false);
  const dialog = useDialogContainer();

  const onResendCodeHandler = () => {
    setResendSeconds(resendDelaySeconds);
    setIsEnableResend(false);
    onResendCode();
  };

  useEffect(() => {
    if (!isEnableResend) {
      const timer = setInterval(() => {
        setResendSeconds((val) => {
          const newVal = val - 1;
          if (newVal === 0) {
            clearInterval(timer);
            setIsEnableResend(true);
          }
          return newVal;
        });
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }

    return () => {};
  }, [isEnableResend]);

  return (
    <Dialog size="M">
      <View slot="hero" height={10}>
        <div className={styles.exit} onClick={dialog.dismiss}>
          <Close size="XS" />
        </div>
      </View>
      <Heading>
        <div className={styles.title}>{title}</div>
      </Heading>

      <Content marginTop={8}>
        <HtmlText>{description}</HtmlText>
        <View marginTop="28px" UNSAFE_className={`${styles.whiteBackground} ${error && styles.error}`}>
          <div ref={ref}>
            <OtpCodeInput onComplete={onCodeComplete} />
          </div>
        </View>
        {error && <div className={styles.errorMessage}>{error}</div>}
        <View marginTop={28}>
          {isEnableResend ? (
            <PrimaryLink onPress={onResendCodeHandler}>{`Please resend the code`}</PrimaryLink>
          ) : (
            <PrimaryLink isDisabled={!isEnableResend} onPress={onResendCodeHandler}>
              <span>
                This code is valid for the next <strong>{`${resendSeconds ? `${resendSeconds}s` : ''}`}</strong>
              </span>
            </PrimaryLink>
          )}
        </View>
      </Content>
    </Dialog>
  );
};

const VerifyCodeModal = ({ isOpen, onDismiss, ...props }) => {
  return <DialogContainer onDismiss={onDismiss}>{isOpen && <DialogContent {...props} />}</DialogContainer>;
};

VerifyCodeModal.displayName = 'VerifyCodeModal';

export default withTheme(VerifyCodeModal);
