import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Grid,
  Box,
  ButtonGroup,
} from '@chakra-ui/react';

export interface SimpleModalProps {
  open: boolean;
  onClose?: () => void;
  onPrimaryAction: () => void;
  onSecondaryAction?: () => void;
  primaryButtonText: string;
  secondaryButtonText?: string;
  children: React.ReactNode;
}

export const SimpleModal = ({
  open,
  onClose = () => {},
  onPrimaryAction,
  onSecondaryAction,
  children,
  primaryButtonText,
  secondaryButtonText,
}: SimpleModalProps) => {
  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="400px" mx={{ base: 5, sm: 0 }}>
        <Grid>
          <Box w="auto" display="none">
            <ModalCloseButton background="border.light" borderRadius="100%" />
          </Box>

          <ModalBody w="auto" textAlign="center" mt={8}>
            {children}
          </ModalBody>

          <ModalFooter
            w="auto"
            justifyContent="center"
            pt={0}
            mt={{ base: 2, sm: 4 }}
            mb={4}
            flexDir={{ base: 'column', sm: 'row' }}
          >
            <ButtonGroup flexDir={{ base: 'column', sm: 'row' }} spacing={{ base: 0, sm: 2 }}>
              <Button
                onClick={onSecondaryAction}
                mb={{ base: 2, sm: 0 }}
                variant={'outline'}
                display={secondaryButtonText ? '' : 'none'}
              >
                {secondaryButtonText}
              </Button>
              <Button onClick={onPrimaryAction}>{primaryButtonText}</Button>
            </ButtonGroup>
          </ModalFooter>
        </Grid>
      </ModalContent>
    </Modal>
  );
};
